import { Component, Inject, Input, OnInit } from '@angular/core';
import { untilDestroyed } from '@ngneat/until-destroy';
import { AllPersonalNotificationsService } from '@ngx-common/notifications/services/all-personal-notifications.service';
import { ECopyleaksAPP, NgxCommonPagesConfig } from '@ngx-common/pages/pages.config';

@Component({
	selector: 'cls-mobile-bottom-notification-item',
	templateUrl: './mobile-bottom-notification-item.component.html',
	styleUrls: ['./mobile-bottom-notification-item.component.scss'],
})
export class MobileBottomNotificationItemComponent implements OnInit {
	/**
	 * Set text for notification button
	 * @Input
	 */
	@Input() text: string = 'Notifications';

	unreadNotificationsCount: number = 0;

	constructor(private _notificationsSvc: AllPersonalNotificationsService) {}

	ngOnInit(): void {
		this._checkUnreadNotifications();
	}

	private _checkUnreadNotifications() {
		this._notificationsSvc.notifications$.pipe(untilDestroyed(this)).subscribe(data => {
			this.unreadNotificationsCount = 0;
			data.forEach(notification => {
				if (!notification.isRead) {
					this.unreadNotificationsCount++;
				}
			});
		});
	}

	async notificationsMenuClosedAsync() {
		if (this.unreadNotificationsCount) {
			await this._notificationsSvc.markAllNotificationsAsRead();
			this.unreadNotificationsCount = 0;
		}
	}

	changeUnreadItemsToZero() {}
}
