import { Router } from '@angular/router';
import { environment } from '@environment';
import { ECopyleaksAPP } from '../../../../pages/pages.config';
import { ScreenService } from '../../../../services/screen.service';

export abstract class BaseToolbarMenuComponent {
	app: ECopyleaksAPP;
	eCopyleaksAPP = ECopyleaksAPP;
	environment = environment;

	constructor(protected router: Router) {}

	openExternalLink(link: string, isBlank: boolean = true) {
		if (isBlank) window?.open(link, '_blank');
		else window?.open(link, '_self');
	}

	openInternalLink(link: string) {
		this.router.navigate([link]);
	}
}
