import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { GTAG_CONSTS } from '../constants/google-analytics.consts';
import { environment } from '@environment';

declare let gtag: any;
@Injectable({
	providedIn: 'root',
})
export class GoogleAnalyticsService {
	isInitalized = false;
	constructor(@Inject(PLATFORM_ID) private platformId: object) {}

	public async eventPageInitilization(pageUrl: string) {
		if (isPlatformBrowser(this.platformId)) {
			// let userId: string;
			const gtagConfig: any = {
				page_path: pageUrl,
			};
			gtag('config', environment.googleAnalyticsKey, gtagConfig);
			this.isInitalized = true;
		}
	}

	public onEventEmite(eventCategory: string, eventAction: string, eventLabel?: string, eventValue?: number) {
		if (isPlatformBrowser(this.platformId)) {
			if (!this.isInitalized) return;
			gtag(GTAG_CONSTS.EVENT, eventAction, {
				event_category: eventCategory,
				event_label: eventLabel,
				value: eventValue,
			});
		}
	}

	public selectedContentEvent(eventName: string, item: IItem) {
		if (isPlatformBrowser(this.platformId)) {
			if (!this.isInitalized) return;
			gtag(GTAG_CONSTS.EVENT, eventName, {
				content_type: GTAG_CONSTS.CONTENT_TYPE.PRODUCT,
				items: [
					{
						id: item.id,
						name: item.name,
						list_name: item.location,
						product: item.product ? item.product : null,
						quantity: '1',
						price: item.price,
					},
				],
			});
		}
	}

	public sendCustomPageViewEvent(page_title: string, page_location: string, page_path?: string) {
		if (isPlatformBrowser(this.platformId)) {
			if (!this.isInitalized) return;
			const eventData: any = {
				page_title,
				page_location,
			};
			if (page_path) {
				eventData.page_path = page_path;
			}
			gtag(GTAG_CONSTS.EVENT, GTAG_CONSTS.PAGE_VIEW, eventData);
		}
	}

	public eventTranscationOrPurchase(eventName: string, item: IItem, transaction?: ITransaction, coupon?: string) {
		if (isPlatformBrowser(this.platformId)) {
			if (!this.isInitalized) return;
			const eventData: any = {
				items: [
					{
						id: item.id,
						name: item.name,
						list_name: item.location,
						product: item.product ? item.product : null,
						quantity: '1',
						price: item.price,
					},
				],
			};

			if (transaction) {
				eventData.transaction_id = transaction.transactionId;
				eventData.affiliation = transaction.affiliation;
				eventData.value = transaction.value;
			}

			if (coupon) {
				eventData.coupon = coupon;
			}

			gtag(GTAG_CONSTS.EVENT, eventName, eventData);
		}
	}

	public purchaseEvent(eventName: string, item: IItem, transaction?: ITransaction, coupon?: string) {
		if (isPlatformBrowser(this.platformId)) {
			if (!this.isInitalized) return;
			const eventData: any = {
				items: [
					{
						id: item.id,
						name: item.name,
						list_name: item.location,
						product: item.product ? item.product : null,
						quantity: '1',
						price: item.price,
					},
				],
			};

			if (transaction) {
				eventData.transaction_id = transaction.transactionId;
				eventData.affiliation = transaction.affiliation;
				eventData.value = transaction.value;
			}

			if (coupon) {
				eventData.coupon = coupon;
			}

			gtag(GTAG_CONSTS.EVENT, eventName, eventData);
		}
	}
}

export interface IEventBase {
	items: IItem[];
}

export interface IItem {
	id: string;
	name: string;
	location: string;
	price: string;
	category?: string;
	list_id?: string;
	list_name?: string;
	discount?: number;
	couponName?: string;
	product?: string;
	yearly?: boolean;
	app?: string;
	currency?: string;
}

export interface ITransaction {
	transactionId: string;
	affiliation?: string;
	value: string;
}
