import {
	APP_INITIALIZER,
	forwardRef,
	Inject,
	ModuleWithProviders,
	NgModule,
	Optional,
	PLATFORM_ID,
} from '@angular/core';
import { StripeCard } from './stripe-card';
import { StripeCVCNumber as StripeCardCvc } from './stripe-card/stripe-card-cvc.component';
import { StripeExpiryNumber as StripeCardExpiry } from './stripe-card/stripe-card-expiry.component';
import { StripeCardNumber } from './stripe-card/stripe-card-number.component';
import { StripeControl } from './stripe-control';
import { Stripe } from './stripe-definitions';
import { Elements } from './stripe-definitions/element';
import { loadStripeJS, StripeConfig, stripeConfigToken, stripeFactory } from './stripe-factory';
import { StripeMaterial } from './stripe-material';

@NgModule({
	imports: [
		/*CommonModule*/
	],
	declarations: [StripeControl, StripeMaterial, StripeCard, StripeCardNumber, StripeCardCvc, StripeCardExpiry],
	exports: [StripeControl, StripeMaterial, StripeCard, StripeCardNumber, StripeCardCvc, StripeCardExpiry],
})
export class StripeModule {
	static _platformId;
	constructor(@Inject(PLATFORM_ID) platformId: string) {
		StripeModule._platformId = platformId;
	}

	static init(config: StripeConfig): ModuleWithProviders<StripeModule> {
		return {
			ngModule: StripeModule,
			providers: [
				/** Loads the Stripe.js script during app initialization */
				{
					provide: APP_INITIALIZER,
					useValue: loadStripeJS,
					multi: true,
					deps: [[new Optional(), new Inject(stripeConfigToken)]],
				},
				/** Provides the global StripeConfig object */
				{ provide: stripeConfigToken, useValue: config },
				/** Provides Stripe as an injectable service. Stripe has been definid as an abstract class to
				 * be purposely used as a valid token while stripeFactory() provides a stripe instance configured
				 * according to the global StripeConfig object.
				 */
				{
					provide: Stripe,
					useFactory: stripeFactory,
					deps: [[new Optional(), new Inject(stripeConfigToken)]],
				},
				/** Provide Stripe.elements() as an injectable service. Elements has been purposely definied
				 * as an abstract class to be used as a valid token. while the factory is inlined here to call
				 * upon the stripe instance and returning the Elements instance configured accoridg to the global
				 * StripeConfig object.
				 */
				{
					provide: Elements,
					useFactory: stripElementsFactory,
					deps: [forwardRef(() => Stripe), [new Optional(), new Inject(stripeConfigToken)]],
				},
			],
		};
	}
}

export function stripElementsFactory(stripe: Stripe, config: StripeConfig) {
	return stripe.elements(config && config.elementsOptions);
}
