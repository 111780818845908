import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { ClsAlertsService } from '@ngx-common-v2/components/cls-alerts/services/cls-alerts.service';
import { NgxCommonPagesConfig } from '../../pages/pages.config';
import { AuthService } from '../../services/auth.service';
import { UrlLocationService } from '../../services/url-location.service';
import { BaseNotificationsService } from './base-notifications.service';

@Injectable({
	providedIn: 'root',
})
export class NotificationsService extends BaseNotificationsService {
	public connectionPath = '/notifications/ws';

	constructor(
		@Inject(PLATFORM_ID) platformId: object,
		urlLocationSvc: UrlLocationService,
		authSvc: AuthService,
		clsAlertsSvc: ClsAlertsService,
		@Inject(NgxCommonPagesConfig.key) _config: NgxCommonPagesConfig
	) {
		super(platformId, urlLocationSvc, authSvc, clsAlertsSvc, _config);
	}

	// On websocket connection success
	onConnectionSuccess(hubConnection: signalR.HubConnection) {
		if (this.authSvc.isLoggedIn()) {
			hubConnection.invoke('Register', this.authSvc.token, this.signalRProducts);
		}
	}
}
