<div *ngIf="!newDesign">
	<div class="init-spinner" fxLayout="column" *ngIf="showSpinner" fxLayoutAlign="center center">
		<cls-spinner></cls-spinner>
	</div>
	<div *ngIf="!showSpinner" class="payment-method">
		<h3 no-margin-top i18n>Choose Payment Method</h3>
		<mat-radio-group
			[(ngModel)]="selectedCardId"
			class="card-list"
			[disabled]="disabledForm"
			(ngModelChange)="cardChanged(selectedCardId)">
			<div
				*ngIf="defaultCard"
				(click)="cardChanged(defaultCard.paymentMethodId)"
				class="card {{
					selectedCardId === defaultCard.paymentMethodId && selectedMethodWithBorder ? 'selected-card' : ''
				}}
				 {{
					selectedCardId === defaultCard.paymentMethodId && !selectedMethodWithBorder
						? 'selected-card-without-border'
						: ''
				}}">
				<div class="radio-btn">
					<mat-radio-button [value]="defaultCard.paymentMethodId"></mat-radio-button>
				</div>
				<div class="card-number">
					<div class="title" i18n="@@NGX.PAYMENT_METHODS.CARD_NUMBER">CARD NUMBER</div>
					<div class="value">xxxx - xxxx - xxxx - {{ defaultCard.last4Digits }}</div>
				</div>
				<div class="name-on-number">
					<div class="title" i18n="@@NGX.PAYMENT_METHODS.NAME_ON_CARD">NAME ON CARD</div>
					<div class="value">{{ defaultCard.nameOnCard }}</div>
				</div>
				<div class="expiry">
					<div class="title" i18n="@@NGX.PAYMENT_METHODS.EXPIRY">EXPIRY</div>
					<div class="value">
						{{ defaultCard.experationMonth < 10 ? '0' + defaultCard.experationMonth : defaultCard.experationMonth }}/{{
							defaultCard.experationYear
						}}
					</div>
				</div>
			</div>

			<div
				(click)="cardChanged(NEW_CARD_ID)"
				class="card {{ selectedCardId === NEW_CARD_ID && selectedMethodWithBorder ? 'selected-card' : '' }}
					 {{ selectedCardId === NEW_CARD_ID && !selectedMethodWithBorder ? 'selected-card-without-border' : '' }}  new-card">
				<div class="radio-btn">
					<mat-radio-button [value]="NEW_CARD_ID"></mat-radio-button>
				</div>
				<div class="card-number">
					<div class="title" i18n="@@NGX.PAYMENT_METHODS.NEW_CREDIT_CARD">NEW CREDIT CARD</div>
				</div>
				<div class="card-types-img">
					<img role="presentation" src="assets/card-types.svg" alt="assets/card-types.svg" />
				</div>
			</div>
		</mat-radio-group>
	</div>
</div>
<!--  -->
<div *ngIf="newDesign">
	<div class="init-spinner" fxLayout="column" *ngIf="showSpinner" fxLayoutAlign="start center">
		<cls-spinner></cls-spinner>
	</div>
	<div *ngIf="!showSpinner">
		<div *ngIf="!billingStatus || billingStatus === eSubscriptionBillingStatus.Healthy" fxLayoutAlign="center center">
			<div
				fxLayout="column"
				fxLayoutAlign="space-between start"
				class="payment-method-container payment-method-container-new-design">
				<ng-container *ngTemplateOutlet="cardDetails"></ng-container>
			</div>
		</div>
		<div *ngIf="billingStatus === eSubscriptionBillingStatus.UnHealthy" fxLayout="column" fxLayoutAlign="center center">
			<div fxLayout="row" class="title-container" fxLayoutGap="12px" fxLayoutAlign="start start" *ngIf="isMobile">
				<mat-icon>warning</mat-icon>
				<span i18n>Your subscription is currently SUSPENDED. We were unable to process your credit card</span>
			</div>
			<div fxLayout="column" fxLayoutAlign="space-between start" class="locked-payment-method-container">
				<div fxLayout="row" class="title-container" fxLayoutGap="12px" *ngIf="!isMobile" fxLayoutAlign="start start">
					<mat-icon>warning</mat-icon>
					<span i18n>
						Your subscription is currently SUSPENDED.
						<br />
						We were unable to process your credit card
					</span>
				</div>
				<ng-container *ngTemplateOutlet="cardDetails"></ng-container>
			</div>
		</div>
	</div>
</div>

<ng-template #cardLogo>
	<img
		class="card-type-img"
		role="presentation"
		alt="/assets/card-types/Visa.svg"
		*ngIf="defaultCard.type === ePaymentMethodTypes.Visa"
		src="/assets/card-types/Visa.svg" />
	<img
		class="card-type-img"
		role="presentation"
		alt="/assets/card-types/AmericanExpress.svg"
		*ngIf="defaultCard.type === ePaymentMethodTypes.AmericanExpress"
		src="/assets/card-types/AmericanExpress.svg" />
	<img
		role="presentation"
		class="card-type-img"
		*ngIf="defaultCard.type === ePaymentMethodTypes.Discover"
		src="/assets/card-types/Discover.svg"
		alt="/assets/card-types/Discover.svg" />
	<img
		role="presentation"
		alt="/assets/card-types/MasterCard.svg"
		class="card-type-img"
		*ngIf="defaultCard.type === ePaymentMethodTypes.MasterCard"
		src="/assets/card-types/MasterCard.svg" />
</ng-template>

<ng-template #cardDetails>
	<div fxLayout="row" fxLayoutGap="15px" class="card-info-container">
		<div fxLayoutAlign="center center">
			<ng-container *ngTemplateOutlet="cardLogo"></ng-container>
		</div>

		<div
			fxLayout="column"
			fxLayoutGap="16px"
			fxLayoutAlign="center space-between"
			fxLayoutGap.lt-md="0px"
			class="card-info">
			<div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutAlign.lt-md="space-between center">
				<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
					<h3 class="card-type">{{ getCardTypeName(defaultCard.type) }}</h3>
					<mat-icon *ngIf="showCheckMarkCircle" id="checkout-payment-methods" class="check-mark">check_circle</mat-icon>
				</div>
				<a class="update-button" i18n (click)="onUpdateCard()">Update card</a>
			</div>
			<div
				fxLayout="row"
				fxLayoutAlign="space-between center"
				fxLayoutAlign.lt-md="space-between center"
				fxLayoutGap.lt-md="0px"
				class="card-additional-info">
				<div fxLayout="row" fxLayoutGap="5px" class="card-data">
					<span class="card-data-label" i18n>Ends with</span>
					<span class="card-data-text">{{ defaultCard.last4Digits }}</span>
				</div>
				<span *ngIf="!isMobile" class="card-data-text">|</span>
				<span *ngIf="isMobile" class="card-data-bullet">&#8226;</span>
				<div fxLayout="row" fxLayoutGap="5px">
					<span class="card-data-label" i18n>Expiration</span>
					<span class="card-data-text">
						{{ defaultCard.experationMonth < 10 ? '0' + defaultCard.experationMonth : defaultCard.experationMonth }}/{{
							defaultCard.experationYear
						}}
					</span>
				</div>
			</div>
		</div>
	</div>
</ng-template>
