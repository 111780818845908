import { Component, EventEmitter, HostBinding, HostListener, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ClsAlertsService } from '@ngx-common-v2/components/cls-alerts/services/cls-alerts.service';
import { filter } from 'rxjs';
import { NotificationErrorDialogComponent } from '../../../notifications/dialogs/notification-error-dialog/notification-error-dialog.component';
import { ProgressPersonalNotificationsService } from '../../../notifications/services/progress-personal-notifications.service';
import { ETaskElementStatus, IPersonalNotificationTask } from '../web-notification';

@UntilDestroy()
@Component({
	selector: 'cls-notification-item-download',
	templateUrl: './notification-item-download.component.html',
	styleUrls: ['./notification-item-download.component.scss'],
})
export class NotificationItemDownloadComponent implements OnInit {
	@Input() model: IPersonalNotificationTask = null;
	@Input() isDeletingItem: boolean;
	@Input() isBackgroundTask: false;
	eTaskElementStatus = ETaskElementStatus;
	@Output() onNotificationClose = new EventEmitter<string>();
	progress = 0;

	@HostBinding('style.cursor') cursor = '';
	isHovering = false;
	allowCancel = true;

	@HostListener('mouseenter')
	onMouseEnter() {
		this.isHovering = true;
		if (
			this.model.metaData?.status == ETaskElementStatus.Done ||
			this.model.metaData?.status == ETaskElementStatus.Failed
		)
			this.cursor = 'pointer';
	}

	@HostListener('mouseleave')
	onMouseLeave() {
		this.isHovering = false;
		this.cursor = '';
	}

	@HostListener('click', ['$event.target'])
	onClick(btn) {
		// if the status of the notification is falied then show an error dialog on host click
		switch (this.model.metaData?.status) {
			case ETaskElementStatus.Failed:
				this.dialog.open(NotificationErrorDialogComponent, {
					panelClass: 'notification-error-frame-dialog',
					width: '553px',
					data: {
						errorMessages: this.model.metaData?.result,
						guideCode: this.model.metaData?.ticketId,
					},
				});
				break;
			case ETaskElementStatus.Done:
				this._manuallyDownloadPDF();
				break;
			default:
				break;
		}
	}

	constructor(
		public dialog: MatDialog,
		private _progressNotificationsSvc: ProgressPersonalNotificationsService,
		private _clsAlertsSvc: ClsAlertsService
	) {}

	ngOnInit(): void {
		this._progressNotificationsSvc.onNotificationChange
			.pipe(
				untilDestroyed(this),
				filter(v => v.id == this.model.id)
			)
			.subscribe(value => {
				this.model = JSON.parse(JSON.stringify(value));
				this.progress = value.metaData.progress;
			});
	}

	private async _manuallyDownloadPDF() {
		this.model = {
			...this.model,
			metaData: {
				...this.model.metaData,
				status: ETaskElementStatus.Running,
			},
		};
		this.allowCancel = false;
		try {
			var notification = this.model;
			if (notification?.metaData?.totalFiles == 1 && notification?.metaData?.fileName) {
				await this._progressNotificationsSvc.downloadReportPdfUrlAsync(
					notification.id,
					'pdf',
					notification.metaData.fileName
				);
			} else {
				await this._progressNotificationsSvc.downloadReportPdfUrlAsync(notification.id, 'zip');
			}
		} catch (error) {
			this._clsAlertsSvc.showHttpResponseError(error);
		} finally {
			this.model = {
				...this.model,
				metaData: {
					...this.model.metaData,
					status: ETaskElementStatus.Done,
				},
			};
			this.allowCancel = true;
		}
	}

	cancelDownload() {
		this._progressNotificationsSvc.cancelNotification(this.model.id);
	}
}
