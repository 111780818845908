import { Component, OnInit, Input } from '@angular/core';
import { EClsResultsTypes, IResultItem as IResultOverviewAnalyticsItem } from './models/cls-results-overview.models';
import { IAnalyticsExternalResults } from '../../models/cls-external-results-table.models';

@Component({
	selector: 'cls-results-overview',
	templateUrl: './cls-results-overview.component.html',
	styleUrls: ['./cls-results-overview.component.scss'],
})
export class ClsResultsOverviewComponent implements OnInit {
	/**
	 * The results data.
	 * @Input
	 */
	@Input() resultItems: IResultOverviewAnalyticsItem[];

	chartData: IAnalyticsExternalResults[] = [];

	// The max width of the container
	@Input() maxWidth: string = '';

	// Overall total results sum.
	totalResults: number = 0;

	//Empty State
	isEmpty: boolean = false;

	colorScheme = {
		domain: ['#052D8B', '#052D8B98', '#052D8B5A', '#052D8B36'],
	};

	eResultsType = EClsResultsTypes;

	constructor() {}

	ngOnInit(): void {
		// sum total
		this.totalResults = this.resultItems.reduce((accumulator, result) => accumulator + result.amount, 0);

		// calc height precentge
		this.resultItems.forEach(result => {
			switch (result.type) {
				case this.eResultsType.BetweenStudents:
					result.resultTypetName = $localize`Between Students`;
					break;
				case this.eResultsType.Internet:
					result.resultTypetName = $localize`Internet`;
					break;
				case this.eResultsType.InternalDataBase:
					result.resultTypetName = $localize`Internal Database`;
					break;
				case this.eResultsType.Repositories:
					result.resultTypetName = $localize`Repositories`;
					break;
			}

			if (!this.totalResults) {
				this.isEmpty = true;
				this.colorScheme = {
					domain: ['#C9E2FC'],
				};
				this.chartData.push({
					name: $localize`No Results`,
					value: 1,
				});
			} else {
				this.chartData.push({
					name: result.resultTypetName,
					value: result.amount,
				});
			}
		});
	}
}
