import { Injectable } from '@angular/core';
import { IPasswordPolicy } from '../../interfaces/interfaces.shared';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class PasswordFormFieldService {
	private _passwordPolicyErrorFlag$ = new BehaviorSubject<boolean>(false);
	private _passwordPolicy$ = new Subject<IPasswordPolicy>();

	public get passwordPolicy$() {
		return this._passwordPolicy$;
	}

	public get passwordPolicyErrorFlag$() {
		return this._passwordPolicyErrorFlag$;
	}

	constructor() {}

	updatePasswordPolicyErrorFlag(status: boolean) {
		this._passwordPolicyErrorFlag$.next(status);
	}

	updatePasswordPolicy(policy: IPasswordPolicy) {
		this._passwordPolicy$.next(policy);
	}
}
