import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from '@environment';
import { ClsAlertsService } from '@ngx-common-v2/components/cls-alerts/services/cls-alerts.service';
import { ClipboardService } from 'ngx-clipboard';
import { ITranslation } from '../../../interfaces/interfaces.shared';
import { CLSTranslateService } from '../../../services/translate.service';

@Component({
	selector: 'app-notification-error-dialog',
	templateUrl: './notification-error-dialog.component.html',
	styleUrls: ['./notification-error-dialog.component.scss'],
})
export class NotificationErrorDialogComponent implements OnInit {
	errorMessageIsArray: boolean;
	translations: ITranslation;
	guideCode: string;

	environment = environment;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private _clipboardSvc: ClipboardService,
		private _clsAlertsSvc: ClsAlertsService,
		private _translateSvc: CLSTranslateService
	) {}
	async ngOnInit() {
		this.translations = await this._translateSvc.getAsync(['TEXT_WAS_COPIED_SUCCESSFULLY']);
		this.guideCode = this.data.guideCode;
		if (Array.isArray(this.data.errorMessages)) {
			this.errorMessageIsArray = true;
		} else {
			this.errorMessageIsArray = false;
		}
	}

	copyGuideCode() {
		this._clipboardSvc.copyFromContent(this.guideCode);
		this._clsAlertsSvc.showCustomPrimary(this.translations.TEXT_WAS_COPIED_SUCCESSFULLY);
	}

	openExternalLink(link: string) {
		window?.open(link, '_blank');
	}
}
