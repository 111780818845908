import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LockoutActivateService } from '../../services/lockout-activate.service';
import { PaymentCheckoutComponentModule } from '../payment-checkout/payment-checkout.module';
import { PaymentProcessingModule } from '../payment-processing/payment-processing.module';
import { PaymentProccessComponent } from './payment-proccess.component';

@NgModule({
	declarations: [PaymentProccessComponent],
	exports: [PaymentProccessComponent],
	imports: [CommonModule, PaymentProcessingModule, PaymentCheckoutComponentModule],
	providers: [LockoutActivateService],
})
export class PaymentProccessModule {}
