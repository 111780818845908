import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SpinnerButtonComponent } from './spinner-button-component/spinner-button.component';
import { FloatDecimalPipeModule } from '../../pipes/float-decimal/float-decimal.module';

@NgModule({
	declarations: [SpinnerButtonComponent],
	exports: [SpinnerButtonComponent],
	imports: [CommonModule, FloatDecimalPipeModule, MatProgressSpinnerModule, MatButtonModule],
})
export class SpinnerButtonModule {}
