<div
	div
	class="integration-item-container"
	fxLayout="column"
	[fxLayoutGap]="integrationItem.isInegrationDeleted ? '32px' : '16px'"
	[class]="className">
	<!-- Top Section -->
	<div fxLayout="column" fxLayoutGap="6px">
		<div class="integration-item-header-container" fxLayout="row" fxLayoutAlign="space-between center">
			<div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="16px">
				<lms-integration-item-badge
					containerHeight="32px"
					containerWidth="32px"
					imageWidth="24px"
					[imageSrc]="badgeImageSrc"></lms-integration-item-badge>
				<div fxLayout="column" fxLayoutAlign="start">
					<span class="header-text" [innerHTML]="integrationItem.name | highlight: highlightedText" i18n></span>
					<span class="installation-date" i18n>INSTALLED: {{ integrationItem.creationDate | date: 'MM/dd/yyyy' }}</span>
				</div>
			</div>

			<ng-container *ngIf="!integrationItem.deletionDate">
				<ng-content select="lms-integration-item-menu"></ng-content>
			</ng-container>
		</div>

		<!-- Middle Section -->
		<div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
			<div class="integration-type">
				<ng-container [ngSwitch]="integrationItem?.type">
					<ng-container *ngSwitchCase="EGeneralLMSIntegrationType.LTI1_3" i18n>
						<span
							[innerHTML]="'LTI 1.3 Integrations' | highlight: (highlightType ? 'LTI 1.3 Integrations' : '')"></span>
					</ng-container>
					<ng-container *ngSwitchCase="EGeneralLMSIntegrationType.Canvas" i18n>
						<span
							[innerHTML]="
								'Canvas Plagiarism Frameworks' | highlight: (highlightType ? 'Canvas Plagiarism Frameworks' : '')
							"></span>
					</ng-container>
					<ng-container *ngSwitchCase="EGeneralLMSIntegrationType.Moodle" i18n>
						<span
							[innerHTML]="
								'Moodle Plagiarism Plugins' | highlight: (highlightType ? 'Moodle Plagiarism Plugins' : '')
							"></span>
					</ng-container>
					<ng-container *ngSwitchDefault></ng-container>
				</ng-container>
			</div>
			<span
				class="integration-status-container"
				[class]="className"
				fxLayout="row"
				fxLayoutGap="4px"
				fxLayoutAlign="start center">
				<span class="integration-status" [class]="className"></span>
				<span class="status-text">
					<ng-container [ngSwitch]="integrationItem?.status">
						<ng-container *ngSwitchCase="EGeneralLMSIntegrationStatus.Active" i18n>ACTIVE</ng-container>
						<ng-container *ngSwitchCase="EGeneralLMSIntegrationStatus.Pending" i18n>PENDING ACTIVATION</ng-container>
						<ng-container *ngSwitchCase="EGeneralLMSIntegrationStatus.Deleted" i18n>PENDING FOR DELETION</ng-container>
						<ng-container *ngSwitchDefault></ng-container>
					</ng-container>
				</span>
			</span>
		</div>

		<div
			*ngIf="
				integrationItem.type === EGeneralLMSIntegrationType.Canvas &&
				integrationItem?.status === EGeneralLMSIntegrationStatus.Pending
			"
			class="canvas-finish-registration"
			i18n>
			Finish Your Registration
			<a href="https://help.copyleaks.com/learning-management-systems-lms" target="_blank">Learn more</a>
		</div>
		<div *ngIf="integrationItem.isInegrationDeleted" class="deletion-explaination" i18n>
			This integration will be automatically deleted {{ integrationItem.deletionDate | dateFromNow }}.
			<br />
			When this registration is deleted, all the data that is related to it will be deleted.
		</div>
	</div>

	<!-- Actions Section -->
	<ng-content select="lms-integration-item-content"></ng-content>
</div>
