<div class="web-notification-menu-container">
	<div class="top-section" fxLayout="row" fxLayoutAlign="center center">
		<h2 i18n="@@NGX.NOTIFICATIONS.TITLE">Notifications</h2>
	</div>
	<div *ngIf="displayedNotifications$ | async as displayedNotifications" class="main-container">
		<!--  -->
		<div
			class="empty-view"
			fxLayout="column"
			fxLayoutGap="10px"
			fxLayoutAlign="center center"
			*ngIf="displayedNotifications.length == 0 && !hasErrorLoadingNotifications && !showSpinner">
			<mat-icon>notifications_off</mat-icon>
			<span i18n="@@NGX.NOTIFICATIONS.NO_NEW_NOTIFICATIONS">You don't have any unread notifications.</span>
		</div>
		<!--  -->
		<div
			class="empty-view"
			fxLayout="column"
			fxLayoutGap="10px"
			fxLayoutAlign="center center"
			*ngIf="displayedNotifications.length == 0 && hasErrorLoadingNotifications && !showSpinner">
			<mat-icon>error_outline</mat-icon>
			<span i18n="@@NGX.NOTIFICATIONS.NOTIFICATIONS_FAIL">Failed to load notifications</span>
			<button
				mat-raised-button
				type="button"
				color="primary"
				(click)="$event.preventDefault(); $event.stopPropagation(); reLoadNotifications()">
				Try again
			</button>
		</div>
		<!--  -->
		<ng-container *ngIf="displayedNotifications.length > 0">
			<ng-container *ngFor="let notification of displayedNotifications">
				<app-web-notification
					[isRead]="notification?.isRead"
					[notificationType]="notification?.messageType"
					[linkAddress]="notification?.linkAddress">
					<app-web-notification-description>{{ notification.message }}</app-web-notification-description>
					<app-web-notification-time [matTooltip]="notification.creationTime | date">
						{{ notification.creationTime | dateFromNow }}
					</app-web-notification-time>
				</app-web-notification>
				<div fxLayout="row" fxLayoutAlign="center center" style="margin-top: 10px; margin-bottom: 10px">
					<cls-spinner-button
						[showSpinner]="showButtonLoading"
						color="primary"
						i18n="@@NGX.SEE_MORE"
						(click)="addMoreNotifications(); $event.stopPropagation()"
						*ngIf="loadMoreNotifications"
						i18n="@@NGX.NOTIFICATIONS.SEE_MORE">
						SEE MORE
					</cls-spinner-button>
				</div>
			</ng-container>
		</ng-container>

		<div
			class="empty-view"
			style="height: 130px"
			fxLayout="column"
			fxLayoutGap="10px"
			fxLayoutAlign="center center"
			*ngIf="showSpinner && !displayedNotifications.length">
			<cls-spinner width="80px"></cls-spinner>
		</div>
	</div>
</div>
