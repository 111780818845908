export interface IResultItem {
	amount: number;
	type: EClsResultsTypes;
	resultTypetName?: string;
}

export enum EClsResultsTypes {
	Internet,
	InternalDataBase,
	Repositories,
	BetweenStudents,
}
