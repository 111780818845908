<ng-container [ngSwitch]="model?.metaData?.status">
	<div class="task-icon-container">
		<ng-container *ngSwitchCase="eTaskElementStatus.Running">
			<div class="running-icon">
				<div class="percentage" *ngIf="progress > 0">{{ progress }}%</div>
				<cls-spinner width="40px"></cls-spinner>
			</div>
		</ng-container>
		<ng-container *ngSwitchCase="eTaskElementStatus.Failed">
			<div class="unread-Notification-badge">
				<div
					class="failed-icon"
					[matBadge]="!model?.isRead && !isBackgroundTask ? '1' : ''"
					matBadgePosition="above before"
					matBadgeColor="peach"
					MatBadgeSize="small">
					<mat-icon>cancel</mat-icon>
				</div>
			</div>
		</ng-container>
		<ng-container *ngSwitchCase="eTaskElementStatus.Done">
			<div class="unread-Notification-badge">
				<div
					class="done-icon"
					[matBadge]="!model?.isRead && !isBackgroundTask ? '1' : ''"
					matBadgePosition="above before"
					matBadgeColor="peach"
					MatBadgeSize="small">
					<mat-icon>check_circle</mat-icon>
				</div>
			</div>
		</ng-container>
		<ng-container *ngSwitchDefault></ng-container>
	</div>
</ng-container>

<div class="message-contianer">
	<div class="title">
		<ng-container [ngSwitch]="model?.metaData?.status">
			<ng-container *ngSwitchCase="eTaskElementStatus.Running">
				<ng-container [ngTemplateOutlet]="runningText"></ng-container>
			</ng-container>
			<!--  -->
			<ng-container *ngSwitchCase="eTaskElementStatus.Failed">
				<ng-container [ngTemplateOutlet]="failedText"></ng-container>
			</ng-container>
			<!--  -->
			<ng-container *ngSwitchCase="eTaskElementStatus.Done">
				<ng-container [ngTemplateOutlet]="doneText"></ng-container>
			</ng-container>
			<!--  -->
		</ng-container>
	</div>
	<div class="description">
		{{ model.creationTime | dateFromNow: (model?.metaData?.status == eTaskElementStatus.Running ? 'Started' : '') }}
	</div>
</div>

<!-- Running template text  -->
<ng-template #runningText>
	<!-- One document -->
	<ng-container *ngIf="model?.metaData?.totalFiles == 1 && model?.metaData?.fileName">
		<ng-container i18n>Indexing&nbsp;</ng-container>
		<span [matTooltip]="model?.metaData?.fileName">'{{ model?.metaData?.fileName | ellipsis: -30 }}'</span>
		<ng-container i18n>&nbsp;document</ng-container>
		...
	</ng-container>
	<!-- Many documents -->
	<ng-container *ngIf="model?.metaData?.totalFiles > 1" i18n>
		Indexing {{ model?.metaData?.totalFiles }} documents
	</ng-container>
	<!--  -->
</ng-template>

<!-- done template text  -->
<ng-template #doneText>
	<!-- One document with name -->
	<ng-container *ngIf="model?.metaData?.totalFiles == 1 && model?.metaData?.fileName">
		<span [matTooltip]="model?.metaData?.fileName">'{{ model?.metaData?.fileName | ellipsis: 20 }}'</span>
		<ng-container i18n>&nbsp;document index completed</ng-container>
	</ng-container>
	<!-- One document without name -->
	<ng-container *ngIf="model?.metaData?.totalFiles == 1 && !model?.metaData?.fileName" i18n>
		{{ model?.metaData?.totalFiles }} document index completed
	</ng-container>
	<!-- Many documents -->
	<ng-container *ngIf="model?.metaData?.totalFiles > 1" i18n>
		{{ model?.metaData?.totalFiles }} documents index completed
	</ng-container>
	<!--  -->
</ng-template>

<!-- failed template text  -->
<ng-template #failedText>
	<!-- One document -->
	<ng-container *ngIf="model?.metaData?.totalFiles == 1 && !model?.metaData?.fileName">
		<ng-container i18n>&nbsp;1 document failed to index!</ng-container>
	</ng-container>
	<!-- One document with name -->
	<ng-container *ngIf="model?.metaData?.totalFiles == 1 && model?.metaData?.fileName">
		<span [matTooltip]="model?.metaData?.fileName">'{{ model?.metaData?.fileName | ellipsis: 30 }}'</span>
		<ng-container i18n>&nbsp;document failed to index!</ng-container>
	</ng-container>
	<!-- Many documents -->
	<ng-container *ngIf="model?.metaData?.totalFiles > 1" i18n>
		{{ model?.metaData?.totalFiles }} documents failed to index
	</ng-container>
</ng-template>
