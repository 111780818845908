import { Component, HostBinding, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ClsAlertsService } from './services/cls-alerts.service';
import { EAlertItemType } from './models/cls-alerts.enums';
import { ClsAlert } from './models/cls-alerts.models';

/**
 * Class representing a container for the page alerts.
 */
@UntilDestroy()
@Component({
	selector: 'cls-alerts',
	templateUrl: './cls-alerts.component.html',
	styleUrls: ['./cls-alerts.component.scss'],
})
export class ClsAlertsComponent implements OnInit {
	alerts: ClsAlert[] = [];
	EAlertItemType = EAlertItemType;

	constructor(private _clsAlertsSvc: ClsAlertsService) {}

	ngOnInit(): void {
		// subscibe to the alerts subject changes and update the list of alerts accordingly
		this._clsAlertsSvc.alerts$.pipe(untilDestroyed(this)).subscribe(alerts => {
			this.alerts = alerts;
		});
	}

	/**
	 * Remove the given alert from the alert list (delete by reference)
	 * @param alert The to be removed alert object
	 */
	closeAlert(alert: ClsAlert) {
		this._clsAlertsSvc.remove(alert);
	}
}
