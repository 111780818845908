import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';

@Component({
	selector: 'cls-toggle-list-item',
	templateUrl: './cls-toggle-list-item.component.html',
	styleUrls: ['./cls-toggle-list-item.component.scss'],
})
export class ClsToggleListItemComponent {
	/**
	 * a boolean to check if the item has icon or not
	 * @Input
	 */
	@Input() hasIcon: boolean = true;
	/**
	 * an image link to replace the icon
	 * @Input
	 */
	@Input() linkImgSrc: string = '';
	/**
	 * the icon color
	 * @Input
	 */
	@Input() iconColor: string = '#11296066';
	/**
	 * the text color
	 * @Input
	 */
	@Input() textColor: string = '#052d8b';

	/**
	 * Whether or not the list item link is external.
	 */
	@Input() isExternalLink: boolean = false;

	/**
	 * Whether or not the list item link is popup.
	 */
	@Input() isPopUpLink: boolean = false;

	/**
	 * The link for href attribute.
	 */
	@Input() link: string;
	/**
	 * the text color
	 * @Input
	 */
	@Input() tagText: string = '';
	/**
	 * an event emitter to trigger the action
	 * @Output
	 */
	@Output() toggleAction = new EventEmitter();
	/**
	 * an element reference to allow us to change the color of the icon dynamiclly
	 * @Output
	 */
	@ViewChild('itemIcon', { read: ElementRef }) itemIcon: ElementRef;
	/**
	 * an element reference to allow us to change the color of the text dynamiclly
	 * @Output
	 */
	@ViewChild('itemText', { read: ElementRef }) itemText: ElementRef;

	constructor(private renderer: Renderer2) {}

	/**
	 * using renderer to change colors
	 * @Function
	 */
	ngAfterViewInit() {
		if (this.itemIcon) this.renderer.setStyle(this.itemIcon.nativeElement, 'color', this.iconColor);
		if (this.itemText) this.renderer.setStyle(this.itemText.nativeElement, 'color', this.textColor);
	}
	/**
	 * On click event function that triggers an event emitter
	 * @Function
	 */
	onLinkClick() {
		this.toggleAction.emit();
	}
}

@Component({
	selector: 'cls-toggle-list-item-icon',
	template: '<ng-content></ng-content>',
	styleUrls: ['./cls-toggle-list-item.component.scss'],
})
export class ClsToggleListIconComponent {}

@Component({
	selector: 'cls-toggle-list-item-text',
	template: '<ng-content></ng-content>',
	styleUrls: ['./cls-toggle-list-item.component.scss'],
})
export class ClsToggleListTextComponent {}
