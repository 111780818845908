<div fxLayoutAlign="center center" class="cls-loader-container">
	<ng-lottie @fadeIn [options]="options" width="100px" height="100px"></ng-lottie>
	<ng-container *ngIf="txtMessage">
		<div @fadeIn class="msg" i18n>
			{{ txtMessage }}
			<span class="dots">{{ dots }}</span>
			<span class="hidden-dots">{{ '.'.repeat(3 - dots.length) }}</span>
		</div>
	</ng-container>
</div>
