import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClsCopyLinkComponent } from './cls-copy-link.component';
import { MatIconModule } from '@angular/material/icon';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ClsAlertsModule } from '../cls-alerts/cls-alerts.module';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
	declarations: [ClsCopyLinkComponent],
	imports: [CommonModule, MatIconModule, ClipboardModule, MatTooltipModule, ClsAlertsModule, MatButtonModule],
	exports: [ClsCopyLinkComponent],
})
export class ClsCopyLinkModule {}
