import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import * as shape from 'd3-shape';
import { IPaginateData } from 'ngx-common-v2/components/cls-paginator/models/cls-paginator.models';
import {
	ESubmissionsTableFilterType,
	ESubmissionsTableSortBy,
	IActivityAnalyticsOverview,
	ISubmissionsAnalyticsResponse,
} from '../../models/activity-analytics.models';

import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbItem } from '@ngx-common/services/seo.service';
import { ClsAlertsService } from 'ngx-common-v2/components/cls-alerts/services/cls-alerts.service';
import { downloadDataToCsv } from 'ngx-common-v2/utils/csv_downloader.utils';
import { ClsNgxChartUtils } from 'ngx-common-v2/utils/ngx-charts.utils';
import { EPlatform } from 'shared/enums';
import { AnalyticsDataService } from '../../services/analytics-data.service';
import { EScanStatus } from 'shared/models';
import { AnalyticsConstants } from 'shared/constants/analytics';
import { EAnalyticsLevel } from '@ngx-common-v2/components/analytics-components/models/cls-analytics.models';

@Component({
	selector: 'lms-analytics-activity',
	templateUrl: './analytics-activity.component.html',
	styleUrls: ['./analytics-activity.component.scss'],
})
export class AnalyticsActivityComponent implements OnInit {
	@Input() isLoadFromDashboard = true;
	@Input() token: string;

	integrationId: string;
	activityId: string;
	platformType: EPlatform;
	eLevelType = EAnalyticsLevel;

	dataSrc: IActivityAnalyticsOverview;

	lineChartCustomColors = [
		{ name: $localize`AI TEXT`, value: '#C1ADDC' },
		{ name: $localize`MATCHED TEXT`, value: '#FD7366' },
	];

	tableViewData: ISubmissionsAnalyticsResponse[] = [];
	breadCrumbs: BreadcrumbItem[];

	tableDataSource: MatTableDataSource<ISubmissionsAnalyticsResponse>;

	minDefaultPageSize = 10;
	paginateState: IPaginateData = {
		pageIndex: 1,
		preIndex: 0,
		pageSize: this.minDefaultPageSize,
	};

	xAxisLabel: string = $localize`Submissions`;
	correctionText: string = $localize`Correction`;
	searchStr: string = '';

	cheatsCounter: number = 0;
	lateSubmissionsCounter: number = 0;
	studentCountNumber: number = 0;

	ePlatformType = EPlatform;
	eSortBy = ESubmissionsTableSortBy;
	eFilterByTableFilter = ESubmissionsTableFilterType;
	eChosenFilter: ESubmissionsTableFilterType = ESubmissionsTableFilterType.All;
	eScanStatus = EScanStatus;
	sortColumn: ESubmissionsTableSortBy;

	isLoading: boolean;
	isTalbeAscending: boolean;
	isTableEmpty: boolean;
	disableSearchInput: boolean;
	isFilterPicked: boolean;
	hasNoFilterResults: boolean;
	isLoadingReportPage: boolean;
	linearCurve = shape.curveBumpX;
	view: number[];
	writingFeedbackChartView: number[];
	ANALYTICS_COLLECTION_DATE = AnalyticsConstants.ANALYTICS_COLLECTION_DATE;

	/**
	 * For cleaning up the search input.
	 */
	@ViewChild('searchInput') searchInput;

	@ViewChild('insightsItem', { static: false }) insightsItemElementRef: ElementRef<HTMLDivElement>;

	/**
	 * If we can load more data with the paginator.
	 */
	get canLoadMore() {
		return this.tableViewData?.length > this.paginateState.pageSize * this.paginateState.pageIndex;
	}
	/**
	 * Whether or not to show paginator.
	 */
	get showPaginator() {
		return !this.isTableEmpty && this.tableViewData?.length > this.minDefaultPageSize && !this.hasNoFilterResults;
	}

	/**
	 * The columns in the table
	 */
	displayedColumns: string[] = [
		'student_name',
		'submitted_documents',
		'ai_content_detected',
		'plagiarism_score',
		'writingFeedback_score',
		'submission_date',
	];

	loadingMessage = $localize`Loading data...`;

	MOODLE_EULA_TOOLTIP = $localize`The user did not accept EULA for this submission.`;
	MISSING_USER_DATA_TOOLTIP = $localize`Missing data about this user.`;
	HIDE_AUTHOR_ANALYTICS_TOOLTIP = $localize`The integration policy blocks access to student analytics data.`;

	constructor(
		private _analyticsService: AnalyticsDataService,
		private _clsAlertSvc: ClsAlertsService,
		private _route: ActivatedRoute,
		private _router: Router
	) {}

	ngOnInit(): void {
		this.platformType = Number(this._route.snapshot.paramMap.get('platformType'));
		this.integrationId = this._route.snapshot.paramMap.get('integrationId');
		this.activityId = this._route.snapshot.paramMap.get('activityId');

		this.getActivityLevelDataAsync();
	}

	async getActivityLevelDataAsync() {
		try {
			this.isLoading = true;
			this.dataSrc = await this._analyticsService.getActivityData(
				this.platformType,
				this.integrationId,
				this.activityId,
				this.token
			);

			this.tableViewData = this.dataSrc?.tableData;
			if (this.tableViewData?.length) {
				this.tableViewData.forEach(sub => {
					this.cheatsCounter += Number(sub.isCheatDetected);
					this.lateSubmissionsCounter += Number(sub.isLateSubmission);
					this.studentCountNumber += sub.userName ? 1 : 0;
				});

				this.tableDataSource = new MatTableDataSource<ISubmissionsAnalyticsResponse>(
					this.tableViewData?.slice(0, this.paginateState.pageSize)
				);
			} else {
				// In case we have empty table
				this.tableDataSource = new MatTableDataSource<ISubmissionsAnalyticsResponse>();
				this.isTableEmpty = true;
				this.disableSearchInput = true;
				this.setEmptyResultsTable();
			}
			this.setBreadCrumbs();
			if (!this.dataSrc.writingFeedbackFeatureEnabled) {
				this.dataSrc.cardData.avgWritingFeedback.value = 'N/A';
				this.dataSrc.cardData.avgWritingFeedback.isDisabled = true;
			}
		} catch (error) {
			if (!this._analyticsService.redirectOnErroredRequest(error)) this._clsAlertSvc.showSupportError();
		} finally {
			this.isLoading = false;
			if (this.dataSrc && this.dataSrc.isStatisticsExists)
				setTimeout(() => {
					this.onResize(null, this.insightsItemElementRef.nativeElement);
				}, 100);
		}
	}

	/**
	 * filter table view data.
	 * @param by the filter 'query' can be new search string the user entered or a filter user choose.
	 */
	fliterViewResults(by: ESubmissionsTableFilterType | string) {
		if (typeof by === 'string') {
			// set the serach string the user entered
			this.searchStr = by.toLowerCase();
		} else {
			this.isFilterPicked = by === ESubmissionsTableFilterType.All ? false : true;
			this.eChosenFilter = by;
		}
		// Filter the table data by the search string and filter
		if (
			by === ESubmissionsTableFilterType.Cheats ||
			(typeof by === 'string' && this.eChosenFilter == ESubmissionsTableFilterType.Cheats)
		) {
			this.tableViewData = this.dataSrc.tableData.filter(
				column => column.isCheatDetected && this.searchByUserNameAndDocName(column.documentName, column.userName)
			);
		}
		if (
			by === ESubmissionsTableFilterType.LateSubmission ||
			(typeof by === 'string' && this.eChosenFilter == ESubmissionsTableFilterType.LateSubmission)
		) {
			this.tableViewData = this.dataSrc.tableData.filter(
				column => column.isLateSubmission && this.searchByUserNameAndDocName(column.documentName, column.userName)
			);
		}
		if (
			by === ESubmissionsTableFilterType.All ||
			(typeof by === 'string' && this.eChosenFilter == ESubmissionsTableFilterType.All)
		) {
			if (this.searchStr != '' && by === ESubmissionsTableFilterType.All) {
				// In case user picked Show all
				this.searchInput.clearSearchValue();
				this.searchStr = '';
				this.tableViewData = this.dataSrc.tableData;
			} else {
				// Search case
				this.tableViewData = this.dataSrc.tableData.filter(column =>
					this.searchByUserNameAndDocName(column.documentName, column.userName)
				);
			}
		}
		if (!this.tableViewData.length) {
			// In case of empty result set
			this.hasNoFilterResults = true;
			this.setEmptyResultsTable();
		} else {
			this.hasNoFilterResults = false;
			this.resetPaginaotrState();
			this.movePaginator(this.paginateState);
		}
	}
	sortDocuments(by: ESubmissionsTableSortBy) {
		if (!this.isTableEmpty) {
			this.isTalbeAscending = !this.isTalbeAscending;
			this.sortColumn = by;
			switch (by) {
				case ESubmissionsTableSortBy.DocumentName:
					this.tableViewData = this.tableViewData.sort((a, b) => a.documentName.localeCompare(b.documentName));
					break;
				case ESubmissionsTableSortBy.AiScore:
					this.tableViewData = this.tableViewData.sort((a, b) => {
						if (a.aiScore == null) return 1;
						if (b.aiScore == null) return -1;
						if (this.isTalbeAscending) return a.aiScore - b.aiScore;
						return b.aiScore - a.aiScore;
					});
					break;
				case ESubmissionsTableSortBy.PlagiarismScore:
					this.tableViewData = this.tableViewData.sort((a, b) => {
						if (a.plagiarismScore == null) return 1;
						if (b.plagiarismScore == null) return -1;
						if (this.isTalbeAscending) return a.plagiarismScore - b.plagiarismScore;
						return b.plagiarismScore - a.plagiarismScore;
					});
					break;
				case ESubmissionsTableSortBy.SubmissionDate:
					this.tableViewData = this.tableViewData.sort(
						(a, b) => a.submissionDate.getTime() - b.submissionDate.getTime()
					);
					break;
				case ESubmissionsTableSortBy.StudentName:
					this.tableViewData = this.tableViewData.sort((a, b) => a.userName.localeCompare(b.userName));
					break;
				case ESubmissionsTableSortBy.WritingFeedback:
					this.tableViewData = this.tableViewData.sort((a, b) => {
						if (a.writingFeedback == null) return 1;
						if (b.writingFeedback == null) return -1;
						if (this.isTalbeAscending) return a.writingFeedback - b.writingFeedback;
						return b.writingFeedback - a.writingFeedback;
					});
				default:
					break;
			}
			if (
				by !== ESubmissionsTableSortBy.AiScore &&
				by !== ESubmissionsTableSortBy.PlagiarismScore &&
				by !== ESubmissionsTableSortBy.WritingFeedback
			)
				this.tableViewData = this.isTalbeAscending ? this.tableViewData : this.tableViewData.reverse();
			this.resetPaginaotrState();
			this.movePaginator(this.paginateState);
		}
	}

	/**
	 * Reset paginator state.
	 */
	resetPaginaotrState() {
		this.paginateState.pageIndex = 1;
		this.paginateState.preIndex = 0;
	}
	/**
	 * Create empty table view.
	 */
	setEmptyResultsTable() {
		this.tableViewData = Array.from({ length: 11 });
		this.resetPaginaotrState();
		this.movePaginator(this.paginateState);
	}

	movePaginator(paginateData: IPaginateData) {
		if (this.paginateState.pageSize != paginateData.pageSize) {
			// Page size change
			this.tableDataSource.data = this.tableViewData?.slice(0, paginateData.pageSize);
		} else {
			// page Index change.
			const startIdx = paginateData.preIndex * paginateData.pageSize;
			const endIdx = paginateData.pageIndex * paginateData.pageSize;
			// page up
			if (startIdx < endIdx) {
				this.tableDataSource.data = this.tableViewData?.slice(startIdx, endIdx);
			} else {
				// page down
				this.tableDataSource.data = this.tableViewData?.slice(
					(paginateData.pageIndex - 1) * paginateData.pageSize,
					(paginateData.preIndex - 1) * paginateData.pageSize
				);
			}
		}
		this.paginateState = paginateData;
	}

	searchByUserNameAndDocName(userName: string, documentName: string) {
		return (
			documentName?.toLocaleLowerCase().includes(this.searchStr) ||
			this.searchStr.includes(documentName?.toLocaleLowerCase()) ||
			userName?.toLocaleLowerCase().includes(this.searchStr) ||
			this.searchStr.includes(userName?.toLocaleLowerCase())
		);
	}

	setBreadCrumbs() {
		this.breadCrumbs = [
			{
				name: this.dataSrc.activityName,
				url: `lms/${this.platformType}/analytics/${this.integrationId}/activity/${this.activityId}`,
			},
		];
		if (this.dataSrc.contextName && this.dataSrc.showAnalytics) {
			this.breadCrumbs = [
				{
					name: this.dataSrc.contextName,
					url: `lms/${this.platformType}/analytics/${this.integrationId}/course/${this.dataSrc.contextId}`,
				},
				...this.breadCrumbs,
			];
		}
		if (this.isLoadFromDashboard) {
			this.breadCrumbs = [
				{
					name: $localize`Integrations`,
					url: '/lti/v1.3/institues',
				},
				{
					name: this.dataSrc?.integrationName,
					url: `/dashboard/${this.platformType}/analytics/${this.integrationId}`,
				},
				...this.breadCrumbs,
			];
		}
	}

	onResize(event, insightsItem?: HTMLDivElement) {
		this.view = null;
		this.writingFeedbackChartView = null;
		setTimeout(() => {
			if (insightsItem) {
				this.view = [insightsItem.offsetWidth + 70, insightsItem.offsetHeight - 80];
				let writingFeedbackChartViewHeight = insightsItem.offsetHeight;
				if (insightsItem.offsetWidth <= 572 && insightsItem.offsetWidth >= 512) writingFeedbackChartViewHeight += 60;
				if (insightsItem.offsetWidth < 512) writingFeedbackChartViewHeight += 100;
				this.writingFeedbackChartView = [insightsItem.offsetWidth - 40, writingFeedbackChartViewHeight - 50];
			}
			setTimeout(() => {
				this.checkGraphsData();
			}, 100);
		}, 100);
	}

	checkGraphsData() {
		const el = ClsNgxChartUtils.GetChartsLineSeriesPathElementsRef();
		// Checking if graphs have only one dot
		if (this.dataSrc?.chartData[0]?.series?.length === 1) {
			ClsNgxChartUtils.setGraphPointStroke(el, 0);
		}
		if (this.dataSrc?.chartData[1]?.series?.length === 1) {
			ClsNgxChartUtils.setGraphPointStroke(el, 1);
		}
	}

	navigateWithBreadCrumb(breadCrumbItem: BreadcrumbItem) {
		if (breadCrumbItem.url != '/') this._router.navigate([breadCrumbItem.url]);
	}

	/**
	 * Download documents
	 */
	download() {
		const dataToExport = this.tableViewData.map(s => {
			return {
				Student: s.userName,
				'Submitted documents': s.documentName.replace(/,/g, ' '),
				'AI Content Detected':
					s.aiScore != null && s.scanStatus === EScanStatus.Completed
						? s.aiScore
						: s.errorMessage
						? $localize`Failed Scan`
						: s.scanStatus === EScanStatus.InProgress
						? $localize`---`
						: $localize`Disabled`,
				'Plagiarism Score':
					s.plagiarismScore != null && s.scanStatus === EScanStatus.Completed
						? s.plagiarismScore
						: s.errorMessage
						? $localize`Failed Scan`
						: s.scanStatus === EScanStatus.InProgress
						? $localize`---`
						: $localize`Disabled`,
				'Writing Correction':
					s.writingFeedback != null &&
					s.scanStatus === EScanStatus.Completed &&
					this.dataSrc.writingFeedbackFeatureEnabled
						? s.writingFeedback
						: s.errorMessage
						? $localize`Failed Scan`
						: s.scanStatus === EScanStatus.InProgress
						? $localize`---`
						: $localize`Disabled`,
				'Submission Date': s.submissionDate,
			};
		});
		downloadDataToCsv(dataToExport, 'Sources_Data', 'MMM Do YYYY h:mm a');
	}
	async openReportPage(item: ISubmissionsAnalyticsResponse) {
		try {
			if (item.isReportPageLoading) return;

			item.isReportPageLoading = true;
			await this._analyticsService.requestAccessToReportPage(
				this.platformType,
				this.integrationId,
				this.activityId,
				item.scanId,
				this.token,
				this.isLoadFromDashboard
			);
		} catch (error) {
			this._clsAlertSvc.showSupportError();
		} finally {
			item.isReportPageLoading = false;
		}
	}
}
