<ng-template #buttonImgContent>
	<ng-content select="cls-button-image"></ng-content>
</ng-template>
<ng-template #buttonTextContent>
	<ng-content select="cls-button-text"></ng-content>
</ng-template>
<ng-template #buttonContent>
	<div fxLayout="row" fxLayoutAlign="center center" [fxLayoutGap]="onlyIcon ? '0px' : '10px'">
		<div class="button-img-container" fxLayoutAlign="center center">
			<ng-container *ngTemplateOutlet="buttonImgContent"></ng-container>
		</div>
		<div class="button-text-container" fxLayoutAlign="center center">
			<ng-container *ngTemplateOutlet="buttonTextContent"></ng-container>
		</div>
		<cls-spinner *ngIf="showSpinner" size="20px"></cls-spinner>
	</div>
</ng-template>
<div class="tooltip-container" fxLayout="row" fxLayoutAlign="start center" [matTooltip]="buttonTooltip" i18n-matTooltip>
	<ng-container *ngIf="!outlined; else outlinedButton">
		<ng-container *ngIf="!onlyIcon; else onlyIconOutlinedButton">
			<button
				mat-raised-button
				[ngStyle]="buttonStyle"
				[class]="filledButtonColorClassName + ' background-transition'"
				[color]="color"
				[attr.aria-label]="ariaLabel"
				[disabled]="disabled || showSpinner"
				i18n-aria-label>
				<ng-container *ngTemplateOutlet="buttonContent"></ng-container>
			</button>
		</ng-container>
		<ng-template #onlyIconOutlinedButton>
			<button
				mat-icon-button
				[ngStyle]="buttonStyle"
				[class]="iconButtonColorClassName + ' background-transition'"
				[color]="color"
				[attr.aria-label]="ariaLabel"
				[disabled]="disabled || showSpinner"
				i18n-aria-label>
				<ng-container *ngTemplateOutlet="buttonContent"></ng-container>
			</button>
		</ng-template>
	</ng-container>

	<!-- Outlined button -->
	<ng-template #outlinedButton>
		<ng-container *ngIf="!onlyIcon; else onlyIconOutlinedButton">
			<button
				mat-button
				[class]="outlinedButtonColorClassName + ' background-transition'"
				[ngStyle]="buttonStyle"
				[color]="color"
				[attr.aria-label]="ariaLabel"
				[disabled]="disabled || showSpinner"
				i18n-aria-label>
				<ng-container *ngTemplateOutlet="buttonContent"></ng-container>
			</button>
		</ng-container>
		<ng-template #onlyIconOutlinedButton>
			<button
				mat-icon-button
				[ngStyle]="buttonStyle"
				[class]="iconButtonColorClassName + ' background-transition'"
				[color]="color"
				[attr.aria-label]="ariaLabel"
				[disabled]="disabled || showSpinner"
				i18n-aria-label>
				<ng-container *ngTemplateOutlet="buttonContent"></ng-container>
			</button>
		</ng-template>
	</ng-template>
</div>
