<div class="table-container">
	<div class="table-wrapper">
		<div class="table-toolbar-container" fxLayout="row" fxLayoutAlign="space-between center">
			<div class="table-title" i18n>Submissions</div>
			<div
				i18n-matTooltip
				[matTooltip]="!isTableEmpty ? EXPORT_DATA_TOOLTIP : ''"
				[ngClass]="{
					'table-toolbar-actions': true,
					'table-toolbar-actions-disabled': isTableEmpty
				}"
				fxLayout="row"
				fxLayoutAlign="center center"
				fxLayoutGap="4px">
				<button
					class="icon-button export-data-text"
					mat-icon-button
					i18n-matTooltip
					matTooltip="Export to manage data in third-party software."
					(click)="isTableEmpty || isLoading ? '' : exortTableData()"
					aria-label="Export data."
					i18n>
					<mat-icon
						[ngClass]="{
							'table-filter-icon': true,
							'disabled-icon': isTableEmpty
						}"
						fontSet="material-icons-round">
						download
					</mat-icon>
					Export Data
				</button>
			</div>
		</div>
		<table
			mat-table
			[dataSource]="tableDataSource"
			[style]="
				!showPaginator ? 'border-bottom-left-radius:10px!important;border-bottom-right-radius:10px!important' : ''
			">
			<!-- Submitted documents -->
			<ng-container matColumnDef="submitted_documents">
				<th mat-header-cell *matHeaderCellDef>
					<span i18n>Submitted documents</span>
				</th>
				<td mat-cell *matCellDef="let element">
					<div
						(click)="
							element?.scanStatus === eScanStatus.Completed && !element.isReportPageLoading
								? openReportPageAsync(element)
								: ''
						"
						[class]="
							element.contextId ? 'open-icon-and-name-container-active' : 'open-icon-and-name-container-disabled'
						"
						i18n-matTooltip
						[ngClass]="{
							'document-name-icon-disable': element?.scanStatus !== eScanStatus.Completed
						}"
						class="open-icon-and-name-container"
						fxLayout="row"
						fxLayoutGap="4px"
						*ngIf="!isLoading; else loadingSkeleton"
						fxLayoutAlign="start"
						i18n-matTooltip
						[matTooltip]="
							!element?.documentName && platformType == ePlatformType.Moodle
								? NOT_ACCEPT_EULA_TOOLTIP
								: element.scanId
								? VIEW_STUDENT_REPORT_TOOLTIP
								: SECURITY_PREMISSION_TOOLTIP
						">
						<ng-container *ngIf="!element?.isReportPageLoading">
							<mat-icon *ngIf="!isTableEmpty && element.scanId" class="open-icon">open_in_new</mat-icon>
						</ng-container>
						<ng-container *ngIf="element?.isReportPageLoading">
							<cls-spinner size="24px"></cls-spinner>
						</ng-container>
						<button
							*ngIf="element?.documentName"
							mat-button
							class="icon-button td-name-text"
							aria-label="opens report page">
							{{ element?.documentName }}
						</button>
					</div>
				</td>
			</ng-container>

			<!-- Assignment -->
			<ng-container matColumnDef="assignment">
				<th mat-header-cell *matHeaderCellDef>
					<span i18n>Assignment</span>
				</th>
				<td mat-cell *matCellDef="let element">
					<ng-container *ngIf="element?.activityId">
						<a
							*ngIf="!isLoading; else loadingSkeleton"
							[routerLink]="
								!isTableEmpty
									? ['/lms', platformType, 'analytics', integrationId, 'activity', element?.activityId]
									: null
							">
							<div
								class="icon-button open-icon-and-name-container td-name-text open-icon-and-name-container-active"
								i18n-matTooltip
								[matTooltip]="VIEW_ACTIVITY_ANALYTICS_TOOLTIP">
								<mat-icon mat-icon-button *ngIf="!isTableEmpty && element.activityId" class="open-icon">
									open_in_new
								</mat-icon>
								<div class="long-text">
									{{ element?.activityName }}
								</div>
							</div>
						</a>
					</ng-container>
					<ng-container *ngIf="!element?.activityId">
						<div
							*ngIf="!isLoading; else loadingSkeleton"
							class="icon-button open-icon-and-name-container td-name-text open-icon-and-name-container-disabled"
							i18n-matTooltip
							[matTooltip]="SECURITY_PREMISSION_TOOLTIP">
							<mat-icon mat-icon-button *ngIf="!isTableEmpty && element.activityId" class="open-icon">
								open_in_new
							</mat-icon>
							<div class="long-text">
								{{ element?.activityName }}
							</div>
						</div>
					</ng-container>
				</td>
			</ng-container>

			<!-- Course -->
			<ng-container matColumnDef="course">
				<th mat-header-cell *matHeaderCellDef>
					<span i18n>Course</span>
				</th>
				<td mat-cell *matCellDef="let element">
					<ng-container *ngIf="element?.contextId">
						<a
							*ngIf="!isLoading; else loadingSkeleton"
							[routerLink]="
								isTableEmpty ? [] : ['/lms', platformType, 'analytics', integrationId, 'course', element?.contextId]
							"
							aria-label="Opens course analytics">
							<div
								class="icon-button open-icon-and-name-container td-name-text open-icon-and-name-container-active"
								i18n-matTooltip
								[matTooltip]="VIEW_COURSE_ANALYTICS_TOOLTIP">
								<mat-icon
									*ngIf="
										!isTableEmpty &&
										element.contextId &&
										(element?.contextName || platformType === ePlatformType.Canvas)
									"
									class="open-icon">
									open_in_new
								</mat-icon>
								<div class="long-text" *ngIf="element?.contextName && platformType != ePlatformType.Canvas">
									{{ element?.contextName }}
								</div>
								<ng-container *ngIf="platformType === ePlatformType.Canvas && !isTableEmpty" i18n>
									View Course Analytics
								</ng-container>
							</div>
						</a>
					</ng-container>
					<ng-container *ngIf="!element?.contextId">
						<div
							*ngIf="!isLoading; else loadingSkeleton"
							class="icon-button open-icon-and-name-container td-name-text open-icon-and-name-container-disabled"
							i18n-matTooltip
							[matTooltip]="SECURITY_PREMISSION_TOOLTIP">
							<div class="long-text" *ngIf="element?.contextName && platformType != ePlatformType.Canvas">
								{{ element?.contextName }}
							</div>
							<ng-container *ngIf="platformType === ePlatformType.Canvas && !isTableEmpty" i18n>
								View Course Analytics
							</ng-container>
						</div>
					</ng-container>
				</td>
			</ng-container>

			<!-- Ai Score -->
			<ng-container matColumnDef="ai_content_detected">
				<th mat-header-cell *matHeaderCellDef>
					<span fxLayout="row" fxLayoutAlign="center">AI content detected</span>
				</th>
				<td mat-cell *matCellDef="let element">
					<cls-score-level
						[isAPIPropertyEnabled]="
							element?.aiScore != null || element?.scanStatus === eScanStatus?.InProgress || isLoading || isTableEmpty
						"
						*ngIf="element?.scanStatus != eScanStatus?.Error || isLoading"
						[isLoading]="element?.scanStatus === eScanStatus?.InProgress || isLoading"
						[score]="element?.aiScore"></cls-score-level>
				</td>
			</ng-container>

			<!-- Plagiarism Score -->
			<ng-container matColumnDef="plagiarism_score">
				<th mat-header-cell *matHeaderCellDef>
					<span fxLayout="row" fxLayoutAlign="center" i18n>Plagiarism score</span>
				</th>
				<td mat-cell *matCellDef="let element">
					<cls-score-level
						[isAPIPropertyEnabled]="
							element?.plagiarismScore != null ||
							element?.scanStatus === eScanStatus?.InProgress ||
							isLoading ||
							isTableEmpty
						"
						*ngIf="element?.scanStatus != eScanStatus?.Error || isLoading"
						[isLoading]="element?.scanStatus === eScanStatus?.InProgress || isLoading"
						[score]="element?.plagiarismScore"></cls-score-level>
				</td>
			</ng-container>
			<!-- Writing Corrections -->
			<ng-container matColumnDef="writingFeedback_score">
				<th mat-header-cell *matHeaderCellDef>
					<span fxLayout="row" fxLayoutAlign="center" i18n>Writing corrections</span>
				</th>
				<td mat-cell *matCellDef="let element">
					<div
						class="td-writingFeedback-score"
						[ngClass]="{ 'writing-feedback-score-disabled': !writingFeedbackFeatureEnabled }">
						<lms-writing-feedback-score
							[score]="element?.writingFeedback"
							[isLoading]="
								element?.scanStatus == eScanStatus.PriceChecked ||
								element?.scanStatus === eScanStatus?.InProgress ||
								isLoading
							"
							[isAPIPropertyEnabled]="
								element?.writingFeedback != null ||
								element?.scanStatus === eScanStatus?.InProgress ||
								isLoading ||
								isTableEmpty
							"
							[isFeatureEnabled]="writingFeedbackFeatureEnabled && !isTableEmpty"
							[isFailedScan]="element?.scanStatus === eScanStatus?.Error"></lms-writing-feedback-score>
					</div>
					<div
						*ngIf="element?.scanStatus === eScanStatus?.Error && !isLoading && writingFeedbackFeatureEnabled"
						fxLayout="row"
						fxLayoutAlign="center center">
						<mat-chip class="mat-chip-error error-chip" [matTooltip]="element?.errorMessage" role="status" i18n>
							FAILED SCAN
						</mat-chip>
					</div>
				</td>
			</ng-container>

			<!-- Due Date -->
			<ng-container matColumnDef="submission_date">
				<th mat-header-cell *matHeaderCellDef>
					<div fxLayout="row" fxLayoutGap="8px">
						<span i18n>Submission date</span>
						<button
							mat-icon-button
							class="icon-button icon-button-with-border"
							[disabled]="isTableEmpty"
							(click)="isTableEmpty || isLoading ? '' : sortBySubmissionDate()"
							i18n-matTooltip
							matTooltip="Sort"
							aria-label="Sort">
							<mat-icon
								fontSet="material-icons-round"
								class="table-sort-icon"
								[style.color]="isTableEmpty ? 'grey' : ''"
								[ngClass]="{
									'table-sort-icon-selected-disabled': isTableEmpty,
									'table-sort-icon-selected-desc': !isTalbeAscending && eSortColumn === eSortBy.SubmissionDate,
									'table-sort-icon-selected-asc': isTalbeAscending && eSortColumn === eSortBy.SubmissionDate
								}">
								sort
							</mat-icon>
						</button>
					</div>
				</th>
				<td mat-cell *matCellDef="let element">
					<div style="text-align: start" *ngIf="!isLoading; else loadingSkeleton">
						{{ element?.submissionDate | date: 'medium' }}
					</div>
				</td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
		</table>

		<ng-container *ngIf="isTableEmpty && !isLoading">
			<cls-contained-content class="empty-message" [isTextCentered]="true">
				<cls-contained-title>
					<div i18n>No Submissions Found</div>
				</cls-contained-title>
				<cls-contained-description>
					<div fxLayout="column" fxLayoutGap="32px" fxLayoutAlign="center center">
						<div i18n>Submit A Document To See Data.</div>
					</div>
				</cls-contained-description>
			</cls-contained-content>
		</ng-container>
	</div>

	<cls-paginator
		[pageIndex]="paginateState.pageIndex"
		(page)="movePaginator($event)"
		[pageSize]="paginateState.pageSize"
		[canLoadMoreData]="canLoadMore"
		[pageSizeOptions]="pageSizeOptions"
		[showPaginator]="showPaginator"
		[receivedDataLength]="tableTotalDataLength"></cls-paginator>
</div>

<ng-template #loadingSkeleton>
	<ngx-skeleton-loader
		count="1"
		animation="progress"
		appearance="line"
		[theme]="{
			'background-color': 'rgba(17, 41, 96, 0.3)',
			height: '18px',
			width: '60%',
			top: '10px',
			'border-radius': '10px'
		}"></ngx-skeleton-loader>
</ng-template>
