<div class="notification-container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="17px">
	<div class="unread-Notification-badge">
		<div
			class="notification-icon-contianer"
			[matBadge]="!isRead ? '1' : ''"
			matBadgePosition="above before"
			matBadgeColor="peach"
			MatBadgeSize="small">
			<mat-icon>
				{{ matIcon ?? 'payments' }}
			</mat-icon>
		</div>
	</div>
	<div class="notification-message-contianer" fxLayout="column" fxLayoutAlign="center start">
		<ng-container *ngIf="title">
			<div class="notification-message-title">{{ title }}</div>
		</ng-container>
		<ng-container *ngIf="description">
			<div class="notification-message-description">{{ description }}</div>
		</ng-container>
	</div>
</div>
