<form [formGroup]="frmContactUS" (ngSubmit)="onFormSubmit(captcha)">
	<div class="contact-us-form-container" fxLayout="column">
		<div fxLayout="row nowrap" fxLayout.lt-md="column" fxLayoutGap="15px">
			<mat-form-field appearance="outline" fxFlex>
				<mat-label i18n>First name</mat-label>
				<input
					matInput
					maxlength="64"
					type="text"
					i18n-placeholder
					formControlName="firstName"
					placeholder="First name" />
				<mat-error i18n>This field is required</mat-error>
			</mat-form-field>

			<mat-form-field full-width fxFlex appearance="outline">
				<mat-label i18n>Last name</mat-label>
				<input
					type="text"
					maxlength="64"
					i18n-placeholder
					formControlName="lastName"
					matInput
					placeholder="Last name" />
				<mat-error i18n>This field is required</mat-error>
			</mat-form-field>
		</div>
		<mat-form-field appearance="outline">
			<mat-label i18n>Institution name</mat-label>
			<input
				i18n-placeholder
				formControlName="companyName"
				maxlength="100"
				type="text"
				matInput
				placeholder="Institution name" />
		</mat-form-field>
		<mat-form-field appearance="outline">
			<mat-label i18n>Estimated number of students</mat-label>
			<input
				i18n-placeholder
				formControlName="numberOfUsers"
				type="number"
				matInput
				placeholder="Estimated number of users" />
		</mat-form-field>
		<mat-form-field appearance="outline">
			<mat-label i18n>Phone number</mat-label>
			<input
				formControlName="phoneNumber"
				autocomplete="off"
				class="input-no-action"
				type="number"
				matInput
				i18n-placeholder
				placeholder="Phone number" />
		</mat-form-field>
		<mat-form-field appearance="outline">
			<mat-label i18n>Email</mat-label>
			<input i18n-placeholder formControlName="email" type="email" maxlength="256" matInput placeholder="Email" />
			<mat-error>{{ getEmailErrorMessageKey() }}</mat-error>
		</mat-form-field>
		<mat-form-field appearance="outline">
			<mat-label i18n>Description</mat-label>
			<textarea
				i18n-placeholder
				formControlName="description"
				matInput
				maxlength="2000"
				placeholder="Description"
				rows="13"></textarea>
			<mat-error i18n>This field is required</mat-error>
		</mat-form-field>
		<div>
			<cls-copyleaks-captcha
				formControlName="captcha"
				#captcha
				[errorText]="captchaErrorTransCode"></cls-copyleaks-captcha>
		</div>
	</div>
	<div text-center>
		<cls-spinner-button type="submit" [showSpinner]="showButtonSpinner" spinnerDiameter="45">
			<div class="btn-send-content">
				{{ SEND }}
			</div>
		</cls-spinner-button>
	</div>
</form>
