import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UnPauseSubscriptionDialogComponent } from './unpause-subscirption-dialog.component';
import { SpinnerButtonModule } from '@ngx-common/components/spinner-button/spinner-button.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { ClsButtonModule } from '@ngx-common-v2/components/cls-button/cls-button.module';
import { SpinnerModule } from '@ngx-common/components/spinner/spinner.module';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
	declarations: [UnPauseSubscriptionDialogComponent],
	exports: [UnPauseSubscriptionDialogComponent],
	imports: [
		CommonModule,
		FlexLayoutModule,
		SpinnerButtonModule,
		MatButtonModule,
		ClsButtonModule,
		MatIconModule,
		SpinnerModule,
	],
})
export class UnPauseSubscriptionDialogModule {}
