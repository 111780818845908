<div class="mat-typography wrapper">
	<div fxLayout="row" mat-dialog-title>
		<h1>{{ data.title }}</h1>
		<span fxFlex></span>
		<mat-icon color="warn">warning</mat-icon>
	</div>
	<div mat-dialog-content fxLayout="column">
		<div style="margin-bottom: 1em">{{ data.message }}</div>
		<div style="margin-bottom: 1em">
			<span i18n>This action cannot be undone. This will permanently delete</span>
			{{ data.item.type }} '{{ data.item.expect }}'
			<span i18n>and its contents.</span>
		</div>
		<div>
			<span i18n>Type the name of the</span>
			{{ data.item.type }} ({{ data.item.expect }})
			<span i18n>to confirm:</span>
		</div>
		<div>
			<mat-form-field>
				<input matInput type="text" name="userInput" autocomplete="off" [(ngModel)]="input" />
			</mat-form-field>
		</div>
	</div>
	<div mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between center">
		<button mat-raised-button [mat-dialog-close]="false" i18n>No</button>
		<button
			mat-raised-button
			color="warn"
			[disabled]="input !== data.item.expect"
			progress-button
			(pbclick)="onYesClick($event)"
			i18n>
			Yes
		</button>
	</div>
</div>
