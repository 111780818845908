import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalloutComponent, CalloutTitle } from './callout-component/callout.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
	declarations: [CalloutComponent, CalloutTitle],
	exports: [CalloutComponent, CalloutTitle],
	imports: [CommonModule, MatIconModule],
})
export class CalloutModule {}
