import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CopyleaksCaptchaComponent } from './copyleaks-captcha-component/copyleaks-captcha.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
	declarations: [CopyleaksCaptchaComponent],
	exports: [CopyleaksCaptchaComponent],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MatCheckboxModule,
		FlexLayoutModule,
		MatFormFieldModule,
		MatInputModule,
		MatIconModule,
		MatButtonModule,
		MatProgressSpinnerModule,
		MatTooltipModule,
	],
})
export class CopyleaksCaptchaModule {}
