<div class="avatar-container" fxLayout="row" fxFlexAlign="start" fxLayoutGap="30px">
	<div class="avatar-with-icon" fxLayout="row">
		<cls-avatar-icon
			[id]="entityAvatar.id"
			[icon]="entityAvatar.icon"
			[backgroundColor]="entityAvatar.iconBgColor"
			[iconSize]="'90px'"
			[iconContainerSize]="'100px'"></cls-avatar-icon>
		<!-- <ngx-avatar
			[gravatarId]="entityAvatar?.email"
			[name]="entityAvatar?.name ? entityAvatar.name : entityAvatar.email"
			[size]="90"></ngx-avatar> -->
		<mat-icon class="remove-icon">do_not_disturb</mat-icon>
	</div>
	<div class="avatar-remove-headline">
		<div style="font-weight: bold">Remove</div>
		<div *ngIf="entityAvatar?.disaplyName" style="font-weight: 500">{{ entityAvatar.name }}?</div>
	</div>
</div>
