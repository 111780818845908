import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ClsAlertsService } from '@ngx-common-v2/components/cls-alerts/services/cls-alerts.service';
import { AllPersonalNotificationsService } from '../../../notifications/services/all-personal-notifications.service';
import { ENotificationType, IPersonalNotification } from '../web-notification';
@UntilDestroy()
@Component({
	selector: 'cls-notification-container',
	templateUrl: './notification-container.component.html',
	styleUrls: ['./notification-container.component.scss'],
})
export class NotificationContainerComponent implements OnInit {
	notifications: IPersonalNotification[] = [];
	canLoadMoreData: boolean = true;
	isLoadingMoreData: boolean = false;
	isDeletingNotification: boolean;
	showSpinner: boolean = true;
	eNotificationType = ENotificationType;
	isDeletingAllNotifications: boolean;

	// notifications read/clear all dates
	lastClearAllNotificationsDate: Date;
	lastReadAllNotificationsDate: Date;

	constructor(
		@Inject(PLATFORM_ID) private _platformId: object,
		private _notificationsSvc: AllPersonalNotificationsService,
		private _clsAlertsSvc: ClsAlertsService
	) {}

	ngOnInit(): void {
		this._notificationsSvc.showLoadingSpinner$.pipe(untilDestroyed(this)).subscribe(value => {
			this.showSpinner = value;
		});
		this._notificationsSvc.notifications$.pipe(untilDestroyed(this)).subscribe(data => {
			this.notifications = data;
			this.canLoadMoreData = this._notificationsSvc.canLoadMoreNotifications;
		});

		//#region notifications read/clear all dates listeners
		this._notificationsSvc.lastClearAndReadNotifications$.pipe(untilDestroyed(this)).subscribe(value => {
			this.lastClearAllNotificationsDate = value.lastClear;
			this.lastReadAllNotificationsDate = value.lastRead;
		});
		//#endregion
	}

	// remove notification with the specified id from the notifications list
	async onCloseNotificationAsync(notificationId: string) {
		try {
			await this._notificationsSvc.deleteNotificationAsync(notificationId);
			this.notifications = this.notifications.filter(notification => notification.id !== notificationId);
			this._notificationsSvc.notifications = this.notifications;
		} catch (err) {
			this._clsAlertsSvc.showHttpResponseError(err);
			this.isDeletingNotification = false;
		}
	}

	async onClearAllNotificationsAsync() {
		try {
			this.isDeletingAllNotifications = true;
			await this._notificationsSvc.deleteCurrentNotificationAsync();
		} catch (err) {
			this._clsAlertsSvc.showHttpResponseError(err);
			this.isDeletingAllNotifications = false;
		}
	}

	onPageScroll(e) {
		if (!this.canLoadMoreData || this.showSpinner) {
			return;
		}

		const tableViewHeight = e.target.offsetHeight; // viewport: ~500px
		const tableScrollHeight = e.target.scrollHeight; // length of all notifications container
		const scrollLocation = e.target.scrollTop; // how far user scrolled

		// If the user has scrolled within 50px of the bottom, add more data
		const buffer = 50;
		const limit = tableScrollHeight - tableViewHeight - buffer;
		if (scrollLocation > limit && !this.isLoadingMoreData) {
			this._notificationsSvc.loadNotificationsAsync();
		}
	}
}
