<div fxLayot="row" fxLayoutAlign="center center">
	<button
		[disableRipple]="true"
		mat-button
		class="btn-container"
		[matMenuTriggerFor]="mobileNotificationsMenu"
		aria-label="notifications"
		(menuClosed)="notificationsMenuClosedAsync()">
		<div fxLayout="column" fxLayoutAlign="center center" class="btn-content">
			<div class="icon-container">
				<mat-icon
					[matBadgeHidden]="unreadNotificationsCount == 0"
					matBadge="&#8288;"
					matBadgePosition="above after"
					matBadgeSize="small"
					matBadgeColor="peach"
					class="icon">
					notifications
				</mat-icon>
			</div>
			<span class="text" i18n>{{ text }}</span>
		</div>
	</button>
</div>

<!-- Notifications menu  -->
<mat-menu
	#mobileNotificationsMenu="matMenu"
	(close)="changeUnreadItemsToZero()"
	class="navbar-advanced-menu"
	aria="Notifications Menu">
	<cls-notification-container (click)="$event.stopPropagation()"></cls-notification-container>
</mat-menu>
