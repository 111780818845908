import {
	Component,
	EventEmitter,
	forwardRef,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import {
	AbstractControl,
	ControlValueAccessor,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	ValidationErrors,
	Validator,
} from '@angular/forms';
import { ClsAlertsService } from '@ngx-common-v2/components/cls-alerts/services/cls-alerts.service';
import { Subject } from 'rxjs';
import { EPaymentServiceSteps } from '../../../models/pricing.model';
import { Stripe } from '../stripe';
import { StripeCVCNumber } from '../stripe/stripe-card/stripe-card-cvc.component';
import { StripeExpiryNumber } from '../stripe/stripe-card/stripe-card-expiry.component';
import { StripeCardNumber } from '../stripe/stripe-card/stripe-card-number.component';
import { PaymentMethod } from '../stripe/stripe-definitions/payment-method';

@Component({
	selector: 'cls-credit-card',
	styleUrls: ['./credit-card.component.scss'],
	templateUrl: `credit-card.component.html`,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => CreditCardComponent),
			multi: true,
		},
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => CreditCardComponent),
			multi: true,
		},
	],
})
export class CreditCardComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator, OnChanges {
	@ViewChild('creditCard') creditCard: StripeCardNumber;
	@ViewChild('expiry') expiry: StripeExpiryNumber;
	@ViewChild('cvc') cvc: StripeCVCNumber;
	@ViewChild('f') form;
	@ViewChild('submitBtn') submitBtn;

	@Input() havePaymentMethod = false;
	@Input() formSubmitted = false;
	@Input() showUpdateBtn = false;
	@Input() newDesign = false;
	@Input() showTitle = true;
	@Input() currentPaymentStep: EPaymentServiceSteps;
	@Input() showUpdateBtnSpinner = false;
	@Input() disabledForm = false;

	@Output() dataInit = new EventEmitter();

	cardModel: any;
	expiryModel: any;
	cvcModel: any;
	cardHolderModel: any;
	isFormReady = false;
	formHasInvalidValuesAfterSubmit = false;

	newPaymentMethod: ICreditCardValue;
	private _unsub = new Subject();
	updateParentControllerValue: (value: ICreditCardValue) => void;

	get isStripLoaded() {
		return !!window ? (window as any).Stripe : undefined;
	}

	constructor(private _stripe: Stripe, private _clsAlertsSvc: ClsAlertsService) {}

	async ngOnInit() {}

	async ngOnChanges() {
		const isFormValid = await this.isValidComponent();
		if (this.disabledForm && !isFormValid) {
			this.disabledForm = false;
		}

		if (this.formSubmitted) {
			await this.valueChange(true);
		}
	}

	isControlInvalid(control: any) {
		if (control?.value?.error && (!control?.value?.empty || this.formHasInvalidValuesAfterSubmit)) return true;
		return false;
	}

	isFocus(control: any) {
		if (control?.focused) return true;
		return false;
	}

	isCardHolderNameValid() {
		if (!this.cardHolderModel && this.formHasInvalidValuesAfterSubmit) return false;
		return true;
	}

	async createPaymentMethod() {
		this.submitBtn.nativeElement.click();
		try {
			const creditCard = (await this._stripe.createPaymentMethod({
				type: 'card',
				billing_details: {
					name: this.cardHolderModel,
				},
				card: this.creditCard.element as any,
			})) as any;
			const isValid = this.isValidComponent();
			if (!isValid) return null;
			if (creditCard?.error) {
				this._clsAlertsSvc.showCustomError(creditCard?.error?.message);
				this.showUpdateBtnSpinner = false;
				this.currentPaymentStep = null;
				this.disabledForm = false;
				this.formSubmitted = false;
				this.clear();
				return null;
			}
			return creditCard;
		} catch (err) {
			this._clsAlertsSvc.showHttpResponseError(err);
			this.showUpdateBtnSpinner = false;
			this.currentPaymentStep = null;
			this.disabledForm = false;
			this.formSubmitted = false;
			this.clear();
			return null;
		}
	}

	async valueChange(updateBtnClicked = false) {
		if (updateBtnClicked) this.currentPaymentStep = EPaymentServiceSteps.UpdatePaymentMethod;
		const isFormValid = await this.isValidComponent();
		if (!updateBtnClicked && !isFormValid) {
			this.formHasInvalidValuesAfterSubmit = true;
			this.updateParentControllerValue(null);
			return;
		}

		if (
			this.updateParentControllerValue != null &&
			(!this.showUpdateBtn || updateBtnClicked) &&
			!this.showUpdateBtnSpinner &&
			!this.disabledForm
		) {
			if (isFormValid && this.showUpdateBtn) this.showUpdateBtnSpinner = true;
			this.newPaymentMethod = await this.createPaymentMethod();
			this.updateParentControllerValue(this.newPaymentMethod != null ? this.newPaymentMethod : null);
		}
	}

	clear() {
		if (!this.cardHolderModel) return;
		this.cardHolderModel = null;
		this.creditCard.clear();
		this.expiry.clear();
		this.cvc.clear();
	}

	async formReady(event) {
		this.isFormReady = true;
		this.dataInit.emit(true);
		setTimeout(() => {
			this.creditCard.focus();
		}, 500);
	}

	//#region Value Accessor
	writeValue(obj: any): void {}
	registerOnChange(fn: any): void {
		this.updateParentControllerValue = fn;
	}
	registerOnTouched(fn: any): void {}
	setDisabledState?(isDisabled: boolean): void {}
	//#endregion Value Accessor
	//#region validators
	validate(control: AbstractControl): ValidationErrors {
		if (this.creditCard) return this.creditCard.error && this.expiry.error && this.cvc.error;
	}
	registerOnValidatorChange?(fn: () => void): void {}
	//#endregion validators

	ngOnDestroy(): void {
		this._unsub.next(null);
		this._unsub.complete();
	}

	isValidComponent() {
		return (
			this.creditCard &&
			this.creditCard.value &&
			!this.creditCard.value.empty &&
			this.creditCard.value.complete &&
			!this.creditCard.value.error &&
			this.expiry.value &&
			!this.expiry.value.empty &&
			this.expiry.value.complete &&
			!this.expiry.value.error &&
			this.cvc.value &&
			!this.cvc.value.empty &&
			this.cvc.value.complete &&
			!this.cvc.value.error &&
			this.cardHolderModel
		);
	}
}

export interface ICreditCardValue {
	paymentMethod: PaymentMethod;
}
