// tslint:disable: no-use-before-declare
import {
	Component,
	ComponentFactoryResolver,
	ElementRef,
	EventEmitter,
	HostListener,
	Inject,
	OnInit,
	ViewChild,
	ViewContainerRef,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ClsAlertsService } from '@ngx-common-v2/components/cls-alerts/services/cls-alerts.service';
import { take } from 'rxjs';
import {
	CopyleaksCaptchaComponent,
	ICopyLeaksCaptchaControlValue,
} from '../../../components/copyleaks-captcha/copyleaks-captcha-component/copyleaks-captcha.component';
import { focusErrorInput } from './../../../utils/focus-error-input.utils';
@UntilDestroy()
@Component({
	selector: 'cls-copyleaks-dialog',
	templateUrl: './copyleaks-dialog.component.html',
	styleUrls: ['./copyleaks-dialog.component.scss'],
})
export class CopyLeaksDialogComponent implements OnInit {
	conformationInputCtrl = new FormControl('', [Validators.required]);
	captchaCtrl = new FormControl(null, [Validators.required]);
	showButtonSpinner = false;

	@ViewChild('vcr', { read: ViewContainerRef, static: true })
	vcr: ViewContainerRef;
	@ViewChild('captcha', { read: CopyleaksCaptchaComponent })
	captcha: CopyleaksCaptchaComponent;

	passedComponentInstance: ICopyLeaksDialogComponent<any>;
	captchaErrorText = '';
	calloutMessage: CopyleaksDialogCalloutMessage;

	constructor(
		public dialogRef: MatDialogRef<CopyLeaksDialogComponent>,
		private _clsAlertsSvc: ClsAlertsService,
		@Inject(MAT_DIALOG_DATA) public data: ICopyLeaksDialogData,
		private fr: ComponentFactoryResolver,
		private _elementRef: ElementRef
	) {}

	ngOnInit(): void {
		if (this.data.component) {
			const factory = this.fr.resolveComponentFactory(this.data.component as any);
			const ref = this.vcr.createComponent(factory);
			this.passedComponentInstance = ref.instance as ICopyLeaksDialogComponent<any>;
		}

		if (!this.data.conformationInputStringMaxLength) {
			this.data.conformationInputStringMaxLength = 19;
		}
		this.data.submitted = false;
		this.dialogRef
			.beforeClosed()
			.pipe(take(1))
			.subscribe(_ => {
				if (!this.data.submitted) {
					if (this.data.onCancel) {
						this.data.onCancel();
					}
				}
			});
	}

	@HostListener('document:keydown', ['$event'])
	handleKeyboardEvent(event: KeyboardEvent) {
		if (event && event.key === 'Enter') {
			this.confirm(this.captcha);
		}
	}

	async confirm(captcha: CopyleaksCaptchaComponent) {
		try {
			this.calloutMessage = null;
			this.showButtonSpinner = true;
			this.captchaErrorText = '';
			captcha.captchaTextCtrl.markAsTouched();
			//#region check passed component validations, if passed
			if (this.data.component) {
				if (!(await this.passedComponentInstance.isValidComponent())) {
					return;
				}
			}
			//#endregion

			//#region check conformation input string validations, if passed
			if (
				this.data.ConformationInputString &&
				this.getConformationInputString() !== this.conformationInputCtrl.value.trim()
			) {
				this.conformationInputCtrl.markAsTouched();
				this.conformationInputCtrl.setErrors({
					match: this.data.ConformationInputString,
				});
				focusErrorInput(this._elementRef);
				return;
			} else {
				this.conformationInputCtrl.setErrors(null);
			}
			//#endregion

			// if all validations passed, start trying to submit
			const passedComponentData = this.data.component ? await this.passedComponentInstance.getSubmittedData() : null;
			const submittedData: ICopyLeaksDialogSubmittedData = {
				...passedComponentData,
			};

			if (this.data.showCaptcha) {
				if (this.captchaCtrl.valid) {
					submittedData.captcha = this.captchaCtrl.value;
					submittedData.captchaComponent = captcha;
				} else {
					focusErrorInput(this._elementRef);
					return;
				}
			}
			await this.data.onSubmit(submittedData);
			this.data.submitted = true;
			this.dialogRef.close();
		} catch (error) {
			if (error === ECopyLeaksDialogSubmitErrors.CaptchaNotValid) {
				this.captchaErrorText = this.data.txtInvalidCaptcha ? this.data.txtInvalidCaptcha : 'Wrong Captcha Text.';
				focusErrorInput(this._elementRef);
			} else if (error instanceof CopyleaksDialogCalloutMessage) {
				this.calloutMessage = error;
			} else {
				this._clsAlertsSvc.showHttpResponseError(error);
			}
		} finally {
			this.showButtonSpinner = false;
		}
	}

	getConformationInputString() {
		const maxLength = this.data.conformationInputStringMaxLength;
		const string = this.data.ConformationInputString.trim();
		if (!maxLength || string.length <= maxLength) {
			return this.data.ConformationInputString.trim();
		} else {
			return 'yes';
		}
	}

	cancel() {
		this.dialogRef.close();
		if (this.data.onCancel) {
			this.data.onCancel();
		}
	}

	selectConformationInputString(divRef) {
		if ((document as any).selection) {
			// IE
			const range = (document.body as any).createTextRange();
			range.moveToElementText(divRef);
			range.select();
		} else if (window?.getSelection) {
			const range = document.createRange();
			range.selectNode(divRef);
			window.getSelection().removeAllRanges();
			window.getSelection().addRange(range);
		}
	}
}

export interface ICopyLeaksDialogData {
	title?: string;
	description?: string;
	confirmationDescription?: string;
	onSubmit?: (submittedData: ICopyLeaksDialogSubmittedData) => Promise<void>;
	onCancel?: () => void;
	ConformationInputString?: string;
	conformationInputStringMaxLength?: number;
	showCaptcha?: boolean;
	component?: Component;
	btnCancelText?: string;
	btnSubmitText?: string;
	txtInvalidCaptcha?: string;
	btnSubmitColor?: string;
	btnCancelColor?: string;
	hideCancelButton?: boolean;
	confirmationText?: string;
	submitted?: boolean;
	isNewDesign?: boolean;
	isRemoved?: boolean;
	confirmationTextNext?: string;
}

export interface ICopyLeaksDialogComponent<T> {
	isValidComponent: () => Promise<boolean>;
	getSubmittedData: () => Promise<T>;
	onKeyEnter?: EventEmitter<T>;
}

export interface ICopyLeaksDialogSubmittedData {
	[key: string]: any;
	captcha?: ICopyLeaksCaptchaControlValue;
	captchaComponent?: CopyleaksCaptchaComponent;
}

export enum ECopyLeaksDialogSubmitErrors {
	CaptchaNotValid,
}

export class CopyleaksDialogCalloutMessage {
	constructor(
		public description: string,
		public title?: string,
		public type: 'success' | 'error' | 'warn' | 'info' = 'error',
		public showIcon = true
	) {}
}
