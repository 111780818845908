import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClsAlertItemComponent } from './cls-alert-item.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { PipesModule } from '@ngx-common/pipes/shared/pipes.module';

@NgModule({
	declarations: [ClsAlertItemComponent],
	imports: [CommonModule, FlexLayoutModule, MatIconModule, PipesModule],
	exports: [ClsAlertItemComponent],
})
export class ClsAlertItemModule {}
