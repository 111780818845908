<div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
	<ng-container *ngIf="activityStatus === eActivityStatus.Active">
		<mat-icon class="active-assignemnt-icon">schedule</mat-icon>
	</ng-container>

	<div class="assignment-due-date-text" i18n>
		Due date: {{ activityDueDateAsDate | date: "MMM dd, yyyy 'at' hh:mm a" }}
	</div>

	<ng-container [ngSwitch]="activityStatus">
		<ng-container *ngSwitchCase="eActivityStatus.Active">
			<div class="assignment-status-container active-status">
				<span class="assignment-status-indicator active-status"></span>
				<span class="assignment-status-text active-status" i18n>ACTIVE</span>
			</div>
		</ng-container>

		<ng-container *ngSwitchCase="eActivityStatus.Ended">
			<div class="assignment-status-container ended-status">
				<span class="assignment-status-indicator ended-status"></span>
				<span class="assignment-status-text ended-status" i18n>ENDED</span>
			</div>
		</ng-container>

		<ng-container *ngSwitchDefault>
			<div class="assignment-status-container unset-status">
				<span class="assignment-status-indicator unset-status"></span>
				<span class="assignment-status-text unset-status" i18n>UNSET</span>
			</div>
		</ng-container>
	</ng-container>
</div>
