import { Component, Input, OnInit } from '@angular/core';
import { EActivityDueDateStatus } from '../../models/activity-analytics.models';

@Component({
	selector: 'lms-analytics-activity-status',
	templateUrl: './analytics-activity-status.component.html',
	styleUrls: ['./analytics-activity-status.component.scss'],
})
export class AnalyticsActivityStatusComponent implements OnInit {
	@Input() activityDueDate: string;

	activityDueDateAsDate: Date;

	eActivityStatus = EActivityDueDateStatus;

	activityStatus: EActivityDueDateStatus;

	ngOnInit(): void {
		this.activityDueDateAsDate = this.activityDueDate ? new Date(this.activityDueDate) : null;
		this.activityStatus = this.setActivityStatus();
	}

	setActivityStatus() {
		if (!this.activityDueDate) return EActivityDueDateStatus.Unset;

		const currentDate = new Date();
		const diff = currentDate.getTime() - this.activityDueDateAsDate.getTime();

		if (diff > 0) return EActivityDueDateStatus.Ended;

		return EActivityDueDateStatus.Active;
	}
}
