import { Component, OnInit, Input } from '@angular/core';
import { AppSnackbarService } from '@ngx-common/services/snackbar.service';

import { LmsInstance, InstituteService } from '../../services/institutes.service';

const images = {
	canvas: '../../../assets/lms/canvas.jpg',
	moodle: '../../../assets/lms/moodle.png',
	BlackboardLearn: '../../../assets/lms/blackboard.png',
	desire2learn: '../../../assets/lms/desire2learn.png',
};

@Component({
	selector: 'institute-integrations',
	templateUrl: './institute-integrations.component.html',
	styleUrls: ['./institute-integrations.component.scss'],
})
export class InstituteIntegrationsComponent implements OnInit {
	images: { [key: string]: string };
	constructor(private instituteService: InstituteService, private snackbarSvc: AppSnackbarService) {
		this.images = images;
	}
	@Input()
	instituteId: string;
	public instances: LmsInstance[];
	ngOnInit() {
		this.instituteService.getLmsInstances(this.instituteId).subscribe(
			instances => {
				this.instances = instances;
			},
			err => this.snackbarSvc.showHttpResponseError(err)
		);
	}
}
