import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import * as shape from 'd3-shape';
import { ClsNgxChartUtils } from '../../../../utils/ngx-charts.utils';
import { EAnalyticsLevel, IAuthorTemplateOverview } from '../../models/cls-analytics.models';

@Component({
	selector: 'cls-analytics-author-template',
	templateUrl: './cls-analytics-author-template.component.html',
	styleUrls: ['./cls-analytics-author-template.component.scss'],
})
export class ClsAnalyticsAuthorTemplateComponent implements OnInit {
	/**
	 * The overall data for the page - for cards, charts and external results table.
	 */
	@Input() analyticsData: IAuthorTemplateOverview;
	@Input() isLoadFromDashboard = false;
	@Input() writingFeedbackFeatureEnabled = false;

	aiChartYAxisLabel: string = $localize`Cases`;
	plagChartYAxisLabel: string = $localize`Incidents`;
	writingFeedbackYAixsLabel: string = $localize`CORRECTION`;

	chartView: number[];
	lineChartView: number[];
	linearCurve = shape.curveBumpX;

	eLevelType = EAnalyticsLevel;

	@ViewChild('chartsContainer') chartsContainerRef: ElementRef<HTMLDivElement>;

	constructor() {}

	ngOnInit(): void {
		setTimeout(() => {
			this.onResize(null, this.chartsContainerRef.nativeElement);
		}, 100);
	}

	// charts responsive logic
	onResize(event, insightsItem?: HTMLDivElement) {
		this.chartView = null;
		this.lineChartView = null;
		setTimeout(() => {
			if (insightsItem) {
				this.chartView = [insightsItem.offsetWidth - 40, insightsItem.offsetHeight - 30];
				this.lineChartView = [insightsItem.offsetWidth - 40, insightsItem.offsetHeight - 60];
				// 30px height diff
			}
			setTimeout(() => {
				this.checkGraphsData();
			}, 100);
		}, 100);
	}

	checkGraphsData() {
		const el = ClsNgxChartUtils.GetChartsLineSeriesPathElementsRef();
		// Checking if graphs have only one dot
		if (this.analyticsData.plagiarismChartData.chartData[0]?.series?.length === 1) {
			ClsNgxChartUtils.setGraphPointStroke(el, 0);
		}
	}
}

@Component({
	selector: 'cls-author-analytics-data',
	template: `
		<div><ng-content></ng-content></div>
	`,
})
export class ClsAuthorAnalyticsData {}
