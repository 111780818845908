import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { environment } from '@environment';
import { BehaviorSubject, Observable, Subscription, merge } from 'rxjs';
import { map, scan, take } from 'rxjs/operators';
import { MAX_CONTAINER_NOTIFICATIONS_TO_SHOW } from '../../../../constants';
import { ESignalRProducts } from '../../../../notifications/enums';
import { ECopyleaksAPP, NgxCommonPagesConfig } from '../../../../pages/pages.config';
import { IPersonalNotification } from '../../../models/web-notifications.models';

@Injectable({
	providedIn: 'root',
})
export class WebNotificationsService implements OnDestroy {
	private _showLoadingSpinner$ = new BehaviorSubject<boolean>(false);
	private _errorMessageLoadingNotification$ = new BehaviorSubject<boolean>(false);

	private _loadMoreNotificationsSubject = new BehaviorSubject<boolean>(null);
	private _loadMoreNotifications$ = this._loadMoreNotificationsSubject.asObservable();
	private _notifications$ = new BehaviorSubject<IPersonalNotification[]>([]);
	private _insertNewNotifications$ = new BehaviorSubject<IPersonalNotification[]>([]);

	private _cursor = '';
	private _signalRProducts = ESignalRProducts.MainWebsite;

	private _markAllNotificationsAsReadSubject = new BehaviorSubject<boolean>(null);
	private _unsub = new Subscription();

	addedNotifications$: Observable<IPersonalNotification[]>;

	markAllNotificationdAsRead$ = this.markAllNotificationsAsReadSubject.asObservable();

	get loadMoreNotifications$() {
		return this._loadMoreNotifications$;
	}

	get showLoadingSpinner$() {
		return this._showLoadingSpinner$;
	}

	get errorMessageLoadingNotification$() {
		return this._errorMessageLoadingNotification$;
	}

	get markAllNotificationsAsReadSubject() {
		return this._markAllNotificationsAsReadSubject;
	}
	set markAllNotificationsAsReadSubject(value) {
		this._markAllNotificationsAsReadSubject = value;
	}

	constructor(private _http: HttpClient, @Inject(NgxCommonPagesConfig.key) _config: NgxCommonPagesConfig) {
		switch (_config?.APP) {
			case ECopyleaksAPP.API:
				this._signalRProducts = ESignalRProducts.API;
				break;
			case ECopyleaksAPP.Identity:
				this._signalRProducts = ESignalRProducts.Identity;
				break;
			case ECopyleaksAPP.Admin:
				this._signalRProducts = ESignalRProducts.Admin;
				break;
			default:
				this._signalRProducts = ESignalRProducts.MainWebsite;
				break;
		}
		this.addedNotifications$ = merge(this._notifications$, this._insertNewNotifications$).pipe(
			scan((acc: IPersonalNotification[], value: IPersonalNotification[]) => [...acc, ...value])
		);
	}

	public loadNotifications(isInit: boolean) {
		this._errorMessageLoadingNotification$.next(false);
		this._showLoadingSpinner$.next(true);
		this._unsub = this._http
			.get<IPersonalNotificationAndCursor>(
				`${environment.notificationsAPI}/notifications/${this._signalRProducts}/personal?&limit=${MAX_CONTAINER_NOTIFICATIONS_TO_SHOW}&cursor=${this._cursor}`
			)
			.pipe(
				take(1),
				map(result => {
					result.notifications.map(notification => ({ ...notification }));
					if (this._cursor === result.cursor) {
						this._loadMoreNotificationsSubject.next(false);
					}
					return result;
				})
			)
			.subscribe(
				result => {
					for (let i = 0, l = result.notifications.length; i < l; i++) {
						for (let j = 0, ll = this._notifications$.value.length; j < ll; j++) {
							if (result.notifications[i].id === this._notifications$.value[j].id) {
								result.notifications.splice(i, 1, this._notifications$.value[j]);
								break;
							}
						}
					}
					if (!this.arraysEqual(result.notifications, this._notifications$.value)) {
						if (isInit) {
							this._notifications$.next(result.notifications);
							this._cursor = '';
						} else {
							this._insertNewNotifications$.next(result.notifications);
							this._cursor = result.cursor;
						}
					}
					this._showLoadingSpinner$.next(false);
				},
				(err: HttpErrorResponse) => {
					this._errorMessageLoadingNotification$.next(true);
					this._showLoadingSpinner$.next(false);
				}
			);
	}

	private arraysEqual(a1: IPersonalNotification[], a2: IPersonalNotification[]): boolean {
		return JSON.stringify(a1) === JSON.stringify(a2);
	}

	public async markAllNotificationsAsRead(notificationIds: string[]) {
		try {
			const requestUrl = `${environment.notificationsAPI}/notifications/${this._signalRProducts}/personal/mark-all-as-read`;
			const notificationsIdsToMarkAsRead: INotificationsIdsToMarkAsRead = {
				notificationIds,
			};
			await this._http.put(`${requestUrl}`, notificationsIdsToMarkAsRead).toPromise();
			this.markAllNotificationsAsReadSubject.next(true);
		} catch (error) {
			throw error;
		}
	}

	public clearNotifications(): void {
		this._cursor = '';
		this._notifications$.next([]);
	}

	ngOnDestroy(): void {
		this._markAllNotificationsAsReadSubject.unsubscribe();
		this._notifications$.unsubscribe();
		this._insertNewNotifications$.unsubscribe();
		this._unsub.unsubscribe();
	}
}

export interface IPersonalNotificationAndCursor {
	cursor: string;
	notifications: IPersonalNotification[];
}

export interface INotificationsIdsToMarkAsRead {
	notificationIds: string[];
}
