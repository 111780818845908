<ng-container [ngSwitch]="model?.metaData?.event">
	<!-- General notification with link to a specific page -->
	<ng-container *ngSwitchCase="eGeneralNotificationEventType.Link">
		<cls-notification-item-general-link
			[linkData]="model?.metaData?.eventModel"
			[title]="model?.message"
			[description]="model?.metaData?.description"
			[isRead]="isRead"></cls-notification-item-general-link>
	</ng-container>
	<!-- General notification that opens the payment dialog  -->
	<ng-container *ngSwitchCase="eGeneralNotificationEventType.PaymentDialog">
		<cls-notification-item-general-payment-dialog
			[paymentDialogData]="model?.metaData?.eventModel"
			[title]="model?.message"
			[description]="model?.metaData?.description"
			[isRead]="isRead"></cls-notification-item-general-payment-dialog>
	</ng-container>
	<!--  -->
	<ng-container *ngSwitchDefault></ng-container>
</ng-container>
