<ng-container *ngIf="frmUserDetails">
	<form
		[formGroup]="frmUserDetails"
		autocomplete="off"
		class="form"
		[style.width]="width ? width : ''"
		(ngSubmit)="submit()">
		<div mat-dialog-title fxLayoutAlign="center">
			<span *ngIf="formMode !== eUserDetailsFormMode.AdditionInfo" class="title" i18n="@@NGX.PERSONAL_INFORMATION">
				Personal Information
			</span>
			<span
				*ngIf="formMode === eUserDetailsFormMode.AdditionInfo"
				class="title-addition-info"
				i18n="@@NGX.A_FEW_MORE_DETAILS">
				Just a few more details
			</span>
		</div>
		<mat-dialog-content>
			<div class="contact-us-form-container callouts-no-header" fxLayout="column">
				<!-- Email Input -->
				<div fxLayout="column" fxLayoutGap="5px" *ngIf="formMode === eUserDetailsFormMode.Informations">
					<mat-label i18n="@@NGX.EMAIL">Email</mat-label>
					<mat-form-field class="copyleaks-input" appearance="outline">
						<input
							formControlName="email"
							autocomplete="off"
							type="email"
							maxlength="256"
							matInput
							placeholder="Email"
							i18n-placeholder
							readonly />
					</mat-form-field>
				</div>
				<!-- First Name Input -->
				<div fxLayout="column" fxLayoutGap="5px">
					<mat-label i18n="@@NGX.FIRST_NAME">First Name</mat-label>
					<mat-form-field class="copyleaks-input" appearance="outline" full-width>
						<input
							matInput
							formControlName="firstName"
							type="text"
							maxlength="25"
							minlength="1"
							[pattern]="regex_validator"
							autocomplete="off"
							placeholder="First Name"
							i18n-placeholder />
						<mat-error i18n="@@NGX.FIRST_NAME_ERROR" *ngIf="frmUserDetails.controls['firstName'].errors">
							Please enter a valid first name without special characters or symbols.
						</mat-error>
					</mat-form-field>
				</div>
				<!-- Last Name Input -->
				<div fxLayout="column" fxLayoutGap="5px">
					<mat-label i18n="@@NGX.LAST_NAME">Last name</mat-label>
					<mat-form-field class="copyleaks-input" appearance="outline" full-width>
						<input
							type="text"
							maxlength="25"
							minlength="1"
							formControlName="lastName"
							[pattern]="regex_validator"
							matInput
							placeholder="Last name"
							i18n-placeholder />
						<mat-error i18n="@@NGX.LAST_NAME_ERROR" *ngIf="frmUserDetails.controls['lastName'].errors">
							Please enter a valid last name without special characters or symbols.
						</mat-error>
					</mat-form-field>
				</div>
				<!-- <div
					fxLayout="column"
					fxLayout.xs="column"
					fxLayoutAlign="start "
					fxLayoutGap="15px"
					class="container user-type-container">
					<label full-width style="font-weight: 600" i18n="@@NGX.WHAT_ARE_YOU">What are you?</label>
					 <mat-radio-group formControlName="userType"
														 fxLayout="row"
														 fxLayoutGap="15px"
														 fxFlex="50%"
														 [matTooltip]="activateOption === eActivateOption.Group ? 'Chosen by Team Manager' :
												 (productType ? 'According to chosen subscription plan' : '')">
							<mat-radio-button [value]="eUserType.Education"
																i18n="@@NGX.EDUCATION.TITLE">
								Education</mat-radio-button>
							<mat-radio-button [value]="eUserType.Business"
																i18n="@@NGX.BUSINESS.TITLE">Business
							</mat-radio-button>
						</mat-radio-group>
					<cls-radio-button-form-field
						formControlName="userType"
						[options]="radioProfileTypes"></cls-radio-button-form-field>
				</div> -->

				<div fxLayout="column" fxLayoutGap="5px">
					<mat-label i18n="@@NGX.WHO_ARE_YOU">Your Position</mat-label>
					<mat-form-field floatLabel="never" appearance="outline" class="copyleaks-input" lang-it-small-mat-select>
						<mat-select formControlName="profileType" full-width placeholder="Who are you?" i18n-placeholder>
							<ng-container>
								<mat-option *ngFor="let type of profileTypesOptions" [value]="type.id">
									{{ type.name }}
								</mat-option>
							</ng-container>
						</mat-select>
						<mat-error i18n="@@NGX.ACTIVATE_ACCOUNT.PROFILE_TYPE_VALIDATION_MESSAGE">
							Please select the option that best describes you
						</mat-error>
					</mat-form-field>
				</div>

				<div fxLayout="column" fxLayoutGap="5px">
					<mat-label>
						<ng-container i18n="@@NGX.INSTUTION_NAME">Institution name</ng-container>
					</mat-label>
					<mat-form-field appearance="outline" class="copyleaks-input">
						<input
							formControlName="companyName"
							type="text"
							maxlength="128"
							minlength="1"
							matInput
							autocomplete="off"
							placeholder="Institution Name" />
					</mat-form-field>
				</div>

				<ng-container *ngIf="formMode === eUserDetailsFormMode.Informations">
					<mat-form-field>
						<mat-label i18n="@@NGX.COUNTRY">COUNTRY</mat-label>
						<mat-select formControlName="country">
							<mat-option *ngFor="let country of countries" [value]="country.code">{{ country.name }}</mat-option>
							<mat-option value="OTR" i18n="@@NGX.Other">Other</mat-option>
						</mat-select>
					</mat-form-field>
				</ng-container>

				<div padding-top class="marketing-emails-check-container">
					<mat-checkbox class="legal-conf-box mat-check-box-text-pre-wrap" formControlName="marketingEmails">
						<span i18n="@@NGX.MARKING" appRemoveWhiteSpace class="marketing-emails-text">
							I would like to receive marketing communications such as newsletters, promotional and discount emails, and
							other valuable content.
						</span>
					</mat-checkbox>
				</div>
				<ng-container
					*ngIf="formMode === eUserDetailsFormMode.Activations || formMode === eUserDetailsFormMode.AdditionInfo">
					<div padding-top>
						<mat-checkbox class="legal-conf-box" formControlName="agreeToTermOfUse">
							<ng-container i18n="@@NGX.AGREE_TO">Agree to</ng-container>
							<span class="copyleaks-a-tag" (click)="goToTermsOfUse($event)">
								<ng-container i18n="@@NGX.TERMS_OF_USE">&nbsp;Terms of Use&nbsp;</ng-container>
							</span>
							<ng-container i18n="@@NGX.AND">&nbsp;and&nbsp;</ng-container>
							<span class="copyleaks-a-tag" (click)="goToPrivacyPolicy($event)">
								<ng-container i18n="@@NGX.PRIVACY_POLICY">&nbsp;Privacy Policy&nbsp;</ng-container>
							</span>
							.
						</mat-checkbox>
						<mat-error style="font-size: 75%" *ngIf="!getCtrlValue('agreeToTermOfUse') && formSubmitted">
							<ng-container>
								<span i18n="@@NGX.AGREE_TO_TERMS_OF_USE">You must agree to the terms of use and privacy policy.</span>
							</ng-container>
						</mat-error>
					</div>
				</ng-container>
			</div>
		</mat-dialog-content>
		<div mat-dialog-actions fxLayoutAlign="center center" class="actions">
			<cls-spinner-button
				type="submit"
				[showSpinner]="showSpinner"
				class="spinner-btn"
				btnHight="36px"
				[fullWidth]="true"
				spinnerDiameter="45"
				i18n="@@NGX.SAVE">
				FINISH
			</cls-spinner-button>
		</div>
	</form>
</ng-container>
