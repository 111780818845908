import {
	Component,
	forwardRef,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
} from '@angular/core';

import {
	AbstractControl,
	ControlValueAccessor,
	FormBuilder,
	FormControl,
	FormGroup,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	ValidationErrors,
	Validator,
	Validators,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService } from '../../../services/auth.service';
import { ICountry, MiscService } from '../../../services/misc.service';
import { validate } from 'postal-codes-js';
import { BillingService } from '../../../services/billing.service';
import { debounceTime } from 'rxjs';
import { EAccountBillingAddressFormControlNames, IAccountBillingAddressValue } from '../models/billing-address.models';
@UntilDestroy()
@Component({
	selector: 'cls-billing-address',
	templateUrl: './billing-address.component.html',
	styleUrls: ['./billing-address.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => BillingAddressComponent),
			multi: true,
		},
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => BillingAddressComponent),
			multi: true,
		},
	],
})
export class BillingAddressComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator, OnChanges {
	@Input() disableForm = false;
	@Input() showBillingTitle = true;
	@Input() useDebounce: boolean = true;
	@Input() showCheckMarkCircle: boolean = false;
	form: FormGroup;
	@Input() details: IAccountBillingAddressValue;
	@Output() finishedLoading = new EventEmitter();
	eAccountBillingAddressFormControlNames = EAccountBillingAddressFormControlNames;
	countries: ICountry[] = [];
	states: string[] = [];
	postalCodeError: boolean;
	countriesStateShow = ['USA'];
	isToShowState = false;
	currentCountryCode = null;
	showSpinner = true;
	showErroMessage = false;
	skeletonTheme = {
		'height.px': 40,
	};

	updateParentControllerValue: (value: IAccountBillingAddressValue) => void;

	constructor(
		private _formBuilder: FormBuilder,
		private _miscSvc: MiscService,
		private _billingSvc: BillingService,
		private _authSvc: AuthService
	) {}

	async ngOnInit() {
		await this._initCountries();
		if (!this.details) {
			this.details = (await this._billingSvc.getBillingDetails()).billingAddress;
		}
		if (!this.details.countryCode) {
			if (this._authSvc.IncompleteOrganizationUserInfo$.value.countryCode) {
				this.details.countryCode = this._authSvc.IncompleteOrganizationUserInfo$.value.countryCode;
			}
		}
		this.buildForm();
		this.countryChanged(this.details.countryCode);
		this.valueChange();
		this.showSpinner = false;
		this.finishedLoading.emit();
	}

	isFieldErrorRequired(controlName: EAccountBillingAddressFormControlNames) {
		var control = this.form.get(controlName);
		return control?.hasError('required') && control?.touched;
	}

	isFieldInvalid(controlName: EAccountBillingAddressFormControlNames) {
		var control = this.form.get(controlName);
		if (control) {
			if (control.value && control.invalid) {
				return this.errorMessage(control?.errors);
			}
		}
		return null;
	}

	errorMessage(controlErr) {
		let controlNameMinError = controlErr?.minlength?.requiredLength;
		let controlNameMaxError = controlErr?.maxlength?.requiredLength;

		if (controlNameMinError) {
			return $localize`Must contain at least ${controlNameMinError} characters.`;
		} else if (controlNameMaxError) {
			return $localize`Must contain a maximum of ${controlNameMaxError} characters.`;
		} else return null;
	}

	buildForm() {
		if (this.form?.controls?.countryCode?.valid) {
			const countryCode = this.form.controls.countryCode.value;
			if (this.details) this.details.countryCode = countryCode;
			else
				this.details = {
					countryCode,
				};
		}
		const details = this.details;

		let controlsConfig: { [key: string]: any } = {
			countryCode: [details ? details.countryCode : '', [Validators.required]],
		};

		controlsConfig['address1'] = [
			details ? details.address1 : '',
			[Validators.required, Validators.minLength(3), Validators.maxLength(30)],
		];
		controlsConfig['address2'] = [details ? details.address2 : '', [Validators.minLength(3), Validators.maxLength(30)]];
		controlsConfig['city'] = [
			details ? details.city : '',
			[Validators.required, Validators.minLength(3), Validators.maxLength(70)],
		];
		controlsConfig['postalCode'] = [details ? details.postalCode : '', [Validators.required]];
		if (this.isToShowState) {
			controlsConfig['state'] = [
				details ? details.state : '',
				[Validators.required, Validators.minLength(3), Validators.maxLength(70)],
			];
		}
		this.form = this._formBuilder.group(controlsConfig);
		this.form.valueChanges
			.pipe(this.useDebounce ? debounceTime(1000) : debounceTime(0), untilDestroyed(this))
			.subscribe(d => {
				if (d?.countryCode != this.currentCountryCode) {
					this.countryChanged(d.countryCode);
				}
				this.valueChange();
			});
		if (this.isToShowState)
			this.form.controls.postalCode.valueChanges.pipe(untilDestroyed(this)).subscribe(_ => {
				if (this.isToShowState) this.isPostalCodeValid();
			});
	}

	ngOnChanges(changes: SimpleChanges) {}

	async valueChange() {
		const isFormValid = await this.isValidComponent();
		if (!isFormValid) {
			this.updateParentControllerValue(null);
			return;
		}

		if (this.updateParentControllerValue != null) {
			this.updateParentControllerValue({
				countryCode: this.form?.controls?.countryCode?.value,
				address1: this.form?.controls?.address1?.value,
				address2: this.form?.controls?.address2?.value,
				city: this.form?.controls?.city?.value,
				postalCode: this.form?.controls?.postalCode?.value,
				state: this.form?.controls?.state?.value,
			});
		}
	}

	private async _initCountries() {
		try {
			if (this.countries?.length) return;
			this.countries = await this._miscSvc.GetCountries();
		} catch (error) {
			console.error(error);
		}
	}

	countryChanged(countryCode) {
		this.currentCountryCode = countryCode;
		const country = this.countries.find(o => o.code == countryCode);
		if (this.canShowState(country?.code)) {
			this.isToShowState = true;
			this.states = country?.states;
		} else {
			this.isToShowState = false;
		}
		this.buildForm();
	}

	canShowState(countryCode) {
		return this.countriesStateShow.includes(countryCode);
	}

	//#region Value Accessor
	writeValue(obj: any): void {}
	registerOnChange(fn: any): void {
		this.updateParentControllerValue = fn;
	}
	registerOnTouched(fn: any): void {}
	setDisabledState?(isDisabled: boolean): void {}
	//#endregion Value Accessor
	//#region validators
	validate(control: AbstractControl): ValidationErrors {
		return this.form?.errors;
	}
	registerOnValidatorChange?(fn: () => void): void {}
	//#endregion validators

	isValidComponent() {
		if (this.isToShowState && !this.isPostalCodeValid()) {
			return false;
		}
		return this.form.valid;
	}

	isPostalCodeValid() {
		var isValid = true;
		let postalCodeValue = this.form?.controls?.postalCode?.value;
		if (postalCodeValue) {
			isValid = validate(this.form?.controls?.countryCode?.value, postalCodeValue) === true;
		}
		if (!isValid) {
			this.form?.controls?.postalCode?.setErrors({ validation: false });
			this.postalCodeError = true;
		} else {
			this.postalCodeError = false;
		}
		return isValid;
	}

	validateAllFormFields(formGroup: FormGroup) {
		//{1}
		Object.keys(formGroup.controls).forEach(field => {
			//{2}
			const control = formGroup.get(field); //{3}
			if (control instanceof FormControl) {
				//{4}
				control.markAsTouched({ onlySelf: true });
			} else if (control instanceof FormGroup) {
				//{5}
				this.validateAllFormFields(control); //{6}
			}
		});
	}

	ngOnDestroy() {}
}
