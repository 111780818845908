<div fxLayout="column" fxLayoutGap="5px">
	<mat-label>
		<ng-content select="cls-password-form-field-label"></ng-content>
	</mat-label>
	<mat-form-field
		#matFormFieldRef
		[appearance]="appearance"
		class="new-password form-field-outline-no-label copyleaks-input"
		full-width>
		<input
			name="new-password"
			[formControl]="newPasswordCtrl"
			#newPassword
			matInput
			autocomplete="off"
			i18n-placeholder
			[placeholder]="!hidePlaceholder ? (passwordPlaceHolder ? passwordPlaceHolder : 'New Password') : ''"
			[type]="hideNewPassword ? 'password' : 'text'"
			[ngStyle]="{ 'font-family': hideNewPassword && newPasswordCtrl?.value ? 'Open Sans' : '' }"
			[maxLength]="maxlength"
			(focus)="inputIsFocused = true"
			(blur)="inputIsFocused = false" />
		<!--  -->
		<div
			class="password-show-icon"
			mat-icon-button
			matSuffix
			(click)="passwordPolicyErrorFlag ? emitRefreshPolicy() : (hideNewPassword = !hideNewPassword)"
			[attr.aria-label]="'Hide password'"
			[attr.aria-pressed]="hideNewPassword">
			<mat-icon>{{ hideNewPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
		</div>
		<button type="button" *ngIf="passwordPolicyErrorFlag" mat-icon-button matPrefix (click)="emitRefreshPolicy()">
			<mat-icon matPrefix *ngIf="passwordPolicyErrorFlag">refresh</mat-icon>
		</button>
		<!--  -->
		<div matPrefix *ngIf="isLoading">
			<cls-spinner width="26px"></cls-spinner>
		</div>
		<!--  -->
		<mat-error>
			<span *ngIf="!newPasswordCtrl?.value?.length" i18n="@@NGX.PASSWORD_FORM_FIELD.NEW_PASSWORD_REQUIRED">
				New password is required.
			</span>
			<span *ngIf="newPasswordCtrl?.value?.length" i18n="@@NGX.PASSWORD_FORM_FIELD.NEW_PASSWORD_INVALID">
				New password is not valid.
			</span>
		</mat-error>
	</mat-form-field>
</div>
<div
	class="passowrd-error-section"
	[style.width]="formFieldWidth + 'px'"
	*ngIf="newPasswordCtrl?.value?.length && inputIsFocused">
	<mat-progress-bar
		mode="determinate"
		aria-label="progressbar"
		[color]="getNewPasswordProgressBarColor()"
		[value]="newPasswordStrength"></mat-progress-bar>
	<mat-card fxLayout="column" fxLayoutGap="10px" *ngIf="!isValidCtrl()">
		<div fxLayout="row" fxLayoutGap="10px" *ngIf="!containAtLeastOneLowerCaseLetter">
			<mat-icon color="warn">error</mat-icon>
			<span i18n="@@NGX.PASSWORD_FORM_FIELD.NEW_PASSWORD_LOWER_CASE">Missing at least one lower character [a-z]</span>
		</div>
		<div fxLayout="row" fxLayoutGap="10px" *ngIf="!containAtLeastOneUpperCaseLetter">
			<mat-icon color="warn">error</mat-icon>
			<span i18n="@@NGX.PASSWORD_FORM_FIELD.NEW_PASSWORD_UPPER_CASE">
				Missing at least one uppercase character [A-Z]
			</span>
		</div>
		<div fxLayout="row" fxLayoutGap="10px" *ngIf="!containAtLeastOneDigit">
			<mat-icon color="warn">error</mat-icon>
			<span i18n="@@NGX.PASSWORD_FORM_FIELD.NEW_PASSWORD_DIGIT_CHARS">Missing at lease one number character [0-9]</span>
		</div>
		<div fxLayout="row" fxLayoutGap="10px" *ngIf="!containAtLeastOneSpecialChar">
			<mat-icon color="warn">error</mat-icon>
			<span style="text-align: start" i18n="@@NGX.PASSWORD_FORM_FIELD.SPECIAL_CHARS">
				Missing at least one special character {{ passwordSpecialChars }}
			</span>
		</div>
		<div fxLayout="row" fxLayoutGap="10px" *ngIf="!containAtLeastEightChars">
			<mat-icon color="warn">error</mat-icon>
			<span i18n="@@NGX.MISSING_LENGTH" *ngIf="passwordPolicy">
				Must contain at least {{ passwordPolicy?.requiredLength }} characters
			</span>
			<span i18n="@@NGX.MISSING_LENGTH.TWO" *ngIf="!passwordPolicy">Must contain at least 8 characters</span>
		</div>
		<div fxLayout="row" fxLayoutGap="10px" *ngIf="containMoreThan32Chars">
			<mat-icon color="warn">error</mat-icon>
			<span i18n="@@NGX.PASSWORD_FORM_FIELD.NEW_PASSWORD_MAX_DIGITS">Must contain a maximum of 32 characters</span>
		</div>
	</mat-card>
</div>
