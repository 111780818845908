<a
	[routerLink]="isUserLogedIn ? link : '/'"
	alt="dashboard-logo"
	class="logo-container"
	[ngClass]="
		isMobile ? 'logo-mobile-container' : showShrinkedLogo ? 'logo-shrink-container' : 'logo-desktop-container'
	">
	<!--  -->
	<ng-container *ngIf="isMobile || showShrinkedLogo">
		<ng-container *ngIf="isMobile">
			<img width="34px" height="32px" [src]="mobileImgUrl" alt="dashboard-logo" />
		</ng-container>
		<ng-container *ngIf="showShrinkedLogo">
			<img width="40px" height="40px" [src]="mobileImgUrl" alt="dashboard-logo" />
		</ng-container>
	</ng-container>
	<!--  -->
	<ng-container *ngIf="!isMobile && !showShrinkedLogo">
		<img class="desktop-logo" [ngClass]="{ 'lms-img': isLMS }" [src]="desktopImgUrl" alt="dashboard-logo" />
	</ng-container>
	<!--  -->
</a>
