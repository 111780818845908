import { Component, Input, OnInit } from '@angular/core';
import { PricingPlanItemResultItem } from '../../../../../../models/pricing.model';
import { ScreenService } from '../../../../../../services/screen.service';
import { ENewPaymentDialogBillingIntervals, IPlanItemData } from '../../../models/new-payment-dialog.model';

@Component({
	selector: 'cls-plan-item',
	templateUrl: './plan-item.component.html',
	styleUrls: ['./plan-item.component.scss'],
})
export class PlanItemComponent implements OnInit {
	@Input() planItemData: IPlanItemData;

	eNewPaymentDialogBillingIntervals = ENewPaymentDialogBillingIntervals;

	get productPlanPrice() {
		if (this.planItemData.interval == ENewPaymentDialogBillingIntervals.Yearly) {
			return (this.planItemData.productPlan.price / 12).toFixed(2);
		}
		return this.planItemData.productPlan.price.toFixed(2);
	}

	get isMobile() {
		return this._screenSvc.isMobile();
	}
	constructor(private _screenSvc: ScreenService) {}

	ngOnInit(): void {}
}
