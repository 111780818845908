import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
	PasswordFormFieldComponent,
	PasswordFormFieldLabelComponent,
} from './password-form-field-component/password-form-field.component';
import { SpinnerModule } from '../spinner/spinner.module';
import { PasswordFormFieldComponentV2Component } from './password-form-field-component-v2/password-form-field-component-v2.component';

@NgModule({
	declarations: [PasswordFormFieldComponent, PasswordFormFieldLabelComponent, PasswordFormFieldComponentV2Component],
	exports: [PasswordFormFieldComponent, PasswordFormFieldLabelComponent, PasswordFormFieldComponentV2Component],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		FormsModule,
		FlexLayoutModule,
		SpinnerModule,
		MatIconModule,
		MatFormFieldModule,
		MatInputModule,
		MatProgressBarModule,
		MatButtonModule,
		MatCardModule,
	],
})
export class PasswordFormFieldModule {}
