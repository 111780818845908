import { ILti1_3IntegrationItemModel } from 'shared/models/lti1_3-integration.model';
import { ICPFToolProxy, IMPPIntegration } from '../../../interfaces';
import {
	EGeneralLMSIntegrationLmsFamilyName,
	EGeneralLMSIntegrationStatus,
	EIntegrationConsumer,
} from './integrations.enums ';

export interface IGetAllUserIntegrationsResponseModel {
	/**
	 * All the users' Canvas plagarism frameworks integrations
	 */
	cpfIntegrations: ICPFToolProxy[];

	/**
	 * All the users' Moodle plagarism plugins integrations
	 */
	mppIntegrations: IMPPIntegration[];

	/**
	 * All the users' LTI 1.3 integrations
	 */
	lti1_3Integrations: ILti1_3IntegrationItemModel[];
}

export class GeneralLMSIntegrationModel {
	/**
	 * Identifier of the integration
	 */
	id: string;

	/**
	 * Name of the integration
	 */
	name: string;

	/**
	 * Type of the integration
	 */
	type: EIntegrationConsumer;

	/**
	 * Status of the integration
	 */
	status: EGeneralLMSIntegrationStatus;

	/**
	 * Deletion date of the integration
	 */
	deletionDate: Date;

	/**
	 * Creation date of the integration
	 */
	creationDate: Date;

	/**
	 * Metadata about the integration, including the name and default scan settings ID
	 */
	metaData: ICPFToolProxy | IMPPIntegration | ILti1_3IntegrationItemModel;

	// Properties for UI use
	itemBedgeSource: string;
	showRestoreButtonSpinner?: boolean;
	isInegrationDeleted?: boolean;

	constructor(
		type: EIntegrationConsumer,
		metaData: ICPFToolProxy | IMPPIntegration | ILti1_3IntegrationItemModel,
		itemBedgeSource: string = null,
		status: EGeneralLMSIntegrationStatus = null
	) {
		this.type = type;
		this.metaData = metaData;
		this.itemBedgeSource = itemBedgeSource;
		this.status = status;
		if (!status) {
			this.initInegrationStatus();
		}
		this.id = this._getInegrationId();
		this.name = this._getInegrationName();
		this.deletionDate = this._getInegrationDeletionDate();
		this.creationDate = this._getInegrationCreationDate();
		this.isInegrationDeleted = this._isInegrationDeleted();
	}

	/**
	 * Gets the Inegration Id.
	 * @returns The integration Id according to the type
	 */
	private _getInegrationId(): string {
		let metaData: ICPFToolProxy | IMPPIntegration | ILti1_3IntegrationItemModel;
		switch (this.type) {
			case EIntegrationConsumer.LTI1_3:
				metaData = this.metaData as ILti1_3IntegrationItemModel;
				return metaData?.id;
			case EIntegrationConsumer.Canvas:
				metaData = this.metaData as ICPFToolProxy;
				return metaData?.guid;
			case EIntegrationConsumer.Moodle:
				metaData = this.metaData as IMPPIntegration;
				return metaData?.key;
			default:
				throw new Error(`Unknown integration type: ${this.type}`);
		}
	}

	/**
	 * Gets the Inegration name.
	 * @returns The integration name according to the type
	 */
	private _getInegrationName(): string {
		let metaData: ICPFToolProxy | IMPPIntegration | ILti1_3IntegrationItemModel;
		switch (this.type) {
			case EIntegrationConsumer.LTI1_3:
				metaData = this.metaData as ILti1_3IntegrationItemModel;
				return metaData?.name;
			case EIntegrationConsumer.Canvas:
				metaData = this.metaData as ICPFToolProxy;
				return metaData?.name;
			case EIntegrationConsumer.Moodle:
				metaData = this.metaData as IMPPIntegration;
				return metaData?.name;
			default:
				throw new Error(`Unknown integration type: ${this.type}`);
		}
	}

	/**
	 * Gets the Inegration creation date.
	 * @returns The integration creation date according to the type
	 */
	private _getInegrationCreationDate(): Date {
		let metaData: ICPFToolProxy | IMPPIntegration | ILti1_3IntegrationItemModel;
		switch (this.type) {
			case EIntegrationConsumer.LTI1_3:
				metaData = this.metaData as ILti1_3IntegrationItemModel;
				return metaData?.createdAt;
			case EIntegrationConsumer.Canvas:
				metaData = this.metaData as ICPFToolProxy;
				return metaData?.createdAt;
			case EIntegrationConsumer.Moodle:
				metaData = this.metaData as IMPPIntegration;
				return metaData?.createdAt;
			default:
				throw new Error(`Unknown integration type: ${this.type}`);
		}
	}

	/**
	 * Gets the Inegration deletion date.
	 * @returns The integration deletion date according to the type
	 */
	private _getInegrationDeletionDate(): Date {
		let metaData: ICPFToolProxy | IMPPIntegration | ILti1_3IntegrationItemModel;
		switch (this.type) {
			case EIntegrationConsumer.LTI1_3:
				metaData = this.metaData as ILti1_3IntegrationItemModel;
				return metaData?.deletionDate;
			case EIntegrationConsumer.Canvas:
				metaData = this.metaData as ICPFToolProxy;
				return metaData?.expiryDate;
			case EIntegrationConsumer.Moodle:
				metaData = this.metaData as IMPPIntegration;
				return metaData?.deleteDate;
			default:
				throw new Error(`Unknown integration type: ${this.type}`);
		}
	}

	/**
	 * Checks whether the integration is deleted or not.
	 * @returns true if the integration is deleted, false otherwise.
	 */
	private _isInegrationDeleted(): boolean {
		let metaData: ICPFToolProxy | IMPPIntegration | ILti1_3IntegrationItemModel;
		switch (this.type) {
			case EIntegrationConsumer.LTI1_3:
				metaData = this.metaData as ILti1_3IntegrationItemModel;
				return metaData?.deletionDate != null;
			case EIntegrationConsumer.Canvas:
				metaData = this.metaData as ICPFToolProxy;
				return metaData?.expiryDate != null;
			case EIntegrationConsumer.Moodle:
				metaData = this.metaData as IMPPIntegration;
				return metaData?.deleteDate != null;
			default:
				throw new Error(`Unknown integration type: ${this.type}`);
		}
	}

	/**
	 * Initializes the integration status according to the integration deletion date (if it's provided then its deleted)
	 */
	public initInegrationStatus(): void {
		let metaData: ICPFToolProxy | IMPPIntegration | ILti1_3IntegrationItemModel;
		switch (this.type) {
			case EIntegrationConsumer.LTI1_3:
				metaData = this.metaData as ILti1_3IntegrationItemModel;
				if (metaData?.deletionDate != null) this.status = EGeneralLMSIntegrationStatus.Deleted;
				else this.status = EGeneralLMSIntegrationStatus.Active;
				break;
			case EIntegrationConsumer.Canvas:
				metaData = this.metaData as ICPFToolProxy;
				if (metaData.expiryDate) {
					this.status = EGeneralLMSIntegrationStatus.Deleted;
				} else if (!metaData.successfullyRegistered) {
					this.status = EGeneralLMSIntegrationStatus.Pending;
				} else this.status = EGeneralLMSIntegrationStatus.Active;
				break;
			case EIntegrationConsumer.Moodle:
				metaData = this.metaData as IMPPIntegration;
				if (metaData.deleteDate) {
					this.status = EGeneralLMSIntegrationStatus.Deleted;
				} else this.status = EGeneralLMSIntegrationStatus.Active;
				break;
			default:
				throw new Error(`Unknown integration type: ${this.type}`);
		}
	}

	/**
	 * Setter for the deletion date of the integration.
	 * @param newDeletionDate The new deletion date
	 */
	public setInegrationDeletionDate(newDeletionDate?: Date): void {
		let metaData: ICPFToolProxy | IMPPIntegration | ILti1_3IntegrationItemModel;
		switch (this.type) {
			case EIntegrationConsumer.LTI1_3:
				metaData = this.metaData as ILti1_3IntegrationItemModel;
				metaData.deletionDate = newDeletionDate;
				break;
			case EIntegrationConsumer.Canvas:
				metaData = this.metaData as ICPFToolProxy;
				metaData.expiryDate = newDeletionDate;
				break;
			case EIntegrationConsumer.Moodle:
				metaData = this.metaData as IMPPIntegration;
				metaData.deleteDate = newDeletionDate;
				break;
			default:
				throw new Error(`Unknown integration type: ${this.type}`);
		}
		this.deletionDate = newDeletionDate;
		this.isInegrationDeleted = this._isInegrationDeleted();
	}

	getIntegrationTypeName(): string {
		let metaData: ICPFToolProxy | IMPPIntegration | ILti1_3IntegrationItemModel;
		switch (this.type) {
			case EIntegrationConsumer.LTI1_3:
				// For LTI 1.3 integrations, set the icon according to the lmsFamily property
				metaData = this.metaData as ILti1_3IntegrationItemModel;
				if (metaData?.platformInformation?.lmsFamily) {
					switch (metaData?.platformInformation?.lmsFamily) {
						case EGeneralLMSIntegrationLmsFamilyName.Brightspace:
							return 'Brightspace';
						case EGeneralLMSIntegrationLmsFamilyName.Sakai:
							return 'Sakai';
						case EGeneralLMSIntegrationLmsFamilyName.Schoology:
							return 'Schoology';
						case EGeneralLMSIntegrationLmsFamilyName.Canvas:
							return 'Canvas';
						case EGeneralLMSIntegrationLmsFamilyName.Moodle:
							return 'Moodle';
						case EGeneralLMSIntegrationLmsFamilyName.Blackboard:
							return 'Blackboard';
						default:
							return 'unknown';
					}
				}
				return 'unknown';
			case EIntegrationConsumer.Canvas:
				return 'Canvas';
			case EIntegrationConsumer.Moodle:
				return 'Moodle';
			default:
				throw new Error(`Unknown integration type: ${this.type}`);
		}
	}
}
