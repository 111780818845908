<div class="lms-score-container">
	<!--  -->
	<ng-container *ngIf="isAPIPropertyEnabled && isFeatureEnabled">
		<!--  -->
		<ng-container *ngIf="!isLoading && score != null && score != undefined && score >= 0">
			<div class="score-data-container">{{ score | number: '1.0-0' }}</div>
		</ng-container>
		<div *ngIf="!isLoading && link" class="link-container">
			<a
				role="link"
				mat-icon-button
				class="mat-icon-button-override link-to-report-btn"
				matTooltip="View the scan report in a new tab"
				aria-label="Click to view the scan report in a new tab"
				i18n-aria-label
				i18n-matTooltip
				(click)="openSource()"
				tabIndex="0"
				(keyup.enter)="openSource()">
				<mat-icon color="primary" fontSet="material-icons-round" class="link-to-report">open_in_new</mat-icon>
			</a>
		</div>
	</ng-container>

	<!--  -->
	<ng-container *ngIf="!isLoading && !isAPIPropertyEnabled && isFeatureEnabled && !isFailedScan">
		<div class="unused-container" i18n>DISABLED</div>
	</ng-container>

	<!--  -->
	<ng-container *ngIf="!isFeatureEnabled && !isLoading && !isFailedScan">
		<div class="unused-container" i18n>N/A</div>
	</ng-container>

	<!--  -->
	<ng-container *ngIf="isLoading">
		<ngx-skeleton-loader
			class="loader"
			[theme]="{
				'border-radius': '30px',
				'background-color': 'rgba(17, 41, 96, 0.3)'
			}"
			animation="progress"
			appearance="line"></ngx-skeleton-loader>
	</ng-container>
</div>
