import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RadioButtonFormFieldComponent } from './radio-button-form-field.component';
import { FormsModule } from '@angular/forms';

@NgModule({
	declarations: [RadioButtonFormFieldComponent],
	exports: [RadioButtonFormFieldComponent],
	imports: [CommonModule, FormsModule],
})
export class RadioButtonFormFieldModule {}
