<ng-container *ngIf="!showSpinner">
	<button
		#btnWrapperElementRef
		mat-raised-button
		[color]="color"
		[type]="type"
		[disabled]="disabled"
		[style.fontWeight]="fontWeight"
		[style.min-width]="fullWidth ? '100%' : noWidth ? 'unset' : btnWidth">
		<ng-content></ng-content>
	</button>
</ng-container>

<ng-container *ngIf="showSpinner">
	<div class="spinner-container" [ngStyle]="{ height: btnHight }">
		<span class="percent-box" *ngIf="percent">{{ percent | number: '1.0-0' }}%</span>
		<mat-progress-spinner [diameter]="spinnerDiameter" [color]="color" mode="indeterminate"></mat-progress-spinner>
	</div>
</ng-container>
