import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IUserBalance } from '@ngx-common/models/user-balance.models';
import { httpFailRetry } from '@ngx-common/observable/http-fail-retry.pipes';
import { LocalStorageService } from '@ngx-common/services/localstorage.service';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject } from 'rxjs';
import { ApiEndpoints, Cookies, LocalStorageKey } from '../constants/auth.constants';
import { DashboardUserSettings } from '../interfaces';

const LOCAL_STORAGE_BALANCE = 'Balance';
export interface LoginModel {
	email: string;
	password: string;
	rememberMe: boolean;
}
// service
@Injectable({
	providedIn: 'root',
})
export class UserSettingsService {
	readonly apiEndpoint = ApiEndpoints.Account;

	// ---- //
	private _userSettings$ = new BehaviorSubject<DashboardUserSettings>(null);
	get userSettings$() {
		return this._userSettings$;
	}
	get userSettings(): DashboardUserSettings {
		return this._userSettings$.value;
	}
	// ---- //

	private _balance$ = new BehaviorSubject<IUserBalance>(null);
	get balance$() {
		return this._balance$;
	}
	get _balance(): IUserBalance {
		return this._balance$.value;
	}
	set _balance(value: IUserBalance) {
		if (value?.features?.ltiIntegration) {
			this._localStorage?.setItem(LOCAL_STORAGE_BALANCE, JSON.stringify(value));
		}
		this._balance$.next(value);
	}

	constructor(
		private _http: HttpClient,
		private _cookieService: CookieService,
		private _localStorage: LocalStorageService
	) {
		this.initUserSettings();
	}

	initUserSettings() {
		const settings = localStorage.getItem(LocalStorageKey.User);

		if (settings) {
			this._userSettings$.next(JSON.parse(settings));
		}

		if (this._cookieService.check(Cookies.SecondaryCookie)) {
			const settingsObject = JSON.parse(this._cookieService.get(Cookies.SecondaryCookie));
			this.updateUserData(settingsObject);
		}
	}

	async getUserBalanceAsync(): Promise<IUserBalance> {
		try {
			if (!this._balance) {
				var storageBalance = this._localStorage?.getItem(LOCAL_STORAGE_BALANCE);
				this._balance = storageBalance
					? JSON.parse(storageBalance)
					: await this._http.get<IUserBalance>(`/v1/account/balance`).pipe(httpFailRetry()).toPromise();
			}
			return this._balance;
		} catch (error) {
			throw error;
		}
	}

	clear() {
		this.updateUserData(null);
		this._balance = null;
	}

	updateUserData(user: DashboardUserSettings) {
		this._userSettings$.next(user);
		localStorage.setItem(LocalStorageKey.User, JSON.stringify(user));
	}
}
