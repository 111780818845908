<ng-template #contentText>
	<ng-content select="cls-dashboard-link-item-text-v2"></ng-content>
</ng-template>
<ng-template #contentShrinkedText>
	<ng-content select="cls-dashboard-link-shrinked-item-text-v2"></ng-content>
</ng-template>
<ng-template #contentIcon>
	<ng-content select="cls-dashboard-link-item-icon-v2"></ng-content>
</ng-template>
<ng-template #contentImage>
	<img alt="SideNav Item Icon" [src]="linkImgSrc" />
</ng-template>
<ng-container *ngIf="isShown && !disableLinkItem">
	<a
		mat-button
		*ngIf="!isSideNavShrinked"
		class="link-item"
		[ngStyle]="{ 'pointer-events': this.isMobile && !this.matDrawer?.opened ? 'none' : 'default' }"
		[routerLink]="[link]"
		fxLayoutAlign="start center"
		(click)="toggleSideNav()"
		[routerLinkActive]="'side-nav-actions-active'"
		[routerLinkActiveOptions]="{ exact: activeMatchExactRoute }">
		<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
			<div class="img-icon-container" fxLayoutAlign="center center" *ngIf="iconAsImgSrc">
				<ng-container *ngTemplateOutlet="contentImage"></ng-container>
			</div>
			<mat-icon *ngIf="!iconAsImgSrc">
				<ng-container *ngTemplateOutlet="contentIcon"></ng-container>
			</mat-icon>
			<span>
				<ng-container *ngTemplateOutlet="contentText"></ng-container>
			</span>
		</div>
	</a>
	<a
		mat-button
		*ngIf="isSideNavShrinked"
		class="shrinked-link-item"
		[routerLink]="[link]"
		(click)="toggleSideNav()"
		routerLinkActive="side-nav-actions-active"
		[routerLinkActiveOptions]="{ exact: activeMatchExactRoute }">
		<div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="2px">
			<div class="img-icon-container" fxLayoutAlign="center center" *ngIf="iconAsImgSrc">
				<ng-container *ngTemplateOutlet="contentImage"></ng-container>
			</div>
			<mat-icon *ngIf="!iconAsImgSrc">
				<ng-container *ngTemplateOutlet="contentIcon"></ng-container>
			</mat-icon>
			<span>
				<ng-container *ngTemplateOutlet="contentShrinkedText"></ng-container>
			</span>
		</div>
	</a>
</ng-container>
