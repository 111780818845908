import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@ngx-common/services/auth.service';
import { UserSettingsService } from '../services/user-settings.service';

@Component({
	selector: 'app-user-init-navigation',
	template: '',
})
export class UserInitNavigationComponent implements OnInit {
	constructor(
		private _router: Router,
		private _userSettingsSvc: UserSettingsService,
		private _authService: AuthService
	) {}
	ngOnInit() {
		this._init();
	}

	async _init() {
		const settings = this._userSettingsSvc.userSettings;
		if (!this._authService.isLoggedIn() || !settings) {
			return await this._router.navigate(['integrations/add'], { replaceUrl: true });
		} else if (
			settings?.hasMoodlePlugins ||
			settings?.hasCanvasPlagiarismFrameworks ||
			settings?.hasLti1_3Integrations
		) {
			return await this._router.navigate(['integrations'], { replaceUrl: true });
		} else if (settings?.hasLtiIntegrations) {
			return await this._router.navigate(['lti/v1.1/institues'], { replaceUrl: true });
		} else {
			return await this._router.navigate(['integrations/add'], { replaceUrl: true });
		}
	}
}
