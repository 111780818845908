import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { environment } from '@environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, map } from 'rxjs';
import { IPaymentMethod } from '../../../components/payment-methods/payment-methods.service';
import { PaymentDialogService } from '../../../dialogs/payment-dialog/services/payment-dialog.service';
import { EDataInitTypes } from '../../../enums/data-init-types.enum';
import {
	EPlatformProductType,
	IPlatformProductsPlans,
	PricingPlan,
	PricingPlanItemResultItem,
	PricingPlanResult,
} from '../../../models/pricing.model';
import { AuthService } from '../../../services/auth.service';
import { IUserSubscriptionPlan, PaymentPlanService } from '../../../services/payment-plan.service';
import { PricingService } from '../../../services/pricing-plans.service';
import {
	ENewPaymentDialogSteps,
	ICheckOutSelectedPlan,
	INewPaymentDialogComponentData,
} from './models/new-payment-dialog.model';
import { EPaymentPlanType } from '../../../enums/payment-plan-type.enum';
import { IBillingTemplateDialogModel } from '../../../dialogs/billing-template-dialog/models/billing-template-dialog.models';

@UntilDestroy()
@Component({
	selector: 'app-new-payment-dialog',
	templateUrl: './new-payment-dialog.component.html',
	styleUrls: ['./new-payment-dialog.component.scss'],
})
export class NewPaymentDialogComponent implements OnInit {
	currentDialogStep: ENewPaymentDialogSteps;
	eNewPaymentDialogSteps = ENewPaymentDialogSteps;
	allProductsPaymentPlans: IPlatformProductsPlans;
	pricingPlans: PricingPlan[];
	selectedPlan: PricingPlan;
	isPlatformBrowser: boolean;
	oldPaymentsMethods: IPaymentMethod;
	requiredPlan: PricingPlanResult;
	currentSubscriptionPlan: IUserSubscriptionPlan;
	recommendedPlan: PricingPlanResult;
	isDataLoading = true;
	isLoadingSubscription: boolean = true;
	isPaymentActive = false;
	discountCode: string;
	billingTemplateDialog: IBillingTemplateDialogModel;
	subPricingPlans: PricingPlanItemResultItem[];
	hideBackButton: boolean;

	// IPlatformProducts
	constructor(
		@Inject(MAT_DIALOG_DATA) public dialogData: INewPaymentDialogComponentData,
		public dialogRef: MatDialogRef<NewPaymentDialogComponent>,
		private _pricingService: PricingService,
		private _paymentPlanSvc: PaymentPlanService,
		private _paymentDialogSvc: PaymentDialogService,
		private _authSvc: AuthService,
		@Inject(PLATFORM_ID) private _platformId: string
	) {}

	async ngOnInit() {
		this.isPlatformBrowser = isPlatformBrowser(this._platformId);
		if (this.dialogData.dialogId === undefined) {
			this.dialogData.dialogId = null;
		}

		if (this._authSvc.isGuestUser) {
			// in case and guest get here
			location.href = `${environment.dashboardWebsiteUrl}/signup`;
			return;
		}
		this.hideBackButton = this.dialogData?.hideBackButton ?? false;
		if (this.dialogData?.isSubscription) {
			this.currentDialogStep = ENewPaymentDialogSteps.PurchaseMoreCredits;
			const currentProductType = this.dialogData?.currentSubscriptionPlan?.platformProductType;
			const currentPrice = this.dialogData?.currentSubscriptionPlan?.creditsPackage;
			const creditsPlans = this._getCreditsPlansByProductType(currentProductType);

			this.billingTemplateDialog = {
				title: $localize`Purchase More Credits`,
				firstDescription: $localize`Add more credits to your Copyleaks account. Note that adding more credits will upgrade your account and increase your recurring payments.`,
				selectOptionTitle: $localize`Credits`,
				options: creditsPlans,
				selectOptionDescription: $localize`1 credit equal to 250 words.`,
				selectedOption: currentPrice,
				saveButtonText: $localize`Checkout`,
				showCancelButton: false,
			};
		} else if (
			this.dialogData.isUpgradeToYearly ||
			this.dialogData.isUpgradeToNextTier ||
			this.dialogData.isFixPaymentMethod
		) {
			this.currentDialogStep = ENewPaymentDialogSteps.Checkout;
		} else {
			this.currentDialogStep = ENewPaymentDialogSteps.ChoosePlan;
		}

		this._paymentDialogSvc.isPaymentActive();

		this._paymentDialogSvc.paymentDialogSvcData$
			.pipe(
				untilDestroyed(this),
				map(d => d.find(i => i.id === this.dialogData.dialogId))
			)
			.subscribe(async data => {
				if (!data) {
					this.isDataLoading = true;
				} else {
					this.isPaymentActive = data.isPaymentActive;
					this.discountCode = data.discountCode;
					this.allProductsPaymentPlans = data?.platformProductsPlans;
					if (
						(this.dialogData.anonymousUser || data.oldMethod || this.dialogData.isFixPaymentMethod) &&
						data.isPaymentActive != null
					) {
						this.oldPaymentsMethods = this.dialogData.anonymousUser ? null : data.oldMethod;
						if (this.dialogData.planId && this.dialogData.platformProductType) {
							if (data.requiredPlan) {
								this.requiredPlan = data.requiredPlan;
								const selectedPlan =
									data.requiredPlan.monthly.planId.toString() !== this.dialogData.planId && // related to a bug with old product ( business & business api) because buisness plans not found
									data.requiredPlan.yearly.planId.toString() !== this.dialogData.planId
										? data.requiredPlan.monthly
										: data.requiredPlan.monthly.planId.toString() === this.dialogData.planId
										? data.requiredPlan.monthly
										: data.requiredPlan.yearly;
								this.pricingPlans = await this._pricingService.mapPlansToPricingPlans();
								var selectedPricingPlan = this.pricingPlans.find(pricingPlan => pricingPlan.id == selectedPlan.planId);
								this.selectedPlan = selectedPricingPlan;
								this.dialogData.priceToPay = selectedPlan.totalPriceAfterDiscount;
							}
						} else {
							this.requiredPlan = data.requiredPlan;
						}
					} else {
						this.requiredPlan = data.requiredPlan;
					}
					this.dataInit(EDataInitTypes.Data);
				}
			});
		this.currentSubscriptionPlan = this.dialogData.currentSubscriptionPlan;
	}

	private _getCreditsPlansByProductType(productType: EPlatformProductType) {
		const plans = this.dialogData?.plans?.products.find(plan => plan.platformProductType === productType).plans;
		const isMonthly = this.dialogData?.currentSubscriptionPlan?.interval?.months === 1;
		if (isMonthly) {
			this.subPricingPlans = plans.map(plan => plan.monthly);
			return this.subPricingPlans.map(plan => plan.credits);
		}
		this.subPricingPlans = plans.map(plan => plan.yearly);
		return this.subPricingPlans.map(plan => plan.credits);
	}

	dataInit(type: EDataInitTypes) {
		this.isDataLoading = false;
	}

	goToStep(step: ENewPaymentDialogSteps) {
		this.currentDialogStep = step;
	}

	saveCheckout(credits: number) {
		const plan = this.subPricingPlans.find(plan => plan.credits == credits);
		this.goToCheckout({
			credits: credits,
			interval: this.dialogData.currentSubscriptionPlan.interval,
			price: plan.price,
			id: plan.planId,
			platformProduct: this.dialogData?.currentSubscriptionPlan?.platformProductType,
		});
	}

	goToCheckout(plan: PricingPlan) {
		this.selectedPlan = plan;
		if (this.dialogData.anonymousUser) {
			this.dialogData.onContinue(true, this.selectedPlan);
			this.closeDialog();
		} else {
			this.currentDialogStep = ENewPaymentDialogSteps.Checkout;
		}
	}

	goToChoosePlan() {
		this.selectedPlan = null;
		if (this.dialogData?.isSubscription) {
			this.currentDialogStep = ENewPaymentDialogSteps.PurchaseMoreCredits;
		} else {
			this.currentDialogStep = ENewPaymentDialogSteps.ChoosePlan;
		}
	}

	onSucccefullyPaid() {
		this.dialogRef.disableClose = false;
		if (this.dialogData.onPaymentSuccess) {
			this.dialogData.onPaymentSuccess();
		}
		if (this.dialogData.onContinue) {
			this.dialogData.onContinue(true, this.selectedPlan);
		}
		this.closeDialog();
	}

	closeDialog() {
		this.dialogRef.close();
	}
}
