<div class="list-container" fxLayout="column" fxLayoutGap="8px">
	<!-- 		fxLayout="row"
		fxLayoutAlign="center center"
		fxLayoutGap="8px" -->
	<button
		mat-button
		(click)="toggleList()"
		class="title-container"
		i18n-matTooltip
		[matTooltip]="showList ? txtCollapse : txtExpend">
		<mat-icon *ngIf="showList">expand_less</mat-icon>
		<mat-icon *ngIf="!showList">expand_more</mat-icon>
		<span>{{ listTitle }}</span>
		<div class="title-row-line"></div>
	</button>
	<ng-container *ngIf="showList">
		<ng-content select="cls-toggle-list-item"></ng-content>
	</ng-container>
</div>
