import { Component, Input, OnInit } from '@angular/core';
import { IDateRange } from '../../models/integration-level.models';
import { BreadcrumbItem } from '@ngx-common/services/seo.service';
import { ActivatedRoute } from '@angular/router';
import { ClsAlertsService } from 'ngx-common-v2/components/cls-alerts/services/cls-alerts.service';
import { AnalyticsDataService } from '../../services/analytics-data.service';
import { EPlatform } from 'shared/enums';
import { IStudentAnalyticsOverview } from '../../models/analytics-student.models';
import { AnalyticsConstants } from 'shared/constants/analytics';

@Component({
	selector: 'lms-analytics-student',
	templateUrl: './analytics-student.component.html',
	styleUrls: ['./analytics-student.component.scss'],
})
export class AnalyticsStudentComponent implements OnInit {
	@Input() isLoadFromDashboard = true;
	@Input() token: string;

	isLoading = false;
	isFirstLoad = true;
	loadingMessage = $localize`Loading data...`;

	ANALYTICS_COLLECTION_DATE = AnalyticsConstants.ANALYTICS_COLLECTION_DATE;
	currentDate: Date = new Date();
	baseStartDate: Date = AnalyticsConstants.ANALYTICS_COLLECTION_DATE;
	/**
	 * The Date range the user picked -> initialized with defaults
	 */
	dateRange: IDateRange = {
		startMonth: this.baseStartDate.getMonth() + 1,
		startYear: this.baseStartDate.getFullYear(),
		endMonth: this.currentDate.getMonth() + 1,
		endYear: this.currentDate.getFullYear(),
	};

	breadCrumbs: BreadcrumbItem[];

	integrationId: string;
	studentId: string;
	platformType: EPlatform;

	dataSrc: IStudentAnalyticsOverview;

	constructor(
		private _analyticsService: AnalyticsDataService,
		private _clsAlertSvc: ClsAlertsService,
		private _route: ActivatedRoute
	) {}

	ngOnInit(): void {
		this.breadCrumbs = [
			{
				name: $localize`Unnokwn`,
				url: '/',
			},
		];
		this.platformType = Number(this._route.snapshot.paramMap.get('platformType'));
		this.integrationId = this._route.snapshot.paramMap.get('integrationId');
		this.studentId = this._route.snapshot.paramMap.get('studentId');
		this.getStudentAnalyticsDataAsync();
	}

	async getStudentAnalyticsDataAsync() {
		try {
			this.isLoading = true;
			this.dataSrc = await this._analyticsService.getStudnetAnalyticsData(
				this.platformType,
				this.integrationId,
				this.studentId,
				this.dateRange,
				this.token
			);
			if (this.isFirstLoad) {
				this.isFirstLoad = false;
				this.dateRange.startMonth = this.dataSrc.startAnalyticsDate.getMonth() + 1;
				this.dateRange.startYear = this.dataSrc.startAnalyticsDate.getFullYear();
				this.baseStartDate.setMonth(this.dataSrc.startAnalyticsDate.getMonth());
				this.baseStartDate.setFullYear(this.dataSrc.startAnalyticsDate.getFullYear());
			}
			// has student name -> update breadCrumbs.
			if (this.dataSrc.studentName)
				this.breadCrumbs = [
					{
						name: this.dataSrc.studentName,
						url: '/',
					},
				];

			if (!this.dataSrc.writingFeedbackFeatureEnabled) {
				this.dataSrc.authorOverviewData.cardsData.avgWritingFeedback.value = 'N/A';
				this.dataSrc.authorOverviewData.cardsData.avgWritingFeedback.isDisabled = true;
			}
		} catch (error) {
			if (!this._analyticsService.redirectOnErroredRequest(error)) this._clsAlertSvc.showSupportError();
		} finally {
			this.isLoading = false;
		}
	}

	dateRangeChange(newDateRange: IDateRange) {
		this.dateRange = {
			...newDateRange,
		};
		this.getStudentAnalyticsDataAsync();
	}
}
