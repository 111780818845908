import { Component, Input } from '@angular/core';

@Component({
	selector: 'lms-integration-item-badge',
	templateUrl: './lms-integration-item-badge.component.html',
	styleUrls: ['./lms-integration-item-badge.component.scss'],
})
export class LmsIntegrationItemBadgeComponent {
	/**
	 * The source of the item image.
	 * @Input
	 */
	@Input() imageSrc: string;

	/**
	 * The container bedage width.
	 * @Input
	 */
	@Input() containerWidth: string = '64px';
	/**
	 * The container bedage height.
	 * @Input
	 */
	@Input() containerHeight: string = '64px';

	/**
	 * The image height.
	 * @Input
	 */
	@Input() imageHeight: string;

	/**
	 * The image width.
	 * @Input
	 */
	@Input() imageWidth: string;
	constructor() {}
}
