import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { WindowService } from '@ngx-common/services/window.service';

@Injectable({
	providedIn: 'root',
})
export class DashboardErrorPagesRedirectsGuard implements CanActivate {
	constructor(private _WindowSvc: WindowService, private _router: Router) {}
	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		if (state.url.startsWith('/guides')) {
			this._WindowSvc.open(`https://help.copyleaks.com/learning-management-systems-lms`, '_blank');
			this._router.navigate(['integrations/add'], { replaceUrl: true });
			return false;
		}
		if (state.url.startsWith('/legal')) {
			this._WindowSvc.open(`https://copyleaks.com/termsofuse/opensource`, '_blank');
			this._router.navigate(['integrations/add'], { replaceUrl: true });
			return false;
		}
		return true;
	}
}
