import { Component, Input } from '@angular/core';

@Component({
	selector: 'analytics-empty-state',
	templateUrl: './analytics-empty-state.component.html',
	styleUrls: ['./analytics-empty-state.component.scss'],
})
export class AnalyticsEmptyStateComponent {
	/**
	 * Optional - Used to set id for scroling to this element.
	 */
	@Input() id: string;
}

@Component({
	selector: 'analytics-empty-state-title',
	template: `
		<ng-content></ng-content>
	`,
})
export class AnalyticsEmptyStateTitle {}

@Component({
	selector: 'analytics-empty-state-description',
	template: `
		<ng-content></ng-content>
	`,
})
export class AnalyticsEmptyStateDescription {}

@Component({
	selector: 'analytics-empty-state-bottom-section',
	template: `
		<ng-content></ng-content>
	`,
})
export class AnalyticsEmptyStateBottomSection {}

@Component({
	selector: 'analytics-empty-state-image',
	template: `
		<ng-content></ng-content>
	`,
})
export class AnalyticsEmptyStateImage {}
