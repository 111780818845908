import { Component, ViewChild } from '@angular/core';
import { ScreenService } from '../../../../services/screen.service';
import { NewDashboardNavbarV2Service } from '../../services/new-dashboard-navbar-v2.service';

@Component({
	selector: 'cls-dashboard-links-v2',
	templateUrl: './dashboard-links-v2.component.html',
	styleUrls: ['./dashboard-links-v2.component.scss'],
})
export class DashboardLinksComponent {
	@ViewChild('sideNavBar', { static: false }) sideNavBar;
	get isMobile() {
		return this._screenSvc?.isMobile();
	}

	get isDrawerOpen() {
		return this._newDashboardNavbarSvc?.MatDrawer?.opened;
	}

	constructor(private _screenSvc: ScreenService, private _newDashboardNavbarSvc: NewDashboardNavbarV2Service) {}
}
