import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentProcessingComponent } from './payment-processing.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { PaymentModule } from '../../components/payment/payment.module';

@NgModule({
	declarations: [PaymentProcessingComponent],
	exports: [PaymentProcessingComponent],
	imports: [CommonModule, MatProgressBarModule, PaymentModule],
})
export class PaymentProcessingModule {}
