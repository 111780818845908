import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PanelComponent, PanelHeader, PanelActions, PanelBody } from './panel/panel.component';

@NgModule({
	declarations: [PanelComponent, PanelHeader, PanelActions, PanelBody],
	exports: [PanelComponent, PanelHeader, PanelActions, PanelBody],
	imports: [CommonModule],
})
export class PanelModule {}
