import * as moment from 'moment';

export function downloadDataToCsv(dataToExport: any[], fileName: string, dateFormate?: string) {
	const headerRow = Object.keys(dataToExport[0]);
	const csvData = [headerRow.join(',')];

	dataToExport.forEach(item => {
		const row = headerRow.map(fieldName => {
			if (!!dateFormate && item[fieldName] instanceof Date) {
				return moment(item[fieldName]).format(dateFormate);
			} else return item[fieldName];
		});
		csvData.push(row.join(','));
	});

	const csvContent = csvData.join('\n');
	const blob = new Blob([csvContent], { type: 'text/csv' });
	const url = window.URL.createObjectURL(blob);

	const a = document.createElement('a');
	a.href = url;
	a.download = `${fileName}.csv`;
	document.body.appendChild(a);
	a.click();
	window.URL.revokeObjectURL(url);
	document.body.removeChild(a);
}
