import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnalyticsStudentSubmissionsTableComponent } from './analytics-student-submissions-table.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ClsContainedContentModule } from 'ngx-common-v2/components/cls-contained-content/cls-contained-content.module';
import { ClsPaginatorModule } from 'ngx-common-v2/components/cls-paginator/cls-paginator.module';
import { ClsScoreLevelModule } from 'ngx-common-v2/components/cls-score-level/cls-score-level.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ClsSpinnerModule } from 'ngx-common-v2/components/cls-spinner/cls-spinner.module';
import { MatChipsModule } from '@angular/material/chips';
import { AnalyticsDataService } from '../../services/analytics-data.service';
import { AnalytiscUtilsService } from '../../services/analytics.utils';
import { LMSWritingFeedbackScoreModule } from 'shared/componenets/lms-writing-feedback-score/lms-writing-feedback-score.module';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';

@NgModule({
	declarations: [AnalyticsStudentSubmissionsTableComponent],
	exports: [AnalyticsStudentSubmissionsTableComponent],
	imports: [
		CommonModule,
		NgxSkeletonLoaderModule,
		MatTooltipModule,
		MatIconModule,
		ClsPaginatorModule,
		ClsContainedContentModule,
		ClsScoreLevelModule,
		MatTableModule,
		FlexLayoutModule,
		MatChipsModule,
		ClsSpinnerModule,
		LMSWritingFeedbackScoreModule,
		MatButtonModule,
		RouterModule,
	],
	providers: [AnalyticsDataService, AnalytiscUtilsService],
})
export class AnalyticsStudentSubmissionsTableModule {}
