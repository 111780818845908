import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselComponent } from './carousel.component';
import { NgxHmCarouselModule } from 'ngx-hm-carousel';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
	declarations: [CarouselComponent],
	exports: [CarouselComponent],
	imports: [CommonModule, NgxHmCarouselModule, FormsModule, MatIconModule, MatTooltipModule],
})
export class CarouselModule {}
