import { Component, Input, OnInit } from '@angular/core';
import { EGeneralNotificationEventType } from './models/notification-item-general.enums';
import { IGeneralNotification } from './models/notification-item-general.models';

@Component({
	selector: 'cls-notification-item-general',
	templateUrl: './notification-item-general.component.html',
	styleUrls: ['./notification-item-general.component.scss'],
})
export class NotificationItemGeneralComponent implements OnInit {
	@Input() model: IGeneralNotification = null;
	@Input() isRead: boolean = false;

	eGeneralNotificationEventType = EGeneralNotificationEventType;

	constructor() {}

	ngOnInit(): void {}
}
