import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { environment } from '@environment';
import { ECopyleaksAPP, NgxCommonPagesConfig } from '../pages/pages.config';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class UserOrganizationService {
	private _organizationInfo$ = new BehaviorSubject<IOrganizationInfo>(null);

	public get organizationInfo$() {
		return this._organizationInfo$;
	}
	public get organizationInfo() {
		return this._organizationInfo$.value;
	}
	public set organizationInfo(value: IOrganizationInfo) {
		this._organizationInfo$.next(value);
	}

	constructor(private _http: HttpClient, @Inject(NgxCommonPagesConfig.key) private _config: NgxCommonPagesConfig) {}

	async initSvc() {
		try {
			this.organizationInfo = await this.getOrganizationInfo();
		} catch (error) {
			/** no need to show message for users for this error */
			console.error('failed to load organization info');
		}
	}

	async getOrganizationInfo() {
		var result = await this._http
			.get<IOrganizationInfo>(`${environment.apiUrl}/v1/account/info/organization-has-logo`)
			.toPromise();
		if (result.hasLogo) {
			result.logoUrl = `${environment.apiUrl}/v1/account/info/organization-logo`;
		}
		return result;
	}

	async deleteOrganizationLogoAsync() {
		await this._http.delete(`${environment.apiUrl}/v1/account/info/organization-logo`).toPromise();
		this.organizationInfo = {
			...this.organizationInfo,
			hasLogo: false,
			logoUrl: null,
		};
	}
}

export interface IOrganizationInfo {
	hasLogo: boolean;
	logoUrl?: string;
}
