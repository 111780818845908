export enum EActionListItem {
	AccountListItem = 'accountListItem',
	SecurityListItem = 'securityListItem',
	AuditsLogListItem = 'auditsLogListItem',
	TeamsListItem = 'teamsListItem',
	RepositoriesListItem = 'repositoriesListItem',
	BillingListItem = 'billingListItem',
}

export enum EDashboardActionEvents {
	openExternalLink,
	openEnternalLink,
	openPaymentDialog,
}
