import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

export enum ESnackbarMessageType {
	Success,
	Info,
	Warn,
	Error,
	VersionUpdate,
}

@Component({
	selector: 'cls-custom-snackbar',
	templateUrl: './custom-snackbar.component.html',
	styleUrls: ['./custom-snackbar.component.scss'],
})
export class CustomSnackbarComponent {
	eSnackbarType = ESnackbarMessageType;

	constructor(
		@Inject(MAT_SNACK_BAR_DATA) public data: ICustomSnackBarData,
		public snackbarRef: MatSnackBarRef<CustomSnackbarComponent>
	) {}

	public static getPanelClassByType(type: ESnackbarMessageType) {
		switch (type) {
			case ESnackbarMessageType.Success:
				return 'success-snackbar';
			case ESnackbarMessageType.Info:
				return 'info-snackbar';
			case ESnackbarMessageType.Warn:
				return 'warn-snackbar';
			case ESnackbarMessageType.Error:
				return 'error-snackbar';
			default:
				throw new Error('Not supported type of snackbar message');
		}
	}

	actionClick() {
		this.snackbarRef.dismissWithAction();
	}
	close() {
		this.snackbarRef.dismiss();
	}
}

export interface ICustomSnackBarData {
	type: ESnackbarMessageType;
	message: string;
	actionName: string;
}
