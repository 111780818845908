import { EProductType } from '@ngx-common/enums/product-type.enums';
import { EPlatformProductType } from './pricing.model';

export function GetProductName(productType: EProductType): string {
	if (productType == EProductType.Repositories) {
		return $localize`Repository`;
	}
	if (productType == EProductType.Supports) {
		return $localize`Support`;
	}
}

export function GetPlatformProductName(platformProduct: EPlatformProductType): string {
	switch (platformProduct) {
		case EPlatformProductType.Bundled:
			return $localize`AI + Plagiarism Detector`;
		case EPlatformProductType.AIDetector:
			return $localize`AI Content Detector`;
		case EPlatformProductType.PlagiarismChecker:
			return $localize`Plagiarism Detector`;
	}
}
