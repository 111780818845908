import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EIntegrationConsumer } from 'projects/Dashboard/src/app/pages/integrations/models/integrations.enums ';

@Component({
	selector: 'disabled-analytics-view',
	templateUrl: './disabled-analytics-view.component.html',
	styleUrls: ['./disabled-analytics-view.component.scss'],
})
export class DisabledAnalyticsViewComponent implements OnInit {
	@Input() platformType: EIntegrationConsumer;
	@Input() scanSettingsId: string;
	@Input() integrationId: string;
	@Input() isAdmin = true;

	link: string;

	constructor() {}

	ngOnInit(): void {
		this.assignSettingsLink();
	}

	assignSettingsLink() {
		if (!this.isAdmin || !this.scanSettingsId) {
			return;
		}
		let route = 'dashboard/';
		switch (this.platformType) {
			case EIntegrationConsumer.LTI1_3:
				route += 'lti1.3';
				break;
			case EIntegrationConsumer.Canvas:
				route += 'canvas';
				break;
			case EIntegrationConsumer.Moodle:
				route += 'moodle';
				break;
		}
		this.link = `${route}/settings/${this.integrationId}/${this.scanSettingsId}`;
	}
}
