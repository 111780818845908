<div class="main-layout-wrapper">
	<!-- Status message bar -->
	<ng-container *ngIf="(app == eCopyleaksAPP.MainWebsite || app == eCopyleaksAPP.API) && !isGuestUser">
		<app-status-message-banner (statusUpdate)="copyleaksStatus = $event"></app-status-message-banner>
	</ng-container>
	<!-- Drawer container -->
	<mat-drawer-container [hasBackdrop]="isMobile">
		<!-- Mobile drawer -->
		<mat-drawer
			*ngIf="isMobile"
			#mobileDrawer
			mode="over"
			[style.width]="!mobileSidenav?.opened ? '0' : ''"
			position="end">
			<div fxLayout="column" fxLayoutAlign="space-between start" class="mobile-drawer-items">
				<button
					mat-icon-button
					color="primary"
					[autofocus]="false"
					class="close-drawer-btn"
					(click)="toggleSideNav()"
					aria-label="close">
					<mat-icon>close</mat-icon>
				</button>
				<div fxLayout="column" fxLayoutGap="16px" class="mobile-drawer-header-section-container">
					<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
						<div *ngIf="orgLogo" class="organization-logo-container" fxLayoutAlign="center center">
							<img alt="Organization Mobile Logo" class="organization-logo" [src]="orgLogo" />
						</div>
						<cls-user-plan
							*ngIf="isLoggedIn && app !== eCopyleaksAPP.LMS"
							[userBalance]="userBalance"
							[user]="user"
							[sharedAccount]="sharedAccount"
							[sharedAccountName]="sharedAccountName"
							[isMobile]="isMobile"></cls-user-plan>
					</div>
					<div class="mobile-drawer-divider"></div>
					<ng-container
						*ngIf="(!isLoggedIn || isGuestUser) && (app === eCopyleaksAPP.MainWebsite || app === eCopyleaksAPP.LMS)">
						<div class="login-signup-actions" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="16px">
							<cls-button (click)="signUp()" class="sign-up-button" i18n>Sign up</cls-button>
							<cls-button (click)="login()" [outlined]="true" i18n>Log In</cls-button>
						</div>
					</ng-container>
					<cls-account-toolbar-menu
						*ngIf="!isGuestUser && isLoggedIn"
						[user]="user"
						[isLoggedIn]="isLoggedIn"
						[userAvatar]="userAvatar"
						[isUserJustAnOrganizationMember]="isUserJustAnOrganizationMember"
						[isUserPartOfOrganization]="isUserPartOfOrganization"
						[isUserAnOrganizationOwner]="isUserAnOrganizationOwner"></cls-account-toolbar-menu>
					<div class="mobile-drawer-divider"></div>
					<div class="side-nav-routes" *ngIf="mobileSidenav?.opened">
						<ng-container *ngTemplateOutlet="clsDashboardLinksRef?.sideNavBar"></ng-container>
					</div>
				</div>
				<div fxLayout="column" fxLayoutGap="16px" class="mobile-drawer-footer-section-container">
					<div
						*ngIf="isLoggedIn && !isGuestUser"
						fxLayout="row"
						fxLayoutAlign="space-around center"
						class="copyleaks-apps-container">
						<button *ngIf="app !== eCopyleaksAPP.LMS" mat-button (click)="openExternalLink(environment.ltiUrl)">
							<div fxLayout="column" fxLayoutAlign="space-between center" fxLayoutGap="5px">
								<img alt="LTI Fav Icon" src="../../../assets/websites-icons/lti-app-favicon.svg" />
								<span>LMS</span>
							</div>
						</button>
						<button
							*ngIf="app !== eCopyleaksAPP.API"
							mat-button
							(click)="openExternalLink(environment.apiFrontEndUrl + '/dashboard')">
							<div fxLayout="column" fxLayoutAlign="space-between center" fxLayoutGap="5px">
								<img alt="API Fav Icon" src="../../../assets/websites-icons/api-favicon.svg" />
								<span>API</span>
							</div>
						</button>
						<button
							*ngIf="app !== eCopyleaksAPP.MainWebsite"
							mat-button
							(click)="openExternalLink(environment.dashboardWebsiteUrl)">
							<div fxLayout="column" fxLayoutAlign="space-between center" fxLayoutGap="5px">
								<img alt="MainWebsite Fav Icon" src="../../../assets/websites-icons/default-favicon.svg" />
								<span>PLATFORM</span>
							</div>
						</button>
						<button
							*ngIf="app !== eCopyleaksAPP.Admin && !isUserJustAnOrganizationMember"
							mat-button
							(click)="openExternalLink(environment.adminWebsiteUrl)">
							<div fxLayout="column" fxLayoutAlign="space-between center" fxLayoutGap="5px">
								<img alt="Admin Fav Icon" src="../../../assets/websites-icons/admin-app-favicon.svg" />
								<span>ADMIN</span>
							</div>
						</button>
					</div>
					<div class="mobile-drawer-divider"></div>
					<div fxLayout="row">
						<div fxLayoutAlign="center center" class="terms-of-use-link-container">
							<a (click)="openExternalLink(environment.mainWebsiteUrl + '/legal/termsofuse')" i18n>Terms of use</a>
						</div>
						<div fxLayoutAlign="center center" class="terms-of-use-link-container">
							<a class="cky-banner-element" href="javascript:void(0);" i18n>Manage Cookies</a>
						</div>
					</div>
				</div>
			</div>
		</mat-drawer>
		<!-- Desktop drawer -->
		<mat-drawer
			#drawer
			class="mat-drawer-desktop"
			*ngIf="!isMobile"
			position="start"
			[opened]="!isMobile"
			mode="side"
			[@shrinkWidth]="showSideNavIconsOnly">
			<div
				class="desktop-drawer-items"
				[ngStyle]="{ padding: showSideNavIconsOnly ? '12px 0px 8px' : '16px 7px' }"
				fxLayout="column"
				fxLayoutAlign="space-between start">
				<div fxLayout="column" fxLayoutGap="24px" style="width: 100%">
					<div
						[fxLayoutAlign]="showSideNavIconsOnly ? 'center center' : 'start center'"
						[ngStyle]="{ 'padding-left': !showSideNavIconsOnly ? '8px' : '0px' }">
						<ng-container *ngTemplateOutlet="logo"></ng-container>
					</div>
					<div class="side-nav-routes">
						<ng-container *ngTemplateOutlet="clsDashboardLinksRef?.sideNavBar"></ng-container>
					</div>
				</div>
				<div fxLayout="column" fxLayoutGap="4px" class="toggle-lists-and-close-btn-container">
					<div fxLayout="column" fxLayoutGap="32px" style="width: 100%">
						<div *ngIf="app !== eCopyleaksAPP.Admin && isUserLoaded" fxLayoutAlign="center center" style="width: 100%">
							<ng-container *ngTemplateOutlet="organizationToggleList"></ng-container>
						</div>
						<div fxLayoutAlign="center center" style="width: 100%">
							<ng-container *ngTemplateOutlet="learnMoreToggleList"></ng-container>
						</div>
					</div>
					<div
						[fxLayoutAlign]="!showSideNavIconsOnly ? 'end center' : 'center center'"
						class="drawer-close-icon-container">
						<button
							aria-label="toggle"
							*ngIf="allowCollapsedSideNav"
							fxLayoutAlign="center center"
							mat-icon-button
							class="btn-toggle"
							(click)="toggleSideNavDisplay(matDrawerContent)"
							i18n-matTooltip
							matTooltip="Toggle">
							<img
								[ngClass]="showSideNavIconsOnly ? 'open-side-nav-icon' : 'close-side-nav-icon'"
								src="assets/icons/close-sidenav-icon.svg"
								alt="expend-sidenav" />
						</button>
					</div>
				</div>
			</div>
		</mat-drawer>
		<!-- Drawer content -->
		<mat-drawer-content
			fxLayout="column"
			#matDrawerContent
			[style.background-color]="isNewScanPage || isSharedWithMePage || isTextComparePage ? '#ebf3f5' : '#fbffff'">
			<!-- Top toolbar -->
			<ng-container *ngTemplateOutlet="topToolbar"></ng-container>
			<!-- Page content -->

			<div
				class="main-layout-content-container"
				[ngClass]="{
					'remove-side-padding': removeSidePadding,
					'main-layout-content-container-background-color': isIdentityBillingTap
				}">
				<div *ngIf="app == eCopyleaksAPP.Identity" responsive-container>
					<ng-container *ngTemplateOutlet="pageContent"></ng-container>
				</div>
				<ng-container *ngIf="app !== eCopyleaksAPP.Identity">
					<ng-container *ngTemplateOutlet="pageContent"></ng-container>
				</ng-container>
			</div>
			<!-- Mobile bottom menu -->
			<div *ngIf="isMobile" class="mobile-bottom-container">
				<app-mobile-bottom-menu></app-mobile-bottom-menu>
			</div>
		</mat-drawer-content>
	</mat-drawer-container>
</div>

<!-- @@@@@@ Templates section @@@@@@ -->

<!-- Website logo template -->
<ng-template #logo>
	<ng-content select="cls-dashboard-logo-v2"></ng-content>
</ng-template>
<!-- Page content template -->
<ng-template #pageContent>
	<ng-content></ng-content>
</ng-template>
<!-- Top toolbar template -->
<ng-template #topToolbar>
	<mat-toolbar class="top-toolbar">
		<div *ngIf="!isMobile" fxLayout="row" fxLayoutAlign="space-between center" class="desktop-toolbar-container">
			<!-- Breadcrumbs -->
			<div *ngIf="!pageTitle" class="bread-crumbs-container" fxFlex="1 1 100%">
				<cls-dynamic-breadcrumb-navigation
					[navPaths]="breadcrumbNavigation$ | async"
					fxLayoutAlign="start center"
					[menuStartIndex]="breadcrumbNavigationMenuStartIndex"
					[menuEndIndex]="breadcrumbNavigationMenuEndIndex"></cls-dynamic-breadcrumb-navigation>
			</div>
			<!-- Scan page title -->
			<div class="page-title-container" fxLayout="row" fxLayoutGap="24px" *ngIf="pageTitle">
				<button
					*ngIf="pageTitle.backLink"
					(click)="openInternalLink(pageTitle.backLink)"
					mat-button
					class="page-title-back-button">
					<mat-icon>keyboard_arrow_left</mat-icon>
					<span>Back</span>
				</button>
				<div class="page-title" fxLayout="row" fxLayoutGap="12px" fxLayoutAlign="center center">
					<mat-icon *ngIf="pageTitle?.icon">{{ pageTitle.icon }}</mat-icon>
					<span>{{ pageTitle.name }}</span>
				</div>
			</div>
			<!-- account settings and actions section -->
			<div fxLayout="row" fxLayoutGap="40px">
				<div fxLayout="row" *ngIf="isLoggedIn" fxLayoutAlign="center center" fxLayoutGap="16px">
					<!-- User plan area -->
					<div *ngIf="app !== eCopyleaksAPP.LMS" fxLayoutAlign="center center" fxLayoutGap="16px">
						<cls-user-plan
							(externalLinkTriggered)="openExternalLink($event)"
							[app]="app"
							[user]="user"
							[userBalance]="userBalance"
							[sharedAccount]="sharedAccount"
							[sharedAccountName]="sharedAccountName"
							[isMobile]="isMobile"></cls-user-plan>
						<!-- #End User plan area -->
					</div>
					<!-- User actions area -->
					<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px" *ngIf="!isGuestUser">
						<cls-apps-toolbar-menu
							[isUserJustAnOrganizationMember]="isUserJustAnOrganizationMember"></cls-apps-toolbar-menu>
						<cls-help-toolbar-menu [userBalance]="userBalance" [user]="user"></cls-help-toolbar-menu>
						<cls-notifcations-toolbar-menu *ngIf="app !== eCopyleaksAPP.LMS"></cls-notifcations-toolbar-menu>
						<!-- Organization Logo - Desktop -->
						<div *ngIf="orgLogo" class="organization-logo-container" fxLayoutAlign="center center">
							<img alt="Organization Logo" draggable="false" class="organization-logo" [src]="orgLogo" />
						</div>
						<cls-account-toolbar-menu
							*ngIf="!isGuestUser"
							[user]="user"
							[isLoggedIn]="isLoggedIn"
							[userAvatar]="userAvatar"
							[isUserJustAnOrganizationMember]="isUserJustAnOrganizationMember"
							[isUserPartOfOrganization]="isUserPartOfOrganization"
							[isUserAnOrganizationOwner]="isUserAnOrganizationOwner"></cls-account-toolbar-menu>
					</div>
					<!-- #End User actions area -->
				</div>
				<!-- if user is not logged in -->
				<div *ngIf="(!isLoggedIn || isGuestUser) && (app === eCopyleaksAPP.MainWebsite || app === eCopyleaksAPP.LMS)">
					<div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="15px">
						<button aria-label="login" mat-button (click)="login()" color="primary" i18n>Login</button>
						<cls-button width="160px" (click)="signUp()" class="sign-up-button" i18n>Create Account</cls-button>
					</div>
				</div>
			</div>
		</div>
		<div *ngIf="isMobile" class="mobile-toolbar-container" fxLayout="row" fxLayoutAlign="space-between center">
			<div fxLayoutAlign="center center">
				<ng-container *ngTemplateOutlet="logo"></ng-container>
			</div>
			<div class="side-nav-routes" *ngIf="!mobileSidenav?.opened">
				<ng-container *ngTemplateOutlet="clsDashboardLinksRef?.sideNavBar"></ng-container>
			</div>
			<button mat-icon-button color="primary" (click)="toggleSideNav()">
				<mat-icon>menu</mat-icon>
			</button>
		</div>
	</mat-toolbar>
</ng-template>
<!-- Upgrades list template -->
<ng-template #organizationToggleList>
	<cls-toggle-list
		*ngIf="!isGuestUser && !isUserJustAnOrganizationMember"
		[ngStyle]="{ display: showSideNavIconsOnly ? 'none' : 'flex' }"
		listTitle="Organization">
		<cls-toggle-list-item
			*ngIf="app != eCopyleaksAPP.LMS && isUserPartOfOrganization"
			[isExternalLink]="true"
			[link]="environment.adminWebsiteUrl">
			<cls-toggle-list-item-icon>corporate_fare</cls-toggle-list-item-icon>
			<cls-toggle-list-item-text i18n>Manage</cls-toggle-list-item-text>
		</cls-toggle-list-item>
		<cls-toggle-list-item
			*ngIf="app != eCopyleaksAPP.LMS && !isUserPartOfOrganization"
			[isExternalLink]="true"
			[link]="environment.adminWebsiteUrl + '/analytics'">
			<cls-toggle-list-item-icon>data_usage</cls-toggle-list-item-icon>
			<cls-toggle-list-item-text i18n>Analytics</cls-toggle-list-item-text>
		</cls-toggle-list-item>
		<cls-toggle-list-item
			*ngIf="app != eCopyleaksAPP.LMS && !isUserPartOfOrganization"
			[tagText]="'1 Free'"
			[isExternalLink]="true"
			[link]="environment.adminWebsiteUrl + '/members'">
			<cls-toggle-list-item-icon>group</cls-toggle-list-item-icon>
			<cls-toggle-list-item-text i18n>Members</cls-toggle-list-item-text>
		</cls-toggle-list-item>
		<cls-toggle-list-item
			linkImgSrc="../../../assets/icons/repositories-icon.svg"
			[isExternalLink]="true"
			[link]="environment.adminWebsiteUrl + '/repositories'">
			<cls-toggle-list-item-text i18n>Repositories</cls-toggle-list-item-text>
		</cls-toggle-list-item>
	</cls-toggle-list>
</ng-template>
<!-- Learn more list template -->
<ng-template #learnMoreToggleList>
	<cls-toggle-list [ngStyle]="{ display: showSideNavIconsOnly ? 'none' : 'flex' }" listTitle="Learn More">
		<cls-toggle-list-item
			[hasIcon]="false"
			*ngIf="app === eCopyleaksAPP.LMS"
			[isExternalLink]="true"
			[link]="environment.helpCenterLink + '/learning-management-systems-lms'">
			<cls-toggle-list-item-text i18n>LMS/LTI Guides</cls-toggle-list-item-text>
		</cls-toggle-list-item>
		<cls-toggle-list-item
			*ngIf="app === eCopyleaksAPP.API"
			[hasIcon]="false"
			[isExternalLink]="true"
			[link]="'https://github.com/Copyleaks/ng-web-report'">
			<cls-toggle-list-item-text i18n>Report Integration</cls-toggle-list-item-text>
		</cls-toggle-list-item>
		<cls-toggle-list-item
			*ngIf="app === eCopyleaksAPP.API"
			[hasIcon]="false"
			[isExternalLink]="false"
			[link]="'/' + documentationRoute">
			<cls-toggle-list-item-text i18n>Documentation</cls-toggle-list-item-text>
		</cls-toggle-list-item>
		<cls-toggle-list-item
			*ngIf="app === eCopyleaksAPP.API"
			[hasIcon]="false"
			[isExternalLink]="false"
			[link]="'/' + documentationRoute + '/sdks'">
			<cls-toggle-list-item-text i18n>Official SDKs</cls-toggle-list-item-text>
		</cls-toggle-list-item>
		<cls-toggle-list-item [hasIcon]="false" [isExternalLink]="true" [link]="environment.helpCenterLink">
			<cls-toggle-list-item-text i18n>Support</cls-toggle-list-item-text>
		</cls-toggle-list-item>
		<cls-toggle-list-item
			*ngIf="app !== eCopyleaksAPP.API"
			[hasIcon]="false"
			[isExternalLink]="true"
			[link]="environment.mainWebsiteUrl + '/newfeatures'">
			<cls-toggle-list-item-text i18n>Features</cls-toggle-list-item-text>
		</cls-toggle-list-item>
		<cls-toggle-list-item [hasIcon]="false" [isExternalLink]="true" [link]="environment.mainWebsiteUrl + '/blog'">
			<cls-toggle-list-item-text i18n>Blog</cls-toggle-list-item-text>
		</cls-toggle-list-item>
		<cls-toggle-list-item [hasIcon]="false" [isPopUpLink]="true" class="cky-banner-element" onclick="return false">
			<cls-toggle-list-item-text i18n>Manage Cookies</cls-toggle-list-item-text>
		</cls-toggle-list-item>
	</cls-toggle-list>
</ng-template>
