import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';
import {
	CustomSnackbarComponent,
	ESnackbarMessageType,
	ICustomSnackBarData,
} from '../components/custom-snackbar/custom-snackbar.component';
import { LogsService } from './logs.service';
import { CLSTranslateService } from './translate.service';
import { AppDirectionalityService } from './app-directionality.service';

@Injectable({
	providedIn: 'root',
})
export class AppSnackbarService {
	isSessionSnackbarErrorShown = false;
	constructor(
		private _translateSvc: CLSTranslateService,
		private _snackBar: MatSnackBar,
		private _logSvc: LogsService,
		private readonly dir: AppDirectionalityService
	) {}

	showHttpResponseError(err: HttpErrorResponse, showDescriptionIfExists = false) {
		if (this._snackBar._openedSnackBarRef) {
			return;
		}

		if (showDescriptionIfExists && err.error) {
			if (err.error.description) {
				this.showCustomError(err.error.description, 15000);
				return;
			}
			if (err.error.Description) {
				this.showCustomError(err.error.Description, 15000);
				return;
			}
		}

		if (err?.status === 401) {
			return;
		}

		console.error(err);

		this.showCustomError(
			`<strong>Unknown Error:</strong><br>Please try again in a few seconds. If the error persists, please contact support@copyleaks.com for more help.`,
			150000
		);

		const errorString = JSON.stringify(err);
		this._logSvc.logErrorToServer(`$showHttpResponseError - ${errorString}`);
	}

	showError(message: string, duration?: number, actionName?: string) {
		return this._snackBar.open(message, actionName ? actionName : 'Close', {
			verticalPosition: 'top',
			horizontalPosition: 'right',
			duration: duration ? duration : 6000,
			panelClass: ['error-snackbar'],
		});
	}
	showCustomError(htmlMessage: string, duration?: number, actionName?: string) {
		return this.show(ESnackbarMessageType.Error, htmlMessage, duration, actionName);
	}

	showWarning(message: string, duration?: number, actionName?: string) {
		return this._snackBar.open(message, actionName ? actionName : 'Close', {
			verticalPosition: 'top',
			horizontalPosition: 'right',
			duration: duration ? duration : 6000,
			panelClass: ['warn-snackbar'],
		});
	}
	showCustomWarning(htmlMessage: string, duration?: number, actionName?: string) {
		return this.show(ESnackbarMessageType.Warn, htmlMessage, duration, actionName);
	}

	showSuccess(message: string, duration?: number, actionName?: string) {
		return this._snackBar.open(message, actionName ? actionName : 'Close', {
			verticalPosition: 'top',
			horizontalPosition: 'right',
			duration: duration ? duration : 6000,
			panelClass: ['success-snackbar'],
		});
	}
	showCustomSuccess(htmlMessage: string, duration?: number, actionName?: string) {
		return this.show(ESnackbarMessageType.Success, htmlMessage, duration, actionName);
	}

	showInfo(message: string, duration?: number, actionName?: string) {
		return this._snackBar.open(message, actionName ? actionName : 'Close', {
			verticalPosition: 'top',
			horizontalPosition: 'right',
			duration: duration ? duration : 6000,
			panelClass: ['info-snackbar'],
		});
	}
	showCustomInfo(htmlMessage: string, duration?: number, actionName?: string) {
		return this.show(ESnackbarMessageType.Info, htmlMessage, duration, actionName);
	}

	showVersionUpdateMessage() {
		return this.show(
			ESnackbarMessageType.VersionUpdate,
			`<p style='font-weight:600;margin-top:0;margin-bottom:10px;'>A new version of Copyleaks is available</p><span style='color:#7f8892;'>Refresh to get the latest version.</span>`,
			1800000,
			'Refresh',
			'update-version-snackbar'
		);
	}

	show(type: ESnackbarMessageType, htmlMessage: string, duration?: number, actionName?: string, panelClass?: string) {
		return this._snackBar.openFromComponent(CustomSnackbarComponent, {
			data: {
				type,
				message: htmlMessage,
				actionName,
			} as ICustomSnackBarData,
			verticalPosition: 'top',
			horizontalPosition: 'right',
			duration: duration ? duration : 6000,
			panelClass: panelClass ? panelClass : CustomSnackbarComponent.getPanelClassByType(type),
			direction: this.dir.value,
		});
	}
}
