<div fxLayot="row" fxLayoutAlign="center center">
	<button
		[disableRipple]="true"
		mat-button
		class="btn-container"
		[routerLink]="[link]"
		routerLinkActive="actions-active"
		[routerLinkActiveOptions]="{ exact: activeMatchExactRoute }">
		<div fxLayout="column" fxLayoutAlign="center center" class="btn-content">
			<div class="icon-container">
				<mat-icon
					*ngIf="!iconAsImgSrc"
					[matBadgeHidden]="unreadNotificationsCount == 0"
					matBadge="&#8288;"
					matBadgePosition="above after"
					matBadgeSize="small"
					matBadgeColor="peach"
					class="icon">
					<ng-content select="cls-mobile-bottom-item-icon"></ng-content>
				</mat-icon>
				<img class="icon" *ngIf="iconAsImgSrc" [src]="isActiveIconAsImgSrc ? activeIconAsImgSrc : iconAsImgSrc" />
			</div>
			<span class="text">
				<ng-content select="cls-mobile-bottom-item-text"></ng-content>
			</span>
		</div>
	</button>
</div>
