import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
	DefaultNotificationDescriptionComponent,
	DefaultNotificationItemComponent,
	DefaultNotificationTimeComponent,
} from './default-notification-item.component';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
	declarations: [
		DefaultNotificationItemComponent,
		DefaultNotificationDescriptionComponent,
		DefaultNotificationTimeComponent,
	],
	exports: [
		DefaultNotificationItemComponent,
		DefaultNotificationDescriptionComponent,
		DefaultNotificationTimeComponent,
	],
	imports: [CommonModule, MatIconModule, FlexLayoutModule],
})
export class DefaultNotificationItemModule {}
