<div class="layout-settings-container" [style.backgroundColor]="backgroundColor ? backgroundColor : ''">
	<div class="nav-settings-left">
		<img
			alt="Copyleaks-LMS"
			[src]="isMoblie ? '../../../assets/layout/lti-favicon.svg' : '../../../assets/layout/copyleaks-lms-logo.svg'" />
		<div style="margin-top: -8px">
			<ng-container *ngIf="breadCrumbs">
				<cls-breadcrumb-navigation>
					<ng-container *ngFor="let item of breadCrumbs">
						<!--[style]="isAdmin ? { 'margin-top': '-4.5px !important' } : {}" -->
						<cls-breadcrumb-item
							(itemClick)="itemClick.emit(item)"
							[last]="item == breadCrumbs[breadCrumbs?.length - 1]">
							<cls-breadcrumb-item-path i18n>{{ item.name }}</cls-breadcrumb-item-path>
						</cls-breadcrumb-item>
					</ng-container>
				</cls-breadcrumb-navigation>
			</ng-container>
			<ng-container *ngIf="!breadCrumbs">
				<div style="width: 300px; margin-top: 24px">
					<ngx-skeleton-loader animation="progress" appearance="line"></ngx-skeleton-loader>
				</div>
			</ng-container>
		</div>
	</div>

	<div class="nav-settings-right">
		<div class="img-nav-bar platform-logo">
			<!-- LMS Logo -->
			<ng-container *ngIf="platform == ePlatform.Canvas">
				<img alt="Canvas" src="../../../../assets/lms/canvas.jpg" />
			</ng-container>
			<ng-container *ngIf="platform == ePlatform.Moodle">
				<img alt="Moodle" src="../../../../assets/lms/moodle.png" />
			</ng-container>
			<ng-container *ngIf="showIcon && platform != ePlatform.Canvas && platform != ePlatform.Moodle">
				<mat-icon>account_balance</mat-icon>
			</ng-container>
		</div>
		<!-- Org Logo -->
		<div *ngIf="orgLogo" class="img-nav-bar institue-logo">
			<div class="top-toolbar-logo" fxLayoutAlign="center center">
				<img
					[src]="orgLogo"
					[alt]="isMoblie ? 'Organization Logo' : 'Organization Mobile Logo'"
					[class]="isMoblie ? 'organization-logo' : 'organization-logo-mobile'" />
			</div>
		</div>
	</div>
</div>
