import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IIntegrationsLinksDialogData } from './models/integrations-links-dialog.models';
import { UrlLocationService } from '@ngx-common/services/url-location.service';
import { EIntegrationConsumer } from '../../../integrations/models/integrations.enums ';

@Component({
	selector: 'app-integrations-links-dialog',
	templateUrl: './integrations-links-dialog.component.html',
	styleUrls: ['./integrations-links-dialog.component.scss'],
})
export class IntegrationsLinksDialogComponent implements OnInit {
	// Link Names (For translation)
	domainLink = $localize`Domain URL`;
	OIDCLink = $localize`OIDC Login URL`;
	redirectLink = $localize`Redirect URL`;
	keysetLink = $localize`Keyset URL`;
	registrationLinkMessage = $localize`Registration URL:`;
	keyMessage = $localize`Key:`;
	secretMessage = $localize`Secret:`;

	// Links URLs values
	integrationLinks: { redirectURL: string; oidcLoginURL: string; keysetURL: string; domain: string };

	EGeneralLMSIntegrationType = EIntegrationConsumer;

	constructor(
		public dialogRef: MatDialogRef<IntegrationsLinksDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: IIntegrationsLinksDialogData,
		private _locationSvc: UrlLocationService
	) {}

	ngOnInit(): void {
		this.dialogRef.addPanelClass('white-background-dialog');

		if (this.data?.integrationInfo?.type === EIntegrationConsumer.LTI1_3 && this.data?.integrationInfo?.id) {
			this.integrationLinks = {
				redirectURL: this._locationSvc.location.origin + '/api/lti/1.3/auth',
				oidcLoginURL: this._locationSvc.location.origin + '/api/lti/1.3/login',
				keysetURL:
					this._locationSvc.location.origin + `/api/lti/1.3/platform/${this.data?.integrationInfo?.id}/public-keyset`,
				domain: this._locationSvc.location.origin,
			};
		}
	}

	closeDialog(): void {
		this.dialogRef.close();
	}
}
