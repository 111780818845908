<form>
	<div fxLayout="row" fxLayoutGap="55.405px">
		<button mat-icon-button style="padding: 0" matTooltip="Back" i18n-matTooltip type="button" (click)="emitBack()">
			<mat-icon [ngStyle]="{ 'font-size': '30px', width: '30px' }">keyboard_backspace</mat-icon>
		</button>
		<h2 class="mat-dialog-title">
			<mat-icon>lock_open</mat-icon>
			<br />
			<span i18n>Confirm your email</span>
		</h2>
	</div>
	<div class="description" i18n="@USER_REGISTER.VERIFY_MFA_CODE_DIALOG.DESCRIPTION">
		A verification code has been sent to your email. Please enter the code below to verfiy your registeration.
	</div>

	<mat-dialog-content class="mat-typography" fxLayoutAlign="center center">
		<div class="verify-code-form">
			<div class="text" i18n="@@USER_REGISTER.VERIFY_MFA_CODE_DIALOG.ENTER_CODE_TXT">
				<strong>Enter the 6 digit code</strong>
			</div>
			<code-input
				#codeInput
				[isCodeHidden]="false"
				[code]="code"
				[codeLength]="6"
				(codeChanged)="onCodeChanged($event)"></code-input>
			<mat-error>
				<ng-container
					*ngIf="clickBeforeComplete"
					i18n="@@USER_REGISTER.VERIFY_MFA_CODE_DIALOG.YOU_MUST_ENTER_CODE_ERR_MSG">
					Please enter the 6 digit code
				</ng-container>
				<ng-container *ngIf="codeErrorTxt">
					{{ codeErrorTxt }}
				</ng-container>
			</mat-error>
		</div>
	</mat-dialog-content>

	<mat-dialog-actions>
		<div fxLayout="column" fxLayoutAlign="center center">
			<div
				fxLayout="column"
				fxLayoutAlign="center center"
				fxLayoutGap="5px"
				*ngIf="!afterCodeResend"
				class="resend-link">
				<span i18n="@@USER_REGISTER.VERIFY_MFA_CODE_DIALOG.DIDNT_RECIEVE_CODE_MSG">Didn't receive a code?</span>
				<div fxLayout="row" fxLayoutGap="5px">
					<a
						(click)="resendActivationSeconds ? null : resendActivationCode()"
						*ngIf="!showResendSpinner"
						[matTooltipDisabled]="!resendActivationSeconds"
						[matTooltip]="'Resend will be available in ' + resendActivationSeconds + ' seconds'"
						[style]="resendActivationSeconds ? 'cursor: text; color: #999;' : 'cursor: pointer; color: #17a1ff;'"
						i18n>
						Send again
					</a>
					<cls-spinner *ngIf="showResendSpinner" width="20px"></cls-spinner>
				</div>
			</div>
			<div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px" *ngIf="afterCodeResend" class="resend-link">
				<span style="color: green; font-weight: bold" i18n="@@USER_REGISTER.VERIFY_MFA_CODE_DIALOG.CODE_RESENT_MSG">
					Code resent!
				</span>
				<mat-icon style="color: green">check_circle</mat-icon>
			</div>
			<ng-container *ngIf="!showSubmitSpinner">
				<button
					mat-button
					type="submit"
					[style.background-color]="'#17a1ff'"
					[style.color]="'white'"
					cdkFocusInitial
					(click)="verify()">
					<!--  -->
					<span i18n="@@USER_REGISTER.VERIFY_MFA_CODE_DIALOG.VERIFY_BTN">Verify</span>
					<!--  -->
				</button>
			</ng-container>
			<!--  -->
			<ng-container *ngIf="showSubmitSpinner">
				<div full-width fxLayout="row" fxLayoutAlign="center center">
					<cls-spinner width="50px"></cls-spinner>
				</div>
			</ng-container>
		</div>
	</mat-dialog-actions>
</form>
