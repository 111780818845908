import { Component, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime } from 'rxjs/operators';
import { eClsInputAppearance } from '../cls-data-input/models/cls-data-input.enums';

@UntilDestroy()
@Component({
	selector: 'cls-search-input',
	templateUrl: './cls-search-input.component.html',
	styleUrls: ['./cls-search-input.component.scss'],
})
export class ClsSearchInputComponent implements OnInit {
	/**
	 * The input data value
	 * @Input
	 */
	@Input() value: string = '';

	/**
	 * The input appereance:
	 * 1) 'outline' for input with white background
	 * 2) 'fill' for input with the custom input color
	 * @Input
	 */
	@Input() appearance: eClsInputAppearance;

	/**
	 * The input type
	 * @Input
	 */
	@Input() type: string;

	/**
	 * The input placeholder
	 * @Input
	 */
	@Input() placeholder: string;

	/**
	 * Flag indicating whether the input will disable the default bottom padding for the hints or error messages
	 * @Input
	 */
	@Input() noBottomPadding: boolean;

	/**
	 * Flag indicating whether the input will be disabled or not
	 * @Input
	 */
	@Input() disabled: boolean = false;

	/**
	 * Number of milliseconds until the confirm changes event is called
	 * @Input
	 */
	@Input() msecUntilChange: number = 1000;

	/**
	 * Flag indicating whether the input spell check is enabled or not
	 * @Input
	 */
	@Input() spellcheck: boolean = false;

	/**
	 * Flag indicating whether the input spell check is enabled or not
	 * @Input
	 */
	@Input() addIconBackground: boolean = true;

	/**
	 * Flag indicating whether the input will have a label or not
	 * @Input
	 */
	@Input() withLabel: boolean = false;

	/**
	 * Emit when the input save icon is pressed
	 * @Output
	 */
	@Output() confirmChange = new EventEmitter<string>();

	/**
	 * Host listener for the keyboard enter click event, which means that the changes are confirmed
	 * @param event The keyboard enter click event
	 */
	@HostListener('keydown.enter', ['$event'])
	onEnter(event: KeyboardEvent) {
		this.confirmSearchValue();
	}

	searchFormControl: FormControl = new FormControl('');
	changed: boolean;

	constructor() {}

	ngOnInit(): void {
		this.searchFormControl.valueChanges
			.pipe(untilDestroyed(this), debounceTime(this.msecUntilChange))
			.subscribe(value => {
				this.value = value;
				this.confirmSearchValue();
			});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.value) {
			this.searchFormControl.setValue(changes.value.currentValue);
		}

		if (changes.disabled?.currentValue) {
			this.searchFormControl.disable();
		} else {
			this.searchFormControl.enable();
		}
	}

	/**
	 * Clear the data input
	 * @public
	 */
	clearSearchValue() {
		this.value = '';
		this.changed = true;
		this.searchFormControl.setValue('');
	}

	/**
	 * Call the save data input event
	 */
	confirmSearchValue() {
		if (this.changed) {
			this.confirmChange.emit(this.value);
			this.changed = false;
		}
	}

	/**
	 * Change the data input
	 * @public
	 */
	changeSearchValue(typedValue?: string) {
		this.value = typedValue;
		this.changed = true;
	}
}
