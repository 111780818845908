import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ChoosePaymentMethodModule } from '../../components/choose-payment-method/choose-payment-method.module';
import { PaymentModule } from '../../components/payment/payment.module';
import { SpinnerModule } from '../../components/spinner/spinner.module';
import { CreditCardDialogModule } from '../../dialogs/credit-card-dialog/credit-card-dialog.module';
import { BillingAddressModule } from '../billing-address/billing-address/billing-address.module';
import { PaymentCheckoutComponent } from './payment-checkout.component';
import { UnPauseSubscriptionDialogModule } from '../../dialogs/unpause-subscirption-dialog/unpause-subscirption-dialog.module';

@NgModule({
	declarations: [PaymentCheckoutComponent],
	exports: [PaymentCheckoutComponent],
	imports: [
		CommonModule,
		FlexLayoutModule,
		FormsModule,
		ReactiveFormsModule,
		ChoosePaymentMethodModule,
		CreditCardDialogModule,
		PaymentModule,
		SpinnerModule,
		MatIconModule,
		MatButtonModule,
		BillingAddressModule,
		UnPauseSubscriptionDialogModule,
	],
})
export class PaymentCheckoutComponentModule {}
