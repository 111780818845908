import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LMSSettingsNavbarComponent } from './lms-navbar.component';
import { MatIconModule } from '@angular/material/icon';
import { BreadcrumbNavigationProjModule } from '@ngx-common/components/breadcrumb-navigation-proj/breadcrumb-navigation.module';
import { DynamicBreadcrumbNavigationModule } from '@ngx-common/components/dynamic-breadcrumb-navigation/dynamic-breadcrumb-navigation.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@NgModule({
	declarations: [LMSSettingsNavbarComponent],
	exports: [LMSSettingsNavbarComponent],

	imports: [
		CommonModule,
		MatIconModule,
		DynamicBreadcrumbNavigationModule,
		BreadcrumbNavigationProjModule,
		NgxSkeletonLoaderModule,
	],
})
export class LmsNavbarModule {}
