<cls-new-dashboard-navbar-v2 class="new-navbar-v2" (clearOnLogOut)="logout()" fxFlex>
	<!-- Logo -->
	<cls-dashboard-logo-v2
		desktopImgUrl="../../../assets/layout/copyleaks-lms-logo.svg"
		mobileImgUrl="../../../assets/layout/lti-favicon.svg"
		link="/"></cls-dashboard-logo-v2>
	<!-- Logo -->

	<!-- Links -->
	<cls-dashboard-links-v2>
		<!-- Integrations page link -->
		<cls-dashboard-link-item-v2 link="/integrations" [activeMatchExactRoute]="true">
			<cls-dashboard-link-item-icon-v2>extension</cls-dashboard-link-item-icon-v2>
			<cls-dashboard-link-item-text-v2 i18n>Integrations</cls-dashboard-link-item-text-v2>
			<cls-dashboard-link-shrinked-item-text-v2 i18n>Integrations</cls-dashboard-link-shrinked-item-text-v2>
		</cls-dashboard-link-item-v2>
		<!-- Integrations page link -->

		<!-- Integrations page link -->
		<cls-dashboard-link-item-v2 link="/integrations/add" [activeMatchExactRoute]="true">
			<cls-dashboard-link-item-icon-v2>add</cls-dashboard-link-item-icon-v2>
			<cls-dashboard-link-item-text-v2 i18n>New integration</cls-dashboard-link-item-text-v2>
			<cls-dashboard-link-shrinked-item-text-v2 i18n>New</cls-dashboard-link-shrinked-item-text-v2>
		</cls-dashboard-link-item-v2>
		<!-- Integrations page link -->
	</cls-dashboard-links-v2>
	<!-- Links -->

	<ng-container *ngIf="!showSpinner">
		<section class="main-content-section">
			<router-outlet></router-outlet>
		</section>
	</ng-container>

	<section class="spinner-container" *ngIf="showSpinner">
		<cls-spinner width="130px"></cls-spinner>
	</section>
</cls-new-dashboard-navbar-v2>
