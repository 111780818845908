import { Direction } from '@angular/cdk/bidi';
import { isPlatformBrowser } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
	AfterViewInit,
	Component,
	ContentChild,
	ElementRef,
	EventEmitter,
	Inject,
	Input,
	OnInit,
	Output,
	PLATFORM_ID,
	ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDrawer, MatDrawerContent } from '@angular/material/sidenav';
import { SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { environment } from '@environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ClsAlertsService } from '@ngx-common-v2/components/cls-alerts/services/cls-alerts.service';
import { BehaviorSubject, delay, filter } from 'rxjs';
import { ClsToggleListComponent } from '../../../../ngx-common-v2/components/cls-toggle-list/cls-toggle-list.component';
import { BreadcrumbNavigationItem } from '../../../components/dynamic-breadcrumb-navigation/dynamic-breadcrumb-navigation.component';
import { CALENDRY_WIDGET } from '../../../constants/calendry-widget.consts';
import {
	CopyLeaksDialogComponent,
	ECopyLeaksDialogSubmitErrors,
	ICopyLeaksDialogData,
	ICopyLeaksDialogSubmittedData,
} from '../../../dialogs/copyleaks-dialog/copyleaks-dialog-component/copyleaks-dialog.component';
import { PaymentDialogService } from '../../../dialogs/payment-dialog/services/payment-dialog.service';
import { EAPIErrorCodes } from '../../../enums/error-codes.enum';
import { HttpStatusCode } from '../../../enums/http-status-code.enum';
import { ELocalStorageKeys } from '../../../enums/local-storage-keys.enum';
import { EPaymentPlanType } from '../../../enums/payment-plan-type.enum';
import { EUserKind } from '../../../enums/user-kinds.enum';
import { IAPICustomErrors } from '../../../models/api-custom-errors.model';
import { UserInfo } from '../../../models/auth.model';
import { EPlatformProductType } from '../../../models/pricing.model';
import { IUserBalance } from '../../../models/user-balance.models';
import { AllPersonalNotificationsService } from '../../../notifications/services/all-personal-notifications.service';
import { AccountPagesRoutes } from '../../../pages/account-pages/model/account-routings.model';
import { ECopyleaksAPP, NgxCommonPagesConfig } from '../../../pages/pages.config';
import { PricingPageRoutings } from '../../../pages/pricing-page/models/pricing-routings.model';
import { SecureImgPipe } from '../../../pipes/secure/secure-img.pipe';
import { AppDirectionalityService } from '../../../services/app-directionality.service';
import { AuthService } from '../../../services/auth.service';
import { LocalStorageService } from '../../../services/localstorage.service';
import { EGroupMemberStatus, ISharedAccountBalance, PaymentPlanService } from '../../../services/payment-plan.service';
import { ScreenService } from '../../../services/screen.service';
import { SupportService } from '../../../services/support.service';
import { UserOrganizationService } from '../../../services/user-organization.service';
import { loadCSS, loadScript } from '../../../utils/url-loaders.utils';
import { DashboardLinksComponent } from '../components/dashboard-links/dashboard-links-v2.component';
import { FeatureSuggestionComponent } from '../components/feature-suggestion/feature-suggestion.component';
import { IPageTitle } from '../models/sidenav-item.model';
import { EDashboardSideNavMode, NewDashboardNavbarV2Service } from '../services/new-dashboard-navbar-v2.service';
import { animate, state, style, transition, trigger } from '@angular/animations';

declare let Calendly: any;
@UntilDestroy()
@Component({
	selector: 'cls-new-dashboard-navbar-v2',
	templateUrl: './new-dashboard-navbar-v2.component.html',
	styleUrls: ['./new-dashboard-navbar-v2.component.scss'],
	animations: [
		trigger('shrinkWidth', [
			state(
				'true',
				style({
					width: '86px', // set the initial width when boolean is true
				})
			),
			state(
				'false',
				style({
					width: '204px', // set the final width when boolean is false
				})
			),
			transition('true => false, false => true', animate('200ms ease-in-out')), // set the animation duration and timing function
		]),
	],
})
export class NewDashboardNavbarV2Component implements OnInit, AfterViewInit {
	@Input() allowCollapsedSideNav = true;
	@Output() clearOnLogOut = new EventEmitter();

	@ViewChild('drawer') sidenav: MatDrawer;
	@ViewChild('mobileDrawer') mobileSidenav: MatDrawer;
	@ViewChild('matDrawerContent', { static: false, read: ElementRef })
	matDrawerContent: ElementRef;
	documentationRoute = 'documentation/v3';

	isLoadingBreadCrumbs = true;
	identityUrl = environment.idUrl;
	adminWebsiteUrl = environment.adminWebsiteUrl;
	breadcrumbNavigation$: BehaviorSubject<BreadcrumbNavigationItem[]>;
	pageTitle: IPageTitle;

	isLoggedIn = false;
	disableBookADemo = false;
	showSideNavIconsOnly = false;
	removeSidePadding = false;
	isNewScanPage = false;
	isSharedWithMePage = false;
	isTextComparePage = false;
	copyleaksStatus: boolean;
	unreadNotificationsCount: number;

	app: ECopyleaksAPP;
	user: UserInfo;
	userBalance: IUserBalance;
	userAvatar: IUserAvatar;
	environment = environment;

	sharedAccount: ISharedAccountBalance;
	sharedAccountName = 'loading...';

	breadcrumbNavigationMenuStartIndex: number = null;
	breadcrumbNavigationMenuEndIndex: number = null;

	eGroupMemberStatus = EGroupMemberStatus;
	accountPagesRoutes = AccountPagesRoutes;
	eCopyleaksAPP = ECopyleaksAPP;
	ePaymentPlanType = EPaymentPlanType;
	pricingPageRoutings = PricingPageRoutings;

	@ContentChild(DashboardLinksComponent)
	public readonly clsDashboardLinksRef: DashboardLinksComponent;
	public readonly clsDashboardToggleLinksRef: ClsToggleListComponent;

	public direction: Direction;
	public orgLogo: SafeUrl;
	public orgLogoIsLoading: boolean;

	get isMobile() {
		return this._screenSvc?.isMobile();
	}
	// we need to get the queryselector from the child dashboard link item
	get selectedListItemId() {
		const activeElement: HTMLAnchorElement = document.querySelector('.side-nav-actions-active');
		if (activeElement) {
			return activeElement.id.toString();
		}
		return null;
	}

	get isUserAnOrganizationOwner(): boolean {
		return this.user && this._authSvc.checkUserIfOrganizationOwner();
	}

	get isUserAnOrganizationAdmin(): boolean {
		return this.user && this._authSvc.checkUserIfOrganizationAdmin();
	}

	get isUserAnOrganizationMember(): boolean {
		return this.user && this._authSvc.checkUserIfOrganizationMember();
	}

	get isUserJustAnOrganizationMember(): boolean {
		return !this.isUserAnOrganizationOwner && !this.isUserAnOrganizationAdmin && this.isUserAnOrganizationMember;
	}

	get isUserPartOfOrganization(): boolean {
		return this._authSvc.checkIfIsPartOfOrganization();
	}

	get isUserLoaded() {
		return this._authSvc.user();
	}

	get isIdentityBillingTap() {
		return this.app == this.eCopyleaksAPP.Identity && this._router.url.includes('billing');
	}

	isGuestUser = false;

	constructor(
		@Inject(PLATFORM_ID) private _platformId: string,
		private _screenSvc: ScreenService,
		private _authSvc: AuthService,
		private _localStorageSvc: LocalStorageService,
		private _newDashboardNavbarSvc: NewDashboardNavbarV2Service,
		private _paymentPlanSvc: PaymentPlanService,
		private _paymentDialogSvc: PaymentDialogService,
		private _router: Router,
		private _supportSvc: SupportService,
		private _clsAlertsSvc: ClsAlertsService,
		private _userOrganizationSvc: UserOrganizationService,
		private _notificationsSvc: AllPersonalNotificationsService,
		private _dialogSvc: MatDialog,
		private _securePipe: SecureImgPipe,
		private readonly _dir: AppDirectionalityService,
		@Inject(NgxCommonPagesConfig.key) private _config: NgxCommonPagesConfig
	) {}

	ngAfterViewInit(): void {
		// set avatar image alt
		if (isPlatformBrowser(this._platformId)) {
			setTimeout(() => {
				if (this.userAvatar != null) {
					try {
						const collection = document.getElementsByClassName('avatar-content ng-star-inserted');
						if (collection.length != 0) {
							for (let index = 0; index < collection.length; index++) {
								const image = collection[index];
								image.setAttribute('alt', 'avatar');
							}
						}
					} catch {}
				}
			}, 1000);
		}
	}

	async ngOnInit() {
		this.app = this._config?.APP;
		this.direction = this._dir.value;
		if (isPlatformBrowser(this._platformId)) {
			if (this.app == this.eCopyleaksAPP.MainWebsite) {
				this._router.events.pipe(untilDestroyed(this)).subscribe(events => {
					if (events instanceof NavigationStart) {
						var urlChanges = events as NavigationStart;
						if (urlChanges?.url.includes('account/new-scan')) {
							this.isNewScanPage = true;
						} else if (urlChanges?.url.includes('account/shared-with-me')) {
							this.isSharedWithMePage = true;
						} else if (urlChanges?.url.includes('text-compare')) {
							this.isTextComparePage = true;
						} else {
							this.isNewScanPage = false;
							this.isSharedWithMePage = false;
							this.isTextComparePage = false;
						}
					}
				});
			}
		}

		if (
			this._authSvc.isLoggedIn() &&
			(this.app == ECopyleaksAPP.MainWebsite ||
				this.app == ECopyleaksAPP.API ||
				this.app == ECopyleaksAPP.Admin ||
				this.app == ECopyleaksAPP.Identity)
		) {
			this._notificationsSvc.loadNotificationsAsync(true);
			this._checkUnreadNotifications();
		}

		this._newDashboardNavbarSvc.removeSidePadding$.pipe(delay(0), untilDestroyed(this)).subscribe(value => {
			this.removeSidePadding = value;
		});

		this._newDashboardNavbarSvc.pageTitle$.pipe(untilDestroyed(this)).subscribe(value => {
			this.pageTitle = value;
		});

		this._newDashboardNavbarSvc.sideNavMode$.pipe(untilDestroyed(this)).subscribe(mode => {
			if (!this.allowCollapsedSideNav || this._screenSvc.isMobile()) {
				return;
			}
			// tslint:disable-next-line: triple-equals
			this.showSideNavIconsOnly = mode == EDashboardSideNavMode.shrinked;
		});
		this.breadcrumbNavigation$ = this._newDashboardNavbarSvc.breadCrumbNavigation$;

		if (isPlatformBrowser(this._platformId)) {
			this._newDashboardNavbarSvc.breadCrumbNavigation$.pipe(untilDestroyed(this)).subscribe(result => {
				if (result && result.length) {
					const screenWdith = !!window ? window.innerWidth : 0;
					const MAX_DISPLAYED_BREADCRUMBS = screenWdith && screenWdith < 1135 ? 2 : 3;
					if (result.length > MAX_DISPLAYED_BREADCRUMBS) {
						this.breadcrumbNavigationMenuStartIndex = 0;
						this.breadcrumbNavigationMenuEndIndex = result.length - MAX_DISPLAYED_BREADCRUMBS;
					} else {
						this.breadcrumbNavigationMenuStartIndex = null;
						this.breadcrumbNavigationMenuEndIndex = null;
					}
					this.breadcrumbNavigation$ = this._newDashboardNavbarSvc.breadCrumbNavigation$;
					this.pageTitle = null;
					this.isLoadingBreadCrumbs = false;
				}
			});
		}

		this._authSvc.user$.pipe(untilDestroyed(this)).subscribe(user => {
			this.isLoggedIn = this._authSvc.isLoggedIn();
			if (this.isLoggedIn && user) {
				this._initUserBalanceAsync();
				this.user = user;
				this.userAvatar = {
					name: this.user.firstName ? `${this.user.firstName} ${this.user.lastName}` : this.user.email,
					userEmail: this.user.email,
					size: 38,
				};
				this.isGuestUser = this.user.userKind == EUserKind.GuestAccount;
			} else {
				this.user = null;
				this.userAvatar = null;
			}
		});
		if (this._authSvc.isLoggedIn()) {
			await this._userOrganizationSvc.initSvc();
			this._userOrganizationSvc.organizationInfo$.pipe(untilDestroyed(this)).subscribe(x => {
				if (x?.hasLogo) {
					this._securePipe
						.transform(x.logoUrl)
						.pipe(untilDestroyed(this))
						.subscribe(s => {
							this.orgLogo = s;
						});
				} else {
					this.orgLogo = null;
				}
			});
		}
		this._newDashboardNavbarSvc.setMatDrawer(this.mobileSidenav);
	}

	private _checkUnreadNotifications() {
		this._notificationsSvc.notifications$.pipe(untilDestroyed(this)).subscribe(data => {
			this.unreadNotificationsCount = 0;
			data.forEach(notification => {
				if (!notification.isRead) {
					this.unreadNotificationsCount++;
				}
			});
		});
	}

	async notificationsMenuClosedAsync() {
		if (this.unreadNotificationsCount) {
			await this._notificationsSvc.markAllNotificationsAsRead();
			this.unreadNotificationsCount = 0;
		}
	}

	private async _initUserBalanceAsync() {
		if (!this.userBalance) {
			this._paymentPlanSvc.updateUserBalance(false, true, true);
			this._paymentPlanSvc.userBalance$
				.pipe(
					untilDestroyed(this),
					filter(b => !!b)
				)
				.subscribe(data => {
					this.userBalance = data;
					if (data) {
						if (this.app !== ECopyleaksAPP.LMS) {
							if (this.userBalance.accountType === this.ePaymentPlanType.SharedAccount) {
								this._initSharedAccountDataAsync();
							} else if (
								this.userBalance.accountType === this.ePaymentPlanType.Trial ||
								this.userBalance.current === 0
							) {
								this._paymentDialogSvc.initOldPaymentMethods();
								this._paymentDialogSvc.initPaymentPlan(
									100,
									'navbar-dashboard-upgrade',
									EPlatformProductType.Bundled,
									null,
									true
								);
							}
						}
					}
				});
		}
	}

	private async _initSharedAccountDataAsync() {
		try {
			const res = await this._paymentPlanSvc.getSharedAccount();
			this.sharedAccountName = res.displayName;
			this.sharedAccount = res;
		} catch (error) {
			console.error(error);
		}
	}

	openExternalLink(link: string, isBlank = true) {
		if (isBlank) {
			window?.open(link, '_blank');
		} else {
			window?.open(link);
		}
	}

	openInternalLink(link: string) {
		this.pageTitle = null;
		this._router.navigate([link]);
	}

	suggestFeature() {
		if (this.isMobile) {
			this.sidenav?.close();
		}
		this._dialogSvc.open(CopyLeaksDialogComponent, {
			width: '600px',
			direction: this._dir.value,
			data: {
				title: $localize`Suggest a Feature`,
				component: FeatureSuggestionComponent,
				showCaptcha: true,
				btnSubmitText: $localize`Submit`,
				onSubmit: this._submitFeatureSuggestionAsync.bind(this),
				hideCancelButton: true,
			} as ICopyLeaksDialogData,
		});
	}

	private async _submitFeatureSuggestionAsync(data: ICopyLeaksDialogSubmittedData) {
		try {
			await this._supportSvc.SuggestAFeature({
				featureTitle: data.title,
				featureDescription: data.description,
				captcha: data.captcha,
				name: `${this.user.firstName} ${this.user.lastName}`,
				company: this.user.company,
			});
			this._clsAlertsSvc.showCustomPrimary(
				$localize`Your Suggestion was sent successfully. We will contact you as soon as possible`
			);
		} catch (ex) {
			data.captchaComponent.generateCaptcha();
			const _ex: HttpErrorResponse = ex;
			if (_ex.status === HttpStatusCode.BAD_REQUEST) {
				const errorBody = _ex.error as IAPICustomErrors;
				if (errorBody && errorBody.code) {
					switch (errorBody.code) {
						case EAPIErrorCodes.CaptchaValidationError:
							throw ECopyLeaksDialogSubmitErrors.CaptchaNotValid;
					}
				}
			} else {
				if (_ex.error) {
					this._clsAlertsSvc.showHttpResponseError(_ex);
				}
			}
		}
	}

	getUserPaymentPlan(type: EPaymentPlanType): string {
		switch (type) {
			case EPaymentPlanType.Trial:
				return $localize`Trial`;
			case EPaymentPlanType.Subscription:
				// return 'Subscription';
				return '';
			case EPaymentPlanType.Prepaid:
				return $localize`Prepaid`;
			case EPaymentPlanType.SharedAccount:
				return $localize`Team Member`;
			default:
				return '';
		}
	}

	async bookADemoAsync() {
		try {
			this.disableBookADemo = true;
			await loadScript(CALENDRY_WIDGET.ScriptUrl);
			await loadCSS(CALENDRY_WIDGET.CssUrl);
			let name = '';
			let email = '';
			if (this.user) {
				name = `${this.user.firstName} ${this.user.lastName}`;
				email = `${this.user.email}`;
			}
			Calendly.initPopupWidget({
				url: CALENDRY_WIDGET.CopyleaksSales,
				prefill: {
					name: `${this.user.firstName}`,
					email: `${email}`,
				},
				utm: {},
			});
		} catch (error) {
		} finally {
			this.disableBookADemo = false;
		}
	}

	toggleSideNav() {
		if (this.isMobile) {
			this.mobileSidenav.toggle();
			this._newDashboardNavbarSvc.setMatDrawer(this.mobileSidenav);
		}
	}

	toggleSideNavDisplay(matDrawarContent: MatDrawerContent) {
		this.showSideNavIconsOnly = !this.showSideNavIconsOnly;
		if (this.showSideNavIconsOnly) {
			this._localStorageSvc.setItem(ELocalStorageKeys.DashboardSideNavMode, EDashboardSideNavMode.shrinked);
			this._newDashboardNavbarSvc.shrinkSideNav();
		} else {
			this._localStorageSvc.setItem(ELocalStorageKeys.DashboardSideNavMode, EDashboardSideNavMode.extended);
			this._newDashboardNavbarSvc.extendSideNav();
		}
	}

	async login() {
		this._router.navigate([`/${AccountPagesRoutes.LoginRedirect}`], {
			queryParams: {
				isLogin: true,
			},
		});
	}

	signUp() {
		if (this._config.APP === ECopyleaksAPP.API) {
			location.href = `${environment.apiFrontEndUrl}/signup`;
		} else {
			location.href = `${environment.dashboardWebsiteUrl}/signup`;
		}
	}

	changeUnreadItemsToZero() {}

	openBillingPage() {
		this._newDashboardNavbarSvc.openBillingPage(this._config.APP, this.user);
	}

	async logoutAsync() {
		await this._authSvc.logoutAsync();
		if (this.app === ECopyleaksAPP.LMS) {
			this.clearOnLogOut.emit();
		}
	}
}

interface IUserAvatar {
	userEmail: string;
	name: string;
	size: number;
}
