import { isPlatformBrowser } from '@angular/common';
import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { environment } from '@environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ClsAlertsService } from '@ngx-common-v2/components/cls-alerts/services/cls-alerts.service';
import { filter, take } from 'rxjs';
import { ESignalRConnectionState } from '../../notifications/enums';
import { NotificationsService } from '../../notifications/services/notifications.service';
import { NotificationsTargets } from '../../notifications/targets';
import { ECopyleaksStatus, ICopyleaksStatus, StatusMessageBannerService } from './status-message-banner.service';

@UntilDestroy()
@Component({
	selector: 'app-status-message-banner',
	templateUrl: './status-message-banner.component.html',
	styleUrls: ['./status-message-banner.component.scss'],
})
export class StatusMessageBannerComponent implements OnInit, OnDestroy {
	statusIsNotOperational: boolean;
	copyleaksStatus: ICopyleaksStatus;
	environment = environment;
	@Output() statusUpdate = new EventEmitter<boolean>();
	constructor(
		@Inject(PLATFORM_ID) private platformId: object,
		private _statusMessageBannerSvc: StatusMessageBannerService,
		private _clsAlertsSvc: ClsAlertsService,
		private _notificationsSvc: NotificationsService
	) {}

	async ngOnInit() {
		if (isPlatformBrowser(this.platformId)) {
			try {
				this.copyleaksStatus = await this._statusMessageBannerSvc.getStatusAsync();
				this._CheckIfStatusesAreOperational();
			} catch (error) {
				this._clsAlertsSvc.showHttpResponseError(error);
			}
		}
	}

	ngAfterViewInit(): void {
		if (isPlatformBrowser(this.platformId)) {
			this._notificationsSvc.startConnection('status-message-banner-component');

			this._notificationsSvc.ConnectionState$.pipe(
				untilDestroyed(this),
				filter(state => state === ESignalRConnectionState.Connected),
				take(1)
			).subscribe(() => {
				this._notificationsSvc
					.on<ICopyleaksStatus>(NotificationsTargets.CopyleaksStatuses)
					.pipe(untilDestroyed(this))
					.subscribe(data => {
						this.copyleaksStatus = data;
						this._CheckIfStatusesAreOperational();
					});
			});
		}
	}

	private _CheckIfStatusesAreOperational() {
		if (
			this.copyleaksStatus.apiStatus !== ECopyleaksStatus.Operational ||
			this.copyleaksStatus.mainWebsiteStatus !== ECopyleaksStatus.Operational ||
			this.copyleaksStatus.idStatus !== ECopyleaksStatus.Operational ||
			this.copyleaksStatus.ltiStatus !== ECopyleaksStatus.Operational
		) {
			this.statusIsNotOperational = true;
		} else {
			this.statusIsNotOperational = false;
		}

		this.statusUpdate.emit(this.statusIsNotOperational);
	}

	ngOnDestroy() {
		this._notificationsSvc.removeListener(NotificationsTargets.CopyleaksStatuses);
		this._notificationsSvc.stopConnection('status-message-banner-component');
	}
}
