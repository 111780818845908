import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClsLoadingAnimationComponent } from './cls-loading-animation.component';
import { LottieCacheModule, LottieModule } from 'ngx-lottie';

@NgModule({
	declarations: [ClsLoadingAnimationComponent],
	imports: [CommonModule, LottieModule.forRoot({ player: playerFactory }), LottieCacheModule.forRoot()],
	exports: [ClsLoadingAnimationComponent],
})
export class ClsLoadingAnimationModule {}

export function playerFactory() {
	return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web');
}
