import { transition, trigger, useAnimation } from '@angular/animations';
import { Component, HostBinding, HostListener, Input, OnChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ProgressPersonalNotificationsService } from '../../../notifications/services/progress-personal-notifications.service';
import { bounceOutRight, fadeIn } from 'ng-animate';
import { filter } from 'rxjs';
import { NotificationErrorDialogComponent } from '../../dialogs/notification-error-dialog/notification-error-dialog.component';
import { ETaskElementStatus, IPersonalNotificationTask } from '../../models/web-notifications.models';

@UntilDestroy()
@Component({
	selector: 'cls-notification-item-delete',
	templateUrl: './notification-item-delete.component.html',
	styleUrls: ['./notification-item-delete.component.scss'],
	animations: [
		trigger('fadeIn', [transition('* => *', useAnimation(fadeIn, { params: { timing: 0.5 } }))]),
		trigger('bounceOutRight', [
			transition(
				':leave',
				useAnimation(bounceOutRight, {
					params: {
						timing: 1.5,
					},
				})
			),
		]),
	],
})
export class NotificationItemDeleteComponent implements OnChanges {
	@Input() model: IPersonalNotificationTask = null;
	@Input() isDeletingItem: boolean;
	@Input() isBackgroundTask: false;
	eTaskElementStatus = ETaskElementStatus;
	progress = 0;

	@HostListener('click', ['$event.target'])
	onClick() {
		// if the status of the notification is falied then show an error dialog on host click
		if (this.model.metaData?.status === ETaskElementStatus.Failed) {
			this.dialog.open(NotificationErrorDialogComponent, {
				panelClass: 'notification-error-frame-dialog',
				width: '553px',
				data: {
					errorMessages: this.model.metaData?.result,
					guideCode: this.model.metaData?.ticketId,
				},
			});
		}
	}

	@HostBinding('style.cursor') cursor = '';

	constructor(public dialog: MatDialog, private _progressNotificationsSvc: ProgressPersonalNotificationsService) {}

	ngOnInit(): void {
		this._progressNotificationsSvc.onNotificationChange
			.pipe(
				untilDestroyed(this),
				filter(v => v.id == this.model.id)
			)
			.subscribe(value => {
				this.model = value;
				if (value.metaData?.progress) {
					this.progress = value.metaData?.progress;
				}
			});
	}

	ngOnChanges(): void {
		// make the notification clickable if the status = Falied
		this.cursor = this.model?.metaData?.status == ETaskElementStatus.Failed ? 'pointer' : '';
	}
}
