import { HttpClient } from '@angular/common/http';
import { Directive } from '@angular/core';
import { environment } from '@environment';
import * as signalR from '@microsoft/signalr';
import { ClsAlertsService } from '@ngx-common-v2/components/cls-alerts/services/cls-alerts.service';
import { BehaviorSubject, filter, Subject, Subscription, takeUntil } from 'rxjs';
import { UnNeedToInitializeUrls } from '../../models/ai-scan-result';
import { NgxCommonPagesConfig } from '../../pages/pages.config';
import { AuthService } from '../../services/auth.service';
import { UrlLocationService } from '../../services/url-location.service';
import { ESignalRConnectionState } from '../enums';
import {
	ENotificationType,
	ETaskElementStatus,
	IBatchDownloadUpdateNotification,
	IBatchIndexDocumentsUpdateNotification,
	IDeleteElementUpdateNotification,
	IPersonalNotificationTask,
} from '../models/web-notifications.models';
import { NotificationsTargets } from '../targets';
import { BaseNotificationsService } from './base-notifications.service';

@Directive()
export abstract class BasePersonalNotificationsService extends BaseNotificationsService {
	public connectionPath = '/notifications/ws';

	public onNotificationChange = new Subject<IPersonalNotificationTask>();
	public tabOnlyNotifications;

	protected _showLoadingSpinner$ = new BehaviorSubject<boolean>(false);
	private _notifications$ = new BehaviorSubject<IPersonalNotificationTask[]>([]);

	get showLoadingSpinner$() {
		return this._showLoadingSpinner$;
	}

	get notifications$() {
		return this._notifications$;
	}

	get notifications() {
		return this._notifications$.value;
	}

	set notifications(value: IPersonalNotificationTask[]) {
		this._notifications$.next(value);
	}

	private _notificationIdsToInteractWith = new Map<string, boolean>();

	public interactWithNotification(notificationId: string) {
		this._notificationIdsToInteractWith.set(notificationId, true);
	}

	public removeNotificationInteraction(notificationId: string) {
		this._notificationIdsToInteractWith.delete(notificationId);
	}

	public isAllowedToInteractWithNotification(notificationId: string) {
		return this._notificationIdsToInteractWith?.get(notificationId);
	}

	constructor(
		platformId: object,
		urlLocationSvc: UrlLocationService,
		authSvc: AuthService,
		protected httpClient: HttpClient,
		clsAlertsSvc: ClsAlertsService,
		_config: NgxCommonPagesConfig
	) {
		super(platformId, urlLocationSvc, authSvc, clsAlertsSvc, _config);
		if (!UnNeedToInitializeUrls.includes(this.urlLocationSvc.location.pathname)) {
			if (authSvc.isLoggedIn()) this.startConnection('base-personal-notifications-service');
		}
	}

	abstract loadNotificationsAsync(forceReload?: boolean): void;
	abstract onDownloadReportPdfRequestFinishAsync(
		notification: IPersonalNotificationTask,
		data: IBatchDownloadUpdateNotification
	): void;

	// On websocket connection success
	onConnectionSuccess(hubConnection: signalR.HubConnection) {
		if (this.authSvc.isLoggedIn()) {
			hubConnection.invoke('Register', this.authSvc.token, this.signalRProducts);
			this._handleIncomingNotifications();
		}
	}

	private _handleIncomingNotifications() {
		let displayedNotificationsSub$: Subscription;
		this.ConnectionState$.subscribe(state => {
			if (state === ESignalRConnectionState.Connected) {
				displayedNotificationsSub$ = this.on<IPersonalNotificationTask>(NotificationsTargets.DisplayedNotifications)
					.pipe(filter(n => this.notifications.findIndex(_n => _n.id == n.id) == -1))
					.subscribe(data => {
						if (data.messageType == ENotificationType.deleteElements) {
							this.initDeleteElementNotificationHandler(data.id);
						}
						if (data.messageType == ENotificationType.downloadReport) {
							this.initDownloadElementNotificationHandler(data.id);
						}
						if (data.messageType == ENotificationType.indexDocuments) {
							this.initIndexDocumentsNotificationHandler(data.id);
						}

						this.notifications = [data, ...this.notifications];
					});
			} else {
				displayedNotificationsSub$?.unsubscribe();
				displayedNotificationsSub$ = null;
			}
		});
	}

	public cancelNotification(canceledNotificationId: string) {
		this.deleteNotificationAsync(canceledNotificationId);
	}

	protected initDownloadElementNotificationHandler(id: string) {
		var notificationunsub = new Subject();
		this.on<IBatchDownloadUpdateNotification>(NotificationsTargets.ElementsPDFDownloadUpdate(id))
			.pipe(takeUntil(notificationunsub))
			.subscribe(data => {
				var notificationIdx = this.notifications.findIndex(n => n.id == data.notificationId);
				if (notificationIdx != -1) {
					if (data.status == ETaskElementStatus.Done) {
						this.notifications[notificationIdx].metaData.totalFiles = data.totalFiles;
						this.onDownloadReportPdfRequestFinishAsync(this.notifications[notificationIdx], data);
					} else if (data.status == ETaskElementStatus.Failed) {
						this.notifications[notificationIdx].message = 'SERVER_NOTIFICATION.DOWNLOAD_ELEMENTS_TASK_FAILED';
						this.notifications[notificationIdx].metaData.ticketId = data.ticketId;
						this.notifications[notificationIdx].metaData.result = data.errorMessage;
					} else if (data.status == ETaskElementStatus.Canceled) {
						this.notifications[notificationIdx].isCanceled = true;
					}
					if (data.status == ETaskElementStatus.Done || data.status == ETaskElementStatus.Failed) {
						notificationunsub.next(null);
						notificationunsub.complete();
					}
					if (data.status != ETaskElementStatus.Done) {
						this.notifications[notificationIdx].metaData.status = data.status;
						if (data.progress >= 100) {
							this.notifications[notificationIdx].metaData.progress = 99;
						} else {
							this.notifications[notificationIdx].metaData.progress = data.progress;
						}
						this.onNotificationChange.next(this.notifications[notificationIdx]);
					}
				}
			});
	}

	protected initDeleteElementNotificationHandler(id: string) {
		var notificationunsub = new Subject();
		this.on<IDeleteElementUpdateNotification>(NotificationsTargets.ElementsDeleteUpdate(id))
			.pipe(takeUntil(notificationunsub))
			.subscribe(data => {
				var notificationIdx = this.notifications.findIndex(n => n.id == data.notificationId);
				if (notificationIdx != -1) {
					this.notifications[notificationIdx].metaData.status = data.status;
					if (data.progress >= 100) {
						this.notifications[notificationIdx].metaData.progress = 99;
					} else {
						this.notifications[notificationIdx].metaData.progress = data.progress;
					}

					if (data.status == ETaskElementStatus.Done) {
						this.notifications[notificationIdx].message = 'SERVER_NOTIFICATION.DELETE_ELEMENTS_TASK_DONE';
					} else if (data.status == ETaskElementStatus.Failed) {
						this.notifications[notificationIdx].message = 'SERVER_NOTIFICATION.DELETE_ELEMENTS_TASK_FAILED';
						this.notifications[notificationIdx].metaData.ticketId = data.ticketId;
						this.notifications[notificationIdx].metaData.result = data.errorMessage;
					}

					if (data.status == ETaskElementStatus.Done || data.status == ETaskElementStatus.Failed) {
						notificationunsub.next(null);
						notificationunsub.complete();
					}
					this.onNotificationChange.next(this.notifications[notificationIdx]);
				}
			});
	}

	protected initIndexDocumentsNotificationHandler(id: string) {
		var notificationunsub = new Subject();
		this.on<IBatchIndexDocumentsUpdateNotification>(NotificationsTargets.DocumentsIndexUpdate(id))
			.pipe(takeUntil(notificationunsub))
			.subscribe(data => {
				var notificationIdx = this.notifications.findIndex(n => n.id == data.notificationId);
				if (notificationIdx != -1) {
					this.notifications[notificationIdx].metaData.status = data.status;
					if (data.progress >= 100) {
						this.notifications[notificationIdx].metaData.progress = 99;
					} else {
						this.notifications[notificationIdx].metaData.progress = data.progress;
					}

					if (data.status == ETaskElementStatus.Done) {
						this.notifications[notificationIdx].message = $localize`Documents are indexed successfully`;
					} else if (data.status == ETaskElementStatus.Failed) {
						this.notifications[notificationIdx].message = $localize`Documents index failed!`;
						this.notifications[notificationIdx].metaData.ticketId = data.ticketId;
					}
					if (data.status == ETaskElementStatus.Done || data.status == ETaskElementStatus.Failed) {
						this.notifications[notificationIdx].metaData.result = data.result;
						notificationunsub.next(null);
						notificationunsub.complete();
					}
					this.onNotificationChange.next(this.notifications[notificationIdx]);
				}
			});
	}

	// Mark all notfications as read
	public async markAllNotificationsAsRead() {
		try {
			const currentNotificationsIds = this._getNotificationsIds();
			await this.httpClient
				.put(`${environment.notificationsAPI}/notifications/${this.signalRProducts}/personal/mark-all-as-read`, {
					notificationIds: currentNotificationsIds,
				})
				.toPromise();
			this.notifications$.next([]);
			this.loadNotificationsAsync(true);
		} catch (err) {
			this.clsAlertsSvc.showHttpResponseError(err);
		}
	}

	// Delete a single notification
	public async deleteNotificationAsync(id: string) {
		try {
			await this.httpClient
				.post(`${environment.notificationsAPI}/notifications/personal/delete`, {
					notificationId: id,
				})
				.toPromise();
		} catch (err) {
			this.clsAlertsSvc.showHttpResponseError(err);
		}
	}

	// Clear current notifications
	public async deleteCurrentNotificationAsync() {
		try {
			const currentNotificationsIds = this._getNotificationsIds();
			this._showLoadingSpinner$.next(true);
			await this.httpClient
				.post(`${environment.notificationsAPI}/notifications/personal/delete-all`, {
					notificationIds: currentNotificationsIds,
				})
				.toPromise();
			this.notifications$.next([]);
			this.loadNotificationsAsync();
		} catch (err) {
			this.clsAlertsSvc.showHttpResponseError(err);
		} finally {
			this._showLoadingSpinner$.next(false);
		}
	}

	// Extract current user owned notifications ids and return them
	private _getNotificationsIds() {
		const filteredNotifications = this.notifications.filter(
			notification => notification.messageType !== ENotificationType.general
		);
		const notificationsIds = filteredNotifications.map(notification => {
			return notification.id;
		});
		return notificationsIds;
	}
}
