import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ClsAlertsService } from '@ngx-common-v2/components/cls-alerts/services/cls-alerts.service';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../../../../services/auth.service';
import { IPersonalNotification } from '../../../models/web-notifications.models';
import { WebNotificationsService } from '../services/web-notifications.service';
@UntilDestroy()
@Component({
	selector: 'app-web-notification-menu',
	templateUrl: './web-notification-menu.component.html',
	styleUrls: ['./web-notification-menu.component.scss'],
})
export class WebNotificationMenuComponent implements OnInit, OnDestroy {
	displayedNotifications$: Observable<IPersonalNotification[]>;
	noNotifications: boolean;
	loadMoreNotifications: boolean;
	showButtonLoading = false;
	hasErrorLoadingNotifications: boolean;
	showSpinner = true;

	constructor(
		private _webNotificationsSvc: WebNotificationsService,
		private _clsAlertsSvc: ClsAlertsService,
		private _authService: AuthService
	) {}

	ngOnInit(): void {
		if (this._authService.isLoggedIn()) {
			this._webNotificationsSvc.loadNotifications(true);
		}

		this._webNotificationsSvc.showLoadingSpinner$.pipe(untilDestroyed(this)).subscribe(showSpinner => {
			if (showSpinner) {
				this.showSpinner = showSpinner;
			} else {
				setTimeout(() => {
					this.showSpinner = showSpinner;
				}, 500);
			}
		});

		this._webNotificationsSvc.errorMessageLoadingNotification$
			.pipe(untilDestroyed(this))
			.subscribe(errorMessage => (this.hasErrorLoadingNotifications = errorMessage));

		this.displayedNotifications$ = combineLatest([
			this._webNotificationsSvc.addedNotifications$,
			this._webNotificationsSvc.markAllNotificationdAsRead$,
		]).pipe(
			untilDestroyed(this),
			map(([notifications, markAllAsRead]) =>
				notifications
					.map(notification => ({
						...notification,
						isRead: markAllAsRead === null ? notification.isRead : markAllAsRead,
					}))
					.sort((a, b) => new Date(b.creationTime).getTime() - new Date(a.creationTime).getTime())
			)
		);

		this._webNotificationsSvc.loadMoreNotifications$
			.pipe(untilDestroyed(this))
			.subscribe(data => (this.loadMoreNotifications = data));
	}

	reLoadNotifications() {
		this._webNotificationsSvc.loadNotifications(false);
	}

	public async addMoreNotifications() {
		try {
			this._webNotificationsSvc.loadNotifications(false);
		} catch (error) {
			this._clsAlertsSvc.showHttpResponseError(error);
		} finally {
			this.showButtonLoading = false;
		}
	}

	ngOnDestroy() {}
}
