import { Component, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

@Component({
	selector: 'cls-spinner-button',
	templateUrl: './spinner-button.component.html',
	styleUrls: ['./spinner-button.component.scss'],
})
export class SpinnerButtonComponent implements AfterViewInit {
	@Input() btnText = 'btnText';
	@Input() type = 'button';
	@Input() showSpinner = false;
	@Input() percent: number;
	@Input() spinnerDiameter = '40';
	@Input() disabled = false;
	@Input() color = 'primary';
	@Input() fullWidth: boolean = false;
	@Input() btnHight: string;
	@Input() btnWidth: string;
	@Input() noWidth: boolean = false;
	@Input() fontWeight: string;

	private _btnWrapperElementRef: ElementRef;

	@ViewChild('btnWrapperElementRef', { read: ElementRef })
	set btnWrapperElementRef(content: ElementRef) {
		if (content) {
			this._btnWrapperElementRef = content;
		}
	}

	constructor() {}

	ngAfterViewInit() {
		if (!this.btnHight && this._btnWrapperElementRef)
			this.btnHight = `${this._btnWrapperElementRef.nativeElement.offsetHeight - 5}px`;
		if (!this.btnWidth && this._btnWrapperElementRef)
			this.btnWidth = `${this._btnWrapperElementRef.nativeElement.offsetWidth - 5}px`;
	}
}
