import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvatarIconComponent } from './avatar-icon.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
	declarations: [AvatarIconComponent],
	imports: [CommonModule, MatIconModule, MatTooltipModule],
	exports: [AvatarIconComponent],
})
export class AvatarIconModule {}
