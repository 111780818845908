<cls-payment-checkout
	[showTitle]="false"
	*ngIf="!invoiceId"
	(confirmPayment)="confirmPayment($event)"
	[showPromoCodeInput]="showPromoCodeInput"
	[horizontalMode]="horizontalMode"
	[productType]="productType"
	[selectedPlatformProduct]="selectedPlanProduct"
	[planId]="planId"
	[creditsPlan]="creditsPlan"
	[initCoupon]="initCoupon"></cls-payment-checkout>

<cls-payment-processing
	*ngIf="invoiceId"
	[invoiceId]="invoiceId"
	(balanceUpdated)="balanceUpdated()"></cls-payment-processing>
