import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuickActionsItemComponent } from './quick-actions-item/quick-actions-item.component';
import { QuickActionsListComponent } from './quick-actions-list/quick-actions-list.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
	declarations: [QuickActionsItemComponent, QuickActionsListComponent],
	exports: [QuickActionsItemComponent, QuickActionsListComponent],
	imports: [CommonModule, RouterModule, FlexLayoutModule, MatIconModule, MatButtonModule, MatTooltipModule],
})
export class QuickActionsModule {}
