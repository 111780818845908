import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'ellipsis',
})
export class EllipsisPipe implements PipeTransform {
	transform(value: string, lengthToEllipsis = 40): any {
		var positiveValue = lengthToEllipsis < 0 ? lengthToEllipsis * -1 : lengthToEllipsis;
		if (!value || value?.length <= positiveValue) {
			return value;
		}
		if (lengthToEllipsis >= 0) return value.slice(0, lengthToEllipsis)?.trim() + '...';
		if (lengthToEllipsis < 0) return '...' + value.slice(value.length - positiveValue, value.length)?.trim();
	}
}
