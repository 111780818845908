import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IUserDetailsFormData } from '../../models/user.models';
import { ActivateAccountRes, ConfirmAccountRes } from '../../models/auth.model';
import { OidcService } from '../../services/oidc.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
	selector: 'app-user-register-dialog',
	templateUrl: './user-register-dialog.component.html',
	styleUrls: ['./user-register-dialog.component.scss'],
})
export class UserRegisterDialogComponent implements OnInit, OnDestroy {
	currentStep: EUserRegisterSteps = EUserRegisterSteps.EmailEntryStep;
	eUserRegisterSteps = EUserRegisterSteps;
	lastRegisteredEmail: string;
	confirmAccountRes: ConfirmAccountRes;
	constructor(
		private _oidcService: OidcService,
		public dialogRef: MatDialogRef<UserRegisterDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public dialogData: IUserRegisterDialogData
	) {}

	ngOnInit() {
		if (this.dialogData.registeringFrom === 'Pricing Page') {
			this.currentStep = EUserRegisterSteps.CodeVerificationStep;
		}
		if (this.dialogData.email) {
			this.lastRegisteredEmail = this.dialogData.email;
		}
		this.dialogRef
			.beforeClosed()
			.pipe(untilDestroyed(this))
			.subscribe(_ => {
				if (this.currentStep !== this.eUserRegisterSteps.PersonalInformationEntryStep) {
					if (this.dialogData.onRegisterationCancel) {
						this.dialogData.onRegisterationCancel();
					}
				}
			});
	}

	onRegistrationSuccess(email: string) {
		this.lastRegisteredEmail = email;
		this.dialogRef.updateSize('480px');
		this.currentStep = this.eUserRegisterSteps.CodeVerificationStep;
	}

	onVerificationProcessComplete(model: ConfirmAccountRes) {
		this.dialogRef.updateSize('600px');
		this.dialogRef.disableClose = true;
		this.confirmAccountRes = model;
		this.currentStep = this.eUserRegisterSteps.PersonalInformationEntryStep;
	}

	async onFinishPersonalInformation(res: ActivateAccountRes) {
		if (this.dialogData && this.dialogData.onRegisterationSuccess) {
			await this.dialogData.onRegisterationSuccess(this.lastRegisteredEmail, res, this.confirmAccountRes);
		}
		this.dialogRef.close();
		if (!this.dialogData?.disableAutoLogin) {
			this._oidcService.autoLogin(res.otac, this.dialogData.returnUrl);
		}
	}

	ngOnDestroy() {}
}
export interface IUserRegisterDialogData {
	navigateToMainPage?: boolean;
	registeringFrom?: string;
	returnUrl?: string;
	disableAutoLogin?: boolean;
	email?: string;
	userInfo?: IUserDetailsFormData;
	loginPageRoute: string;
	onRegisterationSuccess?: (email: string, result: ActivateAccountRes, model: ConfirmAccountRes) => Promise<void>;
	onRegisterationCancel?: () => Promise<void>;
}

export enum EUserRegisterSteps {
	EmailEntryStep,
	CodeVerificationStep,
	PersonalInformationEntryStep,
}
