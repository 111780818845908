<div *ngIf="!isLoading" class="choose-plan-section" fxLayout="column" fxLayoutGap="16px">
	<div fxLayout="column" fxLayoutGap="4px">
		<div
			(click)="triggerOnlySelectedView()"
			class="change-plans-title-container"
			fxLayoutAlign="start center"
			fxLayoutAlign.lt-md="space-between center"
			fxLayout="row"
			fxLayoutGap="16px">
			<ng-container *ngIf="!title">
				<span *ngIf="hasSubscription" i18n>Change plan</span>
				<span *ngIf="!hasSubscription" i18n>Your Plan Options</span>
			</ng-container>
			<ng-container *ngIf="title">
				<span>{{ title }}</span>
			</ng-container>
			<mat-icon *ngIf="!keepOnlySelectedView">expand_less</mat-icon>
			<mat-icon *ngIf="keepOnlySelectedView">expand_more</mat-icon>
		</div>
		<cls-plan-item
			[@myInsertRemoveTrigger]="selectedPlanProduct == ePlatformProductTypes.Bundled || !keepOnlySelectedView"
			[planItemData]="getPlanItemData(ePlatformProductTypes.Bundled)"
			(click)="changeProductSelection(ePlatformProductTypes.Bundled)"></cls-plan-item>
		<cls-plan-item
			[@myInsertRemoveTrigger]="selectedPlanProduct == ePlatformProductTypes.PlagiarismChecker || !keepOnlySelectedView"
			[planItemData]="getPlanItemData(ePlatformProductTypes.PlagiarismChecker)"
			(click)="changeProductSelection(ePlatformProductTypes.PlagiarismChecker)"></cls-plan-item>
		<cls-plan-item
			[@myInsertRemoveTrigger]="selectedPlanProduct == ePlatformProductTypes.AIDetector || !keepOnlySelectedView"
			[planItemData]="getPlanItemData(ePlatformProductTypes.AIDetector)"
			(click)="changeProductSelection(ePlatformProductTypes.AIDetector)"></cls-plan-item>
	</div>
	<div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="4px">
		<div fxLayout="column" fxLayoutGap="8px" class="plan-options-container left-border-radius">
			<span class="plan-options-title" i18n>Plan Options</span>
			<div class="plan-options" fxLayout="row" fxLayoutGap="4px">
				<div
					class="plan-Interval-option left-inner-border-radius"
					(click)="changePlanInterval(eNewPaymentDialogBillingIntervals.Yearly)"
					[ngClass]="{
						'selected-plan-Interval-option': selectedPlanInterval == eNewPaymentDialogBillingIntervals.Yearly
					}"
					fxLayoutAlign="center center"
					fxLayout="row"
					fxLayoutGap="4px">
					<span i18n>Annual</span>
					<span i18n>({{ annualSave }}% off)</span>
				</div>
				<div
					class="plan-Interval-option right-inner-border-radius"
					(click)="changePlanInterval(eNewPaymentDialogBillingIntervals.Monthly)"
					[ngClass]="{
						'selected-plan-Interval-option': selectedPlanInterval == eNewPaymentDialogBillingIntervals.Monthly
					}"
					fxLayoutAlign="center center">
					<span i18n>Monthly</span>
				</div>
			</div>
		</div>
		<div fxLayout="column" fxLayoutGap="8px" class="choose-credits-container right-border-radius">
			<div class="choose-credits" fxLayout="row" fxLayoutAlign="space-between center">
				<span class="choose-credits-title" i18n>Credits</span>
				<span class="notification" i18n>1 credit equals 250 words</span>
			</div>
			<div class="plans-credits" fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="center center">
				<div
					*ngIf="selectedPlanInterval == eNewPaymentDialogBillingIntervals.Monthly"
					class="plans-credits-select-container">
					<mat-select panelClass="plans-credits-select" [(ngModel)]="selectedCreditsAmount">
						<mat-option *ngFor="let credits of monthlyPlansCredits" [value]="credits">
							{{ getDecimalNumber(credits) }}
						</mat-option>
					</mat-select>
				</div>
				<div
					*ngIf="selectedPlanInterval == eNewPaymentDialogBillingIntervals.Yearly"
					class="plans-credits-select-container">
					<mat-select panelClass="plans-credits-select" [(ngModel)]="selectedCreditsAmount">
						<mat-option *ngFor="let credits of yearlyPlansCredits" [value]="credits">
							{{ getDecimalNumber(credits) }}
						</mat-option>
					</mat-select>
				</div>
			</div>
		</div>
	</div>
	<div fxLayoutAlign="center center" class="actions-container">
		<cls-button (click)="goToCheckOut()">
			<span *ngIf="!isAnonymous" i18n>Proceed to Checkout</span>
			<span *ngIf="isAnonymous" i18n>Continue</span>
		</cls-button>
	</div>
</div>
<div class="choose-plan-loading-section" *ngIf="isLoading" fxLayout="column" fxLayoutGap="16px">
	<div class="loading-section" fxLayout="column" fxLayoutGap="4px">
		<ngx-skeleton-loader class="skeleton-loader top-radius" [theme]="{ 'height.px': 40 }"></ngx-skeleton-loader>
		<ngx-skeleton-loader
			class="skeleton-loader"
			[theme]="{ 'height.px': isMobile ? '160.72' : '86' }"></ngx-skeleton-loader>
		<ngx-skeleton-loader
			class="skeleton-loader"
			[theme]="{ 'height.px': isMobile ? '106.72' : '80.72' }"></ngx-skeleton-loader>
		<ngx-skeleton-loader
			class="skeleton-loader bottom-radius"
			[theme]="{ 'height.px': isMobile ? '128.72' : '80.72' }"></ngx-skeleton-loader>
	</div>
	<div class="loading-section" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="2px">
		<ngx-skeleton-loader
			class="skeleton-loader skeleton-loader-plan-period"
			[ngClass]="isMobile ? 'top-radius' : 'left-radius'"
			[theme]="{ 'height.px': isMobile ? 104 : 92 }"></ngx-skeleton-loader>
		<ngx-skeleton-loader
			class="skeleton-loader skeleton-loader-plan-amount"
			[ngClass]="isMobile ? 'bottom-radius' : 'right-radius'"
			[theme]="{ 'height.px': 92 }"></ngx-skeleton-loader>
	</div>
	<div class="loading-section" fxLayoutAlign="center center">
		<ngx-skeleton-loader
			class="skeleton-loader skeleton-loader-btn"
			[theme]="{ 'height.px': 32 }"></ngx-skeleton-loader>
	</div>
</div>
