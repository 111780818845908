import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {
	QuickActionButtonComponent,
	QuickActionComponent,
	QuickActionDescriptionComponent,
	QuickActionIconComponent,
	QuickActionTitleComponent,
} from './quick-action.component';

@NgModule({
	declarations: [
		QuickActionComponent,
		QuickActionIconComponent,
		QuickActionDescriptionComponent,
		QuickActionTitleComponent,
		QuickActionDescriptionComponent,
		QuickActionButtonComponent,
	],
	imports: [CommonModule, MatIconModule, MatButtonModule],
	exports: [
		QuickActionComponent,
		QuickActionIconComponent,
		QuickActionDescriptionComponent,
		QuickActionTitleComponent,
		QuickActionDescriptionComponent,
		QuickActionButtonComponent,
	],
	providers: [],
})
export class QuickActionModule {}
