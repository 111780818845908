import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NewDashboardNavbarV2Service } from '@ngx-common/components/new-dashboard-navbar-v2/services/new-dashboard-navbar-v2.service';
import { IUserAvatar } from '../../../../../enums/avatar.model';
import { UserInfo } from '../../../../../models/auth.model';
import { ECopyleaksAPP, NgxCommonPagesConfig } from '../../../../../pages/pages.config';
import { AuthService } from '../../../../../services/auth.service';
import { ScreenService } from '../../../../../services/screen.service';
import { BaseToolbarMenuComponent } from '../base-toolbar-menu-componenet';

@Component({
	selector: 'cls-account-toolbar-menu',
	templateUrl: './account-toolbar-menu.component.html',
	styleUrls: ['./account-toolbar-menu.component.scss'],
})
export class AccountToolbarMenuComponent extends BaseToolbarMenuComponent implements OnInit {
	@Input() isLoggedIn: boolean;
	@Input() userAvatar: IUserAvatar;
	@Input() user: UserInfo;
	@Input() isUserAnOrganizationOwner: boolean;
	@Input() isUserPartOfOrganization: boolean;
	@Input() isUserJustAnOrganizationMember: boolean;
	@Output() clearOnLogOut = new EventEmitter();

	userNameToolTip: string;

	ACCOUNT_TOOL_TIP = $localize`Account`;

	get isMobile() {
		return this.screenSvc?.isMobile();
	}

	constructor(
		router: Router,
		private _newDashboardNavbarSvc: NewDashboardNavbarV2Service,
		private screenSvc: ScreenService,
		private _authSvc: AuthService,
		@Inject(NgxCommonPagesConfig.key) private _config: NgxCommonPagesConfig
	) {
		super(router);
	}

	ngOnInit() {
		this.app = this._config.APP;

		this.userNameToolTip = `: ${
			this.user?.firstName ? this.user?.firstName + ' ' + this.user?.lastName : this.user?.email
		}`;
	}

	openBillingPage() {
		this._newDashboardNavbarSvc.openBillingPage(this.app, this.user);
	}

	async logoutAsync() {
		await this._authSvc.logoutAsync();
		if (this.app === ECopyleaksAPP.LMS) {
			this.clearOnLogOut.emit();
		}
	}
}
