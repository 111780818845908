import { Injectable } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { Observable } from 'rxjs';
@Injectable({
	providedIn: 'root',
})
export class ScreenService {
	public isActive: (value: string | string[]) => boolean;

	constructor(private mediaObserver: MediaObserver) {
		this.isActive = mediaObserver.isActive;
	}

	public get isMobile$() {
		return this.mediaObserver.asObservable().pipe(
			map(changes => changes.map(ch => ch.mqAlias)),
			map(queries => queries.includes('xs') || queries.includes('sm')),
			distinctUntilChanged()
		);
	}

	public get isTablet$() {
		return this.mediaObserver.asObservable().pipe(
			map(changes => changes.map(ch => ch.mqAlias)),
			map(queries => queries.includes('md')),
			distinctUntilChanged()
		);
	}

	public get isDesktop$() {
		return this.mediaObserver.asObservable().pipe(
			map(changes => changes.map(ch => ch.mqAlias)),
			map(queries => queries.includes('xl') || queries.includes('lg')),
			distinctUntilChanged()
		);
	}

	public get mediaAliases$(): Observable<string[]> {
		return this.mediaObserver.asObservable().pipe(map(changes => changes.map(ch => ch.mqAlias)));
	}

	isMobile() {
		return this.mediaObserver.isActive(['xs', 'sm']);
	}

	isTablet() {
		return this.mediaObserver.isActive(['md']);
	}

	isDesktop() {
		return this.mediaObserver.isActive(['lg', 'xl']);
	}

	getPlatform() {
		if (this.isMobile()) return 'Mobile';
		if (this.isTablet()) return 'Tablet';
		if (this.isDesktop()) return 'Desktop';
		return '';
	}
}
