import { Component, OnInit, Input, Inject, EventEmitter, Output } from '@angular/core';
import { EGraphToScrollTo, ICardsData } from './models/cls-card-data.model';
import { EAnalyticsLevel } from '../../models/cls-analytics.models';

@Component({
	selector: 'cls-results-insights-template',
	templateUrl: './cls-results-insights-template.component.html',
	styleUrls: ['./cls-results-insights-template.component.scss'],
})
export class ResultsInsightsTemplateComponent {
	/**
	 * The cards data.
	 */
	@Input() cardsDataSrc: ICardsData;

	/**
	 * Level type i.e - Integration, Context, Activity, Author.
	 * For each level the template layuot changes.
	 */
	@Input() analyticsLevel: EAnalyticsLevel;

	/**
	 * For controling loading state
	 */
	@Input() isLoading: boolean;

	@Input() isWithScroll: boolean = true;

	@Output() scrollToEvent: EventEmitter<EGraphToScrollTo> = new EventEmitter();

	eGraphToScroll = EGraphToScrollTo;
	eLevelType = EAnalyticsLevel;

	// Base link for the info about each card.
	infoLinkBase = 'https://help.copyleaks.com/kb-search-results?term=';

	scrollTo(scrollTo: EGraphToScrollTo) {
		this.scrollToEvent.emit(scrollTo);
	}
}
