import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EnviromentsLoadGuard } from '@ngx-common/guards/enviroments.guard';
import { LoggedInGuard } from '@ngx-common/guards/logged-in.guard';
import { AccountPagesRoutes } from '@ngx-common/pages/account-pages/model/account-routings.model';
import { AnalyticsActivityComponent } from 'shared/componenets/lms-analytics/pages/analytics-activity/analytics-activity.component';
import { AnalyticsCourseComponent } from 'shared/componenets/lms-analytics/pages/analytics-course/analytics-course.component';
import { AnalyticsIntegrationComponent } from 'shared/componenets/lms-analytics/pages/analytics-integration/analytics-integration.component';
import { AnalyticsStudentComponent } from 'shared/componenets/lms-analytics/pages/analytics-student/analytics-student.component';
import { LtiSharedPagesRoutings } from 'shared/enums';
import { LayoutComponent } from './components/layout/layout.component';
import { DashboardErrorPagesRedirectsGuard } from './guards/error-pages-redirects.guard';
import { ContactUsPageComponent } from './pages/contact-us/contact-us.component';
import { InstituteListPageComponent } from './pages/institute-list-page/institute-list-page.component';
import { UserInitNavigationComponent } from './pages/user-init-navigation.component';
import { AnalyticsConstants } from 'shared/constants/analytics';

const routes: Routes = [
	{
		path: '',
		component: LayoutComponent,
		children: [
			{
				path: '',
				component: UserInitNavigationComponent,
				canActivate: [EnviromentsLoadGuard],
			},
			{
				path: 'lti/v1.1/institues',
				component: InstituteListPageComponent,
				canActivate: [EnviromentsLoadGuard, LoggedInGuard],
			},
			{
				path: 'lti/v1.3/institues',
				redirectTo: 'integrations',
				pathMatch: 'full',
			},
			{
				path: 'dashboard/:platformType/analytics/:integrationId',
				component: AnalyticsIntegrationComponent,
				canActivate: [EnviromentsLoadGuard],
			},
			{
				path: 'usage/export-scans',
				loadChildren: () => import('./pages/data-usage/data-usage.module').then(mod => mod.DataUsageModule),
				canActivate: [EnviromentsLoadGuard, LoggedInGuard],
			},
			{
				path: 'contact-us',
				component: ContactUsPageComponent,
				canActivate: [EnviromentsLoadGuard],
			},
			{
				path: 'integrations',
				loadChildren: () => import('./pages/integrations/integrations.module').then(mod => mod.IntegrationsModule),
				canActivate: [EnviromentsLoadGuard, LoggedInGuard],
			},
			{
				path: 'integrations/add',
				loadChildren: () =>
					import('./pages/add-integration/add-integration.module').then(mod => mod.AddIntegrationModule),
				canActivate: [EnviromentsLoadGuard, LoggedInGuard],
			},

			{
				path: 'canvas/plagiarism-frameworks',
				redirectTo: 'integrations',
				pathMatch: 'full',
			},
			{
				path: 'dashboard/moodle/plagiarism-plugins',
				redirectTo: 'integrations',
				pathMatch: 'full',
			},
			// {
			// 	path: 'testing-only',
			// 	loadChildren: () => import('./pages/testing-only/testing-only.module').then(mod => mod.TestingOnlyModule),
			// },
		],
	},
	{
		path: LtiSharedPagesRoutings.PublicReportRoute,
		loadChildren: () => import('./pages/public-report/public-report.module').then(mod => mod.PublicReportModule),
		canActivate: [EnviromentsLoadGuard],
	},
	{
		path: AnalyticsConstants.Routing.CourseRoute,
		component: AnalyticsCourseComponent,
		canActivate: [EnviromentsLoadGuard, LoggedInGuard],
	},
	{
		path: AnalyticsConstants.Routing.ActivityRoute,
		component: AnalyticsActivityComponent,
		canActivate: [EnviromentsLoadGuard, LoggedInGuard],
	},
	{
		path: AnalyticsConstants.Routing.StudentRoute,
		component: AnalyticsStudentComponent,
		canActivate: [EnviromentsLoadGuard],
	},
	{
		path: 'dashboard/:platform/settings/:integrationId/:scanSettingsId',
		loadChildren: () =>
			import('./pages/dashboard-scan-settings/dashboard-scan-settings.module').then(
				mod => mod.DashboardScanSettingsModule
			),
		canActivate: [EnviromentsLoadGuard, LoggedInGuard],
	},
	{
		path: LtiSharedPagesRoutings.UnauthorizedPage,
		loadChildren: () =>
			import('shared/pages/errors/unauthorized/unauthorized.module').then(mod => mod.UnauthorizedModule),
	},
	{
		path: AccountPagesRoutes.LoginRedirect,
		loadChildren: () =>
			import('@ngx-common/pages/login-redirect/login-redirect.module').then(mod => mod.LoginRedirectModule),
		canActivate: [EnviromentsLoadGuard],
	},
	{
		path: '**',
		loadChildren: () => import('shared/pages/errors/not-found/not-found.module').then(mod => mod.NotFoundModule),
		canActivate: [DashboardErrorPagesRedirectsGuard],
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
