import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import * as moment from 'moment';
import { IDateRange } from '../../models/integration-level.models';
import { FormBuilder } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { Moment } from 'moment';

export const DATE_FORMAT = {
	parse: {
		dateInput: 'MM/YYYY',
	},
	display: {
		dateInput: 'MM/YYYY',
		monthYearLabel: 'MMM YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM YYYY',
	},
};

@Component({
	selector: 'lms-analytics-date-range',
	templateUrl: './analytics-date-range.component.html',
	styleUrls: ['./analytics-date-range.component.scss'],
	providers: [
		{ provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT },
		{ provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
	],
})
export class AnalyticsDateRangeComponent implements OnInit {
	// The Date range the user picked -> initialized with defaults
	@Input() dateRange: IDateRange;
	@Input() baseStartDate: Date;

	currentDate = new Date(Date.now());

	// For date range change in inputs.
	@Output() dateRangeChangeData: EventEmitter<IDateRange> = new EventEmitter<IDateRange>();

	/**
	 * Form group for two date pickers and integration select.
	 */
	integrationSelection = this._formBuilder.group({
		startDate: [, []],
		endDate: [, []],
	});

	constructor(private _formBuilder: FormBuilder) {}

	ngOnInit(): void {
		this.integrationSelection
			.get('startDate')
			.setValue(moment([this.dateRange.startYear, this.dateRange.startMonth - 1, 1]));
		this.integrationSelection.get('endDate').setValue(moment([this.dateRange.endYear, this.dateRange.endMonth - 1, 1]));
	}

	/**
	 * Checks the date pickers and updated them when user set a new datew range.
	 * @param normalizedMonth
	 * @param datepicker
	 * @param isStart
	 */
	setDate(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>, isStart: boolean) {
		let ctrlValue = moment();
		ctrlValue.month(normalizedMonth.month());
		ctrlValue.year(normalizedMonth.year());
		// Gap of date range.
		const gap = Math.abs(
			ctrlValue.diff(this.integrationSelection.get(isStart ? 'endDate' : 'startDate').value, 'years', true)
		);
		this.integrationSelection.get(isStart ? 'startDate' : 'endDate').setValue(ctrlValue);

		// more than one year in date range.
		if (gap > 1) {
			// update date range to be exactly one year.
			this.integrationSelection
				.get(isStart ? 'endDate' : 'startDate')
				.setValue(moment(ctrlValue).add(isStart ? 1 : -1, 'y'));
		}

		this.dateRange = {
			startYear: this.integrationSelection.get('startDate').value.year(),
			startMonth: this.integrationSelection.get('startDate').value.month() + 1,
			endYear: this.integrationSelection.get('endDate').value.year(),
			endMonth: this.integrationSelection.get('endDate').value.month() + 1,
		};
		datepicker.close();
		this.dateRangeChangeData.emit(this.dateRange);
	}
}
