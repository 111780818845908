import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { AvatarModule } from 'ngx-avatar';
import { ClsButtonModule } from '../../../ngx-common-v2/components/cls-button/cls-button.module';
import { ClsToggleListModule } from '../../../ngx-common-v2/components/cls-toggle-list/cls-toggle-list.module';
import { PaymentDialogModule } from '../../dialogs/payment-dialog/payment-dialog.module';
import { PaymentDialogService } from '../../dialogs/payment-dialog/services/payment-dialog.service';
import { UserRegisterDialogModule } from '../../dialogs/user-register-dialog/user-register-dialog.module';
import { CenteredMatMenuDirectiveModule } from '../../directives/centered-mat-menu-directive/centered-mat-menu-directive.module';
import { NotificationContainerModule } from '../../notifications/components/notification-container/notification-container.module';
import { WebNotificationModule } from '../../notifications/components/web-notification';
import { SecureImgPipeModule } from '../../pipes/secure/secure-img.module';
import { PaymentsService } from '../../services/payments.service';
import { SupportService } from '../../services/support.service';
import { UserLocationService } from '../../services/user-location.service';
import { DynamicBreadcrumbNavigationModule } from '../dynamic-breadcrumb-navigation/dynamic-breadcrumb-navigation.module';
import { AppSkeletonLoaderModule } from '../skeleton-loader/skeleton-loader.module';
import { SpinnerModule } from '../spinner/spinner.module';
import { StatusMessageBannerModule } from '../status-message-banner/status-message-banner.module';
import { UserPlanModule } from './components/cls-user-plan/user-plan.module';
import {
	DashboardLinkItemIconV2Component,
	DashboardLinkItemTextV2Component,
	DashboardLinkItemV2Component,
	DashboardLinkShrinkedItemTextV2Component,
} from './components/dashboard-link-item/dashboard-link-item-v2.component';
import { DashboardLinksComponent } from './components/dashboard-links/dashboard-links-v2.component';
import { DashboardLogoComponent } from './components/dashboard-logo/dashboard-logo-v2.component';
import { AccountToolbarMenuComponent } from './components/dashboard-toolbar-menus/account-toolbar-menu/account-toolbar-menu.component';
import { AppsToolbarMenuComponent } from './components/dashboard-toolbar-menus/apps-toolbar-menu/apps-toolbar-menu.component';
import { HelpToolbarMenuComponent } from './components/dashboard-toolbar-menus/help-toolbar-menu/help-toolbar-menu.component';
import { NotifcationsToolbarMenuComponent } from './components/dashboard-toolbar-menus/notifcations-toolbar-menu/notifcations-toolbar-menu.component';
import { FeatureSuggestionComponent } from './components/feature-suggestion/feature-suggestion.component';
import { NewDashboardNavbarV2Component } from './new-dashboard-navbar-v2/new-dashboard-navbar-v2.component';
import { MobileBottomMenuModule } from '../mobile-bottom-menu/mobile-bottom-menu.module';

@NgModule({
	declarations: [
		NewDashboardNavbarV2Component,
		DashboardLinksComponent,
		DashboardLinkItemV2Component,
		DashboardLogoComponent,
		DashboardLinkItemIconV2Component,
		DashboardLinkItemTextV2Component,
		DashboardLinkShrinkedItemTextV2Component,
		FeatureSuggestionComponent,
		NotifcationsToolbarMenuComponent,
		AppsToolbarMenuComponent,
		HelpToolbarMenuComponent,
		AccountToolbarMenuComponent,
	],
	exports: [
		NewDashboardNavbarV2Component,
		DashboardLinksComponent,
		DashboardLinkItemV2Component,
		DashboardLogoComponent,
		DashboardLinkItemIconV2Component,
		DashboardLinkItemTextV2Component,
		DashboardLinkShrinkedItemTextV2Component,
	],
	imports: [
		CommonModule,
		FlexLayoutModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		SecureImgPipeModule,
		AvatarModule,
		WebNotificationModule,
		NotificationContainerModule,
		AppSkeletonLoaderModule,
		SpinnerModule,
		CenteredMatMenuDirectiveModule,
		DynamicBreadcrumbNavigationModule,
		UserPlanModule,
		PaymentDialogModule,
		ClsButtonModule,
		ClsToggleListModule,
		UserRegisterDialogModule,
		StatusMessageBannerModule,
		MobileBottomMenuModule,

		MatSidenavModule,
		MatBadgeModule,
		MatDividerModule,
		MatInputModule,
		MatFormFieldModule,
		MatListModule,
		MatDialogModule,
		MatProgressBarModule,
		MatTooltipModule,
		MatMenuModule,
		MatIconModule,
		MatButtonModule,
		MatToolbarModule,
	],
	entryComponents: [FeatureSuggestionComponent],
	providers: [SupportService, PaymentDialogService, PaymentsService, UserLocationService],
})
export class NewDashboardNavbarV2Module {}
