<ng-container *ngIf="showBalanceLoader">
	<div class="balance-check-spinner">
		<div class="spinner-wrapper">
			<cls-spinner width="50px"></cls-spinner>
		</div>
	</div>
</ng-container>
<!--  -->
<mat-progress-bar
	*ngIf="pageLoading"
	mode="indeterminate"
	class="navigation-progress-bar"
	value="75"></mat-progress-bar>
<!--  -->
<router-outlet></router-outlet>

<cls-alerts></cls-alerts>
