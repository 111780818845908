<ng-template #contentIcon>
	<ng-content select="cls-toggle-list-item-icon"></ng-content>
</ng-template>
<ng-template #contentText>
	<ng-content select="cls-toggle-list-item-text"></ng-content>
</ng-template>
<ng-template #contentImage>
	<img alt="Toggle List Item image" [src]="linkImgSrc" />
</ng-template>
<!--  -->
<ng-container *ngIf="isExternalLink && !isPopUpLink">
	<a [href]="link" target="_blank" class="item-container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
		<mat-icon #itemIcon *ngIf="hasIcon && !linkImgSrc" fxLayoutAlign="center center">
			<ng-container *ngTemplateOutlet="contentIcon"></ng-container>
		</mat-icon>
		<div class="img-icon-container" fxLayoutAlign="center center" *ngIf="linkImgSrc && hasIcon">
			<ng-container *ngTemplateOutlet="contentImage"></ng-container>
		</div>
		<span #itemText>
			<ng-container *ngTemplateOutlet="contentText"></ng-container>
		</span>
		<div class="item-tag-container" *ngIf="tagText">
			<span>
				{{ tagText }}
			</span>
		</div>
	</a>
</ng-container>
<!--  -->
<ng-container *ngIf="!isExternalLink && !isPopUpLink">
	<a [routerLink]="link" class="item-container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
		<mat-icon #itemIcon *ngIf="hasIcon && !linkImgSrc" fxLayoutAlign="center center">
			<ng-container *ngTemplateOutlet="contentIcon"></ng-container>
		</mat-icon>
		<div class="img-icon-container" fxLayoutAlign="center center" *ngIf="linkImgSrc && hasIcon">
			<ng-container *ngTemplateOutlet="contentImage"></ng-container>
		</div>
		<span #itemText>
			<ng-container *ngTemplateOutlet="contentText"></ng-container>
		</span>
		<div class="item-tag-container" *ngIf="tagText">
			<span>
				{{ tagText }}
			</span>
		</div>
	</a>
</ng-container>
<!--  -->
<ng-container *ngIf="isPopUpLink">
	<button mat-button class="item-container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
		<mat-icon #itemIcon *ngIf="hasIcon && !linkImgSrc" fxLayoutAlign="center center">
			<ng-container *ngTemplateOutlet="contentIcon"></ng-container>
		</mat-icon>
		<div class="img-icon-container" fxLayoutAlign="center center" *ngIf="linkImgSrc && hasIcon">
			<ng-container *ngTemplateOutlet="contentImage"></ng-container>
		</div>
		<span #itemText>
			<ng-container *ngTemplateOutlet="contentText"></ng-container>
		</span>
		<div class="item-tag-container" *ngIf="tagText">
			<span>
				{{ tagText }}
			</span>
		</div>
	</button>
</ng-container>
