import { Component, OnInit, ElementRef, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import * as HttpStatusCodes from 'http-status-codes';

import { SuccessDialogComponent, SuccessDialogData } from '../dialogs/success-dialog/success-dialog.component';
import { UserSettingsService } from '../../services/user-settings.service';

import { SupportService } from '../../services/support.service';

export interface APICustomErrors {
	code: ApiErrorCodes;
	description: string;
}

export type ApiErrorCodes =
	| 'InternalError'
	| 'CaptchaValidationError'
	| 'DuplicateUserName'
	| 'wrong'
	| 'permlock'
	| 'confirmation'
	| 'templock';

@Component({
	selector: 'app-contact-us-form',
	templateUrl: './contact-us-form.component.html',
	styleUrls: ['./contact-us-form.component.scss'],
})
export class ContactUsFormComponent implements OnInit {
	frmContactUS: FormGroup;
	showButtonSpinner = false;
	captchaErrorTransCode = '';
	SEND = $localize`SEND`;

	constructor(
		private formBuilder: FormBuilder,
		private dialogService: MatDialog,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private supportService: SupportService,
		private elementRef: ElementRef,
		private userSettingsSvc: UserSettingsService,
		private _clsAlertSvc: ClsAlertsService
	) {}

	ngOnInit() {
		const description = this.activatedRoute.snapshot.queryParams.description;
		this.frmContactUS = this.formBuilder.group({
			firstName: ['', Validators.required],
			lastName: ['', Validators.required],
			companyName: [''],
			numberOfUsers: [''],
			phoneNumber: [''],
			email: [
				(this.userSettingsSvc.userSettings && this.userSettingsSvc.userSettings.email) || '',
				[Validators.required, Validators.email],
			],
			description: [description ? description : '', Validators.required],
			captcha: [null, Validators.required],
		});
	}

	async onFormSubmit(captchaComponent: CopyleaksCaptchaComponent) {
		this.captchaErrorTransCode = '';
		if (this.frmContactUS.invalid) {
			focusErrorInput(this.elementRef);
			return;
		}
		try {
			this.showButtonSpinner = true;
			const formData = this.frmContactUS.getRawValue();
			await this.supportService.sendContactUsEmail({
				name: `${formData.firstName} ${formData.lastName}`,
				email: formData.email,
				company: formData.companyName,
				message: formData.description,
				phoneNumber: formData.phoneNumber,
				numberOfUsers: formData.numberOfUsers,
				captcha: formData.captcha,
			});

			// tslint:disable-next-line: max-line-length
			const successMessage = $localize`Your Information was sent successfully. We will be in touch with you as soon as possible.`;

			this.dialogService.open(SuccessDialogComponent, {
				disableClose: true,
				data: {
					message: successMessage,
					onContinueClick: () => this.router.navigate(['/']),
				} as SuccessDialogData,
			});
		} catch (ex) {
			captchaComponent.generateCaptcha();
			const httpError: HttpErrorResponse = ex;
			if (httpError.status === HttpStatusCodes.BAD_REQUEST) {
				const errorBody = httpError.error as any;
				if (errorBody && errorBody.code) {
					if (errorBody.code === 'CaptchaValidationError') {
						this.captchaErrorTransCode = 'Wrong Captcha';
						return;
					}
				}
			}
			this._clsAlertSvc.showHttpResponseError(ex);
		} finally {
			this.showButtonSpinner = false;
		}
	}

	getEmailErrorMessageKey() {
		const emailCtrl = this.frmContactUS.get('email');
		return emailCtrl.hasError('required')
			? $localize`This field is required`
			: emailCtrl.hasError('email')
			? $localize`Invalid Email address`
			: '';
	}
}

import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { SuccessDialogModule } from '../dialogs/success-dialog/success-dialog.component';
import { CopyleaksCaptchaComponent } from '@ngx-common/components/copyleaks-captcha/copyleaks-captcha-component/copyleaks-captcha.component';
import { focusErrorInput } from '@ngx-common/utils/focus-error-input.utils';
import { CopyleaksCaptchaModule } from '@ngx-common/components/copyleaks-captcha/copyleaks-captcha.module';
import { SpinnerButtonModule } from '@ngx-common/components/spinner-button/spinner-button.module';
import { ClsAlertsService } from '@ngx-common-v2/components/cls-alerts/services/cls-alerts.service';
@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		FlexLayoutModule,
		SpinnerButtonModule,
		CopyleaksCaptchaModule,
		SuccessDialogModule,
		MatFormFieldModule,
		MatInputModule,
	],
	declarations: [ContactUsFormComponent],
	exports: [ContactUsFormComponent],
})
export class ContactUsFormModule {}
