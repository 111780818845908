import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { environment } from '@environment';
import { PipesModule } from '../../pipes/shared/pipes.module';
import { ConfirmPaymentBtnModule } from '../confirm-payment-btn/confirm-payment-btn.module';
import { StripeModule } from '../credit-card/stripe';
import { PlansModule } from '../plans/plans.module';
import { SpinnerModule } from '../spinner/spinner.module';
import { PaymentComponent } from './payment-component/payment.component';
import { FloatDecimalPipeModule } from '../../pipes/float-decimal/float-decimal.module';
import { ClsButtonModule } from '../../../ngx-common-v2/components/cls-button/cls-button.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@NgModule({
	declarations: [PaymentComponent],
	exports: [PaymentComponent],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		PipesModule,
		FlexLayoutModule,
		ConfirmPaymentBtnModule,
		ClsButtonModule,
		FloatDecimalPipeModule,
		NgxSkeletonLoaderModule,

		MatProgressSpinnerModule,
		MatButtonModule,
		MatIconModule,
		MatFormFieldModule,
		MatInputModule,
		MatTooltipModule,

		SpinnerModule,
		PlansModule,

		StripeModule.init({
			publicKey: environment.stripKey,
			elementOptions: {
				style: {
					base: {
						fontFamily: 'haboro-soft, Open Sans, sans-serif',
					},
				},
			},
		}),
	],
})
export class PaymentModule {}
