export enum LtiSharedPagesRoutings {
	UnauthorizedPage = 'unauthorized',
	NotFoundPage = 'not-found',
	ErrorPage = 'error',
	PublicReportRoute = 'lms/:platform/:integrationId/:activityId/web-report/:scanId/shared-report',
}

export enum EAPIErrorCodes {
	InternalError = 'InternalError',
	CaptchaValidationError = 'CaptchaValidationError',
	DuplicateUserName = 'DuplicateUserName',
	Wrong = 'wrong',
	Permlock = 'permlock',
	Confirmation = 'confirmation',
	EmailNotConfirmed = 'noconfirmed',
	RegisterEmailNotConfirmed = 'email-not-activated',
	Templock = 'templock',
	TempEmail = 'tempemail',
	Expired = 'expired',
	InvitationsLimit = 'invitations-limit',
	NotAllowed = 'not-allowed',
	UnAuthorized = 'un-authorized',
}

export enum EPlatform {
	Canvas = 1,
	Moodle = 2,
	LTI1_3 = 3,
}
