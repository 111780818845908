<button
	class="only-icon-btn"
	[matMenuTriggerFor]="helpMenu"
	#helpMenuButtonRef="matMenuTrigger"
	aria-label="help"
	mat-icon-button
	i18n-matTooltip
	matTooltip="Help">
	<mat-icon [ngClass]="{ 'toolbar-icon-clicked': helpMenuButtonRef.menuOpen }">help_outline</mat-icon>
</button>
<!-- Help menu -->
<mat-menu #helpMenu="matMenu" xPosition="before">
	<a
		class="menu-item-container"
		mat-menu-item
		fxLayout="row"
		fxLayoutAlign="start center"
		[href]="environment.mainWebsiteUrl + '/change-language'"
		target="_self">
		<mat-icon>language</mat-icon>
		<span class="menu-item-text" i18n>Change Language</span>
	</a>
	<div class="divider"></div>
	<a
		class="menu-item-container"
		mat-menu-item
		fxLayout="row"
		fxLayoutAlign="start center"
		[href]="environment.helpCenterLink"
		target="_blank">
		<mat-icon>support</mat-icon>
		<span class="menu-item-text" i18n>Help Center</span>
	</a>
	<a
		class="menu-item-container"
		mat-menu-item
		fxLayout="row"
		fxLayoutAlign="start center"
		href="https://copyleaks.com/newfeatures"
		target="_blank">
		<mat-icon>explore</mat-icon>
		<span class="menu-item-text" i18n>Explore Features</span>
	</a>
	<div class="divider"></div>
	<a
		class="menu-item-container"
		mat-menu-item
		fxLayout="row"
		fxLayoutAlign="start center"
		*ngIf="app === eCopyleaksAPP.MainWebsite || app === eCopyleaksAPP.API"
		(click)="suggestFeature()"
		(keyup.enter)="suggestFeature()"
		(keyup.space)="suggestFeature()">
		<mat-icon>star</mat-icon>
		<span class="menu-item-text" i18n>Suggest a feature</span>
	</a>
	<a
		class="menu-item-container"
		mat-menu-item
		fxLayout="row"
		fxLayoutAlign="start center"
		*ngIf="getUserPaymentPlan(userBalance?.accountType) === 'Trial'"
		href="https://copyleaks.com/book-a-demo"
		target="_blank">
		<mat-icon>date_range</mat-icon>
		<span class="menu-item-text" i18n>Book a Demo</span>
	</a>
	<a
		class="menu-item-container"
		mat-menu-item
		fxLayout="row"
		fxLayoutAlign="start center"
		*ngIf="app === eCopyleaksAPP.API"
		[routerLink]="'/' + pricingPageRoutings.SUPPORT_PATH">
		<mat-icon>live_help</mat-icon>
		<span class="menu-item-text" i18n>Support Tiers</span>
	</a>
</mat-menu>
