import { IAnalyticsExternalResults } from './cls-external-results-table.models';
import { ICardsData } from '../templates/cls-results-insights-template/models/cls-card-data.model';

export interface IAuthorTemplateOverview {
	cardsData: ICardsData;
	aiChartData: IBarChart;
	plagiarismChartData: IOneLineChart;
	commonResults: IAnalyticsExternalResults[];
	writingFeedbackChartData: IBarChart;
}

export interface IBaseChart {
	maxYTick: number;
	yTicks: number[];
}

export interface IBarChart extends IBaseChart {
	chartData: IAnalyticsExternalResults[];
}

export interface ITwoLineChart extends IBaseChart {
	chartData: [
		{
			name: string;
			series: IAnalyticsExternalResults[];
		},
		{
			name: string;
			series: IAnalyticsExternalResults[];
		}
	];
}

export interface IOneLineChart extends IBaseChart {
	chartData: [
		{
			name: string;
			series: IAnalyticsExternalResults[];
		}
	];
}

export interface IChartDataByMonth {
	value: number;
	date: string;
}

export enum EAnalyticsLevel {
	Integration,
	Context,
	Activity,
	Author,
}
