import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CopyLeaksDialogComponent } from './copyleaks-dialog-component/copyleaks-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CopyleaksCaptchaModule } from '../../components/copyleaks-captcha/copyleaks-captcha.module';
import { SpinnerButtonModule } from '../../components/spinner-button/spinner-button.module';
import { CalloutModule } from '../../components/callout/callout.module';

@NgModule({
	declarations: [CopyLeaksDialogComponent],
	exports: [CopyLeaksDialogComponent],
	imports: [
		CommonModule,
		FlexLayoutModule,
		FormsModule,
		ReactiveFormsModule,

		CopyleaksCaptchaModule,
		SpinnerButtonModule,
		CalloutModule,

		MatFormFieldModule,
		MatInputModule,
		MatButtonModule,
		MatDialogModule,
	],
	entryComponents: [CopyLeaksDialogComponent],
})
export class CopyLeaksDialogModule {}
