import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SupportedFilesService } from '../../../../services/supported-files.service';
import { IElementSharePersonalNotification } from '../../web-notification';

@Component({
	selector: 'app-shared-items-dialog',
	templateUrl: './shared-items-dialog.component.html',
	styleUrls: ['./shared-items-dialog.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class SharedItemsDialogComponent implements OnInit {
	get supportedFileTypes$() {
		return this._supportedFilesSvc.supportedFileTypes$;
	}

	constructor(
		private _supportedFilesSvc: SupportedFilesService,
		@Inject(MAT_DIALOG_DATA) public dialogData: IElementSharePersonalNotification,
		public dialogRef: MatDialogRef<SharedItemsDialogComponent>
	) {}

	closeDialog() {
		this.dialogRef.close();
	}
	ngOnInit(): void {
		this._initSupportedFilesAsync();
	}

	private async _initSupportedFilesAsync() {
		await this._supportedFilesSvc.initSupportedFileTypes();
	}
}
