<div class="user-email-dialog-wrapper">
	<form [formGroup]="frmRegister" (submit)="register(captcha)">
		<input type="hidden" name="admin" #adminInput value="false" />
		<div mat-dialog-title class="title" fxLayout="row">
			<span i18n="@@USER_REGISTER.EMAIL_ENTRY_DIALOG.TITLE">Create New Account</span>
		</div>
		<div mat-dialog-content class="callouts-no-header">
			<div class="login">
				<span class="member">Already a member?</span>
				<button type="button" mat-button class="no-padding" color="primary" (click)="login()">Log in</button>
			</div>
			<div class="sso-signup">
				<cls-sso-providers (click)="signUp(eSSOProviders.Google)">
					<img logo src="assets/sso/google.svg" />
					<span i18n="@@USER_REGISTER.SIGNUP_WITH_GOOGLE" text>Sign up with Google</span>
				</cls-sso-providers>
				<cls-sso-providers (click)="signUp(eSSOProviders.Facebook)">
					<img logo src="assets/sso/fb.svg" />
					<span i18n="@@USER_REGISTER.SIGNUP_WITH_FACEBOOK" text>Sign up with Facebook</span>
				</cls-sso-providers>
			</div>
			<div class="or-br">
				<div class="br br-left"></div>
				<div class="or">Or</div>
				<div class="br br-right"></div>
			</div>
			<ng-container *ngIf="captchaErrorTransCode || takenEmail || isEmailBanned">
				<cls-callout type="error" no-margin-top>
					<span *ngIf="captchaErrorTransCode" i18n="@@USER_REGISTER.EMAIL_ENTRY_DIALOG.WRONG_CAPTCHA">
						Wrong Captcha Text
					</span>
					<span *ngIf="takenEmail" i18n="@@USER_REGISTER.EMAIL_ENTRY_DIALOG.EMAIL_IS_TAKEN">
						{{ takenEmail }} already taken.
					</span>
					<span *ngIf="isEmailBanned" i18n="@@USER_REGISTER.EMAIL_ENTRY_DIALOG.TEMP_EMAIL_ADDRESS">
						Temporary email address is forbidden.
					</span>
				</cls-callout>
			</ng-container>
			<div class="register-form-field-div">
				<mat-form-field full-width>
					<input
						matInput
						[errorStateMatcher]="matCustomErrorStateMatcher"
						placeholder="Enter your email"
						i18n-placeholder
						formControlName="email"
						required
						#emailInput
						maxlength="100"
						cdkFocusInitial />
					<mat-error>
						<ng-container
							*ngIf="frmRegister.get('email').hasError('required')"
							i18n="@@USER_REGISTER.EMAIL_ENTRY_DIALOG.YOU_MUST_ENTER_YOUR_EMAIL">
							You must enter your email.
						</ng-container>
						<ng-container
							*ngIf="frmRegister.get('email').hasError('email')"
							i18n="@@USER_REGISTER.EMAIL_ENTRY_DIALOG.EMAIL_IS_NOT_VALID">
							Email is not valid.
						</ng-container>
					</mat-error>
				</mat-form-field>
				<span *ngIf="passwordPolicyErrorFlag" style="color: red" i18n="@@NGX.FAILED_TO_LOAD">
					Failed to load password policy
				</span>
				<cls-password-form-field
					*ngIf="frmRegister.get('email').dirty"
					#passwordFormField
					formControlName="newPassword"
					passwordPlaceHolder="Create password"
					[isLoading]="isLoadingPasswordPolicy"></cls-password-form-field>
			</div>

			<div style="padding-bottom: 15px" [style.display]="!frmRegister.get('email').dirty ? 'none' : 'block'">
				<cls-copyleaks-captcha #captcha formControlName="captcha"></cls-copyleaks-captcha>
			</div>
		</div>
		<div mat-dialog-actions fxLayoutAlign="center center" class="actions">
			<cls-spinner-button
				type="submit"
				class="spinner-btn"
				color="primary"
				btnHight="36px"
				[fullWidth]="true"
				[showSpinner]="showButtonSpinner"
				i18n="@@USER_REGISTER.EMAIL_ENTRY_DIALOG.SIGN_UP_BUTTON">
				SIGN UP
			</cls-spinner-button>
		</div>
	</form>
</div>
