import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import {
	EPlatformProductType,
	IPlatformProductsPlans,
	PricingPlan,
	PricingPlanItemResultItem,
	PricingPlanResult,
} from '../../../../../models/pricing.model';
import { IUserSubscriptionPlan, PaymentPlanService } from '../../../../../services/payment-plan.service';
import { PricingService } from '../../../../../services/pricing-plans.service';
import { ScreenService } from '../../../../../services/screen.service';
import {
	ENewPaymentDialogBillingIntervals,
	ENewPaymentDialogSteps,
	ICheckOutSelectedPlan,
	IPlanItemData,
} from '../../models/new-payment-dialog.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs';
import { EPaymentPlanType } from '../../../../../enums/payment-plan-type.enum';
import { GetPlatformProductName } from '@ngx-common/models/pricing-functions.model';
@UntilDestroy()
@Component({
	selector: 'cls-choose-product-plan',
	templateUrl: './choose-product-plan.component.html',
	styleUrls: ['./choose-product-plan.component.scss'],
	animations: [
		trigger('myInsertRemoveTrigger', [
			state('true', style({ height: '*', opacity: '1' })),
			state('false', style({ height: '0', overflow: 'hidden', opacity: '0' })),
			transition('false <=> true', animate(200)),
		]),
	],
})
export class ChooseProductPlanComponent implements OnInit, OnChanges {
	@Input() allProductsPaymentPlans: IPlatformProductsPlans;
	@Input() currentSubscriptionPlan: IUserSubscriptionPlan;
	@Input() isAnonymous: boolean;
	@Input() title: string;
	@Output() continue = new EventEmitter<PricingPlan>();
	isLoading = true;
	hasSubscription: boolean;
	currentDialogStep = ENewPaymentDialogSteps.ChoosePlan;
	eNewPaymentDialogSteps = ENewPaymentDialogSteps;
	eNewPaymentDialogBillingIntervals = ENewPaymentDialogBillingIntervals;
	ePlatformProductTypes = EPlatformProductType;
	keepOnlySelectedView: boolean = false;
	monthlyPlansCredits: number[];
	yearlyPlansCredits: number[];

	// Current selection for the plan on the dialog
	selectedPlanInterval = ENewPaymentDialogBillingIntervals.Yearly;
	selectedPlanProduct = EPlatformProductType.Bundled;
	selectedCreditsAmount: number;

	selectedPlan: PricingPlanResult;

	get isMobile() {
		return this._screenSvc.isMobile();
	}

	get annualSave() {
		let monthlyPriceAnnual = this._getMonthlyProductPlan(this.selectedPlanProduct)?.price * 12;
		return Math.trunc(
			(Math.abs(monthlyPriceAnnual - this._getYearlyProductPlan(this.selectedPlanProduct)?.price) /
				monthlyPriceAnnual) *
				100
		);
	}

	constructor(
		private _screenSvc: ScreenService,
		private _pricingService: PricingService,
		private _paymentPlanSvc: PaymentPlanService
	) {}

	ngOnInit() {
		this._paymentPlanSvc.userBalance$
			.pipe(
				untilDestroyed(this),
				filter(b => !!b)
			)
			.subscribe(balance => {
				if (balance) {
					this.hasSubscription = balance.accountType == EPaymentPlanType.Subscription;
				}
			});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes?.allProductsPaymentPlans?.currentValue) {
			this._getAllPlansCreditsAmount();
			this.selectedCreditsAmount =
				this.selectedPlanInterval == ENewPaymentDialogBillingIntervals.Monthly
					? this.monthlyPlansCredits[0]
					: this.yearlyPlansCredits[0];
			this.isLoading = false;
		}
		if (changes?.currentSubscriptionPlan?.currentValue) {
			if (this.hasSubscription) {
				this.keepOnlySelectedView = true;
				this.selectedPlanProduct = this.currentSubscriptionPlan.platformProductType;
				this.selectedPlanInterval =
					this.currentSubscriptionPlan.interval.months == 12
						? ENewPaymentDialogBillingIntervals.Yearly
						: ENewPaymentDialogBillingIntervals.Monthly;
				this.selectedCreditsAmount = this.currentSubscriptionPlan.creditsPackage;
			}
		}
	}
	// Private methods
	private _getProductName(platformProductType: EPlatformProductType) {
		return GetPlatformProductName(platformProductType);
	}

	private _getProductDescription(platformProductType: EPlatformProductType) {
		switch (platformProductType) {
			case EPlatformProductType.Bundled:
				return $localize`Detect AI-generated content and plagiarism simultaneously`;
			case EPlatformProductType.AIDetector:
				return $localize`Detect AI-generated content with 99% accuracy`;
			case EPlatformProductType.PlagiarismChecker:
				return $localize`Award winning plagiarism detection in nearly every language`;
		}
	}

	private _getProductToolTip(platformProductType: EPlatformProductType) {
		switch (platformProductType) {
			case EPlatformProductType.Bundled:
				return $localize`Get full-spectrum content protection by bundling AI and plagiarism detection into one simple scan and comprehensive report. `;
			case EPlatformProductType.AIDetector:
				return $localize`With over 99% accuracy and full model coverage that includes ChatGPT and Bard, Copyleaks AI Content Detector is the market’s most comprehensive solution.`;
			case EPlatformProductType.PlagiarismChecker:
				return $localize`Detect plagiarism in over 100 languages, including slight variations within the text, hidden and manipulated characters, paraphrasing, and AI-generated content.`;
		}
	}

	private _isCurrentPlan(productType: EPlatformProductType) {
		if (this.currentSubscriptionPlan) {
			if (
				this.currentSubscriptionPlan.creditsPackage == this.selectedCreditsAmount &&
				this.currentSubscriptionPlan.platformProductType == productType
			) {
				return true;
			}
		}
		return false;
	}

	private _getProductPlan(productType: EPlatformProductType) {
		var productPlans = this.allProductsPaymentPlans.products.find(
			product => product.platformProductType == productType
		);
		if (this.selectedPlanInterval == ENewPaymentDialogBillingIntervals.Monthly) {
			let mPlan =
				productPlans.plans.find(plan => plan.monthly.credits == this.selectedCreditsAmount)?.monthly ??
				productPlans.plans.find(plan => plan.monthly.credits > this.selectedCreditsAmount)?.monthly;
			if (!mPlan) return productPlans.plans[productPlans.plans.length - 1]?.monthly;
			return mPlan;
		} else {
			let yPlan =
				productPlans.plans.find(plan => plan.yearly.credits == this.selectedCreditsAmount)?.yearly ??
				productPlans.plans.find(plan => plan.yearly.credits > this.selectedCreditsAmount)?.yearly;
			if (!yPlan) return productPlans.plans[productPlans.plans.length - 1]?.yearly;
			return yPlan;
		}
	}

	private _getMonthlyProductPlan(productType: EPlatformProductType) {
		var productPlans = this.allProductsPaymentPlans.products.find(
			product => product.platformProductType == productType
		);
		if (this.selectedPlanInterval == ENewPaymentDialogBillingIntervals.Yearly)
			return productPlans.plans.find(plan => plan.yearly.credits == this.selectedCreditsAmount)?.monthly;
		return productPlans.plans.find(plan => plan.monthly.credits == this.selectedCreditsAmount)?.monthly;
	}

	private _getYearlyProductPlan(productType: EPlatformProductType) {
		var productPlans = this.allProductsPaymentPlans.products.find(
			product => product.platformProductType == productType
		);
		if (this.selectedPlanInterval == ENewPaymentDialogBillingIntervals.Yearly)
			return productPlans.plans.find(plan => plan.yearly.credits == this.selectedCreditsAmount)?.yearly;
		return productPlans.plans.find(plan => plan.monthly.credits == this.selectedCreditsAmount)?.yearly;
	}

	private async _getAllPlansCreditsAmount() {
		if (!this.allProductsPaymentPlans) {
			this.allProductsPaymentPlans = await this._pricingService.getProductsPlans();
		}
		this.monthlyPlansCredits = this.allProductsPaymentPlans.products[0].plans.map(plan => plan.credits);
		this.yearlyPlansCredits = this.allProductsPaymentPlans.products[0].plans.map(plan => plan?.yearly?.credits);
	}

	private _getMonthlyCredits(credits: number) {
		var index = this.yearlyPlansCredits.findIndex(item => item == credits);
		return this.monthlyPlansCredits[index];
	}
	// Public methods
	getPlanItemData(platformProductType: EPlatformProductType) {
		return {
			productName: this._getProductName(platformProductType),
			isBordered: platformProductType == EPlatformProductType.Bundled && !this.hasSubscription,
			description: this._getProductDescription(platformProductType),
			toolTipMsg: this._getProductToolTip(platformProductType),
			selected: this.selectedPlanProduct == platformProductType,
			isCurrentPlan: this._isCurrentPlan(platformProductType),
			productPlan: this._getProductPlan(platformProductType),
			isLast: this.keepOnlySelectedView || platformProductType == EPlatformProductType.AIDetector,
			monthlyProductPlan: this._getMonthlyProductPlan(platformProductType),
			interval: this.selectedPlanInterval,
			hasDiscount: platformProductType == EPlatformProductType.Bundled,
		} as IPlanItemData;
	}

	getDecimalNumber(value: number) {
		return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	}

	triggerOnlySelectedView() {
		this.keepOnlySelectedView = !this.keepOnlySelectedView;
	}

	changePlanInterval(interval: ENewPaymentDialogBillingIntervals) {
		if (interval != this.selectedPlanInterval) {
			if (this.selectedPlanInterval == ENewPaymentDialogBillingIntervals.Monthly) {
				var planIndex = this.monthlyPlansCredits.findIndex(item => item == this.selectedCreditsAmount);
				this.selectedCreditsAmount = this.yearlyPlansCredits[planIndex];
			} else {
				var planIndex = this.yearlyPlansCredits.findIndex(item => item == this.selectedCreditsAmount);
				this.selectedCreditsAmount = this.monthlyPlansCredits[planIndex];
			}
			this.selectedPlanInterval = interval;
		}
	}

	changeProductSelection(productSelection: EPlatformProductType) {
		this.selectedPlanProduct = productSelection;
	}

	async goToCheckOut() {
		var plan: PricingPlanItemResultItem;
		var selectedProductPlans = this.allProductsPaymentPlans.products.find(
			product => product.platformProductType == this.selectedPlanProduct
		);
		if (this.selectedPlanInterval == ENewPaymentDialogBillingIntervals.Monthly)
			plan = selectedProductPlans.plans.find(plan => plan.credits == this.selectedCreditsAmount).monthly;
		else {
			plan = selectedProductPlans.plans.find(
				plan => plan.credits == this._getMonthlyCredits(this.selectedCreditsAmount)
			).yearly;
		}
		var pricingPlans = await this._pricingService.mapPlansToPricingPlans();
		var selectedPricingPlan = pricingPlans.find(pricingPlan => pricingPlan.id == plan.planId);

		this.continue.emit(selectedPricingPlan);
	}
}
