<div class="page-warper" fxLayout="column" fxLayoutGap="16px">
	<app-lms-navbar
		(itemClick)="navigateWithBreadCrumb($event)"
		class="layout-settings"
		[breadCrumbs]="breadCrumbs"
		[isLoadingData]="isLoading"
		[isAdmin]="false"
		[showIcon]="false"
		backgroundColor="white"></app-lms-navbar>

	<ng-container *ngIf="isLoading">
		<cls-loading-animation [txtMessage]="loadingMessage"></cls-loading-animation>
	</ng-container>

	<div *ngIf="!isLoading" class="data-container" fxLayout="column" fxLayoutGap="8px">
		<ng-container *ngIf="this.dataSrc?.isStatisticsExists; else emptyView">
			<cls-page-banner>
				<cls-page-banner-icon><mat-icon>info_outline</mat-icon></cls-page-banner-icon>
				<cls-page-banner-description i18n>
					Copyleaks LMS Analytics started collecting data on {{ ANALYTICS_COLLECTION_DATE | date }}. Any data before
					this date will not appear.
				</cls-page-banner-description>
			</cls-page-banner>

			<div class="results-insights-template-container">
				<cls-results-insights-template
					fxFlex
					[cardsDataSrc]="dataSrc?.cardsData"
					[isWithScroll]="false"
					[analyticsLevel]="eLevelType.Context"></cls-results-insights-template>
			</div>

			<div fxLayout="row" fxLayoutGap="16px">
				<div class="charts-wrapper line-chart-with-top-legend line-charts-bottom-padding" #insightsItem>
					<div class="chart-title" fxLayout="row" fxLayoutGap="8px">
						<div i18n class="originality-chart-title">Originality Avg Vs. Assignments</div>
						<mat-icon
							class="info-icon"
							i18n-matTooltip
							matTooltip="AI Content and plagiarism scores compared to submitted documents.">
							info_outline
						</mat-icon>
					</div>

					<ngx-charts-line-chart
						*ngIf="view"
						[view]="view"
						[xAxis]="true"
						[yAxis]="true"
						[legend]="true"
						legendTitle=""
						[results]="dataSrc.originalityData.chartData"
						[yAxisLabel]="chartText"
						[showYAxisLabel]="true"
						[showXAxisLabel]="false"
						[showGridLines]="true"
						[customColors]="lineChartCustomColors"
						[yAxisTicks]="dataSrc.originalityData.yTicks"
						[yScaleMax]="dataSrc.originalityData.maxYTick"
						[curve]="linearCurve"
						(window:resize)="onResize($event, insightsItem)"></ngx-charts-line-chart>
				</div>

				<ng-container *ngIf="dataSrc.writingFeedbackFeatureEnabled">
					<div class="charts-wrapper writing-feedback-chart">
						<div class="chart-title" fxLayout="row" fxLayoutGap="8px">
							<div i18n class="originality-chart-title">Avg. Writing Corrections</div>
							<mat-icon class="info-icon" i18n-matTooltip matTooltip="Average number of writing corrections per scan.">
								info_outline
							</mat-icon>
						</div>
						<ngx-charts-bar-vertical
							[view]="writingFeedbackChartView"
							*ngIf="writingFeedbackChartView"
							[scheme]="{ domain: ['#FF9A02'] }"
							[results]="dataSrc.writingFeedbackData.chartData"
							[gradient]="false"
							[xAxis]="true"
							[yAxis]="true"
							[legend]="false"
							[yScaleMin]="0"
							[showXAxisLabel]="true"
							[barPadding]="20"
							[showYAxisLabel]="true"
							[yAxisTicks]="dataSrc.writingFeedbackData.yTicks"
							[yScaleMax]="dataSrc.writingFeedbackData.maxYTick"
							[yAxisLabel]="correctionText"></ngx-charts-bar-vertical>
					</div>
				</ng-container>
				<ng-container *ngIf="!dataSrc.writingFeedbackFeatureEnabled">
					<analytics-empty-state fxFlex>
						<analytics-empty-state-title i18n>Writing Correction</analytics-empty-state-title>
						<analytics-empty-state-image>
							<img src="../../../../assets/analytics/svgs/writingFeedbackEmptyState.svg" alt="Writing Correction" />
						</analytics-empty-state-image>
						<analytics-empty-state-description>
							Keep your writing concise and fresh with tips for improving sentence structure, word choice, overall
							mechanics, spelling, and more.
						</analytics-empty-state-description>
						<analytics-empty-state-bottom-section>
							<ng-container *ngIf="isLoadFromDashboard">
								<div class="empty-state-link-text" i18n>Contact your CSM to enable this feature.</div>
							</ng-container>
							<ng-container *ngIf="!isLoadFromDashboard">
								<div class="empty-state-link-text" i18n>Contact your admin to enable this feature.</div>
							</ng-container>
						</analytics-empty-state-bottom-section>
					</analytics-empty-state>
				</ng-container>
			</div>

			<!-- Table  -->
			<lms-analytics-assignments-tabel
				[dataSrc]="dataSrc?.assignmentsTable"
				[writingFeedbackFeatureEnabled]="dataSrc.writingFeedbackFeatureEnabled"></lms-analytics-assignments-tabel>
		</ng-container>
	</div>
</div>

<ng-template #emptyView>
	<cls-empty-state-template style="padding-bottom: 16px">
		<cls-empty-state-secondary-text i18n *ngIf="dataSrc.showAnalytics">
			Data is Getting Collected...
		</cls-empty-state-secondary-text>
		<cls-empty-state-primary-text i18n *ngIf="dataSrc.showAnalytics">
			Analytics Coming Soon
		</cls-empty-state-primary-text>
	</cls-empty-state-template>
	<cls-empty-state-content>
		<disabled-analytics-view
			*ngIf="!dataSrc.showAnalytics"
			[isAdmin]="isLoadFromDashboard"
			[integrationId]="integrationId"
			[scanSettingsId]="dataSrc.defaultScanSettingsId"
			[platformType]="platformType"></disabled-analytics-view>
	</cls-empty-state-content>
</ng-template>
