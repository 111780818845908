<ng-container *ngIf="instances; else loading">
	<h3 class="list-header" style="margin: 0px" i18n>Integrations</h3>
	<mat-list class="integrations-list" *ngIf="instances.length > 0; else empty">
		<mat-list-item style="height: 60px" class="mat-list-option" *ngFor="let instance of instances">
			<img matListAvatar [src]="images[instance.family]" [alt]="instance.name" />
			<h3 matLine>{{ instance.name }}</h3>
			<p matLine>
				<span i18n>Added</span>
				<span title="{{ instance.createdAt | date: 'medium' }}">{{ instance.createdAt | timeago }}</span>
			</p>
		</mat-list-item>
	</mat-list>
	<ng-template #empty>
		<div class="empty" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
			<mat-icon class="large">check_circle_outline</mat-icon>
			<div i18n>All Set!</div>
			<div style="text-align: center" i18n>Use the Key and Secret to add Copyleaks to your LMS</div>
		</div>
	</ng-template>
</ng-container>

<ng-template #loading>
	<div style="padding: 30px 0px">
		<mat-spinner [diameter]="70" class="center" mode="indeterminate" aria-label="waiting"></mat-spinner>
	</div>
</ng-template>
