<div class="callout-icon" *ngIf="showIcon">
	<ng-container [ngSwitch]="type">
		<ng-container *ngSwitchCase="'success'">
			<mat-icon>check_circle</mat-icon>
		</ng-container>
		<ng-container *ngSwitchCase="'error'">
			<mat-icon>error</mat-icon>
		</ng-container>
		<ng-container *ngSwitchCase="'warn'">
			<mat-icon>warning</mat-icon>
		</ng-container>
		<ng-container *ngSwitchCase="'info'">
			<mat-icon>info</mat-icon>
		</ng-container>
	</ng-container>
</div>
<div class="callout-content">
	<div header #headerRef>
		<ng-content select="cls-callout-title"></ng-content>
	</div>
	<div>
		<ng-content></ng-content>
	</div>
</div>
