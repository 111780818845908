import { InjectionToken } from '@angular/core';
import { ElementsOptions, ElementType, ElementOptions } from './stripe-definitions/element';
import { StripeJS, Stripe, StripeOptions } from './stripe-definitions';
import { loadStripe } from '@stripe/stripe-js';

export interface StripeConfig<T extends ElementType = any> {
	publicKey: string;
	options?: StripeOptions;
	elementsOptions?: ElementsOptions;
	elementOptions?: ElementOptions<T>;
	isPlatformServer?: boolean;
}

export const stripeConfigToken = new InjectionToken<StripeConfig>('wizdm.stripe.config');

/** Retrives the global StripeJS object  */
export function getStripeJS(): StripeJS {
	return !!window ? (window as any).Stripe : undefined;
}

/** Stripe.js v3 script loader */
export function loadStripeJS(config: StripeConfig): Promise<StripeJS> {
	if (config?.isPlatformServer || !config?.publicKey) {
		return Promise.resolve(null);
	}
	const stripjs = getStripeJS();
	stripjs
		? Promise.resolve(stripjs)
		: loadStripe(config?.publicKey).then(stripe => {
				Promise.resolve(stripe);
		  });
}

/** Instantiates a Stripe istance accoding to the provided options */
export function stripeFactory(config: StripeConfig): Stripe {
	if (config.isPlatformServer) {
		return;
	}

	const stripeJS = getStripeJS();
	if (!stripeJS) {
		throw new Error('StripeJS loading failed');
	}

	if (!config || typeof config.publicKey !== 'string') {
		throw new Error('A valid publicKey must be provided');
	}

	return stripeJS(config.publicKey, config.options);
}
