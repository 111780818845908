import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { httpFailRetry } from '../observable/http-fail-retry.pipes';
import { environment } from '@environment';
import { DevicesService } from './devices.service';
import { WindowService } from './window.service';

@Injectable({
	providedIn: 'root',
})
export class MiscService {
	private _userProfileTypes: IUserProfileTypeOption[];
	private _countries: ICountry[];
	private _isAllowFreePlan: boolean;
	private _supportedCrossLanguage: Promise<ISupportedCrossLanguages>;
	constructor(private http: HttpClient, private _devicesSvc: DevicesService, private _windowSvc: WindowService) {}
	async getUrlsFromSiteMap(siteMapUrl: string) {
		const model: ISitemapUrls = {
			url: siteMapUrl,
		};
		try {
			return await this.http.post<string[]>(`${environment.apiUrl}/v1/sitemap-to-urls`, model).toPromise();
		} catch (error) {
			throw error;
		}
	}
	async getProfileTypes() {
		try {
			if (!this._userProfileTypes) {
				this._userProfileTypes = await this.http
					.get<IUserProfileTypeOption[]>(`${environment.apiUrl}/v1/user-profiles`)
					.pipe(httpFailRetry())
					.toPromise();
			}
			return this._userProfileTypes;
		} catch (error) {
			this.initStatic();
			return this._userProfileTypes;
		}
	}

	async GetCountries() {
		try {
			if (!this._countries) {
				this._countries = await this.http
					.get<ICountry[]>(`${environment.apiUrl}/v1/countries?cdn-cache=true&v=1`)
					.pipe(httpFailRetry())
					.toPromise();
				this._countries.push({ name: 'Other', code: 'OTR' } as ICountry);
			}
			return this._countries;
		} catch (error) {
			throw error;
		}
	}
	async isAllowFreePlan() {
		try {
			if (this._isAllowFreePlan === null || this._isAllowFreePlan === undefined) {
				const result = await this.http.get(`${environment.apiUrl}/v1/country-status`).pipe(httpFailRetry()).toPromise();
				if (!result.status) {
					this._isAllowFreePlan = false;
				} else {
					this._isAllowFreePlan = true;
				}
			}
			return this._isAllowFreePlan;
		} catch (error) {
			this._isAllowFreePlan = true;
			return this._isAllowFreePlan;
		}
	}

	public async getSupportedLanguageList(): Promise<ILocale[]> {
		const results = this.http.get<ILocale[]>(`${environment.apiUrl}/v1/language-list`).toPromise();
		return results;
	}

	public async getSupportedCrossLanguageListAsync(authToken: string = null): Promise<ISupportedCrossLanguages> {
		if (!this._supportedCrossLanguage) {
			if (!authToken) {
				this._supportedCrossLanguage = this.http
					.get<ISupportedCrossLanguages>(`${environment.apiUrl}/v1/supported-cross-languages-list`)
					.toPromise();
			} else {
				let headers = new HttpHeaders();
				headers = new HttpHeaders().append('Authorization', `Bearer ${authToken}`);
				this._supportedCrossLanguage = this.http
					.get<ISupportedCrossLanguages>(`${environment.apiUrl}/api/v1/supported-cross-languages-list`, { headers })
					.toPromise();
			}
		}
		return this._supportedCrossLanguage;
	}

	private initStatic() {
		this._userProfileTypes = [
			{
				name: 'Middle School Teacher',
				id: 1,
			},
			{
				name: 'Middle School Faculty',
				id: 2,
			},
			{
				name: 'Middle School IT',
				id: 3,
			},
			{
				name: 'Middle School Student',
				id: 4,
			},
			{
				name: 'High School Teacher',
				id: 20,
			},
			{
				name: 'High School Faculty',
				id: 5,
			},
			{
				name: 'High School IT',
				id: 6,
			},
			{
				name: 'High School Student',
				id: 7,
			},
			{
				name: 'College/University Professor',
				id: 8,
			},
			{
				name: 'College/University Faculty',
				id: 9,
			},
			{
				name: 'College/University IT',
				id: 10,
			},
			{
				name: 'College/University Student',
				id: 11,
			},
			{
				name: 'Masters Student',
				id: 12,
			},
			{
				name: 'PhD Student',
				id: 13,
			},
			{
				name: 'Education - Other',
				id: 21,
			},
			{
				name: 'Publisher',
				id: 14,
			},
			{
				name: 'Blogger',
				id: 15,
			},
			{
				name: 'Writer',
				id: 16,
			},
			{
				name: 'SEO agency',
				id: 17,
			},
			{
				name: 'Legal',
				id: 18,
			},
			{
				name: 'Small Business',
				id: 19,
			},
			{
				name: 'Business - Other',
				id: 22,
			},
			{
				name: 'Educator',
				id: 101,
			},
		];
	}
}

export interface IUserProfileTypeOption {
	name: string;
	id: number;
}

export interface ICountry {
	name: string;
	code: string;
	states: string[];
}
export interface ILocale {
	code: string;
	label: string;
}

export interface ISitemapUrls {
	url: string;
}

export interface ISupportedCrossLanguages {
	documentLanguages: Map<string, string>;
	resultLanguages: Map<string, string>;
}
