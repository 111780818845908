import { Component, HostListener, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ClsAlertsService } from '@ngx-common-v2/components/cls-alerts/services/cls-alerts.service';
import { take } from 'rxjs';
import { INewPaymentDialogComponentData } from '../../../../../dialogs/new-payment-dialog/new-payment-dialog/models/new-payment-dialog.model';
import { NewPaymentDialogComponent } from '../../../../../dialogs/new-payment-dialog/new-payment-dialog/new-payment-dialog.component';
import { PaymentDialogService } from '../../../../../dialogs/payment-dialog/services/payment-dialog.service';
import { EPlatformProductType } from '../../../../../models/pricing.model';
import { AppDirectionalityService } from '../../../../../services/app-directionality.service';
import { PricingService } from '../../../../../services/pricing-plans.service';
import { IOpenPaymentDialogEventModel } from '../../models/notification-item-general.models';

const PAYMENT_DIALOG_ID = 'general-notification-open-payment-dialog';

@Component({
	selector: 'cls-notification-item-general-payment-dialog',
	templateUrl: './notification-item-general-payment-dialog.component.html',
	styleUrls: ['./notification-item-general-payment-dialog.component.scss'],
})
export class NotificationItemGeneralPaymentDialogComponent implements OnInit {
	@HostListener('click', ['$event.target'])
	onClick() {
		// open the payment dialog with the specified parameters
		this._notificationClickHandler();
	}

	@Input() paymentDialogData: IOpenPaymentDialogEventModel;
	@Input() title: string;
	@Input() description: string;
	@Input() matIcon: string;
	@Input() isRead: boolean;

	private _isPaymentDialogOpen: boolean;

	constructor(
		private _paymentDialogSvc: PaymentDialogService,
		private _matDialogSvc: MatDialog,
		private readonly _dir: AppDirectionalityService,
		private _pricingSvc: PricingService,
		private _clsAlertsSvc: ClsAlertsService
	) {}

	async ngOnInit() {
		this._paymentDialogSvc.initOldPaymentMethods();
	}

	private _notificationClickHandler() {
		this._initSelectedPlan();
		if (this._isPaymentDialogOpen) return;

		this._isPaymentDialogOpen = true;
		const dialogRef = this._matDialogSvc.open(NewPaymentDialogComponent, {
			autoFocus: false,
			direction: this._dir.value,
			panelClass: ['mat-dialog-custom-payment-dialog'],
			data: {
				planId: this.paymentDialogData?.planId ?? '',
				dialogId: PAYMENT_DIALOG_ID,
				title: this.paymentDialogData?.dialogTitle,
			} as INewPaymentDialogComponentData,
		});
		dialogRef
			.afterClosed()
			.pipe(take(1))
			.subscribe(
				_ => (this._isPaymentDialogOpen = false),
				_ => (this._isPaymentDialogOpen = false)
			);
	}

	private async _initSelectedPlan() {
		try {
			const selectedPlanId = this.paymentDialogData?.planId;
			const plans = await this._pricingSvc.getProductsPlans(this.paymentDialogData?.coupon);
			const pricingPlans = await this._pricingSvc.mapPlansToPricingPlans();
			let selectedPlan = pricingPlans.find(p => p.id === selectedPlanId);

			if (!selectedPlan) {
				this._paymentDialogSvc.initPaymentPlan(
					100,
					PAYMENT_DIALOG_ID,
					EPlatformProductType.Bundled,
					this.paymentDialogData.product,
					true,
					this.paymentDialogData?.coupon
				);
				return;
			}
			const isMonthlyPayment = selectedPlan.interval.months !== 12;
			let minCredits = selectedPlan.credits;
			if (!isMonthlyPayment) {
				const monthlyPlan = await this._pricingSvc.getMonthlyPlanByCredits(selectedPlan.credits);
				minCredits = monthlyPlan.credits;
			}
			var selectProduct = this._paymentDialogSvc.getSelectedProduct(plans, selectedPlanId);
			this._paymentDialogSvc.initPaymentPlan(
				minCredits,
				PAYMENT_DIALOG_ID,
				selectProduct,
				this.paymentDialogData.product,
				true,
				this.paymentDialogData?.coupon
			);
		} catch (error) {
			this._clsAlertsSvc.showHttpResponseError(error);
		}
	}
}
