import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NumeralPipe } from '@ngx-common/pipes/numeral/numeral.pipe';
import { ClsCircularProgressBarComponent } from './cls-circular-progress-bar.component';

@NgModule({
	declarations: [ClsCircularProgressBarComponent],
	imports: [CommonModule, FlexLayoutModule, MatProgressSpinnerModule],
	providers: [NumeralPipe],
	exports: [ClsCircularProgressBarComponent],
})
export class ClsCircularProgressBarModule {}
