import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FloatDecimalPipe } from './float-decimal.pipe';

@NgModule({
	declarations: [FloatDecimalPipe],
	exports: [FloatDecimalPipe],
	imports: [CommonModule],
})
export class FloatDecimalPipeModule {}
