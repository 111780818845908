import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatBadgeModule } from '@angular/material/badge';
import { MatIconModule } from '@angular/material/icon';
import { ClsCircularProgressBarModule } from '../../../../../ngx-common-v2/components/cls-circular-progress-bar/cls-circular-progress-bar.module';
import { ClsImageButtonModule } from '../../../../../ngx-common-v2/components/cls-image-button/cls-image-button.module';
import { UserPlanComponent } from './user-plan.component';
import { ClsButtonModule } from '../../../../../ngx-common-v2/components/cls-button/cls-button.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NewPaymentDialogModule } from '../../../../dialogs/new-payment-dialog/new-payment-dialog/new-payment-dialog.module';
import { RefillsBudgetSelectModule } from '../../../../components/refills-budget-select/refills-budget-select.module';
import { IdentityDialogModule } from '../../../../dialogs/identity-dialog/identity-dialog.module';
import { UnPauseSubscriptionDialogModule } from '../../../../dialogs/unpause-subscirption-dialog/unpause-subscirption-dialog.module';

@NgModule({
	declarations: [UserPlanComponent],
	exports: [UserPlanComponent],
	imports: [
		CommonModule,
		FlexLayoutModule,
		MatIconModule,
		MatTooltipModule,
		MatBadgeModule,
		NewPaymentDialogModule,
		ClsCircularProgressBarModule,
		ClsImageButtonModule,
		ClsButtonModule,
		RefillsBudgetSelectModule,
		IdentityDialogModule,
		UnPauseSubscriptionDialogModule,
	],
})
export class UserPlanModule {}
