import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BoardComponent, BoardHeader, BoardBody, BoardActions } from './board/board.component';

@NgModule({
	declarations: [BoardComponent, BoardHeader, BoardBody, BoardActions],
	exports: [BoardComponent, BoardHeader, BoardBody, BoardActions],
	imports: [CommonModule],
})
export class BoardModule {}
