import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { AnalyticsEmptyStateModule } from '@ngx-common-v2/components/analytics-components/templates/analytics-empty-state/analytics-empty-state.module';
import { ScreenService } from '@ngx-common/services/screen.service';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ClsResultsOverviewModule } from 'ngx-common-v2/components/analytics-components/charts/cls-results-overview/cls-results-overview.module';
import { ClsCommonResultsTemplateModule } from 'ngx-common-v2/components/analytics-components/templates/cls-common-results-template/cls-common-results-template.module';
import { ClsEmptyStateTemplateModule } from 'ngx-common-v2/components/analytics-components/templates/cls-empty-state-template/cls-empty-state-template.module';
import { ResultsInsightsTemplateModule } from 'ngx-common-v2/components/analytics-components/templates/cls-results-insights-template/cls-results-insights-template.module';
import { ClsAlertsService } from 'ngx-common-v2/components/cls-alerts/services/cls-alerts.service';
import { ClsContainedContentModule } from 'ngx-common-v2/components/cls-contained-content/cls-contained-content.module';
import { ClsLoadingAnimationModule } from 'ngx-common-v2/components/cls-loading-animation/cls-loading-animation.module';
import { ClsPageBannerModule } from 'ngx-common-v2/components/cls-page-banner/cls-page-banner.module';
import { ClsPaginatorModule } from 'ngx-common-v2/components/cls-paginator/cls-paginator.module';
import { ClsScoreLevelModule } from 'ngx-common-v2/components/cls-score-level/cls-score-level.module';
import { ClsSearchInputModule } from 'ngx-common-v2/components/cls-search-input/cls-search-input.module';
import { ClsSpinnerModule } from 'ngx-common-v2/components/cls-spinner/cls-spinner.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { LmsNavbarModule } from 'shared/componenets/lms-navbar/lms-navbar.module';
import { LMSWritingFeedbackScoreModule } from 'shared/componenets/lms-writing-feedback-score/lms-writing-feedback-score.module';
import { AnalyticsActivityStatusModule } from '../../components/analytics-activity-status/analytics-activity-status.module';
import { DisabledAnalyticsViewModule } from '../../components/disabled-analytics-view/disabled-analytics-view.module';
import { AnalyticsDataService } from '../../services/analytics-data.service';
import { AnalytiscUtilsService } from '../../services/analytics.utils';
import { AnalyticsActivityComponent } from './analytics-activity.component';
@NgModule({
	declarations: [AnalyticsActivityComponent],
	exports: [AnalyticsActivityComponent],
	imports: [
		CommonModule,
		FlexLayoutModule,
		ResultsInsightsTemplateModule,
		MatIconModule,
		MatTooltipModule,
		ClsResultsOverviewModule,
		NgxChartsModule,
		NgxSkeletonLoaderModule,
		MatTableModule,
		ClsSearchInputModule,
		ClsContainedContentModule,
		ClsPaginatorModule,
		ClsScoreLevelModule,
		MatMenuModule,
		ClsCommonResultsTemplateModule,
		ClsLoadingAnimationModule,
		LmsNavbarModule,
		MatChipsModule,
		ClsSpinnerModule,
		AnalyticsActivityStatusModule,
		ClsPageBannerModule,
		ClsEmptyStateTemplateModule,
		DisabledAnalyticsViewModule,
		AnalyticsEmptyStateModule,
		LMSWritingFeedbackScoreModule,
		MatButtonModule,
		RouterModule,
	],
	providers: [AnalyticsDataService, ScreenService, AnalytiscUtilsService, ClsAlertsService],
})
export class AnalyticsActivityModule {}
