import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatusMessageBannerComponent } from './status-message-banner.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { StatusMessageBannerService } from './status-message-banner.service';

@NgModule({
	declarations: [StatusMessageBannerComponent],
	exports: [StatusMessageBannerComponent],
	imports: [CommonModule, FlexLayoutModule, MatIconModule],
	providers: [StatusMessageBannerService],
})
export class StatusMessageBannerModule {}
