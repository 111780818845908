import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClsSpinnerComponent } from './cls-spinner.component';

@NgModule({
	declarations: [ClsSpinnerComponent],
	imports: [CommonModule],
	exports: [ClsSpinnerComponent],
})
export class ClsSpinnerModule {}
