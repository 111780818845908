import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
	selector: '[appRemoveWhiteSpace]',
})
export class RemoveWhiteSpaceDirective implements OnInit {
	constructor(private elementRef: ElementRef<HTMLElement>) {}
	ngOnInit() {
		const text = this.elementRef.nativeElement.textContent;
		this.elementRef.nativeElement.textContent = text.replace(/\s+/g, ' ');
	}
}
