import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppSnackbarService } from '@ngx-common/services/snackbar.service';
import { remove } from 'lodash';
import { RepositoriesService } from 'shared/services/repositories.service';
import { ProgressButtonEvent } from '../../directives/progress-button.directive';
import { Institute, InstituteService } from '../../services/institutes.service';
import { DeleteSomethingDialog } from '../dialogs/delete-something-dialog/delete-something-dialog.component';
import { InstituteCard } from '../institute-card/institute-card.component';

@Component({
	selector: 'institute-list',
	templateUrl: './institute-list.component.html',
	styleUrls: ['./institute-list.component.scss'],
})
export class InstituteListComponent implements OnInit {
	public institutes: Institute[];
	constructor(
		private instituteService: InstituteService,
		private repositoriesService: RepositoriesService,
		private dialog: MatDialog,
		private snackbarSvc: AppSnackbarService
	) {}
	async ngOnInit() {
		await this.repositoriesService.initPrivateRepositoriesAsync();
		this.instituteService.getInstitutes().subscribe(
			institutes => {
				console.log(institutes);
				this.institutes = institutes;
			},
			err => this.snackbarSvc.showHttpResponseError(err)
		);
	}

	regenerateSecret(instituteCard: InstituteCard) {
		const { institute } = instituteCard;

		this.instituteService.regenerateSecret(institute.id).subscribe(
			({ secret }) => {
				institute.secret = secret;
				instituteCard.toggleSecret(true);
				this.snackbarSvc.showCustomInfo($localize`New secret generated!`);
			},
			err => this.snackbarSvc.showHttpResponseError(err)
		);
	}
	confirmDelete(institute: Institute) {
		this.dialog.open(DeleteSomethingDialog, {
			data: {
				title: $localize`delete all data?`,
				message: $localize`Are you sure you want to delete all institution data?`,
				item: {
					type: 'institution',
					expect: institute.name,
				},
				action: this.onInstituteDelete(institute),
			},
		});
	}
	onInstituteDelete(institute: Institute) {
		return async (result: boolean, progress: ProgressButtonEvent) => {
			if (!result) {
				return;
			}
			progress.start();
			try {
				await this.instituteService.deleteInstitute(institute.id).toPromise();
				const [deleted] = remove(this.institutes, inst => inst.id === institute.id);
				this.snackbarSvc.showCustomInfo($localize`Institution ${deleted.name} was deleted!`);
				progress.stop();
			} catch (err) {
				this.snackbarSvc.showHttpResponseError(err);
				progress.stop();
			}
		};
	}

	editInstitute(institute: Institute) {
		this.instituteService.editInstitute(institute).subscribe((res: Institute) => {
			this.snackbarSvc.showCustomInfo($localize`Institution saved!`);
		}, this.snackbarSvc.showHttpResponseError);
	}
}
