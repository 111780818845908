import { Component, HostListener, Input, OnInit } from '@angular/core';
import { EPathElementGeneralType } from '../../../../../enums/path-element-general-type.enum';
import { IPathElement } from '../../../../../models/elements.models';
import { UserScanData } from '../../../../../models/user-scans-models';
import { ISupportedFileTypes } from '../../../../../models/v3-specifications-main.model';
import { ISharedElementData } from '../../../../../notifications/models/web-notifications.models';
import { WindowService } from '../../../../../services/window.service';

@Component({
	selector: 'app-shared-item',
	templateUrl: './shared-item.component.html',
	styleUrls: ['./shared-item.component.scss'],
})
export class SharedItemComponent implements OnInit {
	@Input() data: ISharedElementData;
	@Input() supportedFileTypes: ISupportedFileTypes;
	icon: string;

	get supportedOcrFilesMimeTypes(): string[] {
		return this.supportedFileTypes?.code.map(s => s.extension);
	}
	get supportedRegularFilesMimeTypes(): string[] {
		return this.supportedFileTypes?.code.map(s => s.extension);
	}
	get supportedCodeFilesMimeTypes(): string[] {
		return this.supportedFileTypes?.code.map(s => s.extension);
	}

	constructor(private _windowService: WindowService) {}

	ngOnInit(): void {
		this.icon = UserScanData.getItemIcon(
			{
				name: this.data.displayName,
				generalType: this.data.type,
			} as IPathElement,
			this.supportedOcrFilesMimeTypes,
			this.supportedRegularFilesMimeTypes,
			this.supportedCodeFilesMimeTypes
		);
	}

	@HostListener('click', ['$event.target'])
	onClick() {
		if (this.data.type == EPathElementGeneralType.Folder)
			this._windowService.open(
				`${location.origin}/dashboard/v1/account/shared-with-me?folderId=${this.data.elementId}`,
				'_blank'
			);
		else
			this._windowService.open(`${location.origin}/dashboard/v1/account/scans/${this.data.elementId}/report`, '_blank');
	}
}
