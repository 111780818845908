<div
	*ngIf="!paymentMethodsFinishedInit"
	class="loading-container"
	[ngClass]="{ 'dialog-mode': horizontalMode }"
	style="height: 100%"
	fxLayoutAlign="center center">
	<cls-spinner width="100px"></cls-spinner>
</div>
<div class="main-container" *ngIf="paymentMethodsFinishedInit" [ngClass]="{ 'dialog-mode': horizontalMode }">
	<!--  -->
	<div class="payment-checkout-content-container" fxLayout="column" fxLayoutGap="8px" fxLayoutAlign="start start">
		<!--  -->
		<div *ngIf="errMsg" class="error-msg">
			<mat-icon>warning</mat-icon>
			<span>{{ errMsg }}</span>
		</div>
		<!--  -->
		<div *ngIf="isCheckOutPage" class="back-btn-container">
			<button mat-button fxLayout="row" fxLayoutAlign="center center" class="back-btn" (click)="back()">
				<mat-icon class="back-icon">chevron_left</mat-icon>
				<span class="back-text" i18n>Back</span>
			</button>
		</div>
		<!--  -->
		<h2
			class="confirm-payment-method-title"
			i18n="@@PAYMENT_CHECKOUT.TITLE.CHANGE_YOUR_PAYMENT_METHOD"
			*ngIf="subscriptionState?.billingStatus === eSubscriptionBillingStatus.UnHealthy">
			Change your payment method
		</h2>
		<!--  -->
		<h2
			class="confirm-payment-method-title"
			i18n="@@PAYMENT_CHECKOUT.TITLE.VERIFY_YOUR_ACCOUNT_DETAILS"
			*ngIf="subscriptionState?.billingStatus === eSubscriptionBillingStatus.PaymentLockedOut">
			Verify your account details
		</h2>
		<!--  -->
		<div
			[fxLayout]="horizontalMode ? 'row' : 'column'"
			[ngStyle]="{ width: horizontalMode ? '100%' : 'unset' }"
			fxLayout.lt-md="column"
			fxLayoutGap="32px">
			<div class="address-and-payment-method-container" fxLayout="column">
				<div class="billing-address-section" [ngClass]="{ 'billing-address-section-checkout-page': isCheckOutPage }">
					<cls-billing-address
						[disableForm]="formSubmittedCreditCard"
						[formControl]="billingAddressCtrl"
						[showCheckMarkCircle]="validAddress && isCheckOutPage"
						#billingAdressRef></cls-billing-address>
				</div>
				<div
					class="choose-payment-method-section"
					[ngClass]="{ 'choose-payment-method-section-checkout-page': isCheckOutPage }"
					fxLayout="column"
					fxLayoutGap="8px">
					<div fxLayout="row" fxLayoutAlign="space-between center" class="payment-method-title">
						<h3 [ngClass]="{ 'payment-title-checkout-page': isCheckOutPage }" no-margin-top fxLayoutGap="4px" i18n>
							Payment Method
						</h3>
						<h5
							class="back-saved-card"
							*ngIf="choosePaymentMethodData?.havePaymentMethod && !choosePaymentMethodData?.isOldPaymentMethod"
							no-margin-top
							fxLayoutGap="4px"
							(click)="cancelUpdateCC()"
							i18n>
							Cancel update
						</h5>
					</div>
					<div [ngClass]="{ 'choose-payment-method-checkout-page': isCheckOutPage }">
						<cls-choose-payment-method
							*ngIf="showCC"
							#choosePaymentMethodRef
							[formSubmitted]="formSubmittedCreditCard"
							[billingStatus]="subscriptionState?.billingStatus"
							[showTitle]="false"
							[showCheckMarkCircle]="isCheckOutPage"
							(updateCard)="UpdatePaymentMethod()"
							(exportData)="onChoosePaymentMethod($event)"
							[newDesign]="true"></cls-choose-payment-method>
					</div>
					<!--  -->
				</div>
			</div>
			<cls-payment
				class="payment-component"
				[ngClass]="{ 'payment-component-checkout-page': isCheckOutPage }"
				[productType]="productType"
				[selectedPlatformProduct]="selectedPlatformProduct"
				[isMonthly]="!paymentDetails?.isMonthlyPayment"
				[billingAddress]="billingAddressCtrl?.value"
				[horizontalMode]="horizontalMode"
				[repositoryId]="repositoryId"
				[isLoading]="!paymentDetails"
				[isCheckOutPage]="isCheckOutPage"
				(confirmPayment)="onConfirmPayment($event)"
				(priceOfferError)="updateErrorMessage($event)"
				(confirmPaymentBtnClicked)="confirmPaymentBtnClicked($event)"
				(validAddressEvent)="validAddress = $event"
				[isOldPaymentMethod]="choosePaymentMethodData?.isOldPaymentMethod"
				[selectedPaymentMethodId]="choosePaymentMethodData?.selectedPaymentMethodId"
				[newPaymentMethod]="choosePaymentMethodData?.newPaymentMethod"
				[billingStatus]="subscriptionState?.billingStatus"
				[isMobile]="isMobile"
				[newDesign]="true"
				[selectedPlan]="selectedPlan"
				[monthlyPlan]="paymentDetails?.monthlyPlan"
				[yearlyPlan]="paymentDetails?.yearlyPlan"
				#paymentRef
				[leadName]="'new-checkout-page'"
				[showPromoCodeInputSection]="showPromoCodeInput"
				[coupon]="initCoupon"></cls-payment>
		</div>
	</div>
	<!--  -->
</div>
