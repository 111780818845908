<div class="pie-chart-container" fxLayout="column" fxLayoutAlign="space-between center">
	<h3 class="pie-chart-title">{{ pieChartTitle }}</h3>
	<ngx-charts-pie-chart
		[scheme]="colorScheme"
		[results]="chartData"
		[legend]="false"
		[doughnut]="true"
		[view]="[290, 290]">
		<ng-template #tooltipTemplate let-model="model">
			<div>{{ model.name }} {{ isEmpty ? '' : '- ' + ((model.value / totalSum) * 100 | number: '1.0-0') + '%' }}</div>
		</ng-template>
	</ngx-charts-pie-chart>

	<!-- Two rows -->
	<ng-container *ngIf="!isEmpty && dataSrc.length > 5">
		<div fxLayout="row" fxLayoutAlign="space-between" class="two-rows-indexes">
			<div fxLayout="column" class="chart-indexes">
				<ng-container *ngFor="let resource of dataSrc.slice(0, 5); let i = index">
					<ng-container
						[ngTemplateOutlet]="chartIndex"
						[ngTemplateOutletContext]="{ item: resource, index: i }"></ng-container>
				</ng-container>
			</div>
			<div fxLayout="column" class="chart-indexes">
				<ng-container *ngFor="let resource of dataSrc.slice(5, dataSrc.length); let i = index">
					<ng-container
						[ngTemplateOutlet]="chartIndex"
						[ngTemplateOutletContext]="{ item: resource, index: i + 5 }"></ng-container>
				</ng-container>
			</div>
		</div>
	</ng-container>

	<!-- one row -->
	<ng-container *ngIf="!isEmpty && dataSrc.length < 6">
		<div fxLayout="column" class="chart-indexes one-row-indexes">
			<ng-container *ngFor="let resource of dataSrc.slice(0, dataSrc.length); let i = index">
				<ng-container
					[ngTemplateOutlet]="chartIndex"
					[ngTemplateOutletContext]="{ item: resource, index: i }"></ng-container>
			</ng-container>
		</div>
	</ng-container>

	<!-- Empty -->
	<ng-container *ngIf="isEmpty">
		<div fxLayout="row" fxLayoutAlign="center center" class="chart-indexes empty-state" style="min-height: 130px">
			<ng-container
				[ngTemplateOutlet]="chartIndex"
				[ngTemplateOutletContext]="{ item: chartData[0], index: 9 }"></ng-container>
		</div>
	</ng-container>
</div>

<ng-template #chartIndex let-item="item" let-index="index">
	<div fxLayout="row" fxLayoutGap="8px" [matTooltip]="item.value + ' - ' + item.name" style="max-height: 19px">
		<span class="resource-color-circle" [style]="{ 'background-color': colorArray[index] }"></span>
		<div class="resource-name">{{ item.name }}</div>
	</div>
</ng-template>
