import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PaymentStatusResult } from '../../components/payment/payment-component/payment.component';
import { ENewPaymentDialogBillingIntervals } from '../../dialogs/new-payment-dialog/new-payment-dialog/models/new-payment-dialog.model';
import { EProductType } from '../../enums/product-type.enums';
import { EPlatformProductType } from '../../models/pricing.model';
import { ConfettiService } from '../../services/confetti.service';
import { PricingService } from '../../services/pricing-plans.service';

@Component({
	selector: 'cls-payment-proccess',
	templateUrl: './payment-proccess.component.html',
	styleUrls: ['./payment-proccess.component.scss'],
})
export class PaymentProccessComponent implements OnInit {
	invoiceId: string;
	@Input() initCoupon: string;
	@Input() creditsPlan: number;
	@Input() horizontalMode: boolean = true;
	@Input() selectedPlanProduct: EPlatformProductType = EPlatformProductType.Bundled;
	@Input() planId: string;
	@Input() showPromoCodeInput = false;
	@Output() succcefullyFinished = new EventEmitter<boolean>();
	@Output() proccessing = new EventEmitter<boolean>();
	@Input() productType = EProductType.App;
	constructor(private _confettiService: ConfettiService, private _pricingService: PricingService) {}

	ngOnInit(): void {}

	confirmPayment($event: PaymentStatusResult) {
		if ($event.isSuccess) {
			this.invoiceId = $event.invoiceId;
			if (this.proccessing) this.proccessing.emit(true);
		}
	}

	public async balanceUpdated() {
		this._confettiService.create();
		this.succcefullyFinished.emit(true);
	}
}
