import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment';
import { AuthService } from './auth.service';
import { httpFailRetry } from '../observable/http-fail-retry.pipes';

@Injectable()
export class UserLocationService {
	constructor(private _http: HttpClient, private _authSvc: AuthService) {}
	async getUserCountryCode() {
		if (this._authSvc.isLoggedIn()) {
			return this._authSvc.user().countryCode;
		} else {
			try {
				const country = await this.getUserCountry();
				return country.code;
			} catch (error) {
				return 'OTR';
			}
		}
	}
	async getUserCountry() {
		return await this._http
			.get(`${environment.apiUrl}/v1/get-country-code`)
			.pipe<IUserCountry>(httpFailRetry())
			.toPromise();
	}
}

export interface IUserCountry {
	code: string;
}
