import { DecimalPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { environment } from '@environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, take } from 'rxjs';
import { INewPaymentDialogComponentData } from '../../../../dialogs/new-payment-dialog/new-payment-dialog/models/new-payment-dialog.model';
import { NewPaymentDialogComponent } from '../../../../dialogs/new-payment-dialog/new-payment-dialog/new-payment-dialog.component';
import { EPaymentPlanType } from '../../../../enums/payment-plan-type.enum';
import { EProductType } from '../../../../enums/product-type.enums';
import { UserInfo } from '../../../../models/auth.model';
import { IUserBalance } from '../../../../models/user-balance.models';
import { ECopyleaksAPP } from '../../../../pages/pages.config';
import { AppDirectionalityService } from '../../../../services/app-directionality.service';
import { AuthService } from '../../../../services/auth.service';
import {
	ESubscriptionBillingStatus,
	ISharedAccountBalance,
	IUserSubscriptionState,
	PaymentPlanService,
} from '../../../../services/payment-plan.service';
import { PricingService } from '../../../../services/pricing-plans.service';
import { EDashboardActionEvents } from '../../enums/dashboard-items.enum';
import { NewDashboardNavbarV2Service } from '../../services/new-dashboard-navbar-v2.service';
import { IdentityDialogComponent } from '../../../../dialogs/identity-dialog/identity-dialog.component';
import {
	IRefillsBudgetSelectDialogComponentData,
	RefillsBudgetSelectComponent,
} from '../../../../components/refills-budget-select/refills-budget-select.component';
import { EWalletType } from '../../../../enums/wallet-type.enum';
import { SubscriptionBalanceService } from '../../../../services/billing-page-services/subscription-balance.service';
import { UrlLocationService } from '../../../../services/url-location.service';
import {
	UnPauseSubscriptionDialogComponent,
	IUnPauseSubscriptionDialogDialogModel,
} from '@ngx-common/dialogs/unpause-subscirption-dialog/unpause-subscirption-dialog.component';
import { EDeploymentType } from '../../../../enums/deployment-type.enum';

@UntilDestroy()
@Component({
	selector: 'cls-user-plan',
	templateUrl: './user-plan.component.html',
	styleUrls: ['./user-plan.component.scss'],
})
export class UserPlanComponent implements OnInit {
	// Inputs & Outputs
	@Input() app: ECopyleaksAPP;
	@Input() userBalance: IUserBalance;
	@Input() user: UserInfo;
	@Input() isMobile: boolean;
	@Input() sharedAccount: ISharedAccountBalance;
	@Input() sharedAccountName: string;
	@Output() externalLinkTriggered = new EventEmitter<string>();

	// Enums & Consts
	environment = environment;
	eDashboardActionEvents = EDashboardActionEvents;
	ePaymentPlanType = EPaymentPlanType;

	// Variables
	isLoading = true;
	isCanceled = false;
	subscriptionState: IUserSubscriptionState;
	pagesLeft: number;
	planPages: number = 0;
	private _isPaymentDialogOpen: boolean;
	paymentDialog: {
		features: string[];
	};
	unHealtySubscription: boolean;

	// Get Accessors
	get isGuestUser() {
		return this._authSvc.isGuestUser;
	}

	get isUserAnOrganizationAdmin() {
		return this._authSvc.checkUserIfOrganizationAdmin();
	}

	get isUserOnlyAnOrganizationMember() {
		return (
			!this._authSvc.checkUserIfOrganizationOwner() &&
			!this._authSvc.checkUserIfOrganizationAdmin() &&
			this._authSvc.checkUserIfOrganizationMember()
		);
	}

	get canShowTag() {
		if (
			this.isGuestUser ||
			(this.userBalance &&
				(this.userBalance?.accountType == EPaymentPlanType.Prepaid ||
					this.userBalance?.accountType == EPaymentPlanType.SharedAccount))
		) {
			return true;
		}
		return false;
	}

	get numberOfRefillsLeft() {
		return this.subscriptionState.refills.max - this.subscriptionState.refills.current;
	}

	constructor(
		private _newDashboardNavbarSvc: NewDashboardNavbarV2Service,
		private _paymentPlanSvc: PaymentPlanService,
		private _urlLocationSvc: UrlLocationService,
		private _router: Router,
		private _authSvc: AuthService,
		private _pricingService: PricingService,
		private _decimalPipe: DecimalPipe,
		private _dialogSvc: MatDialog,
		private _subSvc: SubscriptionBalanceService,
		private readonly dir: AppDirectionalityService
	) {}

	async ngOnInit() {
		this._init();
	}

	async _init() {
		this._authSvc.user$.pipe(untilDestroyed(this)).subscribe(user => {
			let isLoggedIn = this._authSvc.isLoggedIn();
			if (isLoggedIn && user) {
				if (!this._paymentPlanSvc.userBalance) this._paymentPlanSvc.updateUserBalance(false, true, true);
			}
		});

		this._paymentPlanSvc.userBalance$
			.pipe(
				untilDestroyed(this),
				filter(b => !!b)
			)
			.subscribe(async data => {
				this.userBalance = data;
				this.pagesLeft = data.current;
				this.planPages = data.max;
				await this._initSubscription();
				this.isLoading = false;
			});
	}

	getProductType() {
		switch (this.app) {
			case ECopyleaksAPP.MainWebsite:
				return EProductType.App;
			case ECopyleaksAPP.API:
				return EProductType.Api;
		}
	}

	async _initSubscription() {
		if (this.userBalance.accountType == EPaymentPlanType.Subscription) {
			this.subscriptionState = await this._paymentPlanSvc.getSubscriptionState(this.getProductType());
			this.unHealtySubscription = this.subscriptionState.billingStatus == ESubscriptionBillingStatus.UnHealthy;
			this.isCanceled = !!this.subscriptionState?.nextPlan;
			if (this.app == ECopyleaksAPP.MainWebsite) {
				try {
					await (<any>window)?.churnkey.check('failed-payment', {
						subscriptionId: this.subscriptionState.churnkeyDetails.subscriptionId, // optional
						customerId: this.subscriptionState.churnkeyDetails.customerId, // required
						authHash: this.subscriptionState.churnkeyDetails.authHash, // required
						appId: this.subscriptionState.churnkeyDetails.appId, // required
						mode: environment.deploymentType === EDeploymentType.Production ? 'live' : 'test',
						provider: 'stripe',
						softWall: true, // set to true to allow exiting wall
						forceCheck: false, // recommended to leave this to false to avoid redundant checks
						gracePeriodDays: 0, // allow a grace period in which failed payment wall is shown but can be exited (soft wall)
						ignoreInvoicesWithoutAttempt: true, // set to true to ignore invoices without a failed charge
					});
				} catch (err) {
					console.log(err);
				}
			}
		}
	}

	getUserAccountType() {
		if (this._authSvc.isGuestUser) return $localize`Guest Account`;
		if (this.userBalance?.accountType == EPaymentPlanType.Prepaid) return $localize`Prepaid`;
		if (this.userBalance?.accountType == EPaymentPlanType.Trial) return $localize`Trial`;
		if (this.userBalance?.accountType == EPaymentPlanType.SharedAccount)
			return this.isUserAnOrganizationAdmin ? $localize`Organization Admin` : $localize`Organization Member`;
	}

	get wordsTooltipTxt() {
		try {
			if (this._decimalPipe && this.userBalance) {
				return $localize`${this._decimalPipe.transform(this.userBalance?.current)} Credits`;
			}
			return null;
		} catch {
			return null;
		}
	}

	goToBilling() {
		if (this.app == ECopyleaksAPP.Identity) {
			this._router.navigate(['/billing/']);
		} else if (this.app == ECopyleaksAPP.Admin) {
			this._router.navigate(['/billing']);
		} else {
			var url = environment.idUrl + '/billing/' + this.getProductType();
			this.externalLinkTriggered.emit(url);
		}
	}

	upgradePricingPlan(e: Event) {
		this._urlLocationSvc.location.href = `${environment.mainWebsiteUrl}/pricing`;
		// e.stopPropagation();

		// if (this._isPaymentDialogOpen) {
		// 	return;
		// }

		// this._isPaymentDialogOpen = true;
		// const dialogRef = this._dialogSvc.open(NewPaymentDialogComponent, {
		// 	autoFocus: false,
		// 	direction: this.dir.value,
		// 	panelClass: ['mat-dialog-custom-payment-dialog'],
		// 	data: {
		// 		plansDialogData: {
		// 			features: this.paymentDialog?.features,
		// 			isYearly: true,
		// 		},
		// 		dialogId: 'navbar-dashboard-upgrade',
		// 		title: $localize`Upgrade`,
		// 	} as INewPaymentDialogComponentData,
		// });

		// dialogRef
		// 	.afterClosed()
		// 	.pipe(take(1))
		// 	.subscribe(
		// 		_ => (this._isPaymentDialogOpen = false),
		// 		_ => (this._isPaymentDialogOpen = false)
		// 	);
	}

	enableAutoRefill() {
		const dialog = this._dialogSvc.open(IdentityDialogComponent, {
			autoFocus: false,
			direction: this.dir.value,
			minWidth: '350px',
			data: {
				budget: 1000,
				title: $localize`Choose Refill Limit`,
				component: RefillsBudgetSelectComponent,
				hideCancelButton: true,
				btnSubmitText: $localize`Save`,
				onSubmit: async data => {
					await this._subSvc.changeSubscriptionRefills(EWalletType.Platform, data.budget);
					await this._paymentPlanSvc.updateUserBalance();
					dialog.close();
				},
				onCancel: async () => {
					dialog.close();
				},
			} as IRefillsBudgetSelectDialogComponentData,
		});
	}

	async reactiveAccount() {
		if (this.userBalance?.accountType == this.ePaymentPlanType.Subscription) {
			const dialog = this._dialogSvc.open(UnPauseSubscriptionDialogComponent, {
				autoFocus: false,
				width: '481px',
				panelClass: 'custom-dialog',
				direction: this.dir.value,
				data: {
					title: $localize`Ready to pick up where you left off?`,
					description: $localize`Reactivate your account today and get access to additional features and full reporting.`,
					btnSubmitText: $localize`Reactivate account`,
				} as IUnPauseSubscriptionDialogDialogModel,
			});
		}
	}
}
