<div
	class="cls-banner-container"
	fxLayout="row"
	fxLayoutGap="10px"
	fxLayoutAlign="start center"
	[ngClass]="{
		warning: bannerType == ebannerType.Warning,
		error: bannerType == ebannerType.Error
	}">
	<ng-content select="cls-page-banner-icon"></ng-content>
	<ng-content select="cls-page-banner-description"></ng-content>
</div>
