<ng-container *ngIf="currentStep === eUserRegisterSteps.EmailEntryStep">
	<app-user-register-email-entry
		(registrationSuccess)="onRegistrationSuccess($event)"
		[dialogData]="dialogData"></app-user-register-email-entry>
</ng-container>
<!--  -->
<ng-container *ngIf="currentStep === eUserRegisterSteps.CodeVerificationStep">
	<app-verify-email-by-code
		(verificationProcessComplete)="onVerificationProcessComplete($event)"
		[email]="dialogData?.email"
		(back)="currentStep = eUserRegisterSteps.EmailEntryStep"></app-verify-email-by-code>
</ng-container>
<!--  -->
<ng-container *ngIf="currentStep === eUserRegisterSteps.PersonalInformationEntryStep">
	<app-personal-information-entry
		(finish)="onFinishPersonalInformation($event)"
		[userInfo]="dialogData?.userInfo"
		[activateToken]="confirmAccountRes?.token"
		[email]="dialogData?.email"></app-personal-information-entry>
</ng-container>
