import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'cls-credit-card-loader',
	templateUrl: './credit-card-loader.component.html',
	styleUrls: ['./credit-card-loader.component.scss'],
})
export class CreditCardLoaderComponent implements OnInit {
	skeletonTheme = {
		'height.px': 60.8,
	};
	constructor() {}

	ngOnInit(): void {}
}
