import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CalloutModule } from '../../components/callout/callout.module';
import { CarouselModule } from '../../components/carousel/carousel.module';
import { ChoosePaymentMethodModule } from '../../components/choose-payment-method/choose-payment-method.module';
import { ConfirmPaymentBtnModule } from '../../components/confirm-payment-btn/confirm-payment-btn.module';
import { PaymentProccessModule } from '../../components/payment-proccess/payment-proccess.module';
import { PlansModule } from '../../components/plans/plans.module';
import { SpinnerButtonModule } from '../../components/spinner-button/spinner-button.module';
import { SpinnerModule } from '../../components/spinner/spinner.module';
import { NumeralPipeModule } from '../../pipes/numeral/numeral-pipe.module';
import { ConfettiService } from '../../services/confetti.service';
import { PaymentsService } from '../../services/payments.service';
import { PricingService } from '../../services/pricing-plans.service';
import { UserLocationService } from '../../services/user-location.service';
import { PaymentMethodsDialogComponent } from './components/payment-methods-dialog/payment-methods-dialog.component';
import { PaymentPlanDialogComponent } from './components/payment-plan-dialog/payment-plan-dialog.component';
import { PaymentSelectedPlanDialogComponent } from './components/payment-selected-plan-dialog/payment-selected-plan-dialog.component';
import { PaymentSummaryDialogComponent } from './components/payment-summary-dialog/payment-summary-dialog.component';
import { PaymentDialogComponent } from './payment-dialog/payment-dialog.component';
import { PaymentDialogService } from './services/payment-dialog.service';
import { FloatDecimalPipeModule } from '../../pipes/float-decimal/float-decimal.module';

@NgModule({
	declarations: [
		PaymentDialogComponent,
		PaymentPlanDialogComponent,
		PaymentMethodsDialogComponent,
		PaymentSummaryDialogComponent,
		PaymentSelectedPlanDialogComponent,
	],
	exports: [PaymentDialogComponent],
	entryComponents: [PaymentDialogComponent],
	imports: [
		FormsModule,
		CommonModule,
		FlexLayoutModule,
		TranslateModule,
		RouterModule,
		CarouselModule,
		ReactiveFormsModule,
		FloatDecimalPipeModule,

		CalloutModule,
		SpinnerButtonModule,
		SpinnerModule,
		MatButtonModule,
		MatDialogModule,
		MatRadioModule,
		MatIconModule,
		MatTooltipModule,
		MatProgressSpinnerModule,
		NumeralPipeModule,
		MatDividerModule,
		ChoosePaymentMethodModule,
		ConfirmPaymentBtnModule,
		PaymentProccessModule,

		PlansModule,
	],
	providers: [PaymentDialogService, ConfettiService, PaymentsService, PricingService, UserLocationService],
})
export class PaymentDialogModule {
	constructor() {}
}
