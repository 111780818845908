import { Component, Input, OnInit } from '@angular/core';
import { IsActiveMatchOptions, Router } from '@angular/router';
import { ScreenService } from '../../../../services/screen.service';
import { EDashboardSideNavMode, NewDashboardNavbarV2Service } from '../../services/new-dashboard-navbar-v2.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'cls-dashboard-link-item-v2',
	templateUrl: './dashboard-link-item-v2.component.html',
	styleUrls: ['./dashboard-link-item-v2.component.scss'],
})
export class DashboardLinkItemV2Component implements OnInit {
	@Input() link = '';
	@Input() activeMatchExactRoute = false;
	@Input() disableLinkItem = false;
	@Input() iconAsImgSrc: string;
	@Input() activeIconAsImgSrc: string;
	@Input() customTooltip: string;
	@Input() overrideHighlight = false;

	sideNavMode: EDashboardSideNavMode = EDashboardSideNavMode.shrinked;

	get isMobile() {
		return this._screenSvc?.isMobile();
	}

	get isShown() {
		if (this.isMobile && !this.matDrawer?.opened) {
			return this._router.isActive(this.link, this.activeMatchExactRoute);
		}
		return true;
	}

	get isActiveTitleView() {
		return this.isMobile && !this.matDrawer?.opened;
	}

	get matDrawer() {
		return this._newDashboardNavbarSvc?.MatDrawer;
	}

	get isSideNavShrinked() {
		return !this.isMobile && this.sideNavMode == EDashboardSideNavMode.shrinked;
	}

	/** @returns IsActiveMatchOptions object according to the activeMatchExactRoute input value (boolean value is deprecated for isActive function)*/
	get isActiveMatchOptions(): IsActiveMatchOptions {
		if (this.activeMatchExactRoute)
			return { paths: 'exact', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored' };
		return {
			paths: 'subset',
			queryParams: 'subset',
			fragment: 'ignored',
			matrixParams: 'ignored',
		};
	}

	/** @returns the img path acording if the this link is selected or not*/
	get linkImgSrc() {
		if (this.iconAsImgSrc) {
			// if the link is active then return the img path that is given for active state
			if (this._router.isActive(this.link, this.isActiveMatchOptions))
				return this.activeIconAsImgSrc ?? this.iconAsImgSrc;
			// else return the link for non active link state
			return this.iconAsImgSrc;
		}
		return null;
	}

	constructor(
		private _newDashboardNavbarSvc: NewDashboardNavbarV2Service,
		private _screenSvc: ScreenService,
		private _router: Router
	) {}

	ngOnInit(): void {
		this._newDashboardNavbarSvc.sideNavMode$.pipe(untilDestroyed(this)).subscribe(mode => {
			this.sideNavMode = mode;
		});
	}

	toggleSideNav() {
		if (this.isMobile && this.matDrawer?.opened) {
			this._newDashboardNavbarSvc.closeDrawer();
		}
	}
}

@Component({
	selector: 'cls-dashboard-link-item-icon-v2',
	template: '<ng-content></ng-content>',
	styleUrls: ['./dashboard-link-item-v2.component.scss'],
})
export class DashboardLinkItemIconV2Component {}

@Component({
	selector: 'cls-dashboard-link-item-text-v2',
	template: '<ng-content></ng-content>',
	styleUrls: ['./dashboard-link-item-v2.component.scss'],
})
export class DashboardLinkItemTextV2Component {}

@Component({
	selector: 'cls-dashboard-link-shrinked-item-text-v2',
	template: '<ng-content></ng-content>',
	styleUrls: ['./dashboard-link-item-v2.component.scss'],
})
export class DashboardLinkShrinkedItemTextV2Component {}
