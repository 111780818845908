<cls-payment-methods
	(dataInit)="dataInitFunc(EDataInitTypes.PaymentMethods)"
	(selectChange)="onPaymentMethodSelectChange($event)"
	[disabledForm]="formSubmitted"
	[billingStatus]="billingStatus"
	[showCheckMarkCircle]="showCheckMarkCircle"
	[newDesign]="newDesign"
	[selectedMethodWithBorder]="selectedMethodWithBorder"
	[style.display]="selectedPaymentMethodId !== STRIPE_NEW_CARD_ID ? 'block' : 'none'"
	class="payment-method-comp"
	*ngIf="havePaymentMethod && !showJustNewCreditCard"></cls-payment-methods>
<!--  -->
<div
	[style.display]="selectedPaymentMethodId !== STRIPE_NEW_CARD_ID && !newDesign ? 'none' : 'flex'"
	*ngIf="showStripComponents && (!newDesign || selectedPaymentMethodId === STRIPE_NEW_CARD_ID)"
	[@fadin]
	#creditCardDivId
	class="credit-card-col-online">
	<cls-credit-card
		[formControl]="creditCardCtrl"
		[newDesign]="newDesign"
		[disabledForm]="formSubmitted"
		[formSubmitted]="formSubmitted"
		[showTitle]="showTitle"
		#creditCardRef
		(dataInit)="dataInitFunc(EDataInitTypes.CreditCard)"
		[havePaymentMethod]="havePaymentMethod"></cls-credit-card>
</div>
