<div class="mobile-bottom-container">
	<mat-toolbar color="#fbffff" class="toolbar">
		<mat-toolbar-row class="toolbar-row">
			<!-- APP Platform : start -->
			<div
				*ngIf="app === eCopyleaksAPP.MainWebsite"
				class="item-container"
				fxLayot="row"
				fxLayoutAlign="space-between center">
				<cls-mobile-bottom-item [link]="'/' + dashboardMainPageRoute + '/account/shared-with-me'">
					<cls-mobile-bottom-item-icon>folder_shared</cls-mobile-bottom-item-icon>
					<cls-mobile-bottom-item-text i18n>Shared</cls-mobile-bottom-item-text>
				</cls-mobile-bottom-item>
				<cls-mobile-bottom-item [link]="'/' + dashboardMainPageRoute + '/account/scans'">
					<cls-mobile-bottom-item-icon>folder_special</cls-mobile-bottom-item-icon>
					<cls-mobile-bottom-item-text i18n>Scans</cls-mobile-bottom-item-text>
				</cls-mobile-bottom-item>
				<cls-mobile-bottom-item
					[link]="'/' + dashboardMainPageRoute + '/account/new-scan'"
					[activeMatchExactRoute]="true">
					<cls-mobile-bottom-item-icon>add_circle_outline</cls-mobile-bottom-item-icon>
					<cls-mobile-bottom-item-text i18n>New Scan</cls-mobile-bottom-item-text>
				</cls-mobile-bottom-item>
				<cls-mobile-bottom-notification-item></cls-mobile-bottom-notification-item>
			</div>
			<!-- APP Platform : end -->
			<!--  -->
			<!-- ADMIN Platform : start -->
			<div
				*ngIf="app === eCopyleaksAPP.Admin"
				class="item-container"
				fxLayot="row"
				fxLayoutAlign="space-between center">
				<cls-mobile-bottom-item [link]="'/members'">
					<cls-mobile-bottom-item-icon>group</cls-mobile-bottom-item-icon>
					<cls-mobile-bottom-item-text i18n>Members</cls-mobile-bottom-item-text>
				</cls-mobile-bottom-item>
				<cls-mobile-bottom-item
					[link]="'/repositories'"
					iconAsImgSrc="assets/icons/repositories-icon.svg"
					activeIconAsImgSrc="assets/icons/repositories-icon-active.svg">
					<cls-mobile-bottom-item-text i18n>Repo’</cls-mobile-bottom-item-text>
				</cls-mobile-bottom-item>
				<cls-mobile-bottom-item [link]="'/policies'">
					<cls-mobile-bottom-item-icon>policy</cls-mobile-bottom-item-icon>
					<cls-mobile-bottom-item-text i18n>Policies</cls-mobile-bottom-item-text>
				</cls-mobile-bottom-item>
				<cls-mobile-bottom-item [link]="'/organization/details'">
					<cls-mobile-bottom-item-icon>corporate_fare</cls-mobile-bottom-item-icon>
					<cls-mobile-bottom-item-text i18n>Org</cls-mobile-bottom-item-text>
				</cls-mobile-bottom-item>
				<!-- [disableLinkItem]="isEuUser"  -->
				<cls-mobile-bottom-item
					iconAsImgSrc="assets/icons/ai-grc-icon.svg"
					activeIconAsImgSrc="assets/icons/ai-grc-icon-selected.svg"
					[link]="'/ai-grc'">
					<cls-mobile-bottom-item-text i18n>Gen AI</cls-mobile-bottom-item-text>
				</cls-mobile-bottom-item>
				<cls-mobile-bottom-notification-item></cls-mobile-bottom-notification-item>
			</div>
			<!-- ADMIN Platform : end -->
			<!--  -->
			<!-- LMS Platform : start -->
			<!-- <div *ngIf="app === eCopyleaksAPP.LMS" class="item-container" fxLayot="row" fxLayoutAlign="space-between center">
				<cls-mobile-bottom-item [link]="'#'" [activeMatchExactRoute]="true">
					<cls-mobile-bottom-item-icon>add_circle_outline</cls-mobile-bottom-item-icon>
					<cls-mobile-bottom-item-text>Add New</cls-mobile-bottom-item-text>
				</cls-mobile-bottom-item>
				<cls-mobile-bottom-item [link]="'#'">
					<cls-mobile-bottom-item-icon>extension</cls-mobile-bottom-item-icon>
					<cls-mobile-bottom-item-text>Integrations</cls-mobile-bottom-item-text>
				</cls-mobile-bottom-item>
				<cls-mobile-bottom-notification-item></cls-mobile-bottom-notification-item>
			</div> -->
			<!-- LMS Platform : end -->
			<!--  -->
			<!-- API Platform : start -->
			<div *ngIf="app === eCopyleaksAPP.API" class="item-container" fxLayot="row" fxLayoutAlign="space-between center">
				<cls-mobile-bottom-item [link]="'/' + apiUrl + '/scans'" [activeMatchExactRoute]="true">
					<cls-mobile-bottom-item-icon>folder_special</cls-mobile-bottom-item-icon>
					<cls-mobile-bottom-item-text i18n>Scans</cls-mobile-bottom-item-text>
				</cls-mobile-bottom-item>
				<cls-mobile-bottom-item [link]="'/' + documentationRoute">
					<cls-mobile-bottom-item-icon>integration_instructions</cls-mobile-bottom-item-icon>
					<cls-mobile-bottom-item-text i18n>Docs</cls-mobile-bottom-item-text>
				</cls-mobile-bottom-item>
				<cls-mobile-bottom-item [link]="'/' + documentationRoute + '/sdks'">
					<cls-mobile-bottom-item-icon>code</cls-mobile-bottom-item-icon>
					<cls-mobile-bottom-item-text i18n>SDKs</cls-mobile-bottom-item-text>
				</cls-mobile-bottom-item>
				<cls-mobile-bottom-item [link]="'/' + apiUrl + '/quickstart-guide'">
					<cls-mobile-bottom-item-icon>bolt</cls-mobile-bottom-item-icon>
					<cls-mobile-bottom-item-text i18n>Quickstart</cls-mobile-bottom-item-text>
				</cls-mobile-bottom-item>
				<cls-mobile-bottom-item [link]="'/' + apiUrl" [activeMatchExactRoute]="true">
					<cls-mobile-bottom-item-icon>dashboard</cls-mobile-bottom-item-icon>
					<cls-mobile-bottom-item-text i18n>Dashboard</cls-mobile-bottom-item-text>
				</cls-mobile-bottom-item>
				<cls-mobile-bottom-notification-item></cls-mobile-bottom-notification-item>
			</div>
			<!-- API Platform : end -->
			<!--  -->
			<!-- ID Platform : start -->
			<div
				*ngIf="app === eCopyleaksAPP.Identity"
				class="item-container"
				fxLayot="row"
				fxLayoutAlign="space-between center">
				<cls-mobile-bottom-item [link]="'/personal-information'">
					<cls-mobile-bottom-item-icon>person</cls-mobile-bottom-item-icon>
					<cls-mobile-bottom-item-text i18n>Account</cls-mobile-bottom-item-text>
				</cls-mobile-bottom-item>
				<cls-mobile-bottom-item [link]="'/security'" [activeMatchExactRoute]="true">
					<cls-mobile-bottom-item-icon>lock</cls-mobile-bottom-item-icon>
					<cls-mobile-bottom-item-text i18n>Security</cls-mobile-bottom-item-text>
				</cls-mobile-bottom-item>
				<cls-mobile-bottom-item [link]="'/security/audit-logs'">
					<cls-mobile-bottom-item-icon>list_alt</cls-mobile-bottom-item-icon>
					<cls-mobile-bottom-item-text i18n>Logs</cls-mobile-bottom-item-text>
				</cls-mobile-bottom-item>
				<cls-mobile-bottom-item [link]="'/billing'">
					<cls-mobile-bottom-item-icon>request_quote</cls-mobile-bottom-item-icon>
					<cls-mobile-bottom-item-text i18n>Billing</cls-mobile-bottom-item-text>
				</cls-mobile-bottom-item>
				<cls-mobile-bottom-notification-item></cls-mobile-bottom-notification-item>
			</div>
			<!-- ID Platform : end -->
		</mat-toolbar-row>
	</mat-toolbar>
</div>
