import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { IAccountBillingAddressValue } from '../components/billing-address/models/billing-address.models';
import { EWalletType } from '../enums/wallet-type.enum';
import { httpFailRetry } from '../observable/http-fail-retry.pipes';
import { AuthService } from './auth.service';
import { DevicesService } from './devices.service';
import { WindowService } from './window.service';

@Injectable({
	providedIn: 'root',
})
export class BillingService {
	private _cursors = new Map<number, string>();
	private _details$ = new BehaviorSubject<IAccountBillingDetailsValue>(null);

	public get details$() {
		return this._details$;
	}

	constructor(
		private _http: HttpClient,
		private _windowSvc: WindowService,
		private _devicesSvc: DevicesService,
		private _authSvc: AuthService
	) {}

	async getInvoices(payload: IGetInvoicesPayload, product: EWalletType) {
		let queryParams: string;
		const cursor = this._cursors.get(payload.offset - payload.limit);
		if ((payload.offset || payload.offset === 0) && (payload.limit || payload.limit === 0)) {
			queryParams = `?${cursor ? `Cursor=${cursor}&` : ''}Limit=${payload.limit}`;
		}

		return await this._http
			.get<IInvoicesListResult>(`${environment.apiUrl}/v1/account/${product}/billings${queryParams ? queryParams : ''}`)
			.pipe(
				httpFailRetry(),
				map((data: IInvoicesListResult) => {
					this._cursors.set(payload.offset, data.cursor);
					data.invoices = data.invoices.map(invoice => {
						return {
							...invoice,
							creation: new Date(invoice.creation),
						};
					});
					return data;
				})
			)
			.toPromise();
	}

	async downloadInvoice(invoiceId: string, name: string) {
		try {
			const fileUrl = `${environment.apiUrl}/v1/account/billings/download/${invoiceId}`;
			const data = await this._http.get(fileUrl, { responseType: 'arraybuffer' }).pipe(httpFailRetry()).toPromise();
			this._downloadPdfFileWithoutNavigation(data, name);
		} catch (error) {
			throw error;
		}
	}

	async updateBillingDetails(model: IAccountBillingDetailsValue) {
		const url = `${environment.apiUrl}/v1/account/billing/details`;
		await this._http.put(url, model.billingAddress).pipe(httpFailRetry()).toPromise();
		let user = this._authSvc.user();
		if (user) {
			user.countryCode = model.billingAddress.countryCode;
			user.company = model.companyName;
			this._authSvc.updateUserData(user, false);
		}
	}

	async getBillingDetails() {
		const url = `${environment.apiUrl}/v1/account/billing/details`;
		try {
			const res = await this._http.get<IAccountBillingDetailsValue>(url).pipe(httpFailRetry()).toPromise();
			const user = this._authSvc.user();
			if (user) {
				res.companyName = user.company;
				res.billingAddress.countryCode = user.countryCode;
			}
			this.details$.next(res);
			return res;
		} catch (error) {
			throw error;
		}
	}

	private _downloadPdfFileWithoutNavigation(dataToDownload: any, fileName: string) {
		const blob = new Blob([dataToDownload], { type: 'application/pdf' });
		const url = window?.URL.createObjectURL(blob);
		const a = document.createElement('a');
		document.body.appendChild(a);
		a.setAttribute('style', 'display: none');
		a.href = url;
		a.download = fileName;
		a.click();
		window?.URL.revokeObjectURL(url);
		a.remove(); // remove the element
	}
}

export interface IGetInvoicesPayload {
	offset: number;
	limit: number;
}

export interface IInvoicesListResult {
	invoices: IInvoice[];
	cursor?: string;
}

export interface IInvoice {
	creation: Date;
	id: string;
	amount: number;
	description: string;
}

export interface IAccountBillingDetailsValue {
	billingAddress: IAccountBillingAddressValue;
	companyName?: string;
}
