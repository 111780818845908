import { IAccountBillingAddressValue } from '../components/billing-address/models/billing-address.models';

export class PricingPlan {
	id: string;
	credits: number;
	price: number;
	platformProduct?: EPlatformProductType;
	interval: {
		months: number;
		days: number;
	};
}
export class PricingOffer {
	planId: string;
	coupon?: string;
	billingAddress: IAccountBillingAddressValue;
	code?: string; // obsolete
}
export class PricingOfferResult {
	itemsPrice: number;
	couponDiscount: number;
	vat: number;
	totalPrice: number;
}
export class PricingRecommendation {
	amount: number;
	price: number;
	pricePlusTax: number;
	planId: string;
	shouldPayTax: boolean;
}
export class PlansPricingResult {
	plans: PricingPlanResult[];
}

export class SupportPlansPricingResult {
	plans: SupportPricingPlanItemResultItem[];
}

export class IPlatformProductsPlans {
	products: IProductPlans[];
}
export class IProductPlans {
	name: string;
	platformProductType: EPlatformProductType;
	plans: PricingPlanResult[];
}
export class PricingPlanResult {
	credits: number;
	monthly: PricingPlanItemResultItem;
	yearly: PricingPlanItemResultItem;
	highAvailability?: boolean;
}

export class PricingPlanItemResultItem {
	planId: string;
	credits: number;
	price: number;
	totalPrice: number;
	totalPriceAfterDiscount: number;
	vat: number;
}

export class SupportPricingPlanItemResultItem {
	planId: string;
	price: number;
	totalPrice: number;
	totalPriceAfterDiscount: number;
	vat: number;
}

export enum EPaymentServiceSteps {
	UpdatePaymentMethod,
	Processing,
	GettingCredits,
	Completed,
	CreatingRepository,
	UpdatingRepository,
}

export enum EPlatformProductType {
	AIDetector = 1,
	PlagiarismChecker = 2,
	Bundled = 3,
}
