import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { ClsAlertsService } from '@ngx-common-v2/components/cls-alerts/services/cls-alerts.service';
import { loadStripe } from '@stripe/stripe-js';
import { BehaviorSubject } from 'rxjs';
import { IPaymentMethod, PaymentMethodsService } from '../../../components/payment-methods/payment-methods.service';
import { EProductType } from '../../../enums/product-type.enums';
import { EPlatformProductType, IPlatformProductsPlans, PricingPlanResult } from '../../../models/pricing.model';
import { PaymentsService } from '../../../services/payments.service';
import { PricingService } from '../../../services/pricing-plans.service';

@Injectable()
export class PaymentDialogService {
	private _paymentDialogSvcData$ = new BehaviorSubject<IPaymentDialogServiceData[]>([
		{
			oldMethod: null,
			platformProductsPlans: null,
			requiredPlan: null,
			isPaymentActive: null,
			id: null,
		},
	]);

	get paymentDialogSvcData$() {
		return this._paymentDialogSvcData$;
	}

	constructor(
		private _paymentsSvc: PaymentsService,
		private _paymentMethodSvc: PaymentMethodsService,
		private _pricingSvc: PricingService,
		private _clsAlertsSvc: ClsAlertsService
	) {}

	public async isPaymentActive() {
		const res = await this._paymentsSvc.isPaymentActive();
		this._paymentDialogSvcData$.next([
			...this._paymentDialogSvcData$.value.map(i => ({
				...i,
				isPaymentActive: res.isPaymentActive,
			})),
		]);
	}

	async initOldPaymentMethods() {
		try {
			const oldPaymentMethod = await this._paymentMethodSvc.getDefaultPaymentMethod().toPromise();
			this._paymentDialogSvcData$.next([
				...this._paymentDialogSvcData$.value.map(i => ({
					...i,
					oldMethod: oldPaymentMethod,
				})),
			]);
		} catch (ex) {
			this._clsAlertsSvc.showHttpResponseError(ex);
		}
	}

	async initPaymentPlan(
		minCredits = 100,
		dialogId: string = null,
		platformProduct = EPlatformProductType.Bundled,
		productType?: EProductType,
		showAllPlan = false,
		discountCode?: string
	) {
		await loadStripe(environment.stripKey);
		const plansResult = await this._pricingSvc.getProductsPlans(discountCode, productType);
		let planData = this._paymentDialogSvcData$.value.find(i => i.id === dialogId);

		if (!planData) {
			const defaultPlan = this._paymentDialogSvcData$.value.find(i => i.id === null);
			planData = {
				oldMethod: defaultPlan.oldMethod,
				requiredPlan: null,
				platformProductsPlans: null,
				isPaymentActive: defaultPlan.isPaymentActive,
				id: dialogId,
				discountCode,
			};
		}

		let requiredPlan: PricingPlanResult;
		plansResult.products.forEach(product => {
			if (product.platformProductType == platformProduct) {
				requiredPlan = product.plans.find(plan => plan.credits == minCredits);
			}
		});
		this._paymentDialogSvcData$.next([
			...this._paymentDialogSvcData$.value.filter(i => i.id !== dialogId),
			{
				...planData,
				platformProductsPlans: plansResult,
				requiredPlan,
				discountCode,
			},
		]);
	}

	getSelectedProduct(allProductsPaymentPlans: IPlatformProductsPlans, planId: string) {
		var selectedProduct: EPlatformProductType;
		for (let i = 0; i < allProductsPaymentPlans.products.length; i++) {
			var currentPlan = allProductsPaymentPlans.products[i].plans.find(
				plan => plan.monthly.planId === planId || plan.yearly.planId === planId
			);
			if (currentPlan) {
				selectedProduct = allProductsPaymentPlans.products[i].platformProductType;
			}
		}
		if (selectedProduct) return selectedProduct;
		return EPlatformProductType.Bundled;
	}

	changedRequiredPlan(plan: PricingPlanResult, dialogId: string) {
		const data = this._paymentDialogSvcData$.value.find(i => i.id === dialogId);
		this._paymentDialogSvcData$.next([
			...this._paymentDialogSvcData$.value.filter(i => i.id !== dialogId),
			{
				id: data.id,
				isPaymentActive: data.isPaymentActive,
				oldMethod: data.oldMethod,
				platformProductsPlans: data.platformProductsPlans,
				requiredPlan: plan,
				discountCode: data.discountCode,
			},
		]);
	}
}

export interface IPaymentDialogServiceData {
	oldMethod: IPaymentMethod;
	platformProductsPlans: IPlatformProductsPlans;
	requiredPlan: PricingPlanResult;
	isPaymentActive: boolean;
	id: string;
	discountCode?: string;
}

export interface IPaymentDialogDiscountManagement {
	code: string;
	id: string;
	plan: {
		monthly: {
			id: number;
			priceBeforeDiscount: number;
			priceAfterDiscount: number;
		};
		yearly: {
			id: number;
			priceBeforeDiscount: number;
			priceAfterDiscount: number;
		};
	};
}
