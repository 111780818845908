export enum EWalletType {
	Undefined = 0,
	App = 2,
	Api = 3,
	Repository = 7,
	Support = 8,
	Organization = 9,
	Platform = 10,
	LMS = 11,
}
