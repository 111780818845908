<div class="custom-snackbar-wrapper">
	<div class="message-icon-wrapper">
		<mat-icon>
			<ng-container [ngSwitch]="data.type">
				<ng-container *ngSwitchCase="eSnackbarType.Success">check_circle</ng-container>
				<ng-container *ngSwitchCase="eSnackbarType.Info">info</ng-container>
				<ng-container *ngSwitchCase="eSnackbarType.Warn">warning</ng-container>
				<ng-container *ngSwitchCase="eSnackbarType.Error">error</ng-container>
				<ng-container *ngSwitchCase="eSnackbarType.VersionUpdate">sync</ng-container>
				<ng-container *ngSwitchDefault>error</ng-container>
			</ng-container>
		</mat-icon>
	</div>
	<div class="message-wrapper" [innerHTML]="data.message | safe: 'html'"></div>
	<div *ngIf="data.type != eSnackbarType.VersionUpdate">
		<button
			aria-label="action"
			*ngIf="data.actionName"
			(click)="actionClick()"
			class="snackbar-button mat-simple-snackbar-action">
			{{ data.actionName }}
		</button>
		<button (click)="close()" *ngIf="!data.actionName" class="snackbar-button icon-only" title="Close">
			<!-- <span class="button-icon">X</span> -->
			<mat-icon>close</mat-icon>
		</button>
	</div>
</div>

<!-- version update -->
<div *ngIf="data.type == eSnackbarType.VersionUpdate && data.actionName" class="bottom-actions">
	<button aria-label="action" (click)="actionClick()" class="snackbar-button mat-simple-snackbar-action">
		{{ data.actionName }}
	</button>
</div>
