import { Component, EventEmitter, Inject, Input, LOCALE_ID, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '@environment';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ClsAlertsService } from '@ngx-common-v2/components/cls-alerts/services/cls-alerts.service';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NAME_VALIDATION_REGEX } from '../../constants';
import { ECookiesKeys } from '../../enums/cookies-keys.enum';
import { ActivateAccountRes } from '../../models/auth.model';
import { IUserDetailsFormData } from '../../models/user.models';
import { ICountry, IUserProfileTypeOption, MiscService } from '../../services/misc.service';
import { EActivateOption, UserService } from '../../services/user.service';
import { WindowService } from '../../services/window.service';

export enum EUserDetailsFormMode {
	Informations = 1,
	Activations = 2,
	AdditionInfo = 3,
}
@UntilDestroy()
@Component({
	selector: 'app-user-details-form',
	templateUrl: './user-details-form.component.html',
	styleUrls: ['./user-details-form.component.scss'],
})
export class UserDetailsFormComponent implements OnInit, OnDestroy {
	@Input() showSpinner = false;
	@Input() options: IUserDetailsFormComponentOptions;
	@Input() activateToken: string;
	@Input() activateOption: EActivateOption;
	@Input() email: string;
	@Input() width: string;
	@Output() finish = new EventEmitter<ActivateAccountRes>();

	eActivateOption = EActivateOption;
	frmUserDetails: FormGroup;
	formSubmitted = false;
	formMode: EUserDetailsFormMode = EUserDetailsFormMode.Informations;
	eUserDetailsFormMode: typeof EUserDetailsFormMode = EUserDetailsFormMode;
	regex_validator: string = NAME_VALIDATION_REGEX;

	profileTypesOptions: IUserProfileTypeOption[] = [];

	private _unsub = new Subject();
	countries: ICountry[] = [];

	constructor(
		private _formBuilder: FormBuilder,
		private _clsAlertsSvc: ClsAlertsService,
		private _cookieSvc: CookieService,
		private _miscSvc: MiscService,
		private _userSvc: UserService,
		private _windowSvc: WindowService,
		@Inject(LOCALE_ID) private localeId: string
	) {}

	ngOnInit() {
		this._initProfilesTypes();
		this._initCountries();

		const { options } = this;

		if (options && options.FormMode) {
			this.formMode = options.FormMode;
		}

		this._initForm();
		this._disableUserTypeField();
	}

	private _disableUserTypeField() {
		// tslint:disable-next-line: triple-equals
		if (this.activateOption && this.activateOption == this.eActivateOption.Group) {
			this.frmUserDetails?.get('userType').disable();
		}
	}

	private _initForm() {
		const { options } = this;
		const controlsConfig: { [key: string]: any } = {
			firstName: [
				options && options.initFormData && options.initFormData.firstName ? options.initFormData.firstName : '',
				Validators.required,
			],
			lastName: [
				options && options.initFormData && options.initFormData.lastName ? options.initFormData.lastName : '',
				Validators.required,
			],
			companyName: [
				options && options.initFormData && options.initFormData.company ? options.initFormData.company : '',
			],
			email: [options && options.initFormData && options.initFormData.email ? options.initFormData.email : ''],
			profileType: [
				options && options.initFormData && options.initFormData.profileType ? options.initFormData.profileType : '',
				Validators.required,
			],
			marketingEmails: [
				options &&
				options.initFormData &&
				(options.initFormData.marketingEmails || options.initFormData.marketingEmails === false)
					? options.initFormData.marketingEmails
					: false,
				Validators.required,
			],
		};

		if (this.formMode === EUserDetailsFormMode.Informations) {
			controlsConfig.country = [
				options && options.initFormData && options.initFormData.country ? options.initFormData.country : 'OTR',
				Validators.required,
			];
		} else {
			controlsConfig.agreeToTermOfUse = [false, Validators.required];
		}

		this.frmUserDetails = this._formBuilder.group(controlsConfig);

		this.frmUserDetails
			?.get('userType')
			.valueChanges.pipe(takeUntil(this._unsub))
			.subscribe(() => {
				this.frmUserDetails?.get('profileType').setValue(null);
			});
	}

	private async _initProfilesTypes() {
		try {
			this.profileTypesOptions = await this._miscSvc.getProfileTypes();
		} catch (error) {
			console.error(error);
		}
	}
	private async _initCountries() {
		try {
			this.countries = await this._miscSvc.GetCountries();
		} catch (error) {
			console.error(error);
		}
	}
	async submit() {
		try {
			this.formSubmitted = true;

			if (
				this.frmUserDetails.invalid ||
				(this.formMode === EUserDetailsFormMode.Activations && !this.getCtrlValue('agreeToTermOfUse')) ||
				(this.formMode === EUserDetailsFormMode.AdditionInfo && !this.getCtrlValue('agreeToTermOfUse'))
			) {
				return;
			}
			var promRefId = this._cookieSvc.get(ECookiesKeys.FPromRefId);
			var promTId = this._cookieSvc.get(ECookiesKeys.FPromTidId);
			if (!promRefId) {
				promRefId = this._cookieSvc.get(ECookiesKeys.AwinRefId);
				promTId = this._cookieSvc.get(ECookiesKeys.AwinTidId);
			}

			this.showSpinner = true;
			let res;
			const userInfo: IUserDetailsFormData = {
				profileType: this.frmUserDetails.get('profileType').value,
				firstName: this.frmUserDetails.get('firstName').value,
				lastName: this.frmUserDetails.get('lastName').value,
				company: this.frmUserDetails.get('companyName').value,
				email: this.email,
				marketingEmails: this.frmUserDetails.get('marketingEmails').value,
			};
			if (this.formMode === EUserDetailsFormMode.Activations) {
				res = await this._userSvc.activateAccountViaTokenAsync(userInfo, this.activateToken, promRefId, promTId);
			} else if (this.formMode == EUserDetailsFormMode.AdditionInfo) {
				res = await this._userSvc.submitAdditionInfoAsync(userInfo, promRefId, promTId);
			}

			this._cookieSvc.delete(ECookiesKeys.FPromRefId);
			this._cookieSvc.delete(ECookiesKeys.FPromTidId);

			this._userSvc.activateRes = res;
			this.finish.emit(res);
		} catch (err) {
			this._clsAlertsSvc.showHttpResponseError(err);
		}
	}

	getCtrlValue(ctrlName: string) {
		return this.frmUserDetails?.get(ctrlName).value;
	}

	goToTermsOfUse(event: Event) {
		const value = this.frmUserDetails?.get('agreeToTermOfUse').value;
		event.stopPropagation();
		this._windowSvc.open(`${environment.mainWebsiteUrl}/legal/termsofuse`, '_blank');

		setTimeout(() => {
			this.frmUserDetails?.get('agreeToTermOfUse').setValue(value);
		}, 1000);
	}

	goToPrivacyPolicy(event: Event) {
		const value = this.frmUserDetails?.get('agreeToTermOfUse').value;
		event.stopPropagation();
		this._windowSvc.open(`${environment.mainWebsiteUrl}/legal/privacypolicy`, '_blank');

		setTimeout(() => {
			this.frmUserDetails?.get('agreeToTermOfUse').setValue(value);
		}, 1000);
	}

	public fxFlexPersentageHandler(): string {
		if (this.localeId === 'it') {
			return '25%';
		}
		return '100%';
	}

	ngOnDestroy(): void {
		this._unsub.next(null);
		this._unsub.complete();
	}
}

export interface IUserDetailsFormComponentOptions {
	initFormData?: IUserDetailsFormData;
	FormMode?: EUserDetailsFormMode;
}
