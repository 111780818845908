import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { IPaymentMethod } from '../components/payment-methods/payment-methods.service';
import { EWalletType } from '../enums/wallet-type.enum';
import { httpFailRetry } from '../observable/http-fail-retry.pipes';
import { AuthService } from './auth.service';
import { Invoice } from './payment.service';

@Injectable()
export class PaymentsService {
	oldPaymentMethod: IPaymentMethod;

	constructor(private _http: HttpClient, private _authSvc: AuthService) {}

	async isPaymentActive() {
		try {
			return { isPaymentActive: true };

			return await this._http
				.get<{ isPaymentActive: boolean }>(`${environment.apiUrl}/v1/is-payments-active`)
				.pipe(httpFailRetry())
				.toPromise();
		} catch (error) {
			throw error;
		}
	}
}

export interface IPayWithTokenReqPayload {
	Product: EWalletType;
	IsRefill: boolean;
	Token: string;
	Quantity: number;
	ExpectedPrice: number;
	PlanId: string;
	Coupon: string;
	Code?: string;
	countryCode?: string;
}

export interface IPayWithIframeModel {
	requestedPlanDisplayName: string;
	totalPrice: number;
	planId: string;
	coupon: string;
	code?: string;
	email?: string;
	id?: string;
}

export interface IPayWithTokenResponse {
	repoId?: string;
	invoice: Invoice;
	errorMsg: string;
}
