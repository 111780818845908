import { transition, trigger, useAnimation } from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ESubscriptionBillingStatus } from '../../services/payment-plan.service';
import { fadeIn } from 'ng-animate';
import { EPaymentServiceSteps } from '../../models/pricing.model';

@UntilDestroy()
@Component({
	selector: 'cls-confirm-payment-btn',
	templateUrl: './confirm-payment-btn.component.html',
	styleUrls: ['./confirm-payment-btn.component.scss'],
	animations: [trigger('fadeIn', [transition(':enter', useAnimation(fadeIn))])],
})
export class ConfirmPaymentBtnComponent {
	@Input() showConfirmPaymentBtnSpinner = false;
	@Input() totalPrice;
	@Input() currentPaymentStep: EPaymentServiceSteps;
	@Input() newDesign: boolean;
	@Input() billingStatus: ESubscriptionBillingStatus;
	@Input() updateCreditCardMode = false;
	@Input() disabledButton: boolean = false;
	@Input() buttonId: string = '';
	eSubscriptionBillingStatus = ESubscriptionBillingStatus;

	@Output() confirmPayment = new EventEmitter<boolean>();

	EPaymentServiceSteps = EPaymentServiceSteps;
	constructor() {}

	confirmPaymentFunc() {
		if (this.showConfirmPaymentBtnSpinner && this.currentPaymentStep !== EPaymentServiceSteps.Completed) {
			return;
		}
		this.confirmPayment.emit(true);
	}
}
