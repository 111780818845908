import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MobileBottomMenuComponent } from './mobile-bottom-menu.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
	MobileBottomItemComponent,
	MobileBottomMenuIconComponent,
	MobileBottomMenuTextComponent,
} from './mobile-bottom-item/mobile-bottom-item.component';
import { MatBadgeModule } from '@angular/material/badge';
import { RouterModule } from '@angular/router';
import { MobileBottomNotificationItemComponent } from './mobile-bottom-notification-item/mobile-bottom-notification-item.component';
import { NotificationContainerModule } from '@ngx-common/notifications/components/notification-container/notification-container.module';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatListModule } from '@angular/material/list';

@NgModule({
	declarations: [
		MobileBottomMenuComponent,
		MobileBottomItemComponent,
		MobileBottomNotificationItemComponent,
		MobileBottomMenuIconComponent,
		MobileBottomMenuTextComponent,
	],
	exports: [MobileBottomMenuComponent],
	imports: [
		CommonModule,
		RouterModule,
		FlexLayoutModule,
		NotificationContainerModule,

		MatBadgeModule,
		MatListModule,
		MatTooltipModule,
		MatMenuModule,
		MatIconModule,
		MatButtonModule,
		MatToolbarModule,
	],
})
export class MobileBottomMenuModule {}
