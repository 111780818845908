import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { SpinnerModule } from '../spinner/spinner.module';
import { ConfirmPaymentBtnComponent } from './confirm-payment-btn.component';
import { FloatDecimalPipeModule } from '../../pipes/float-decimal/float-decimal.module';
import { ClsButtonModule } from '../../../ngx-common-v2/components/cls-button/cls-button.module';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
	declarations: [ConfirmPaymentBtnComponent],
	exports: [ConfirmPaymentBtnComponent],
	imports: [
		CommonModule,
		MatIconModule,
		ClsButtonModule,
		FloatDecimalPipeModule,
		FlexLayoutModule,
		MatButtonModule,
		MatDialogModule,
		SpinnerModule,
	],
})
export class ConfirmPaymentBtnModule {}
