import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResultsInsightsTemplateComponent } from './cls-results-insights-template.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ClsInfoCardModule } from '../../../cls-info-card/cls-info-card.module';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
	declarations: [ResultsInsightsTemplateComponent],
	imports: [CommonModule, FlexLayoutModule, ClsInfoCardModule, MatIconModule, MatTooltipModule],
	exports: [ResultsInsightsTemplateComponent],
})
export class ResultsInsightsTemplateModule {}
