import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ClsAlertsService } from '@ngx-common-v2/components/cls-alerts/services/cls-alerts.service';
import {
	CopyLeaksDialogComponent,
	ECopyLeaksDialogSubmitErrors,
	ICopyLeaksDialogData,
	ICopyLeaksDialogSubmittedData,
} from '../../../../../dialogs/copyleaks-dialog/copyleaks-dialog-component/copyleaks-dialog.component';
import { EAPIErrorCodes } from '../../../../../enums/error-codes.enum';
import { HttpStatusCode } from '../../../../../enums/http-status-code.enum';
import { EPaymentPlanType } from '../../../../../enums/payment-plan-type.enum';
import { IAPICustomErrors } from '../../../../../models/api-custom-errors.model';
import { UserInfo } from '../../../../../models/auth.model';
import { IUserBalance } from '../../../../../models/user-balance.models';
import { NgxCommonPagesConfig } from '../../../../../pages/pages.config';
import { PricingPageRoutings } from '../../../../../pages/pricing-page/models/pricing-routings.model';
import { AppDirectionalityService } from '../../../../../services/app-directionality.service';
import { SupportService } from '../../../../../services/support.service';
import { FeatureSuggestionComponent } from '../../feature-suggestion/feature-suggestion.component';
import { BaseToolbarMenuComponent } from '../base-toolbar-menu-componenet';

@Component({
	selector: 'cls-help-toolbar-menu',
	templateUrl: './help-toolbar-menu.component.html',
	styleUrls: ['./help-toolbar-menu.component.scss'],
})
export class HelpToolbarMenuComponent extends BaseToolbarMenuComponent implements OnInit {
	@Input() userBalance: IUserBalance;
	@Input() user: UserInfo;
	pricingPageRoutings = PricingPageRoutings;
	constructor(
		router: Router,
		private _dialogSvc: MatDialog,
		private readonly _dir: AppDirectionalityService,
		private _supportSvc: SupportService,
		private _clsAlertsSvc: ClsAlertsService,
		@Inject(NgxCommonPagesConfig.key) private _config: NgxCommonPagesConfig
	) {
		super(router);
	}
	ngOnInit() {
		this.app = this._config.APP;
	}

	suggestFeature() {
		// if (this.isMobile) {
		// 	this.sidenav?.close();
		// }
		this._dialogSvc.open(CopyLeaksDialogComponent, {
			width: '600px',
			direction: this._dir.value,
			data: {
				title: $localize`Suggest a Feature`,
				component: FeatureSuggestionComponent,
				showCaptcha: true,
				btnSubmitText: $localize`Submit`,
				onSubmit: this._submitFeatureSuggestionAsync.bind(this),
				hideCancelButton: true,
			} as ICopyLeaksDialogData,
		});
	}

	private async _submitFeatureSuggestionAsync(data: ICopyLeaksDialogSubmittedData) {
		try {
			await this._supportSvc.SuggestAFeature({
				featureTitle: data.title,
				featureDescription: data.description,
				captcha: data.captcha,
				name: `${this.user.firstName} ${this.user.lastName}`,
				company: this.user.company,
			});
			this._clsAlertsSvc.showCustomPrimary(
				$localize`Your Suggestion was sent successfully. We will contact you as soon as possible`
			);
		} catch (ex) {
			data.captchaComponent.generateCaptcha();
			const _ex: HttpErrorResponse = ex;
			if (_ex.status === HttpStatusCode.BAD_REQUEST) {
				const errorBody = _ex.error as IAPICustomErrors;
				if (errorBody && errorBody.code) {
					switch (errorBody.code) {
						case EAPIErrorCodes.CaptchaValidationError:
							throw ECopyLeaksDialogSubmitErrors.CaptchaNotValid;
					}
				}
			} else {
				if (_ex.error) {
					this._clsAlertsSvc.showHttpResponseError(_ex);
				}
			}
		}
	}

	getUserPaymentPlan(type: EPaymentPlanType): string {
		switch (type) {
			case EPaymentPlanType.Trial:
				return $localize`Trial`;
			case EPaymentPlanType.Subscription:
				// return 'Subscription';
				return '';
			case EPaymentPlanType.Prepaid:
				return $localize`Prepaid`;
			case EPaymentPlanType.SharedAccount:
				return $localize`Team Member`;
			default:
				return '';
		}
	}
}
