<div
	class="assignments-table"
	[style]="{
		'box-shadow': isLoading ? 'none' : ' -2px -2px 8px 0px #fbffff80, 2px 2px 4px 0px #00000026'
	}">
	<!-- <ng-container *ngIf="!isLoading"> -->
	<div class="table-wrapper">
		<div class="table-toolbar-container" fxLayout="row" fxLayoutAlign="space-between center">
			<div class="table-title" i18n>Assignments</div>
			<div class="table-toolbar-actions" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="15px">
				<button
					class="icon-button"
					mat-icon-button
					i18n-matTooltip
					matTooltip="Export to manage data in third-party software."
					(click)="isTableEmpty || hasNoSearchResults || isLoading ? '' : download()"
					aria-label="Exports data.">
					<mat-icon
						[ngClass]="{ 'table-filter-icon': true, 'disabled-icon': isTableEmpty || hasNoSearchResults }"
						fontSet="material-icons-round"
						[ngStyle]="platformType == ePlatformType.Moodle ? { lineHeight: '30px' } : {}">
						download
					</mat-icon>
				</button>
				<cls-search-input
					[addIconBackground]="false"
					[withLabel]="true"
					[noBottomPadding]="true"
					appearance="fill"
					[placeholder]="
						platformType == ePlatformType.Moodle && isWithPagination
							? moodleSearchInputPlaceholderTxt
							: othersSearchInputPlaceholderTxt
					"
					[msecUntilChange]="isWithPagination ? 100 : 0"
					[disabled]="isTableEmpty && !hasNoSearchResults"
					[value]="searchStr"
					(confirmChange)="onSearchConfirm($event)"></cls-search-input>
			</div>
		</div>
		<table
			mat-table
			[dataSource]="tableDataSource"
			[style]="
				!showPaginator || isLoading || hasNoSearchResults
					? 'border-bottom-left-radius:10px!important;border-bottom-right-radius:10px!important'
					: ''
			">
			<!-- Assignment Name -->
			<ng-container matColumnDef="assignment_name">
				<th mat-header-cell *matHeaderCellDef style="width: 18%">
					<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
						<span i18n>Assignment name</span>
						<button
							mat-icon-button
							class="icon-button"
							*ngIf="!isWithPagination"
							(click)="sortAllData(sortBy.AssignmentName)"
							i18n-matTooltip
							matTooltip="Sort"
							aria-label="Sort">
							<mat-icon
								fontSet="material-icons-round"
								class="table-sort-icon"
								[ngClass]="{
									'table-sort-icon-selected-desc': sortColumn === sortBy.AssignmentName && isTalbeDescending,
									'table-sort-icon-selected-asc': sortColumn === sortBy.AssignmentName && !isTalbeDescending,
									'table-sort-icon-selected-disabled': isWithPagination
								}"
								[style.color]="isTableEmpty || hasNoSearchResults ? 'gray' : ''">
								sort
							</mat-icon>
						</button>
					</div>
				</th>

				<td mat-cell *matCellDef="let element">
					<a
						*ngIf="!isLoading; else loadingSkeleton"
						[routerLink]="
							element?.contextId ? ['/lms', platformType, 'analytics', integrationId, 'activity', element?.id] : []
						"
						aria-label="opens assignment analytics">
						<div
							fxLayout="row"
							fxLayoutAlign="start center"
							fxLayoutGap="8px"
							mat-icon-button
							[class]="element?.contextId ? 'names-container-hover activity-name-text' : 'disabled-assignment'"
							[matTooltip]="element?.name">
							<mat-icon class="open-in-new" mat-cell *ngIf="element?.name?.length">open_in_new</mat-icon>
							<div class="long-text">
								{{ element?.name }}
							</div>
						</div>
					</a>
				</td>
			</ng-container>

			<!-- Course Name -->
			<ng-container matColumnDef="course_name">
				<th mat-header-cell *matHeaderCellDef>
					<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
						<span i18n>Course name</span>
					</div>
				</th>
				<td mat-cell *matCellDef="let element">
					<a
						*ngIf="!isLoading; else loadingSkeleton"
						mat-icon-button
						class="names-container-hover course-name"
						i18n-matTooltip
						[matTooltip]="element?.courseName"
						[routerLink]="
							element?.contextId ? ['/lms', platformType, 'analytics', integrationId, 'course', element?.contextId] : []
						"
						aria-label="opens course analytics">
						<mat-icon class="open-in-new" mat-cell *ngIf="element?.courseName?.length">open_in_new</mat-icon>
						{{ element?.courseName }}
					</a>
				</td>
			</ng-container>
			<!-- Due Date -->
			<ng-container matColumnDef="due_date">
				<th mat-header-cell *matHeaderCellDef>
					<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
						<span i18n>Due date</span>
						<button
							mat-icon-button
							class="icon-button"
							(click)="!isWithPagination ? sortAllData(sortBy.DueDate) : getSortedData(sortBy.DueDate)"
							i18n-matTooltip
							matTooltip="Sort"
							aria-label="Sort">
							<mat-icon
								fontSet="material-icons-round"
								class="table-sort-icon"
								[ngClass]="{
									'table-sort-icon-selected-desc': sortColumn === sortBy.DueDate && isTalbeDescending,
									'table-sort-icon-selected-asc': sortColumn === sortBy.DueDate && !isTalbeDescending
								}"
								[style.color]="isTableEmpty || hasNoSearchResults ? 'gray' : ''">
								sort
							</mat-icon>
						</button>
					</div>
				</th>
				<td mat-cell *matCellDef="let element">
					<ng-container *ngIf="!isLoading; else loadingSkeleton">
						<ng-container *ngIf="element?.dueDate && currentDate - element?.dueDate?.getTime() > 0">
							{{ element?.dueDate | date: 'MMM d, y, h:mm a' }}
						</ng-container>
						<ng-container *ngIf="element?.dueDate && currentDate - element?.dueDate?.getTime() < 0">
							<div
								fxLayout="row"
								fxLayoutAlign="start"
								i18n-matTooltip
								matTooltip="Stats will be updated when this assignment due date has passed.">
								<mat-icon class="time-icon">schedule</mat-icon>
								<div class="active-assignment">
									Ends:
									{{ element?.dueDate | date: 'MMM d, y, h:mm a' }}
								</div>
							</div>
						</ng-container>
					</ng-container>
				</td>
			</ng-container>
			<!-- Create Date -->
			<ng-container matColumnDef="create_date">
				<th mat-header-cell *matHeaderCellDef>
					<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
						<span i18n>Creation date</span>
						<button
							mat-icon-button
							class="icon-button"
							(click)="!isWithPagination ? sortAllData(sortBy.CreatedDate) : getSortedData(sortBy.CreatedDate)"
							i18n-matTooltip
							matTooltip="Sort"
							aria-label="Sort">
							<mat-icon
								fontSet="material-icons-round"
								class="table-sort-icon"
								[ngClass]="{
									'table-sort-icon-selected-desc': sortColumn === sortBy.CreatedDate && isTalbeDescending,
									'table-sort-icon-selected-asc': sortColumn === sortBy.CreatedDate && !isTalbeDescending
								}"
								[style.color]="isTableEmpty || hasNoSearchResults ? 'gray' : ''">
								sort
							</mat-icon>
						</button>
					</div>
				</th>
				<td mat-cell *matCellDef="let element">
					<ng-container *ngIf="!isLoading; else loadingSkeleton">
						{{ element?.createdDate | date: 'MMMM y' }}
					</ng-container>
				</td>
			</ng-container>

			<!-- Ai Score -->
			<ng-container matColumnDef="avg_ai">
				<th mat-header-cell *matHeaderCellDef>
					<div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
						<span i18n>Avg. AI content detected</span>
						<button
							*ngIf="!isWithPagination"
							mat-icon-button
							class="icon-button"
							(click)="sortAllData(sortBy.AIScore)"
							i18n-matTooltip
							matTooltip="Sort"
							aria-label="Sort">
							<mat-icon
								fontSet="material-icons-round"
								class="table-sort-icon"
								[ngClass]="{
									'table-sort-icon-selected-desc': sortColumn === sortBy.AIScore && isTalbeDescending,
									'table-sort-icon-selected-asc': sortColumn === sortBy.AIScore && !isTalbeDescending
								}"
								[style.color]="isTableEmpty || hasNoSearchResults ? 'gray' : ''">
								sort
							</mat-icon>
						</button>
					</div>
				</th>
				<td mat-cell *matCellDef="let element" style="text-align: center">
					<ng-container *ngIf="!isLoading">
						<ng-container *ngIf="element?.averageAI != null">
							<cls-score-level [score]="element.averageAI"></cls-score-level>
						</ng-container>
						<ng-container *ngIf="element?.averageAI == null && !isTableEmpty && !hasNoSearchResults" i18n>
							Waiting for results...
						</ng-container>
					</ng-container>
					<ng-container *ngIf="!isLoading; else loadingSkeleton"></ng-container>
				</td>
			</ng-container>
			<!-- Plagiarism score -->
			<ng-container matColumnDef="plagiarism_score">
				<th mat-header-cell *matHeaderCellDef>
					<div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
						<span i18n>Avg. Plagiarism score</span>
						<button
							*ngIf="!isWithPagination"
							mat-icon-button
							class="icon-button"
							(click)="sortAllData(sortBy.PlagiairsmScore)"
							i18n-matTooltip
							matTooltip="Sort"
							aria-label="Sort">
							<mat-icon
								fontSet="material-icons-round"
								class="table-sort-icon"
								[ngClass]="{
									'table-sort-icon-selected-desc': sortColumn === sortBy.PlagiairsmScore && isTalbeDescending,
									'table-sort-icon-selected-asc': sortColumn === sortBy.PlagiairsmScore && !isTalbeDescending
								}"
								[style.color]="isTableEmpty || hasNoSearchResults ? 'gray' : ''">
								sort
							</mat-icon>
						</button>
					</div>
				</th>
				<td mat-cell *matCellDef="let element" style="text-align: center">
					<ng-container *ngIf="!isLoading">
						<ng-container *ngIf="element?.averagePlagiarism != null">
							<cls-score-level [score]="element?.averagePlagiarism"></cls-score-level>
						</ng-container>
						<ng-container *ngIf="element?.averagePlagiarism == null && !isTableEmpty && !hasNoSearchResults" i18n>
							Waiting for results...
						</ng-container>
					</ng-container>
					<ng-container *ngIf="!isLoading; else loadingSkeleton"></ng-container>
				</td>
			</ng-container>

			<!-- Writing feedback -->
			<ng-container matColumnDef="writingFeeback_avg">
				<th mat-header-cell *matHeaderCellDef>
					<div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
						<span i18n>Avg. Writing corrections</span>
						<button
							*ngIf="!isWithPagination"
							mat-icon-button
							class="icon-button"
							(click)="sortAllData(sortBy.WritingFeedback)"
							i18n-matTooltip
							matTooltip="Sort"
							aria-label="Sort">
							<mat-icon
								fontSet="material-icons-round"
								class="table-sort-icon"
								[ngClass]="{
									'table-sort-icon-selected-desc': sortColumn === sortBy.WritingFeedback && isTalbeDescending,
									'table-sort-icon-selected-asc': sortColumn === sortBy.WritingFeedback && !isTalbeDescending
								}"
								[style.color]="isTableEmpty || hasNoSearchResults ? 'gray' : ''">
								sort
							</mat-icon>
						</button>
					</div>
				</th>
				<td
					mat-cell
					*matCellDef="let element"
					class="avg-writing-correction"
					[ngClass]="{ 'disabled-avg-writing-correction': !writingFeedbackFeatureEnabled }">
					<ng-container *ngIf="!isLoading">
						<ng-container *ngIf="element?.averageWritingFeedback != null && writingFeedbackFeatureEnabled">
							{{ element?.averageWritingFeedback | number: '1.0-0' }}
						</ng-container>
						<ng-container
							*ngIf="
								element?.averageWritingFeedback == null &&
								!isTableEmpty &&
								!hasNoSearchResults &&
								writingFeedbackFeatureEnabled
							"
							i18n>
							Waiting for results...
						</ng-container>
						<ng-container *ngIf="!writingFeedbackFeatureEnabled && !isTableEmpty && !hasNoSearchResults">
							N/A
						</ng-container>
					</ng-container>
					<ng-container *ngIf="!isLoading; else loadingSkeleton"></ng-container>
				</td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
		</table>
		<ng-container *ngIf="(isTableEmpty || hasNoSearchResults) && !isLoading">
			<cls-contained-content class="empty-message" [isTextCentered]="true">
				<cls-contained-title>
					<div *ngIf="isTableEmpty && !hasNoSearchResults" i18n="contained-title-empty">No Assignments Found</div>
					<div *ngIf="hasNoSearchResults" i18n="contained-title-empty-search">No Search results</div>
				</cls-contained-title>
				<cls-contained-description>
					<div fxLayout="column" fxLayoutGap="32px" fxLayoutAlign="center center">
						<div i18n="contained-description-empty" *ngIf="isTableEmpty && !hasNoSearchResults">
							Use this integration in a assignments to see data.
						</div>
						<div i18n="contained-description-empty-search" *ngIf="hasNoSearchResults">Cannot find Assignments.</div>
					</div>
				</cls-contained-description>
			</cls-contained-content>
		</ng-container>
	</div>
	<!-- </ng-container> -->
	<cls-paginator
		(page)="movePaginator($event)"
		[pageSize]="paginateState.pageSize"
		[canLoadMoreData]="canLoadMore"
		[pageSizeOptions]="[10, 50, 100]"
		[isLoadingMoreData]="isLoading"
		[showPaginator]="showPaginator && !isLoading"
		[pageIndex]="paginateState.pageIndex"
		[receivedDataLength]="totalDataLength"></cls-paginator>

	<ng-container *ngIf="!isTableEmpty && !hasNoSearchResults && !isLoading && showPaginator && !isWithPagination">
		<div class="sum-resources-text" i18n>
			{{ dataSrc?.length }}
			different assignments
		</div>
	</ng-container>
</div>

<ng-template #loadingSkeleton>
	<ngx-skeleton-loader
		count="1"
		animation="progress"
		appearance="line"
		[theme]="{
			'background-color': 'rgba(17, 41, 96, 0.3)',
			height: '18px',
			width: '40%',
			top: '10px',
			'border-radius': '10px'
		}"></ngx-skeleton-loader>
</ng-template>
