<div class="refills-budget-select-wrapper form-field-outline-no-label">
	<mat-form-field appearance="outline" full-width>
		<mat-select [(ngModel)]="selectedBudget">
			<mat-option
				*ngFor="let option of refillsOptions"
				[disabled]="dialogData?.disabledOptions ? option.value < dialogData.budget : false"
				[value]="option.value">
				{{ option.name }}
			</mat-option>
		</mat-select>
	</mat-form-field>
</div>
