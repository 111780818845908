import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbNavigationComponent } from './breadcrumb-navigation/breadcrumb-navigation.component';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { BreadcrumbItemPathComponent, BreadcrumbItemComponent } from './breadcrumb-item/breadcrumb-item.component';
import { BreadcumbsSeoDirective } from './directives/breadcumbs-seo.directive';
import { EllipsisPipeModule } from '../../pipes/ellipsis/ellipsis-pipe.module';
import { SpinnerButtonModule } from '../spinner-button/spinner-button.module';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
	declarations: [
		BreadcrumbNavigationComponent,
		BreadcrumbItemComponent,
		BreadcrumbItemPathComponent,
		BreadcumbsSeoDirective,
	],
	exports: [BreadcrumbNavigationComponent, BreadcrumbItemComponent, BreadcrumbItemPathComponent],
	imports: [
		CommonModule,
		RouterModule,
		FlexLayoutModule,
		NgxSkeletonLoaderModule,
		MatIconModule,
		EllipsisPipeModule,
		MatButtonModule,
		MatTooltipModule,
		SpinnerButtonModule,
	],
})
export class BreadcrumbNavigationProjModule {}
