<div class="form" fxLayout="column" fxLayoutGap="8px">
	<ngx-skeleton-loader class="table-skeleton-loader card-number-section" [theme]="skeletonTheme"></ngx-skeleton-loader>
	<div class="card-inf-section" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
		<div class="card-exp-cvc-container" fxLayout="row" fxLayoutGap="8px">
			<ngx-skeleton-loader class="table-skeleton-loader expiry" [theme]="skeletonTheme"></ngx-skeleton-loader>
			<ngx-skeleton-loader class="table-skeleton-loader cvc" [theme]="skeletonTheme"></ngx-skeleton-loader>
		</div>
		<ngx-skeleton-loader class="table-skeleton-loader cardholder" [theme]="skeletonTheme"></ngx-skeleton-loader>
	</div>
</div>
