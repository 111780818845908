import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinner, MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { environment } from '@environment';
// import { CustomFormsModule } from 'ngx-custom-validators';
import { NarikCustomValidatorsModule } from '@narik/custom-validators';
import { BoardModule } from '@ngx-common/components/board/board.module';
import { BreadcrumbNavigationProjModule } from '@ngx-common/components/breadcrumb-navigation-proj/breadcrumb-navigation.module';
import { CalloutModule } from '@ngx-common/components/callout/callout.module';
import { CustomSnackbarModule } from '@ngx-common/components/custom-snackbar/custom-snackbar.module';
import { NewDashboardNavbarModule } from '@ngx-common/components/new-dashboard-navbar/new-dashboard-navbar.module';
import { PanelModule } from '@ngx-common/components/panel/panel.module';
import { QuickActionsModule } from '@ngx-common/components/quick-actions/quick-actions.module';
import { ScanDatabasesTableModule } from '@ngx-common/components/scan-databases-table/scan-databases-table.module';
import { SpinnerModule } from '@ngx-common/components/spinner/spinner.module';
import { CopyLeaksDialogModule } from '@ngx-common/dialogs/copyleaks-dialog/copyleaks-dialog.module';
import { JwtInterceptor } from '@ngx-common/interceptors/jwt.interceptor';
import { UnauthorizedInterceptor } from '@ngx-common/interceptors/unauthorized.interceptor';
import { ECopyleaksAPP, NgxCommonPagesConfig } from '@ngx-common/pages/pages.config';
import { CustomSentryErrorHandler } from '@ngx-common/services/custom-sentry-handler.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import * as Sentry from '@sentry/angular';
import { AvatarModule } from 'ngx-avatar';
import { CookieService } from 'ngx-cookie-service';
import { TimeagoModule } from 'ngx-timeago';
import { from, Observable } from 'rxjs';
import { RepositoryValidationMessageModule } from 'shared/componenets/repository-validation-message/repository-validation-message.module';
import { AppRoutingModule } from './app-routing.module';
import { DashboardAppComponent } from './app.component';
import { ContactUsFormModule } from './components/contact-us-form/contact-us-form.component';
import { DangerDialog } from './components/dialogs/danger-dialog/danger-dialog.component';
import { DeleteSomethingDialog } from './components/dialogs/delete-something-dialog/delete-something-dialog.component';
import { ErrorBlockComponent } from './components/error-block/error-block.component';
import { InstituteCard } from './components/institute-card/institute-card.component';
import { InstituteIntegrationsComponent } from './components/institute-integrations/institute-integrations.component';
import { InstituteListComponent } from './components/institute-list/institute-list.component';
import { LayoutComponent } from './components/layout/layout.component';
import { ProgressIconButton } from './components/progress-icon-button/progress-icon-button.component';
import { ProgressButtonDirective } from './directives/progress-button.directive';
import { QuickActionModule } from './modules/quick-action/quick-action.module';
import { ContactUsPageComponent } from './pages/contact-us/contact-us.component';
import { InstituteListPageComponent } from './pages/institute-list-page/institute-list-page.component';
import { LegalPageComponent } from './pages/legal/legal-page.component';
import { OpenSourcePageComponent } from './pages/legal/open-source/open-source-page.component';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
import { ClsAlertsModule } from 'ngx-common-v2/components/cls-alerts/cls-alerts.module';
import { AnalyticsCourseModule } from 'shared/componenets/lms-analytics/pages/analytics-course/analytics-course.module';
import { AnalyticsActivityModule } from 'shared/componenets/lms-analytics/pages/analytics-activity/analytics-activity.module';
import { AnalyticsIntegrationModule } from 'shared/componenets/lms-analytics/pages/analytics-integration/analytics-integration.module';
import { AnalyticsStudentModule } from 'shared/componenets/lms-analytics/pages/analytics-student/analytics-student.module';
import { NewDashboardNavbarV2Module } from '@ngx-common/components/new-dashboard-navbar-v2/new-dashboard-navbar-v2.module';

export class WebpackTranslateLoader implements TranslateLoader {
	getTranslation(lang: string): Observable<any> {
		if (lang === 'en-US') {
			lang = 'en';
		}
		return from(import(`../assets/i18n/${lang}.json`));
	}
}

@NgModule({
	declarations: [
		DashboardAppComponent,
		LayoutComponent,
		InstituteListPageComponent,
		InstituteCard,
		NotFoundPageComponent,
		ProgressButtonDirective,
		ProgressIconButton,
		InstituteListComponent,
		DangerDialog,
		DeleteSomethingDialog,
		InstituteIntegrationsComponent,
		ErrorBlockComponent,
		LegalPageComponent,
		ContactUsPageComponent,
		OpenSourcePageComponent,
	],
	imports: [
		HttpClientModule,
		BrowserModule.withServerTransition({
			appId: 'ng-universal-copyleaks',
		}),
		TimeagoModule.forRoot(),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useClass: WebpackTranslateLoader,
			},
		}),
		AppRoutingModule,
		BrowserAnimationsModule,
		FormsModule,
		ClsAlertsModule,

		// CustomFormsModule,
		NarikCustomValidatorsModule,
		BoardModule,
		PanelModule,
		BreadcrumbNavigationProjModule,

		SpinnerModule,
		CopyLeaksDialogModule,
		AvatarModule,
		NewDashboardNavbarModule,

		FlexLayoutModule,
		MatMenuModule,
		MatIconModule,
		MatSidenavModule,
		MatToolbarModule,
		MatDividerModule,
		MatListModule,
		MatCheckboxModule,
		MatFormFieldModule,
		MatExpansionModule,
		MatSlideToggleModule,
		MatProgressSpinnerModule,
		MatButtonModule,
		MatInputModule,
		MatStepperModule,
		MatRadioModule,
		MatDialogModule,
		MatCardModule,
		MatTooltipModule,

		CalloutModule,
		QuickActionsModule,
		QuickActionModule,
		ContactUsFormModule,

		RepositoryValidationMessageModule,
		ScanDatabasesTableModule,

		MatSnackBarModule,
		CustomSnackbarModule,

		MatProgressBarModule,

		AnalyticsIntegrationModule,
		AnalyticsCourseModule,
		AnalyticsActivityModule,
		AnalyticsStudentModule,
		NewDashboardNavbarV2Module,
	],
	providers: [
		CookieService,
		{
			provide: NgxCommonPagesConfig.key,
			useValue: {
				APP: ECopyleaksAPP.LMS,
				APP_ORIGIN: environment.ltiUrl,
			} as NgxCommonPagesConfig,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: JwtInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: UnauthorizedInterceptor,
			multi: true,
		},
		// ** Sentry Error Logging **
		{
			provide: ErrorHandler,
			useClass: CustomSentryErrorHandler,
		},
		{
			provide: Sentry.TraceService,
			deps: [Router],
			useValue: undefined,
		},
		// *************************
	],
	entryComponents: [DeleteSomethingDialog, DangerDialog, MatProgressSpinner],
	bootstrap: [DashboardAppComponent],
})
export class AppModule {}
