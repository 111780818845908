import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from '@environment';
import { ClsAlertsService } from '@ngx-common-v2/components/cls-alerts/services/cls-alerts.service';
import { Subject, take } from 'rxjs';
import { MAX_CONTAINER_NOTIFICATIONS_TO_SHOW } from '../../constants';
import { NgxCommonPagesConfig } from '../../pages/pages.config';
import { AuthService } from '../../services/auth.service';
import { UrlLocationService } from '../../services/url-location.service';
import {
	ENotificationType,
	ETaskElementStatus,
	IBatchDownloadUpdateNotification,
	IPersonalNotification,
	IPersonalNotificationTask,
	IUsersNotificationsClearReadHistory,
} from '../models/web-notifications.models';
import { BasePersonalNotificationsService } from './base-personal-notifications.service';

@Injectable({
	providedIn: 'root',
})
export class AllPersonalNotificationsService extends BasePersonalNotificationsService {
	private _cursor = '';
	private _canLoadMoreNotifications = true;

	get canLoadMoreNotifications() {
		return this._canLoadMoreNotifications;
	}

	private _lastClearAndReadNotifications$ = new Subject<IUsersNotificationsClearReadHistory>();
	get lastClearAndReadNotifications$() {
		return this._lastClearAndReadNotifications$;
	}

	constructor(
		@Inject(PLATFORM_ID) platformId: object,
		urlLocationSvc: UrlLocationService,
		authSvc: AuthService,
		httpClient: HttpClient,
		clsAlertsSvc: ClsAlertsService,
		@Inject(NgxCommonPagesConfig.key) config: NgxCommonPagesConfig
	) {
		super(platformId, urlLocationSvc, authSvc, httpClient, clsAlertsSvc, config);
		this._onServiceInit();
	}

	private _onServiceInit() {
		this.onNotificationChange.subscribe(value => {
			if (value?.metaData?.status == ETaskElementStatus.Canceled) {
				this.notifications = [...this.notifications.filter(n => n.id != value.id)];
			}
		});
	}

	// Load user notifications
	public async loadNotificationsAsync(forceReload = false) {
		try {
			if (forceReload) {
				this._cursor = '';
				this._canLoadMoreNotifications = true;
				this.notifications$.next([]);
			}

			if (!this._canLoadMoreNotifications) return;

			this._showLoadingSpinner$.next(true);
			var newNotificationsAndCursor = await this.httpClient
				.get<IPersonalNotificationAndCursor>(
					`${environment.notificationsAPI}/notifications/${this.signalRProducts}/personal?&limit=${MAX_CONTAINER_NOTIFICATIONS_TO_SHOW}&cursor=${this._cursor}`
				)
				.toPromise();
			this._cursor = newNotificationsAndCursor.cursor;
			var currentNotifications: IPersonalNotification[] = [];
			this.notifications$.pipe(take(1)).subscribe(notifications => {
				currentNotifications = notifications;
			});
			var oldAndNewNotifications = [...currentNotifications, ...newNotificationsAndCursor.notifications];
			this.notifications = oldAndNewNotifications;
			if (newNotificationsAndCursor.notifications.length < MAX_CONTAINER_NOTIFICATIONS_TO_SHOW)
				this._canLoadMoreNotifications = false;

			// update notifications clear/read status
			if (newNotificationsAndCursor.lastClear || newNotificationsAndCursor.lastRead) {
				this._lastClearAndReadNotifications$.next({
					lastClear: newNotificationsAndCursor.lastClear,
					lastRead: newNotificationsAndCursor.lastRead,
				} as IUsersNotificationsClearReadHistory);
			}
			if (newNotificationsAndCursor.lastRead)
				this.notifications = this.notifications.map(n => {
					if (n.messageType === ENotificationType.general && n.creationTime <= newNotificationsAndCursor.lastRead)
						n.isRead = true;
					return n;
				});
		} catch (err) {
			this.clsAlertsSvc.showHttpResponseError(err);
		} finally {
			this._showLoadingSpinner$.next(false);
		}
	}

	onDownloadReportPdfRequestFinishAsync(
		notification: IPersonalNotificationTask,
		data: IBatchDownloadUpdateNotification
	): void {
		/** abstruct method*/
	}
}

export interface IPersonalNotificationAndCursor {
	cursor: string;
	notifications: IPersonalNotification[];
	lastRead?: Date;
	lastClear?: Date;
}
