//tslint:disable
export class NotificationsTargets {
	static ScansChanges = 'scansChanges';
	static ScanningStatistics = 'SCANNING_STATISTICS'; //coming from notifications
	static CopyleaksStatuses = 'COPYLEAKS_STATUSES'; //coming from notifications
	static RepositoriesChanges = 'repositoriesChanges';
	static PriceCheck = 'priceCheck';
	static FlashAlerts = 'flashAlerts';
	static DisplayedNotifications = 'displayedNotifications';
	static ElementsSummary = 'elementsSummary';
	static transferOwnershipUpdate = 'transferOwnershipUpdate';

	static PdfDownload(scanId: string): string {
		return `pdfDownload${scanId}`;
	}
	static ScanNewResult(scanId: string): string {
		return `newResult${scanId}`;
	}
	static DocumentsCompareChanges(id: string): string {
		return `documentsCompareChanges${id}`;
	}
	static ElementsDeleteUpdate(id: string) {
		return `ElementsDeleteUpdate${id}`;
	}
	static ElementsPDFDownloadUpdate(id: string) {
		return `ElementsPDFDownloadUpdate${id}`;
	}
	static DocumentsIndexUpdate(id: string) {
		return `DocumentsIndexUpdate${id}`;
	}
}
