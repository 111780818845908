import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment';
import { ICopyLeaksCaptchaControlValue } from '../components/copyleaks-captcha/copyleaks-captcha-component/copyleaks-captcha.component';
import { AuthService } from './auth.service';

@Injectable()
export class SupportService {
	constructor(private _http: HttpClient, private _authSvc: AuthService) {}

	async sendContactUsEmail(data: IContactUsPayload) {
		try {
			await this._http.post(`${environment.apiUrl}/v1/support/contact-us`, data).toPromise();
		} catch (error) {
			throw error;
		}
	}

	async sendContactUsSupportCustomerPlanEmail(data: ISupportContactUsPayload) {
		try {
			await this._http.post(`${environment.apiUrl}/v1/support/contact-us-custom-plan`, data).toPromise();
		} catch (error) {
			throw error;
		}
	}

	async sendTwoSitesComparisonEmail(data: ITwoSitesComparisonPayload) {
		try {
			await this._http.post(`${environment.apiUrl}/v1/support/two-sites-comparison`, data).toPromise();
		} catch (error) {
			throw error;
		}
	}

	async sendQuoteRequest(data: IContactUsPayload) {
		try {
			await this._http.post(`${environment.apiUrl}/v1/support/quote-request`, data).toPromise();
		} catch (error) {
			throw error;
		}
	}

	async SuggestAFeature(data: IFeatureRequestPayload) {
		try {
			await this._http.post(`${environment.apiUrl}/v1/support/feature-request`, data).toPromise();
		} catch (error) {
			throw error;
		}
	}

	async sendPageFeedback(data: PageFeedback) {
		data.email = this._authSvc?.user()?.email;
		return await this._http.post(`${environment.apiUrl}/v1/support/send-page-feedback`, data).toPromise();
	}
}

export interface IContactUsPayload {
	name: string;
	email: string;
	company: string;
	message: string;
	phoneNumber: number;
	NumberOfUsers?: number;
	numberOfPages?: number;
	captcha: ICopyLeaksCaptchaControlValue;
}

export interface ISupportContactUsPayload {
	name: string;
	email: string;
	message: string;
}

export interface ITwoSitesComparisonPayload {
	name: string;
	email: string;
	message?: string;
	domain1: string;
	domain2: string;
}

export interface IFeatureRequestPayload {
	featureTitle: string;
	featureDescription: string;
	company: string;
	name: string;
	captcha: ICopyLeaksCaptchaControlValue;
}
export class PageFeedback {
	public email: string;
	public description: string;
	public screenshot: string;
	public logs: any;
	public pageUrl: string;
	public userAgent: string;
}
