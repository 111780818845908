import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { WindowService } from '../../../../services/window.service';
import { ENotificationType } from '../../../models/web-notifications.models';

@Component({
	selector: 'app-web-notification',
	templateUrl: './web-notification.component.html',
	styleUrls: ['./web-notification.component.scss'],
})
export class WebNotificationComponent {
	@Input() linkAddress: string;
	@Input() isRead: boolean;
	@Input() notificationType: ENotificationType;

	constructor(private _router: Router, private _windowSvc: WindowService) {}

	public navigateToPage(): void {
		if (this.linkAddress) {
			if (this.linkAddress.includes('https://') || this.linkAddress.includes('http://')) {
				this._windowSvc.open(this.linkAddress, '_blank');
			}
		}
	}

	public checkIfLinkAddressIsNull(): boolean {
		if (this.linkAddress !== '' && this.linkAddress !== null && this.linkAddress !== undefined) return true;
		return false;
	}
}

@Component({
	selector: 'app-web-notification-description',
	template: '<ng-content></ng-content>',
})
export class WebNotificationDescriptionComponent {}

@Component({
	selector: 'app-web-notification-time',
	template: '<ng-content></ng-content>',
})
export class WebNotificationTimeComponent {}
