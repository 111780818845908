import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from '@environment';
import { AuthService } from '@ngx-common/services/auth.service';
import { untilDestroy } from 'shared/utils/rxjs/untilDestroy';
import { DashboardUserSettings } from '../../interfaces';
import { UserSettingsService } from '../../services/user-settings.service';

@Component({
	selector: 'app-layout',
	templateUrl: './layout.component.html',
	styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, OnDestroy {
	userSettings: DashboardUserSettings;
	environment = environment;
	showSpinner = true;

	get isUserLogin() {
		return this._authSvc?.isLoggedIn();
	}

	constructor(private _userSettingsSvc: UserSettingsService, private _authSvc: AuthService) {}

	ngOnInit() {
		this._userSettingsSvc.userSettings$.pipe(untilDestroy(this)).subscribe(settings => {
			this.userSettings = settings;
			this.showSpinner = false;
		});
	}

	goToLink(url: string) {
		window.open(url, '_blank');
	}

	logout() {
		this._userSettingsSvc.clear();
	}

	ngOnDestroy() {}
}
