import { DOCUMENT } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { environment } from '@environment';
import {
	CopyLeaksDialogComponent,
	ICopyLeaksDialogData,
} from '@ngx-common/dialogs/copyleaks-dialog/copyleaks-dialog-component/copyleaks-dialog.component';
import { ECookiesKeys } from '@ngx-common/enums/cookies-keys.enum';
import { AppDirectionalityService } from '@ngx-common/services/app-directionality.service';
import { AuthService } from '@ngx-common/services/auth.service';
import { LocalStorageService } from '@ngx-common/services/localstorage.service';
import { UrlLocationService } from '@ngx-common/services/url-location.service';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { take } from 'rxjs/operators';
import { UserSettingsService } from './services/user-settings.service';
import { OidcService } from '@ngx-common/services/oidc.service';
import { ClsAlertsService } from '@ngx-common-v2/components/cls-alerts/services/cls-alerts.service';
import { NotificationsService } from 'shared/notifications';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class DashboardAppComponent implements OnInit {
	title = 'DashboardApp';
	pageLoading: boolean;
	showBalanceLoader = false;
	direction: string;
	constructor(
		private ngxTranslateSvc: TranslateService,
		private _router: Router,
		private _authService: AuthService,
		private _oidcService: OidcService,
		private _clsAlertSvc: ClsAlertsService,
		private _userSettingsSvc: UserSettingsService,
		private _localStorageSvc: LocalStorageService,
		private _matDialog: MatDialog,
		private _cookiesSvc: CookieService,
		private _urlLocationSvc: UrlLocationService,
		private _renderer: Renderer2,
		private readonly dir: AppDirectionalityService,
		@Inject(DOCUMENT) private _document: Document,
		@Inject(LOCALE_ID) private _locale: string,
		private _notificationsSvc: NotificationsService
	) {}
	ngOnInit(): void {
		this.ngxTranslateSvc.setDefaultLang('en');
		console.log(`version: ${environment.version}`);

		// #region translation setup
		this.setupLanguage();
		this.direction = this.dir.value;
		if (this.direction === 'rtl') {
			this._renderer.addClass(this._document.body, `copyleaks-rtl`);
		}
		this._renderer.addClass(this._document.body, `language-${this._locale}`);

		this._authService.checkIfUserAlreadyLoggedin();

		this._authService.onLoginSuccess$.pipe(take(1)).subscribe(_ => {
			this._userSettingsSvc.initUserSettings();
		});

		this._setupRoutingHandlers();

		this._authService.isLogedIn$.subscribe(isLogedIn => {
			if (isLogedIn) {
				this._isUserAllowedToUseTheWebsite();
			}
		});

		// this._authService.user$.subscribe(user => {
		// 	if (user) this._setupGoogleAnalytics();
		// });
		this._setupNotifications();
	}
	private _setupNotifications() {
		let baseUrl;
		if (location.host.includes('localhost')) {
			baseUrl = `https://localhost:5003`;
		} else {
			baseUrl = location.origin;
		}
		this._notificationsSvc.setup(baseUrl);
	}
	// private async _setupGoogleAnalytics() {
	// 	var user = await this._oidcService?.manager?.getUser();
	// 	if (user?.profile?.sub) {
	// 		(window as any).dataLayer = (window as any).dataLayer || [];
	// 		(window as any).dataLayer.push({
	// 			user_id: user?.profile?.sub,
	// 		});
	// 	}
	// }

	private setupLanguage(): void {
		if (environment.production) {
			const baseHref = this._document.getElementsByTagName('base')[0].attributes[0].value;
			let storageLang = this._cookiesSvc.get(ECookiesKeys.Langauge);

			if (this._locale.toLowerCase() == 'en-us') this._locale = 'en';
			if (storageLang.toLowerCase() == 'en-us') storageLang = 'en';

			if (storageLang) {
				if (baseHref === `/` && this._locale === 'en' && storageLang !== 'en') {
					// Enter to the web with '/' but with localStorage inside
					const pageURL = this._urlLocationSvc.location.pathname;
					const queryParams = this._urlLocationSvc.location.search;
					this._urlLocationSvc.location.href = `${storageLang}${pageURL}${queryParams}`;
				} else if (this._locale !== storageLang && this._locale !== 'en') {
					this._cookiesSvc.set(ECookiesKeys.Langauge, this._locale, null, '/', 'copyleaks.com');
				}
			} else {
				this._cookiesSvc.set(ECookiesKeys.Langauge, this._locale, null, '/', 'copyleaks.com'); // Covers first time going to the website
			}
		}
	}

	private async _isUserAllowedToUseTheWebsite() {
		try {
			this.showBalanceLoader = true;
			const balance = await this._userSettingsSvc.getUserBalanceAsync();
			if (!balance?.features?.ltiIntegration) {
				this._matDialog.open(CopyLeaksDialogComponent, {
					disableClose: true,
					width: '475px',
					data: {
						title: 'Access Denied',
						description: `Please contact <a href="mailto:sales@copyleaks.com">sales@copyleaks.com</a> to set up a trial of Copyleaks for your LMS platform.`,
						btnSubmitText: 'Book A Demo',
						hideCancelButton: true,
						onSubmit: async () => {
							window.open('https://copyleaks.com/book-a-demo/education', '_self');
							this._router.navigate;
						},
					} as ICopyLeaksDialogData,
				});
			}
			this.showBalanceLoader = false;
		} catch (error) {
			this._clsAlertSvc.showHttpResponseError(error);
		}
	}

	private async _setupRoutingHandlers() {
		this._router.events.subscribe(event => {
			switch (true) {
				case event instanceof NavigationStart:
					setTimeout(() => {
						this.pageLoading = true;
					});
					break;
				case event instanceof NavigationEnd:
				case event instanceof NavigationCancel:
				case event instanceof NavigationError:
					setTimeout(() => {
						this.pageLoading = false;
					});
					break;
				default:
					break;
			}
		});
	}
}
