import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
	selector: 'app-institute-list-page',
	templateUrl: './institute-list-page.component.html',
	styleUrls: ['./institute-list-page.component.scss'],
})
export class InstituteListPageComponent {
	constructor(titleService: Title) {
		titleService.setTitle('Copyleaks LMS - Dashboard');
	}
}
