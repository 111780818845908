<div class="shared-item-container" fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
	<div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
		<div class="open-in-new-tab-container" fxLayoutAlign="center center">
			<mat-icon>open_in_new</mat-icon>
		</div>
		<div fxLayoutAlign="start center">
			<img width="23px" [src]="icon !== undefined ? icon : 'assets/images/scan-items/folder.svg'" alt="icon" />
		</div>
	</div>
	<span class="item-name" [matTooltip]="data.displayName" i18n-matTooltip>{{ data.displayName | ellipsis: 40 }}</span>
</div>
