import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ClsAlertsComponent } from './cls-alerts.component';
import { ClsAlertItemModule } from './components/cls-alert-item/cls-alert-item.module';

@NgModule({
	declarations: [ClsAlertsComponent],
	exports: [ClsAlertsComponent],
	imports: [CommonModule, FlexLayoutModule, ClsAlertItemModule],
})
export class ClsAlertsModule {}
