import { Directive, ElementRef, AfterContentInit, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { SEOService, BreadcrumbItem } from '../../../services/seo.service';
import { environment } from '@environment';

@Directive({
	selector: '[clsBreadcumbsSeo]',
})
export class BreadcumbsSeoDirective implements AfterContentInit {
	constructor(
		private el: ElementRef<HTMLElement>,
		@Inject(PLATFORM_ID) private platformId,
		private seoService: SEOService
	) {}
	ngAfterContentInit(): void {
		if (isPlatformServer(this.platformId)) {
			try {
				const breadcrumbsItemElement = this.el.nativeElement.querySelectorAll('span[class="path-name"]');
				const breadcrumbNameElements = this.el.nativeElement.querySelectorAll('cls-breadcrumb-item-path');
				const breadcrumbs: BreadcrumbItem[] = [];
				breadcrumbNameElements.forEach((element, idx) => {
					const url = `${environment.mainWebsiteUrl}${breadcrumbsItemElement[idx].getAttribute('path-link')}`;
					breadcrumbs.push({
						name: element.textContent.trim(),
						url,
					});
				});
				this.seoService.addBreadcrumb(breadcrumbs);
			} catch (error) {
			} finally {
			}
		}
	}
}
