import { Direction } from '@angular/cdk/bidi';
import { Component, Input, ViewChild, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppDirectionalityService } from '../../services/app-directionality.service';

@Component({
	selector: 'cls-dynamic-breadcrumb-navigation',
	templateUrl: './dynamic-breadcrumb-navigation.component.html',
	styleUrls: ['./dynamic-breadcrumb-navigation.component.scss'],
})
export class DynamicBreadcrumbNavigationComponent implements OnInit {
	@Input() navPaths: BreadcrumbNavigationItem[] = [];
	@Input() isFolderSelect: boolean;
	@ViewChild('editInput') editInput: ElementRef;
	@Input() menuEndIndex: number;
	@Input() menuStartIndex: number;
	@Input() customColor: string = '#999999';
	inputHasError = false;
	public direction: Direction;

	get isExpandedBreadcrumbs(): boolean {
		return !!this.menuStartIndex || !!this.menuEndIndex || this.menuStartIndex === 0 || this.menuEndIndex === 0;
	}

	constructor(
		private _router: Router,
		private readonly dir: AppDirectionalityService,
		private _elementRef: ElementRef
	) {}

	ngOnInit(): void {
		this.direction = this.dir.value;
	}

	onKeyDown(event: KeyboardEvent, path: BreadcrumbNavigationItem, editInput: HTMLInputElement) {
		if (event.key === 'Enter') {
			path.isEditMode = false;
			// this.saveEditedValue(path, editInput);
		}
	}
	showLoader() {
		return this.navPaths && this.navPaths.filter(i => i.isLoading).length > 0;
	}

	onclick(nav: BreadcrumbNavigationItem) {
		if (nav.link !== undefined && nav.link) {
			this._router.navigate([nav.link], {
				queryParams: {
					folderId: nav.id,
				},
			});
		} else {
			if (nav.onClick) {
				nav.onClick();
			}
		}
	}

	enalbeEditMode(path: BreadcrumbNavigationItem, editInput: HTMLInputElement, hiddenText: HTMLSpanElement) {
		path.isEditMode = true;
		setTimeout(() => {
			this.onInputChange(editInput, path, hiddenText);
			editInput.focus();
		});
	}

	async saveEditedValue(path: BreadcrumbNavigationItem, editInput: HTMLInputElement) {
		this.inputHasError = false;
		const oldName = path.name.slice();
		const newName = editInput.value.trim();

		if (!newName) {
			this.inputHasError = true;
			return;
		}
		if (oldName != newName) {
			try {
				path.isEditMode = false;
				path.name = newName;
				await path.onEdit(newName);
			} catch (error) {
				path.name = oldName;
			}
		} else {
			path.isEditMode = false;
		}
	}

	onInputChange(editInput: HTMLInputElement, path: BreadcrumbNavigationItem, hiddenText: HTMLSpanElement) {
		this.inputHasError = false;
		const newName = editInput.value.trim();

		if (!newName) {
			this.inputHasError = true;
			return;
		}

		const elements = [
			...this._elementRef.nativeElement.querySelectorAll('.breadcrumb-nav-item'),
			...this._elementRef.nativeElement.querySelectorAll('.arrow-right-icon'),
		];

		let elementsWidth = 0;
		elements.forEach(element => {
			elementsWidth += element.offsetWidth;
		});

		const maxWidth = this._elementRef.nativeElement.offsetWidth - elementsWidth - 50;

		setTimeout(() => {
			let inputWidth = Math.max(100, hiddenText.offsetWidth);
			if (inputWidth > maxWidth) {
				inputWidth = maxWidth;
			}
			path.inputWidth = inputWidth;
		});
	}
}

export class BreadcrumbNavigationItem {
	public name: string;
	public isLoading?: boolean;
	public link?: string;
	public icon?: string;
	public onClick?: () => void;
	public onEdit?: (value: string) => Promise<any>;
	public isEditMode?: boolean;
	public inputWidth?: number;
	public isViewer?: boolean = false;
	public ignoreTranslate?: boolean;
	public direction?: Direction;
	public isReport?: boolean;
	public id?: string;
}
