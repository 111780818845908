import { Component, OnInit, Inject, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'app-success-message-dialog',
	templateUrl: 'success-dialog.component.html',
	styleUrls: ['success-dialog.component.scss'],
})
export class SuccessDialogComponent {
	constructor(
		public dialogRef: MatDialogRef<SuccessDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: SuccessDialogData
	) {}
	CONTINUE = $localize`CONTINUE`;
	continue() {
		this.dialogRef.close();
		this.data.onContinueClick();
	}
}

export interface SuccessDialogData {
	message: string;
	onContinueClick: () => void;
}

@NgModule({
	declarations: [SuccessDialogComponent],
	exports: [SuccessDialogComponent],
	entryComponents: [SuccessDialogComponent],
	imports: [CommonModule, MatButtonModule, MatDialogModule],
})
export class SuccessDialogModule {}
