<div class="main-container" fxLayout="column" fxLayoutGap="16px">
	<div fxLayout="row" fxLayoutGap="16px">
		<!-- Cards section -->
		<cls-results-insights-template
			fxFlex
			[isWithScroll]="false"
			[cardsDataSrc]="analyticsData.cardsData"
			[analyticsLevel]="eLevelType.Author"></cls-results-insights-template>

		<!-- Charts section -->
		<div fxFlex fxLayout="column" fxLayoutGap="16px">
			<div class="charts-wrapper" #chartsContainer style="max-height: 194px">
				<div class="chart-title" fxLayout="row" fxLayoutGap="8px">
					<h3 i18n>Matched Text</h3>
					<mat-icon class="info-icon" i18n-matTooltip matTooltip="Shows historical data of detected plagiarism.">
						info_outline
					</mat-icon>
				</div>
				<ngx-charts-line-chart
					class="chart"
					[view]="lineChartView"
					*ngIf="lineChartView"
					[xAxis]="true"
					[yAxis]="true"
					[legend]="false"
					[results]="analyticsData.plagiarismChartData.chartData"
					[yAxisLabel]="plagChartYAxisLabel"
					[showYAxisLabel]="true"
					[showXAxisLabel]="false"
					[showGridLines]="true"
					[scheme]="{ domain: ['#FD7366'] }"
					[yAxisTicks]="analyticsData.plagiarismChartData.yTicks"
					[curve]="linearCurve"
					(window:resize)="onResize($event, chartsContainer)"
					[yScaleMax]="analyticsData.plagiarismChartData.maxYTick"></ngx-charts-line-chart>
			</div>
			<div class="charts-wrapper" style="max-height: 194px">
				<div class="chart-title" fxLayout="row" fxLayoutGap="8px">
					<h3 i18n>AI Text</h3>
					<mat-icon
						class="info-icon"
						i18n-matTooltip
						matTooltip="Shows historical data of detected AI-generated content.">
						info_outline
					</mat-icon>
				</div>
				<ngx-charts-bar-vertical
					class="chart"
					[view]="chartView"
					*ngIf="chartView"
					[scheme]="{ domain: ['#C1ADDC'] }"
					[results]="analyticsData.aiChartData.chartData"
					[gradient]="false"
					[xAxis]="true"
					[yAxis]="true"
					[legend]="false"
					[yScaleMin]="0"
					[showXAxisLabel]="true"
					[barPadding]="20"
					[showYAxisLabel]="true"
					[yAxisLabel]="aiChartYAxisLabel"
					[yAxisTicks]="analyticsData.aiChartData.yTicks"
					[yScaleMax]="analyticsData.aiChartData.maxYTick"></ngx-charts-bar-vertical>
			</div>

			<ng-container *ngIf="writingFeedbackFeatureEnabled">
				<div class="charts-wrapper" style="max-height: 194px">
					<div class="chart-title" fxLayout="row" fxLayoutGap="8px">
						<h3 i18n>Writing Corrections</h3>
						<mat-icon
							class="info-icon"
							i18n-matTooltip
							matTooltip="Number of corrections within the time span provided.">
							info_outline
						</mat-icon>
					</div>
					<ngx-charts-bar-vertical
						class="chart"
						[view]="chartView"
						*ngIf="chartView"
						[scheme]="{ domain: ['#FF9A02'] }"
						[results]="analyticsData.writingFeedbackChartData.chartData"
						[gradient]="false"
						[xAxis]="true"
						[yAxis]="true"
						[legend]="false"
						[yScaleMin]="0"
						[showXAxisLabel]="true"
						[barPadding]="20"
						[showYAxisLabel]="true"
						[yAxisLabel]="writingFeedbackYAixsLabel"
						[yAxisTicks]="analyticsData.writingFeedbackChartData.yTicks"
						[yScaleMax]="analyticsData.writingFeedbackChartData.maxYTick"></ngx-charts-bar-vertical>
				</div>
			</ng-container>
			<ng-container *ngIf="!writingFeedbackFeatureEnabled">
				<analytics-empty-state class="analytics-empty-state-height">
					<analytics-empty-state-title i18n>Writing Correction</analytics-empty-state-title>
					<analytics-empty-state-image>
						<img src="../../../../assets/analytics/svgs/writingFeedbackEmptyState.svg" alt="Writing Correction" />
					</analytics-empty-state-image>
					<analytics-empty-state-description>
						Keep your writing concise and fresh with tips for improving sentence structure, word choice, overall
						mechanics, spelling, and more.
					</analytics-empty-state-description>
					<analytics-empty-state-bottom-section>
						<ng-container *ngIf="isLoadFromDashboard">
							<div class="empty-state-link-text" i18n>Contact your CSM to enable this feature.</div>
						</ng-container>
						<ng-container *ngIf="!isLoadFromDashboard">
							<div class="empty-state-link-text" i18n>Contact your admin to enable this feature.</div>
						</ng-container>
					</analytics-empty-state-bottom-section>
				</analytics-empty-state>
			</ng-container>
		</div>
	</div>

	<!-- Content projection analytics data section -->
	<ng-content select="cls-author-analytics-data"></ng-content>

	<!-- Common Results section -->
	<cls-common-results-template [dataSrc]="analyticsData.commonResults"></cls-common-results-template>
</div>
