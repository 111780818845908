<div class="copy-link--container">
	<p class="copy-link--name">{{ linkName }}</p>
	<button mat-button class="copy-link-tag-contianer" (click)="copyUrlToClipBorad()">
		<button mat-icon-button class="copy-link-btn" matTooltip="Copy" i18n-matTooltip>
			<mat-icon class="copy-link-icon" fontSet="material-icons-round">content_copy</mat-icon>
		</button>
		<div class="copy-link-href" matTooltip="Copy" i18n-matTooltip>{{ linkSrcTrimmed }}</div>
		<button
			mat-icon-button
			*ngIf="addViewOption"
			class="view-link-btn"
			matTooltip="View"
			i18n-matTooltip
			(click)="$event.preventDefault(); $event.stopPropagation(); viewLink()">
			<mat-icon
				class="view-link-icon"
				fontSet="material-icons-round"
				[ngClass]="{
					'view-link-icon-toggled': linkVisible
				}">
				visibility
			</mat-icon>
		</button>
	</button>
</div>
