import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { SpinnerModule } from '../../../components/spinner/spinner.module';
import { ClsButtonModule } from '../../../../ngx-common-v2/components/cls-button/cls-button.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ChooseProductPlanComponent } from './components/choose-product-plan/choose-product-plan.component';
import { PlanItemComponent } from './components/choose-product-plan/plan-item/plan-item.component';
import { NewPaymentDialogComponent } from './new-payment-dialog.component';
import { PaymentProccessModule } from '../../../components/payment-proccess/payment-proccess.module';
import { NumeralPipeModule } from '../../../pipes/numeral/numeral-pipe.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BillingTemplateDialogModule } from '@ngx-common/dialogs/billing-template-dialog/billing-template-dialog.module';

@NgModule({
	declarations: [NewPaymentDialogComponent, PlanItemComponent, ChooseProductPlanComponent],
	imports: [
		CommonModule,
		FlexLayoutModule,
		FormsModule,
		ClsButtonModule,
		MatIconModule,
		MatButtonModule,
		MatFormFieldModule,
		MatTooltipModule,
		MatRadioModule,
		MatSelectModule,
		ReactiveFormsModule,
		PaymentProccessModule,
		NumeralPipeModule,
		MatInputModule,
		MatOptionModule,
		SpinnerModule,
		NgxSkeletonLoaderModule,
		BillingTemplateDialogModule,
	],
})
export class NewPaymentDialogModule {}
