export enum EAdvancedProfilesPanels {
	none = 1,
	location,
	internalSources,
	externalSources,
	filters,
	crossLanguageDetection,
}
export enum EInternetScanTypes {
	everywhere,
	trustedDomains,
	selectedDomains,
}
export enum EScanPropertiesDatabaseType {
	CopyleaksDB,
	Repository,
}
export enum EScanProfilesForm {
	fcProfileId = 'id',
	fcProfileName = 'name',
	fcProfileSelected = 'selected',
	fcProfileTeamId = 'teamId',
	fcIsAdvanced = 'isAdvanced',
	fcIsPriceCheckMode = 'isPriceCheckMode',
	fcIsSandBox = 'isSandBox',
	fgFilters = 'filters',
	fcFilterReferences = 'references',
	fcFilterQuotes = 'quotes',
	fcFilterCitations = 'citations',
	fcFilterTitles = 'titles',
	fcFilterTableOfContent = 'tableOfContent',
	fcFilterHtmlTemplate = 'htmlTemplate',
	fgFilterCode = 'code',
	fcFilterCodeComments = 'comments',
	fcAIDetection = 'aiDetection',
	fgMatchTypes = 'matchTypes',
	fcMatchTypesIdentical = 'identicalCheck',
	fcMatchTypesMinorChanged = 'minorChangedCheck',
	fcMatchTypesRelatedMeaning = 'relatedMeaningCheck',
	fgSearch = 'search',
	fcSearchMinCopiedWords = 'minCopiedWords',
	fcSearchSensitivity = 'sensitivityLevel',
	fcDetectionPreference = 'detectionPreference',
	fcSearchCheatDetection = 'cheatDetection',
	fgExternalSources = 'externalSources',
	fcChooseExternalSource = 'chooseSource',
	fcExternalSourcesSafeSearch = 'safeSearch',
	fgExternalSourcesInternet = 'internet',
	fcInternetEnabled = 'enabled',
	fcInternetType = 'type',
	fcInternetTrustedDomains = 'trustedDomains',
	fcInternetSelectedDomains = 'selectedDomains',
	fgInternalSources = 'internalSources',
	fcInternalSourcesUploadedFiles = 'includeUploadedFiles',
	fcInternalSourcesDatabases = 'databases',
	fgSensitiveDataProtection = 'sensitiveDataProtection',
	fcSensitiveDataProtectionDriversLicense = 'driversLicense',
	fcSensitiveDataProtectionCredentials = 'credentials',
	fcSensitiveDataProtectionPassport = 'passport',
	fcSensitiveDataProtectionNetwork = 'network',
	fcSensitiveDataProtectionUrl = 'url',
	fcSensitiveDataProtectionEmailAddress = 'emailAddress',
	fcSensitiveDataProtectionCreditCard = 'creditCard',
	fcSensitiveDataProtectionPhoneNumber = 'phoneNumber',
	fcCrossLanguages = 'crossLanguages',
}

export enum EAPIProfileScanStatusResouse {
	OnlyBatchWithNoMultiFiles,
	NoSource,
	HasRepositoriesIssues,
}

export enum EDetectionPreferences {
	MaxCoverage = 0,
	MaxResults = 1,
}

export enum EInternalDataBaseState {
	allNotChecked,
	partChecked,
	allChecked,
}

export enum EScanProfileDialogTemplates {
	Sources,
	Filters,
	CrossLanguageDetection,
	SecurityMeasures,
	Omit,
}
