<button
	class="only-icon-btn"
	[matMenuTriggerFor]="appsMenu"
	#appsMenuButtonRef="matMenuTrigger"
	mat-icon-button
	aria-label="apps"
	i18n-matTooltip
	matTooltip="Apps">
	<mat-icon [ngClass]="{ 'toolbar-icon-clicked': appsMenuButtonRef.menuOpen }">apps</mat-icon>
</button>
<mat-menu #appsMenu="matMenu" xPosition="before">
	<div fxLayout="row" class="apps-menu-container" fxLayout="row">
		<a
			*ngIf="app !== eCopyleaksAPP.LMS"
			class="menu-item-container"
			mat-menu-item
			matTooltip="LMS Integrations for Education"
			i18n-matTooltip
			fxLayout="column"
			fxLayoutAlign="start center"
			[href]="environment.ltiUrl"
			target="_blank">
			<img alt="LTI Fav Icon" src="assets/websites-icons/lti-app-favicon.svg" />
			<span>LMS</span>
		</a>
		<a
			*ngIf="app !== eCopyleaksAPP.API"
			class="menu-item-container"
			mat-menu-item
			matTooltip="API Platform for Developers"
			i18n-matTooltip
			fxLayout="column"
			fxLayoutAlign="start center"
			[href]="environment.apiFrontEndUrl + '/dashboard'"
			target="_blank">
			<img alt="API Fav Icon" src="assets/websites-icons/api-favicon.svg" />
			<span>API</span>
		</a>
		<a
			*ngIf="app !== eCopyleaksAPP.MainWebsite"
			class="menu-item-container"
			mat-menu-item
			matTooltip="Main Website Tools"
			i18n-matTooltip
			fxLayout="column"
			fxLayoutAlign="start center"
			[href]="environment.dashboardWebsiteUrl"
			target="_blank">
			<img alt="MainWebsite Fav Icon" src="assets/websites-icons/default-favicon.svg" />
			<span>PLATFORM</span>
		</a>
		<a
			*ngIf="app !== eCopyleaksAPP.Admin && !isUserJustAnOrganizationMember"
			class="menu-item-container"
			mat-menu-item
			matTooltip="Organization Settings"
			i18n-matTooltip
			fxLayout="column"
			fxLayoutAlign="start center"
			[href]="environment.adminWebsiteUrl"
			target="_blank">
			<img alt="Admin Fav Icon" src="assets/websites-icons/admin-app-favicon.svg" />
			<span>ADMIN</span>
		</a>
	</div>
</mat-menu>
