<cls-credit-card-loader *ngIf="!isFormReady"></cls-credit-card-loader>
<div class="credit-card" [style.display]="isFormReady ? 'block' : 'none'">
	<div fxLayout="column" fxLayoutGap="8px" fxLayoutAlign="center">
		<div
			*ngIf="!havePaymentMethod && (showUpdateBtn || showTitle)"
			fxLayoutAlign="start center"
			class="title-container-new">
			<h3 fxLayout="row" fxLayoutGap="4px">
				<span i18n *ngIf="showUpdateBtn">Change</span>
				<span i18n *ngIf="showTitle">Payment Method</span>
			</h3>
		</div>
		<form fxLayout="column" #f class="form" fxLayoutAlign="center center" fxLayoutGap="8px">
			<div *ngIf="isStripLoaded" fxLayout="column" fxLayoutGap="4px" class="credit-card-numbe-div">
				<span class="input-label" i18n>Card Number*</span>
				<div
					fxLayout="row"
					fxLayoutAlign="start center"
					fxLayoutGap="32px"
					fxLayoutGap.lt-md="4px"
					class="credit-card-number">
					<div class="stripe-card-container" fxLayout="column">
						<cls-stripe-card-number
							matStripe
							class="card-number"
							[ngClass]="{
								'error-border': isControlInvalid(creditCard),
								'hover-border': !isControlInvalid(creditCard),
								'focused-border': isFocus(creditCard) && !isControlInvalid(creditCard)
							}"
							[disabled]="disabledForm"
							name="cardModel"
							[(ngModel)]="cardModel"
							(ready)="formReady($event)"
							ngDefaultControl
							[showIcon]="true"
							[required]="true"
							[matTooltip]="creditCard?.error?.message"
							[matTooltipDisabled]="!creditCard?.error?.message && !formSubmitted"
							#creditCard></cls-stripe-card-number>
						<span *ngIf="creditCard?.error?.message" class="error-message" i18n>
							{{ creditCard?.error?.message }}
						</span>
					</div>

					<div fxLayout="row" fxLayoutAlign="space-between center" class="card-types-div">
						<img role="presentation" src="assets/card-types/Visa.svg" alt="Visa.svg" />
						<img role="presentation" src="assets/card-types/MasterCard.svg" alt="MasterCard.svg" />
						<img role="presentation" src="assets/card-types/AmericanExpress.svg" alt="AmericanExpress.svg" />
						<img role="presentation" src="assets/card-types/Discover.svg" alt="Discover.svg" />
					</div>
				</div>
			</div>

			<div class="card-inf-section" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
				<div class="expiry-and-cvc-container" fxLayout="row" fxLayoutGap="8px">
					<div class="expiry" *ngIf="isStripLoaded" fxLayout="column" fxLayoutGap="4px">
						<span class="input-label" i18n>Expiration*</span>
						<cls-stripe-card-expiry
							class="input-container"
							matStripe
							name="expiryModel"
							[ngClass]="{
								'error-border': isControlInvalid(expiry),
								'hover-border': !isControlInvalid(expiry),
								'focused-border': isFocus(expiry) && !isControlInvalid(expiry)
							}"
							[disabled]="disabledForm"
							ngDefaultControl
							[(ngModel)]="expiryModel"
							[required]="true"
							[matTooltip]="expiry?.error?.message"
							[matTooltipDisabled]="!expiry?.error?.message"
							#expiry></cls-stripe-card-expiry>
						<span *ngIf="expiry?.error?.message" class="error-message" i18n>
							{{ expiry?.error?.message }}
						</span>
					</div>

					<div class="cvc" *ngIf="isStripLoaded" fxLayout="column" fxLayoutGap="4px">
						<span class="input-label" i18n>CVC*</span>
						<cls-stripe-card-cvc
							class="input-container"
							[ngClass]="{
								'error-border': isControlInvalid(cvc),
								'hover-border': !isControlInvalid(cvc),
								'focused-border': isFocus(cvc) && !isControlInvalid(cvc)
							}"
							matStripe
							name="cvcModel"
							[disabled]="disabledForm"
							[(ngModel)]="cvcModel"
							ngDefaultControl
							[required]="true"
							[matTooltip]="cvc?.error?.message"
							[matTooltipDisabled]="!cvc?.error?.message"
							#cvc></cls-stripe-card-cvc>
						<span *ngIf="cvc?.error?.message" class="error-message" i18n>
							{{ cvc?.error?.message }}
						</span>
					</div>
				</div>
				<div class="cardholder" *ngIf="isStripLoaded" fxLayout="column" fxLayoutGap="4px">
					<span class="input-label" i18n>Cardholder Name*</span>
					<input
						class="input-container"
						matInput
						name="cardHolderModel"
						[ngClass]="{
							'error-border': !isCardHolderNameValid(),
							'hover-border': isCardHolderNameValid()
						}"
						type="text"
						[(ngModel)]="cardHolderModel"
						[disabled]="disabledForm"
						placeholder="Cardholder"
						matTooltip="Please provide name on card for billing purposes"
						[matTooltipDisabled]="cardHolder.valid"
						required
						value="Cardholder"
						#cardHolder />
					<span *ngIf="!isCardHolderNameValid()" class="error-message" i18n>
						Please provide name on card for billing purposes
					</span>
				</div>
			</div>
			<button style="display: none" #submitBtn type="submit"></button>
		</form>
		<div *ngIf="showUpdateBtn" [ngClass]="newDesign ? 'security-inf-new' : 'security-inf'">
			<div class="inf-text">
				<mat-icon class="lock-icon">lock</mat-icon>
				<span i18n>Your transaction is secure with SSL encyrption.</span>
			</div>
		</div>
		<cls-confirm-payment-btn
			*ngIf="showUpdateBtn"
			[showConfirmPaymentBtnSpinner]="showUpdateBtnSpinner"
			[currentPaymentStep]="currentPaymentStep"
			[updateCreditCardMode]="true"
			(confirmPayment)="valueChange(true)"></cls-confirm-payment-btn>
	</div>
</div>
