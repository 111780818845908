import { Component, Input, OnInit } from '@angular/core';
import { EClsBannerType } from './models/cls-page-banner.enum';

@Component({
	selector: 'cls-page-banner',
	templateUrl: './cls-page-banner.component.html',
	styleUrls: ['./cls-page-banner.component.scss'],
})
export class ClsPageBannerComponent implements OnInit {
	/**
	 * Passing the banner type will determin the backgroung color of the banner
	 */
	@Input() bannerType: EClsBannerType = EClsBannerType.Info;

	ebannerType = EClsBannerType;

	constructor() {}

	ngOnInit(): void {}
}

@Component({
	selector: 'cls-page-banner-icon',
	template: '<ng-content></ng-content>',
})
export class ClsPageBannerIcon {}

@Component({
	selector: 'cls-page-banner-description',
	template: '<ng-content></ng-content>',
})
export class ClsPageBannerDescription {}
