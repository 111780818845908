import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { ClsAlertsService } from '@ngx-common-v2/components/cls-alerts/services/cls-alerts.service';
import { BehaviorSubject } from 'rxjs';
import { EPaymentPlanType } from '../enums/payment-plan-type.enum';
import { EProductType } from '../enums/product-type.enums';
import { EWalletType } from '../enums/wallet-type.enum';
import { EPlatformProductType } from '../models/pricing.model';
import { IUserBalance } from '../models/user-balance.models';
import { httpFailRetry } from '../observable/http-fail-retry.pipes';
import { AuthService } from '../services/auth.service';
import { GA_EVENT_ACTIONS, GA_EVENT_CATEGORY, GA_EVENT_LABELS } from '../constants/google-analytics.consts';
import { GoogleAnalyticsService } from './google-analytics.service';
@Injectable({
	providedIn: 'root',
})
export class PaymentPlanService {
	private _userBalance$ = new BehaviorSubject<IUserBalance>(null);
	private _userSubscription: IUserSubscriptionState;

	get userBalance$() {
		return this._userBalance$;
	}
	get userBalance() {
		return this._userBalance$.value;
	}

	private _sharedAccount$ = new BehaviorSubject<ISharedAccountBalance>(null);

	get sharedAccount$() {
		return this._sharedAccount$;
	}

	get sharedAccount() {
		return this._sharedAccount$.value;
	}

	public get userSubscription() {
		return this._userSubscription;
	}

	set userSubscription(subs: IUserSubscriptionState) {
		this._userSubscription = subs;
	}

	constructor(
		private _http: HttpClient,
		private _authSvc: AuthService,
		private _clsAlertsSvc: ClsAlertsService,
		private _gaSvc: GoogleAnalyticsService
	) {}

	async updateUserBalance(removeSessionType = false, force = true, handleException = false) {
		try {
			this._userBalance$.next(null);
			const balance = await this._http
				.get<IUserBalance>(`${environment.apiUrl}/v1/account/balance?force=${force}`)
				.pipe(httpFailRetry())
				.toPromise();
			this._userBalance$.next(balance);
			return balance;
		} catch (error) {
			if (handleException) {
				this._clsAlertsSvc.showHttpResponseError(error);
			} else {
				throw error;
			}
		}
	}

	async getUserBalance(force = true): Promise<IUserBalance> {
		try {
			const productType = this._authSvc.getCurrentUserProductType();
			const balance = await this._http
				.get<IUserBalance>(`${environment.apiUrl}/v1/account/${productType}/balance?force=${force}`)
				.pipe(httpFailRetry())
				.toPromise();
			return balance;
		} catch (error) {
			throw error;
		}
	}

	async getAccountInfo(productType?: EProductType): Promise<IAccountInfo> {
		try {
			let _productType = productType;
			if (!productType) {
				_productType = this._authSvc.getCurrentUserProductType();
			}
			const accountType = await this._http
				.get<IAccountInfo>(`${environment.apiUrl}/v3/${productType}/account/info`)
				.pipe(httpFailRetry())
				.toPromise();
			return accountType;
		} catch (error) {
			throw error;
		}
	}

	//#region subscription
	async getSubscriptionState(productType: EProductType): Promise<IUserSubscriptionState> {
		try {
			if (!productType || productType == EProductType.Unknown) productType = this._authSvc.getCurrentUserProductType();
			const subs = await this._http
				.get<IUserSubscriptionState>(`${environment.apiUrl}/v1/account/${productType}/balance/subscription`)
				.pipe(httpFailRetry())
				.toPromise();
			this.userSubscription = subs;
			return this.userSubscription;
		} catch (error) {
			throw error;
		}
	}

	async cancelSubscription(productType: EProductType, forceChange) {
		try {
			return await this._http
				.patch(`${environment.apiUrl}/v1/account/${productType}/balance/subscription/cancel`, { forceChange })
				.toPromise();
		} catch (err) {
			throw err;
		}
	}

	async revertSubscriptionPlanChange(productType: EProductType) {
		try {
			return await this._http
				.patch(`${environment.apiUrl}/v1/account/${productType}/balance/subscription/revert-changes`, null)
				.toPromise();
		} catch (error) {
			throw error;
		}
	}

	async changeSubscriptionRefills(productType: EProductType, maxRefills: number) {
		try {
			if (maxRefills > 0) {
				this._gaSvc.onEventEmite(GA_EVENT_CATEGORY.UX, GA_EVENT_ACTIONS.AUTO_REFILL_ENABLED, GA_EVENT_LABELS.REFILL);
			}
			return await this._http
				.patch(`${environment.apiUrl}/v1/account/${productType}/balance/subscription/refills`, { maxRefills })
				.toPromise();
		} catch (err) {
			throw err;
		}
	}

	async unPauseSubscription() {
		var res = await this._http
			.patch(`${environment.apiUrl}/v1/account/platform/balance/subscription/unpause`, {})
			.toPromise();
		return res;
	}

	async pauseSubscription(pauseDurationInMonths: number) {
		var res = await this._http
			.patch(`${environment.apiUrl}/v1/account/platform/balance/subscription/pause`, { pauseDurationInMonths })
			.toPromise();
		setTimeout(async () => {
			await this.updateUserBalance();
		}, 1000);
		return res;
	}

	//#endregion subscription

	async getSharedAccount(): Promise<ISharedAccountBalance> {
		try {
			this._sharedAccount$.next(null);
			const sharedAccount = await this._http
				.get<ISharedAccountBalance>(`${environment.apiUrl}/v1/account/balance/shared-account`)
				.pipe<ISharedAccountBalance>(httpFailRetry())
				.toPromise();

			const user = this._authSvc.user();
			this._authSvc.setUserData({
				company: user.company,
				countryCode: user.countryCode,
				email: user.email,
				firstName: user.firstName,
				lastName: user.lastName,
				profileType: user.profileType,
				roles: user.roles,
				marketingEmails: user.marketingEmails,
				userKind: user.userKind,
				needToUpdatebillingAddress: false,
			});

			this._sharedAccount$.next(sharedAccount);
			return sharedAccount;
		} catch (error) {
			throw error;
		}
	}

	isTrailUser() {
		const balance = this._userBalance$.value;
		if (balance) {
			return balance.accountType === EPaymentPlanType.Trial;
		}
		return false;
	}

	isPrepaidUser() {
		const balance = this._userBalance$.value;
		if (balance) {
			return balance.accountType === EPaymentPlanType.Prepaid;
		}
		return false;
	}
}

export interface IAccountInfo {
	accountType: EPaymentPlanType;
}

export interface ISharedAccountBalance {
	allowPersonalProfiles: boolean;
	displayName: string;
	product: EWalletType;
	status: EGroupMemberStatus;
	department: string;
}

export enum EGroupMemberStatus {
	Active = 1,
	PaymentFailure = 2,
	Suspended = 3,
}

export interface IUserSubscriptionState {
	nextBilling: Date;
	creditCardLastDigits: number;
	billingStatus: ESubscriptionBillingStatus;
	refills: {
		max: number;
		current: number;
	};
	currentPlan: IUserSubscriptionPlan;
	nextPlan: {
		creditsPackage: number;
		displayName: string;
		planId: string;
		interval?: {
			months: number;
			days: number;
		};
	};
	pauseSubscription?: {
		isPaused: boolean;
		pausedUntil: string;
	};
	churnkeyDetails?: {
		subscriptionId: string;
		customerId: string;
		authHash: string;
		appId: string;
	};
}

export interface IUserSubscriptionPlan {
	creditsPackage: number;
	displayName: string;
	platformProductType?: EPlatformProductType;
	planId: string;
	interval: {
		months: number;
		days: number;
	};
	price: number;
}

export enum ESubscriptionBillingStatus {
	Healthy = 1,
	UnHealthy = 2,
	PaymentLockedOut = 3, // OBOSLETE
}
