import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from '@environment';
import { SIB_EVENTS } from '../constants/sendinblue-analytics.consts';
import { EDeploymentType } from '../enums/deployment-type.enum';
import { IItem, ITransaction } from './google-analytics.service';
import { SendInBlueAnalyticsService } from './send-in-blue-analytics.service';
import { UserService } from './user.service';
import { NgxCommonPagesConfig } from '@ngx-common/pages/pages.config';
import { ActivatedRoute } from '@angular/router';

declare let dataLayer: any[];

@Injectable({
	providedIn: 'root',
})
export class GoogleTagMangerService {
	constructor(
		@Inject(PLATFORM_ID) private platformId: object,
		private _userSvc: UserService,
		private _sendInBlueSvc: SendInBlueAnalyticsService,
		@Inject(NgxCommonPagesConfig.key) private _config: NgxCommonPagesConfig,
		private _activatedRoute: ActivatedRoute
	) {}

	public eventPurchase(item: IItem, transaction?: ITransaction) {
		if (isPlatformBrowser(this.platformId)) {
			if (environment.deploymentType != EDeploymentType.Production) return;
			dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
			dataLayer.push({
				event: 'purchase',
				ecommerce: {
					transaction_id: transaction?.transactionId,
					affiliation: 'Copyleaks',
					value: item?.price,
					tax: '0',
					currency: 'USD',
					tc: this._userSvc.getSignUpLeadName(),
					coupon: item.couponName ?? 'none',
					items: [
						{
							item_id: item.id,
							currency: 'USD',
							item_name: item.name,
							item_category: item.category,
							item_category2: item.list_name, // item.product name
							item_category3: this._getPurchaseSource(),
							price: item.price,
							quantity: '1',
							discount: item.discount,
						},
					],
				},
			});
			this._userSvc.removeSignUpLeadName();
			this._sendInBlueSvc.onTrackEventEmit(SIB_EVENTS.PURCHASED_ITEM, item, null, null, null, null);
		}
	}

	public eventSignup() {
		if (isPlatformBrowser(this.platformId)) {
			dataLayer.push({
				event: 'sign_up',
			});
		}
	}

	private _getPurchaseSource() {
		const redirectUrl = this._activatedRoute?.snapshot?.queryParams?.redirectUrl;
		if (redirectUrl && (redirectUrl as string).includes('ai-content-detector-extension-thank-you')) {
			return 5;
		}
		return this._config?.APP;
	}
}
