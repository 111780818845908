import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClsCommonResultsTemplateComponent } from './cls-common-results-template.component';
import { ClsPieChartModule } from '../../charts/cls-pie-chart/cls-pie-chart.module';
import { ClsExternalResultsTableModule } from '../../tables/cls-external-results-table/cls-external-results-table.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ClsSearchInputModule } from '../../../cls-search-input/cls-search-input.module';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
	declarations: [ClsCommonResultsTemplateComponent],
	imports: [
		CommonModule,
		ClsPieChartModule,
		ClsExternalResultsTableModule,
		FlexLayoutModule,
		ClsSearchInputModule,
		MatIconModule,
	],
	exports: [ClsCommonResultsTemplateComponent],
})
export class ClsCommonResultsTemplateModule {}
