import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserDetailsFormComponent } from './user-details-form.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SpinnerButtonModule } from '../spinner-button/spinner-button.module';
import { PasswordFormFieldModule } from '../password-form-field/password-form-field.module';
import { CalloutModule } from '../callout/callout.module';
import { RemoveWhiteSpaceDirectiveModule } from '../../directives/remove-white-space-directive/remove-white-space-directive.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { RadioButtonFormFieldModule } from '../radio-button-form-field/radio-button-form-field.module';

@NgModule({
	declarations: [UserDetailsFormComponent],
	exports: [UserDetailsFormComponent],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		TranslateModule,
		FlexLayoutModule,

		RadioButtonFormFieldModule,
		SpinnerButtonModule,
		PasswordFormFieldModule,
		CalloutModule,
		RemoveWhiteSpaceDirectiveModule,

		MatFormFieldModule,
		MatInputModule,
		MatSelectModule,
		MatRadioModule,
		MatIconModule,
		MatDialogModule,
		MatButtonModule,
		MatDividerModule,
		MatCheckboxModule,
		MatTooltipModule,
	],
})
export class UserDetailsFormModule {}
