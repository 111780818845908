<div class="dialog-container" fxLayout="column" fxLayoutGap="16px">
	<div fxLayout="row" fxLayoutAlign="space-between center">
		<div fxLayout="row" fxLayoutAlign="start center">
			<div class="share-icon-container">
				<ngx-avatar
					[gravatarId]="dialogData.metaData.handledBy"
					[name]="dialogData.metaData.handledBy"
					[size]="60"></ngx-avatar>
			</div>
			<div fxLayout="column">
				<span class="invite-msg" i18n>
					{{ dialogData.metaData.handledBy }} shared {{ dialogData.metaData.sharedItems.length }} items with you.
				</span>
				<span class="invite-date">{{ dialogData.creationTime | date: 'd/M/yyyy' }}</span>
			</div>
		</div>
		<button mat-icon-button class="close-dialog-button" (click)="closeDialog()">
			<mat-icon>clear</mat-icon>
		</button>
	</div>
	<div fxLayout="column" fxLayoutGap="8px">
		<span class="shared-items-list-title" i18n>Review all items:</span>
		<div class="shared-items-list" fxLayoutGap="16px" fxLayout="column">
			<ng-container *ngIf="supportedFileTypes$ | async as supportedFileTypes; else supportedFileTypesRef">
				<app-shared-item
					*ngFor="let item of dialogData.metaData.sharedItems"
					[data]="item"
					[supportedFileTypes]="supportedFileTypes"></app-shared-item>
			</ng-container>
			<ng-template #supportedFileTypesRef>
				<div style="overflow: hidden" fxLayoutAlign="center center">
					<cls-spinner></cls-spinner>
				</div>
			</ng-template>
		</div>
	</div>
</div>
