import { Component, Input, OnInit } from '@angular/core';
import { IAnalyticsExternalResults } from '../../models/cls-external-results-table.models';

@Component({
	selector: 'cls-pie-chart',
	templateUrl: './cls-pie-chart.component.html',
	styleUrls: ['./cls-pie-chart.component.scss'],
})
export class ClsPieChartComponent implements OnInit {
	/**
	 * The data source of the pie chart.
	 */
	@Input() dataSrc: IAnalyticsExternalResults[];

	/**
	 * pie chart title
	 * @type {string}
	 * @Input
	 */
	@Input() pieChartTitle: string = $localize`Top 10 Internet Sources`;
	chartData: IAnalyticsExternalResults[];

	/**
	 * The total "Times Found" of all resources.
	 */
	totalSum = 0;

	isEmpty = false;

	@Input() colorArray = [
		'#0B163E', // First color value
		'#052D8B', // Second color value
		'#06417C', // Third color value
		'#0A61B9', // Fourth color value
		'#0F7AE5', // Fifth color value
		'#3F9AF5', // Sixth color value
		'#6BB1F7', // Seventh color value
		'#8CC3F9', // Eight color value
		'#ADD4FB', // nineth color value
		'#C9E2FC', // Tenth color value
	];

	colorScheme = {
		domain: this.colorArray,
	};

	constructor() {}

	ngOnInit(): void {
		this.totalSum = this.dataSrc.reduce((accumulator, result) => accumulator + result.value, 0);
		let totalSources = this.dataSrc.length;
		// Empty state -> set one dummy result
		if (this.dataSrc.length === 0) {
			this.chartData = [{ name: $localize`No Results`, value: 1 }];
			this.isEmpty = true;
			this.colorScheme = {
				domain: ['#C9E2FC'],
			};
			return;
		}
		// More than 10
		if (this.dataSrc.length > 10) {
			const sumOther = this.dataSrc
				.slice(9, this.dataSrc.length)
				.reduce((accumulator, result) => accumulator + result.value, 0);
			this.dataSrc = this.dataSrc.slice(0, 9);
			this.dataSrc.push({
				name: this.foramtOthersNumber(totalSources - 9) + $localize` others`,
				value: sumOther,
			});
		}

		this.chartData = this.dataSrc;
	}
	/**
	 * Form number of "others" to be with k or m.
	 */
	foramtOthersNumber(othersNum: number) {
		// M format
		if (othersNum > 999_999) {
			return Math.trunc(othersNum / 1_000_000) + 'M';
		}
		//K format
		if (othersNum > 999) {
			return Math.trunc(othersNum / 1_000) + 'K';
		}
		return othersNum;
	}
}
