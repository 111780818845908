import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AvatarModule } from 'ngx-avatar';
import { SpinnerModule } from '../../../components/spinner/spinner.module';
import { DateFromNowPipeModule } from '../../../pipes/date-from-now/date-from-now-pipe.module';
import { EllipsisPipeModule } from '../../../pipes/ellipsis/ellipsis-pipe.module';
import { NotificationItemRecurrenceUpdateComponent } from './notification-item-recurrence-update/notification-item-recurrence-update.component';
import { NotificationRecurrenceItemsDialogComponent } from './recurrence-update-items-dialog/notification-recurrence-items-dialog.component';
import { NotificationRecurrenceItemComponent } from './recurrence-update-items-dialog/recurrence-update-item/notification-recurrence-item.component';

@NgModule({
	declarations: [
		NotificationItemRecurrenceUpdateComponent,
		NotificationRecurrenceItemsDialogComponent,
		NotificationRecurrenceItemComponent,
	],
	exports: [NotificationItemRecurrenceUpdateComponent],
	imports: [
		CommonModule,
		FlexLayoutModule,

		AvatarModule,
		DateFromNowPipeModule,
		EllipsisPipeModule,
		SpinnerModule,

		MatIconModule,
		MatButtonModule,
		MatDialogModule,
		MatTooltipModule,
		MatBadgeModule,
	],
})
export class NotificationItemRecurrenceUpdateModule {}
