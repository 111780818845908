import { Component, Input } from '@angular/core';

@Component({
	selector: 'cls-panel',
	templateUrl: './panel.component.html',
	styleUrls: ['./panel.component.scss'],
})
export class PanelComponent {
	@Input() margin = '4px';
	@Input() height;
	@Input() padding = '30px';
	@Input() backgroundColor = 'white';
}

@Component({
	selector: 'cls-panel-header',
	template: '<ng-content></ng-content>',
})
// tslint:disable-next-line: component-class-suffix
export class PanelHeader {}

@Component({
	selector: 'cls-panel-actions',
	template: '<ng-content></ng-content>',
})
// tslint:disable-next-line: component-class-suffix
export class PanelActions {}

@Component({
	selector: 'cls-panel-body',
	template: '<ng-content></ng-content>',
})
// tslint:disable-next-line: component-class-suffix
export class PanelBody {}
