import { EPathElementGeneralType } from '../../enums/path-element-general-type.enum';

export interface IPersonalNotification {
	id?: string;
	message: string;
	linkAddress?: string;
	creationTime: Date;
	isCanceled?: boolean;
	expireTime: Date;
	isRead: boolean;
	messageType: ENotificationType;
}

export interface IPersonalNotificationTask extends IPersonalNotification {
	metaData?: IElementTaskMetaData;
}

export interface IElementSharePersonalNotification extends IPersonalNotification {
	metaData: {
		handledBy: string;
		participantName: string;
		sharedItems: ISharedElementData[];
	};
}

export interface IElementsRecurrenceUpdateNotification extends IPersonalNotification {
	metaData: {
		updatedBy: string;
		updatedItems: IRecurrenceUpdateElementData[];
		frequencyPeriod: number;
		frequencyType: number;
	};
}

export interface IRecurrenceUpdateElementData {
	elementId: string;
	displayName: string;
	type: EPathElementGeneralType;
}

export interface IElementTaskMetaData {
	selectedIds?: string[];
	status: ETaskElementStatus;
	totalFiles?: number;
	totalFolders?: number;
	fileName?: string;
	result?: any;
	ticketId?: string;
	progress?: number;
}

export interface ISharedElementData {
	elementId: string;
	displayName: string;
	originalName: string;
	type: EPathElementGeneralType;
}

export interface IElementsSummaryResponseModel {
	totalUsedCredits: number;
	totalInProgressFiles: number;
	totalCompletedFiles: number;
	totalDownloadedFiles: number;
	totalErrorFiles: number;
	totalIndexedFiles: number;
	totalRescanLimitReachedFiles: number;
	totalFilesWithUnknownProfile: number;
	totalFilesWithNoSuccessfulSnapshots: number;
	totalFolders: number;
	isMultipleOwners: boolean;
	totalDownloadableFiles: number;
	status: ETaskElementStatus;
	errorMessage: string;
}

export interface IDeleteElementUpdateNotification {
	notificationId: string;
	progress: number;
	status: ETaskElementStatus;
	errorMessage: any;
	ticketId?: string;
}

export interface IBatchDownloadUpdateNotification {
	notificationId: string;
	totalFiles?: number;
	progress?: number;
	status: ETaskElementStatus;
	errorMessage: any;
	ticketId?: string;
}

export interface IUsersNotificationsClearReadHistory {
	lastClear: Date;
	lastRead: Date;
}

export enum ETaskElementStatus {
	Running,
	Done,
	Failed,
	Canceled,
}

export interface IBatchIndexDocumentsUpdateNotification {
	notificationId: string;
	progress?: number;
	status: ETaskElementStatus;
	errorMessage: any;
	ticketId?: string;
	result?: any;
}

export enum ENotificationType {
	custom = 0,
	copyleaksFeature = 1,
	scanComplete = 2,
	systemStatus = 3,
	payment = 4,
	salesPromotion = 5,
	deleteElements = 6,
	downloadReport = 7,
	share = 8,
	transferElements = 9,
	recurrenceUpdate = 10,
	general = 11,
	indexDocuments = 12,
}
export interface ICustomWebNotification {
	imageURL: string;
}
