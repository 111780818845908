<div class="info-card-container" fxLayout="column" fxLayoutAlign="center center" [ngStyle]="cardStyle">
	<div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="8px" fxFlex>
		<ng-container *ngIf="!hideIconContainer">
			<div
				*ngIf="!isLoading; else loadingIconView"
				[class]="showIcon ? 'info-card-icon-container' : 'info-card-title-container'">
				<ng-content select="mat-icon"></ng-content>
			</div>
			<ng-template #loadingIconView>
				<ngx-skeleton-loader
					class="icon-loader"
					[theme]="{
						'border-radius': '50%',
						'background-color': 'rgba(17, 41, 96, 0.3)'
					}"
					animation="progress"
					appearance="line"></ngx-skeleton-loader>
			</ng-template>
		</ng-container>

		<div class="info-card-content-container" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="4px">
			<ng-content *ngIf="!isLoading; else loadingTitleView" select="cls-info-card-title"></ng-content>
			<ng-template #loadingTitleView>
				<ngx-skeleton-loader
					class="title-loader"
					[theme]="{
						'border-radius': '30px',
						'background-color': 'rgba(17, 41, 96, 0.3)'
					}"
					animation="progress"
					appearance="line"></ngx-skeleton-loader>
			</ng-template>

			<ng-content *ngIf="!isLoading; else loadingDescriptionView" select="cls-info-card-description"></ng-content>
			<ng-template #loadingDescriptionView>
				<ngx-skeleton-loader
					class="description-loader"
					[theme]="{
						'border-radius': '30px',
						'background-color': 'rgba(17, 41, 96, 0.3)'
					}"
					animation="progress"
					appearance="line"></ngx-skeleton-loader>
			</ng-template>
		</div>
	</div>
	<div *ngIf="!isLoading" class="info-card-footer-container">
		<ng-content select="cls-info-card-footer"></ng-content>
	</div>
</div>
