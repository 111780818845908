<div
	class="results-overview-container"
	fxLayout="column"
	fxLayoutGap="12px"
	[style]="{ 'max-width': maxWidth ? maxWidth : '' }">
	<div fxLayout="row" fxLayoutGap="8px">
		<h3 class="results-header-text" i18n>Plagiarism Results Overview</h3>
		<mat-icon class="info-icon" i18n-matTooltip matTooltip="See where the results came from.">info_outline</mat-icon>
	</div>

	<div class="chart-and-results-container" fxLayout="row warp" fxLayoutAlign="start center" fxLayoutGap="32px">
		<ngx-charts-pie-chart
			[scheme]="colorScheme"
			[results]="chartData"
			[legend]="false"
			[doughnut]="true"
			[view]="[180, 180]">
			<ng-template #tooltipTemplate let-model="model">
				<div>{{ model.name }} {{ isEmpty ? '' : '- ' + model.value }}</div>
			</ng-template>
		</ngx-charts-pie-chart>

		<ng-container *ngFor="let item of resultItems">
			<div fxFlex class="result-item-container" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="2px">
				<div class="result-item-icon-container" fxLayoutAlign="center center">
					<ng-container [ngSwitch]="item.type">
						<mat-icon class="result-type-icon" *ngSwitchCase="eResultsType.Internet">language</mat-icon>
						<mat-icon *ngSwitchCase="eResultsType.BetweenStudents" class="result-type-icon">compare_arrows</mat-icon>
						<mat-icon *ngSwitchCase="eResultsType.InternalDataBase">
							<img
								class="result-type-img result-type-internal-db-img"
								alt="Copyleaks-Internal-Database"
								src="../../../../assets/images/icons/cls_internal_db.svg" />
						</mat-icon>
						<mat-icon *ngSwitchCase="eResultsType.Repositories">
							<img class="result-type-img" alt="Repositories" src="../../../../assets/images/icons/Repository.svg" />
						</mat-icon>
					</ng-container>
				</div>
				<div class="result-type-name">
					{{ item.resultTypetName }}
				</div>
				<div class="result-type-name" [matTooltip]="item.amount">
					<ng-container *ngIf="totalResults">{{ (item.amount / totalResults) * 100 | number: '1.0-0' }} %</ng-container>
					<ng-container *ngIf="!totalResults">0%</ng-container>
				</div>
			</div>
		</ng-container>
	</div>
</div>
