import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { WindowService } from '../../../../../services/window.service';
import { ELinkEventModelTarget, ELinkEventModelType } from '../../models/notification-item-general.enums';
import { ILinkEventModel } from '../../models/notification-item-general.models';

@Component({
	selector: 'cls-notification-item-general-link',
	templateUrl: './notification-item-general-link.component.html',
	styleUrls: ['./notification-item-general-link.component.scss'],
})
export class NotificationItemGeneralLinkComponent implements OnInit {
	@HostListener('click', ['$event.target'])
	onClick() {
		// navigate to the specified link
		this._notificationClickHandler();
	}

	@Input() linkData: ILinkEventModel;
	@Input() title: string;
	@Input() description: string;
	@Input() matIcon: string;
	@Input() isRead: boolean;

	constructor(private _windowSvc: WindowService, private _router: Router, private _route: ActivatedRoute) {}

	ngOnInit(): void {}

	private _notificationClickHandler() {
		if (!this.linkData?.value) return;
		if (this.linkData?.type === ELinkEventModelType.Internal) {
			this._router.navigate([this.linkData?.value], { relativeTo: this._route });
			return;
		}
		const linkTarget = this.linkData?.target === ELinkEventModelTarget.Blank ? '_blank' : '_self';
		this._windowSvc.open(this.linkData.value, linkTarget);
	}
}
