<div class="content-wrapper">
	<div class="loading-preview-container">
		<h2 i18n="@@PAYMENT_PROCESSING.TITLE" class="title">Please wait</h2>
		<span i18n="@@PAYMENT_PROCESSING.DESCRIPTION" class="description">
			An invoice is being sent to your email...
			<br />
			It typically takes a few seconds
		</span>
		<mat-progress-bar mode="indeterminate" class="navigation-progress-bar" value="60"></mat-progress-bar>
	</div>
</div>
