import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicBreadcrumbNavigationComponent } from './dynamic-breadcrumb-navigation.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { EllipsisPipeModule } from '../../pipes/ellipsis/ellipsis-pipe.module';
import { MatMenuModule } from '@angular/material/menu';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
	declarations: [DynamicBreadcrumbNavigationComponent],
	exports: [DynamicBreadcrumbNavigationComponent],
	imports: [
		CommonModule,
		RouterModule,
		FlexLayoutModule,

		TranslateModule,
		NgxSkeletonLoaderModule,
		MatIconModule,
		EllipsisPipeModule,

		MatButtonModule,
		MatTooltipModule,
		MatIconModule,
		MatMenuModule,
	],
})
export class DynamicBreadcrumbNavigationModule {}
