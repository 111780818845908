import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ClsAlertsService } from '@ngx-common-v2/components/cls-alerts/services/cls-alerts.service';
import { CodeInputComponent } from 'angular-code-input';
import { timer } from 'rxjs';
import { take } from 'rxjs/operators';
import { EAPIErrorCodes } from '../../../../enums/error-codes.enum';
import { HttpStatusCode } from '../../../../enums/http-status-code.enum';
import { ITranslation } from '../../../../interfaces/interfaces.shared';
import { IAPICustomErrors } from '../../../../models/api-custom-errors.model';
import { ConfirmAccountRes } from '../../../../models/auth.model';
import { CLSTranslateService } from '../../../../services/translate.service';
import { UserService } from '../../../../services/user.service';
@UntilDestroy()
@Component({
	selector: 'app-verify-email-by-code',
	templateUrl: './verify-email-by-code.component.html',
	styleUrls: ['./verify-email-by-code.component.scss'],
})
export class VerifyEmailByCodeComponent implements OnInit, OnDestroy {
	@Input() codeErrorTxt: string;
	@Input() email: string;
	@Output() verificationProcessComplete = new EventEmitter<ConfirmAccountRes>();
	@Output() back = new EventEmitter<string[]>();
	@ViewChild('codeInput') codeInput: CodeInputComponent;

	showSubmitSpinner = false;
	code = '';
	clickBeforeComplete = false;
	showResendSpinner = false;
	afterCodeResend = false;

	resendActivationSeconds = 60;
	translations: ITranslation;
	constructor(
		private _userSvc: UserService,
		private _clsAlertsSvc: ClsAlertsService,
		private _translate: CLSTranslateService
	) {}

	async ngOnInit() {
		this._initTranslations();
		setTimeout(() => {
			this.codeInput.focusOnField(0);
		}, 100);
		const delay = this.resendActivationSeconds;

		timer(1000, 1000)
			.pipe(take(delay), untilDestroyed(this))
			.subscribe(() => {
				this.resendActivationSeconds--;
			});
	}

	private async _initTranslations() {
		this.translations = await this._translate.getAsync([
			'NGX.USER_REGISTER_DIALOG.CANCEL',
			'NGX.USER_REGISTER_DIALOG.CONTINUE',
			'NGX.USER_REGISTER_DIALOG.SEND_ACTIVATION_MAIL',
		]);
	}

	testKey() {
		console.log(123);
	}

	onCodeChanged(code: string) {
		if (this.clickBeforeComplete) {
			this.clickBeforeComplete = false;
		}
		if (this.codeErrorTxt) {
			this.codeErrorTxt = null;
		}
		this.code = code;
		if (this.code.length == 6) {
			this.codeInput.focusOnField(5);
		}
	}

	async resendActivationCode() {
		try {
			this.showResendSpinner = true;
			await this._userSvc.resendActivationCode({
				email: this.email,
			});
			this.afterCodeResend = true;
		} catch (ex) {
			this._clsAlertsSvc.showHttpResponseError(ex);
		} finally {
			this.showResendSpinner = false;
		}
	}

	async onCodeCompleted(code: string) {
		try {
			this.showSubmitSpinner = true;
			const emailConfirmRes = await this._userSvc.verifyEmailWithCodeAsync(code, this.email);
			this.verificationProcessComplete.emit(emailConfirmRes);
		} catch (ex) {
			const _ex: HttpErrorResponse = ex;
			if (_ex.status === HttpStatusCode.BAD_REQUEST) {
				const errorBody = _ex.error as IAPICustomErrors;
				if (errorBody && errorBody.code) {
					this.code = '';
					switch (errorBody.code) {
						case EAPIErrorCodes.VerifyCodeError:
							this.codeErrorTxt = errorBody.description;
							this.code = '';
							this.codeInput.focusOnField(0);
							break;
						default:
							this._clsAlertsSvc.showHttpResponseError(_ex);
					}
				}
			} else {
				if (_ex.error) {
					this._clsAlertsSvc.showHttpResponseError(_ex);
				}
			}
		} finally {
			this.showSubmitSpinner = false;
		}
	}

	emitBack() {
		this.back.emit();
	}

	verify() {
		if (this.code.length < 6) {
			this.clickBeforeComplete = true;
		} else {
			this.onCodeCompleted(this.code);
		}
	}

	ngOnDestroy() {}
}
