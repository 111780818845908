<ng-container [ngSwitch]="model?.metaData?.status">
	<div class="task-icon-container">
		<ng-container *ngSwitchCase="eTaskElementStatus.Running">
			<div class="running-icon">
				<div class="percentage" *ngIf="progress > 0">{{ progress }}%</div>
				<mat-icon class="stop-download" matTooltip="Cancel Download" (click)="cancelDownload()" *ngIf="allowCancel">
					close
				</mat-icon>
				<cls-spinner width="40px"></cls-spinner>
			</div>
		</ng-container>
		<ng-container *ngSwitchCase="eTaskElementStatus.Failed">
			<div class="unread-Notification-badge">
				<div
					class="failed-icon"
					[matBadge]="!model?.isRead && !isBackgroundTask ? '1' : ''"
					matBadgePosition="above before"
					matBadgeColor="peach"
					MatBadgeSize="small">
					<mat-icon>cancel</mat-icon>
				</div>
			</div>
		</ng-container>
		<ng-container *ngSwitchCase="eTaskElementStatus.Done">
			<div class="unread-Notification-badge">
				<div
					class="done-icon"
					[ngClass]="{ 'done-icon-hover': isHovering }"
					[matBadge]="!model?.isRead && !isBackgroundTask ? '1' : ''"
					matBadgePosition="above before"
					matBadgeColor="peach"
					MatBadgeSize="small">
					<mat-icon *ngIf="!isHovering">check_circle</mat-icon>
					<mat-icon *ngIf="isHovering">file_download</mat-icon>
				</div>
			</div>
		</ng-container>
		<ng-container *ngSwitchDefault></ng-container>
	</div>
</ng-container>

<div class="message-contianer">
	<div class="title">
		<ng-container [ngSwitch]="model?.metaData?.status">
			<ng-container *ngSwitchCase="eTaskElementStatus.Running">
				<ng-container [ngTemplateOutlet]="runningText"></ng-container>
			</ng-container>
			<!--  -->
			<ng-container *ngSwitchCase="eTaskElementStatus.Failed">
				<ng-container [ngTemplateOutlet]="failedText"></ng-container>
			</ng-container>
			<!--  -->
			<ng-container *ngSwitchCase="eTaskElementStatus.Done">
				<ng-container [ngTemplateOutlet]="doneText"></ng-container>
			</ng-container>
			<!--  -->
		</ng-container>
	</div>
	<div class="description">
		{{ model.creationTime | dateFromNow: (model?.metaData?.status == eTaskElementStatus.Running ? 'Started' : '') }}
	</div>
</div>

<!-- Running template text  -->
<ng-template #runningText>
	<!-- One file -->
	<ng-container *ngIf="model?.metaData?.totalFiles == 1 && model?.metaData?.fileName">
		<ng-container i18n>Exporting&nbsp;</ng-container>
		<span [matTooltip]="model?.metaData?.fileName">'{{ model?.metaData?.fileName | ellipsis: -30 }}'</span>
		<ng-container i18n>&nbsp;report</ng-container>
		...
	</ng-container>
	<!-- One file with folders -->
	<ng-container
		*ngIf="model?.metaData?.totalFiles == 1 && !model?.metaData?.fileName"
		i18n="@@NGX.NOTIFICATION_DOWNLOAD_ITEM.RUNNING_ONE_ELEMENT_WITH_FOLDERS">
		Exporting {{ model?.metaData?.totalFiles }} report as .zip
	</ng-container>
	<!-- Many files -->
	<ng-container *ngIf="model?.metaData?.totalFiles > 1" i18n="@@NGX.NOTIFICATION_DOWNLOAD_ITEM.RUNNING_MULTIPLE_FILES">
		Exporting {{ model?.metaData?.totalFiles }} reports as .zip
	</ng-container>
	<!--  -->
</ng-template>

<!-- done template text  -->
<ng-template #doneText>
	<!-- One file -->
	<ng-container *ngIf="model?.metaData?.totalFiles == 1 && model?.metaData?.fileName">
		<span [matTooltip]="model?.metaData?.fileName">'{{ model?.metaData?.fileName | ellipsis: 20 }}'</span>
		<ng-container i18n>&nbsp;report export completed</ng-container>
	</ng-container>
	<!-- One file -->
	<ng-container
		*ngIf="model?.metaData?.totalFiles == 1 && !model?.metaData?.fileName"
		i18n="@@NGX.NOTIFICATION_DOWNLOAD_ITEM.DONE_ONE_ELEMENT_WITH_FOLDER">
		{{ model?.metaData?.totalFiles }} report export completed
	</ng-container>
	<!-- Many files -->
	<ng-container *ngIf="model?.metaData?.totalFiles > 1" i18n="@@NGX.NOTIFICATION_DOWNLOAD_ITEM.DONE_FILES">
		{{ model?.metaData?.totalFiles }} reports export completed
	</ng-container>
	<!--  -->
</ng-template>

<!-- failed template text  -->
<ng-template #failedText>
	<!-- 1 file or 1 folder -->
	<ng-container *ngIf="model?.metaData?.totalFiles == 1">
		<span [matTooltip]="model?.metaData?.fileName">'{{ model?.metaData?.fileName | ellipsis: 30 }}'</span>
		<ng-container i18n>&nbsp;report failed to export!</ng-container>
	</ng-container>
	<!-- files only -->
	<ng-container *ngIf="model?.metaData?.totalFiles > 1" i18n="@@NGX.NOTIFICATION_DOWNLOAD_ITEM.FAILED_FILES">
		{{ model?.metaData?.totalFiles }} reports failed to export
	</ng-container>
	<!--  -->
</ng-template>
