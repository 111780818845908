<div class="main-container" fxLayout="column" fxLayoutGap="16px">
	<div fxLayout="row" fxLayoutGap="16px" class="row-height">
		<div class="empty-item-style" fxLayout="column" fxLayoutAlign="center center">
			<ng-content select="cls-empty-state-secondary-text"></ng-content>
			<ng-content select="cls-empty-state-primary-text"></ng-content>
			<ng-content select="cls-empty-state-content"></ng-content>
		</div>

		<!-- small containers -->
		<div fxLayout="row" fxLayoutGap="16px">
			<!-- big -->
			<div class="empty-item-style" style="width: 45%"></div>
			<!-- smaller -->
			<div fxLayout="column" fxLayoutGap="16px">
				<div fxLayout="row" fxLayoutGap="16px">
					<div class="empty-item-style"></div>
					<div class="empty-item-style"></div>
				</div>

				<div fxLayout="row" fxLayoutGap="16px">
					<div class="empty-item-style"></div>
					<div class="empty-item-style"></div>
				</div>
			</div>
		</div>
	</div>

	<div fxLayout="row" fxLayoutGap="16px" class="row-height">
		<div class="empty-item-style"></div>
		<div class="empty-item-style"></div>
	</div>
	<div fxLayout="row" fxLayoutGap="16px" class="row-height">
		<div class="empty-item-style"></div>
		<div class="empty-item-style"></div>
	</div>
</div>
