import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
	ClsInfoCardComponent,
	ClsInfoCardDescriptionComponent,
	ClsInfoCardFooterComponent,
	ClsInfoCardTitleComponent,
} from './cls-info-card.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@NgModule({
	declarations: [
		ClsInfoCardComponent,
		ClsInfoCardDescriptionComponent,
		ClsInfoCardTitleComponent,
		ClsInfoCardFooterComponent,
	],
	imports: [CommonModule, FlexLayoutModule, NgxSkeletonLoaderModule],
	exports: [
		ClsInfoCardComponent,
		ClsInfoCardTitleComponent,
		ClsInfoCardDescriptionComponent,
		ClsInfoCardFooterComponent,
	],
})
export class ClsInfoCardModule {}
