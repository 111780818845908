import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClsExternalResultsTableComponent } from './cls-external-results-table.component';
import { MatTableModule } from '@angular/material/table';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { ClsPaginatorModule } from '../../../cls-paginator/cls-paginator.module';
import { ClsContainedContentModule } from '../../../../components/cls-contained-content/cls-contained-content.module';
import { ClsSearchInputModule } from '../../../cls-search-input/cls-search-input.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
	declarations: [ClsExternalResultsTableComponent],
	imports: [
		CommonModule,
		MatTableModule,
		FlexLayoutModule,
		MatIconModule,
		ClsPaginatorModule,
		ClsContainedContentModule,
		ClsSearchInputModule,
		MatTooltipModule,
		MatButtonModule,
	],
	exports: [ClsExternalResultsTableComponent],
})
export class ClsExternalResultsTableModule {}
