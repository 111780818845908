import { environment } from '@environment';
import { maxBy } from 'lodash-es';
import { EXTENSIONS_WITH_ICONS } from '../constants';
import { EPathElementGeneralType } from '../enums/path-element-general-type.enum';
import { EPathElementType } from '../enums/path-element-type.enum';
import { ENotificationType, ETaskElementStatus } from '../notifications/models/web-notifications.models';
import { ProgressPersonalNotificationsService } from '../notifications/services/progress-personal-notifications.service';
import { EElementShareRole } from './elements-share.models';
import {
	EElementScanStatus,
	EFrequencyTypes,
	IElementsScheduling,
	IFolderScheduledSegmentSettings,
	IFolderSegment,
	IPathElement,
	IScanSnapshot,
} from './elements.models';

export class UserScanData {
	public id?: string;
	public scanId?: string;
	public parentId?: string;
	public name?: string;
	public date?: Date;
	public expirationDate?: Date;
	public score?: number;
	public aiCoverage?: number;
	public type?: EPathElementType;
	public status?: EElementScanStatus;
	public errorMessage?: string;
	public generalType?: EPathElementGeneralType;
	public rowType?: EUserScansRowType;
	public icon?: string;
	public progressBarClass?: string;
	public aiProgressBarClass?: string;
	public scanSnapshots?: IScanSnapshot[];
	public isRescanning?: boolean;
	public allowRescan?: boolean;
	public waitingForNewSnapshot?: boolean;
	public isDisabled?: boolean;
	public isDownloading?: boolean;
	public isLoadingRecurrenceSettings?: boolean;
	public isShared?: boolean;
	public isSharedWithTeam?: boolean;
	public role?: EElementShareRole;
	public snapshotDiffPercent?: number;
	public elementsScheduling?: IElementsScheduling;
	public recurringFailures?: number;

	constructor(
		element?: IPathElement,
		progressNotificationsSvc?: ProgressPersonalNotificationsService,
		supportedOcrFilesMimeTypes?: string[],
		supportedRegularFilesMimeTypes?: string[],
		supportedCodeFilesMimeTypes?: string[]
	) {
		if (
			element &&
			progressNotificationsSvc &&
			supportedOcrFilesMimeTypes &&
			supportedRegularFilesMimeTypes &&
			supportedCodeFilesMimeTypes
		) {
			const scanSnapshots = element.scanSnapshots?.map(s => ({ ...s, createDate: new Date(s.createDate) }));
			const lastScanSnapshot = scanSnapshots ? maxBy(scanSnapshots, 'createDate') : null;

			// update rescanning flags
			let { isRescanning, allowRescan } = UserScanData._checkRescanningOption(element);

			this.date = lastScanSnapshot ? lastScanSnapshot.createDate : element.dateCreated;
			this.expirationDate = element.expirationDate;
			this.id = element.id;
			this.scanId = element?.id;
			this.parentId = element.parentId;
			this.name = element.name;
			this.score = lastScanSnapshot ? lastScanSnapshot.score : null;
			this.aiCoverage = lastScanSnapshot ? lastScanSnapshot.aiCoverage : null;
			this.type = element.type;
			this.rowType = EUserScansRowType.element;
			this.status = lastScanSnapshot?.status;
			this.errorMessage = lastScanSnapshot?.errorMessage;
			this.generalType = element.generalType;
			this.icon = UserScanData.getItemIcon(
				element,
				supportedOcrFilesMimeTypes,
				supportedRegularFilesMimeTypes,
				supportedCodeFilesMimeTypes
			);
			this.progressBarClass = UserScanData.getScoreProgressBarClass(lastScanSnapshot?.score);
			this.aiProgressBarClass = UserScanData.getScoreProgressBarClass(lastScanSnapshot?.aiCoverage);
			this.scanSnapshots = element.scanSnapshots;
			this.isRescanning = isRescanning;
			this.allowRescan = allowRescan;
			this.waitingForNewSnapshot = false;
			this.isDisabled = element.isDisabled;
			this.isDownloading = UserScanData.isElementDownloading(element.id, progressNotificationsSvc);
			this.isShared = element.isShared;
			this.isSharedWithTeam = element.isSharedWithTeam;
			this.role = element.role;
			this.elementsScheduling = element.elementsScheduling;
			this.recurringFailures = element.recurringFailures;
			this.snapshotDiffPercent = UserScanData.calculateSnapshotDiff(element);
		}
	}

	static generateInstanceForNewFolder() {
		var instance = new UserScanData();
		instance.rowType = EUserScansRowType.editing;
		instance.role = EElementShareRole.OWNER;
		return instance;
	}

	static isElementDownloading(id: string, progressNotificationsSvc: ProgressPersonalNotificationsService) {
		for (const notification of progressNotificationsSvc.notifications) {
			if (
				notification.messageType == ENotificationType.downloadReport &&
				notification?.metaData?.status == ETaskElementStatus.Running &&
				notification?.metaData?.selectedIds?.includes(id)
			) {
				return true;
			}
		}
		return false;
	}

	static getScoreProgressBarClass(score: number) {
		if (score < 10) {
			return 'mat-progress-bar-success';
		}
		if (score < 50) {
			return 'mat-progress-bar-warn';
		}
		return 'mat-progress-bar-danger';
	}

	static _checkRescanningOption(element: IPathElement) {
		const scanSnapshots = element.scanSnapshots?.map(s => ({ ...s, createDate: new Date(s.createDate) }));
		const lastScanSnapshot = scanSnapshots ? maxBy(scanSnapshots, 'createDate') : null;

		let isRescanning = false;
		let allowRescan = true;

		// don't allow index scan type to rescan
		if (element.generalType === EPathElementGeneralType.Index) {
			allowRescan = false;
		} else {
			if (lastScanSnapshot?.status === EElementScanStatus.new) {
				allowRescan = false; // don't allow rescanning if the last snapshot still new
				/* if the status is new and the snapshots are more than one
				then this scan status is "Rescanning" */
				isRescanning = element.scanSnapshots?.length > 1 ? true : false;
			}
			const todayScansNum = this.countTodayScans(scanSnapshots);
			// if the num of todays scan exceeds the maxRescanDaily then disable the rescan option
			if (todayScansNum >= environment.maxRescanDaily) {
				allowRescan = false;
			}
			// also if all the snapshots failed, don't allow to rescan
			if (scanSnapshots && this.isAllSnapshotsFailed(scanSnapshots)) {
				allowRescan = false;
			}
		}
		return { allowRescan, isRescanning };
	}

	static getRecurrenceSettingAsString(element: UserScanData, inheritedScheduling: IFolderScheduledSegmentSettings) {
		var recurrenceSettings: string;
		if (element.elementsScheduling) {
			if (element.elementsScheduling.frequencyType == EFrequencyTypes.Never) {
				recurrenceSettings = $localize`Don't repeat`;
			} else if (element.elementsScheduling.frequencyType == EFrequencyTypes.Days) {
				recurrenceSettings =
					`${element.elementsScheduling?.frequencyPeriod}` +
					' ' +
					(element.elementsScheduling?.frequencyPeriod == 1 ? $localize`Day` : $localize`Days`);
			} else if (element.elementsScheduling.frequencyType == EFrequencyTypes.Weeks) {
				recurrenceSettings =
					`${element.elementsScheduling?.frequencyPeriod}` +
					' ' +
					(element.elementsScheduling?.frequencyPeriod == 1 ? $localize`Week` : $localize`Weeks`);
			} else if (element.elementsScheduling.frequencyType == EFrequencyTypes.Months) {
				recurrenceSettings =
					`${element.elementsScheduling?.frequencyPeriod}` +
					' ' +
					(element.elementsScheduling?.frequencyPeriod == 1 ? $localize`Month` : $localize`Months`);
			}
		} else if (inheritedScheduling) {
			if (inheritedScheduling.frequencyType == EFrequencyTypes.Never) {
				recurrenceSettings = $localize`Don't repeat`;
			} else if (inheritedScheduling.frequencyType == EFrequencyTypes.Days) {
				recurrenceSettings =
					`${inheritedScheduling?.frequencyPeriod}` +
					' ' +
					(inheritedScheduling?.frequencyPeriod == 1 ? $localize`Day` : $localize`Days`);
			} else if (inheritedScheduling.frequencyType == EFrequencyTypes.Weeks) {
				recurrenceSettings =
					`${inheritedScheduling?.frequencyPeriod}` +
					' ' +
					(inheritedScheduling?.frequencyPeriod == 1 ? $localize`Week` : $localize`Weeks`);
			} else if (inheritedScheduling.frequencyType == EFrequencyTypes.Months) {
				recurrenceSettings =
					`${inheritedScheduling?.frequencyPeriod}` +
					' ' +
					(inheritedScheduling?.frequencyPeriod == 1 ? $localize`Month` : $localize`Months`);
			}
		}
		return recurrenceSettings;
	}

	static getInheritedRescanSchedulerSettingsFromSegments(folderSegments: IFolderSegment[]) {
		return folderSegments
			.slice()
			.reverse()
			.find(segment => segment.elementsScheduling);
	}

	static countTodayScans(scanSnapshots: IScanSnapshot[]) {
		// check if there is a snapshots for this scan
		// and if so count how many snapshots date matches today
		let todayScansNum = 0;
		if (scanSnapshots) {
			scanSnapshots.forEach(snapshot => {
				let snapshotDate = new Date(snapshot.createDate);
				// * check if today mathces the snapshot date and check the
				// * length of snapshots array to make sure that the snapshot isn't the same as the recurring scan
				if (
					snapshotDate.getDate() === new Date().getDate() &&
					snapshotDate.getMonth() === new Date().getMonth() &&
					snapshotDate.getFullYear() === new Date().getFullYear()
				)
					todayScansNum++;
			});
		}
		return todayScansNum;
	}

	static isAllSnapshotsFailed(scanSnapshots: IScanSnapshot[]): boolean {
		// check if all the given snapshots failed
		let snapshot = scanSnapshots?.find(
			snapshot =>
				snapshot.status === EElementScanStatus.complete ||
				snapshot.status === EElementScanStatus.downloaded ||
				snapshot.status === EElementScanStatus.indexed ||
				snapshot.status === EElementScanStatus.new ||
				snapshot.status === EElementScanStatus.priceChecked
		);
		return !snapshot;
	}

	static calculateSnapshotDiff(element: UserScanData) {
		if (element?.scanSnapshots?.length >= 2) {
			const lastSnapshot = maxBy(
				element.scanSnapshots.filter(
					x => x.status == EElementScanStatus.downloaded || x.status == EElementScanStatus.complete
				),
				'createDate'
			);
			if (lastSnapshot) {
				var elementSnapshotsWithoutLast = element.scanSnapshots.slice();
				var lastSnapShotIndex = elementSnapshotsWithoutLast.findIndex(s => s.scanId === lastSnapshot.scanId);
				elementSnapshotsWithoutLast.splice(lastSnapShotIndex, 1);
				const secondToLastSnapshot = maxBy(
					elementSnapshotsWithoutLast.filter(
						x => x.status == EElementScanStatus.downloaded || x.status == EElementScanStatus.complete
					),
					'createDate'
				);

				if (secondToLastSnapshot) return lastSnapshot.score - secondToLastSnapshot.score;
			}
		}
		return null;
	}

	static getItemIcon(
		item: IPathElement,
		supportedOcrFilesMimeTypes: string[],
		supportedRegularFilesMimeTypes: string[],
		supportedCodeFilesMimeTypes: string[]
	) {
		const extension = item.name?.split('.').pop();
		if (item.generalType === EPathElementGeneralType.Folder) {
			return `assets/images/scan-items/folder.svg`;
		}
		if (item.generalType === EPathElementGeneralType.CompareFiles) {
			return `assets/images/scan-items/compare.svg`;
		}

		if (item.generalType === EPathElementGeneralType.Index) {
			return `assets/images/scan-items/index_only.svg`;
		}
		return this._getLogoByItemExtension(
			extension,
			item,
			supportedOcrFilesMimeTypes,
			supportedRegularFilesMimeTypes,
			supportedCodeFilesMimeTypes
		);
	}

	private static _getLogoByItemExtension(
		extension: string,
		item: IPathElement,
		supportedOcrFilesMimeTypes: string[],
		supportedRegularFilesMimeTypes: string[],
		supportedCodeFilesMimeTypes: string[]
	) {
		if (extension === item.name) {
			return `assets/images/scan-items/regular.svg`;
		} else {
			if (EXTENSIONS_WITH_ICONS.includes(extension) && extension) {
				return `assets/images/scan-items/${extension}.svg`;
			} else if (item.name.includes('http')) {
				return `assets/images/scan-items/url.svg`;
			} else if (
				(supportedOcrFilesMimeTypes.includes(`.${extension}`) &&
					!EXTENSIONS_WITH_ICONS.includes(extension) &&
					extension) ||
				extension === 'gif'
			) {
				// regular image svg
				return `assets/images/scan-items/image.svg`;
			} else if (supportedCodeFilesMimeTypes.includes(`.${extension}`) && extension) {
				// regular code svg
				return `assets/images/scan-items/code.svg`;
			} else if (
				supportedRegularFilesMimeTypes.includes(`.${extension}`) &&
				!EXTENSIONS_WITH_ICONS.includes(extension) &&
				extension
			) {
				// regular file svg
				return `assets/images/scan-items/regular.svg`;
			} else {
				return `assets/images/scan-items/regular.svg`;
			}
		}
	}
}

export interface IUserScansQueryParams {
	search?: string;
	folderId?: string;
	pageIndex?: number;
	updateRecurrence?: boolean;
}

enum EUserScansRowType {
	element = 1,
	loading,
	editing,
}
