import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { AnalyticsEmptyStateModule } from '@ngx-common-v2/components/analytics-components/templates/analytics-empty-state/analytics-empty-state.module';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ClsEmptyStateTemplateModule } from 'ngx-common-v2/components/analytics-components/templates/cls-empty-state-template/cls-empty-state-template.module';
import { ClsContainedContentModule } from 'ngx-common-v2/components/cls-contained-content/cls-contained-content.module';
import { ClsCopyLinkModule } from 'ngx-common-v2/components/cls-copy-link/cls-copy-link.module';
import { ClsLoadingAnimationModule } from 'ngx-common-v2/components/cls-loading-animation/cls-loading-animation.module';
import { ClsPageBannerModule } from 'ngx-common-v2/components/cls-page-banner/cls-page-banner.module';
import { ClsPaginatorModule } from 'ngx-common-v2/components/cls-paginator/cls-paginator.module';
import { ClsScoreLevelModule } from 'ngx-common-v2/components/cls-score-level/cls-score-level.module';
import { ClsSearchInputModule } from 'ngx-common-v2/components/cls-search-input/cls-search-input.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { LmsIntegrationItemModule } from 'shared/componenets/lms-integration-item/lms-integration-item.module';
import { ClsCommonResultsTemplateModule } from '../../../../../ngx-common-v2/components/analytics-components/templates/cls-common-results-template/cls-common-results-template.module';
import { ResultsInsightsTemplateModule } from '../../../../../ngx-common-v2/components/analytics-components/templates/cls-results-insights-template/cls-results-insights-template.module';
import { AnalyticsAssignmentTableModule } from '../../components/analytics-activities-tabel/analytics-activities-table.module';
import { AnalyticsCoursesTableComponent } from '../../components/analytics-courses-table/analytics-courses-table.component';
import { AnalyticsDateRangeModule } from '../../components/analytics-date-range/analytics-date-range.module';
import { DisabledAnalyticsViewModule } from '../../components/disabled-analytics-view/disabled-analytics-view.module';
import { AnalyticsDataService } from '../../services/analytics-data.service';
import { AnalyticsIntegrationComponent } from './analytics-integration.component';

@NgModule({
	declarations: [AnalyticsIntegrationComponent, AnalyticsCoursesTableComponent],
	exports: [AnalyticsIntegrationComponent],
	imports: [
		FlexLayoutModule,
		MatIconModule,
		ResultsInsightsTemplateModule,
		ClsCommonResultsTemplateModule,
		NgxChartsModule,
		MatTooltipModule,
		AnalyticsEmptyStateModule,
		NgxSkeletonLoaderModule,
		MatTableModule,
		ClsPaginatorModule,
		ClsContainedContentModule,
		ClsSearchInputModule,
		ClsScoreLevelModule,
		ClsLoadingAnimationModule,
		AnalyticsAssignmentTableModule,
		MatSelectModule,
		LmsIntegrationItemModule,
		MatMenuModule,
		ClsCopyLinkModule,
		MatDialogModule,
		MatButtonModule,
		AnalyticsDateRangeModule,
		ClsPageBannerModule,
		ClsEmptyStateTemplateModule,
		DisabledAnalyticsViewModule,
		RouterModule,
	],
	providers: [AnalyticsDataService],
})
export class AnalyticsIntegrationModule {}
