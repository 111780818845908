import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClsButtonComponent } from './cls-button.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ClsSpinnerModule } from '../cls-spinner/cls-spinner.module';

@NgModule({
	declarations: [ClsButtonComponent],
	imports: [CommonModule, MatIconModule, MatButtonModule, MatTooltipModule, FlexLayoutModule, ClsSpinnerModule],
	exports: [ClsButtonComponent],
})
export class ClsButtonModule {}
