import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { ScreenService } from 'ngx-common/services/screen.service';
import { EPlatform } from 'shared/enums';
import { LmsSettingsService } from '../lms-settings/service/lms-settings.service';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BreadcrumbItem } from '@ngx-common/services/seo.service';
// import { SecureImgPipe } from '@ngx-common/pipes/secure/secure-img.pipe';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { IOrganizationInfo } from '@ngx-common/services/user-organization.service';
import { environment } from '@environment';

@UntilDestroy()
@Component({
	selector: 'app-lms-navbar',
	templateUrl: './lms-navbar.component.html',
	styleUrls: ['./lms-navbar.component.scss'],
})
export class LMSSettingsNavbarComponent implements OnInit {
	@Input() breadCrumbs: BreadcrumbItem[];
	@Input() platform: EPlatform;
	@Input() authToken: string;
	@Input() isAdmin: string;
	@Input() isLoadingData: boolean = true;
	@Input() showIcon: boolean = true;
	@Input() backgroundColor: string;

	@Output() itemClick = new EventEmitter();

	private _organizationInfo$ = new BehaviorSubject<IOrganizationInfo>({ hasLogo: false });
	get organizationInfo() {
		return this._organizationInfo$.value;
	}
	orgLogo: SafeUrl;
	isMoblie: boolean;

	ePlatform = EPlatform;

	constructor(
		private _screenSvc: ScreenService,
		// private _securePipe: SecureImgPipe,
		private _http: HttpClient // private _lmsService: LmsSettingsService = null
	) {}

	ngOnInit() {
		this.isMoblie = this._screenSvc.isMobile();
		// if (this.loadingViaService) {
		// 	this._lmsService.isLoadingData$.pipe(untilDestroyed(this)).subscribe(val => {
		// 		this.isLoadingData = val;
		// 	});
		// }
	}

	// private async _initOrganiztionLogoAsync() {
	// 	let headers: HttpHeaders;
	// 	if (this.authToken) {
	// 		headers = new HttpHeaders().append('Authorization', `Bearer ${this.authToken}`);
	// 	}
	// 	await this._setupOrgLogoAsync(this.authToken, headers);
	// 	this._organizationInfo$.pipe(untilDestroyed(this)).subscribe(async x => {
	// 		if (x.hasLogo) {
	// 			this._securePipe
	// 				.transform(x.logoUrl, headers)
	// 				.pipe(untilDestroyed(this))
	// 				.subscribe(s => {
	// 					this.orgLogo = s;
	// 				});
	// 		} else {
	// 			this.orgLogo = null;
	// 		}
	// 	});
	// }

	private async _setupOrgLogoAsync(token: string = null, headers: HttpHeaders = null) {
		try {
			let info = await this._getOrganizationInfoAsync(token, headers);
			this._organizationInfo$.next(info);
		} catch (error) {
			/** no need to show message for users for this error */
			console.error('failed to load organization info');
		}
	}

	private async _getOrganizationInfoAsync(token: string, headers: HttpHeaders) {
		if (this.organizationInfo.hasLogo) {
			return this.organizationInfo;
		}

		let result: IOrganizationInfo;

		result = await this._http
			.get<IOrganizationInfo>(`${environment.apiUrl}/v1/account/info/organization-has-logo`, {
				headers: token ? headers : {},
			})
			.toPromise();

		if (result.hasLogo) {
			result.logoUrl = `${environment.apiUrl}/v1/account/info/organization-logo`;
		}

		return result;
	}
}
