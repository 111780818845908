import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClsPieChartComponent } from './cls-pie-chart.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
	declarations: [ClsPieChartComponent],
	imports: [CommonModule, NgxChartsModule, FlexLayoutModule, MatTooltipModule],
	exports: [ClsPieChartComponent],
})
export class ClsPieChartModule {}
