import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EAlertItemType } from '../../models/cls-alerts.enums';

/**
 * Class representing a single page alerts.
 */
@Component({
	selector: 'cls-alert-item',
	templateUrl: './cls-alert-item.component.html',
	styleUrls: ['./cls-alert-item.component.scss'],
})
export class ClsAlertItemComponent implements OnInit {
	@Input() title: string = '';
	@Input() description: string = '';
	@Input() actionText: string = '';
	@Input() action: () => any;
	@Input() alertItemType: EAlertItemType = EAlertItemType.Primary;

	@Output() close = new EventEmitter<any>();

	EAlertItemType = EAlertItemType;

	constructor() {}

	ngOnInit(): void {}

	triggerAction() {
		this.action();
	}

	closeAlert() {
		this.close.emit();
	}
}
