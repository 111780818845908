<div class="copyleaks-dialog">
	<ng-container *ngIf="data?.isNewDesign">
		<h1 mat-dialog-title class="copyleaks-dialog-title large-title" [innerHTML]="data.title"></h1>
	</ng-container>
	<ng-container *ngIf="!data?.isNewDesign">
		<h1 mat-dialog-title class="copyleaks-dialog-title" [innerHTML]="data.title"></h1>
	</ng-container>
	<div mat-dialog-content>
		<cls-callout *ngIf="captchaErrorText" type="info" style="margin-top: 0 !important">
			{{ captchaErrorText }}
		</cls-callout>

		<ng-container *ngIf="calloutMessage">
			<cls-callout [type]="calloutMessage.type">
				<cls-callout-title *ngIf="calloutMessage.title">
					{{ calloutMessage.title }}
				</cls-callout-title>
				{{ calloutMessage.description }}
			</cls-callout>
		</ng-container>

		<p *ngIf="data.description" [innerHTML]="data.description"></p>

		<ng-container #vcr></ng-container>

		<cls-copyleaks-captcha
			class="cls-captcha-section"
			#captcha
			[style.display]="data.showCaptcha ? 'flex' : 'none'"
			[formControl]="captchaCtrl"
			[floatLabel]="'always'"></cls-copyleaks-captcha>

		<br *ngIf="data.showCaptcha && !data.ConformationInputString" />

		<div *ngIf="data.ConformationInputString" fxLayout="column">
			<ng-container *ngIf="!data?.isNewDesign">
				<br />
				<div class="conf-input-label" (click)="selectConformationInputString(confStr)">
					{{ data.confirmationText ? data.confirmationText : 'To Continue Write' }}
					&#34;
					<span style="font-weight: bold" #confStr>{{ getConformationInputString() }}</span>
					&#34;
				</div>
			</ng-container>
			<!-- prettier-ignore -->

			<ng-container *ngIf="data?.isNewDesign">
        <div class="conf-input-label" (click)="selectConformationInputString(confStr)">
          <p style="margin-top: -5px;">{{ data.confirmationText ? data.confirmationText : 'To Continue Write' }}
          &#34;
          <span style="font-weight: bold" #confStr>{{ getConformationInputString() }}</span>
          &#34;
          <ng-container *ngIf="data?.confirmationTextNext">{{data?.confirmationTextNext}}</ng-container>
        </p>
        </div>
			</ng-container>

			<ng-container *ngIf="!data?.isNewDesign">
				<mat-form-field class="from-field-no-required-marker" style="width: 100%" full-width [floatLabel]="'always'">
					<input matInput [formControl]="conformationInputCtrl" type="text" maxlength="100" required />
				</mat-form-field>
			</ng-container>
			<ng-container *ngIf="data?.isNewDesign && data?.ConformationInputString?.length > 0">
				<div class="input-conf" style="margin-bottom: 30px">
					<input matInput [formControl]="conformationInputCtrl" type="text" maxlength="100" required />
				</div>
			</ng-container>
		</div>
		<ng-container *ngIf="!data?.isNewDesign">
			<div fxLayoutAlign="center center" class="dialog-actions">
				<button
					mat-raised-button
					class="btn-cancel"
					(click)="cancel()"
					[color]="data.btnCancelColor ? data.btnCancelColor : ''"
					*ngIf="!showButtonSpinner && !data.hideCancelButton">
					{{ data.btnCancelText ? data.btnCancelText : 'NO' }}
				</button>
				<div class="btn-submit-wrapper">
					<cls-spinner-button
						type="submit"
						[showSpinner]="showButtonSpinner"
						spinnerDiameter="30"
						(click)="confirm(captcha)"
						[color]="data.btnSubmitColor ? data.btnSubmitColor : 'primary'">
						{{ data.btnSubmitText ? data.btnSubmitText : 'YES' }}
					</cls-spinner-button>
				</div>
			</div>
		</ng-container>
		<ng-container *ngIf="data?.isNewDesign">
			<div fxLayoutAlign="center center" class="dialog-actions">
				<button
					mat-raised-button
					class="btn-cancel-new"
					(click)="cancel()"
					[color]="data.btnCancelColor ? data.btnCancelColor : ''"
					*ngIf="!showButtonSpinner && !data.hideCancelButton">
					{{ data.btnCancelText ? data.btnCancelText : 'NO' }}
				</button>
				<div class="btn-submit-wrapper">
					<cls-spinner-button
						[fontWeight]="'bold'"
						[btnWidth]="'170px'"
						class="submit-new"
						type="submit"
						[showSpinner]="showButtonSpinner"
						spinnerDiameter="30"
						(click)="confirm(captcha)"
						[color]="data.btnSubmitColor ? data.btnSubmitColor : 'primary'">
						{{ data.btnSubmitText ? data.btnSubmitText : 'YES' }}
					</cls-spinner-button>
				</div>
			</div>
		</ng-container>
	</div>
</div>
