import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnalyticsStudentComponent } from './analytics-student.component';
import { ClsAnalyticsAuthorTemplateModule } from 'ngx-common-v2/components/analytics-components/templates/cls-analytics-author-template/cls-analytics-author-template.module';
import { AnalyticsDateRangeModule } from '../../components/analytics-date-range/analytics-date-range.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ClsLoadingAnimationModule } from 'ngx-common-v2/components/cls-loading-animation/cls-loading-animation.module';
import { LmsNavbarModule } from 'shared/componenets/lms-navbar/lms-navbar.module';
import { AnalyticsStudentSubmissionsTableModule } from '../../components/analytics-student-submissions-table/analytics-student-submissions-table.module';
import { AnalyticsDataService } from '../../services/analytics-data.service';
import { AnalytiscUtilsService } from '../../services/analytics.utils';
import { ClsPageBannerModule } from 'ngx-common-v2/components/cls-page-banner/cls-page-banner.module';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
	declarations: [AnalyticsStudentComponent],
	exports: [AnalyticsStudentComponent],
	imports: [
		CommonModule,
		ClsAnalyticsAuthorTemplateModule,
		AnalyticsDateRangeModule,
		FlexLayoutModule,
		ClsLoadingAnimationModule,
		LmsNavbarModule,
		AnalyticsStudentSubmissionsTableModule,
		ClsPageBannerModule,
		MatIconModule,
	],
	providers: [AnalyticsDataService, AnalytiscUtilsService],
})
export class AnalyticsStudentModule {}
