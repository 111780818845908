import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CodeInputModule } from 'angular-code-input';
import { CalloutModule } from '../../components/callout/callout.module';
import { CopyleaksCaptchaModule } from '../../components/copyleaks-captcha/copyleaks-captcha.module';
import { SpinnerButtonModule } from '../../components/spinner-button/spinner-button.module';
import { SpinnerModule } from '../../components/spinner/spinner.module';
import { UserDetailsFormModule } from '../../components/user-details-form/user-details-form.module';
import { CopyLeaksDialogModule } from '../copyleaks-dialog/copyleaks-dialog.module';
import { ResendActivationSuccessDialogModule } from '../resend-activation-success-dialog/resend-activation-success-dialog.module';
import { PersonalInformationEntryComponent } from './components/personal-information-entry/personal-information-entry.component';
import { UserRegisterEmailEntryComponent } from './components/user-register-email-entry/user-register-email-entry.component';
import { VerifyEmailByCodeComponent } from './components/verify-email-by-code/verify-email-by-code.component';
import { UserRegisterDialogComponent } from './user-register-dialog.component';
import { SsoProvidersComponent } from './components/sso-providers/sso-providers.component';
import { PasswordFormFieldModule } from '../../components/password-form-field/password-form-field.module';

@NgModule({
	declarations: [
		UserRegisterDialogComponent,
		UserRegisterEmailEntryComponent,
		VerifyEmailByCodeComponent,
		PersonalInformationEntryComponent,
		SsoProvidersComponent,
	],
	exports: [UserRegisterDialogComponent],
	imports: [
		CommonModule,
		FormsModule,
		RouterModule,
		ReactiveFormsModule,
		TranslateModule,

		FlexLayoutModule,

		CopyleaksCaptchaModule,
		UserDetailsFormModule,
		SpinnerButtonModule,
		CalloutModule,
		CodeInputModule,
		SpinnerModule,

		ResendActivationSuccessDialogModule,
		CopyLeaksDialogModule,

		MatDialogModule,
		MatButtonModule,
		MatTooltipModule,
		MatIconModule,
		MatFormFieldModule,
		MatInputModule,
		PasswordFormFieldModule,
	],
	entryComponents: [
		UserRegisterDialogComponent,
		UserRegisterEmailEntryComponent,
		VerifyEmailByCodeComponent,
		PersonalInformationEntryComponent,
	],
})
export class UserRegisterDialogModule {}
