import { transition, trigger, useAnimation } from '@angular/animations';
import { isPlatformBrowser } from '@angular/common';
import {
	Component,
	EventEmitter,
	Inject,
	Input,
	OnDestroy,
	OnInit,
	Output,
	PLATFORM_ID,
	ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ESubscriptionBillingStatus } from '../../services/payment-plan.service';
import { ScrollToService } from '@nicky-lenaers/ngx-scroll-to';
import { fadeIn } from 'ng-animate';
import { STRIPE_NEW_CARD_ID } from '../../constants';
import { EDataInitTypes } from '../../enums/data-init-types.enum';
import { AuthService } from '../../services/auth.service';
import { CreditCardComponent, ICreditCardValue } from '../credit-card/credit-card-component/credit-card.component';
import { PaymentMethod } from '../credit-card/stripe/stripe-definitions/payment-method';
import { IPaymentMethod, PaymentMethodsService } from '../payment-methods/payment-methods.service';
@UntilDestroy()
@Component({
	selector: 'cls-choose-payment-method',
	styleUrls: ['./choose-payment-method.component.scss'],
	templateUrl: `choose-payment-method.component.html`,
	animations: [trigger('fadin', [transition(':enter', useAnimation(fadeIn))])],
})
export class ChoosePaymentMethodComponent implements OnInit {
	@ViewChild('creditCardRef') creditCardRef: CreditCardComponent;
	@ViewChild('creditCardDivId') creditCardDivId: any;

	STRIPE_NEW_CARD_ID = STRIPE_NEW_CARD_ID;
	EDataInitTypes = EDataInitTypes;

	@Input() formSubmitted = false;

	@Input() showJustNewCreditCard = false;

	@Input() selectedMethodWithBorder = true;

	@Input() isScrollEnabled = false;

	@Input() showTitle = true;

	@Input() newDesign: boolean = false;

	@Input() billingStatus: ESubscriptionBillingStatus;

	@Input() showCheckMarkCircle: boolean = false;

	@Output() dataInit = new EventEmitter();

	@Output() updateCard = new EventEmitter();

	@Output() exportData = new EventEmitter<ChoosePaymentMethodExportedData>();

	havePaymentMethod: boolean;
	isOldPaymentMethod = false;

	selectedPaymentMethodId: string;
	creditCardCtrl: FormControl;
	isPlatformBrowser: any;

	initData = {
		creditCard: false,
		paymentMethods: false,
	};

	get showStripComponents(): boolean {
		return this.isPlatformBrowser && !!(window as any).Stripe;
	}

	constructor(
		private _authSvc: AuthService,
		private _paymentMethodSvc: PaymentMethodsService,
		private _formBuilder: FormBuilder,
		private _scrollToSvc: ScrollToService,
		@Inject(PLATFORM_ID) private platformId: object
	) {}

	async ngOnInit() {
		this.isPlatformBrowser = isPlatformBrowser(this.platformId);
		this.creditCardCtrl = this._formBuilder.control(null, Validators.required);
		this.creditCardCtrl.valueChanges.pipe(untilDestroyed(this)).subscribe(_ => {
			this.exportDataFunc();
		});
		if (!this.showJustNewCreditCard) {
			setTimeout(() => {
				if (this._paymentMethodSvc.getDefaultPaymentMethod()) {
					this._paymentMethodSvc
						.getDefaultPaymentMethod()
						.pipe(untilDestroyed(this))
						.subscribe(d => {
							this.havePaymentMethod = d != null;
							this.dataInitFunc(EDataInitTypes.PaymentMethods);
						});
				} else {
					this.havePaymentMethod = false;
					this.dataInitFunc(EDataInitTypes.PaymentMethods);
				}
			});
			if (!this.havePaymentMethod) {
				this.selectedPaymentMethodId = STRIPE_NEW_CARD_ID;
			}
		} else {
			this.havePaymentMethod = false;
			this.selectedPaymentMethodId = STRIPE_NEW_CARD_ID;
		}
		this.dataInitFunc(EDataInitTypes.PaymentMethods);
		this.exportDataFunc();
	}

	public onPaymentMethodSelectChange(event: IPaymentMethod) {
		this.selectedPaymentMethodId = event.paymentMethodId;
		if (event.paymentMethodId !== STRIPE_NEW_CARD_ID) {
			this.isOldPaymentMethod = true;
		} else {
			this.isOldPaymentMethod = false;
		}
		this.exportDataFunc();
		if (this.isScrollEnabled) {
			setTimeout(() => {
				this._scrollToSvc.scrollTo({
					target: this.creditCardDivId,
				});
			}, 100);
		}
	}

	dataInitFunc(type?: string) {
		if (type === EDataInitTypes.PaymentMethods) {
			this.dataInit.emit(true);
		}
	}

	exportDataFunc() {
		this.exportData.emit({
			isOldPaymentMethod: this.isOldPaymentMethod,
			newPaymentMethod: (this.creditCardCtrl.value as ICreditCardValue)?.paymentMethod,
			selectedPaymentMethodId: this.selectedPaymentMethodId,
			havePaymentMethod: this.havePaymentMethod,
		});
	}

	clear() {
		this.creditCardRef?.clear();
	}
}

export interface ChoosePaymentMethodExportedData {
	selectedPaymentMethodId: string;
	newPaymentMethod: PaymentMethod;
	isOldPaymentMethod: boolean;
	havePaymentMethod?: boolean;
}
