import { isPlatformBrowser } from '@angular/common';
import * as signalR from '@microsoft/signalr';

import { environment } from '@environment';
import { BehaviorSubject, Observable } from 'rxjs';

import { UrlLocationService } from '../../services/url-location.service';
import { ESignalRConnectionState, ESignalRProducts } from '../enums';

import { Directive } from '@angular/core';
import { ClsAlertsService } from '@ngx-common-v2/components/cls-alerts/services/cls-alerts.service';
import * as Sentry from '@sentry/angular';
import { ECopyleaksAPP, NgxCommonPagesConfig } from '../../pages/pages.config';
import { AuthService } from '../../services/auth.service';
import { runOutsideAngular } from '../../utils/zone';

@Directive()
export abstract class BaseNotificationsService {
	// abstructs variables
	abstract connectionPath: string;
	// private variables
	private _baseUrl: string;
	private _hubConnection: signalR.HubConnection;
	private _manuallyConnectionClosed = false;
	private _runningConnection: string[] = [];
	// protected variables
	protected reconnectionRetrys = 0;
	protected maxRetrys = 10;
	protected signalRProducts: ESignalRProducts;
	// Observalbed
	private _connectionState$ = new BehaviorSubject<ESignalRConnectionState>(ESignalRConnectionState.Unknown);
	// Getters
	public get ConnectionState$() {
		return this._connectionState$;
	}
	// Constructor
	constructor(
		protected platformId: object,
		protected urlLocationSvc: UrlLocationService,
		protected authSvc: AuthService,
		protected clsAlertsSvc: ClsAlertsService,
		protected config: NgxCommonPagesConfig
	) {
		switch (config?.APP) {
			case ECopyleaksAPP.API:
				this.signalRProducts = ESignalRProducts.API;
				break;
			case ECopyleaksAPP.MainWebsite:
				this.signalRProducts = ESignalRProducts.MainWebsite;
				break;
			case ECopyleaksAPP.Admin:
				this.signalRProducts = ESignalRProducts.Admin;
				break;
			case ECopyleaksAPP.LMS:
				this.signalRProducts = ESignalRProducts.LMS;
				break;
			case ECopyleaksAPP.Identity:
				this.signalRProducts = ESignalRProducts.Identity;
				break;
		}
		if (urlLocationSvc.location.origin.includes('localhost')) {
			this._baseUrl = `https://localhost:5003`;
		} else {
			this._baseUrl = `${environment.notificationsAPI ? environment.notificationsAPI : urlLocationSvc.location.origin}`;
		}
	}

	// abstract methods
	protected abstract onConnectionSuccess(hubConnection: signalR.HubConnection): void;

	public startConnection(connectionName: string) {
		if (
			isPlatformBrowser(this.platformId) &&
			(this.config.APP == ECopyleaksAPP.API ||
				this.config.APP == ECopyleaksAPP.MainWebsite ||
				this.config.APP == ECopyleaksAPP.Admin ||
				this.config.APP == ECopyleaksAPP.LMS ||
				this.config.APP == ECopyleaksAPP.Identity)
		) {
			this._runningConnection = this._runningConnection.filter(cn => cn !== connectionName);
			this._runningConnection.push(connectionName);
			const connectionState = this._connectionState$.value;
			if (!this._hubConnection || connectionState === ESignalRConnectionState.Error) {
				this._connectionState$.next(ESignalRConnectionState.Unknown);
				this._manuallyConnectionClosed = false;
				this._hubConnection = new signalR.HubConnectionBuilder()
					.withUrl(`${this._baseUrl}${this.connectionPath}`)
					.build();

				this._connect();

				this._hubConnection.onclose(() => {
					if (this._manuallyConnectionClosed) {
						this._connectionState$.next(ESignalRConnectionState.Disconnected);
					} else {
						this._connectionState$.next(ESignalRConnectionState.Disconnected);
						this._connect();
					}
				});
			}
		}
	}

	private _connect() {
		if (isPlatformBrowser(this.platformId)) {
			if (this._hubConnection) {
				this._connectionState$.next(ESignalRConnectionState.PendingConnection);
				this._hubConnection
					.start()
					.then(() => {
						this.reconnectionRetrys = 0;
						this.onConnectionSuccess(this._hubConnection);
						this._connectionState$.next(ESignalRConnectionState.Connected);
					})
					.catch(err => {
						if (this.reconnectionRetrys <= this.maxRetrys) {
							this.reconnectionRetrys++;
							setTimeout(() => {
								this._connect();
							}, 1000 * this.reconnectionRetrys);
						} else {
							this._connectionState$.next(ESignalRConnectionState.Error);
							runOutsideAngular(() =>
								Sentry.captureException('Web Socket connection issue, check console for more details')
							);
						}
					});
			}
		}
	}

	stopConnection(connectionName: string) {
		if (isPlatformBrowser(this.platformId)) {
			this._runningConnection = this._runningConnection.filter(cn => cn !== connectionName);

			if (!environment.production) {
				console.log(JSON.stringify(this._runningConnection));
			}

			const hasConnection = this._runningConnection.length;
			if (this._hubConnection && this._hubConnection.state === signalR.HubConnectionState.Connected && !hasConnection) {
				this._manuallyConnectionClosed = true;
				this._hubConnection.stop();
				this._hubConnection = null;
			}
		}
	}

	on<ReceivedDataType>(target: string) {
		if (isPlatformBrowser(this.platformId)) {
			return new Observable<ReceivedDataType>(observer => {
				this._hubConnection.on(target, data => {
					observer.next(JSON.parse(data));
				});
			});
		}
	}

	async joinGroup(groupName: string) {
		if (isPlatformBrowser(this.platformId)) {
			try {
				await this._hubConnection.invoke('JoinGroup', groupName);
			} catch (error) {
				throw error;
			}
		}
	}

	async leaveGroup(groupName: string) {
		if (isPlatformBrowser(this.platformId)) {
			try {
				// await this._hubConnection.invoke('LeaveGroup', groupName);
			} catch (error) {
				throw error;
			}
		}
	}

	removeListener(target: string) {
		if (isPlatformBrowser(this.platformId)) {
			if (this._hubConnection) {
				this._hubConnection.off(target);
			}
		}
	}
}
