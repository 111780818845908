import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SpinnerModule } from '../../../components/spinner/spinner.module';
import { DateFromNowPipeModule } from '../../../pipes/date-from-now/date-from-now-pipe.module';
import { BackgroundNotificationTaskItemModule } from '../background-notification-task-item/background-notification-task-item.module';
import { DefaultNotificationItemModule } from '../default-notification-item/default-notification-item.module';
import { NotificationIndexDocumentModule } from '../notification-index-document/notification-index-document.module';
import { NotificationItemDeleteModule } from '../notification-item-delete/notification-item-delete.module';
import { NotificationItemDownloadModule } from '../notification-item-download/notification-item-download.module';
import { NotificationItemGeneralModule } from '../notification-item-general/notification-item-general.module';
import { NotificationItemRecurrenceUpdateModule } from '../notification-item-recurrence-update/notification-item-recurrence-update.module';
import { NotificationItemShareModule } from '../notification-item-share/notification-item-share.module';
import { NotificationContainerComponent } from './notification-container.component';

@NgModule({
	declarations: [NotificationContainerComponent],
	exports: [NotificationContainerComponent],
	imports: [
		CommonModule,
		FlexLayoutModule,
		BackgroundNotificationTaskItemModule,
		NotificationItemDeleteModule,
		NotificationItemDownloadModule,
		NotificationItemShareModule,
		NotificationItemRecurrenceUpdateModule,
		NotificationItemGeneralModule,
		DefaultNotificationItemModule,
		NotificationIndexDocumentModule,
		SpinnerModule,
		MatIconModule,
		MatButtonModule,
		MatMenuModule,
		MatTooltipModule,
		DateFromNowPipeModule,
	],
})
export class NotificationContainerModule {}
