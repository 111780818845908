import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { PaymentPlanService } from '@ngx-common/services/payment-plan.service';

@Component({
	selector: 'app-unpause-subscirption-dialog',
	templateUrl: './unpause-subscirption-dialog.component.html',
	styleUrls: ['./unpause-subscirption-dialog.component.scss'],
})
export class UnPauseSubscriptionDialogComponent implements OnInit {
	btnDisable: boolean = false;
	constructor(
		public dialogRef: MatDialogRef<UnPauseSubscriptionDialogComponent>,
		private _paymentPlanSvc: PaymentPlanService,
		@Inject(MAT_DIALOG_DATA) public data: IUnPauseSubscriptionDialogDialogModel,
		private location: Location
	) {}

	ngOnInit(): void {}

	async onClick() {
		this.btnDisable = true;
		await this._paymentPlanSvc.unPauseSubscription();
		setTimeout(async () => {
			await this._paymentPlanSvc.updateUserBalance();
			this.btnDisable = false;
			this.dialogRef.close();
		}, 1000);
	}

	closeDialog() {
		if (this.data?.disableClose) {
			this.location.back();
		} else this.dialogRef.close();
	}
}

export interface IUnPauseSubscriptionDialogDialogModel {
	disableClose?: boolean;
	onCancel?: () => void;
}
