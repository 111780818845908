<div
	*ngIf="
		!isLoadingMoreData &&
		!hidePaginatorContainer &&
		receivedDataLength &&
		showPaginator &&
		(offSet !== 0 || receivedDataLength >= pageSizeOptions[0])
	"
	class="paginator-top-container">
	<div class="paginator-container" fxLayout="row wrap" fxLayoutAlign="end center" *ngIf="showPaginator">
		<div
			*ngIf="showPageSizeOptions"
			fxLayout="row"
			fxLayoutGap="5px"
			fxLayoutAlign="start center"
			class="items-per-page"
			style="margin-right: 20px">
			<mat-label fxFlex i18n>Items per page:</mat-label>
			<mat-select
				i18n-matTooltip
				matTooltip="Items per page"
				[(ngModel)]="pageSize"
				(ngModelChange)="changeItemsPerPage()"
				panelClass="cls-paginator-component-selection-menu">
				<mat-option *ngFor="let value of pageSizeOptions" [value]="value">
					{{ value }}
				</mat-option>
			</mat-select>
		</div>

		<div class="items-per-page" style="min-width: 100px" fxLayoutAlign="center center" *ngIf="canLoadMoreData" i18n>
			{{ offSet ? offSet + 1 : 1 }} - {{ offSet + pageSize }} of many
		</div>
		<div class="items-per-page" style="margin-right: 5px" *ngIf="!canLoadMoreData" i18n>
			{{ offSet ? offSet + 1 : 1 }} - {{ receivedDataLength }} of
			{{ receivedDataLength }}
		</div>

		<div class="buttons-container" fxLayout="row" fxLayoutAlign="center center">
			<button
				class="prev-page-button"
				mat-icon-button
				(click)="previous()"
				[disabled]="pageIndex === 1"
				aria-label="left"
				i18n-matTooltip
				matTooltip="Previous page">
				<mat-icon fontSet="material-icons-round">chevron_left</mat-icon>
			</button>
			<button
				class="next-page-button"
				mat-icon-button
				(click)="next()"
				[disabled]="!canLoadMoreData"
				aria-label="right"
				i18n-matTooltip
				matTooltip="Next page">
				<mat-icon fontSet="material-icons-round">chevron_right</mat-icon>
			</button>
		</div>
	</div>
</div>
