import { Component, Input, OnInit, ViewChild, ElementRef, AfterViewInit, Renderer2 } from '@angular/core';

@Component({
	selector: 'cls-callout',
	templateUrl: './callout.component.html',
	styleUrls: ['./callout.component.scss'],
	// tslint:disable-next-line: no-host-metadata-property
	host: {
		'[class]': '"callout callout-"+type',
	},
})
export class CalloutComponent implements AfterViewInit {
	@Input() type: 'success' | 'error' | 'warn' | 'info' = 'success';
	@Input() title = '[title]';
	@Input() description = '[description]';
	@Input() showIcon = true;

	@ViewChild('headerRef', { static: true }) headerRef: ElementRef;
	hasTitle = false;

	constructor(private _elementRef: ElementRef, private _renderer: Renderer2) {}

	ngAfterViewInit() {
		this.hasTitle =
			this.headerRef.nativeElement &&
			this.headerRef.nativeElement.childNodes.length &&
			this.headerRef.nativeElement.childNodes.item(0).tagName &&
			this.headerRef.nativeElement.childNodes.item(0).tagName.toString().toLowerCase() === 'cls-callout-title';

		if (this._elementRef && !this.hasTitle) {
			this._renderer.addClass(this._elementRef.nativeElement, 'no-title');
		}
	}
}

@Component({
	selector: 'cls-callout-title',
	template: `
		<div style="padding-bottom:10px"><ng-content></ng-content></div>
	`,
	styleUrls: ['./callout.component.scss'],
})
// tslint:disable-next-line: component-class-suffix
export class CalloutTitle {}
