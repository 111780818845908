<div class="page-warper" fxLayout="column" fxLayoutGap="16px">
	<app-lms-navbar
		*ngIf="!isLoading"
		[ngClass]="{ 'layout-settings': true, 'student-page-navbar-design': true }"
		[breadCrumbs]="breadCrumbs"
		[isLoadingData]="isLoading"
		[isAdmin]="false"
		[showIcon]="false"
		backgroundColor="white"></app-lms-navbar>
	<ng-container *ngIf="isLoading">
		<cls-loading-animation [txtMessage]="loadingMessage"></cls-loading-animation>
	</ng-container>
	<div class="main-container" *ngIf="!isLoading" fxLayout="column" fxLayoutGap="16px">
		<cls-page-banner>
			<cls-page-banner-icon><mat-icon>info_outline</mat-icon></cls-page-banner-icon>
			<cls-page-banner-description i18n>
				Copyleaks LMS Analytics started collecting data on {{ ANALYTICS_COLLECTION_DATE | date }}. Any data before this
				date will not appear.
			</cls-page-banner-description>
		</cls-page-banner>
		<div fxLayout="column" fxLayoutGap="16px">
			<lms-analytics-date-range
				(dateRangeChangeData)="dateRangeChange($event)"
				[dateRange]="dateRange"
				[baseStartDate]="baseStartDate"></lms-analytics-date-range>
			<cls-analytics-author-template
				[analyticsData]="dataSrc.authorOverviewData"
				[isLoadFromDashboard]="isLoadFromDashboard"
				[writingFeedbackFeatureEnabled]="dataSrc.writingFeedbackFeatureEnabled">
				<cls-author-analytics-data>
					<lms-analytics-student-submissions-table
						[isLoadFromDashboard]="isLoadFromDashboard"
						[token]="token"
						[dateRange]="dateRange"
						[platformType]="platformType"
						[studentId]="studentId"
						[integrationId]="integrationId"
						[writingFeedbackFeatureEnabled]="
							dataSrc.writingFeedbackFeatureEnabled
						"></lms-analytics-student-submissions-table>
				</cls-author-analytics-data>
			</cls-analytics-author-template>
		</div>
	</div>
</div>
