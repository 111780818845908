import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnalyticsDateRangeComponent } from './analytics-date-range.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
	declarations: [AnalyticsDateRangeComponent],
	exports: [AnalyticsDateRangeComponent],
	imports: [
		CommonModule,
		FlexLayoutModule,
		MatInputModule,
		FormsModule,
		ReactiveFormsModule,
		MatNativeDateModule,
		MatDatepickerModule,
		MatFormFieldModule,
		MatIconModule,
		MatTooltipModule,
	],
})
export class AnalyticsDateRangeModule {}
