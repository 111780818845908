import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PaymentService } from '../../services/payment.service';
import { SpinnerModule } from '../spinner/spinner.module';
import { PlansComponent } from './plans-component/plans.component';
import { FloatDecimalPipeModule } from '../../pipes/float-decimal/float-decimal.module';

@NgModule({
	declarations: [PlansComponent],
	exports: [PlansComponent],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		FormsModule,
		FloatDecimalPipeModule,
		MatButtonModule,
		MatIconModule,
		MatTooltipModule,
		MatRadioModule,
		SpinnerModule,
		MatDividerModule,
	],
	providers: [PaymentService],
})
export class PlansModule {}
