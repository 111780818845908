import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { ClsAlertsService } from '@ngx-common-v2/components/cls-alerts/services/cls-alerts.service';
import { AuthService } from '../services/auth.service';
import { OidcService } from '../services/oidc.service';

@Injectable({
	providedIn: 'root',
})
export class LoggedInGuard implements CanActivate {
	constructor(
		private _authSvc: AuthService,
		private _oidc: OidcService,
		private _router: Router,
		private _clsAlertsSvc: ClsAlertsService,
		@Inject(PLATFORM_ID) private platformId: object
	) {}
	async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		if (isPlatformServer(this.platformId)) {
			return true;
		}
		if (!(await this._authSvc.isLoggedInAsync())) {
			this._oidc.login();
			return false;
		} else {
			const roles = route.data['roles'] ? (route.data['roles'] as Array<string>) : null;
			if (!roles) return true;
			else {
				const canAccess = this._authSvc.checkIfUserHaveRoles(roles);
				if (canAccess) return true;
				this._clsAlertsSvc.showCustomError('forbidden : cannot access this page');
				this._router.navigateByUrl('/');
				return false;
			}
		}
	}
}
