import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
	ClsEmptyStateContent,
	ClsEmptyStatePrimaryText,
	ClsEmptyStateSecondaryText,
	ClsEmptyStateTemplateComponent,
} from './cls-empty-state-template.component';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
	declarations: [
		ClsEmptyStateTemplateComponent,
		ClsEmptyStatePrimaryText,
		ClsEmptyStateSecondaryText,
		ClsEmptyStateContent,
	],
	exports: [ClsEmptyStateTemplateComponent, ClsEmptyStatePrimaryText, ClsEmptyStateSecondaryText, ClsEmptyStateContent],
	imports: [CommonModule, FlexLayoutModule],
})
export class ClsEmptyStateTemplateModule {}
