import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';

@Injectable()
export class StatusMessageBannerService {
	constructor(private _http: HttpClient) {}

	async getStatusAsync() {
		return await this._http.get<ICopyleaksStatus>(`${environment.apiUrl}/v1/copyleaks-status`).toPromise();
	}
}

export interface ICopyleaksStatus {
	apiStatus: ECopyleaksStatus;
	mainWebsiteStatus: ECopyleaksStatus;
	idStatus: ECopyleaksStatus;
	ltiStatus: ECopyleaksStatus;
}

export enum ECopyleaksStatus {
	Operational = 1,
	DegradedPerformance,
	PartialOutage,
	MajorOutage,
	Maintenance,
}
