export enum EGeneralNotificationEventType {
	Link,
	PaymentDialog,
}

export enum ELinkEventModelType {
	Internal,
	External,
}

export enum ELinkEventModelTarget {
	Blank,
	Self,
}

export enum EAllowedUsersTypes {
	All,
	WithoutSubscription,
}
