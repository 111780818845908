<button
	class="only-icon-btn"
	[matMenuTriggerFor]="webNotificationsMenu"
	mat-icon-button
	aria-label="notifications"
	(menuClosed)="notificationsMenuClosedAsync()"
	#notificationsMenuButtonRef="matMenuTrigger"
	i18n-matTooltip
	matTooltip="Notifications">
	<mat-icon
		*ngIf="!unreadNotificationsCount"
		[ngClass]="{ 'toolbar-icon-clicked': notificationsMenuButtonRef.menuOpen }">
		notifications
	</mat-icon>
	<mat-icon
		*ngIf="unreadNotificationsCount"
		class="notifications-bell"
		[matBadge]="unreadNotificationsCount"
		matBadgeSize="small"
		matBadgePosition="above before"
		matBadgeColor="peach">
		notifications
	</mat-icon>
</button>

<!-- Notifications menu -->
<mat-menu
	#webNotificationsMenu="matMenu"
	(close)="changeUnreadItemsToZero()"
	class="navbar-advanced-menu"
	aria="Notifications Menu">
	<cls-notification-container (click)="$event.stopPropagation()"></cls-notification-container>
</mat-menu>
