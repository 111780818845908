import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClsResultsOverviewComponent } from './cls-results-overview.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxChartsModule } from '@swimlane/ngx-charts';

@NgModule({
	declarations: [ClsResultsOverviewComponent],
	imports: [CommonModule, FlexLayoutModule, MatIconModule, MatTooltipModule, NgxChartsModule],
	exports: [ClsResultsOverviewComponent],
})
export class ClsResultsOverviewModule {}
