import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
	EUserDetailsFormMode,
	IUserDetailsFormComponentOptions,
} from '../../../../components/user-details-form/user-details-form.component';
import { ActivateAccountRes } from '../../../../models/auth.model';
import { IUserDetailsFormData } from '../../../../models/user.models';

@Component({
	selector: 'app-personal-information-entry',
	templateUrl: './personal-information-entry.component.html',
	styleUrls: ['./personal-information-entry.component.scss'],
})
export class PersonalInformationEntryComponent implements OnInit {
	showButtonSpinner: boolean;
	userDetailsFormOptions: IUserDetailsFormComponentOptions;
	@Input() email: string;
	@Input() activateToken: string;
	@Input() userInfo: IUserDetailsFormData;
	@Output() finish = new EventEmitter<ActivateAccountRes>();

	constructor() {}

	ngOnInit(): void {
		this.userDetailsFormOptions = {
			FormMode: EUserDetailsFormMode.Activations,
			initFormData: this.userInfo,
		};
	}

	onFormSubmit(res: ActivateAccountRes) {
		this.finish.emit(res);
	}
}
