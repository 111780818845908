import { Component, ContentChild, Input } from '@angular/core';

@Component({
	selector: 'cls-board-header',
	template: '<ng-content></ng-content>',
	styleUrls: ['./board.component.scss'],
})
// tslint:disable-next-line: component-class-suffix
export class BoardHeader {}

@Component({
	selector: 'cls-board-body',
	template: '<ng-content></ng-content>',
	styleUrls: ['./board.component.scss'],
})
// tslint:disable-next-line: component-class-suffix
export class BoardBody {}

@Component({
	selector: 'cls-board-actions',
	template: '<ng-content></ng-content>',
	styleUrls: ['./board.component.scss'],
})

// tslint:disable-next-line: component-class-suffix
export class BoardActions {}

@Component({
	selector: 'cls-board',
	templateUrl: './board.component.html',
	styleUrls: ['./board.component.scss'],
})
export class BoardComponent {
	@Input() borderPadding = '4px';
	@ContentChild(BoardHeader, { static: true }) header: BoardHeader;
}
