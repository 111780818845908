import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { environment } from '@environment';
import { ClsAlertsService } from '@ngx-common-v2/components/cls-alerts/services/cls-alerts.service';
import { SetupIntent } from '@stripe/stripe-js';
import * as guid from 'uuid';
import { Stripe } from '../components/credit-card/stripe';
import { EPaymentIntentStatus } from '../components/credit-card/stripe/stripe-definitions/payment-intent';
import { PaymentMethod } from '../components/credit-card/stripe/stripe-definitions/payment-method';
import { PaymentMethodsService } from '../components/payment-methods/payment-methods.service';
import { IRepositroyPayWithTokenModel } from '../dialogs/repository-payment-dialog/models/repository-payment-dialog.interface';
import { EProductType } from '../enums/product-type.enums';
import { PayForSubscriptionRequestModel } from '../models/payment.model';
import { PricingOffer, PricingOfferResult } from '../models/pricing.model';
import { httpFailRetry } from '../observable/http-fail-retry.pipes';
import { ECopyleaksAPP, NgxCommonPagesConfig } from '../pages/pages.config';
import { AuthService } from '../services/auth.service';
import { PaymentPlanService } from './payment-plan.service';

@Injectable()
export class PaymentService {
	private _cachedPricingOffers = new Map<string, PricingOfferResult>();
	constructor(
		private _http: HttpClient,
		private _authSvc: AuthService,
		private _stripe: Stripe,
		private _paymentMehodSvc: PaymentMethodsService,
		private _clsAlertsSvc: ClsAlertsService,
		private _paymentPlanSvc: PaymentPlanService,
		@Inject(NgxCommonPagesConfig.key) private _config: NgxCommonPagesConfig
	) {}

	async getPricingOffer(data: PricingOffer, selectedProduct?: EProductType): Promise<PricingOfferResult> {
		try {
			let _productType = selectedProduct;
			if (!selectedProduct) {
				_productType = this._authSvc.getCurrentUserProductType();
			}
			const url = `${environment.apiUrl}/v1/${_productType}/pricing/offer`;
			const offer = await this._http.post<PricingOfferResult>(url, data).toPromise();
			let user = this._authSvc.user();
			if (data.billingAddress) {
				user.countryCode = data.billingAddress.countryCode;
				this._authSvc.updateUserData(user, false);
			}
			return offer;
		} catch (error) {
			throw error;
		}
	}

	async createAndConfirmPaymentMethod(
		paymentMethod: PaymentMethod,
		updateMode: eCreditCardUpdateMode = eCreditCardUpdateMode.UpdateCreditCardAndPayment
	): Promise<SetupIntent> {
		try {
			if (!(paymentMethod && paymentMethod.id)) {
				return;
			}
			if (this._authSvc.isGuestUser) return;
			const setupIntent = await this._http
				.put<SetupIntent>(`${environment.apiUrl}/v1/account/payment-method`, {
					paymentMethodId: paymentMethod.id,
					updateMode,
					idempotencyKey: updateMode ? guid() : null,
				})
				.toPromise();
			if (!setupIntent) {
				return null;
			}
			if (
				setupIntent.status === EPaymentIntentStatus.REQUIRES_ACTION ||
				setupIntent.status === EPaymentIntentStatus.REQUIRES_PAYMENT_METHOD
			) {
				const action = (<any>setupIntent).nextAction;
				if (action && action.type === 'use_stripe_sdk') {
					const res = ((await this._stripe.confirmCardSetup((<any>setupIntent).clientSecret)) as any).setupIntent;
					if (res && res.status === EPaymentIntentStatus.SUCCEEDED) {
						this._paymentMehodSvc.clearDefaultPaymentMethod();
						return setupIntent;
					} else {
						return null;
					} // failed
				}
			} else if (setupIntent.status === EPaymentIntentStatus.SUCCEEDED) {
				this._paymentMehodSvc.clearDefaultPaymentMethod();
				return setupIntent;
			} else if (setupIntent.status === EPaymentIntentStatus.CANCELED) {
				return null; // failed
			}
			return null;
		} catch (err) {
			throw err;
		}
	}

	async createSubscription(model: PayForSubscriptionRequestModel, selectedProduct?: EProductType): Promise<Invoice> {
		try {
			let _productType = selectedProduct;
			if (!selectedProduct) {
				_productType = this._authSvc.getCurrentUserProductType();
			}
			return await this._http
				.post<Invoice>(`${environment.apiUrl}/v1/account/payments/${_productType}/pay`, {
					...model,
				})
				.toPromise();
		} catch (err) {
			throw err;
		}
	}

	async createRepoSubscription(model: IRepositroyPayWithTokenModel): Promise<RepositoryPaymentResponse> {
		try {
			return await this._http
				.post<RepositoryPaymentResponse>(`${environment.apiUrl}/v1/account/payments/repositories/pay`, {
					...model,
				})
				.toPromise();
		} catch (err) {
			throw err;
		}
	}

	async createSupportSubscription(model: PayForSubscriptionRequestModel): Promise<Invoice> {
		try {
			return (<any>await this._http
				.post<Invoice>(`${environment.apiUrl}/v1/account/supports/payments/pay`, {
					...model,
				})
				.toPromise()).invoice;
		} catch (err) {
			throw err;
		}
	}

	async checkIfBalanceUpdated(paymentId: string, productType?: EProductType): Promise<boolean> {
		try {
			const result = await this._http
				.get<IsCreditsGivenModel>(`${environment.apiUrl}/v1/account/${paymentId}/is-credits-given`)
				.pipe(httpFailRetry(5000, 12))
				.toPromise();
			if (this._config.APP != ECopyleaksAPP.Admin) this._paymentPlanSvc.updateUserBalance(false, true, true);
			return result.given;
		} catch (err) {
			if (productType == EProductType.Repositories)
				this._clsAlertsSvc.showCustomError(
					$localize`The repository have not been changed to the new plan
			 If it dosent change within 15 minutes, please write to ` +
						`<a href="mailto:support@Copyleaks.com"
			class="copyleaks-a-tag">support@copyleaks.com</a>`
				);
			else
				this._clsAlertsSvc.showCustomError(
					$localize`The pages have not been added to the account.
			 If they do not appear within 15 minutes, please write to ` +
						`<a href="mailto:support@Copyleaks.com"
			class="copyleaks-a-tag">support@copyleaks.com</a>`
				);
			throw err;
		}
	}

	async checkIfRepositoryUpdated(paymentId: string): Promise<boolean> {
		try {
			const result = await this._http
				.get<IsCreditsGivenModel>(`${environment.apiUrl}/v1/account/${paymentId}/is-credits-given`)
				.pipe(httpFailRetry(5000, 12))
				.toPromise();
			return result.given;
		} catch (err) {
			throw err;
		}
	}
}

export interface Invoice {
	payment_intent: PaymentIntentInvoice;
	id: string;
	paid: boolean;
	errorMsg: string;
}

export interface RepositoryPaymentResponse {
	invoice: Invoice;
	repoId: string;
}
export interface PaymentIntentInvoice {
	status: string;
	client_secret: string;
}

export enum eCreditCardUpdateMode {
	UpdateCreditCardWithoutPayment,
	UpdateCreditCardAndPayment,
}

export interface IsCreditsGivenModel {
	given: boolean;
}
