import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ICopyLeaksDialogComponent } from '../../../../dialogs/copyleaks-dialog/copyleaks-dialog-component/copyleaks-dialog.component';

@Component({
	selector: 'app-feature-suggestion',
	templateUrl: './feature-suggestion.component.html',
	styleUrls: ['./feature-suggestion.component.scss'],
})
export class FeatureSuggestionComponent implements OnInit, ICopyLeaksDialogComponent<IFeatureSuggestionFormData> {
	frmFeatureSuggestion: FormGroup;
	constructor(private _fb: FormBuilder) {}

	ngOnInit() {
		this.frmFeatureSuggestion = this._fb.group({
			title: ['I suggest you ', Validators.required],
			description: ['', Validators.required],
		});
	}

	async isValidComponent() {
		this.frmFeatureSuggestion.markAllAsTouched();
		return this.frmFeatureSuggestion.valid;
	}
	async getSubmittedData() {
		return this.frmFeatureSuggestion.getRawValue();
	}
}

export interface IFeatureSuggestionFormData {
	title: string;
	description: string;
}
