<div class="score-container" [style.justifyContent]="!link ? 'flex-start' : 'space-around'">
	<!--  -->
	<ng-container *ngIf="isAPIPropertyEnabled">
		<ng-container *ngIf="!isLoading && score != null && score != undefined">
			<div class="color-level-container">
				<div class="scale-back"><div class="level-bar" [class]="className"></div></div>
			</div>
			<div style="width: 35px">{{ score | number: '1.0-0' }}%</div>
		</ng-container>
		<!--  -->
		<!-- <ng-container *ngIf="isCheatDetected">
			<div class="cheat-detect-contatiner">
				<mat-icon fontSet="material-icons-round">warning_amber</mat-icon>
				<mat-chip class="mat-chip-warn" i18n>CHEAT DETECTED</mat-chip>
			</div>
		</ng-container> -->
		<!--  -->
		<ng-container *ngIf="isLoading">
			<ngx-skeleton-loader
				class="loader"
				[theme]="{
					'border-radius': '30px',
					'background-color': 'rgba(17, 41, 96, 0.3)'
				}"
				animation="progress"
				appearance="line"></ngx-skeleton-loader>
		</ng-container>
		<!--  -->
		<ng-container *ngIf="!isLoading">
			<ng-container *ngIf="link">
				<a
					role="link"
					aria-label="Click to open the scan report in a new tab"
					mat-icon-button
					class="mat-icon-button-override link-to-report-btn"
					matTooltip="Open the scan report in a new tab"
					i18n-aria-label
					i18n-matTooltip
					(click)="openSource()"
					tabIndex="0"
					(keyup.enter)="openSource()">
					<mat-icon color="primary" fontSet="material-icons-round" class="link-to-report">open_in_new</mat-icon>
				</a>
			</ng-container>
		</ng-container>
	</ng-container>
	<!--  -->
	<ng-container *ngIf="!isLoading && !isAPIPropertyEnabled">
		<div class="unused-container" i18n>DISABLED</div>
	</ng-container>
</div>
