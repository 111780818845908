import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, forwardRef, Inject, Input } from '@angular/core';
import { CardNumberElementOptions, Elements, IconStyle } from '../stripe-definitions/element';
import { StripeElement } from '../stripe-element';
import { StripeConfig, stripeConfigToken } from '../stripe-factory';

/** Stripe Card Element for Angular */
@Component({
	selector: 'cls-stripe-card-number',
	template: '',
	providers: [{ provide: StripeElement, useExisting: forwardRef(() => StripeCardNumber) }],
})
// tslint:disable-next-line: component-class-suffix
export class StripeCardNumber extends StripeElement<'cardNumber'> {
	constructor(elements: Elements, @Inject(stripeConfigToken) config: StripeConfig, ref: ElementRef<HTMLElement>) {
		super('cardNumber', elements, config, ref);
		// ref.nativeElement.classList.add("cls-strip-input-text-overraid");
	}

	/** Card specific options */
	protected get options(): CardNumberElementOptions {
		return {
			disabled: this.disabled,
			iconStyle: this.iconStyle,
			showIcon: this.showIcon,
		};
	}

	/** The brand of the Card */
	get brand(): string {
		return (super.value && super.value.brand) || '';
	}

	/** Disables the Card control */
	@Input('disabled') set disableSetter(value: boolean) {
		this.disabled = coerceBooleanProperty(value);
	}
	public disabled = false;

	/** Hides the card icon */
	@Input('showIcon') set showIconSetter(value: boolean) {
		this.showIcon = coerceBooleanProperty(value);
	}
	public showIcon: boolean;

	/** Selects the icon style */
	@Input() iconStyle: IconStyle;
}
