<div class="table-container">
	<div class="table-wrapper">
		<div *ngIf="showTableHeader" class="table-toolbar-container" fxLayout="row" fxLayoutAlign="space-between center">
			<div class="table-title">{{ tableTitle }}</div>
			<div class="table-toolbar-actions" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="15px">
				<button
					mat-icon-button
					class="icon-button"
					i18n-matTooltip
					matTooltip="Export to manage data in third-party software."
					[matTooltipDisabled]="isEmptyState"
					(click)="!isEmptyState && !hasNoSearchResults ? download() : ''"
					aria-label="Exports results.">
					<mat-icon
						[ngClass]="{ 'table-filter-icon': true, 'disabled-icon': isEmptyState || hasNoSearchResults }"
						fontSet="material-icons-round">
						download
					</mat-icon>
				</button>
				<cls-search-input
					[withLabel]="true"
					[addIconBackground]="false"
					[noBottomPadding]="true"
					[disabled]="isEmptyState"
					appearance="fill"
					i18n-placeholder
					placeholder="Search Resource"
					[msecUntilChange]="0"
					(confirmChange)="onSearchConfirm($event)"></cls-search-input>
			</div>
		</div>
		<table
			mat-table
			[dataSource]="tableDataSource"
			[style]="
				!showPaginator || hasNoSearchResults || isEmptyState
					? 'border-bottom-left-radius:10px!important;border-bottom-right-radius:10px!important'
					: ''
			">
			<!-- Result Sources -->
			<ng-container matColumnDef="results_sources">
				<th mat-header-cell *matHeaderCellDef [style.width]="!isMobile ? '80%' : '50%'" i18n>Domains</th>
				<td mat-cell *matCellDef="let element">
					<a
						*ngIf="!isEmptyState"
						class="source-container"
						mat-icon-button
						[href]="'https://' + element?.name"
						target="_blank"
						aria-label="view source">
						<mat-icon class="open-in-new" mat-cell *ngIf="element?.name?.length">open_in_new</mat-icon>
						{{ element?.name }}
					</a>
				</td>
			</ng-container>

			<!-- Times Found -->
			<ng-container matColumnDef="times_found">
				<th mat-header-cell *matHeaderCellDef style="padding: 0 5px 0 0">
					<div fxLayout="row" fxLayoutAlign="space-between center">
						<span i18n>Times Found</span>
						<button
							mat-icon-button
							class="icon-button"
							(click)="!hasNoSearchResults ? sortSources() : ''"
							i18n-matTooltip
							matTooltip="Sort">
							<mat-icon
								fontSet="material-icons-round"
								class="table-sort-icon"
								[ngClass]="{
									'table-sort-icon-selected-desc': !isTalbeAscending,
									'table-sort-icon-selected-asc': isTalbeAscending
								}"
								[style.cursor]="!hasNoSearchResults ? 'pointer' : 'default'"
								[style.color]="isEmptyState || hasNoSearchResults ? 'gray' : ''">
								sort
							</mat-icon>
						</button>
					</div>
				</th>
				<td mat-cell *matCellDef="let element" style="padding: 0 0 0 40px">
					{{ element?.value?.toLocaleString('en-US') }}
				</td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
		</table>

		<ng-container *ngIf="isEmptyState">
			<cls-contained-content class="empty-message" [isTextCentered]="true">
				<cls-contained-title>{{ emptyState.title }}</cls-contained-title>
				<cls-contained-description>
					<div fxLayout="column" fxLayoutGap="32px" fxLayoutAlign="center center">
						<div>{{ emptyState.description }}</div>
					</div>
				</cls-contained-description>
			</cls-contained-content>
		</ng-container>

		<ng-container *ngIf="hasNoSearchResults">
			<cls-contained-content [isTextCentered]="true">
				<cls-contained-title><div i18n="no_result_found_trns">No Search Results Found</div></cls-contained-title>
				<cls-contained-description>
					<div fxLayout="column" fxLayoutGap="32px" fxLayoutAlign="center center">
						<div i18n>There is no internet results to show</div>
					</div>
				</cls-contained-description>
			</cls-contained-content>
		</ng-container>
	</div>

	<ng-container *ngIf="!isEmptyState && !hasNoSearchResults">
		<div class="sum-resources-text" *ngIf="!isMobile && dataSource?.length > pageAmountOptions[0]" i18n>
			{{ dataSource.length }}
			different internet sources
		</div>
	</ng-container>
	<cls-paginator
		*ngIf="!hasNoSearchResults"
		[pageIndex]="paginateState.pageIndex"
		(page)="movePaginator($event)"
		[pageSize]="paginateState.pageSize"
		[canLoadMoreData]="canLoadMore"
		[pageSizeOptions]="pageAmountOptions"
		[showPaginator]="showPaginator"
		[receivedDataLength]="dataSource.length"></cls-paginator>
</div>
