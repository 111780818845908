import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CenteredMatMenuDirective } from './centered-mat-menu.directive';

@NgModule({
	declarations: [CenteredMatMenuDirective],
	exports: [CenteredMatMenuDirective],
	imports: [CommonModule],
})
export class CenteredMatMenuDirectiveModule {}
