export enum ESignalRConnectionState {
	Error,
	Disconnected,
	Connected,
	PendingConnection,
	Unknown,
}

export enum ESignalRProducts {
	MainWebsite = 0,
	API = 1,
	ALL = 2,
	Admin = 3,
	LMS = 4,
	Identity = 5,
}
