import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IElementsRecurrenceUpdateNotification } from '../../../models/web-notifications.models';
import { SupportedFilesService } from '../../../../services/supported-files.service';

@Component({
	selector: 'app-notification-recurrence-items-dialog',
	templateUrl: './notification-recurrence-items-dialog.component.html',
	styleUrls: ['./notification-recurrence-items-dialog.component.scss'],
})
export class NotificationRecurrenceItemsDialogComponent implements OnInit {
	get supportedFileTypes$() {
		return this._supportedFilesSvc.supportedFileTypes$;
	}
	constructor(
		private _supportedFilesSvc: SupportedFilesService,
		@Inject(MAT_DIALOG_DATA) public dialogData: IElementsRecurrenceUpdateNotification,
		public dialogRef: MatDialogRef<NotificationRecurrenceItemsDialogComponent>
	) {}

	ngOnInit(): void {
		this._initSupportedFilesAsync();
	}
	closeDialog() {
		this.dialogRef.close();
	}
	getFrequencyTypeString() {
		switch (this.dialogData?.metaData?.frequencyType) {
			case 1:
				return this.dialogData?.metaData?.frequencyPeriod === 1 ? $localize`day` : $localize`days`;
			case 7:
				return this.dialogData?.metaData?.frequencyPeriod === 1 ? $localize`week` : $localize`weeks`;
			case 30:
				return this.dialogData?.metaData?.frequencyPeriod === 1 ? $localize`month` : $localize`months`;
			default:
				return '';
		}
	}

	private async _initSupportedFilesAsync() {
		await this._supportedFilesSvc.initSupportedFileTypes();
	}
}
