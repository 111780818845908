import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { ClsAlertsService } from '@ngx-common-v2/components/cls-alerts/services/cls-alerts.service';
import { BehaviorSubject } from 'rxjs';
import { EWalletType } from '../../enums/wallet-type.enum';
import { IUserBalance } from '../../models/user-balance.models';
import { httpFailRetry } from '../../observable/http-fail-retry.pipes';
import { EGroupMemberStatus, PaymentPlanService } from '../../services/payment-plan.service';
import { GA_EVENT_ACTIONS, GA_EVENT_CATEGORY, GA_EVENT_LABELS } from '../../constants/google-analytics.consts';
import { GoogleAnalyticsService } from '../google-analytics.service';

@Injectable({
	providedIn: 'root',
})
export class SubscriptionBalanceService {
	private _platformUserBalance$ = new BehaviorSubject<IUserBalance>(null);

	get platformUserBalance$() {
		return this._platformUserBalance$;
	}

	constructor(
		private _http: HttpClient,
		private _clsAlertsSvc: ClsAlertsService,
		private _gaSvc: GoogleAnalyticsService,
		private _paymentPlanSvc: PaymentPlanService
	) {}

	init() {
		this.updateUserBalance(EWalletType.Platform);
	}

	async updateUserBalance(productType: EWalletType, force = true, handleException = false) {
		try {
			this._setBlanaceData(null);
			const balance = await this._http
				.get<IUserBalance>(`${environment.apiUrl}/v1/account/${productType}/balance?force=${force}`)
				.pipe(httpFailRetry())
				.toPromise();
			this._setBlanaceData(balance);
			return balance;
		} catch (error) {
			if (handleException) {
				this._clsAlertsSvc.showHttpResponseError(error);
			} else {
				throw error;
			}
		}
	}

	private _setBlanaceData(balance: IUserBalance) {
		this._platformUserBalance$.next(balance);
	}

	async getUserBalance(productType: EWalletType, force = true): Promise<IUserBalance> {
		try {
			return await this._http
				.get<IUserBalance>(`${environment.apiUrl}/v1/account/${productType}/balance?force=${force}`)
				.pipe(httpFailRetry())
				.toPromise();
		} catch (error) {
			throw error;
		}
	}

	//#region subscription
	async getSubscriptionState(productType: EWalletType): Promise<IUserSubscriptionState> {
		try {
			return await this._http
				.get<IUserSubscriptionState>(`${environment.apiUrl}/v1/account/${productType}/balance/subscription`)
				.pipe(httpFailRetry())
				.toPromise();
		} catch (error) {
			throw error;
		}
	}

	async cancelSubscription(productType: EWalletType, forceChange) {
		try {
			const result = await this._http
				.patch(`${environment.apiUrl}/v1/account/${productType}/balance/subscription/cancel`, { forceChange })
				.toPromise();
			this._paymentPlanSvc.updateUserBalance(false, true, true);
			return result;
		} catch (err) {
			throw err;
		}
	}

	async revertSubscriptionPlanChange(productType: EWalletType) {
		try {
			const result = await this._http
				.patch(`${environment.apiUrl}/v1/account/${productType}/balance/subscription/revert-changes`, null)
				.toPromise();
			this._paymentPlanSvc.updateUserBalance(false, true, true);
			return result;
		} catch (error) {
			throw error;
		}
	}

	async changeSubscriptionRefills(productType: EWalletType, maxRefills: number) {
		try {
			if (maxRefills > 0) {
				this._gaSvc.onEventEmite(GA_EVENT_CATEGORY.UX, GA_EVENT_ACTIONS.AUTO_REFILL_ENABLED, GA_EVENT_LABELS.REFILL);
			}
			const result = await this._http
				.patch(`${environment.apiUrl}/v1/account/${productType}/balance/subscription/refills`, { maxRefills })
				.toPromise();
			this._paymentPlanSvc.updateUserBalance(false, true, true);
			return result;
		} catch (err) {
			throw err;
		}
	}
	//#endregion subscription
}

export interface ISharedAccountBalance {
	displayName: string;
	status: EGroupMemberStatus;
	product: EWalletType;
	team: string;
	allowPersonalProfiles?: boolean;
}

export interface IUserSubscriptionState {
	nextBilling: Date;
	creditCardLastDigits: number;
	billingStatus: ESubscriptionBillingStatus;
	refills: {
		max: number;
		current: number;
	};
	currentPlan: {
		creditsPackage: number;
		displayName: string;
		planId: string;
		interval: {
			months: number;
			days: number;
		};
		price: number;
	};
	nextPlan: {
		creditsPackage: number;
		displayName: string;
		planId: string;
		interval?: {
			months: number;
			days: number;
		};
	};
}

export enum ESubscriptionBillingStatus {
	Healthy = 1,
	UnHealthy = 2,
	PaymentLockedOut = 3, // OBOSLETE
}
