import { Component, HostListener, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EPathElementGeneralType } from '../../../../enums/path-element-general-type.enum';
import { AppDirectionalityService } from '../../../../services/app-directionality.service';
import { WindowService } from '../../../../services/window.service';
import { IElementSharePersonalNotification } from '../../web-notification';
import { SharedItemsDialogComponent } from './../shared-items-dialog/shared-items-dialog.component';

@Component({
	selector: 'cls-notification-item-transfer',
	templateUrl: './notification-item-transfer.component.html',
	styleUrls: ['./notification-item-transfer.component.scss'],
})
export class NotificationItemTransferComponent {
	@Input() model: IElementSharePersonalNotification = null;
	@Input() isBackgroundTask = false;
	ePathElementGeneralType = EPathElementGeneralType;

	constructor(
		private _windowService: WindowService,
		private _matDialog: MatDialog,
		private readonly _dir: AppDirectionalityService
	) {}

	@HostListener('click', ['$event.target'])
	onClick() {
		if (this.model.metaData.sharedItems.length == 1) {
			var sharedItem = this.model.metaData.sharedItems[0];
			if (sharedItem.type == EPathElementGeneralType.Folder)
				this._windowService.open(
					`${location.origin}/dashboard/v1/account/scans?folderId=${sharedItem.elementId}`,
					'_blank'
				);
			else
				this._windowService.open(
					`${location.origin}/dashboard/v1/account/scans/${sharedItem.elementId}/report`,
					'_blank'
				);
		} else {
			this._matDialog.open(SharedItemsDialogComponent, {
				autoFocus: false,
				direction: this._dir.value,
				panelClass: ['mat-dialog-mobile-full'],
				width: '500px',
				data: this.model,
			});
		}
	}
}
