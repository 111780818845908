import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { ClsAlertsService } from '@ngx-common-v2/components/cls-alerts/services/cls-alerts.service';
import { IRepositoriesGetResponse, IRepository } from '@ngx-common/interfaces/repositories.shared.interfaces';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
@Injectable({
	providedIn: 'root',
})
export class RepositoriesService {
	private _privateRepositories$ = new BehaviorSubject<IRepository[]>(null);
	get privateRepositories$() {
		return this._privateRepositories$.asObservable();
	}
	private set _privateRepositories(value: IRepository[]) {
		this._privateRepositories$.next(value);
	}
	private get _privateRepositories() {
		return this._privateRepositories$.value;
	}
	constructor(private _http: HttpClient, private _alertSvc: ClsAlertsService) {}

	public async initPrivateRepositoriesAsync(token?: string) {
		try {
			if (this._privateRepositories) {
				return this._privateRepositories;
			}

			let headers = new HttpHeaders();
			if (token) {
				headers = new HttpHeaders().append('Authorization', `Bearer ${token}`);
			}

			const requestUrl = `${environment.apiUrl}/api/v1/repositories/get-private-repositories`;
			var response = await this._http.get<IRepositoriesGetResponse>(`${requestUrl}`, { headers }).toPromise();
			this._privateRepositories = response.repositories;
		} catch (error) {
			this._alertSvc.showHttpResponseError(error);
		}
	}
}
