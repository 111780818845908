import { IPlanDialogData } from '../../../../dialogs/payment-dialog/components/payment-plan-dialog/payment-plan-dialog.component';
import { EProductType } from '../../../../enums/product-type.enums';
import {
	EPlatformProductType,
	IPlatformProductsPlans,
	PricingPlan,
	PricingPlanItemResultItem,
} from '../../../../models/pricing.model';
import { IUserSubscriptionPlan } from '../../../../services/payment-plan.service';

export interface INewPaymentDialogComponentData {
	priceToPay?: number;
	planId?: string;
	title?: string;
	platformProductType?: EPlatformProductType;
	currentSubscriptionPlan?: IUserSubscriptionPlan;
	productType?: EProductType;
	dialogId?: string;
	anonymousUser?: boolean;
	plansDialogData?: IPlanDialogData;
	onPaymentSuccess?: () => any;
	onContinue?: (isPaymentSuccess: boolean, lastSelectedPlan?: PricingPlan) => any;
	isUpgradeToYearly?: boolean;
	isFixPaymentMethod?: boolean;
	isUpgradeToNextTier?: boolean;
	isSubscription?: boolean;
	hideBackButton?: boolean;
	plans?: IPlatformProductsPlans;
}

export interface IPlanItemData {
	productName: string;
	description: string;
	toolTipMsg: string;
	productPlan: PricingPlanItemResultItem;
	monthlyProductPlan: PricingPlanItemResultItem;
	selected: boolean;
	isCurrentPlan: boolean;
	interval: ENewPaymentDialogBillingIntervals;
	isBordered?: boolean;
	isLast?: boolean;
	hasDiscount?: boolean;
}

export interface ICheckOutSelectedPlan {
	plan: PricingPlanItemResultItem;
	credits: number;
	product: EPlatformProductType;
}

export enum ENewPaymentDialogSteps {
	ChoosePlan,
	Checkout,
	Processing,
	PurchaseMoreCredits,
}

export enum ENewPaymentDialogBillingIntervals {
	Monthly,
	Yearly,
}
