import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IBillingTemplateDialogModel } from './models/billing-template-dialog.models';
import { ScreenService } from '../../services/screen.service';
import { DISABLE_OPTION, UNLIMITED_OPTION } from '@ngx-common/constants/refill-dialog.consts';

@Component({
	selector: 'app-billing-template-dialog',
	templateUrl: './billing-template-dialog.component.html',
	styleUrls: ['./billing-template-dialog.component.scss'],
})
export class BillingTemplateDialogComponent implements OnInit {
	@Input() billingTemplate: IBillingTemplateDialogModel;
	@Output() saveButtonEvent = new EventEmitter<string>();
	@Output() closeButtonEvent = new EventEmitter<void>();

	get isMobile() {
		return this._screenSvc.isMobile();
	}

	constructor(private _screenSvc: ScreenService) {}

	ngOnInit(): void {}

	selectOption(option: any): void {
		this.billingTemplate.selectedOption = option;
	}

	saveTemplate(): void {
		this.saveButtonEvent.emit(this.billingTemplate.selectedOption);
	}

	closeTemplate(): void {
		this.closeButtonEvent.emit();
	}

	isDisabledOption(option: any): boolean {
		if (option !== DISABLE_OPTION && option !== UNLIMITED_OPTION) {
			return this.billingTemplate?.disabledOptions?.some(disabledOption => disabledOption === +option);
		}
	}
}
