import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
	ClsContainedContentComponent,
	ClsContainedDescriptionComponent,
	ClsContainedTitleComponent,
} from './cls-contained-content.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
	declarations: [ClsContainedContentComponent, ClsContainedTitleComponent, ClsContainedDescriptionComponent],
	imports: [CommonModule, FlexLayoutModule, MatIconModule],
	exports: [ClsContainedContentComponent, ClsContainedTitleComponent, ClsContainedDescriptionComponent],
})
export class ClsContainedContentModule {}
