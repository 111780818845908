<button
	*ngIf="!isMobile"
	mat-icon-button
	class="user-avatar-btn"
	fxLayoutAlign="center center"
	[matMenuTriggerFor]="accountMenu"
	[matTooltip]="ACCOUNT_TOOL_TIP + userNameToolTip">
	<ngx-avatar
		*ngIf="userAvatar"
		[gravatarId]="userAvatar?.userEmail"
		[name]="userAvatar?.name"
		[size]="userAvatar?.size"></ngx-avatar>
</button>

<div *ngIf="isMobile" fxLayout="row" fxLayoutGap="10px">
	<a mat-icon-button class="user-avatar-btn" fxLayoutAlign="center center" [href]="environment.idUrl" target="_blank">
		<ngx-avatar
			*ngIf="userAvatar"
			[gravatarId]="userAvatar?.userEmail"
			[name]="userAvatar?.name"
			[size]="userAvatar?.size"></ngx-avatar>
	</a>
	<div class="user-details-section" fxLayout="column">
		<!-- user full name -->
		<span>{{ user?.firstName }} {{ user?.lastName }}</span>
		<div class="user-details-action-container" fxLayout="row" fxLayoutAlign="space-between center">
			<!-- account settings link -->
			<a tabindex="0" [href]="environment.idUrl" target="_blank" i18n>Account settings</a>
			<div class="dot-divider">•</div>
			<!-- log out  -->
			<a
				tabindex="0"
				(click)="logoutAsync()"
				(keyup.enter)="logoutAsync()"
				(keyup.space)="logoutAsync()"
				class="log-out-link"
				i18n>
				Log out
			</a>
		</div>
	</div>
</div>

<mat-menu #accountMenu="matMenu" xPosition="before" fxLayoutAlign="start center">
	<a
		class="menu-item-container"
		mat-menu-item
		fxLayout="row"
		fxLayoutAlign="start center"
		*ngIf="app !== eCopyleaksAPP.Identity"
		[href]="environment.idUrl"
		target="_blank">
		<mat-icon>person</mat-icon>
		<span class="menu-item-text" i18n>Account Settings</span>
	</a>
	<a
		class="menu-item-container"
		mat-menu-item
		fxLayout="row"
		fxLayoutAlign="start center"
		*ngIf="app !== eCopyleaksAPP.Identity && (isUserAnOrganizationOwner || !isUserPartOfOrganization)"
		(keyup.enter)="openBillingPage()"
		(keyup.space)="openBillingPage()"
		(click)="openBillingPage()">
		<mat-icon>request_quote</mat-icon>
		<span class="menu-item-text" i18n>Billing</span>
	</a>
	<a
		class="menu-item-container"
		mat-menu-item
		fxLayout="row"
		fxLayoutAlign="start center"
		*ngIf="app == eCopyleaksAPP.Admin"
		[href]="environment.idUrl + '/security'"
		target="_blank">
		<mat-icon>lock</mat-icon>
		<span class="menu-item-text" i18n>Security</span>
	</a>
	<div class="divider"></div>
	<a
		class="menu-item-container"
		mat-menu-item
		fxLayout="row"
		fxLayoutAlign="start center"
		*ngIf="app !== eCopyleaksAPP.LMS && app !== eCopyleaksAPP.Admin && !isUserJustAnOrganizationMember"
		[href]="environment.adminWebsiteUrl + '/members'"
		target="_blank">
		<mat-icon>corporate_fare</mat-icon>
		<span class="menu-item-text" i18n>Organization</span>
	</a>
	<a
		class="menu-item-container"
		mat-menu-item
		fxLayout="row"
		fxLayoutAlign="start center"
		*ngIf="app !== eCopyleaksAPP.Admin && !isUserJustAnOrganizationMember"
		[href]="environment.adminWebsiteUrl + '/repositories'"
		target="_blank">
		<mat-icon>layers</mat-icon>
		<span class="menu-item-text" i18n>Repository</span>
	</a>
	<div class="divider"></div>
	<a
		class="menu-item-container"
		mat-menu-item
		fxLayout="row"
		fxLayoutAlign="start center"
		*ngIf="app !== eCopyleaksAPP.Admin && app !== eCopyleaksAPP.API && app !== eCopyleaksAPP.LMS"
		[href]="environment.mainWebsiteUrl + '/affiliate'"
		target="_blank">
		<mat-icon>card_giftcard</mat-icon>
		<span class="menu-item-text" i18n>Earn with Copyleaks</span>
	</a>
	<a
		class="menu-item-container"
		mat-menu-item
		fxLayout="row"
		fxLayoutAlign="start center"
		href="https://copyleaks.com/legal/termsofuse"
		target="_blank">
		<mat-icon>offline_pin</mat-icon>
		<span class="menu-item-text" i18n>Terms of use</span>
	</a>
	<div class="divider"></div>
	<a
		class="menu-item-container"
		mat-menu-item
		fxLayout="row"
		fxLayoutAlign="start center"
		(click)="logoutAsync()"
		(keyup.enter)="logoutAsync()"
		(keyup.space)="logoutAsync()">
		<mat-icon>power_settings_new</mat-icon>
		<span class="menu-item-text" i18n>Log Out</span>
	</a>
</mat-menu>
