<div class="panel-wrapper" [style.margin]="margin" [style.height]="height" [style.background-color]="backgroundColor">
	<div class="panel-title">
		<div class="panel-title-spacer">
			<ng-content select="cls-panel-header"></ng-content>
		</div>
		<ng-content select="cls-panel-actions"></ng-content>
	</div>
	<div class="panel-body" [style.padding]="padding" [style.background-color]="backgroundColor">
		<ng-content select="cls-panel-body"></ng-content>
	</div>
</div>
