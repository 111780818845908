import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { IPaginateData } from 'ngx-common-v2/components/cls-paginator/models/cls-paginator.models';
import { EPlatform } from 'shared/enums';
import { EScanStatus } from 'shared/models';
import { IDateRange } from '../../models/integration-level.models';
import {
	ESortByStudentSubmissionsTable,
	ISortAndPaginateStudentSubmissionsTable,
	IStudentSubmissionsAnalyticsTableData,
} from '../../models/analytics-student.models';
import { AnalyticsDataService } from '../../services/analytics-data.service';
import { ClsAlertsService } from 'ngx-common-v2/components/cls-alerts/services/cls-alerts.service';
import { downloadDataToCsv } from 'ngx-common-v2/utils/csv_downloader.utils';
import { Router } from '@angular/router';

// static data import

@Component({
	selector: 'lms-analytics-student-submissions-table',
	templateUrl: './analytics-student-submissions-table.component.html',
	styleUrls: ['./analytics-student-submissions-table.component.scss'],
})
export class AnalyticsStudentSubmissionsTableComponent implements OnInit {
	@Input() dateRange: IDateRange;
	@Input() platformType: EPlatform;
	@Input() integrationId: string;
	@Input() studentId: string;
	@Input() isLoadFromDashboard = true;
	@Input() token: string;
	@Input() writingFeedbackFeatureEnabled = false;

	ePlatformType = EPlatform;
	eScanStatus = EScanStatus;
	eSortBy = ESortByStudentSubmissionsTable;
	eSortColumn = ESortByStudentSubmissionsTable.NoSort;

	isTableEmpty = false;
	isTalbeAscending: boolean = false;
	isLoading = false;
	noMoreDataToLoad = false;

	tableSortAndPaginateState: ISortAndPaginateStudentSubmissionsTable;
	tableDataSource: MatTableDataSource<IStudentSubmissionsAnalyticsTableData> =
		new MatTableDataSource<IStudentSubmissionsAnalyticsTableData>();
	tableTotalDataLength = 0;
	/**
	 * The columns in the table
	 */
	displayedColumns: string[] = [
		'submitted_documents',
		'assignment',
		'course',
		'ai_content_detected',
		'plagiarism_score',
		'writingFeedback_score',
		'submission_date',
	];
	corsursArray: string[] = [];

	// Paginaor
	minDefaultPageSize = 10;
	pageSizeOptions = [this.minDefaultPageSize, 50, 100];
	paginateState: IPaginateData = {
		pageIndex: 1,
		preIndex: 0,
		pageSize: this.minDefaultPageSize,
	};

	/**
	 * Whether or not to show paginator.
	 */
	get showPaginator() {
		if (this.paginateState.pageIndex === 1)
			return this.tableDataSource?.data?.length >= this.minDefaultPageSize && !this.isLoading && !this.isTableEmpty;
		return !this.isTableEmpty && !this.isLoading;
	}

	/**
	 * If we can load more data with the paginator.
	 */
	get canLoadMore() {
		return !this.noMoreDataToLoad && this.tableDataSource?.data?.length >= this.paginateState.pageSize;
	}
	// Paginaor

	EXPORT_DATA_TOOLTIP = $localize`Export to manage data in third-party software.`;
	NOT_ACCEPT_EULA_TOOLTIP = $localize`The user did not accept EULA for this submission.`;
	SECURITY_PREMISSION_TOOLTIP = $localize`Due to security permissions, you may not have access to this report, in order to access it, you will need to navigate to the analytics area for this specific assignment.`;
	VIEW_STUDENT_REPORT_TOOLTIP = $localize`View student report.`;
	VIEW_ACTIVITY_ANALYTICS_TOOLTIP = $localize`View assignment analytics.`;
	VIEW_COURSE_ANALYTICS_TOOLTIP = $localize`View course analytics.`;

	constructor(
		private _analyticsService: AnalyticsDataService,
		private _clsAlertSvc: ClsAlertsService,
		private _router: Router
	) {}

	ngOnInit(): void {
		this.tableSortAndPaginateState = {
			amount: this.minDefaultPageSize,
			corsur: null,
			dateRange: this.dateRange,
			IsDescending: this.isTalbeAscending,
			sortBy: this.eSortColumn,
			startFrom: 0,
		};
		this.setTableViewData([]);
		this.getTableDataAsync();
	}

	async getTableDataAsync() {
		try {
			this.isLoading = true;
			let response = await this._analyticsService.getStudentSubmissionsTableData(
				this.platformType,
				this.integrationId,
				this.studentId,
				this.tableSortAndPaginateState,
				this.token
			);
			// push new corsur to corsur array
			if (response.corsur?.length && response.submissions.length) this.corsursArray.push(response.corsur);

			response.submissions?.map(s => {
				try {
					s.documentName = decodeURI(s.documentName);
				} catch (error) {}
			});
			// set new pagination state corusr
			this.tableSortAndPaginateState.corsur =
				this.corsursArray.length != 0 ? this.corsursArray[this.corsursArray.length - 1] : '';

			// set overall data that has been fetched so far -> for paginator.
			this.tableTotalDataLength =
				(this.paginateState.pageIndex - 1) * this.paginateState.pageSize + response.submissions.length;

			if (this.paginateState.pageIndex != 1 && !response.submissions?.length) {
				this.noMoreDataToLoad = true;
				this.paginateState = {
					...this.paginateState,
					preIndex: this.paginateState.preIndex - 1,
					pageIndex: this.paginateState.pageIndex - 1,
				};
			} else {
				this.noMoreDataToLoad = false;
				this.setTableViewData(response.submissions);
			}
		} catch (error) {
			if (!this._analyticsService.redirectOnErroredRequest(error)) this._clsAlertSvc.showSupportError();
		} finally {
			this.isLoading = false;
		}
	}

	setTableViewData(data: IStudentSubmissionsAnalyticsTableData[]) {
		if (!data.length) this.setEmptyResultsTable();
		else {
			this.isTableEmpty = false;
			this.tableDataSource.data = data;
		}
	}

	setEmptyResultsTable() {
		this.tableDataSource.data = Array.from({ length: 11 });
		this.isTableEmpty = true;
	}

	// get sorted table data by submission date.
	sortBySubmissionDate() {
		this.eSortColumn = this.eSortBy.SubmissionDate;
		this.isTalbeAscending = !this.isTalbeAscending;
		this.corsursArray = [];
		this.paginateState = {
			pageIndex: 1,
			preIndex: 0,
			pageSize: this.paginateState.pageSize,
		};
		this.tableSortAndPaginateState = {
			amount: this.paginateState.pageSize,
			startFrom: 0,
			searchStr: '',
			sortBy: this.eSortColumn,
			IsDescending: this.isTalbeAscending,
			corsur: '',
			dateRange: this.dateRange,
		};
		this.getTableDataAsync();
	}

	resetPaginaotrState() {
		this.paginateState.pageIndex = 1;
		this.paginateState.preIndex = 0;
	}

	movePaginator(paginateData: IPaginateData) {
		if (this.paginateState.pageSize != paginateData.pageSize) {
			this.resetPaginaotrState();
			this.paginateState.pageSize = paginateData.pageSize;
			this.corsursArray = [];
			this.tableSortAndPaginateState = {
				...this.tableSortAndPaginateState,
				amount: this.paginateState.pageSize,
				corsur: '',
				startFrom: 0,
			};
			this.getTableDataAsync();
			return;
		}

		if (this.paginateState.pageIndex < paginateData.pageIndex) {
			this.paginateState = paginateData;
			this.tableSortAndPaginateState = {
				...this.tableSortAndPaginateState,
				amount: this.paginateState.pageSize,
				startFrom: this.paginateState.preIndex * this.paginateState.pageSize,
			};
		} else {
			this.paginateState = paginateData;
			this.corsursArray.pop();
			this.corsursArray.pop();
			this.tableSortAndPaginateState = {
				...this.tableSortAndPaginateState,
				amount: this.paginateState.pageSize,
				corsur: this.corsursArray.length ? this.corsursArray.pop() : '',
				startFrom:
					this.paginateState.pageIndex == 1 ? 0 : (this.paginateState.pageIndex - 1) * this.paginateState.pageSize,
			};
		}
		this.getTableDataAsync();
	}

	// Download table data.
	exortTableData() {
		const dataToExport = this.tableDataSource.data.map(s => {
			const submissionDate = new Date(s.submissionDate);

			return {
				'Submitted documents': s.documentName.replace(/,/g, ' '),
				'Assignment name': s.activityName.replace(/,/g, ' '),
				'Course name':
					this.platformType === this.ePlatformType.Canvas
						? `Course Id - {${s.contextId}}`
						: s.contextName.replace(/,/g, ' '),
				'AI content detected':
					s.aiScore != null && s.scanStatus === EScanStatus.Completed
						? s.aiScore
						: s.errorMessage
						? $localize`Failed Scan`
						: s.scanStatus === EScanStatus.InProgress
						? $localize`---`
						: $localize`Disabled`,
				'Plagiarism score':
					s.plagiarismScore != null && s.scanStatus === EScanStatus.Completed
						? s.plagiarismScore
						: s.errorMessage
						? $localize`Failed Scan`
						: s.scanStatus === EScanStatus.InProgress
						? $localize`---`
						: $localize`Disabled`,
				'Writing Correction':
					s.writingFeedback != null && s.scanStatus === EScanStatus.Completed && this.writingFeedbackFeatureEnabled
						? s.writingFeedback
						: s.errorMessage
						? $localize`Failed Scan`
						: s.scanStatus === EScanStatus.InProgress
						? $localize`---`
						: $localize`Disabled`,
				'Submission date': submissionDate.toLocaleDateString() + ' ' + submissionDate.toLocaleTimeString(),
			};
		});

		downloadDataToCsv(dataToExport, 'Submissions_Data', 'MMM Do YYYY h:mm a');
	}

	async openReportPageAsync(item: IStudentSubmissionsAnalyticsTableData) {
		try {
			if (item.isReportPageLoading || !item.scanId) return;
			item.isReportPageLoading = true;
			await this._analyticsService.requestAccessToReportPage(
				this.platformType,
				this.integrationId,
				item.activityId,
				item.scanId,
				this.token,
				this.isLoadFromDashboard
			);
		} catch (error) {
			this._clsAlertSvc.showSupportError();
		} finally {
			item.isReportPageLoading = false;
		}
	}

	// open to activity page.
	openActivityAnalyticsPage(assignmentId: string) {
		if (assignmentId)
			this._router.navigate([`lms/${this.platformType}/analytics/${this.integrationId}/activity/${assignmentId}`]);
	}
	// open to course page.
	openCourseAnalytics(courseId: string) {
		if (courseId)
			this._router.navigate([`lms/${this.platformType}/analytics/${this.integrationId}/course/${courseId}`]);
	}
}
