import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
	LmsIntegrationItemComponent,
	LmsIntegrationItemContentComponent,
	LmsIntegrationItemMenuComponent,
} from './lms-integration-item.component';
import { LmsLti1_3IntegrationItemBadgeModule } from '../lms-integration-item-badge/lms-integration-item-badge.module';
import { DateFromNowPipeModule } from '@ngx-common/pipes/date-from-now/date-from-now-pipe.module';
import { MatIconModule } from '@angular/material/icon';
import { ClsButtonModule } from 'ngx-common-v2/components/cls-button/cls-button.module';
import { MatMenuModule } from '@angular/material/menu';

import { FlexLayoutModule } from '@angular/flex-layout';
import { HighlightSearchedValuePipeModule } from 'ngx-common-v2/pipes/highlight-searched-value/highlight-searched-value-pipe.module';

@NgModule({
	declarations: [LmsIntegrationItemComponent, LmsIntegrationItemContentComponent, LmsIntegrationItemMenuComponent],
	imports: [
		CommonModule,
		LmsLti1_3IntegrationItemBadgeModule,
		DateFromNowPipeModule,
		MatMenuModule,
		MatIconModule,
		HighlightSearchedValuePipeModule,
		ClsButtonModule,
		FlexLayoutModule,
	],

	exports: [LmsIntegrationItemComponent, LmsIntegrationItemContentComponent, LmsIntegrationItemMenuComponent],
})
export class LmsIntegrationItemModule {}
