import { Component, Inject, OnInit } from '@angular/core';
import { environment } from '@environment';
import { ECopyleaksAPP, NgxCommonPagesConfig } from '@ngx-common/pages/pages.config';
import { ScreenService } from '@ngx-common/services/screen.service';

@Component({
	selector: 'app-mobile-bottom-menu',
	templateUrl: './mobile-bottom-menu.component.html',
	styleUrls: ['./mobile-bottom-menu.component.scss'],
})
export class MobileBottomMenuComponent implements OnInit {
	dashboardMainPageRoute = 'dashboard/v1';
	apiUrl = 'dashboard';
	documentationRoute = 'documentation/v3';

	app: ECopyleaksAPP;
	eCopyleaksAPP = ECopyleaksAPP;

	get isMobile() {
		return this._screenSvc?.isMobile();
	}

	constructor(
		private _screenSvc: ScreenService,
		@Inject(NgxCommonPagesConfig.key) private _config: NgxCommonPagesConfig
	) {}

	ngOnInit(): void {
		this.app = this._config?.APP;
	}
}
