export const ApiEndpoints = {
	Account: '/api/account',
	Institutes: '/api/institutes',
	Assignment: '/api/assignment',
};

export const Cookies = {
	SecondaryCookie: 'dashboard-secondary-cookie',
};

export const LocalStorageKey = {
	User: 'lti-dashboard-user',
};
