import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as shape from 'd3-shape';
import * as moment from 'moment';
import { EGraphToScrollTo } from 'ngx-common-v2/components/analytics-components/templates/cls-results-insights-template/models/cls-card-data.model';
import { ClsAlertsService } from 'ngx-common-v2/components/cls-alerts/services/cls-alerts.service';
import { ClsNgxChartUtils } from 'ngx-common-v2/utils/ngx-charts.utils';
import {
	EGeneralLMSIntegrationStatus,
	EIntegrationConsumer,
} from 'projects/Dashboard/src/app/pages/integrations/models/integrations.enums ';
import { GeneralLMSIntegrationModel } from 'projects/Dashboard/src/app/pages/integrations/models/integrations.models';
import { IntegrationsLinksDialogComponent } from 'projects/Dashboard/src/app/pages/lti1_3-integrations/dialogs/integrations-links-dialog/integrations-links-dialog.component';
import { IIntegrationsLinksDialogData } from 'projects/Dashboard/src/app/pages/lti1_3-integrations/dialogs/integrations-links-dialog/models/integrations-links-dialog.models';
import { ILti1_3IntegrationItemModel } from 'shared/models/lti1_3-integration.model';
import { IDateRange, IIntegrationLevelOverview } from '../../models/integration-level.models';
import { AnalyticsDataService } from '../../services/analytics-data.service';
import { AnalyticsConstants } from 'shared/constants/analytics';
import { NewDashboardNavbarV2Service } from '@ngx-common/components/new-dashboard-navbar-v2/services/new-dashboard-navbar-v2.service';
import { EAnalyticsLevel } from '@ngx-common-v2/components/analytics-components/models/cls-analytics.models';

@Component({
	selector: 'lms-integration-level',
	templateUrl: './analytics-integration.component.html',
	styleUrls: ['./analytics-integration.component.scss'],
})
export class AnalyticsIntegrationComponent implements OnInit, OnDestroy {
	dataSrc: IIntegrationLevelOverview;
	integrationItemData: GeneralLMSIntegrationModel;
	isLoading: boolean = false;
	currentDate: Date = new Date();
	baseStartDate: Date = AnalyticsConstants.ANALYTICS_COLLECTION_DATE;

	/**
	 * The Date range the user picked -> initialized with defaults
	 */
	dateRange: IDateRange = {
		startMonth: this.baseStartDate.getMonth() + 1,
		startYear: this.baseStartDate.getFullYear(),
		endMonth: this.currentDate.getMonth() + 1,
		endYear: this.currentDate.getFullYear(),
	};

	platformType: EIntegrationConsumer;
	ePlatformType = EIntegrationConsumer;
	EGeneralLMSIntegrationType = EIntegrationConsumer;
	EGeneralLMSIntegrationStatus = EGeneralLMSIntegrationStatus;
	eScrollToType = EGraphToScrollTo;
	eLevelType = EAnalyticsLevel;

	integrationId: string;

	lineChartCustomColors = [
		{ name: $localize`AI TEXT`, value: '#C1ADDC' },
		{ name: $localize`MATCHED TEXT`, value: '#FD7366' },
	];
	incidentsText: string = $localize`INCIDENTS`;
	correctionText: string = $localize`CORRECTION`;
	submissionsText: string = $localize`SUBMISSIONS`;
	resultsText: string = $localize`RESULTS`;
	loadingMessage = $localize`Loading data...`;
	registrationLinkMessage = $localize`Registration URL:`;
	keyMessage = $localize`Key:`;
	secretMessage = $localize`Secret:`;
	isFirstLoad = true;

	linearCurve = shape.curveBumpX;
	chartView: number[];
	lineChartView: number[];
	writingFeedbackChartView: number[];

	ANALYTICS_COLLECTION_DATE = AnalyticsConstants.ANALYTICS_COLLECTION_DATE;

	@ViewChild('insightsItem') insightsItemElementRef: ElementRef<HTMLDivElement>;

	constructor(
		private _newDashboradNavSvc: NewDashboardNavbarV2Service,
		private _analyticsDataService: AnalyticsDataService,
		private _route: ActivatedRoute,
		private _clsAlertSvc: ClsAlertsService,
		private _router: Router,
		private _dialogSvc: MatDialog
	) {}

	ngOnInit() {
		this.platformType = Number(this._route.snapshot.paramMap.get('platformType'));
		this.integrationId = this._route.snapshot.paramMap.get('integrationId');
		let gap = Math.abs(moment(this.baseStartDate).diff(this.currentDate, 'years', true));
		if (gap > 1) {
			this.baseStartDate.setFullYear(this.currentDate.getFullYear() - 1);
			this.dateRange = {
				startMonth: this.baseStartDate.getMonth() + 1,
				startYear: this.baseStartDate.getFullYear(),
				endMonth: this.currentDate.getMonth() + 1,
				endYear: this.currentDate.getFullYear(),
			};
		}

		this._newDashboradNavSvc.removeSidePadding = true;

		// this.initBalance();
		this.getIntegrationLevelDataAsync();
	}

	/**
	 * Get all Integration level data
	 */
	async getIntegrationLevelDataAsync() {
		try {
			this.isLoading = true;
			this.dataSrc = await this._analyticsDataService.getIntegrationLevelDataAsync(
				this.platformType,
				this.integrationId,
				this.dateRange
			);
			if (this.isFirstLoad) {
				this.isFirstLoad = false;
				this.dateRange.startMonth = new Date(this.dataSrc.startAnalyticsDate).getMonth() + 1;
				this.dateRange.startYear = new Date(this.dataSrc.startAnalyticsDate).getFullYear();
				this.baseStartDate.setMonth(this.dataSrc.startAnalyticsDate.getMonth());
				this.baseStartDate.setFullYear(this.dataSrc.startAnalyticsDate.getFullYear());
			}
			this.integrationItemData = this.dataSrc?.integrationData;
			this.setBreadCrumbs();

			if (!this.dataSrc.writingFeedbackFeatureEnabled) {
				this.dataSrc.cardsData.avgWritingFeedback.value = 'N/A';
				this.dataSrc.cardsData.avgWritingFeedback.isDisabled = true;
			}
		} catch (error) {
			if (!this._analyticsDataService.redirectOnErroredRequest(error)) this._clsAlertSvc.showSupportError();
		} finally {
			this.isLoading = false;
			if (this.dataSrc && this.dataSrc.isStatisticsExists)
				setTimeout(() => {
					this.onResize(null, this.insightsItemElementRef.nativeElement);
				}, 100);
		}
	}

	setBreadCrumbs() {
		this._newDashboradNavSvc.setBreadCrumbNavigation([
			{
				name: $localize`Integrations`,
				link: '/lti/v1.3/institues',
			},
			{
				name: this.dataSrc?.integrationName,
				link: `/dashboard/${this.platformType}/analytics/${this.integrationId}`,
			},
		]);
	}

	formatYAxisTicks(tick: any): string {
		return `+${tick}`;
	}

	dateRangeChange(newDateRange: IDateRange) {
		this.dateRange = {
			...newDateRange,
		};
		this.getIntegrationLevelDataAsync();
	}

	onResize(event, insightsItem?: HTMLDivElement) {
		this.chartView = null;
		this.lineChartView = null;
		this.writingFeedbackChartView = null;
		setTimeout(() => {
			if (insightsItem) {
				this.chartView = [insightsItem.offsetWidth - 40, insightsItem.offsetHeight + 5];
				this.lineChartView = [insightsItem.offsetWidth + 70, insightsItem.offsetHeight - 60];
				this.writingFeedbackChartView = [insightsItem.offsetWidth - 40, insightsItem.offsetHeight - 30];
			}
			setTimeout(() => {
				this.checkGraphsData();
			}, 100);
		}, 100);
	}
	/**
	 * Scrolls into a graph and highlight it.
	 * @param scrollTo - @type @enum EGraphToScrollTo - The type of garph to scroll to.
	 */
	scrollToGraph(scrollTo: EGraphToScrollTo) {
		const grpahView = document.getElementById(String(scrollTo));
		if (grpahView) {
			grpahView.animate(
				[
					{
						boxShadow: '-2px -2px 8px 0px rgb(63, 154, 245), 2px 2px 4px 0px rgb(63, 154, 245)',
						borderRadius: '10px',
					},
					{
						boxShadow: '-2px -2px 8px 0px rgba(255, 255, 255, 0.5), 2px 2px 4px 0px rgba(0, 0, 0, 0.15)',
						borderRadius: '10px',
					},
				],
				{ duration: 1500, easing: 'ease-in-out' }
			);
			grpahView.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}
	}

	checkGraphsData() {
		const el = ClsNgxChartUtils.GetChartsLineSeriesPathElementsRef();
		// Checking if graphs have only one dot
		if (this.dataSrc?.originalityData?.chartData[0]?.series?.length === 1) {
			ClsNgxChartUtils.setGraphPointStroke(el, 0);
		}
		if (this.dataSrc?.originalityData?.chartData[1].series.length === 1) {
			ClsNgxChartUtils.setGraphPointStroke(el, 1);
		}
		if (this.dataSrc?.crossLanguageData.chartData[0].series.length === 1) {
			ClsNgxChartUtils.setGraphPointStroke(el, 2);
		}
		if (
			this.dataSrc?.crossLanguageData?.chartData[0].series.length <= 1 &&
			this.dataSrc?.cheatsFoundData?.chartData[0].series.length === 1
		) {
			ClsNgxChartUtils.setGraphPointStroke(el, 3);
		}
		if (
			this.dataSrc?.crossLanguageData?.chartData[0].series.length != 0 &&
			this.dataSrc?.cheatsFoundData?.chartData[0].series.length === 1
		) {
			ClsNgxChartUtils.setGraphPointStroke(el, 4);
		}
	}

	/**
	 * Opens the integration default scan settings page according to the integration type
	 * @param platform
	 */
	openSettings(integration: GeneralLMSIntegrationModel) {
		let route = 'dashboard/';
		switch (integration.type) {
			case EIntegrationConsumer.LTI1_3:
				route += 'lti1.3';
				break;
			case EIntegrationConsumer.Canvas:
				route += 'canvas';
				break;
			case EIntegrationConsumer.Moodle:
				route += 'moodle';
				break;
			default:
				throw new Error(`Unknown integration type: ${integration.type}`);
		}
		this._router.navigateByUrl(
			`${route}/settings/${this.integrationId}/${integration?.metaData?.defaultScanSettingsId}`
		);
	}

	/**
	 * Opens the dialog for LTI 1.3 setup links.
	 * @param integration The LTI 1.3 integration
	 */
	openIntegrationLinksDialog(integration: GeneralLMSIntegrationModel): void {
		const metaData = integration.metaData as ILti1_3IntegrationItemModel;
		if (!metaData) return;
		this._dialogSvc.open(IntegrationsLinksDialogComponent, {
			autoFocus: false,
			width: '400px',
			data: {
				integrationInfo: integration,
			} as IIntegrationsLinksDialogData,
		});
	}

	/**
	 * Opens the cross language guide for empty state.
	 */
	openCrossLanguageGuideLink() {
		window.open('https://copyleaks.com/features/cross-language-plagiarism-detection');
	}

	ngOnDestroy(): void {
		this._newDashboradNavSvc.removeSidePadding = false;
	}
}
