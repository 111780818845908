import { ActivatedRoute, Router } from '@angular/router';
import { PricingPageForm } from './pricing-form.model';

export class PricingPageRoutings {
	public static MAIN_PRODUCT_PATH = 'pricing/step/:step';
	public static OTHER_PRODUCT_PATH = 'pricing';

	public static MAIN_PRODUCT_PATH_PARAMS = {
		STEP: 'step',
	};
	public static MAIN_PRODUCT_QUERY_PARAMS = {
		PLAN_ID: 'planId',
		PRODUCT_TYPE: 'productType',
		COUPON: 'coupon',
		LEAD_NAME: 'lead-name',
	};

	public static get SUPPORT_PATH(): string {
		return 'pricing/supports';
	}

	public static async PageRoutingsAndHistoryHandler(config: PageRoutingsAndHistoryHandlerConfig) {
		const { formData, router, activatedRoute, addToHistory } = config;
		const { MAIN_PRODUCT_QUERY_PARAMS: QUERY_PARAMS } = this;
		let pagePath = PricingPageRoutings.MAIN_PRODUCT_PATH;
		const queryParams = {
			...activatedRoute.snapshot.queryParams,
		};

		if (formData.step) {
			pagePath = pagePath.replace(`:${this.MAIN_PRODUCT_PATH_PARAMS.STEP}`, formData.step.toString());
		}
		if (formData.step === 2) {
			if (formData?.plan?.selected?.id) {
				queryParams[QUERY_PARAMS.PLAN_ID] = formData.plan.selected.id;
			}
		}

		await router.navigate([`/${pagePath}`], {
			replaceUrl: !addToHistory,
			queryParams: formData.paymentSuccess ? {} : queryParams,
		});
	}
}

export class PageRoutingsAndHistoryHandlerConfig {
	constructor(
		public router: Router,
		public activatedRoute: ActivatedRoute,
		public formData: PricingPageForm,
		public addToHistory = true
	) {}
}
