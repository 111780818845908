import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClsAlertsService } from '@ngx-common-v2/components/cls-alerts/services/cls-alerts.service';
import { PaymentService } from '../../services/payment.service';

@Component({
	selector: 'cls-payment-processing',
	templateUrl: './payment-processing.component.html',
	styleUrls: ['./payment-processing.component.scss'],
})
export class PaymentProcessingComponent implements OnInit {
	@Input() invoiceId: string;
	@Output() balanceUpdated = new EventEmitter<boolean>();
	constructor(private _paymentSvc: PaymentService, private _clsAlertsSvc: ClsAlertsService) {}

	ngOnInit(): void {
		this._checkIfBalanceUpdated();
	}
	private async _checkIfBalanceUpdated() {
		try {
			await this._paymentSvc.checkIfBalanceUpdated(this.invoiceId);
			this.balanceUpdated.emit(true);
		} catch (error) {
			this._clsAlertsSvc.showHttpResponseError(error);
		}
	}
}
