<div class="title-container" fxLayout="row" fxLayoutAlign="space-between center">
	<span class="title">
		<ng-container [ngSwitch]="data?.integrationInfo?.type">
			<ng-container *ngSwitchCase="EGeneralLMSIntegrationType.LTI1_3" i18n>Setup URL's</ng-container>
			<ng-container *ngSwitchDefault i18n>Credentials</ng-container>
		</ng-container>
	</span>
	<button mat-icon-button class="close-btn" (click)="closeDialog()" i18n-matTooltip matTooltip="Close">
		<mat-icon class="close-icon">close</mat-icon>
	</button>
</div>

<div class="links-container" fxLayout="column" fxLayoutGap="16px" fxLayoutAlign="center start">
	<ng-container [ngSwitch]="data?.integrationInfo?.type">
		<!-- Links for LTI 1.3 integration -->
		<ng-container *ngSwitchCase="EGeneralLMSIntegrationType.LTI1_3">
			<cls-copy-link [linkName]="domainLink" [linkSrc]="integrationLinks.domain" [linkSrcLength]="45"></cls-copy-link>
			<cls-copy-link
				[linkName]="OIDCLink"
				[linkSrc]="integrationLinks.oidcLoginURL"
				[linkSrcLength]="45"></cls-copy-link>
			<cls-copy-link
				[linkName]="redirectLink"
				[linkSrc]="integrationLinks.redirectURL"
				[linkSrcLength]="45"></cls-copy-link>
			<cls-copy-link
				[linkName]="keysetLink"
				[linkSrc]="integrationLinks.keysetURL"
				[linkSrcLength]="45"></cls-copy-link>
		</ng-container>
		<!-- Links for MPP integration -->
		<ng-container *ngSwitchCase="EGeneralLMSIntegrationType.Moodle">
			<cls-copy-link
				[linkName]="keyMessage"
				[linkSrc]="data?.integrationInfo?.metaData?.key"
				[linkSrcLength]="45"></cls-copy-link>
			<cls-copy-link
				[linkName]="secretMessage"
				[linkSrc]="data?.integrationInfo?.metaData?.accessSecret"
				[linkSrcLength]="20"
				[addViewOption]="true"></cls-copy-link>
		</ng-container>
		<ng-container *ngSwitchDefault></ng-container>
	</ng-container>
</div>
