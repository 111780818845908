import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClsScoreLevelComponent } from './cls-score-level.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { MatChipsModule } from '@angular/material/chips';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
	declarations: [ClsScoreLevelComponent],
	imports: [
		CommonModule,
		FlexLayoutModule,
		MatIconModule,
		NgxSkeletonLoaderModule,
		MatChipsModule,
		MatTooltipModule,
		MatButtonModule,
	],
	exports: [ClsScoreLevelComponent],
})
export class ClsScoreLevelModule {}
