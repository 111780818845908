import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BillingTemplateDialogComponent } from './billing-template-dialog.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { ClsButtonModule } from '@ngx-common-v2/components/cls-button/cls-button.module';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
	declarations: [BillingTemplateDialogComponent],
	exports: [BillingTemplateDialogComponent],
	imports: [
		CommonModule,
		FlexLayoutModule,
		MatFormFieldModule,
		MatDividerModule,
		MatButtonModule,
		ClsButtonModule,
		MatMenuModule,
		MatIconModule,
	],
})
export class BillingTemplateDialogModule {}
