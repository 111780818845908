import { EScanPageMode, IFolderSegment, IPathElement } from './elements.models';
import { UserScanData } from './user-scans-models';

export interface IMemberToInvite {
	id?: string;
	departmentId?: string;
	name?: string;
	email?: string;
	type: EMemberSharedType;
	role?: EElementShareRole;
	totalOwners?: number;
	backgourndColor?: string;
	icon?: string;
	isDisablesSelectRole?: boolean;
	isDirectShare?: boolean;
}

export enum EElementShareRole {
	REMOVE,
	VIEWER,
	EDITOR,
	VARIES,
	OWNER,
	TRANSFER,
}

export enum EMemberSharedType {
	USER = 1,
	TEAM,
}

export interface IInviteMemebers extends IRequestInvitation {
	members: IMemberToInvite[];
	role: EElementShareRole;
}

export interface IRequestInvitation {
	ids: string[];
	scanPageMode?: EScanPageMode;
	isSearchForMult?: boolean;
}
export interface IUpdateMemberRole extends IRequestInvitation {
	role: EElementShareRole;
	type: EMemberSharedType;
	memberId: string;
}

export interface IScanToShare {
	userRole: EElementShareRole;
	scanElement: IPathElement;
}

export interface IOpenShareModel {
	selected: UserScanData[];
	scanId?: string;
	invitedMembers: IMemberToInvite[];
	scanPageMode: EScanPageMode;
	segments: IFolderSegment[];
	isSearchForMult: boolean;
	onShareMembersUpdate?: (members: IMemberToInvite[]) => void;
	onShareDialogClose?: (isClosed: boolean) => void;
}
