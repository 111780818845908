import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
	selector: 'cls-info-card',
	templateUrl: './cls-info-card.component.html',
	styleUrls: ['./cls-info-card.component.scss'],
})
export class ClsInfoCardComponent implements OnInit, OnChanges {
	/**
	 * The card width
	 * i.e. : cardWidth="300px"
	 * @Input
	 */
	@Input() cardWidth: string;

	/**
	 * The card height
	 * i.e. : cardHeight="300px"
	 * @Input
	 */
	@Input() cardHeight: string;

	/**
	 * Flag that indicates whether card info is being loaded or not, if it is loaded then a loading view will be shown
	 * @Input
	 */
	@Input() isLoading: boolean;

	/**
	 * Flag that indicates whether card info is being loaded or not, if it is loaded then a loading view will be shown
	 * @Input
	 */
	@Input() hideIconContainer: boolean = false;

	/**
	 * Flag that indicates whether to show icon or not
	 * @Input
	 */
	@Input() showIcon: boolean = true;

	cardStyle: any = {};

	constructor() {}

	ngOnChanges(changes: SimpleChanges): void {
		// update the styles object according to the changes of the card width and card height
		if (changes.cardWidth) {
			this.cardStyle = {
				...this.cardStyle,
				width: this.cardWidth,
			};
		}
		if (changes.cardHeight) {
			this.cardStyle = {
				...this.cardStyle,
				'min-height': this.cardHeight,
				height: '100%',
			};
		}
	}

	ngOnInit(): void {}
}

/**
 * The title component of the info card
 */
@Component({
	selector: 'cls-info-card-title',
	styleUrls: ['./cls-info-card.component.scss'],
	template: `
		<div class="info-card-title" [style.color]="color"><ng-content></ng-content></div>
	`,
})
export class ClsInfoCardTitleComponent {
	@Input() color: string = '#052d8b';
}

/**
 * The description component of the info card
 */
@Component({
	selector: 'cls-info-card-description',
	styleUrls: ['./cls-info-card.component.scss'],
	template: '<div class="info-card-description" [style.color]="color"><ng-content></ng-content></div>',
})
export class ClsInfoCardDescriptionComponent {
	@Input() color: string = '#052d8b';
}

/**
 * The footer component of the info card
 */
@Component({
	selector: 'cls-info-card-footer',
	styleUrls: ['./cls-info-card.component.scss'],
	template: '<div class="info-card-footer"><ng-content></ng-content></div>',
})
export class ClsInfoCardFooterComponent {}
