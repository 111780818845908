import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { PasswordFormFieldService } from '../password-form-field.service';
import { PasswordFormFieldBaseComponent } from '../password-form-field-base.component';

@UntilDestroy()
@Component({
	// tslint:disable-next-line: component-selector
	selector: 'cls-password-form-field',
	templateUrl: './password-form-field.component.html',
	styleUrls: ['./password-form-field.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => PasswordFormFieldComponent),
			multi: true,
		},
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => PasswordFormFieldComponent),
			multi: true,
		},
	],
})
export class PasswordFormFieldComponent extends PasswordFormFieldBaseComponent {
	@Input() isLoading = false;
	@Input() appearance: string;
	@Input() maxlength: number = 63;
	@Input() inputLabel: string;
	@Input() hidePlaceholder = false;
	@Input() passwordPlaceHolder: string;

	constructor(_passwordFormFieldSvc: PasswordFormFieldService) {
		super(_passwordFormFieldSvc);
	}
}

@Component({
	selector: 'cls-password-form-field-label',
	template: '<ng-content></ng-content>',
	styleUrls: ['./password-form-field.component.scss'],
})
export class PasswordFormFieldLabelComponent {}
