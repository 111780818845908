import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RefillsBudgetSelectComponent } from './refills-budget-select.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { ClsSpinnerModule } from '../../../ngx-common-v2/components/cls-spinner/cls-spinner.module';

@NgModule({
	imports: [CommonModule, MatFormFieldModule, MatSelectModule, FormsModule, ClsSpinnerModule],
	declarations: [RefillsBudgetSelectComponent],
	exports: [RefillsBudgetSelectComponent],
	entryComponents: [RefillsBudgetSelectComponent],
})
export class RefillsBudgetSelectModule {}
