<div
	class="selected-avatar"
	[ngStyle]="buttonContainerStyle"
	[style.background-color]="backgroundColor ? backgroundColor : '#d8edff'">
	<mat-icon [ngStyle]="buttonStyle" (click)="sendEditEvent()">{{ icon ? icon : '' }}</mat-icon>
</div>

<!-- if there is an edit icon -> show it on top right and send event to parent on click -->
<div *ngIf="addEditIcon" class="edit-icon-container" matTooltip="Edit" i18n-matTooltip>
	<mat-icon
		class="edit-icon"
		[style.font-size]="editIconSize ? editIconSize : ''"
		[style.width]="editIconSize ? editIconSize : ''"
		[style.height]="editIconSize ? editIconSize : ''"
		(click)="sendEditEvent()">
		edit
	</mat-icon>
</div>
