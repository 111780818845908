import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClsToggleListComponent } from './cls-toggle-list.component';
import { MatIconModule } from '@angular/material/icon';
import {
	ClsToggleListIconComponent,
	ClsToggleListTextComponent,
	ClsToggleListItemComponent,
} from './components/cls-toggle-list-item/cls-toggle-list-item.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
	declarations: [
		ClsToggleListComponent,
		ClsToggleListItemComponent,
		ClsToggleListIconComponent,
		ClsToggleListTextComponent,
	],
	exports: [ClsToggleListComponent, ClsToggleListItemComponent, ClsToggleListIconComponent, ClsToggleListTextComponent],
	imports: [CommonModule, FlexLayoutModule, MatIconModule, MatTooltipModule, RouterModule, MatButtonModule],
})
export class ClsToggleListModule {}
