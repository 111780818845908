import { Component, Inject, Input, OnInit } from '@angular/core';
import { NgxCommonPagesConfig } from '../../../../../pages/pages.config';
import { BaseToolbarMenuComponent } from '../base-toolbar-menu-componenet';
import { Router } from '@angular/router';

@Component({
	selector: 'cls-apps-toolbar-menu',
	templateUrl: './apps-toolbar-menu.component.html',
	styleUrls: ['./apps-toolbar-menu.component.scss'],
})
export class AppsToolbarMenuComponent extends BaseToolbarMenuComponent implements OnInit {
	@Input() isUserJustAnOrganizationMember: boolean;

	constructor(router: Router, @Inject(NgxCommonPagesConfig.key) private _config: NgxCommonPagesConfig) {
		super(router);
	}

	ngOnInit() {
		this.app = this._config.APP;
	}
}
