<div class="task-icon-container">
	<div class="unread-Notification-badge">
		<ngx-avatar
			[gravatarId]="model.metaData.updatedBy"
			[name]="model.metaData.updatedBy"
			[matBadge]="!model?.isRead && !isBackgroundTask ? '1' : ''"
			matBadgePosition="above before"
			matBadgeColor="peach"
			MatBadgeSize="small"
			[size]="40"></ngx-avatar>
	</div>
</div>

<div class="message-contianer">
	<div class="title">
		<strong>{{ model.metaData.updatedBy }}</strong>
		<ng-container *ngIf="model.metaData.updatedItems.length == 1">
			<span i18n>&nbsp;has updated:</span>
			<strong>&nbsp;{{ model.metaData.updatedItems[0].displayName | ellipsis: 30 }}</strong>
		</ng-container>
		<ng-container *ngIf="model.metaData.updatedItems.length > 1">
			<span i18n>&nbsp;has updated</span>
			<strong>&nbsp;{{ model.metaData.updatedItems.length }}</strong>
			<span i18n>&nbsp;items</span>
		</ng-container>
		<ng-container>
			<span i18n *ngIf="model.metaData.frequencyType !== 0">&nbsp;to run every</span>
			<span i18n *ngIf="model.metaData.frequencyType === 0">&nbsp;to never run.</span>
			<span *ngIf="model.metaData.frequencyPeriod > 1">&nbsp;{{ model.metaData.frequencyPeriod }}</span>
			<span *ngIf="model.metaData.frequencyType !== 0">&nbsp;{{ getFrequencyTypeString() }}.</span>
		</ng-container>
	</div>
	<div class="description">
		{{ model.creationTime | dateFromNow: 'Shared' }}
	</div>
</div>
