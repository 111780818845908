import { Injectable } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { environment } from '@environment';
import { ClsAlertsService } from '@ngx-common-v2/components/cls-alerts/services/cls-alerts.service';
import { BehaviorSubject } from 'rxjs';
import { BreadcrumbNavigationItem } from '../../../components/dynamic-breadcrumb-navigation/dynamic-breadcrumb-navigation.component';
import { ORG_ROLES } from '../../../constants/organization-roles.consts';
import { ELocalStorageKeys } from '../../../enums/local-storage-keys.enum';
import { UserInfo } from '../../../models/auth.model';
import { ECopyleaksAPP } from '../../../pages/pages.config';
import { LocalStorageService } from '../../../services/localstorage.service';
import { ScreenService } from '../../../services/screen.service';

export enum EDashboardSideNavMode {
	extended,
	shrinked,
}

@Injectable({
	providedIn: 'root',
})
export class NewDashboardNavbarService {
	private _sideNavMode$ = new BehaviorSubject<EDashboardSideNavMode>(null);

	private _breadCrumbNavigation$ = new BehaviorSubject<BreadcrumbNavigationItem[]>([]);
	private _removeSidePadding$ = new BehaviorSubject<boolean>(false);

	private _matDrawer: MatDrawer;
	public setMatDrawer(matDrawer: MatDrawer) {
		this._matDrawer = matDrawer;
	}
	get MatDrawer() {
		return this._matDrawer;
	}

	//#region side nav mode
	get sideNavMode$() {
		return this._sideNavMode$;
	}

	//#region remove side padding

	get removeSidePadding$() {
		return this._removeSidePadding$.asObservable();
	}

	set removeSidePadding(value: boolean) {
		this._removeSidePadding$.next(value);
	}

	constructor(
		private _localStorageSvc: LocalStorageService,
		private _screenSvc: ScreenService,
		private _clsAlertsSvc: ClsAlertsService
	) {
		this.setDefaultSideNav();
	}

	extendSideNav() {
		this._sideNavMode$.next(EDashboardSideNavMode.extended);
	}

	shrinkSideNav() {
		// shrink the dashboard navbar only if the the view isn't mobile (i.e. on mobile view show the dasboard items with names)
		if (!this._screenSvc.isMobile()) this._sideNavMode$.next(EDashboardSideNavMode.shrinked);
	}

	setDefaultSideNav() {
		const storageSideNavMode = this._localStorageSvc.getItem(ELocalStorageKeys.DashboardSideNavMode) as any;
		this._sideNavMode$.next(storageSideNavMode);
	}

	toggle() {
		this._matDrawer.toggle();
	}
	//#endregion side nav mode

	//#region Breadcrumb Navigation
	get breadCrumbNavigation$() {
		return this._breadCrumbNavigation$;
	}

	setBreadCrumbNavigation(breadcrumbNavigation: BreadcrumbNavigationItem[]) {
		this._breadCrumbNavigation$.next(breadcrumbNavigation);
	}

	openBillingPage(currentApp: ECopyleaksAPP, user: UserInfo) {
		switch (currentApp) {
			case ECopyleaksAPP.MainWebsite:
				if (!user.isPartOfOrganization) this.openExternalLink(`${environment.idUrl}/billing/app`);
				else {
					if (user.roles.includes(ORG_ROLES.Owner)) {
						this.openExternalLink(`${environment.adminWebsiteUrl}/billing/app`);
					} else {
						this._clsAlertsSvc.showCustomError($localize`Access denied! You are not autherized to access this page.`);
					}
				}
				break;
			case ECopyleaksAPP.API:
				if (!user.isPartOfOrganization) this.openExternalLink(`${environment.idUrl}/billing/api`);
				else {
					if (user.roles.includes(ORG_ROLES.Owner)) {
						this.openExternalLink(`${environment.adminWebsiteUrl}/billing/api`);
					} else {
						this._clsAlertsSvc.showCustomError($localize`Access denied! You are not autherized to access this page.`);
					}
				}
				break;
			default:
				if (!user.isPartOfOrganization) this.openExternalLink(`${environment.idUrl}/billing`);
				else {
					if (user.roles.includes(ORG_ROLES.Owner)) {
						this.openExternalLink(`${environment.adminWebsiteUrl}/billing`);
					} else {
						this._clsAlertsSvc.showCustomError($localize`Access denied! You are not autherized to access this page.`);
					}
				}
				break;
		}
	}

	openExternalLink(link: string) {
		window?.open(link, '_blank');
	}

	clearBreadCrumbNavigation() {
		this._breadCrumbNavigation$.next([]);
	}
	//#endregion Breadcrumb Navigation
}
