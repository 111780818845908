/**
 * General Copyleaks LMS integration type
 */
export enum EIntegrationConsumer {
	/**
	 * Moodle Plagiarism Plugins
	 */
	Canvas = 1,

	/**
	 * Canvas Plagiarism Frameworks
	 */
	Moodle = 2,

	/**
	 * LTI 1.3
	 */
	LTI1_3 = 3,
}

/**
 * General Copyleaks LMS integration status
 */
export enum EGeneralLMSIntegrationStatus {
	Pending = 1,
	Active = 2,
	Deleted = 3,
}

/**
 * General Copyleaks LMS integration status
 */
export enum EGeneralLMSIntegrationLmsFamilyName {
	Brightspace = 'desire2learn',
	Sakai = 'sakai',
	Schoology = 'schoology',
	Canvas = 'canvas',
	Moodle = 'moodle',
	Blackboard = 'BlackboardLearn',
}
