import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { AvatarIconModule } from '../../components/avatar-selection-menu/avatar-icon/avatar-icon.module';
import { AvatarModule } from 'ngx-avatar';
import { RemoveAvatarModule } from '../../components/remove-avatar/remove-avatar.module';
import { CalloutModule } from '../../components/callout/callout.module';
import { CopyleaksCaptchaModule } from '../../components/copyleaks-captcha/copyleaks-captcha.module';
import { SpinnerModule } from '../../components/spinner/spinner.module';
import { IdentityDialogComponent } from './identity-dialog.component';

@NgModule({
	declarations: [IdentityDialogComponent],
	exports: [IdentityDialogComponent],
	imports: [
		CommonModule,
		MatDialogModule,
		MatButtonModule,
		MatIconModule,
		FlexLayoutModule,
		SpinnerModule,
		CopyleaksCaptchaModule,
		CalloutModule,
		ReactiveFormsModule,
		AvatarModule,
		RemoveAvatarModule,
		AvatarIconModule,
	],
})
export class IdentityDialogModule {}
