import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CreditCardModule } from '../../components/credit-card/credit-card.module';
import { PaymentMethodsModule } from '../payment-methods/payment-methods.module';
import { AppSkeletonLoaderModule } from '../skeleton-loader/skeleton-loader.module';
import { ChoosePaymentMethodComponent } from './choose-payment-method.component';
import { ScrollToService } from '@nicky-lenaers/ngx-scroll-to';

@NgModule({
	declarations: [ChoosePaymentMethodComponent],
	exports: [ChoosePaymentMethodComponent],
	imports: [CommonModule, CreditCardModule, PaymentMethodsModule, ReactiveFormsModule],
	providers: [ScrollToService],
})
export class ChoosePaymentMethodModule {}
