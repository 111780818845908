import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CalloutModule } from '@ngx-common/components/callout/callout.module';
import { SpinnerButtonModule } from '@ngx-common/components/spinner-button/spinner-button.module';
import { RepositoryValidationMessageComponent } from './repository-validation-message.component';

@NgModule({
	exports: [RepositoryValidationMessageComponent],
	declarations: [RepositoryValidationMessageComponent],
	imports: [CommonModule, CalloutModule, SpinnerButtonModule],
})
export class RepositoryValidationMessageModule {}
