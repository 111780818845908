import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { httpFailRetry } from '../observable/http-fail-retry.pipes';

@Injectable()
export class LockoutActivateService {
	activateUrl: { activateUrl: string };
	constructor(private _http: HttpClient) {}

	async getActivteUrl(): Promise<string> {
		try {
			if (this.activateUrl) {
				return this.activateUrl.activateUrl;
			}

			const fileUrl = `${environment.apiUrl}/v1/account/lockouts/activate-url`;
			this.activateUrl = await this._http.get<{ activateUrl: string }>(fileUrl).pipe(httpFailRetry()).toPromise();
			return this.activateUrl.activateUrl;
		} catch (error) {
			throw error;
		}
	}
}
