<div class="task-icon-container">
	<div class="unread-Notification-badge">
		<ngx-avatar
			[gravatarId]="model.metaData.handledBy"
			[name]="model.metaData.handledBy"
			[matBadge]="!model?.isRead && !isBackgroundTask ? '1' : ''"
			matBadgePosition="above before"
			matBadgeColor="peach"
			MatBadgeSize="small"
			[size]="40"></ngx-avatar>
	</div>
</div>

<div class="message-contianer">
	<div class="title">
		<strong>{{ model.metaData.handledBy }}</strong>
		<ng-container *ngIf="model.metaData.sharedItems.length == 1">
			has transferred ownership of
			<strong>{{ model.metaData.sharedItems[0].displayName | ellipsis: 30 }}</strong>
			to you.
		</ng-container>
		<ng-container *ngIf="model.metaData.sharedItems.length > 1">
			has transferred ownership of
			<strong>{{ model.metaData.sharedItems.length }}</strong>
			items to you.
		</ng-container>
	</div>
	<div class="description">
		{{ model.creationTime | dateFromNow: 'Shared' }}
	</div>
</div>
