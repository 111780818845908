import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import {
	IAssignmentsResponseTableData,
	IContextsResponseModel,
	IDateRange,
	IIntegrationAnalyticsResponseOverview,
	ISortAndPaginateAssignmentsTable,
	ISortAndPaginateCoursesTable,
} from '../models/integration-level.models';

import { ICourseAnalyticsResponseOverview } from '../models/analytics-course.models';
import { IActivityAnalyticsOverviewResponse } from '../models/activity-analytics.models';
import { AnalytiscUtilsService } from './analytics.utils';
import { EPlatform, LtiSharedPagesRoutings } from 'shared/enums';
import { EIntegrationConsumer } from 'projects/Dashboard/src/app/pages/integrations/models/integrations.enums ';
import { Router } from '@angular/router';
import { HttpStatusCode } from '@ngx-common/enums/http-status-code.enum';
import {
	ISortAndPaginateStudentSubmissionsTable,
	IStudentAnalyticsResponse,
	IStudentSubmissionsTableDataResponse,
} from '../models/analytics-student.models';

@Injectable()
export class AnalyticsDataService {
	constructor(
		private _analytiscUtilsService: AnalytiscUtilsService,
		private _http: HttpClient,
		private _router: Router
	) {}
	/**
	 * Get request for integration level page.
	 * @returns IIntegrationLevelOverview
	 */
	async getIntegrationLevelDataAsync(platformType: EIntegrationConsumer, integrationId: string, dateRange: IDateRange) {
		const response = await this._http
			.post<IIntegrationAnalyticsResponseOverview>(
				`api/v1/lms/${platformType}/analytics/${integrationId}/integration`,
				dateRange
			)
			.toPromise();
		return this._analytiscUtilsService.mapToIntegrationAnalytics(response, platformType, integrationId);
	}
	/**
	 * Get request for course level page.
	 * @returns IAnalyticsCourseOverview
	 */
	async getCourseData(platformType: EPlatform, integrationId: string, courseId: string, token: string = null) {
		let headers = new HttpHeaders();
		if (token) {
			headers = headers.append('Authorization', `Bearer ${token}`);
		}
		let response = await this._http
			.get<ICourseAnalyticsResponseOverview>(
				`/api/v1/lms/${platformType}/analytics/${integrationId}/context/${courseId}`,
				{
					headers: headers,
				}
			)
			.toPromise();
		return this._analytiscUtilsService.mapToAnlyticsCourse(response);
	}

	/**
	 * Get request for activity level page.
	 * @returns IActivityAnalyticsOverview
	 */
	async getActivityData(platformType: EPlatform, integrationId: string, activityId: string, token: string = null) {
		let headers = new HttpHeaders();
		if (token) {
			headers = headers.append('Authorization', `Bearer ${token}`);
		}
		let response = await this._http
			.get<IActivityAnalyticsOverviewResponse>(
				`/api/v1/lms/${platformType}/analytics/${integrationId}/activity/${activityId}`,
				{
					headers: headers,
				}
			)
			.toPromise();
		return this._analytiscUtilsService.mapToActivityAnalytics(response, platformType);
	}

	/**
	 * Get request for assignments table.
	 * @returns IAssignmentsResponseTableData
	 */
	async getAssignmentsTableData(
		platformType: EPlatform,
		integrationId: string,
		sortAndPaginateState: ISortAndPaginateAssignmentsTable
	) {
		let result = await this._http
			.post<IAssignmentsResponseTableData>(
				`api/v1/lms/${platformType}/analytics/${integrationId}/activities`,
				sortAndPaginateState
			)
			.toPromise();
		result.activities.map(s => {
			{
				s.dueDate = s.dueDate ? new Date(s.dueDate) : s.dueDate;
				return s;
			}
		});
		return result;
	}

	/**
	 * Get request for courses table.
	 * @returns ISortAndPaginateCoursesTable
	 */
	async getCoursesTableData(
		platformType: EPlatform,
		integrationId: string,
		sortAndPaginateState: ISortAndPaginateCoursesTable
	) {
		let response = await this._http
			.post<IContextsResponseModel>(
				`api/v1/lms/${platformType}/analytics/${integrationId}/contexts`,
				sortAndPaginateState
			)
			.toPromise();
		return response;
	}

	async requestAccessToReportPage(
		platformType: EPlatform,
		integrationId: string,
		activityId: string,
		scanId: string,
		token: string,
		isFromDashboard = false
	) {
		let headers = new HttpHeaders();
		if (token) {
			headers = headers.append('Authorization', `Bearer ${token}`);
		}
		this._router.navigateByUrl;
		const result = await this._http
			.post<{ route: string; state?: string }>(
				`/api/v1/lms/${platformType}/analytics/${integrationId}/report/${scanId}`,
				{
					activityId: activityId,
				},
				{
					headers: headers,
				}
			)
			.toPromise();
		if (isFromDashboard && platformType == EPlatform.LTI1_3) {
			result.route = `v1.3/${result.route}`;
		}

		if (result?.state) {
			sessionStorage.setItem(activityId, result.state);
		}

		window.open(result.route, '_blank');
	}

	redirectOnErroredRequest(error: any) {
		const httpError: HttpErrorResponse = error;
		const status = httpError.status;
		if (!status || status < HttpStatusCode.BAD_REQUEST) {
			return false;
		}
		switch (status) {
			case 404:
				this._router.navigateByUrl(LtiSharedPagesRoutings.NotFoundPage);
				break;
			case 401:
				this._router.navigateByUrl(LtiSharedPagesRoutings.UnauthorizedPage);
				break;
			default:
				this._router.navigateByUrl(LtiSharedPagesRoutings.ErrorPage);
				break;
		}
		return true;
	}

	/**
	 * Get request for student level page.
	 * @returns IAuthorTemplateOverview
	 */
	async getStudnetAnalyticsData(
		platformType: EPlatform,
		integrationId: string,
		studentId: string,
		dateRange: IDateRange,
		token: string
	) {
		let headers = new HttpHeaders();
		if (token) {
			headers = headers.append('Authorization', `Bearer ${token}`);
		}

		let response = await this._http
			.post<IStudentAnalyticsResponse>(
				`/api/v1/lms/${platformType}/analytics/${integrationId}/student/${studentId}`,
				dateRange,
				{ headers }
			)
			.toPromise();
		return this._analytiscUtilsService.mapToStudentAnalytics(response);
	}

	/**
	 * Get request for student submissions table.
	 * @returns IStudentSubmissionsTableDataResponse
	 */
	async getStudentSubmissionsTableData(
		platformType: EPlatform,
		integrationId: string,
		studentId: string,
		sortAndPaginateState: ISortAndPaginateStudentSubmissionsTable,
		token: string = null
	) {
		let headers = new HttpHeaders();
		if (token) {
			headers = headers.append('Authorization', `Bearer ${token}`);
		}
		return await this._http
			.post<IStudentSubmissionsTableDataResponse>(
				`/api/v1/lms/${platformType}/analytics/${integrationId}/student-submissions/${studentId}`,
				sortAndPaginateState,
				{ headers }
			)
			.toPromise();
	}
}
