import { Component, OnInit, Input, forwardRef, OnDestroy } from '@angular/core';
import {
	CopyleaksCaptchaService,
	ICaptchaGenerateResult,
	ICopyleaksCaptchaTranslations,
} from '../copyleaks-captcha.service';
import {
	NG_VALUE_ACCESSOR,
	NG_VALIDATORS,
	ControlValueAccessor,
	Validator,
	AbstractControl,
	ValidationErrors,
	FormControl,
	Validators,
	FormGroupDirective,
	NgForm,
} from '@angular/forms';
import { Subject, interval } from 'rxjs';
import { takeUntil, startWith } from 'rxjs/operators';
import { ErrorStateMatcher } from '@angular/material/core';

/** Error when invalid control is dirty, touched */
class CustomErrorStateMatcher implements ErrorStateMatcher {
	isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
		return !!(control && control.invalid && (control.dirty || control.touched));
	}
}

@Component({
	// tslint:disable-next-line: component-selector
	selector: 'cls-copyleaks-captcha',
	templateUrl: './copyleaks-captcha.component.html',
	styleUrls: ['./copyleaks-captcha.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => CopyleaksCaptchaComponent),
			multi: true,
		},
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => CopyleaksCaptchaComponent),
			multi: true,
		},
	],
})
export class CopyleaksCaptchaComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {
	@Input() options: ICopyLeaksCaptchaComponentOptions;
	@Input() floatLabel: 'always' | 'never' | 'auto' = 'auto';

	generatedCaptcha: ICaptchaGenerateResult;
	captchaTextCtrl: FormControl;
	@Input() errorText: string;
	showCaptchaSpinner = false;
	showInputSpinner = false;

	customErrorStateMatcher = new CustomErrorStateMatcher();

	updateParentControllerValue: (value: ICopyLeaksCaptchaControlValue) => void;

	private _unsub = new Subject();
	canRefreshCaptcha = true;

	maxRefreshes = 6;
	constructor(private _captchaSvc: CopyleaksCaptchaService) {}

	ngOnInit() {
		this.captchaTextCtrl = new FormControl('', [Validators.required]);

		const translations = this._captchaSvc.getTranslations();

		if (!this.options && translations) {
			this.options = {
				placeholder: translations.placeholder,
				validations: translations.validations,
			};
		}

		this.generateCaptcha();

		this.captchaTextCtrl.valueChanges.pipe(takeUntil(this._unsub)).subscribe(data => {
			if (this.updateParentControllerValue && this.generatedCaptcha && this.generatedCaptcha.tracking) {
				this.updateParentControllerValue({
					answer: this.captchaTextCtrl.value,
					tracking: this.generatedCaptcha.tracking,
				});
			}
		});
	}

	async generateCaptcha() {
		try {
			if (this.maxRefreshes === 0) {
				return;
			}
			this.showCaptchaSpinner = true;
			this.generatedCaptcha = await this._captchaSvc.generate();
			this.captchaTextCtrl.setValue('');
		} catch (error) {
			console.error(error);
		} finally {
			this.maxRefreshes--;
			this.showCaptchaSpinner = false;
		}
	}

	async verify() {
		try {
			this.showInputSpinner = true;
			this.errorText = '';

			const userTypeText = this.captchaTextCtrl.value;

			if (!userTypeText) {
				this.errorText =
					this.options && this.options.validations && this.options.validations.txtRequiredMessage
						? this.options.validations.txtRequiredMessage
						: 'Please fill the text that you see in the image.';

				throw new Error(`captcha error: captcha text is required`);
			}

			const verifyResults = await this._captchaSvc.verify({
				answer: userTypeText,
				tracking: this.generatedCaptcha.tracking,
			});

			if (!verifyResults.success) {
				this.generateCaptcha();
				this.captchaTextCtrl.setValue('');
				this.errorText = this.errorText =
					this.options && this.options.validations && this.options.validations.txtNotValidMessage
						? this.options.validations.txtNotValidMessage
						: 'The text you entered is not valid, please try again.';
				throw new Error(`captcha error: ${verifyResults.reason}`);
			}
		} catch (exception) {
			throw exception;
		} finally {
			this.showInputSpinner = false;
		}
	}

	//#region Value Accessor
	writeValue(obj: any): void {}
	registerOnChange(fn: any): void {
		this.updateParentControllerValue = fn;
	}
	registerOnTouched(fn: any): void {}
	setDisabledState?(isDisabled: boolean): void {}
	//#endregion Value Accessor
	//#region validators
	validate(control: AbstractControl): ValidationErrors {
		return this.captchaTextCtrl.errors;
	}
	registerOnValidatorChange?(fn: () => void): void {}
	//#endregion validators

	ngOnDestroy(): void {
		this._unsub.next(null);
		this._unsub.complete();
	}
}

// tslint:disable-next-line: no-empty-interface
export interface ICopyLeaksCaptchaComponentOptions extends ICopyleaksCaptchaTranslations {}
export interface ICopyLeaksCaptchaControlValue {
	answer: string;
	tracking: string;
}
