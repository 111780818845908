<div class="pay-btn" [ngClass]="{ 'pay-btn-new': newDesign }" mat-dialog-actions>
	<cls-button
		[disabled]="disabledButton"
		[buttonId]="buttonId"
		aria-label="confirm-payment"
		[color]="
			!billingStatus || billingStatus === eSubscriptionBillingStatus.Healthy || updateCreditCardMode
				? 'primary'
				: 'warn'
		"
		class="btn"
		(click)="confirmPaymentFunc()">
		<div fxLayout="row" fxLayoutAlign="center center" class="btn-content-container">
			<ng-container
				*ngIf="
					!showConfirmPaymentBtnSpinner &&
					currentPaymentStep !== EPaymentServiceSteps.Completed &&
					!updateCreditCardMode &&
					(billingStatus === eSubscriptionBillingStatus.Healthy || !billingStatus)
				">
				<span class="span-btn">
					<ng-container i18n>Complete Purchase</ng-container>
				</span>
			</ng-container>
			<ng-container *ngIf="billingStatus === eSubscriptionBillingStatus.UnHealthy && !showConfirmPaymentBtnSpinner">
				<mat-icon class="lock-icon">lock</mat-icon>
				<span i18n="@@NGX.UPDATE_YOUR_CARD">UPDATE YOUR CARD</span>
			</ng-container>
			<ng-container *ngIf="updateCreditCardMode && !showConfirmPaymentBtnSpinner">
				<span i18n="@@NGX.UPDATE">UPDATE</span>
			</ng-container>
			<ng-container
				*ngIf="billingStatus === eSubscriptionBillingStatus.PaymentLockedOut && !showConfirmPaymentBtnSpinner">
				<mat-icon class="lock-icon">lock</mat-icon>
				<span i18n="@@NGX.UPDATE_YOUR_DETAILS">UPDATE YOUR DETAILS</span>
			</ng-container>
			<ng-container *ngIf="showConfirmPaymentBtnSpinner">
				<div class="spinner" [@fadeIn]>
					<cls-spinner color="white" width="24px"></cls-spinner>
				</div>
				<ng-container *ngIf="currentPaymentStep === EPaymentServiceSteps.UpdatePaymentMethod">
					<span
						*ngIf="!updateCreditCardMode"
						[@fadeIn]
						style="margin-left: 5px"
						i18n="@@NGX.CONFIRM_PAYMENT_BTN.UPDATE_NEW_PAYMENT_METHOD"
						class="span-btn">
						Updating New Payment Method
					</span>
					<span
						*ngIf="updateCreditCardMode"
						[@fadeIn]
						style="margin-left: 5px"
						i18n="@@NGX.CONFIRM_PAYMENT_BTN.UPDATING"
						class="span-btn">
						Updating
					</span>
				</ng-container>
				<ng-container *ngIf="currentPaymentStep === EPaymentServiceSteps.Processing">
					<span class="span-btn" [@fadeIn] style="margin-left: 5px" i18n="@@NGX.CONFIRM_PAYMENT_BTN.PROCESSING_PAYMENT">
						Processing Payment
					</span>
				</ng-container>
				<ng-container *ngIf="currentPaymentStep === EPaymentServiceSteps.GettingCredits">
					<span [@fadeIn] style="margin-left: 5px" i18n="@@NGX.CONFIRM_PAYMENT_BTN.ADDING_PAGES">Adding Credits</span>
				</ng-container>
				<ng-container *ngIf="currentPaymentStep === EPaymentServiceSteps.CreatingRepository">
					<span [@fadeIn] style="margin-left: 5px" i18n="@@NGX.CONFIRM_PAYMENT_BTN.CREATE_REPOSITORY">
						Creating Repository
					</span>
				</ng-container>
				<ng-container *ngIf="currentPaymentStep === EPaymentServiceSteps.UpdatingRepository">
					<span [@fadeIn] style="margin-left: 5px" i18n="@@NGX.CONFIRM_PAYMENT_BTN.UPDATING_REPOSITORY">
						Updating Repository
					</span>
				</ng-container>
			</ng-container>
			<ng-container *ngIf="!showConfirmPaymentBtnSpinner && currentPaymentStep === EPaymentServiceSteps.Completed">
				<span [@fadeIn] style="display: flex; align-items: center">
					<mat-icon class="icon" style="margin-right: 5px">done</mat-icon>
					<span i18n="@@NGX.CONFIRM_PAYMENT_BTN.COMPLETED">Completed</span>
				</span>
			</ng-container>
		</div>
	</cls-button>
</div>
