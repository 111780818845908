import { Injectable } from '@angular/core';
import {
	IActivityAnalyticsOverview,
	IActivityAnalyticsOverviewResponse,
	IWritingFeedbackCorrections,
	ResultOverviewResponseData,
} from '../models/activity-analytics.models';
import { ICardsData } from 'ngx-common-v2/components/analytics-components/templates/cls-results-insights-template/models/cls-card-data.model';
import {
	IIntegrationAnalyticsResponseOverview,
	IIntegrationLevelOverview,
	IAssignmentsTableDataBase,
	IAnalyticsIntegrationData,
	IAnalyticsTotalCases,
} from '../models/integration-level.models';
import {
	ICourseAnalyticsResponseOverview,
	ICourseAnalyticsOverview,
	IChartDataByAmount,
} from '../models/analytics-course.models';
import {
	EClsResultsTypes,
	IResultItem,
} from 'ngx-common-v2/components/analytics-components/charts/cls-results-overview/models/cls-results-overview.models';
import { GeneralLMSIntegrationModel } from 'projects/Dashboard/src/app/pages/integrations/models/integrations.models';
import { EIntegrationConsumer } from 'projects/Dashboard/src/app/pages/integrations/models/integrations.enums ';
import { ICPFToolProxy, IMPPIntegration } from 'projects/Dashboard/src/app/interfaces';
import { ILti1_3IntegrationItemModel } from 'shared/models/lti1_3-integration.model';
import { IAnalyticsExternalResults } from 'ngx-common-v2/components/analytics-components/models/cls-external-results-table.models';
import { IStudentAnalyticsOverview, IStudentAnalyticsResponse } from '../models/analytics-student.models';
import {
	EAnalyticsLevel,
	IAuthorTemplateOverview,
	IBarChart,
	IChartDataByMonth,
	IOneLineChart,
	ITwoLineChart,
} from 'ngx-common-v2/components/analytics-components/models/cls-analytics.models';
import { EPlatform } from 'shared/enums';

@Injectable()
export class AnalytiscUtilsService {
	private _similarityText = $localize`MATCHED TEXT`;
	private _AIText = $localize`AI TEXT`;
	constructor() {}

	/**
	 * Helper function to map response data to overview data of integration page.
	 * @param response The response object.
	 * @returns IIntegrationLevelOverview
	 */
	public mapToIntegrationAnalytics(
		response: IIntegrationAnalyticsResponseOverview,
		platformType: EIntegrationConsumer,
		integrationId: string
	) {
		let maxSubmittedScansValue = this.maxValue(response.submittedScans.map(s => s.value));
		let maxResultsValue = this.maxValue(response.result.map(s => s.value));
		let maxCrossLanguageValue = this.maxValue(response.crossLanguages.map(s => s.value));
		let maxCheatDetectionsValue = this.maxValue(response.cheatsDetections.map(s => s.value));
		let maxWritingFeedbackValue = this.maxValue(response.writingFeedback.map(s => s.value));

		let integrationAnalytics: IIntegrationLevelOverview = {
			integrationName: response?.integrationData?.integrationName,
			cardsData: this.mapToCardsData(response?.analyticsTotalsCases, EAnalyticsLevel.Integration),
			externalResults: response.externalResults,
			hideContexts: response.hideContexts,
			originalityData:
				response.originalityPlagiarism.length || response.originalityAI.length
					? this.mapChartData(
							response.originalityAI,
							this._AIText,
							response.originalityPlagiarism,
							this._similarityText
					  )
					: this.createEmptyStateTwoLineChart(this._similarityText, this._AIText),
			submittedScansData: response.submittedScans.length
				? {
						chartData: this.mapDataToChartSeries(response.submittedScans),
						maxYTick: maxSubmittedScansValue == 0 ? 8 : maxSubmittedScansValue,
						yTicks: this.getYticsArray(maxSubmittedScansValue),
				  }
				: this.createEmptyStateBarChart(),
			resultsData: response.result.length
				? {
						chartData: this.mapDataToChartSeries(response.result),
						maxYTick: maxResultsValue == 0 ? 8 : maxResultsValue,
						yTicks: this.getYticsArray(maxResultsValue),
				  }
				: this.createEmptyStateBarChart(),
			crossLanguageData: {
				chartData: [
					{
						name: $localize`Cross language plagiarism`,
						series: this.mapDataToChartSeries(response.crossLanguages),
					},
				],
				maxYTick: maxCrossLanguageValue,
				yTicks: this.getYticsArray(maxCrossLanguageValue),
			},
			cheatsFoundData: response.cheatsDetections.length
				? {
						chartData: [
							{
								name: $localize`Cheats found`,
								series: this.mapDataToChartSeries(response.cheatsDetections),
							},
						],
						maxYTick: maxCheatDetectionsValue == 0 ? 8 : maxCheatDetectionsValue,
						yTicks: this.getYticsArray(maxCheatDetectionsValue),
				  }
				: this.createEmptyStateOneLineChart($localize`Cheats found`),
			integrationData: this.mapToIntegrationItemData(response?.integrationData, platformType, integrationId),
			startAnalyticsDate: new Date(response.startAnalyticsDate),
			isStatisticsExists: response.isStatisticsExists,
			showAnalytics: response.showAnalytics,
			writingFeedback: response.writingFeedback.length
				? {
						chartData: this.mapDataToChartSeries(response.writingFeedback),
						maxYTick: maxWritingFeedbackValue == 0 ? 8 : maxWritingFeedbackValue,
						yTicks: this.getYticsArray(maxWritingFeedbackValue),
				  }
				: this.createEmptyStateBarChart(),
			writingFeedbackFeatureEnabled: response.writingFeedbackFeatureEnabled,
		};
		return integrationAnalytics;
	}

	/**
	 * Helper function to map response data to overview data of context page.
	 * @param response The response object.
	 * @returns ICourseAnalyticsOverview
	 */
	public mapToAnlyticsCourse(response: ICourseAnalyticsResponseOverview) {
		const activities = this.convertCreatedDateToDateObject(response.activities);
		let maxWritingFeedbackValue = this.maxValue(response.writingFeedback.map(s => s.value));

		let result: ICourseAnalyticsOverview = {
			integrationName: response.integrationName,
			courseName: response.courseName,
			assignmentsTable: activities,
			cardsData: this.mapToCardsData(response.analyticsTotalsCases, EAnalyticsLevel.Context),
			originalityData:
				response.aiOriginality.length ||
				(response.plagiarismOriginality.length && response.plagiarismOriginality.length)
					? this.mapChartDataByPrecentage(
							response.aiOriginality,
							this._AIText,
							response.plagiarismOriginality,
							this._similarityText
					  )
					: this.createEmptyStateTwoLineChart(this._similarityText, this._AIText),
			isStatisticsExists: response.isStatisticsExists,
			showAnalytics: response.showAnalytics,
			defaultScanSettingsId: response.defaultScanSettingsId,
			writingFeedbackData: response.writingFeedback.length
				? {
						chartData: this.mapDataToChartSeries(response.writingFeedback),
						maxYTick: maxWritingFeedbackValue == 0 ? 8 : maxWritingFeedbackValue,
						yTicks: this.getYticsArray(maxWritingFeedbackValue),
				  }
				: this.createEmptyStateBarChart(),
			writingFeedbackFeatureEnabled: response.writingFeedbackFeatureEnabled,
		};

		return result;
	}

	/**
	 * Helper function to map response data to overview data of activity page.
	 * @param response The response object.
	 * @returns IActivityAnalyticsOverview
	 */
	public mapToActivityAnalytics(response: IActivityAnalyticsOverviewResponse, platformType: EPlatform) {
		response.submissions?.map(s => {
			s.submissionDate = s.submissionDate ? (s.submissionDate = new Date(s.submissionDate)) : null;
			s.documentName = decodeURI(s.documentName);
		});
		const plagiarismChartData = response?.plagiarismOriginality?.length
			? response.plagiarismOriginality
			: this.createEmptyStateChartDataByAmount();

		const aiChartData = response?.aiOriginality?.length
			? response.aiOriginality
			: this.createEmptyStateChartDataByAmount();

		let maxWritingFeedbackValue =
			response.writingFeedback != null
				? this.maxValue([
						response.writingFeedback?.grammar,
						response.writingFeedback?.mechanics,
						response.writingFeedback?.sentenceStructure,
						response.writingFeedback?.wordChoice,
				  ])
				: 0;

		return {
			tableData: response.submissions,
			externalResults: response?.externalResults,
			resultsOverviewData: this.mapToResultsOverview(response.resultsOverview, platformType),
			chartData: this.mapChartDataByPrecentage(aiChartData, this._AIText, plagiarismChartData, this._similarityText),
			cardData: this.mapToCardsData(response?.analyticsTotalsCases, EAnalyticsLevel.Activity),
			contextId: response?.contextId,
			contextName: response.contextName,
			integrationName: response.integrationName,
			activityName: response.activityName,
			dueDate: response?.dueDate,
			isStatisticsExists: response.isStatisticsExists,
			showAnalytics: response.showAnalytics,
			defaultScanSettingsId: response.defaultScanSettingsId,
			hideAuthorAnalytics: response.hideAuthorAnalytics,
			writingFeedbackData: response.writingFeedback
				? {
						chartData: this.mapWritingFeedbackCorrectionsToChartSeries(response.writingFeedback),
						maxYTick: maxWritingFeedbackValue == 0 ? 8 : maxWritingFeedbackValue,
						yTicks: this.getYticsArray(maxWritingFeedbackValue),
				  }
				: this.createEmptyStateBarChart(),
			writingFeedbackFeatureEnabled: response.writingFeedbackFeatureEnabled,
		} as IActivityAnalyticsOverview;
	}

	mapToStudentAnalytics(response: IStudentAnalyticsResponse) {
		let maxAiValue = this.maxValue(response.originalityAI.map(s => s.value));
		let aiYtics = this.getYticsArray(maxAiValue);
		let maxPlagiarismValue = this.maxValue(response.originalityPlagiarism.map(s => s.value));
		let plagiarismYtics = this.getYticsArray(maxPlagiarismValue);
		let maxWritingFeedbackValue = this.maxValue(response.writingFeedback.map(s => s.value));
		let writingFeedbackYticks = this.getYticsArray(maxWritingFeedbackValue);

		return {
			startAnalyticsDate: new Date(response.startAnalyticsDate),
			studentName: response.studentName,
			authorOverviewData: {
				aiChartData: response.originalityAI?.length
					? {
							chartData: this.mapDataToChartSeries(response.originalityAI),
							maxYTick: aiYtics[0],
							yTicks: aiYtics,
					  }
					: this.createEmptyStateBarChart(),
				plagiarismChartData: response.originalityPlagiarism?.length
					? {
							chartData: [
								{
									name: this._similarityText,
									series: this.mapDataToChartSeries(response.originalityPlagiarism),
								},
							],
							maxYTick: plagiarismYtics[0],
							yTicks: plagiarismYtics,
					  }
					: this.createEmptyStateOneLineChart(this._similarityText),
				cardsData: this.mapToCardsData(response.analyticsTotalsCases, EAnalyticsLevel.Author),
				commonResults: response.externalResults,
				writingFeedbackChartData: response.writingFeedback?.length
					? {
							chartData: this.mapDataToChartSeries(response.writingFeedback),
							maxYTick: writingFeedbackYticks[0],
							yTicks: writingFeedbackYticks,
					  }
					: this.createEmptyStateBarChart(),
			} as IAuthorTemplateOverview,
			writingFeedbackFeatureEnabled: response.writingFeedbackFeatureEnabled,
		} as IStudentAnalyticsOverview;
	}

	/**
	 * Heleper function to convert date string to date object (for assignments table).
	 * @param data
	 * @returns IAssignmentsTableData
	 */
	public convertCreatedDateToDateObject(data: IAssignmentsTableDataBase[]) {
		return data.map(s => {
			{
				s.createdDate = s.createdDate ? new Date(s.createdDate) : s.createdDate;
				s.dueDate = s.dueDate ? new Date(s.dueDate) : s.dueDate;
				return s;
			}
		});
	}

	/**
	 *
	 * @param firstSeries
	 * @param firstSeriesName
	 * @param secondSeries
	 * @param secondSeriesName
	 * @returns
	 */
	private mapChartDataByPrecentage(
		firstSeries: IChartDataByAmount[],
		firstSeriesName: string,
		secondSeries: IChartDataByAmount[],
		secondSeriesName: string
	) {
		let secondSeriesByScore: IAnalyticsExternalResults[] = Array.from(
			{ length: 11 },
			(el, idx) =>
				({
					name: String(idx * 10) + '%',
					value: 0,
				} as IAnalyticsExternalResults)
		);
		let firstSeriesByScore: IAnalyticsExternalResults[] = Array.from(
			{ length: 11 },
			(el, idx) =>
				({
					name: String(idx * 10) + '%',
					value: 0,
				} as IAnalyticsExternalResults)
		);

		// sum all amounts of with the same score.
		firstSeries?.forEach(s => {
			let val = firstSeriesByScore[Math.floor(s.score / 10)];
			if (val) val.value += s.amount;
		});
		secondSeries?.forEach(s => {
			let val = secondSeriesByScore[Math.floor(s.score / 10)];
			if (val) val.value += s.amount;
		});
		let maxYTick = this.maxValues(
			firstSeriesByScore?.map(val => val.value),
			secondSeriesByScore?.map(val => val.value)
		);

		let yTicks = this.getYticsArray(maxYTick);
		if (maxYTick === 0) maxYTick = 8;
		else maxYTick = yTicks[0] > maxYTick ? yTicks[0] : maxYTick;
		return {
			chartData: [
				{
					name: firstSeriesName,
					series: firstSeriesByScore,
				},
				{
					name: secondSeriesName,
					series: secondSeriesByScore,
				},
			],
			maxYTick,
			yTicks,
		} as ITwoLineChart;
	}

	/**
	 * Helper function to map data to two lines chart data
	 * @param firstSeriesDataSet
	 * @param firstSeriesName
	 * @param secondSeriesDataSet
	 * @param secondSeriesName
	 * @returns ITwoLineChart
	 */
	private mapChartData(
		firstSeriesDataSet: IChartDataByMonth[],
		firstSeriesName: string,
		secondSeriesDataSet: IChartDataByMonth[],
		secondSeriesName: string
	) {
		let maxSeriesValue = this.maxValues(
			firstSeriesDataSet.map(s => s.value),
			secondSeriesDataSet.map(s => s.value)
		);
		const yTics = this.getYticsArray(maxSeriesValue);
		return {
			chartData: [
				{
					name: firstSeriesName,
					series: this.mapDataToChartSeries(firstSeriesDataSet),
				},
				{
					name: secondSeriesName,
					series: this.mapDataToChartSeries(secondSeriesDataSet),
				},
			],
			maxYTick: yTics[0],
			yTicks: yTics,
		} as ITwoLineChart;
	}

	/**
	 * Heleper function to get the max value of in two diffrent arrays
	 * @param values1 The first array of numbers.
	 * @param values2 The second array of numbers.
	 * @returns number
	 */
	private maxValues(values1: number[], values2: number[]) {
		let val1 = this.maxValue(values1);
		let val2 = this.maxValue(values2);
		return val1 > val2 ? val1 : val2;
	}

	/**
	 * Helper function to get the max value of given array.
	 * @param values The array of numbers to find the max value in.
	 * @returns number - The max number.
	 */
	private maxValue(values: number[]) {
		if (values.length) return this.customRound(values.reduce((a, b) => Math.max(a, b)));
		return 0;
	}

	/**
	 * Heleper function to get the y array ticks of chart base on the max value of the chart.
	 * @param maxValue The max value of the chart.
	 * @returns number[]
	 */
	private getYticsArray(maxValue: number) {
		if (maxValue == 0) return [0, 2, 4, 6, 8];
		let roundedNumber = Math.ceil(maxValue / 4) * 4;
		const resultArray: number[] = [roundedNumber];
		const dev = roundedNumber / 4;

		for (let i = 0; i < 4; i++) {
			roundedNumber -= dev;
			resultArray.push(roundedNumber);
		}

		return resultArray;
	}

	/**
	 * Heleper function to create chart series array with month/year name.
	 * @param data The array of values as Date and number objects.
	 * @returns IAnalyticsExternalResults[]
	 */
	private mapDataToChartSeries(data: IChartDataByMonth[]) {
		return data.map(s => {
			return { name: s.date, value: s.value } as IAnalyticsExternalResults;
		});
	}

	/**
	 * Heleper function to map overall results (plagiarism cases, ai cases,cross language cases, text cases).
	 * @param response The response object containing the data.
	 * @param eLevelType The type of the Integration anlytics - Integration, Context, Activity.
	 * @returns
	 */
	private mapToCardsData(analyticsTotalsCases: IAnalyticsTotalCases, eLevelType: EAnalyticsLevel) {
		let result: ICardsData = {
			plagiarismCases: {
				toShow: true,
				value: analyticsTotalsCases?.plagiarismCases,
				title: $localize`Matched Text Cases`,
			},
			crossLanguage: {
				toShow: true,
				value: analyticsTotalsCases?.crossLanguageCases,
				title: $localize`Cross-Language`,
			},
			aiTextCases: {
				toShow: true,
				value: analyticsTotalsCases?.aiCases,
				title: $localize`AI Text Cases`,
			},
			avgWritingFeedback: {
				toShow: true,
				value:
					analyticsTotalsCases?.writingFeedback != null && analyticsTotalsCases?.writingFeedback >= 0
						? analyticsTotalsCases?.writingFeedback
						: 'N/A',
				title: $localize`Writing Corrections`,
			},
			totalScans: {
				toShow: true,
				value: analyticsTotalsCases.totalScans,
				title: $localize`Scans`,
			},
		};

		switch (eLevelType) {
			case EAnalyticsLevel.Integration:
				result = {
					...result,
					totalResults: {
						toShow: true,
						value: analyticsTotalsCases.totalResults,
						title: $localize`Matched Text Results`,
					},
					cheatDetection: {
						toShow: true,
						value: analyticsTotalsCases.cheatDetectionCases,
						title: $localize`Cheat Detection`,
					},
				};
				break;
			case EAnalyticsLevel.Activity:
			case EAnalyticsLevel.Author:
				result = {
					...result,
					cheatDetection: {
						toShow: true,
						value: analyticsTotalsCases?.cheatDetectionCases,
						title: $localize`Cheats Found`,
						showDesignIcon: true,
					},
				};
				break;
			default:
				break;
		}
		return result;
	}

	/**
	 * Helper function to round a given number.
	 * @param number The number to round.
	 * @returns number
	 */
	private customRound(number: number): number {
		if (number > 1000) {
			return Math.ceil(number / 1000) * 1000;
		} else if (number > 100) {
			return Math.ceil(number / 100) * 100;
		} else if (number <= 100) {
			return Math.ceil(number / 10) * 10;
		} else {
			return number;
		}
	}

	/**
	 * Creates empty one line chart data.
	 * @param name The series name.
	 * @returns IOneLineChart
	 */
	private createEmptyStateOneLineChart(name: string = '') {
		return {
			chartData: [
				{
					name,
					series: [],
				},
			],
			maxYTick: 2,
			yTicks: [0, 1, 2],
		} as IOneLineChart;
	}

	/**
	 * Creates empty two line chart.
	 * @param firstName The first chart series name.
	 * @param secondName The second chart series name.
	 * @returns ITwoLineChart
	 */
	private createEmptyStateTwoLineChart(firstName: string, secondName: string) {
		return {
			chartData: [
				{
					name: firstName,
					series: [], //firstSeriesByScore,
				},
				{
					name: secondName,
					series: [], // firstSeriesByScore,
				},
			],
			maxYTick: 2,
			yTicks: [0, 1, 2],
		} as ITwoLineChart;
	}

	/**
	 * Creates an empty ngx bar chart data.
	 * @returns IBarChart
	 */
	private createEmptyStateBarChart() {
		return {
			chartData: [],
			maxYTick: 2,
			yTicks: [0, 1, 2],
		} as IBarChart;
	}

	/**
	 *
	 * @param data The data coming from form the server about the external results type of activity level.
	 * @returns IResultItem[]
	 */
	private mapToResultsOverview(data: ResultOverviewResponseData, platformType: EPlatform) {
		let result = [
			{
				type: EClsResultsTypes.Internet,
				amount: data.internet,
			},
			{
				type: EClsResultsTypes.InternalDataBase,
				amount: data.internalDatabase,
			},
			{
				type: EClsResultsTypes.Repositories,
				amount: data.repositories,
			},
		] as IResultItem[];
		if (platformType == EPlatform.LTI1_3) {
			result.push({
				type: EClsResultsTypes.BetweenStudents,
				amount: data.batchResults,
			});
		}

		return result;
	}

	/**
	 * Creates empty state for chart series that presents his data as precenatage.
	 * @returns IChartDataByAmount[]
	 */
	private createEmptyStateChartDataByAmount() {
		return Array.from({ length: 11 }, (val, idx) => {
			return {
				amount: 0,
				score: 0,
			};
		}) as IChartDataByAmount[];
	}

	/**
	 *
	 * @param data The integration item data.
	 * @param platfromType The platform type - LTI1.3, MPP, CPF.
	 * @param integrationId The id of the integration.
	 * @returns GeneralLMSIntegrationModel
	 */
	private mapToIntegrationItemData(
		data: IAnalyticsIntegrationData,
		platfromType: EIntegrationConsumer,
		integrationId: string
	) {
		let dataOfPlatform: ICPFToolProxy | IMPPIntegration | ILti1_3IntegrationItemModel;
		switch (platfromType) {
			case EIntegrationConsumer.LTI1_3:
				dataOfPlatform = {
					createdAt: data.createdAt,
					deletionDate: data?.deleteDate,
					id: integrationId,
					name: data.integrationName,
					platformInformation: {
						lmsFamily: data.lmsFamily,
					},
					showRestoreButtonSpinner: false,

					defaultScanSettingsId: data.defaultScanSettingsId,
				} as ILti1_3IntegrationItemModel;
				break;
			case EIntegrationConsumer.Canvas:
				dataOfPlatform = {
					createdAt: data.createdAt,
					guid: integrationId,
					name: data.integrationName,
					scanIntegrationId: data.scanIntegrationId,
					defaultScanSettingsId: data.defaultScanSettingsId,
					key: data.key,
					copyleaksRegistrationUrl: data.registrationUrl,
					secret: data.secret,
					successfullyRegistered: true,
				} as ICPFToolProxy;
				break;
			case EIntegrationConsumer.Moodle:
				dataOfPlatform = {
					key: data.key,
					name: data.integrationName,
					createdAt: data.createdAt,
					deleteDate: data?.deleteDate,
					defaultScanSettingsId: data.defaultScanSettingsId,
					accessSecret: data.secret,
					id: integrationId,
				} as IMPPIntegration;
				break;
			default:
				break;
		}
		return new GeneralLMSIntegrationModel(platfromType, dataOfPlatform, this.getIntegrationBdgeSource(data.lmsFamily));
	}

	/**
	 * Helper function to get the image of lms family.
	 * @param lmsFamily Get the corect path to the bedge of the lms by lms family.
	 * @returns string - path to bedge.
	 */
	private getIntegrationBdgeSource(lmsFamily: string) {
		let bedgeSource = '../../../../assets/new_lms/lti1.3-';
		switch (lmsFamily) {
			case 'desire2learn':
				bedgeSource = bedgeSource + 'brightspace.png';
				break;
			case 'BlackboardLearn':
				bedgeSource = bedgeSource + 'blackboard.png';
				break;
			default:
				bedgeSource = bedgeSource + `${lmsFamily.toLowerCase()}.png`;
				break;
		}
		return bedgeSource;
	}

	/**
	 * Heleper function to map writing feedback corrections to chart series
	 * @param writingFeedbackCorrections
	 */
	private mapWritingFeedbackCorrectionsToChartSeries(writingFeedbackCorrections: IWritingFeedbackCorrections) {
		return [
			{
				name: $localize`MECHANICS`,
				value: writingFeedbackCorrections.mechanics,
			},
			{
				name: $localize`WORD CHOICE`,
				value: writingFeedbackCorrections.wordChoice,
			},
			{
				name: $localize`GRAMMAR`,
				value: writingFeedbackCorrections.grammar,
			},
			{
				name: $localize`SENTENCE STRUCTURE`,
				value: writingFeedbackCorrections.sentenceStructure,
			},
		] as IAnalyticsExternalResults[];
	}
}
