import { IRefillsBudgets } from '@ngx-common/dialogs/auto-refill-dialog/models/auto-refill-dialog.models';
import { MAX_REFILLS } from '.';

export const UNLIMITED_OPTION: string = 'Unlimited';
export const DISABLE_OPTION = 'Disable';

export const REFILL_OPTIONS_ARR: IRefillsBudgets[] = [
	{
		name: DISABLE_OPTION,
		value: 0,
	},
	{
		name: UNLIMITED_OPTION,
		value: MAX_REFILLS,
	},
	{
		name: '5',
		value: 5,
	},
	{
		name: '10',
		value: 10,
	},
	{
		name: '15',
		value: 15,
	},
	{
		name: '20',
		value: 20,
	},
	{
		name: '25',
		value: 25,
	},
];
