import { Component, OnDestroy, OnInit } from '@angular/core';
import { NewDashboardNavbarService } from '@ngx-common/components/new-dashboard-navbar/services/new-dashboard-navbar.service';

@Component({
	selector: 'app-contact-us',
	templateUrl: './contact-us.component.html',
	styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsPageComponent implements OnInit, OnDestroy {
	constructor(private _newDashboardSvc: NewDashboardNavbarService) {}

	ngOnInit() {
		this._newDashboardSvc.setBreadCrumbNavigation([
			{
				name: $localize`Home`,
				link: '/',
			},
			{
				name: $localize`Technical Help`,
				link: '/contact-us',
			},
		]);
	}

	ngOnDestroy() {
		this._newDashboardSvc.clearBreadCrumbNavigation();
	}
}
