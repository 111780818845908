import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { environment } from '@environment';
import { BehaviorSubject, filter, map } from 'rxjs';
import { ISupportedFile, ISupportedFileTypes } from '../models/v3-specifications-main.model';
import { httpFailRetry } from '../observable/http-fail-retry.pipes';
import { EScanFilesPageMode } from '../enums/scan-page-mode.enum';

@Injectable()
export class SupportedFilesService implements OnDestroy {
	private _isDataLoading$ = new BehaviorSubject<boolean>(false);
	private _supportedFileTypes$ = new BehaviorSubject<ISupportedFileTypes>(null);

	private set _supportedFileTypes(value: ISupportedFileTypes) {
		this._supportedFileTypes$.next(value);
	}

	public get supportedFileTypes$() {
		return this._supportedFileTypes$;
	}

	public get supportedFileTypes() {
		return this._supportedFileTypes$.value;
	}

	constructor(private _http: HttpClient) {}

	async initSupportedFileTypes() {
		try {
			if (this.supportedFileTypes || this._isDataLoading$.value) {
				return;
			}
			this._isDataLoading$.next(true);
			this._supportedFileTypes = await this._http
				.get<ISupportedFileTypes>(`${environment.apiUrl}/v1/supported-file-types?cdn-cache=true&v=1`)
				.pipe(httpFailRetry())
				.toPromise();
			this._isDataLoading$.next(false);
		} catch (error) {
			throw error;
		}
	}

	async initSupportedFileTypesAdminAsync() {
		try {
			if (this.supportedFileTypes || this._isDataLoading$.value) {
				return;
			}
			this._isDataLoading$.next(true);
			this._supportedFileTypes = await this._http
				.get<ISupportedFileTypes>(`${environment.apiUrl}/v1/repositories/documents/supported-file-types`)
				.pipe(httpFailRetry())
				.toPromise();
			this._isDataLoading$.next(false);
		} catch (error) {
			throw error;
		}
	}

	get supportedRegularFilesExtensions$() {
		return this._supportedFileTypes$.pipe(
			filter(d => !!d),
			map(d => [...d.textual.map(t => t.extension), ...d.nonTextual.map(t => t.extension)])
		);
	}

	get supportedCodeFilesExtensions$() {
		return this._supportedFileTypes$.pipe(
			filter(d => !!d),
			map(d => d.code.map(t => t.extension))
		);
	}

	get supportedOcrExtensions$() {
		return this._supportedFileTypes$.pipe(
			filter(d => !!d),
			map(d => d.ocr.map(t => t.extension))
		);
	}

	get supportedRegularFilesMimeTypes$() {
		return this._supportedFileTypes$.pipe(
			filter(d => !!d),
			map(d => {
				return this._mapMimes(d.textual).concat(this._mapMimes(d.nonTextual));
			})
		);
	}

	get supportedCodeFilesMimeTypes$() {
		return this._supportedFileTypes$.pipe(
			filter(d => !!d),
			map(d => {
				return this._mapMimes(d.code);
			})
		);
	}

	get supportedOcrMimeTypes$() {
		return this._supportedFileTypes$.pipe(
			filter(d => !!d),
			map(d => {
				return this._mapMimes(d.ocr);
			})
		);
	}

	private _mapMimes(mimes: ISupportedFile[]) {
		var allMimes: string[] = [];
		mimes
			.map(t => t.mimeTypes)
			.forEach(mimes =>
				mimes.forEach(mime => {
					if (!allMimes.find(i => i == mime)) allMimes.push(mime);
				})
			);
		return allMimes;
	}

	getFileMaxSize$(fileExtension: string, scanPageMode?: EScanFilesPageMode) {
		if (scanPageMode === EScanFilesPageMode.RegularFiles) {
			return this._supportedFileTypes$.pipe(
				filter(d => !!d),
				map(d => [...d.textual, ...d.nonTextual].find(ele => ele.extension == fileExtension)?.maxSizeKb)
			);
		} else if (scanPageMode === EScanFilesPageMode.CodeFiles) {
			return this._supportedFileTypes$.pipe(
				filter(d => !!d),
				map(d => d.code.find(ele => ele.extension == fileExtension)?.maxSizeKb)
			);
		} else if (scanPageMode === EScanFilesPageMode.OcrFiles) {
			return this._supportedFileTypes$.pipe(
				filter(d => !!d),
				map(d => d.ocr.find(ele => ele.extension == fileExtension)?.maxSizeKb)
			);
		} else if (scanPageMode === EScanFilesPageMode.FileCompare) {
			return this._supportedFileTypes$.pipe(
				filter(d => !!d),
				map(d => [...d.textual, ...d.nonTextual, ...d.code].find(ele => ele.extension == fileExtension)?.maxSizeKb)
			);
		} else {
			return this._supportedFileTypes$.pipe(
				filter(d => !!d),
				map(
					d =>
						[...d.textual, ...d.nonTextual, ...d.code, ...d.ocr].find(ele => ele.extension == fileExtension)?.maxSizeKb
				)
			);
		}
	}

	bytesToKb(bytes: number) {
		if (bytes === 0) return 0;
		return bytes / 1024;
	}

	ngOnDestroy() {
		this._supportedFileTypes$.unsubscribe();
	}
}
