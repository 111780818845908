import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
	name: 'dateFromNow',
	pure: false,
})
export class DateFromNowPipe implements PipeTransform {
	constructor() {}
	transform(value: Date, text?: string): any {
		return `${text ? text + ' ' : ''}${moment.utc(value).fromNow()}`;
	}
}
