import { DecimalPipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ClsAlertsService } from '@ngx-common-v2/components/cls-alerts/services/cls-alerts.service';
import { Subject } from 'rxjs';
import { Stripe } from '../../../components/credit-card/stripe';
import { EPaymentIntentStatus } from '../../../components/credit-card/stripe/stripe-definitions/payment-intent';
import { PaymentMethod } from '../../../components/credit-card/stripe/stripe-definitions/payment-method';
import {
	CopyLeaksDialogComponent,
	ECopyLeaksDialogSubmitErrors,
	ICopyLeaksDialogData,
} from '../../../dialogs/copyleaks-dialog/copyleaks-dialog-component/copyleaks-dialog.component';
import {
	IUserRegisterDialogData,
	UserRegisterDialogComponent,
} from '../../../dialogs/user-register-dialog/user-register-dialog.component';
import { EAPIErrorCodes } from '../../../enums/error-codes.enum';
import { HttpStatusCode } from '../../../enums/http-status-code.enum';
import { EProductType } from '../../../enums/product-type.enums';
import { IAPICustomErrors } from '../../../models/api-custom-errors.model';
import { ActivateAccountRes } from '../../../models/auth.model';
import {
	PayForRepositroySubscriptionRequestModel,
	PayForSubscriptionRequestModel,
} from '../../../models/payment.model';
import {
	EPaymentServiceSteps,
	EPlatformProductType,
	PricingOfferResult,
	PricingPlan,
} from '../../../models/pricing.model';
import { IUserDetailsFormData } from '../../../models/user.models';
import { AccountPagesRoutes } from '../../../pages/account-pages/model/account-routings.model';
import { AppDirectionalityService } from '../../../services/app-directionality.service';
import { AuthService } from '../../../services/auth.service';
import { IItem, ITransaction } from '../../../services/google-analytics.service';
import { GoogleTagMangerService } from '../../../services/google-tag-manager.service';
import { ESubscriptionBillingStatus } from '../../../services/payment-plan.service';
import { Invoice, PaymentService } from '../../../services/payment.service';
import { ScreenService } from '../../../services/screen.service';
import { CLSTranslateService } from '../../../services/translate.service';
import { UserService, eRegistrationMode } from '../../../services/user.service';
import { IAccountBillingAddressValue } from '../../billing-address/models/billing-address.models';
import { GetPlatformProductName, GetProductName } from '@ngx-common/models/pricing-functions.model';

@UntilDestroy()
@Component({
	selector: 'cls-payment',
	styleUrls: ['./payment.component.scss'],
	templateUrl: `payment.component.html`,
})
export class PaymentComponent implements OnInit, OnChanges, OnDestroy {
	@ViewChild('promoCodeInput', { read: ElementRef }) promoCodeInput: ElementRef;

	priceOffer: PricingOfferResult;

	@Input() coupon: string;
	@Input() repositoryId: string;
	@Input() isLoading: boolean;

	@Input() showPromoCodeInputSection = true;

	@Input() billingAddress: IAccountBillingAddressValue;
	@Input() selectedPlatformProduct: EPlatformProductType;

	@Input() selectedPlan: PricingPlan;
	@Input() monthlyPlan: PricingPlan;
	@Input() newDesign: boolean;
	@Input() isLocked: boolean;
	@Input() horizontalMode: boolean;
	@Input() billingStatus: ESubscriptionBillingStatus;
	@Input() productType: EProductType;

	@Input() leadName = 'pricing-page';

	@Input() yearlyPlan: PricingPlan;
	@Input() isMonthly = true;

	@Input() isMobile: boolean;

	@Input() selectedPaymentMethodId: string;
	@Input() newPaymentMethod: PaymentMethod;
	@Input() isOldPaymentMethod = false;

	@Input() frmCustomerInfo: FormGroup;

	@Input() LOGIN_PAGE_ROUTE;

	@Input() isCheckOutPage: boolean;

	@Output() dataInit = new EventEmitter();

	@Output() confirmPayment = new EventEmitter<PaymentStatusResult>();

	@Output() activateRes = new EventEmitter<ActivateAccountRes>();

	@Output() confirmPaymentBtnClicked = new EventEmitter();

	@Output() priceOfferError = new EventEmitter<string>();

	@Output() validAddressEvent = new EventEmitter<boolean>(false);

	eSubscriptionBillingStatus = ESubscriptionBillingStatus;

	threeDSIframeSrc: string;

	priceMonthlyOffer: PricingOfferResult;
	priceYearlyOffer: PricingOfferResult;
	yearlyPayment = false;
	math = Math;

	promoCodeFormCtrl: FormControl;
	captchaCtrl: FormControl;

	promotionCode = new FormControl();
	showPromoCode = false;
	promoCodeError = '';
	currentPaymentStep: EPaymentServiceSteps;
	showConfirmPaymentBtnSpinner = false;
	showApplyPromoCodeSpinner = false;
	hideAddNewCouponOption = false;
	showPaymentSpinner = false;
	changedFromMonthly = false;
	initFinished = false;
	invalidAddressErr: boolean = false;
	isUserLoggedIn = false;
	registedUserEmail = '';

	translations = {} as any;
	private _unsub = new Subject();

	get vatPrice() {
		return this.priceOffer?.vat == 0
			? this.priceOffer?.vat
			: this._decimalPipe.transform(this.priceOffer?.vat, '1.2-2');
	}
	get productName() {
		if (this.productType == EProductType.Repositories || this.productType == EProductType.Supports) {
			return GetProductName(this.productType);
		} else {
			return GetPlatformProductName(this.selectedPlatformProduct);
		}
	}

	get loadingCheckOutPage() {
		return this.isCheckOutPage && (this.isLoading || this.showPaymentSpinner);
	}
	constructor(
		private _paymentSvc: PaymentService,
		private _fb: FormBuilder,
		private _translateSvc: CLSTranslateService,
		private _decimalPipe: DecimalPipe,
		private readonly _dir: AppDirectionalityService,
		private _clsAlertsSvc: ClsAlertsService,
		private _dialogSvc: MatDialog,
		private _router: Router,
		private _stripe: Stripe,
		public sanitizer: DomSanitizer,
		private _authSvc: AuthService,
		private _userSvc: UserService,
		private _screenSvc: ScreenService,
		private _gtagSvc: GoogleTagMangerService
	) {}

	async ngOnChanges(simpleChanges: SimpleChanges) {
		if (simpleChanges.billingAddress && this.initFinished) {
			this.invalidAddressErr = false;
			await this._updatePriceOffer();
		}
		if (simpleChanges.selectedPlan && simpleChanges.selectedPlan.currentValue) {
			await this._updatePriceOffer(true);
		}
		this.getPlanType();
	}

	async ngOnInit() {
		try {
			this.isUserLoggedIn = this._authSvc.isLoggedIn();
			this.promoCodeFormCtrl = this._fb.control(this.coupon ? this.coupon : '', [Validators.required]);
			this.captchaCtrl = this._fb.control('', [Validators.required]);

			this.dataInit.emit(true);
			this.initFinished = true;
		} catch (error) {
			this.dataInit.emit(false);
		}
	}

	private async _updatePriceOffer(force = false) {
		try {
			this.getPlanType();
			if (!force) {
				if (!(this.billingAddress && this.billingAddress.countryCode)) return;
			}
			if (!this.selectedPlan) return;
			this.showPaymentSpinner = true;
			const promoCode = this.promoCodeFormCtrl?.value;

			this.priceOffer = await this._paymentSvc.getPricingOffer(
				{
					planId: this.selectedPlan.id,
					billingAddress: this.billingAddress,
					coupon: promoCode ? promoCode : null,
				},
				this.productType
			);
			if (this.priceOffer.couponDiscount) this.showPromoCode = true;

			if (!this.yearlyPayment) {
				this.priceMonthlyOffer = this.priceOffer;
				if (this.yearlyPlan)
					this.priceYearlyOffer = await this._paymentSvc.getPricingOffer(
						{
							planId: this.yearlyPlan.id,
							billingAddress: this.billingAddress,
							coupon: promoCode ? promoCode : null,
						},
						this.productType
					);
			} else {
				this.priceYearlyOffer = this.priceOffer;
			}
			if (this.billingAddress && this.priceOffer) {
				this.validAddressEvent.emit(true);
			}
			this.priceOfferError.emit(null);
		} catch (error) {
			if (error?.error?.code == 'bad-coupon') {
				this.promoCodeFormCtrl.setValue('');
				await this._updatePriceOffer();
				this.promoCodeError = $localize`No such coupon.`;
				this.showPromoCode = true;
			} else if (error?.error?.code == 'invalid-address') {
				this.hideAddNewCouponOption = true;
				this.invalidAddressErr = true;
				this.validAddressEvent.emit(false);
				this.priceOfferError.emit(error?.error.description);
			} else {
				this.hideAddNewCouponOption = true;
				this.invalidAddressErr = true;
				console.log(error);
				throw error;
			}
		} finally {
			this.showPaymentSpinner = false;
		}
	}

	getPlanType(billed = true) {
		const _selectedPlan = this.selectedPlan;
		let planType = '';
		if (_selectedPlan && _selectedPlan?.interval?.months === 12) {
			this.yearlyPayment = true;
			planType = $localize`Yearly`;
		} else {
			planType = $localize`Monthly`;
		}
		if (billed) return planType?.toLocaleLowerCase();
		return planType;
	}

	changeToYearlyPlan() {
		if (this.showConfirmPaymentBtnSpinner) return;
		this.priceOffer = this.priceYearlyOffer;
		this.selectedPlan = this.yearlyPlan;
		this.yearlyPayment = true;
		this.changedFromMonthly = true;
	}

	changeToMonthlyPlan() {
		if (this.showConfirmPaymentBtnSpinner) return;
		this.priceOffer = this.priceMonthlyOffer;
		this.selectedPlan = this.monthlyPlan;
		this.yearlyPayment = false;
	}

	isMonthlyPlan() {
		// tslint:disable-next-line: triple-equals
		if (this.selectedPlan && this.selectedPlan.interval.months == 12) {
			return false;
		}
		return true;
	}

	getPercentageDiscount() {
		return this.monthlyPlan && this.yearlyPlan
			? Math.floor(((this.monthlyPlan.price * 12 - this.yearlyPlan.price) * 100) / (this.monthlyPlan.price * 12))
			: 0;
	}

	async applyPromoCode() {
		if (this.showConfirmPaymentBtnSpinner) return;
		this.showApplyPromoCodeSpinner = true;
		this.promoCodeError = '';
		const promoCodeCode = this.promoCodeFormCtrl.value;
		if (promoCodeCode) {
			try {
				await this._updatePriceOffer(true);
			} catch (error) {
				this.promoCodeFormCtrl.setValue(null);
				const ex = error as HttpErrorResponse;
				if (ex && ex.error) {
					const resError = ex.error as IAPICustomErrors;
					if (resError.description) {
						this.promoCodeError = resError.description;
					} else {
						this.promoCodeError = $localize`No such coupon.`;
					}
				}
			} finally {
				this.showApplyPromoCodeSpinner = false;
			}
		}
	}

	calculateDiscountToPercentage() {
		if (this.priceOffer.itemsPrice === 0) {
			return $localize`0`;
		}
		const percentage = (Math.abs(this.priceOffer?.couponDiscount) / this.priceOffer.itemsPrice) * 100;
		return $localize`${percentage.toFixed(0)}`;
	}

	showPromoCodeInput() {
		if (this.showConfirmPaymentBtnSpinner) return;
		this.showPromoCode = true;
		setTimeout(() => {
			this.promoCodeInput.nativeElement.focus();
		});
	}

	onPromoCodeInputKeyDown(event: KeyboardEvent) {
		if (this.showConfirmPaymentBtnSpinner) return;
		if (event.key === 'Escape') {
			this.promoCodeFormCtrl.setValue('');
			this.hidePromoCodeIfEmpty();
		}
	}

	hidePromoCodeIfEmpty() {
		if (this.showConfirmPaymentBtnSpinner) return;
		const code = this.promoCodeFormCtrl.value;
		if (!code) {
			this.showPromoCode = false;
		}
	}

	async clearPromoCode() {
		if (this.showConfirmPaymentBtnSpinner) return;
		this.showPromoCode = false;
		this.promoCodeFormCtrl.setValue('');
		if (this.priceMonthlyOffer?.couponDiscount != 0) {
			this.priceMonthlyOffer = null;
		}
		if (this.priceYearlyOffer?.couponDiscount != 0) {
			this.priceYearlyOffer = null;
		}
		this.promotionCode.setValue(null);

		await this._updatePriceOffer();
	}

	async confirmPaymentFunc() {
		try {
			if (
				(!this.isOldPaymentMethod && !this.newPaymentMethod) ||
				(this.isOldPaymentMethod && !this.selectedPaymentMethodId) ||
				this.showApplyPromoCodeSpinner ||
				(!this.isUserLoggedIn && this.frmCustomerInfo.invalid)
			)
				return;

			if (!this.billingAddress) return;
			if (!this.isOldPaymentMethod && this.isUserLoggedIn) {
				this.currentPaymentStep = EPaymentServiceSteps.UpdatePaymentMethod;
				const setupIntent = await this._paymentSvc.createAndConfirmPaymentMethod(this.newPaymentMethod);

				if (setupIntent) {
					this.currentPaymentStep = EPaymentServiceSteps.Processing;
					await this.payForSubscription(this.newPaymentMethod.id);
					return true;
				} else {
					this.showConfirmPaymentBtnSpinner = false;
					this.confirmPayment.emit({
						isSuccess: false,
						hideError: true,
						errorMsg: 'Invalid credit card.',
					});
					return false;
				}
			} else if (this.isUserLoggedIn) {
				this.currentPaymentStep = EPaymentServiceSteps.Processing;
				await this.payForSubscription(this.selectedPaymentMethodId);
				return true;
			} else {
				this.currentPaymentStep = EPaymentServiceSteps.Processing;
				await this.payForSubscription(this.newPaymentMethod.id);
				return true;
			}
			// No customer action needed
		} catch (error) {
			if (!this.isUserLoggedIn) {
				this.frmCustomerInfo.get('captcha').setValue(null);
			}
			let hide = false;
			if (
				(error.status === HttpStatusCode.FORBIDDEN && error?.error?.code === EAPIErrorCodes.BillingBlock) ||
				error?.message?.code === EAPIErrorCodes.BillingBlock
			)
				hide = true;
			this.confirmPayment.emit({
				isSuccess: false,
				hideError: hide,
				errorMsg: error.error,
			});
			return false;
		}
	}

	private async payForSubscription(paymentMethodId) {
		try {
			let invoice: Invoice;
			let repoId: string;
			let subscriptionRequestModel = {
				repoId: this.repositoryId,
				coupon: this.promoCodeFormCtrl.value,
				finalAmount: this.priceOffer.totalPrice,
				idempotencyKey: Math.random().toString(36).substring(7),
				maxRefil: 0,
				country: this._authSvc.user$.value.countryCode,
				paymentMethodId,
				priceId: this.selectedPlan.id,
				email: this.registedUserEmail,
				isEncyrptCoupon: false,
				platformProductType: this.selectedPlatformProduct,
				log: {
					source: this.leadName,
					url: window?.location.href,
					platform: this._screenSvc.getPlatform(),
					trackingId: this._userSvc.getSignUpLeadName(),
				},
			} as PayForSubscriptionRequestModel;
			if (this.productType == EProductType.Repositories) {
				let result = await this._paymentSvc.createRepoSubscription({
					repoId: this.repositoryId,
					...subscriptionRequestModel,
				} as PayForRepositroySubscriptionRequestModel);
				invoice = result.invoice;
				repoId = result.repoId;
			} else {
				invoice = await this._paymentSvc.createSubscription(subscriptionRequestModel, this.productType);
			}

			let paymentIntent = invoice.payment_intent;

			let isSuccess = false;
			if (invoice.paid) {
				isSuccess = true;
			} else if (
				paymentIntent.status === EPaymentIntentStatus.REQUIRES_ACTION ||
				paymentIntent.status === EPaymentIntentStatus.REQUIRES_PAYMENT_METHOD
			) {
				paymentIntent = (await this._stripe.confirmCardPayment(paymentIntent.client_secret)).paymentIntent;
				if (paymentIntent && paymentIntent.status === EPaymentIntentStatus.SUCCEEDED) {
					isSuccess = true;
				} else {
					isSuccess = false;
				}
			} else if (paymentIntent.status === EPaymentIntentStatus.SUCCEEDED) {
				isSuccess = true;
			} else if (
				paymentIntent.status === EPaymentIntentStatus.CANCELED ||
				paymentIntent.status === EPaymentIntentStatus.REQUIRES_PAYMENT_METHOD
			) {
				isSuccess = false;
			}

			if (isSuccess) {
				this._gtagSvc.eventPurchase(this.getTranscation(), {
					transactionId: invoice.id,
					value: this.priceOffer.totalPrice.toString(),
				} as ITransaction);
			}
			if (this.productType == EProductType.Repositories && repoId) {
				this.confirmPayment.emit({
					isSuccess,
					repoId,
					invoiceId: invoice.id,
					errorMsg: invoice.errorMsg,
				});
			} else {
				this.confirmPayment.emit({
					isSuccess,
					invoiceId: invoice.id,
					errorMsg: invoice.errorMsg,
				});
			}
			this.showConfirmPaymentBtnSpinner = false;
		} catch (error) {
			let hide = false;
			if (
				(error.status === HttpStatusCode.FORBIDDEN && error?.error?.code === EAPIErrorCodes.BillingBlock) ||
				error?.message?.code === EAPIErrorCodes.BillingBlock
			)
				hide = true;
			this.confirmPayment.emit({
				isSuccess: false,
				hideError: hide,
				errorMsg: error.error,
			});
		}
	}

	confirmPaymentBtnClickedFunc() {
		if (!this.invalidAddressErr) {
			this.confirmPaymentBtnClicked.emit(true);
		}
	}

	async saveUserDetailsWithCaptcha() {
		try {
			this.showConfirmPaymentBtnSpinner = true;

			//this.sendTranscation();
			const billingFormData = this.frmCustomerInfo?.getRawValue();

			if (!this.isUserLoggedIn && !billingFormData.agreeToTermOfUse) {
				return;
			}

			if (this.frmCustomerInfo?.invalid) {
				if (
					!this.isUserLoggedIn &&
					!billingFormData.captcha &&
					this.newPaymentMethod &&
					billingFormData.firstName &&
					billingFormData.newPassword &&
					billingFormData.lastName &&
					billingFormData.country &&
					billingFormData.email &&
					billingFormData.agreeToTermOfUse
				) {
					this.setBillingAdressCaptcha();
					return;
				} else if (!this.isUserLoggedIn) {
					return;
				}
			}

			if (!this.isUserLoggedIn) {
				this.registedUserEmail = billingFormData.email;
				await this._userSvc.register({
					email: billingFormData.email,
					captcha: billingFormData.captcha,
					registeringFrom: 'Anonymous Payment',
					firstName: billingFormData.firstName,
					lastName: billingFormData.lastName,
					countryCode: billingFormData.country,
					company: billingFormData.company,
					registrationMode: eRegistrationMode.OnPaySession,
					password: billingFormData.newPassword,
				});
				const userData: IUserDetailsFormData = {
					firstName: billingFormData.firstName,
					lastName: billingFormData.lastName,
					country: billingFormData.country,
					company: billingFormData.company,
				};
				this._dialogSvc.open(UserRegisterDialogComponent, {
					width: '480px',
					direction: this._dir.value,
					data: {
						loginPageRoute: `/${AccountPagesRoutes.LoginRedirect}`,
						disableAutoLogin: true,
						registeringFrom: 'Pricing Page',
						email: this.registedUserEmail,
						userInfo: userData,
						onRegisterationSuccess: async (email, result) => {
							await this.confirmPaymentFunc();
							this.activateRes.emit(result);
						},
						onRegisterationCancel: async () => {
							this.frmCustomerInfo.enable();
							// this.frmCustomerInfo.get('captcha').setValue(null);
						},
					} as IUserRegisterDialogData,
				});
			} else {
				await this.confirmPaymentFunc();
			}
		} catch (ex) {
			const err: HttpErrorResponse = ex;
			if (!this.isUserLoggedIn) {
				this.frmCustomerInfo.get('captcha').setValue(null);
			}
			if (err.status === HttpStatusCode.BAD_REQUEST) {
				const errorBody: IAPICustomErrors = err.error as IAPICustomErrors;
				if (errorBody && errorBody.code) {
					switch (errorBody.code) {
						case EAPIErrorCodes.CaptchaValidationError:
							throw EAPIErrorCodes.CaptchaValidationError;
						case EAPIErrorCodes.RegisterEmailNotConfirmed:
							// user already exists but not confirmed
							const billingFormData = this.frmCustomerInfo.getRawValue();
							this.showLoginDialog(
								$localize`Account already exists`,
								$localize`Login to your account to complete your purchase`,
								billingFormData.email
							);
							return;
						case EAPIErrorCodes.TempEmail:
							this.frmCustomerInfo.get('email').setValue(null);
							this._clsAlertsSvc.showCustomError($localize`Temporary email address is forbidden.`);
							return;
						case EAPIErrorCodes.Bademail:
							this.frmCustomerInfo.get('email').setValue(null);
							this._clsAlertsSvc.showCustomError($localize`Email is not valid`);
							return;
						default:
							if (errorBody.description) this._clsAlertsSvc.showCustomError(errorBody.description);
							break;
					}
				}
			}

			if (err.status === HttpStatusCode.CONFLICT) {
				// user already exists
				const billingFormData = this.frmCustomerInfo.getRawValue();
				this.showLoginDialog(
					$localize`Account already exists`,
					$localize`Login to your account to complete your purchase`,
					billingFormData.email
				);
				return;
			}
			this._clsAlertsSvc.showHttpResponseError(ex);
		} finally {
			this.showConfirmPaymentBtnSpinner = false;
		}
	}

	private setBillingAdressCaptcha() {
		this._dialogSvc.open(CopyLeaksDialogComponent, {
			panelClass: ['cs-dialog-action-center'],
			direction: this._dir.value,
			data: {
				title: $localize`Verify you are not a robot?`,
				showCaptcha: true,
				hideCancelButton: true,
				btnSubmitText: $localize`Continue`,
				onSubmit: async data => {
					try {
						this.frmCustomerInfo.get('captcha').setValue(data.captcha);
						await this.saveUserDetailsWithCaptcha();
					} catch (error) {
						if (error === EAPIErrorCodes.CaptchaValidationError) {
							data.captchaComponent.generateCaptcha();
							throw ECopyLeaksDialogSubmitErrors.CaptchaNotValid;
						}
						this._clsAlertsSvc.showHttpResponseError(error);
					}
				},
				onCancel: () => {
					this.frmCustomerInfo.get('captcha').setValue(null);
				},
			} as ICopyLeaksDialogData,
		});
	}

	private getTranscation() {
		const planDetails = this.selectedPlan;
		return {
			id: `${planDetails.id}`,
			name: `${planDetails.credits} Credits Plan`,
			location: location?.href,
			yearly: this.yearlyPayment,
			price: `${this.priceOffer.totalPrice}`,
			category: this.yearlyPayment ? 'yearly' : 'monthly',
			discount: this.priceOffer.couponDiscount,
			list_name: this._authSvc.getCurrentUserProductType().toString(),
			couponName: this.coupon,
		} as IItem;
	}

	private showLoginDialog(title: string, description: string, email: string = '') {
		this._dialogSvc.open(CopyLeaksDialogComponent, {
			direction: this._dir.value,
			data: {
				title,
				description,
				hideCancelButton: true,
				btnSubmitText: $localize`Login`,
				onSubmit: async () => {
					this._router.navigate([`/${AccountPagesRoutes.LoginRedirect}`], {
						queryParams: {
							isLogin: true,
						},
					});
					// this._router.navigate(['/account/login', { email }], {
					// 	queryParams: {
					// 		returnUrl: `${this._urlLocationSvc.pathname}${this._urlLocationSvc.location.search}`
					// 	}
					// });
				},
			} as ICopyLeaksDialogData,
		});
	}

	ngOnDestroy(): void {
		this._unsub.complete();
		this._unsub.next(null);
	}
}

export interface PaymentStatusResult {
	isSuccess: boolean;
	repoId?: string;
	invoiceId?: string;
	hideError?: boolean;
	errorMsg?: string;
}
