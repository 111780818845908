import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from '../../services/enviroments.service';
import { environment } from '@environment';

@Injectable({
	providedIn: 'root',
})
export class CopyleaksCaptchaService {
	private _baseUrl: string;
	private _translations: ICopyleaksCaptchaTranslations;
	constructor(private _http: HttpClient, private _envSvc: EnvironmentService) {
		this._baseUrl = environment.captchaUrl;
	}

	setTranslations(translations: ICopyleaksCaptchaTranslations) {
		this._translations = translations;
	}

	getTranslations(): ICopyleaksCaptchaTranslations {
		return this._translations;
	}

	async generate() {
		try {
			return await this._http.get<ICaptchaGenerateResult>(`${this._baseUrl}/generate`).toPromise();
		} catch (error) {
			throw error;
		}
	}

	async verify(payloads: ICaptchaVerifyPayloads) {
		try {
			return await this._http.post<ICaptchaVerifyResult>(`${this._baseUrl}/verify`, payloads).toPromise();
		} catch (error) {
			throw error;
		}
	}
}

export interface ICaptchaVerifyResult {
	reason: string;
	success: boolean;
}

export interface ICaptchaVerifyPayloads {
	tracking: string;
	answer: string;
}

export interface ICaptchaGenerateResult {
	tracking: string;
	image: string;
}

export interface ICopyleaksCaptchaTranslations {
	placeholder: string;
	validations: {
		txtRequiredMessage: string;
		txtNotValidMessage: string;
	};
}
