<div class="content-wrapper">
	<div [class]="isSelfContained ? 'absolut-contained' : ''">
		<div
			class="content-container"
			[class]="isSelfContained ? 'self-contained' : ''"
			fxLayout="column"
			[fxLayoutGap]="gapSize"
			fxLayoutAlign="center">
			<div class="title" [style.fontSize]="titleSize" [style.textAlign]="isTextCentered ? 'center' : 'left'">
				<ng-content select="cls-contained-title"></ng-content>
			</div>
			<div class="description" [style.textAlign]="isTextCentered ? 'center' : 'left'">
				<ng-content select="cls-contained-description"></ng-content>
			</div>
		</div>
	</div>
</div>
