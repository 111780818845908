<div class="dynamic-bread-crumbs-skeleton-loader-container" *ngIf="showLoader()">
	<ngx-skeleton-loader></ngx-skeleton-loader>
</div>

<ng-container *ngIf="!showLoader() && !isExpandedBreadcrumbs">
	<ng-container *ngFor="let path of navPaths; let idx = index">
		<span
			*ngIf="isFolderSelect || path.onEdit || path.onClick"
			[ngStyle]="{ cursor: idx == navPaths.length - 1 ? 'default' : 'pointer' }"
			[ngClass]="{
				'last-nav-item': idx == navPaths.length - 1,
				editable: path.onEdit
			}"
			(click)="path.onEdit ? enalbeEditMode(path, editInput, hiddenText) : onclick(path)"
			class="breadcrumb-nav-item"
			matTooltip="Rename"
			i18n-matTooltip
			[matTooltipDisabled]="!path.onEdit"
			[style.display]="path.isEditMode ? 'none' : ''">
			<span *ngIf="!path.ignoreTranslate">{{ path.name | ellipsis: 30 }}</span>
			<span *ngIf="path.ignoreTranslate">{{ path.name | ellipsis: 30 }}</span>
		</span>

		<span
			*ngIf="!path.onEdit && !path.onClick && !isFolderSelect && !path.isViewer"
			[ngStyle]="{ cursor: idx == navPaths.length - 1 ? 'default' : 'pointer' }"
			class="breadcrumb-nav-item">
			<a
				[routerLink]="path.link !== undefined && path.link ? path.link : ''"
				[queryParams]="path.id !== undefined && path.id ? { folderId: path.id } : {}"
				[attr.aria-label]="path.name"
				role="link">
				<span [ngClass]="{ 'last-nav-item': idx == navPaths.length - 1 }" *ngIf="!path.ignoreTranslate">
					{{ path.name | ellipsis: 30 }}
				</span>
			</a>
			<a
				[routerLink]="path.link !== undefined && path.link ? path.link : ''"
				[queryParams]="path.id !== undefined && path.id ? { folderId: path.id } : {}"
				[attr.aria-label]="path.name"
				role="link">
				<span [ngClass]="{ 'last-nav-item': idx == navPaths.length - 1 }" *ngIf="path.ignoreTranslate">
					{{ path.name | ellipsis: 30 }}
				</span>
			</a>
		</span>

		<span
			*ngIf="!path.onEdit && !path.onClick && !isFolderSelect && path.isViewer"
			[ngStyle]="{ cursor: idx == navPaths.length - 1 - menuEndIndex ? 'default' : 'pointer' }"
			class="breadcrumb-nav-item">
			<span [ngClass]="{ 'last-nav-item': idx == navPaths.length - 1 - menuEndIndex }" *ngIf="!path.ignoreTranslate">
				{{ path.name | ellipsis: 30 }}
			</span>
			<span [ngClass]="{ 'last-nav-item': idx == navPaths.length - 1 - menuEndIndex }" *ngIf="path.ignoreTranslate">
				{{ path.name | ellipsis: 30 }}
			</span>
		</span>

		<input
			type="text"
			#editInput
			maxlength="255"
			[style.width]="path.inputWidth + 'px'"
			(input)="onInputChange(editInput, path, hiddenText)"
			[style.display]="!path.isEditMode ? 'none' : ''"
			[ngClass]="{ 'edit-input-error': inputHasError }"
			class="edit-input"
			(blur)="saveEditedValue(path, editInput)"
			(keydown)="onKeyDown($event, path, editInput)"
			[value]="path.name" />

		<span #hiddenText class="edit-input" style="visibility: hidden; white-space: pre; position: absolute">
			{{ editInput?.value }}
		</span>

		<span
			class="arrow-icon"
			fxLayoutAlign="center center"
			[ngStyle]="{ color: idx == navPaths.length - 2 ? '#3F9AF5' : '#11296066' }"
			*ngIf="idx != navPaths.length - 1 && direction === 'ltr'">
			<mat-icon>keyboard_arrow_right</mat-icon>
		</span>

		<span
			class="arrow-icon"
			fxLayoutAlign="center center"
			[ngStyle]="{ color: idx == navPaths.length - 2 ? '#3F9AF5' : '#11296066' }"
			*ngIf="idx != navPaths.length - 1 && direction === 'rtl'">
			<mat-icon>keyboard_arrow_left</mat-icon>
		</span>
	</ng-container>
</ng-container>

<ng-container *ngIf="!showLoader() && isExpandedBreadcrumbs">
	<!-- First Loop -->
	<ng-container *ngFor="let path of navPaths?.slice(0, menuStartIndex); let idx = index">
		<a
			*ngIf="isFolderSelect || path.onEdit || path.onClick"
			[ngStyle]="{ cursor: idx == navPaths.length - 1 ? 'default' : 'pointer' }"
			[ngClass]="{ 'last-nav-item': idx == navPaths.length - 1 }"
			[routerLink]="path.link !== undefined && path.link ? path.link : ''"
			[queryParams]="path.id !== undefined && path.id ? { folderId: path.id } : {}"
			role="link">
			<span *ngIf="!path.ignoreTranslate">{{ path.name | ellipsis: 30 }}</span>
			<span *ngIf="path.ignoreTranslate">{{ path.name | ellipsis: 30 }}</span>
		</a>

		<span
			*ngIf="!path.onEdit && !path.onClick && !isFolderSelect"
			[ngStyle]="{ cursor: idx == navPaths.length - 1 ? 'default' : 'pointer' }"
			[ngClass]="{ 'last-nav-item': idx == navPaths.length - 1 }"
			class="breadcrumb-nav-item">
			<a
				[routerLink]="path.link !== undefined && path.link ? path.link : ''"
				[queryParams]="path.id !== undefined && path.id ? { folderId: path.id } : {}"
				[attr.aria-label]="path.name"
				role="link">
				<span *ngIf="!path.ignoreTranslate">{{ path.name | ellipsis: 30 }}</span>
			</a>
			<a
				[routerLink]="path.link !== undefined && path.link ? path.link : ''"
				[queryParams]="path.link !== undefined && path.link ? { folderId: path.id } : {}"
				[attr.aria-label]="path.name"
				role="link">
				<span *ngIf="path.ignoreTranslate">{{ path.name | ellipsis: 30 }}</span>
			</a>
		</span>

		<span
			class="arrow-icon"
			[ngStyle]="{ color: idx == navPaths.length - 2 ? '#3F9AF5' : '#11296066' }"
			fxLayoutAlign="center center"
			*ngIf="direction === 'ltr'">
			<mat-icon>keyboard_arrow_right</mat-icon>
		</span>
		<span
			class="arrow-icon"
			[ngStyle]="{ color: idx == navPaths.length - 2 ? '#3F9AF5' : '#11296066' }"
			fxLayoutAlign="center center"
			*ngIf="direction === 'rtl'">
			<mat-icon>keyboard_arrow_left</mat-icon>
		</span>
	</ng-container>
	<!-- Menu Loop -->
	<ng-container>
		<div [matMenuTriggerFor]="menu" class="breadcrumb-nav-item three-dots">
			...
			<span class="tooltiptext" i18n="@@BREADCRUMBS_EXPAND_FULL_PATH">Expand Full Path</span>
		</div>
		<mat-menu #menu="matMenu">
			<ng-container *ngFor="let path of navPaths?.slice(menuStartIndex, menuEndIndex); let idx = index">
				<button
					mat-menu-item
					[routerLink]="path.link !== undefined && path.link ? path.link : ''"
					[queryParams]="path.id !== undefined && path.id ? { folderId: path.id } : {}">
					<mat-icon>folder_open</mat-icon>
					<span *ngIf="isFolderSelect || path.onEdit || path.onClick" [ngStyle]="{ cursor: 'pointer' }">
						<span *ngIf="!path.ignoreTranslate">{{ path.name | ellipsis: 30 }}</span>
						<span *ngIf="path.ignoreTranslate">{{ path.name | ellipsis: 30 }}</span>
					</span>

					<span
						*ngIf="!path.onEdit && !path.onClick && !isFolderSelect"
						[ngStyle]="{ cursor: 'pointer' }"
						class="breadcrumb-nav-item">
						<a
							[routerLink]="[path.link]"
							[queryParams]="{ folderId: path.id }"
							class="copyleaks-a-tag-black"
							[attr.aria-label]="path.name">
							<span *ngIf="!path.ignoreTranslate">{{ path.name | ellipsis: 30 }}</span>
						</a>
						<a
							[routerLink]="[path.link]"
							[queryParams]="{ folderId: path.id }"
							class="copyleaks-a-tag-black"
							[attr.aria-label]="path.name">
							<span *ngIf="path.ignoreTranslate">{{ path.name | ellipsis: 30 }}</span>
						</a>
					</span>
				</button>
			</ng-container>
		</mat-menu>
		<span class="arrow-icon" style="color: #3f9af5" fxLayoutAlign="center center" *ngIf="direction === 'ltr'">
			<mat-icon>keyboard_arrow_right</mat-icon>
		</span>
		<span class="arrow-icon" style="color: #3f9af5" fxLayoutAlign="center center" *ngIf="direction === 'rtl'">
			<mat-icon>keyboard_arrow_left</mat-icon>
		</span>
	</ng-container>
	<!-- Leftover loop -->
	<ng-container *ngFor="let path of navPaths?.slice(menuEndIndex, navPaths.length); let idx = index">
		<span
			*ngIf="isFolderSelect || path.onEdit || path.onClick"
			[ngStyle]="{ cursor: idx == navPaths.length - 1 ? 'default' : 'pointer' }"
			[ngClass]="{
				'last-nav-item': idx == navPaths.length - 1,
				editable: path.onEdit
			}"
			(click)="path.onEdit ? enalbeEditMode(path, editInput, hiddenText) : onclick(path)"
			class="breadcrumb-nav-item"
			matTooltip="Rename"
			i18n-matTooltip
			[matTooltipDisabled]="!path.onEdit"
			[style.display]="path.isEditMode ? 'none' : ''">
			<span *ngIf="!path.ignoreTranslate">{{ path.name | ellipsis: 30 }}</span>
			<span *ngIf="path.ignoreTranslate">{{ path.name | ellipsis: 30 }}</span>
		</span>
		<span
			*ngIf="!path.onEdit && !path.onClick && !isFolderSelect && !path.isViewer"
			[ngStyle]="{ cursor: idx == navPaths.length - 1 ? 'default' : 'pointer' }"
			[ngClass]="{ 'last-nav-item': idx == navPaths.length - 1 }"
			class="breadcrumb-nav-item">
			<a [routerLink]="[path.link]" [queryParams]="{ folderId: path.id }" [attr.aria-label]="path.name">
				<span *ngIf="!path.ignoreTranslate">{{ path.name | ellipsis: 30 }}</span>
			</a>
			<a [routerLink]="[path.link]" [queryParams]="{ folderId: path.id }" [attr.aria-label]="path.name">
				<span *ngIf="path.ignoreTranslate">{{ path.name | ellipsis: 30 }}</span>
			</a>
		</span>
		<span
			*ngIf="!path.onEdit && !path.onClick && !isFolderSelect && path.isViewer"
			[ngStyle]="{ cursor: idx == navPaths.length - 1 - menuEndIndex ? 'default' : 'pointer' }"
			[ngClass]="{ 'last-nav-item': idx == navPaths.length - 1 - menuEndIndex }"
			class="breadcrumb-nav-item">
			<span *ngIf="!path.ignoreTranslate">{{ path.name | ellipsis: 30 }}</span>
			<span *ngIf="path.ignoreTranslate">{{ path.name | ellipsis: 30 }}</span>
		</span>

		<input
			type="text"
			#editInput
			maxlength="255"
			[style.width]="path.inputWidth + 'px'"
			(input)="onInputChange(editInput, path, hiddenText)"
			[style.display]="!path.isEditMode ? 'none' : ''"
			[ngClass]="{ 'edit-input-error': inputHasError }"
			class="edit-input"
			(blur)="saveEditedValue(path, editInput)"
			(keydown)="onKeyDown($event, path, editInput)"
			[value]="path.name" />

		<span #hiddenText class="edit-input" style="visibility: hidden; white-space: pre; position: absolute">
			{{ editInput?.value }}
		</span>

		<span
			class="arrow-icon"
			[ngStyle]="{ color: idx == navPaths.length - 2 ? '#3F9AF5' : '#11296066' }"
			fxLayoutAlign="center center"
			*ngIf="direction === 'ltr' && idx != navPaths?.slice(menuEndIndex, navPaths.length).length - 1">
			<mat-icon>keyboard_arrow_right</mat-icon>
		</span>
		<span
			class="arrow-icon"
			[ngStyle]="{ color: idx == navPaths.length - 2 ? '#3F9AF5' : '#11296066' }"
			fxLayoutAlign="center center"
			*ngIf="direction === 'rtl' && idx != navPaths?.slice(menuEndIndex, navPaths.length).length - 1">
			<mat-icon>keyboard_arrow_left</mat-icon>
		</span>
	</ng-container>
</ng-container>
