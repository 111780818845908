import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { STRIPE_NEW_CARD_ID } from '../../../constants';
import { ESubscriptionBillingStatus } from '../../../services/payment-plan.service';
import { ScreenService } from '../../../services/screen.service';
import { EPaymentMethodTypes, IPaymentMethod, PaymentMethodsService } from '../payment-methods.service';

@UntilDestroy()
@Component({
	selector: 'cls-payment-methods',
	styleUrls: ['./payment-methods.component.scss'],
	templateUrl: `payment-methods.component.html`,
})
export class PaymentMethodsComponent implements OnInit {
	@Output() selectChange = new EventEmitter<IPaymentMethod>();
	@Output() updateCard = new EventEmitter();
	@Output() dataInit = new EventEmitter();

	@Input() disabledForm = false;
	@Input() selectedMethodWithBorder = true;
	@Input() newDesign = false;
	@Input() billingStatus: ESubscriptionBillingStatus;
	@Input() showCheckMarkCircle: boolean = false;
	get isMobile() {
		return this._screenSvc.isMobile();
	}

	isUpdatingPaymentMethod: boolean;
	defaultCard: IPaymentMethod = null;
	ePaymentMethodTypes = EPaymentMethodTypes;
	eSubscriptionBillingStatus = ESubscriptionBillingStatus;
	NEW_CARD_ID = STRIPE_NEW_CARD_ID;
	selectedCardId = STRIPE_NEW_CARD_ID;
	showSpinner = false;

	constructor(private _paymentMehodSvc: PaymentMethodsService, private _screenSvc: ScreenService) {}

	async ngOnInit() {
		try {
			this.showSpinner = true;
			if (this._paymentMehodSvc.getDefaultPaymentMethod()) {
				this._paymentMehodSvc
					.getDefaultPaymentMethod()
					.pipe(untilDestroyed(this))
					.subscribe(d => {
						this.dataInit.emit(true);
						this.defaultCard = d;
						this.selectedCardId = this.defaultCard.paymentMethodId;
						this.cardChanged(this.selectedCardId);
					});
			} else {
				this.dataInit.emit(true);
				this.defaultCard = null;
				this.selectedCardId = null;
				this.cardChanged(null);
			}
		} catch (err) {
			console.log(err);
		} finally {
			this.showSpinner = false;
		}
	}

	getCardTypeName(type: EPaymentMethodTypes) {
		switch (type) {
			case EPaymentMethodTypes.Visa:
				return 'Visa';
			case EPaymentMethodTypes.AmericanExpress:
				return 'American Express';
			case EPaymentMethodTypes.Discover:
				return 'Discover';
			case EPaymentMethodTypes.MasterCard:
				return 'MasterCard';
		}
	}

	cardChanged(paymentMethodId: string) {
		if (this.disabledForm) {
			return;
		}
		this.selectedCardId = paymentMethodId;
		const selectedCard =
			paymentMethodId === this.NEW_CARD_ID
				? ({ paymentMethodId: this.NEW_CARD_ID } as IPaymentMethod)
				: this.defaultCard;
		this.selectChange.emit(selectedCard);
	}

	onUpdateCard() {
		this.cardChanged(this.NEW_CARD_ID);
	}
}
