import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AvatarBackgroundColors, AvatarIcons } from '../../../enums/avatar.model';

@Component({
	selector: 'cls-avatar-icon',
	templateUrl: './avatar-icon.component.html',
	styleUrls: ['./avatar-icon.component.scss'],
})
export class AvatarIconComponent implements OnInit, OnChanges {
	@Input() id: string; // the id purpose is to generate default avatar
	@Input() backgroundColor: string;
	@Input() icon: string;
	@Input() iconSize: string = '';
	@Input() iconContainerSize: string = '';
	@Input() addEditIcon: boolean;
	@Input() editIconSize: string;
	@Input() iconPadding: string;

	@Output() edit = new EventEmitter<any>();

	buttonStyle = {};
	buttonContainerStyle = {};

	avatarIcons = Object.values(AvatarIcons);
	avatarBackgroundColors = Object.values(AvatarBackgroundColors);

	constructor() {}

	ngOnChanges(changes: SimpleChanges) {
		if (!changes.icon && !changes.backgroundColor && changes.id) this.createRandomAvatar(this.id);
		if (!this.icon || !this.backgroundColor) this.createRandomAvatar(this.id);
		this.buttonStyle = {
			color: this.getIconColor(),
			width: this.iconSize,
			height: this.iconSize,
			'font-size': this.iconSize,
			'line-height': this.iconSize,
			cursor: this.addEditIcon ? 'pointer' : '',
			padding: this.iconPadding ? this.iconPadding : '4px',
		};
		if (this.iconContainerSize)
			this.buttonContainerStyle = {
				width: this.iconContainerSize,
				height: this.iconContainerSize,
				padding: 0,
			};
	}

	ngOnInit(): void {}

	getIconColor(): string {
		switch (this.backgroundColor) {
			case AvatarBackgroundColors.MysticMaroon:
			case AvatarBackgroundColors.MajorelleBlue:
			case AvatarBackgroundColors.RoseGold:
			case AvatarBackgroundColors.MetallicBronze:
			case AvatarBackgroundColors.DeepGreen:
			case AvatarBackgroundColors.CharmPink:
			case AvatarBackgroundColors.MetallicSeaweed:
			case AvatarBackgroundColors.CrystalBlue:
				return '#F0F5F9';
			default:
				return '#52616B';
		}
	}

	createRandomAvatar(id: string) {
		let sumAssci = 0;
		for (let i = 0; i < id?.length - 1; i++) sumAssci += id.charCodeAt(i);
		this.icon = this.avatarIcons[sumAssci % 45];
		this.backgroundColor = this.avatarBackgroundColors[sumAssci % 18];
	}

	sendEditEvent() {
		this.edit.emit(null);
	}
}
