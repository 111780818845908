import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnalyticsActivityStatusComponent } from './analytics-activity-status.component';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
	declarations: [AnalyticsActivityStatusComponent],
	imports: [CommonModule, MatIconModule, FlexLayoutModule],
	exports: [AnalyticsActivityStatusComponent],
})
export class AnalyticsActivityStatusModule {}
