import { Component, EventEmitter, HostBinding, HostListener, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs';
import { NotificationErrorDialogComponent } from '../../../notifications/dialogs/notification-error-dialog/notification-error-dialog.component';
import { ProgressPersonalNotificationsService } from '../../../notifications/services/progress-personal-notifications.service';
import { ETaskElementStatus, IPersonalNotificationTask } from '../web-notification';

@UntilDestroy()
@Component({
	selector: 'cls-notification-index-document',
	templateUrl: './notification-index-document.component.html',
	styleUrls: ['./notification-index-document.component.scss'],
})
export class NotificationIndexDocumentComponent implements OnInit, OnChanges {
	@Input() model: IPersonalNotificationTask = null;
	@Input() isDeletingItem: boolean;
	@Input() isBackgroundTask: false;
	eTaskElementStatus = ETaskElementStatus;
	@Output() onNotificationClose = new EventEmitter<string>();
	progress = 0;

	@HostBinding('style.cursor') cursor = '';
	isHovering = false;
	allowCancel = true;

	@HostListener('mouseenter')
	onMouseEnter() {
		this.isHovering = true;
		if (
			this.model.metaData?.status == ETaskElementStatus.Done ||
			this.model.metaData?.status == ETaskElementStatus.Failed
		)
			this.cursor = 'pointer';
	}

	@HostListener('mouseleave')
	onMouseLeave() {
		this.isHovering = false;
		this.cursor = '';
	}

	@HostListener('click', ['$event.target'])
	onClick(btn) {
		// if the status of the notification is falied then show an error dialog on host click
		switch (this.model.metaData?.status) {
			case ETaskElementStatus.Failed:
				this.dialog.open(NotificationErrorDialogComponent, {
					panelClass: 'notification-error-frame-dialog',
					width: '553px',
					data: {
						errorMessages: this.model.metaData?.result,
						guideCode: this.model.metaData?.ticketId,
					},
				});
				break;
			default:
				break;
		}
	}

	constructor(public dialog: MatDialog, private _progressNotificationsSvc: ProgressPersonalNotificationsService) {}

	ngOnInit(): void {
		this._progressNotificationsSvc.onNotificationChange
			.pipe(
				untilDestroyed(this),
				filter(v => v.id == this.model.id)
			)
			.subscribe(value => {
				this.model = JSON.parse(JSON.stringify(value));
				this.progress = value.metaData.progress;
			});
	}

	ngOnChanges(): void {
		// make the notification clickable if the status = Falied
		this.cursor = this.model?.metaData?.status == ETaskElementStatus.Failed ? 'pointer' : '';
	}

	cancelIndexing() {
		this._progressNotificationsSvc.cancelNotification(this.model.id);
	}
}
