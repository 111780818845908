import { EPathElementGeneralType } from '../enums/path-element-general-type.enum';
import { EPathElementType } from '../enums/path-element-type.enum';
import { EElementShareRole } from './elements-share.models';

export enum EScanPageMode {
	myScansPage,
	sharedWithMe,
	reportPage,
}

export const SelectScanDestinationRootFoldersIDs = {
	MyScansRootId: '1',
	SharedWithMeRootId: '2',
};

export interface IFolderSegment {
	id: string;
	name: string;
	elementsScheduling?: IElementsScheduling;
}

export interface IPathElement extends IFolderSegment {
	dateCreated?: Date;
	generalType?: EPathElementGeneralType;
	parentId?: string;
	type?: EPathElementType;
	expirationDate?: Date;
	scanSnapshots?: IScanSnapshot[];
	isDisabled?: boolean;
	isShared?: boolean;
	isSharedWithTeam?: boolean;
	role?: EElementShareRole;
	recurringFailures?: number;
}

export interface IElementsScheduling {
	id?: string;
	elementId?: string;
	lastRunDate?: Date;
	nextRunDate?: Date;
	createDate?: Date;
	frequencyPeriod?: number;
	frequencyType?: EFrequencyTypes;
}

export interface IScanSnapshot {
	scanId: string;
	recurringScanId: string;
	status: EElementScanStatus;
	usedCredits: number;
	resultCount: number;
	score: number;
	aiCoverage: number;
	createDate: Date;
	usedProfileId?: string;
	errorCode: number;
	errorMessage: string;
}

export interface IFolderScheduledSegmentSettings {
	id?: string;
	name?: string;
	frequencyPeriod: number;
	frequencyType: EFrequencyTypes;
}

export enum EElementScanStatus {
	new = 1,
	complete = 2,
	priceChecked = 3,
	downloaded = 4,
	error = 5,
	indexed = 6,
}

export enum EFrequencyTypes {
	Never = 0,
	Days = 1,
	Weeks = 7,
	Months = 30,
}
