<ng-container [ngSwitch]="model?.metaData?.status">
	<div class="task-icon-container">
		<ng-container *ngSwitchCase="eTaskElementStatus.Running">
			<div class="done-icon">
				<div class="percentage" *ngIf="progress > 0">{{ progress }}%</div>
				<cls-spinner width="40px"></cls-spinner>
			</div>
		</ng-container>
		<ng-container *ngSwitchCase="eTaskElementStatus.Failed">
			<div class="unread-Notification-badge">
				<div
					class="failed-icon"
					[matBadge]="!model?.isRead && !isBackgroundTask ? '1' : ''"
					matBadgePosition="above before"
					matBadgeColor="peach"
					MatBadgeSize="small">
					<mat-icon>cancel</mat-icon>
				</div>
			</div>
		</ng-container>
		<ng-container *ngSwitchCase="eTaskElementStatus.Done">
			<div class="unread-Notification-badge">
				<div
					class="done-icon"
					[matBadge]="!model?.isRead && !isBackgroundTask ? '1' : ''"
					matBadgePosition="above before"
					matBadgeColor="peach"
					MatBadgeSize="small">
					<mat-icon>check_circle</mat-icon>
				</div>
			</div>
		</ng-container>
		<ng-container *ngSwitchDefault></ng-container>
	</div>
</ng-container>

<div class="message-contianer">
	<div class="title">
		<ng-container [ngSwitch]="model?.metaData?.status">
			<ng-container *ngSwitchCase="eTaskElementStatus.Running">
				<ng-container [ngTemplateOutlet]="runningText"></ng-container>
			</ng-container>
			<!--  -->
			<ng-container *ngSwitchCase="eTaskElementStatus.Failed">
				<ng-container [ngTemplateOutlet]="failedText"></ng-container>
			</ng-container>
			<!--  -->
			<ng-container *ngSwitchCase="eTaskElementStatus.Done">
				<ng-container [ngTemplateOutlet]="doneText"></ng-container>
			</ng-container>
			<!--  -->
		</ng-container>
	</div>
	<div class="description">
		{{ model.creationTime | dateFromNow: (model?.metaData?.status == eTaskElementStatus.Running ? 'Started' : '') }}
	</div>
</div>

<!-- Running template text  -->
<ng-template #runningText>
	<!-- 1 file or 1 folder -->
	<ng-container *ngIf="model?.metaData?.fileName">
		<span i18n="@@NGX.NOTIFICATION_DELETE_ITEM.RUNNING_ONE_ELEMENT_V1">Deleting&nbsp;</span>
		<span [matTooltip]="model?.metaData?.fileName">'{{ model?.metaData?.fileName | ellipsis: -30 }}'</span>
		...
	</ng-container>
	<!-- files only -->
	<ng-container
		*ngIf="model?.metaData?.totalFiles && !model?.metaData?.totalFolders && !model?.metaData?.fileName"
		i18n="@@NGX.NOTIFICATION_DELETE_ITEM.RUNNING_FILES">
		Deleting {{ model?.metaData?.totalFiles }} {model?.metaData?.totalFiles, plural, =1 {file} other {files} }...this
		may take a few seconds
	</ng-container>
	<!--  folders only -->
	<ng-container
		*ngIf="model?.metaData?.totalFolders && !model?.metaData?.totalFiles && !model?.metaData?.fileName"
		i18n="@@NGX.NOTIFICATION_DELETE_ITEM.RUNNING_FOLDERS">
		Deleting {{ model?.metaData?.totalFolders }}
		{model?.metaData?.totalFolders, plural, =1 {folder} other {folders} }...this may take a few seconds
	</ng-container>
	<!--  folders & files -->
	<ng-container *ngIf="model?.metaData?.totalFolders && model?.metaData?.totalFiles && !model?.metaData?.fileName">
		<span i18n>Deleting</span>
		{{ model?.metaData?.totalFolders }}
		<span i18n>{model?.metaData?.totalFolders, plural, =1 {folder} other {folders} }</span>
		<span i18n>&nbsp;and</span>
		{{ model?.metaData?.totalFiles }}
		<span i18n>{model?.metaData?.totalFiles, plural, =1 {file} other {files} }</span>
		<span i18n>...this may take a few seconds</span>
	</ng-container>
	<!--  -->
</ng-template>

<!-- done template text  -->
<ng-template #doneText>
	<!-- 1 file or 1 folder -->
	<ng-container *ngIf="model?.metaData?.fileName" i18n="@@NGX.NOTIFICATION_DELETE_ITEM.DONE_ONE_ELEMENT">
		<span [matTooltip]="model?.metaData?.fileName">'{{ model?.metaData?.fileName | ellipsis: -13 }}'</span>
		has been successfully deleted!
	</ng-container>
	<!-- files only -->
	<ng-container
		*ngIf="model?.metaData?.totalFiles && !model?.metaData?.totalFolders && !model?.metaData?.fileName"
		i18n="@@NGX.NOTIFICATION_DELETE_ITEM.DONE_FILES">
		{{ model?.metaData?.totalFiles }} {model?.metaData?.totalFiles, plural, =1 {file was} other {files were} }
		successfully deleted!
	</ng-container>
	<!--  folders only -->
	<ng-container
		*ngIf="model?.metaData?.totalFolders && !model?.metaData?.totalFiles && !model?.metaData?.fileName"
		i18n="@@NGX.NOTIFICATION_DELETE_ITEM.DONE_FOLDERS">
		{{ model?.metaData?.totalFolders }} {model?.metaData?.totalFolders, plural, =1 {folder was} other {folders were} }
		successfully deleted!
	</ng-container>
	<!--  folders & files -->
	<ng-container *ngIf="model?.metaData?.totalFolders && model?.metaData?.totalFiles && !model?.metaData?.fileName">
		{{ model?.metaData?.totalFolders }}
		<span i18n>{model?.metaData?.totalFolders, plural, =1 {folder} other {folders}} and</span>
		{{ model?.metaData?.totalFiles }}
		<span i18n>{model?.metaData?.totalFiles, plural, =1 {file} other {files}} were successfully deleted!</span>
	</ng-container>
	<!--  -->
</ng-template>

<!-- failed template text  -->
<ng-template #failedText>
	<!-- 1 file or 1 folder -->
	<ng-container *ngIf="model?.metaData?.fileName" i18n="@@NGX.NOTIFICATION_DELETE_ITEM.FAILED_ONE_ELEMENT">
		<span [matTooltip]="model?.metaData?.fileName">'{{ model?.metaData?.fileName | ellipsis: -30 }}'</span>
		failed to delete!
	</ng-container>
	<!-- files only -->
	<ng-container
		*ngIf="model?.metaData?.totalFiles && !model?.metaData?.totalFolders && !model?.metaData?.fileName"
		i18n="@@NGX.NOTIFICATION_DELETE_ITEM.FAILED_FILES">
		{{ model?.metaData?.totalFiles }} {model?.metaData?.totalFiles, plural, =1 {file} other {files} } failed to delete.
	</ng-container>
	<!--  folders only -->
	<ng-container
		*ngIf="model?.metaData?.totalFolders && !model?.metaData?.totalFiles && !model?.metaData?.fileName"
		i18n="@@NGX.NOTIFICATION_DELETE_ITEM.FAILED_FOLDERS">
		{{ model?.metaData?.totalFolders }} {model?.metaData?.totalFolders, plural, =1 {folder} other {folders} } failed to
		delete.
	</ng-container>
	<!--  folders & files -->
	<ng-container *ngIf="model?.metaData?.totalFolders && model?.metaData?.totalFiles && !model?.metaData?.fileName">
		{{ model?.metaData?.totalFiles }}
		<span i18n>{model?.metaData?.totalFiles, plural, =1 {file} other {files} }</span>
		<span i18n>&nbsp;and</span>
		{{ model?.metaData?.totalFolders }}
		<span i18n>{model?.metaData?.totalFolders, plural, =1 {folder} other {folders} }</span>
		<span i18n>&nbsp;failed to delete.</span>
	</ng-container>
	<!--  -->
</ng-template>
