import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { UserRegisterDialogModule } from '../../dialogs/user-register-dialog/user-register-dialog.module';
import { NotificationContainerModule } from '../../notifications/components/notification-container/notification-container.module';
import { WebNotificationModule } from '../../notifications/components/web-notification';
import { AvatarModule } from 'ngx-avatar';
import { PaymentDialogModule } from '../../dialogs/payment-dialog/payment-dialog.module';
import { PaymentDialogService } from '../../dialogs/payment-dialog/services/payment-dialog.service';
import { CenteredMatMenuDirectiveModule } from '../../directives/centered-mat-menu-directive/centered-mat-menu-directive.module';
import { PaymentsService } from '../../services/payments.service';
import { SupportService } from '../../services/support.service';
import { UserLocationService } from '../../services/user-location.service';
import { DynamicBreadcrumbNavigationModule } from '../dynamic-breadcrumb-navigation/dynamic-breadcrumb-navigation.module';
import { AppSkeletonLoaderModule } from '../skeleton-loader/skeleton-loader.module';
import { SpinnerModule } from '../spinner/spinner.module';
import { StatusMessageBannerModule } from '../status-message-banner/status-message-banner.module';
import {
	DashboardLinkItemComponent,
	DashboardLinkItemIconComponent,
	DashboardLinkItemTextComponent,
} from './components/dashboard-link-item/dashboard-link-item.component';
import { DashboardLinksComponent } from './components/dashboard-links/dashboard-links.component';
import { DashboardLogoComponent } from './components/dashboard-logo/dashboard-logo.component';
import { DashboardUserPlanComponent } from './components/dashboard-user-plan/dashboard-user-plan.component';
import { FeatureSuggestionComponent } from './components/feature-suggestion/feature-suggestion.component';
import { NewDashboardNavbarComponent } from './new-dashboard-navbar/new-dashboard-navbar.component';
import { MatBadgeModule } from '@angular/material/badge';
import { SecureImgPipeModule } from '../../pipes/secure/secure-img.module';
import { NewPaymentDialogModule } from '../../dialogs/new-payment-dialog/new-payment-dialog/new-payment-dialog.module';

@NgModule({
	declarations: [
		NewDashboardNavbarComponent,
		DashboardLinksComponent,
		DashboardLinkItemComponent,
		DashboardLogoComponent,
		DashboardLinkItemIconComponent,
		DashboardLinkItemTextComponent,
		FeatureSuggestionComponent,
		DashboardUserPlanComponent,
	],
	exports: [
		NewDashboardNavbarComponent,
		DashboardLinksComponent,
		DashboardLinkItemComponent,
		DashboardLogoComponent,
		DashboardLinkItemIconComponent,
		DashboardLinkItemTextComponent,
	],
	imports: [
		CommonModule,
		FlexLayoutModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		SecureImgPipeModule,
		AvatarModule,
		WebNotificationModule,
		NotificationContainerModule,
		AppSkeletonLoaderModule,
		SpinnerModule,
		CenteredMatMenuDirectiveModule,
		DynamicBreadcrumbNavigationModule,
		PaymentDialogModule,
		UserRegisterDialogModule,
		StatusMessageBannerModule,
		NewPaymentDialogModule,

		MatSidenavModule,
		MatFormFieldModule,
		MatBadgeModule,
		MatDividerModule,
		MatInputModule,
		MatListModule,
		MatDialogModule,
		MatProgressBarModule,
		MatTooltipModule,
		MatMenuModule,
		MatIconModule,
		MatButtonModule,
		MatToolbarModule,
	],
	entryComponents: [FeatureSuggestionComponent],
	providers: [SupportService, PaymentDialogService, PaymentsService, UserLocationService],
})
export class NewDashboardNavbarModule {}
