import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ClsAlertsService } from '@ngx-common-v2/components/cls-alerts/services/cls-alerts.service';
import { debounceTime, withLatestFrom } from 'rxjs/operators';
import { CopyleaksCaptchaComponent } from '../../../../components/copyleaks-captcha/copyleaks-captcha-component/copyleaks-captcha.component';
import { PasswordFormFieldComponent } from '../../../../components/password-form-field/password-form-field-component/password-form-field.component';
import { PasswordFormFieldService } from '../../../../components/password-form-field/password-form-field.service';
import { EDataSharingKeys } from '../../../../enums/data-sharing-keys.enum';
import { EAPIErrorCodes } from '../../../../enums/error-codes.enum';
import { HttpStatusCode } from '../../../../enums/http-status-code.enum';
import { IAPICustomErrors } from '../../../../models/api-custom-errors.model';
import { AuthService } from '../../../../services/auth.service';
import { DataSharingService } from '../../../../services/data-sharing.service';
import { ESSOProviders } from '../../../../services/oidc.service';
import { UserService, eRegistrationMode } from '../../../../services/user.service';
import { focusErrorInput } from '../../../../utils/focus-error-input.utils';
import { IUserRegisterDialogData } from '../../user-register-dialog.component';

class CustomErrorStateMatcher implements ErrorStateMatcher {
	isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
		return !!(control && control.invalid && (control.dirty || control.touched));
	}
}

@UntilDestroy()
@Component({
	selector: 'app-user-register-email-entry',
	templateUrl: './user-register-email-entry.component.html',
	styleUrls: ['./user-register-email-entry.component.scss'],
})
export class UserRegisterEmailEntryComponent implements OnInit, AfterViewInit {
	@ViewChild('adminInput', { read: ElementRef }) adminInput: ElementRef;
	@ViewChild('emailInput', { read: ElementRef }) emailInput: ElementRef;
	@Input() dialogData: IUserRegisterDialogData;
	@Output() registrationSuccess = new EventEmitter<string>();
	@ViewChild('passwordFormField') formField: PasswordFormFieldComponent;

	isLoadingPasswordPolicy = false;
	eSSOProviders = ESSOProviders;
	public frmRegister: FormGroup;
	public showButtonSpinner = false;
	public matCustomErrorStateMatcher = new CustomErrorStateMatcher();
	passwordPolicyErrorFlag = false;
	public captchaErrorTransCode = '';
	public takenEmail = '';
	public isEmailNotActive = false;
	public lastRegisteredEmail = '';
	public isEmailBanned = false;

	constructor(
		private _elementRef: ElementRef,
		private _fb: FormBuilder,
		private _userSvc: UserService,
		private _dataSharingSvc: DataSharingService,
		private _clsAlertsSvc: ClsAlertsService,
		private _authSvc: AuthService,
		private _passwordFormFieldSvc: PasswordFormFieldService,
		private _dialogSvc: MatDialog
	) {}

	ngOnInit(): void {
		this.frmRegister = this._fb.group({
			email: ['', [Validators.required, Validators.email]],
			captcha: [null, [Validators.required]],
			isAdmin: [false],
			newPassword: ['', Validators.required],
		});
		this._initSubs();
		this.frmRegister.controls['email'].valueChanges.pipe(untilDestroyed(this), debounceTime(500)).subscribe(value => {
			this.initPasswordPolicy();
		});
	}

	ngAfterViewInit(): void {
		if (this.dialogData.email) {
			this.frmRegister.controls['email'].setValue(this.dialogData.email);
			this.frmRegister.controls['email'].markAsDirty();
		}
	}

	async initPasswordPolicy() {
		try {
			if (!this.frmRegister.controls.email.valid) return;
			this.isLoadingPasswordPolicy = true;
			const email = this.frmRegister.controls.email.value;
			const passwordPolicy = await this._userSvc.getPasswordPolicyAsync(email);
			this._passwordFormFieldSvc.updatePasswordPolicy(passwordPolicy);
			this._passwordFormFieldSvc.updatePasswordPolicyErrorFlag(false);
			if (this.frmRegister.controls.newPassword.dirty && document?.activeElement != this.emailInput.nativeElement) {
				this.formField.newPasswordInput.nativeElement.focus();
			}
		} catch (error) {
			this._clsAlertsSvc.showHttpResponseError(error);
			this._passwordFormFieldSvc.updatePasswordPolicyErrorFlag(true);
		} finally {
			this.isLoadingPasswordPolicy = false;
		}
	}

	private _initSubs() {
		this._passwordFormFieldSvc.passwordPolicyErrorFlag$
			.pipe(untilDestroyed(this), withLatestFrom(this._passwordFormFieldSvc.passwordPolicy$))
			.subscribe(([result, passwordPolicy]) => {
				this.passwordPolicyErrorFlag = result;
			});
	}

	login() {
		this._authSvc.loginWithReturnUrl(this.dialogData.returnUrl);
		this._dialogSvc.closeAll();
	}

	signUp(provider: ESSOProviders) {
		this._authSvc.socialLogin(provider);
		this._dialogSvc.closeAll();
	}

	async register(captcha: CopyleaksCaptchaComponent) {
		try {
			captcha.captchaTextCtrl.markAllAsTouched();
			this.isEmailNotActive = false;
			this.captchaErrorTransCode = '';
			this.takenEmail = '';
			this.lastRegisteredEmail = '';

			if (this.frmRegister.invalid) {
				return;
			}

			this.showButtonSpinner = true;
			const adminInputValue = this.adminInput.nativeElement.value;

			const formData = this.frmRegister.getRawValue();
			this.lastRegisteredEmail = formData.email;
			if (this.dialogData && this.dialogData.returnUrl) {
				formData.returnUrl = this.dialogData.returnUrl;
			}
			this.dialogData.email = this.lastRegisteredEmail;
			const result = await this._userSvc.register({
				...formData,
				password: formData.newPassword,
				isAdmin: adminInputValue,
				registeringFrom: this.dialogData && this.dialogData.registeringFrom ? this.dialogData.registeringFrom : null,
				registrationMode: eRegistrationMode.OffPaySession,
			});

			this._dataSharingSvc.set(EDataSharingKeys.RegisteredEmail, formData.email);
			this._dataSharingSvc.set(EDataSharingKeys.TrackingId, result);
			this.registrationSuccess.emit(formData.email);
		} catch (error) {
			captcha.generateCaptcha();
			const err: HttpErrorResponse = error;
			if (err.status === HttpStatusCode.BAD_REQUEST) {
				const errorBody: IAPICustomErrors = err.error as IAPICustomErrors;
				if (errorBody && errorBody.code) {
					switch (errorBody.code) {
						case EAPIErrorCodes.CaptchaValidationError:
							this.captchaErrorTransCode = 'COPYLEAKS_CAPTCHA.WRONG_ANSWER';
							return;
						case EAPIErrorCodes.TempEmail:
							this.isEmailBanned = true;
							return;
						case EAPIErrorCodes.Bademail:
							//this.frmRegister.get('email').setValue(null);
							this._clsAlertsSvc.showCustomError($localize`Email is not valid.`);
							return;
						case EAPIErrorCodes.AlreadPartOfTeam:
							//this.frmRegister.get('email').setValue(null);
							this._clsAlertsSvc.showCustomError($localize`This account is part of a team.`);
							return;
						case EAPIErrorCodes.RegisterEmailNotConfirmed:
							const formData = this.frmRegister.getRawValue();
							this.registrationSuccess.emit(formData.email);
							return;
						default:
							if (errorBody.description) this._clsAlertsSvc.showCustomError(errorBody.description);
							break;
					}
				}
			}

			if (err.status === HttpStatusCode.CONFLICT) {
				const emailCtrl = this.frmRegister.get('email');
				this.takenEmail = emailCtrl.value;
				//this.frmRegister.reset();
				captcha.captchaTextCtrl.reset();
				focusErrorInput(this._elementRef);
				return;
			}
			this._clsAlertsSvc.showHttpResponseError(err);
		} finally {
			this.showButtonSpinner = false;
		}
	}
}
