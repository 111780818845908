import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IFormCtrlLockState, LockFormControlService } from './service/lock-form-control.service';

/**
 * In order to use this component and show a lock icon that can be controlled and read from outside
 * tou will need to the next steps:
 * 1. Declare the service at the outer component.
 * 2. Through the service, set the variable 'displayLock' to 'true'
 * 3. Through the service, set the variable 'isAdmin' to your choice by permission of the logged user
 *
 * You can check for example at the 'scan-advanced-profiles'
 */
@Component({
	selector: 'cls-lock-form-control',
	templateUrl: './lock-form-control.component.html',
	styleUrls: ['./lock-form-control.component.scss'],
})
export class LockFormControlComponent implements OnInit {
	@Input() isLocked: boolean;
	@Input() formCtrlNameToLock: string;
	@Input() formCtrlParent: string;
	@Input() toGapIcon: boolean;
	@Input() modifyToolTip: string;
	@Input() isEditable: boolean = true;

	isAdmin: boolean;
	displayLock: boolean;
	lockFormCtrl = new FormControl();

	ADMIN_LOCK_CLOSE_TOOLTIP = $localize`Click to enable instructor editing.`;
	ADMIN_LOCK_OPEN_TOOLTIP = $localize`Click to disable instructor editing.`;
	INSTRUCTOR_LOCK_CLOSE_TOOLTIP = $localize`This setting is locked by your organization policy.`;

	constructor(private _lockFormControleSvc: LockFormControlService) {}

	ngOnInit(): void {
		this.isAdmin = this._lockFormControleSvc.isAdmin;
		this.displayLock = this._lockFormControleSvc.displayLock;
	}

	changeLockMode() {
		if (this.isAdmin && this.isEditable) {
			this.isLocked = !this.isLocked;
			let lockData: IFormCtrlLockState = {
				isLocked: this.isLocked,
				formCtrlName: this.formCtrlNameToLock,
				formCtrlParent: this.formCtrlParent,
			};
			this._lockFormControleSvc._lockedFormCtrl = lockData;
		}
	}
}

@Component({
	selector: 'cls-form-control-lock-item',
	template: '<ng-content></ng-content>',
})
export class LockFormControlItemComponent {}
