import {
	Component,
	ElementRef,
	EventEmitter,
	forwardRef,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	Renderer2,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import {
	AbstractControl,
	ControlValueAccessor,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	ValidationErrors,
	Validator,
} from '@angular/forms';
import { MatTable } from '@angular/material/table';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { orderBy } from 'lodash-es';
import { AnimationOptions } from 'ngx-lottie';
import { filter } from 'rxjs/operators';
import { EPaymentPlanType } from '../../enums/payment-plan-type.enum';
import { ERepositoryPermissions } from '../../enums/repositories.shared.enums';
import { IRepository } from '../../interfaces/repositories.shared.interfaces';
import { IUserBalance } from '../../models/user-balance.models';
import { AuthService } from '../../services/auth.service';
import { PaymentPlanService } from '../../services/payment-plan.service';
import { IDisableKey } from '../scan-profiles/models/disable-settings.model';
import { EScanProfilesForm, EScanPropertiesDatabaseType } from '../scan-profiles/models/scan-profiles.enums';
import { IScanPropertiesInternalSourcesDatabase } from '../scan-profiles/models/scan-profiles.interfaces';
import { DefaultScanPropertiesProfile } from '../scan-profiles/models/scan-profiles.models';

enum EDatabaseTableRowColumns {
	Name = 'name',
	includeUserScans = 'includeUserScans',
	includeOthersScans = 'includeOthersScans',
	Index = 'index',
}
@UntilDestroy()
@Component({
	selector: 'app-scan-databases-table',
	templateUrl: './scan-databases-table.component.html',
	styleUrls: ['./scan-databases-table.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => ScanDatabasesTableComponent),
			multi: true,
		},
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => ScanDatabasesTableComponent),
			multi: true,
		},
	],
})
export class ScanDatabasesTableComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor, Validator {
	@Input() supportResponsive = true;
	@Input() showAddRepositoryButton = true;
	@Input() hideInternalDatabase = false;
	@Input() isRepositoriesLoading = false;
	@Input() repositories: IRepository[] = [];
	@Input() disableInternalSources: IDisableKey;
	@Input() isAdmin: boolean = true;
	@Output() addRepository = new EventEmitter();
	eDatabaseTableRowColumns = EDatabaseTableRowColumns;
	eScanPropertiesDatabaseType = EScanPropertiesDatabaseType;
	eScanPropertiesForm = EScanProfilesForm;
	eRepositoryPermissions = ERepositoryPermissions;
	ePaymentPlanType = EPaymentPlanType;
	columns: string[] = [];
	dataSource: IScanPropertiesInternalSourcesDatabase[] = [];

	@ViewChild('databaseMatTable')
	matTable: MatTable<IScanPropertiesInternalSourcesDatabase>;
	updateValueAccessor: (value: IScanPropertiesInternalSourcesDatabase[]) => void;
	validationsMap = new Map<string, IScanDatabasesTableValidations>();
	userBalance: IUserBalance;
	isGuestUser: boolean;
	isOwner: boolean;
	options: AnimationOptions = {
		renderer: 'svg',
		path: 'assets/lottie-files/crown.json',
		loop: false,
	};

	tooltipScansUpload = $localize`Your scans will automatically be uploaded to the internal database.`;
	tooltipScansNoPermission = $localize`No Permission`;
	tooltipPrivateREpository = $localize`Private Repository: `;

	constructor(
		private _elementRef: ElementRef,
		private _renderer: Renderer2,
		private _authSvc: AuthService,
		private _paymentPlanSvc: PaymentPlanService
	) {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes?.repositories?.currentValue?.length) {
			this._setupRepositories();
		}
	}

	ngOnInit() {
		this.columns = Object.values(EDatabaseTableRowColumns);
		this._paymentPlanSvc.userBalance$
			.pipe(
				untilDestroyed(this),
				filter(b => !!b)
			)
			.subscribe(balance => {
				this.userBalance = balance;
				this.isGuestUser = this._authSvc.isGuestUser;
			});
		if (this.supportResponsive) {
			this._renderer.addClass(this._elementRef.nativeElement, 'support-responsive');
		}
		this.isOwner = this._authSvc.checkUserIfOrganizationOwner();
	}

	private _setupRepositories() {
		this.AddDataToTable(this.repositories.map(this._mapRepositroyToTableRow.bind(this)));
	}

	private _mapRepositroyToTableRow(repo: IRepository) {
		const existsData = this.dataSource.find(tr => tr.id === repo.id);
		return {
			id: repo.id,
			name: repo.name,
			index: existsData ? existsData.index : false,
			includeUserScans: existsData ? existsData.includeUserScans : false,
			includeOthersScans: existsData ? existsData.includeOthersScans : false,
			type: EScanPropertiesDatabaseType.Repository,
			permission: repo.permission,
		} as IScanPropertiesInternalSourcesDatabase;
	}

	AddDataToTable(newData: IScanPropertiesInternalSourcesDatabase[]) {
		if (newData && newData.length) {
			// check if product is education & data include internal database
			if (
				!this.hideInternalDatabase &&
				newData.filter(d => d.type === EScanPropertiesDatabaseType.CopyleaksDB).length === 0
			) {
				const defaultProfile = new DefaultScanPropertiesProfile();
				const internalDb = defaultProfile.internalSources.databases[0];
				internalDb.includeOthersScans = false;
				internalDb.includeUserScans = false;
				internalDb.index = false;
				newData.push(internalDb);
			}
			// update permission
			newData
				.filter(d => d.type === EScanPropertiesDatabaseType.Repository)
				.forEach(d => {
					const repository = this.repositories.find(r => r.id === d.id);
					if (repository) {
						d.permission = repository.permission;
					}
				});

			// merge changes
			const updatedTableData = newData.filter(d => this.dataSource.findIndex(ds => ds.id === d.id) !== -1);
			const newTableData = newData.filter(d => this.dataSource.findIndex(ds => ds.id === d.id) === -1);
			const otherTableData = this.dataSource.filter(
				d => updatedTableData.findIndex(ds => ds.id === d.id) === -1 && newTableData.indexOf(d) === -1
			);
			this.dataSource = orderBy(
				[...newTableData, ...updatedTableData, ...otherTableData],
				['type', 'includeUserScans', 'includeOthersScans', 'index'],
				['asc', 'desc', 'desc', 'desc']
			).filter(
				r => r.permission !== ERepositoryPermissions.None || r.includeOthersScans || r.includeUserScans || r.index
			);
			this.validationsMap.clear();
			this.dataSource.forEach(r => {
				if (r.type !== EScanPropertiesDatabaseType.CopyleaksDB) {
					const isRemoved = this._isRemoved(r);
					const canIndex = this._canIndex(r);
					this.validationsMap.set(r.id, {
						id: r.id,
						name: r.name,
						canIndex,
						isRemoved,
						valid: !isRemoved && (!r.index || (r.index && canIndex)),
					});
				} else {
					this.validationsMap.set(r.id, {
						id: r.id,
						name: r.name,
						canIndex: false,
						isRemoved: false,
						valid: true,
					});
				}
			});
			this._updateValueAccessor();
		}
	}

	private _updateValueAccessor() {
		setTimeout(() => {
			if (this.updateValueAccessor) {
				this.updateValueAccessor(this.dataSource);
			}
		});
	}

	private _isRemoved(element: IScanPropertiesInternalSourcesDatabase) {
		if (!this.isRepositoriesLoading && element.type !== EScanPropertiesDatabaseType.CopyleaksDB) {
			const isRemoved = this.repositories.filter(r => r.id === element.id).length === 0;
			return isRemoved;
		} else {
			return false;
		}
	}

	private _canIndex(element: IScanPropertiesInternalSourcesDatabase) {
		if (!element.permission) {
			return false;
		}
		return element.permission !== ERepositoryPermissions.Viewer;
	}

	checkBoxChange(column: EDatabaseTableRowColumns, row: IScanPropertiesInternalSourcesDatabase) {
		if (row.type === EScanPropertiesDatabaseType.CopyleaksDB) {
			switch (column) {
				case EDatabaseTableRowColumns.includeUserScans:
					if (row.includeUserScans) {
						if (!row.index) {
							row.index = true;
						}
					}
					break;
				case EDatabaseTableRowColumns.includeOthersScans:
					if (row.includeOthersScans) {
						if (!row.index) {
							row.index = true;
						}
					}
					break;
				default:
					break;
			}
			if (!row.includeOthersScans && !row.includeUserScans) {
				row.index = false;
			}
		} else if (
			!row.index &&
			!row.includeOthersScans &&
			!row.includeUserScans &&
			this.validationsMap.get(row.id).isRemoved
		) {
			this.dataSource = [...this.dataSource.filter(r => r.id !== row.id)];
			this.validationsMap.delete(row.id);
		}
		this._updateValueAccessor();
	}

	isSourceLocked(id: string) {
		return this.disableInternalSources
			? this.disableInternalSources[id] != null
				? this.disableInternalSources[id]
				: false
			: false;
	}

	isSourceDisabled(id: string) {
		return !this.isAdmin && this.disableInternalSources
			? this.disableInternalSources[id] != null
				? this.disableInternalSources[id]
				: false
			: false;
	}

	//#region content accessor interfaces
	writeValue(value: IScanPropertiesInternalSourcesDatabase[]): void {
		if (value && value.length) {
			this.AddDataToTable(value);
		}
	}
	registerOnChange(fn: (value: IScanPropertiesInternalSourcesDatabase[]) => void): void {
		this.updateValueAccessor = fn;
	}
	validate(control: AbstractControl): ValidationErrors {
		const errors: IScanDatabasesTableValidations[] = [];
		this.validationsMap.forEach(value => {
			if (!value.valid) {
				errors.push(value);
			}
		});
		return errors.length ? errors : null;
	}
	registerOnTouched(fn: any): void {}
	setDisabledState?(isDisabled: boolean): void {}
	registerOnValidatorChange?(): void {}
	//#endregion

	ngOnDestroy() {}
}

export interface IScanDatabasesTableValidations {
	id: string;
	name: string;
	isRemoved: boolean;
	canIndex: boolean;
	valid: boolean;
}
