import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DisabledAnalyticsViewComponent } from './disabled-analytics-view.component';
import { ClsContainedContentModule } from '@ngx-common-v2/components/cls-contained-content/cls-contained-content.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { ClsButtonModule } from '@ngx-common-v2/components/cls-button/cls-button.module';

@NgModule({
	declarations: [DisabledAnalyticsViewComponent],
	imports: [CommonModule, ClsContainedContentModule, FlexLayoutModule, MatIconModule, ClsButtonModule],
	exports: [DisabledAnalyticsViewComponent],
})
export class DisabledAnalyticsViewModule {}
