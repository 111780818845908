export interface ICardsData {
	plagiarismCases: ICardMetaData;
	crossLanguage: ICardMetaData;
	aiTextCases: ICardMetaData;
	totalResults?: ICardMetaData;
	cheatDetection?: ICardMetaData;
	totalScans?: ICardMetaData;
	avgWritingFeedback?: ICardMetaData;
}
export interface ICardMetaData {
	value: number | string;
	toShow: boolean;
	title?: string;
	showDesignIcon?: boolean;
	isDisabled?: boolean;
}

export enum EGraphToScrollTo {
	Originality,
	SubmittedScans,
	Results,
	CrossLanguage,
	CheatsFound,
	WritingCorrections,
}
