import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateFromNowPipe } from './date-from-now.pipe';

@NgModule({
	imports: [CommonModule],
	declarations: [DateFromNowPipe],
	exports: [DateFromNowPipe],
	providers: [DateFromNowPipe],
})
export class DateFromNowPipeModule {}
