import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatInput } from '@angular/material/input';
import { environment } from '@environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IRepository } from '@ngx-common/interfaces/repositories.shared.interfaces';
import { isEqual } from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, filter, map, pairwise, take } from 'rxjs/operators';
import { RepositoriesService } from 'shared/services/repositories.service';
import { MaxDaysToExpire, MinDaysToExpire, Institute } from '../../services/institutes.service';

@UntilDestroy()
@Component({
	selector: 'institute-card',
	templateUrl: './institute-card.component.html',
	styleUrls: ['./institute-card.component.scss'],
})
export class InstituteCard implements OnInit {
	@Input() institute: Institute;
	@Output() regenerate = new EventEmitter<InstituteCard>();
	@Output() delete = new EventEmitter<Institute>();
	@Output() edit = new EventEmitter<Institute>();

	@ViewChild('form', { static: true }) form: NgForm;
	@ViewChild('secret', { static: true }) secret: MatInput;
	@ViewChild(MatExpansionPanel, { static: true }) advanced: MatExpansionPanel;

	readonly MaxDaysToExpire = MaxDaysToExpire;
	readonly MinDaysToExpire = MinDaysToExpire;
	public editMode = false;
	public showSecret = false;
	public isRepositoriesLoading = true;
	public repositories: IRepository[];
	private fields$: Observable<any>;
	private fieldsSub: Subscription;

	constructor(public dialog: MatDialog, private _repositoriesService: RepositoriesService) { }

	ngOnInit() {
		this._repositoriesService.privateRepositories$
			.pipe(
				untilDestroyed(this),
				filter(r => !!r),
				take(1)
			)
			.subscribe(repositories => {
				this.repositories = repositories;
				this.isRepositoriesLoading = false;
				setTimeout(() => {
					this.toggleEdit();
				}, 1000);
			});

		this.fields$ = this.form.form.valueChanges.pipe(
			untilDestroyed(this),
			debounceTime(1000),
			pairwise(),
			map(a => {
				this.institute.enableDraftSubmission = this.institute.canStudentAccessReport
					? this.institute.enableDraftSubmission
					: false;
				return a;
			})
		);
	}

	toggleEdit() {
		this.editMode = !this.editMode;
		if (this.editMode) {
			// this.advanced.open();
			this.fieldsSub = this.fields$.subscribe(this.handleFieldsChange);
		} else {
			// this.advanced.close();
			this.fieldsSub.unsubscribe();
		}
	}

	handleFieldsChange = ([prev, next]) => {
		// && !isEqual(prev, next)
		if (this.form.valid) {
			this.edit.emit({
				...this.institute,
				repositories: this.institute.repositories.filter(
					repo => repo.includeOthersScans || repo.includeUserScans || repo.index
				),
			} as Institute);
		}
	};

	toggleSecret(show?: boolean) {
		if (show === undefined) {
			this.showSecret = !this.showSecret;
		} else {
			this.showSecret = show;
		}
	}

	addRepository() {
		open(`${environment.idUrl}/repositories`, "_blank");
	}
}
