<div fxLayout="column" class="dialog-container">
	<button
		class="close-btn"
		[disabled]="btnDisable"
		mat-icon-button
		(click)="closeDialog()"
		fxLayout="column"
		fxLayoutAlign="center center">
		<mat-icon class="close-icon">close</mat-icon>
	</button>
	<div class="content-container" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="32px">
		<div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="8px">
			<div class="title-container" fxLayout="column" fxLayoutAlign="center center">
				<h3 i18n class="title">Ready to pick up where you left off?</h3>
			</div>
			<div class="description-container" fxLayout="column" fxLayoutGap="2px">
				<p class="description" i18n>
					Reactivate your account today and get access to additional features and full reporting.
				</p>
			</div>
		</div>
		<div fxLayout="row" fxLayoutAlign="center center" class="action-container">
			<cls-button class="btn" width="100%" fontWeight="700" (click)="onClick()" [disabled]="btnDisable">
				<span *ngIf="!btnDisable" i18n>Reactivate account</span>
				<cls-spinner color="white" *ngIf="btnDisable" width="18px"></cls-spinner>
			</cls-button>
		</div>
	</div>
</div>
