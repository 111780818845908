<div
	fxLayout="row"
	fxLayout.lt-md="column-reverse"
	fxLayoutGap="0px"
	fxLayoutGap.lt-md="12px"
	fxLayoutAlign="space-between center"
	fxLayoutAlign.lt-md="start start"
	class="plan-item-container"
	[ngClass]="{ 'green-border': planItemData.isBordered, 'last-item': planItemData.isLast }">
	<div fxLayout="column" fxLayoutGap="2px">
		<div
			fxLayout="row"
			fxLayout.lt-md="column"
			fxLayoutGap="8px"
			fxLayoutAlign="start center"
			fxLayoutAlign.lt-md="start start"
			class="item-selection-container">
			<div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
				<mat-radio-button [checked]="planItemData.selected">{{ planItemData.productName }}</mat-radio-button>
				<mat-icon [matTooltip]="planItemData.toolTipMsg" i18n-matTooltip *ngIf="!isMobile">error</mat-icon>
			</div>
			<div *ngIf="planItemData.hasDiscount" fxLayoutAlign="center center" class="discount-msg-container">
				<span i18n>SAVE 20%</span>
			</div>
		</div>
		<span class="plan-description">{{ planItemData.description }}</span>
		<span class="current-plan-text" *ngIf="planItemData.isCurrentPlan">Current plan</span>
	</div>
	<div
		class="plan-price-container"
		fxLayout="column"
		fxLayoutGap="4px"
		fxLayoutAlign="end end"
		fxLayoutAlign.lt-md="start start">
		<div class="plan-item-price" fxLayout="row" fxLayoutAlign="start end">
			<span class="price">${{ productPlanPrice }}</span>
			<span class="interval">/mo</span>
		</div>
	</div>
</div>
