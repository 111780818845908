import { IResultItem } from 'ngx-common-v2/components/analytics-components/charts/cls-results-overview/models/cls-results-overview.models';
import { ICardsData } from 'ngx-common-v2/components/analytics-components/templates/cls-results-insights-template/models/cls-card-data.model';

import { IChartDataByAmount } from './analytics-course.models';
import { IAnalyticsTotalCases } from './integration-level.models';
import { IAnalyticsExternalResults } from 'ngx-common-v2/components/analytics-components/models/cls-external-results-table.models';
import { EScanStatus } from 'shared/models';
import { IBarChart, ITwoLineChart } from 'ngx-common-v2/components/analytics-components/models/cls-analytics.models';

export enum ESubmissionsTableSortBy {
	StudentName,
	DocumentName,
	AiScore,
	PlagiarismScore,
	SubmissionDate,
	WritingFeedback,
}

export enum ESubmissionsTableFilterType {
	LateSubmission,
	Cheats,
	All,
}

export enum EActivityDueDateStatus {
	Active,
	Ended,
	Unset,
}

export interface ISubmissionsAnalyticsResponse {
	scanId: string;
	userName?: string;
	documentName: string;
	userId?: string;
	aiScore: number;
	plagiarismScore: number;
	submissionDate: Date;
	isCheatDetected?: boolean;
	isLateSubmission?: boolean;
	scanStatus: EScanStatus;
	errorMessage?: string;
	isReportPageLoading: boolean;
	writingFeedback: number;
}

export interface ResultOverviewResponseData {
	internet: number;
	internalDatabase: number;
	repositories: number;
	batchResults: number;
}

export interface IActivityAnalyticsOverviewResponse {
	submissions: ISubmissionsAnalyticsResponse[];
	externalResults: IAnalyticsExternalResults[];
	contextId: string;
	contextName: string;
	resultsOverview: ResultOverviewResponseData;
	integrationName: string;
	plagiarismOriginality: IChartDataByAmount[];
	aiOriginality: IChartDataByAmount[];
	analyticsTotalsCases: IAnalyticsTotalCases;
	activityName: string;
	dueDate: Date;
	isStatisticsExists: boolean;
	showAnalytics: boolean;
	defaultScanSettingsId?: string;
	hideAuthorAnalytics: boolean;
	writingFeedback: IWritingFeedbackCorrections;
	writingFeedbackFeatureEnabled: boolean;
}

export interface IActivityAnalyticsOverview extends IActivityAnalyticsOverviewResponse {
	chartData: ITwoLineChart;
	tableData: ISubmissionsAnalyticsResponse[];
	resultsOverviewData: IResultItem[];
	cardData: ICardsData;
	isStatisticsExists: boolean;
	writingFeedbackData: IBarChart;
}

export interface IWritingFeedbackCorrections {
	mechanics: number;
	wordChoice: number;
	grammar: number;
	sentenceStructure: number;
}
