import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

/**
 * @Pipe
 * Pipe that highlight a given text occurrence within a given tag content
 */
@Pipe({
	name: 'highlight',
})
export class HighlightSearchedValuePipe implements PipeTransform {
	constructor(private sanitizer: DomSanitizer) {}

	transform(value: any, matchArg: any, color: any = 'yellow'): any {
		if (!matchArg) {
			return value;
		}
		var re = new RegExp(matchArg, 'gi');
		let match = value.match(re);

		// If there's no match, just return the original value.
		if (!match) {
			return value;
		}

		// If there's a match, wrap the value with span with yellow background
		const replacedValue = value.replace(
			re,
			`<span class='highlight' style='background-color: ${color ?? 'yellow'};'>` + match[0] + '</span>'
		);
		return this.sanitizer.bypassSecurityTrustHtml(replacedValue);
	}
}
