<div
	class="page-warper"
	fxLayout="column"
	[fxLayoutGap]="this.dataSrc?.isStatisticsExists ? '16px' : ''"
	[style.backgroundColor]="isLoading ? '' : '#ebf3f5'">
	<app-lms-navbar
		*ngIf="!isLoading"
		(itemClick)="navigateWithBreadCrumb($event)"
		class="layout-settings"
		[breadCrumbs]="breadCrumbs"
		[isLoadingData]="isLoading"
		[isAdmin]="false"
		[showIcon]="false"
		backgroundColor="white"></app-lms-navbar>

	<ng-container *ngIf="isLoading">
		<cls-loading-animation [txtMessage]="loadingMessage"></cls-loading-animation>
	</ng-container>

	<div *ngIf="!isLoading" class="main-conainer" fxLayout="column" fxLayoutGap="16px">
		<ng-container *ngIf="this.dataSrc?.isStatisticsExists; else emptyView">
			<cls-page-banner>
				<cls-page-banner-icon><mat-icon>info_outline</mat-icon></cls-page-banner-icon>
				<cls-page-banner-description i18n>
					Copyleaks LMS Analytics started collecting data on {{ ANALYTICS_COLLECTION_DATE | date }}. Any data before
					this date will not appear.
				</cls-page-banner-description>
			</cls-page-banner>
			<!-- Activity due date and status  -->
			<lms-analytics-activity-status [activityDueDate]="dataSrc?.dueDate"></lms-analytics-activity-status>

			<!-- Cards data -->
			<div class="results-insights-template-container">
				<cls-results-insights-template
					fxFlex
					[isWithScroll]="false"
					[cardsDataSrc]="dataSrc?.cardData"
					[analyticsLevel]="eLevelType.Activity"></cls-results-insights-template>
			</div>

			<!-- chart section -->
			<div fxLayout="row" fxLayoutGap="16px">
				<div
					class="charts-wrapper line-chart-with-top-legend line-charts-bottom-padding analytics-activity-chart"
					#insightsItem>
					<div class="chart-title" fxLayout="row" fxLayoutGap="8px">
						<div i18n class="originality-chart-title">Originality Vs. Submissions</div>
						<mat-icon
							class="info-icon"
							i18n-matTooltip
							matTooltip="AI Content and plagiarism scores compared to submitted documents.">
							info_outline
						</mat-icon>
					</div>
					<ngx-charts-line-chart
						class="chart"
						*ngIf="view"
						[view]="view"
						[xAxis]="true"
						[yAxis]="true"
						[legend]="true"
						legendTitle=""
						[results]="dataSrc?.chartData?.chartData"
						[yAxisLabel]="xAxisLabel"
						[showYAxisLabel]="true"
						[showXAxisLabel]="false"
						[showGridLines]="true"
						[customColors]="lineChartCustomColors"
						[yAxisTicks]="dataSrc?.chartData?.yTicks"
						[yScaleMax]="dataSrc?.chartData?.maxYTick"
						[curve]="linearCurve"
						(window:resize)="onResize($event, insightsItem)"></ngx-charts-line-chart>
				</div>
				<ng-container *ngIf="dataSrc.writingFeedbackFeatureEnabled">
					<div class="charts-wrapper line-charts-bottom-padding" #chartWritingFeedback>
						<div class="chart-title" fxLayout="row" fxLayoutGap="8px">
							<div i18n class="originality-chart-title">Writing Corrections By Category</div>
							<mat-icon
								class="info-icon"
								i18n-matTooltip
								matTooltip="Number of writing correct per category, categories include Sentence Structure, Grammar, Word Choice and Mechanics.">
								info_outline
							</mat-icon>
						</div>
						<ngx-charts-bar-vertical
							[view]="writingFeedbackChartView"
							*ngIf="writingFeedbackChartView"
							[scheme]="{ domain: ['#FF9A02'] }"
							[results]="dataSrc?.writingFeedbackData?.chartData"
							[gradient]="false"
							[xAxis]="true"
							[yAxis]="true"
							[legend]="false"
							[yScaleMin]="0"
							[showXAxisLabel]="true"
							[barPadding]="100"
							[showYAxisLabel]="true"
							[yAxisTicks]="dataSrc?.writingFeedbackData?.yTicks"
							[yScaleMax]="dataSrc?.writingFeedbackData?.maxYTick"
							[yAxisLabel]="correctionText"
							[maxXAxisTickLength]="18"></ngx-charts-bar-vertical>
					</div>
				</ng-container>
				<ng-container *ngIf="!dataSrc.writingFeedbackFeatureEnabled">
					<analytics-empty-state fxFlex>
						<analytics-empty-state-title i18n>Writing Correction</analytics-empty-state-title>
						<analytics-empty-state-image>
							<img src="../../../../assets/analytics/svgs/writingFeedbackEmptyState.svg" alt="Writing Correction" />
						</analytics-empty-state-image>
						<analytics-empty-state-description>
							Keep your writing concise and fresh with tips for improving sentence structure, word choice, overall
							mechanics, spelling, and more.
						</analytics-empty-state-description>
						<analytics-empty-state-bottom-section>
							<ng-container *ngIf="isLoadFromDashboard">
								<div class="empty-state-link-text" i18n>Contact your CSM to enable this feature.</div>
							</ng-container>
							<ng-container *ngIf="!isLoadFromDashboard">
								<div class="empty-state-link-text" i18n>Contact your admin to enable this feature.</div>
							</ng-container>
						</analytics-empty-state-bottom-section>
					</analytics-empty-state>
				</ng-container>
			</div>

			<!-- Submissions table section -->
			<div class="table-container">
				<div class="table-wrapper">
					<div class="table-toolbar-container" fxLayout="row" fxLayoutAlign="space-between center">
						<div class="table-title" i18n>Submissions</div>
						<div class="table-toolbar-actions" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="15px">
							<button
								class="icon-button"
								mat-icon-button
								i18n-matTooltip
								matTooltip="Export to manage data in third-party software."
								(click)="isTableEmpty || hasNoFilterResults ? '' : download()"
								aria-label="Export data.">
								<mat-icon
									[ngClass]="{
										'table-filter-icon': true,
										'disabled-icon': isTableEmpty || hasNoFilterResults
									}"
									fontSet="material-icons-round">
									download
								</mat-icon>
							</button>
							<button
								mat-icon-button
								class="icon-button"
								mat-icon-button
								i18n-matTooltip
								matTooltip="Filter results."
								[matMenuTriggerFor]="menu"
								(click)="(isTableEmpty)"
								aria-label="Filter results.">
								<mat-icon
									[ngClass]="{
										'filter-picked': isFilterPicked,
										'table-filter-icon': true,
										'disabled-icon': isTableEmpty || (hasNoFilterResults && searchStr.length)
									}"
									fontSet="material-icons-round">
									{{ eChosenFilter === eFilterByTableFilter.All ? 'filter_list_off' : 'filter_list' }}
								</mat-icon>
							</button>
							<cls-search-input
								[withLabel]="true"
								[addIconBackground]="false"
								class="search-bar-input"
								#searchInput
								[noBottomPadding]="true"
								[disabled]="disableSearchInput"
								appearance="fill"
								i18n-placeholder
								placeholder="Search Student/Document"
								[msecUntilChange]="0"
								(confirmChange)="fliterViewResults($event)"></cls-search-input>
						</div>
					</div>
					<table
						mat-table
						[dataSource]="tableDataSource"
						[style]="
							!showPaginator ? 'border-bottom-left-radius:10px!important;border-bottom-right-radius:10px!important' : ''
						">
						<!-- Student name  -->
						<ng-container matColumnDef="student_name">
							<th mat-header-cell *matHeaderCellDef style="width: 13%">
								<div fxLayout="row" fxLayoutGap="8px">
									<span i18n>Student</span>
									<button
										mat-icon-button
										class="icon-button"
										(click)="sortDocuments(eSortBy.StudentName)"
										i18n-matTooltip
										matTooltip="Sort"
										aria-label="Sort">
										<mat-icon
											fontSet="material-icons-round"
											class="table-sort-icon"
											[ngClass]="{
												'table-sort-icon-selected-disabled': isTableEmpty || hasNoFilterResults,
												'table-sort-icon-selected-desc': sortColumn === eSortBy.StudentName && !isTalbeAscending,
												'table-sort-icon-selected-asc': sortColumn === eSortBy.StudentName && isTalbeAscending
											}">
											sort
										</mat-icon>
									</button>
								</div>
							</th>
							<td mat-cell *matCellDef="let element" style="padding-left: 10px">
								<div fxLayout="row" fxLayoutAlign="start center">
									<a
										*ngIf="!isTableEmpty"
										mat-icon-button
										class="student-name-container icon-button"
										[class]="element?.userId ? 'student-name-active' : 'student-name-disabled'"
										[matTooltip]="element?.name"
										[routerLink]="
											element?.userId && !dataSrc.hideAuthorAnalytics
												? ['/lms', platformType, 'analytics', integrationId, 'student', element?.userId]
												: null
										"
										[matTooltip]="!element?.userName && platformType == ePlatformType.Moodle ? MOODLE_EULA_TOOLTIP : ''"
										[matTooltip]="
											dataSrc.hideAuthorAnalytics
												? HIDE_AUTHOR_ANALYTICS_TOOLTIP
												: !element?.userId
												? MISSING_USER_DATA_TOOLTIP
												: element?.userName
										"
										aria-label="opens student analytics.">
										<mat-icon *ngIf="!(isTableEmpty || hasNoFilterResults)" class="submission-table-icons">
											person
										</mat-icon>
										<ng-container *ngIf="element?.userName">
											{{ element?.userName }}
										</ng-container>
										<ng-container *ngIf="!element?.userName && !hasNoFilterResults" i18n>Unknown</ng-container>
									</a>
								</div>
							</td>
						</ng-container>
						<!-- Submitted documents -->
						<ng-container matColumnDef="submitted_documents">
							<th mat-header-cell *matHeaderCellDef>
								<div fxLayout="row" fxLayoutGap="8px">
									<span i18n>Submitted documents</span>
									<button
										mat-icon-button
										class="icon-button"
										(click)="sortDocuments(eSortBy.DocumentName)"
										i18n-matTooltip
										matTooltip="Sort"
										aria-label="Sort">
										<mat-icon
											fontSet="material-icons-round"
											class="table-sort-icon"
											[ngClass]="{
												'table-sort-icon-selected-disabled': isTableEmpty || hasNoFilterResults,
												'table-sort-icon-selected-desc': sortColumn === eSortBy.DocumentName && !isTalbeAscending,
												'table-sort-icon-selected-asc': sortColumn === eSortBy.DocumentName && isTalbeAscending
											}">
											sort
										</mat-icon>
									</button>
								</div>
							</th>
							<td mat-cell *matCellDef="let element">
								<div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
									<div fxLayout="row" fxLayoutGap="4px" *ngIf="!isTableEmpty" fxLayoutAlign="start center">
										<div
											(click)="
												element?.scanStatus === eScanStatus.Completed && !element.isReportPageLoading
													? openReportPage(element)
													: ''
											"
											fxLayout="row"
											fxLayoutGap="8px"
											fxLayoutAlign="start center"
											[matTooltip]="element?.documentName"
											class="document-name-icon-container"
											[ngClass]="{
												'document-name-icon-active': element?.scanStatus === eScanStatus.Completed,
												'document-name-icon-disable': element?.scanStatus !== eScanStatus.Completed
											}">
											<ng-container *ngIf="!element?.isReportPageLoading">
												<mat-icon class="submission-table-icons" *ngIf="!(isTableEmpty || hasNoFilterResults)">
													open_in_new
												</mat-icon>
											</ng-container>
											<ng-container *ngIf="element?.isReportPageLoading">
												<cls-spinner size="24px"></cls-spinner>
											</ng-container>
											<button
												mat-button
												class="icon-button text-trim-document"
												[style]="{
													'max-width':
														element?.isLateSubmission && element?.isCheatDetected
															? '130px'
															: element?.isCheatDetected
															? '200px'
															: ''
												}"
												aria-label="opens report page">
												{{ element?.documentName }}
											</button>
										</div>
									</div>
									<div
										fxLayout="row"
										fxLayoutGap="4px"
										fxLayoutAlign="start center"
										*ngIf="element?.isLateSubmission || element?.isCheatDetected">
										<mat-icon
											*ngIf="element?.isLateSubmission"
											class="late-submission-icon material-icons-outlined"
											[matTooltip]="'Late submission: ' + (element?.submissionDate | date: 'MM/dd/yyyy HH:mm')"
											i18n-matTooltip>
											watch_later
										</mat-icon>
										<div fxLayout="row" fxLayoutGap="4px" fxLayoutAlign="start center" *ngIf="element?.isCheatDetected">
											<mat-icon
												class="material-icons-round cheat-detected-icon"
												matTooltip="Cheating detected"
												i18n-matTooltip>
												warning_amber
											</mat-icon>
											<a
												class="learn-more-text"
												href="https://help.copyleaks.com/kb-search-results?term=cheat+detection"
												i18n-matTooltip
												matTooltip="Triggered when an atypical text manipulation is detected, typically used to deceive plagiarism detection."
												i18n
												aria-label="opens cheat detection guide.">
												Learn more
											</a>
										</div>
									</div>
								</div>
							</td>
						</ng-container>
						<!-- Ai Score -->
						<ng-container matColumnDef="ai_content_detected">
							<th mat-header-cell *matHeaderCellDef>
								<div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="center center">
									<span i18n>AI content detected</span>
									<button
										mat-icon-button
										class="icon-button"
										(click)="sortDocuments(eSortBy.AiScore)"
										i18n-matTooltip
										matTooltip="Sort"
										aria-label="Sort">
										<mat-icon
											fontSet="material-icons-round"
											class="table-sort-icon"
											[ngClass]="{
												'table-sort-icon-selected-disabled': isTableEmpty || hasNoFilterResults,
												'table-sort-icon-selected-desc': sortColumn === eSortBy.AiScore && !isTalbeAscending,
												'table-sort-icon-selected-asc': sortColumn === eSortBy.AiScore && isTalbeAscending
											}">
											sort
										</mat-icon>
									</button>
								</div>
							</th>
							<td mat-cell *matCellDef="let element">
								<cls-score-level
									[isAPIPropertyEnabled]="
										element?.aiScore != null ||
										element?.scanStatus === eScanStatus?.InProgress ||
										hasNoFilterResults ||
										isTableEmpty
									"
									*ngIf="element?.scanStatus != eScanStatus?.Error"
									[isLoading]="element?.scanStatus === eScanStatus?.InProgress"
									[isCheatDetected]="element?.isCheatDetected"
									[score]="element?.aiScore"></cls-score-level>
							</td>
						</ng-container>

						<!-- Plagiarism -->
						<ng-container matColumnDef="plagiarism_score">
							<th mat-header-cell *matHeaderCellDef>
								<div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="center center">
									<span i18n>Plagiarism score</span>
									<button
										mat-icon-button
										class="icon-button"
										(click)="sortDocuments(eSortBy.PlagiarismScore)"
										i18n-matTooltip
										matTooltip="Sort"
										aria-label="Sort">
										<mat-icon
											fontSet="material-icons-round"
											class="table-sort-icon"
											[ngClass]="{
												'table-sort-icon-selected-disabled': isTableEmpty || hasNoFilterResults,
												'table-sort-icon-selected-desc': sortColumn === eSortBy.PlagiarismScore && !isTalbeAscending,
												'table-sort-icon-selected-asc': sortColumn === eSortBy.PlagiarismScore && isTalbeAscending
											}">
											sort
										</mat-icon>
									</button>
								</div>
							</th>
							<td mat-cell *matCellDef="let element">
								<cls-score-level
									[isAPIPropertyEnabled]="
										element?.plagiarismScore != null ||
										element?.scanStatus === eScanStatus?.InProgress ||
										hasNoFilterResults ||
										isTableEmpty
									"
									*ngIf="element?.scanStatus != eScanStatus?.Error"
									[isLoading]="element?.scanStatus === eScanStatus?.InProgress"
									[isCheatDetected]="element?.isCheatDetected"
									[score]="element?.plagiarismScore"></cls-score-level>
							</td>
						</ng-container>

						<!-- >Writing corrections -->
						<ng-container matColumnDef="writingFeedback_score">
							<th mat-header-cell *matHeaderCellDef>
								<div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="center center">
									<span i18n>Writing corrections</span>
									<button
										*ngIf="dataSrc.writingFeedbackFeatureEnabled"
										mat-icon-button
										class="icon-button"
										(click)="sortDocuments(eSortBy.WritingFeedback)"
										i18n-matTooltip
										matTooltip="Sort"
										aria-label="Sort">
										<mat-icon
											fontSet="material-icons-round"
											class="table-sort-icon"
											[ngClass]="{
												'table-sort-icon-selected-disabled': isTableEmpty || hasNoFilterResults,
												'table-sort-icon-selected-desc': sortColumn === eSortBy.WritingFeedback && !isTalbeAscending,
												'table-sort-icon-selected-asc': sortColumn === eSortBy.WritingFeedback && isTalbeAscending
											}">
											sort
										</mat-icon>
									</button>
								</div>
							</th>
							<td mat-cell *matCellDef="let element">
								<div
									class="td-writingFeedback-score"
									*ngIf="element?.scanStatus !== eScanStatus?.Error"
									[ngClass]="{ 'writing-feedback-score-disabled': !dataSrc.writingFeedbackFeatureEnabled }">
									<lms-writing-feedback-score
										[score]="element?.writingFeedback"
										[isLoading]="
											element?.scanStatus == eScanStatus.PriceChecked || element?.scanStatus === eScanStatus?.InProgress
										"
										[isAPIPropertyEnabled]="
											element?.writingFeedback != null ||
											element?.scanStatus === eScanStatus?.InProgress ||
											hasNoFilterResults ||
											isTableEmpty
										"
										[isFeatureEnabled]="dataSrc.writingFeedbackFeatureEnabled"></lms-writing-feedback-score>
								</div>
								<div *ngIf="element?.scanStatus === eScanStatus?.Error" fxLayout="row" fxLayoutAlign="center center">
									<mat-chip class="mat-chip-error error-chip" [matTooltip]="element.errorMessage" role="status" i18n>
										FAILD SCAN
									</mat-chip>
								</div>
							</td>
						</ng-container>

						<!-- Due Date -->
						<ng-container matColumnDef="submission_date">
							<th mat-header-cell *matHeaderCellDef style="padding: 0">
								<div fxLayout="row" fxLayoutGap="8px">
									<span i18n>Submission date</span>
									<button
										mat-icon-button
										class="icon-button"
										(click)="isTableEmpty ? '' : sortDocuments(eSortBy.SubmissionDate)"
										i18n-matTooltip
										matTooltip="Sort"
										aria-label="Sort">
										<mat-icon
											fontSet="material-icons-round"
											class="table-sort-icon"
											[ngClass]="{
												'table-sort-icon-selected-disabled': isTableEmpty || hasNoFilterResults,
												'table-sort-icon-selected-desc': sortColumn === eSortBy.SubmissionDate && !isTalbeAscending,
												'table-sort-icon-selected-asc': sortColumn === eSortBy.SubmissionDate && isTalbeAscending
											}">
											sort
										</mat-icon>
									</button>
								</div>
							</th>
							<td mat-cell *matCellDef="let element" style="padding: 0">
								<div style="text-align: start">
									{{ element?.submissionDate | date: 'medium' }}
								</div>
							</td>
						</ng-container>

						<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
						<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
					</table>

					<ng-container *ngIf="isTableEmpty || hasNoFilterResults">
						<cls-contained-content class="empty-message" [isTextCentered]="true">
							<cls-contained-title>
								<div *ngIf="isTableEmpty" i18n>No Submissions Found</div>
								<div *ngIf="hasNoFilterResults && searchStr.length" i18n>No Search Results Found.</div>
								<div
									*ngIf="
										hasNoFilterResults &&
										(eChosenFilter === eFilterByTableFilter.LateSubmission ||
											eChosenFilter === eFilterByTableFilter.Cheats) &&
										!searchStr.length
									"
									i18n>
									No Fliter Results Found.
								</div>
							</cls-contained-title>
							<cls-contained-description>
								<div fxLayout="column" fxLayoutGap="32px" fxLayoutAlign="center center">
									<div *ngIf="isTableEmpty" i18n>Submit A Document To See Data.</div>
									<div *ngIf="hasNoFilterResults && searchStr.length" i18n>Can't Find Submissions.</div>
									<div
										*ngIf="
											hasNoFilterResults && eChosenFilter === eFilterByTableFilter.LateSubmission && !searchStr.length
										"
										i18n>
										No Late Submissions Found.
									</div>
									<div
										*ngIf="hasNoFilterResults && eChosenFilter === eFilterByTableFilter.Cheats && !searchStr.length"
										i18n>
										No Cheats Found.
									</div>
								</div>
							</cls-contained-description>
						</cls-contained-content>
					</ng-container>
				</div>

				<cls-paginator
					[pageIndex]="paginateState.pageIndex"
					(page)="movePaginator($event)"
					[pageSize]="paginateState.pageSize"
					[canLoadMoreData]="canLoadMore"
					[pageSizeOptions]="[10, 50, 100]"
					[showPaginator]="showPaginator"
					[receivedDataLength]="tableViewData?.length"></cls-paginator>

				<div class="sum-submissions-text" *ngIf="showPaginator" i18n>
					Total: {{ tableViewData?.length }} submissions
				</div>
			</div>
			<!-- Results Overview -->
			<ng-container *ngIf="!isLoading">
				<cls-results-overview fxFlex [resultItems]="dataSrc?.resultsOverviewData"></cls-results-overview>
			</ng-container>

			<!-- Most common -->
			<ng-container *ngIf="!isLoading">
				<cls-common-results-template
					class="results-template"
					[dataSrc]="dataSrc?.externalResults"></cls-common-results-template>
			</ng-container>
		</ng-container>
	</div>
</div>

<mat-menu #menu="matMenu" xPosition="before">
	<!-- Delete Button -->
	<button mat-menu-item (click)="fliterViewResults(eFilterByTableFilter.LateSubmission)">
		<div class="menu-item">
			<span
				i18n
				[ngClass]="{
					'menue-item-text': true,
					'active-item': eChosenFilter === eFilterByTableFilter.LateSubmission
				}">
				Late submissions ({{ lateSubmissionsCounter }})
			</span>
		</div>
	</button>
	<button mat-menu-item (click)="fliterViewResults(eFilterByTableFilter.Cheats)">
		<div class="menu-item">
			<span
				i18n
				class="menue-item-text"
				[ngClass]="{
					'menue-item-text': true,
					'active-item': eChosenFilter === eFilterByTableFilter.Cheats
				}">
				Cheats ({{ cheatsCounter }})
			</span>
		</div>
	</button>
	<button mat-menu-item (click)="fliterViewResults(eFilterByTableFilter.All)">
		<div class="menu-item">
			<span
				i18n
				[ngClass]="{
					'menue-item-text': true,
					'active-item': eChosenFilter === eFilterByTableFilter.All
				}">
				Show all {{ dataSrc?.tableData?.length }}
			</span>
		</div>
	</button>
</mat-menu>

<ng-template #emptyView>
	<cls-empty-state-template style="padding-bottom: 16px">
		<cls-empty-state-secondary-text i18n *ngIf="dataSrc.showAnalytics">
			Data is Getting Collected...
		</cls-empty-state-secondary-text>
		<cls-empty-state-primary-text i18n *ngIf="dataSrc.showAnalytics">
			Analytics Coming Soon
		</cls-empty-state-primary-text>
		<cls-empty-state-content>
			<disabled-analytics-view
				*ngIf="!dataSrc.showAnalytics"
				[isAdmin]="isLoadFromDashboard"
				[integrationId]="integrationId"
				[scanSettingsId]="dataSrc.defaultScanSettingsId"
				[platformType]="platformType"></disabled-analytics-view>
		</cls-empty-state-content>
	</cls-empty-state-template>
</ng-template>
