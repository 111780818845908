export enum ERepositoryVisibility {
	None = 0,
	Visible,
}

export enum ERepositoryHighAvailability {
	None = 0,
	Activated,
}

export enum ERepositoryStatus {
	Running = 0,
	PendingForDeletion = 1,
	Error = 2,
	Updating = 3,
	PaymentRequired = 4,
}

export enum ERepositoryMaskingPolicy {
	NoMask = 0,
	OtherUserFiles,
	AllFiles,
}

export enum ERepositoryPermissions {
	None = 0,
	Viewer,
	Editor,
	Manager,
	Owner,
}
