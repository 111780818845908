<div class="errors-section" *ngIf="!isRepositoriesValid">
	<cls-callout type="error" [showIcon]="false">
		<cls-callout-title i18n="@@PERMISSIONS_ACCESS_HAS_CHANGED">Lack of Permissions</cls-callout-title>
		<ng-container *ngIf="isReadOnly">
			<p style="margin: 0 0 8px" i18n>
				Bad profile configuration. Please contact your administrator.
				<br />
			</p>
		</ng-container>
		<ng-container *ngFor="let error of errors">
			<ng-container *ngIf="error.isRemoved">
				&#x2718;
				<ng-container i18n="@@REPOSITORY_HAS_BEEN_REMOVED">
					<b>{{ error.name }}</b>
					has been removed.
				</ng-container>
			</ng-container>
			<ng-container *ngIf="!error.isRemoved && !error.canIndex">
				&#x2718;
				<ng-container i18n="@@NO_PERMISSION_TO_INDEX_FILES">
					You do not have permission to index files for Repository
					<b>{{ error.name }}</b>
					.
				</ng-container>
			</ng-container>
			<br />
		</ng-container>
		<div padding-top *ngIf="!isReadOnly">
			<cls-spinner-button
				color="primary"
				type="button"
				spinnerDiameter="30"
				[showSpinner]="showRepoFixSpinner"
				(click)="fixRepositoriesValidation()"
				i18n="@@FIX">
				Fix
			</cls-spinner-button>
		</div>
	</cls-callout>
</div>
