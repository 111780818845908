import { Injectable } from '@angular/core';
import * as confetti from 'canvas-confetti';
@Injectable()
export class ConfettiService {
	create() {
		try {
			confetti.create(null, { resize: true })({
				particleCount: 300,
				spread: 360,
				origin: {
					y: 0.2,
				},
			});
		} catch (error) {
			console.error(error);
		}
	}
}
