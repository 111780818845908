<div
	*ngIf="(isLoading || showPaymentSpinner) && !isCheckOutPage"
	class="loading-container"
	fxLayout="column"
	fxLayoutGap="16px">
	<ngx-skeleton-loader class="table-skeleton-loader summary"></ngx-skeleton-loader>
	<ngx-skeleton-loader class="table-skeleton-loader checkout-btn"></ngx-skeleton-loader>
</div>
<!-- isLoading || isLoadingOffer -->

<!-- !isLoading && !isLoadingOffer -->
<div
	*ngIf="(!isLoading && !showPaymentSpinner) || isCheckOutPage"
	class="payment-summary-container"
	fxLayout="column"
	fxLayoutGap="32px">
	<div class="payment-details" fxLayout="column" fxLayoutGap="8px">
		<div class="payment-section" fxLayout="column" fxLayoutGap="16px" fxLayoutAlign.lt-md="start start">
			<ng-container *ngIf="loadingCheckOutPage; else showSelectedPlan">
				<div class="loader-conatiner-full-width" fxLayout="column" fxLayoutGap="4px">
					<div class="loader-conatiner-full-width" fxLayout="row" fxLayoutAlign="space-between start">
						<ngx-skeleton-loader class="skeleton-loader loader-plan-name"></ngx-skeleton-loader>
						<ngx-skeleton-loader class="skeleton-loader loader-credits-number"></ngx-skeleton-loader>
					</div>
					<ngx-skeleton-loader class="skeleton-loader loader-change-to-annually"></ngx-skeleton-loader>
				</div>
			</ng-container>

			<ng-template #showSelectedPlan>
				<div class="selected-plan-container">
					<h3 class="selected-plan-text" i18n>Selected Plan</h3>
				</div>
				<div class="plan-section" fxLayout="column" fxLayoutGap="4px">
					<div class="plan-info" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="16px">
						<div class="plan-description">
							<span class="plan-name">
								{{ productName }},
								<ng-container i18n>up to</ng-container>
							</span>
							<span class="credits-number">
								{{ selectedPlan?.credits + ' credits' }}
							</span>
						</div>
						<div class="plan-price" fxLayout="row" fxLayoutAlign="start end">
							<span class="price">
								${{ (isMonthlyPlan() ? selectedPlan?.price : monthlyPlan?.price) | number: '1.2-2' }}
							</span>
							<span class="per-month-text" i18n>/mo</span>
						</div>
					</div>
					<div *ngIf="isMonthlyPlan()" fxLayout="row" fxLayoutGap="10px" class="plan-details">
						<span class="plan-details-text" i18n>Auto renewing & billed monthly</span>
						<a (click)="changeToYearlyPlan()">Change to Annual</a>
					</div>
					<div *ngIf="!isMonthlyPlan()" class="plan-details" fxLayoutGap="10px">
						<span class="plan-details-text" i18n>Auto renewing & billed annually</span>
						<a *ngIf="changedFromMonthly" (click)="changeToMonthlyPlan()">Change to Monthly</a>
					</div>
				</div>
			</ng-template>

			<div class="section-divider" [class.section-divider-loading]="loadingCheckOutPage"></div>

			<!-- Annual Plan Discount -->
			<ng-container *ngIf="loadingCheckOutPage; else annualPlanDiscount">
				<div class="loader-conatiner-full-width" fxLayout="row" fxLayoutAlign="space-between start">
					<ngx-skeleton-loader class="skeleton-loader" style="width: 163px"></ngx-skeleton-loader>
					<ngx-skeleton-loader class="skeleton-loader" style="width: 68px"></ngx-skeleton-loader>
				</div>
			</ng-container>
			<ng-template #annualPlanDiscount>
				<div
					class="yearly-discount-section"
					fxLayout="row"
					fxLayoutAlign="space-between center"
					*ngIf="yearlyPayment && monthlyPlan">
					<div i18n title="Undo change plan" class="annual-discount">Annual Plan Discount</div>
					<div class="yearly-discount-price-container" fxLayout="row" fxLayoutAlign="start end">
						<span class="annual-discount">
							-${{ (monthlyPlan?.price * 12 - yearlyPlan?.price) / 12 | number: '1.2-2' }}
						</span>
						<span class="per-month-text annual-discount" i18n>/mo</span>
					</div>
				</div>
			</ng-template>

			<div
				class="section-divider"
				[class.section-divider-loading]="loadingCheckOutPage"
				*ngIf="(yearlyPayment && monthlyPlan) || loadingCheckOutPage"></div>

			<!-- Sales Tax -->
			<ng-container *ngIf="loadingCheckOutPage; else showSalesTax">
				<div class="loader-conatiner-full-width" fxLayout="row" fxLayoutAlign="space-between start">
					<ngx-skeleton-loader class="skeleton-loader" style="width: 163px"></ngx-skeleton-loader>
					<ngx-skeleton-loader class="skeleton-loader" style="width: 68px"></ngx-skeleton-loader>
				</div>
			</ng-container>
			<ng-template #showSalesTax>
				<div class="sales-tax-section" fxLayout="row" fxLayoutAlign="space-between center">
					<span class="sales-tax-text" i18n>Sales Tax</span>
					<span class="sales-tax-price">
						<ng-container *ngIf="!showPaymentSpinner && priceOffer">${{ vatPrice }}</ng-container>
						<ng-container *ngIf="showPaymentSpinner && priceOffer">
							<cls-spinner width="20px"></cls-spinner>
						</ng-container>
						<ng-container *ngIf="!priceOffer">-</ng-container>
					</span>
				</div>
			</ng-template>

			<div class="section-divider" [class.section-divider-loading]="loadingCheckOutPage"></div>
		</div>
		<ng-container *ngIf="loadingCheckOutPage; else showTotalPrice">
			<div fxLayout="column" fxLayoutGap="8px">
				<ngx-skeleton-loader class="skeleton-loader loder-coupon"></ngx-skeleton-loader>
				<div fxLayout="row" fxLayoutAlign="space-between center">
					<ngx-skeleton-loader class="skeleton-loader" style="width: 59px"></ngx-skeleton-loader>
					<div fxLayout="column" fxLayoutGap="2px">
						<ngx-skeleton-loader class="skeleton-loader loader-total-price"></ngx-skeleton-loader>
						<ngx-skeleton-loader class="skeleton-loader" style="width: 59px"></ngx-skeleton-loader>
					</div>
				</div>
			</div>
		</ng-container>
		<ng-template #showTotalPrice>
			<div fxLayout="column" fxLayoutGap="8px">
				<!-- Coupon -->
				<div *ngIf="priceOffer" class="coupon-code-section" fxLayout="column" fxLayoutGap="8px">
					<div
						class="coupon-code-input-container"
						fxLayout="column"
						fxLayoutGap="2px"
						*ngIf="showPromoCode && priceOffer?.couponDiscount === 0 && showPromoCodeInputSection">
						<div fxLayout="row" fxLayoutAlign="start end" fxLayoutGap="2px">
							<div class="promo-code-input">
								<input
									matInput
									[ngClass]="{ 'input-error': promoCodeError }"
									i18n-placeholder
									placeholder="Enter Coupon Code"
									#promoCodeInput
									[formControl]="promoCodeFormCtrl"
									type="text"
									autocomplete="off"
									(keydown)="onPromoCodeInputKeyDown($event)"
									(blur)="hidePromoCodeIfEmpty()" />
							</div>
							<div class="coupon-apply-btn">
								<button
									mat-raised-button
									class="btn-apply"
									fxLayoutAlign="center center"
									[disabled]="!promoCodeFormCtrl.valid"
									(click)="applyPromoCode()">
									<span class="apply-btn" *ngIf="!showApplyPromoCodeSpinner" i18n>Apply</span>
									<div *ngIf="showApplyPromoCodeSpinner">
										<cls-spinner width="18px"></cls-spinner>
									</div>
								</button>
							</div>
						</div>
						<span class="promo-code-input-error" *ngIf="promoCodeError">
							{{ promoCodeError }}
						</span>
					</div>

					<div
						class="promocode"
						fxLayoutAlign="space-between center"
						*ngIf="showPromoCode && priceOffer?.couponDiscount !== null && priceOffer?.couponDiscount !== 0">
						<span class="desc" i18n>
							{{ calculateDiscountToPercentage() }}% OFF Coupon Applied
							<sup>
								<span class="btn-discount-cancel" clickable (click)="clearPromoCode()" title="Cancel Coupon">X</span>
							</sup>
						</span>
						<span class="amount">-${{ math.abs(priceOffer?.couponDiscount) | floatDecimal: '1.2-2' }}</span>
					</div>

					<div
						class="promo-code-link-section"
						(click)="showPromoCodeInput()"
						*ngIf="priceOffer && showPromoCode === false && showPromoCodeInputSection && !hideAddNewCouponOption">
						<span i18n>I have a coupon</span>
					</div>
				</div>
				<!-- Total price-->
				<div class="price-total-section" fxLayout="row" fxLayoutAlign="space-between center">
					<h4 class="price-total-text" i18n>Total</h4>
					<div fxLayout="column" fxLayoutAlign="center end">
						<span class="price-to-pay">
							<ng-container *ngIf="!showPaymentSpinner && priceOffer">
								${{ priceOffer?.totalPrice | number: '1.2-2' }}
							</ng-container>
							<ng-container *ngIf="showPaymentSpinner && priceOffer">
								<cls-spinner width="20px"></cls-spinner>
							</ng-container>
						</span>
						<ng-container *ngIf="!priceOffer">-</ng-container>
						<div
							*ngIf="yearlyPayment && !showPaymentSpinner && priceOffer"
							class="price-before-discount"
							fxLayout="row"
							fxLayoutGap="4px">
							<span class="text" i18n>Instead of</span>
							<span class="price">${{ monthlyPlan?.price * 12 | number: '1.2-2' }}</span>
						</div>
						<div
							*ngIf="
								priceOffer &&
								!showPaymentSpinner &&
								!yearlyPayment &&
								showPromoCode &&
								priceOffer?.couponDiscount !== null &&
								priceOffer?.couponDiscount !== 0
							"
							class="price-before-discount"
							fxLayout="row"
							fxLayoutGap="4px">
							<span class="text" i18n>Instead of</span>
							<span class="price">${{ monthlyPlan?.price }}</span>
						</div>
					</div>
				</div>
			</div>
		</ng-template>
	</div>
	<!-- Confirm Button-->
	<div
		class="confirm-btn-container"
		[class.confirm-btn-container-loader]="loadingCheckOutPage"
		fxLayoutAlign="center start">
		<cls-confirm-payment-btn
			*ngIf="!showPaymentSpinner || loadingCheckOutPage"
			[buttonId]="isCheckOutPage ? 'confirm-payment-btn' : ''"
			[disabledButton]="loadingCheckOutPage"
			[showConfirmPaymentBtnSpinner]="showConfirmPaymentBtnSpinner"
			[totalPrice]="priceOffer?.totalPrice"
			[newDesign]="newDesign"
			[billingStatus]="billingStatus"
			[currentPaymentStep]="currentPaymentStep"
			(confirmPayment)="
				loadingCheckOutPage ? '$event.preventDefault()' : confirmPaymentBtnClickedFunc()
			"></cls-confirm-payment-btn>
	</div>
</div>
