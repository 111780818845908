<div class="disabled-analytics-container" fxLayout="row" fxLayoutAlign="space-between center">
	<div fxLayout="column" fxLayoutGap="16px">
		<div fxLayout="column" fxLayoutGap="8px">
			<ng-container *ngIf="isAdmin">
				<div i18n class="analytics-active">Activate Analytics</div>
				<div i18n class="analytics-desc">Learn more about data trends in student work.</div>
			</ng-container>
			<ng-container *ngIf="!isAdmin">
				<div i18n class="analytics-active">LMS Analytics</div>
				<div i18n class="analytics-desc">Learn more about data trends in student work.</div>
			</ng-container>
		</div>
		<div fxLayout="column" fxLayoutGap="16px">
			<div fxLayout="row" fxLayoutGap="12px" *ngIf="isAdmin">
				<ng-container [ngTemplateOutlet]="check"></ng-container>
				<div i18n>Full integration level analytics</div>
			</div>
			<div fxLayout="row" fxLayoutGap="12px">
				<ng-container [ngTemplateOutlet]="check"></ng-container>
				<div i18n>Course level analytics</div>
			</div>
			<div fxLayout="row" fxLayoutGap="12px">
				<ng-container [ngTemplateOutlet]="check"></ng-container>
				<div i18n>Assignment level analytics</div>
			</div>
			<div fxLayout="row" fxLayoutGap="12px">
				<ng-container [ngTemplateOutlet]="check"></ng-container>
				<div i18n>Student level analytics (optional)</div>
			</div>
		</div>
		<div *ngIf="!isAdmin" i18n style="font-weight: 700">Contact your Admin to learn more.</div>
	</div>
	<div>
		<img alt="analytics-image" src="assets\images\analytics-report.svg" alt="" />
	</div>
	<div *ngIf="isAdmin" class="btn-cont">
		<a [href]="link" class="link-btn" aria-label="Click to open settings page." i18n>Go to Settings</a>
	</div>
</div>

<ng-template #check>
	<div class="checker-round">
		<mat-icon>done</mat-icon>
	</div>
</ng-template>
