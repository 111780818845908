<section
	class="dialog-close-icon"
	*ngIf="!disableClose && data?.type !== eIdentityDialogType.DeleteTeam"
	mat-dialog-close>
	<button mat-icon-button aria-label="close">
		<mat-icon>close</mat-icon>
	</button>
</section>
<!--  -->
<div *ngIf="data?.help" style="width: 100%" fxLayoutAlign="start start" fxLayoutAlign.lt-sm="center start">
	<a
		class="help-link-container"
		target="_blank"
		[href]="data?.help?.link"
		fxLayout="row"
		fxLayoutAlign="start center"
		fxLayoutAlign.lt-sm="center center"
		fxLayoutGap="10px"
		(mouseover)="data?.help?.titleHoverText ? (changeText = true) : (changeText = false)"
		(mouseout)="changeText = false">
		<mat-icon>info</mat-icon>
		<span *ngIf="!changeText">{{ data?.help?.title }}</span>
		<span *ngIf="changeText">{{ data?.help?.titleHoverText }}</span>
	</a>
</div>
<!--  -->
<ng-container *ngIf="data?.type === eIdentityDialogType.DeleteTeam">
	<app-remove-avatar [entityAvatar]="avatar"></app-remove-avatar>
</ng-container>

<ng-container *ngIf="data?.type !== eIdentityDialogType.DeleteTeam">
	<div *ngIf="data?.title" class="{{ data?.titleAlignCenter ? 'title-align-center' : '' }}">
		<h2
			mat-dialog-title
			[style.text-align]="data?.titleAlignCenter ? 'center' : 'start'"
			[style.color]="data?.titleStyle?.color ? data?.titleStyle?.color : '#040f21'"
			[style.font-size]="data?.titleStyle?.fontSize ? data?.titleStyle?.fontSize : '25px'"
			[style.margin]="data?.titleStyle?.margin ? data?.titleStyle?.margin : '0px 0px 20px'">
			{{ data?.title }}
		</h2>
	</div>
</ng-container>
<!--  -->
<div *ngIf="data?.dialogImg" class="img-container">
	<img role="presentation" [src]="data?.dialogImg" />
</div>
<!--  -->
<mat-dialog-content class="mat-typography">
	<cls-callout *ngIf="captchaErrorText" type="info" style="margin-top: 0 !important">
		{{ captchaErrorText }}
	</cls-callout>
	<!--  -->
	<p *ngIf="data?.description">
		<strong *ngIf="data?.strongDescription" [innerHTML]="data?.strongDescription"></strong>
		<span *ngIf="data?.descriptionIntro" [innerHTML]="data?.descriptionIntro"></span>
		<ng-container *ngIf="data?.strongDescription || data?.descriptionIntro"><br /></ng-container>
		<span [innerHTML]="data?.description"></span>
	</p>
	<!--  -->
	<cls-callout *ngIf="data.callOutDescription" type="info" style="margin-top: 0 !important">
		{{ data.callOutDescription }}
	</cls-callout>
	<!--  -->
	<ng-container #vcr></ng-container>
	<!--  -->
	<cls-copyleaks-captcha
		class="cls-captcha-section"
		#captcha
		[style.display]="data.showCaptcha ? 'flex' : 'none'"
		[formControl]="captchaCtrl"
		[floatLabel]="'always'"></cls-copyleaks-captcha>

	<br *ngIf="data.showCaptcha" />
</mat-dialog-content>
<!--  -->
<mat-dialog-actions
	*ngIf="!data?.hideSubmitButton || !data?.hideCancelButton"
	[style.justify-content]="data?.hideCancelButton ? 'center' : ''">
	<ng-container *ngIf="!showSubmitSpinner">
		<button
			mat-button
			*ngIf="!data?.hideSubmitButton"
			[style.background-color]="data?.btnSubmitBGColor ? data?.btnSubmitBGColor : '#17a1ff'"
			[ngStyle]="data?.btnSubmitBorder ? { border: '3px ' + data?.btnSubmitColor + ' solid' } : ''"
			[style.color]="data?.btnSubmitColor ? data?.btnSubmitColor : 'white'"
			cdkFocusInitial
			(click)="submit(captcha)">
			<!--  -->
			<span *ngIf="data?.btnSubmitText">{{ data?.btnSubmitText }}</span>
			<!--  -->
			<span *ngIf="!data?.btnSubmitText && data.type === eIdentityDialogType.Default" i18n="@@ID_DIALOG_SAVE">
				Save
			</span>
			<!--  -->
			<span *ngIf="!data?.btnSubmitText && data.type === eIdentityDialogType.Confirmation" i18n="@@ID_DIALOG_CONF_YES">
				Yes
			</span>
			<span *ngIf="!data?.btnSubmitText && data.type === eIdentityDialogType.DeleteTeam" i18n="@@ID_DIALOG_CONF_OK">
				OK
			</span>
			<!--  -->
		</button>
		<!--  -->
		<button
			mat-button
			mat-dialog-close
			*ngIf="!data?.hideCancelButton"
			[style.background-color]="data?.btnCancelBGColor ? data?.btnCancelBGColor : '#ACB7D3'"
			[style.color]="data?.btnCancelColor ? data?.btnCancelColor : 'white'"
			[ngStyle]="data.type !== eIdentityDialogType.DeleteTeam ? { 'margin-left': '60px' } : ''"
			[ngStyle]="data.type === eIdentityDialogType.DeleteTeam ? { 'max-width': '60%' } : ''"
			(click)="conditinalNavigate()">
			<!--  -->
			<span *ngIf="data?.btnCancelText">{{ data?.btnCancelText }}</span>
			<!--  -->
			<span *ngIf="!data?.btnCancelText && data.type === eIdentityDialogType.Default" i18n="@@ID_DIALOG_CANCEL">
				Cancel
			</span>
			<!--  -->
			<span *ngIf="!data?.btnCancelText && data.type === eIdentityDialogType.Confirmation" i18n="@@ID_DIALOG_CONF_NO">
				No
			</span>
			<span *ngIf="!data?.btnCancelText && data.type === eIdentityDialogType.DeleteTeam" i18n="@@ID_DIALOG_VISIT_TEAM">
				Visit Team Members
			</span>
			<!--  -->
		</button>
	</ng-container>
	<!--  -->
	<ng-container *ngIf="showSubmitSpinner">
		<div full-width fxLayout="row" fxLayoutAlign="center center">
			<cls-spinner width="50px"></cls-spinner>
		</div>
	</ng-container>
</mat-dialog-actions>
