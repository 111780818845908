import {
	IAuthorTemplateOverview,
	IChartDataByMonth,
} from 'ngx-common-v2/components/analytics-components/models/cls-analytics.models';
import { ISubmissionsAnalyticsResponse } from './activity-analytics.models';
import {
	IAnalyticsTotalCases,
	IAssignmentsResponseTableData,
	IBaseSortAndPaginateTable,
} from './integration-level.models';
import { IAnalyticsExternalResults } from 'ngx-common-v2/components/analytics-components/models/cls-external-results-table.models';

export interface IAuthorAnalyticsData {
	originalityPlagiarism: IChartDataByMonth[];
	originalityAI: IChartDataByMonth[];
	analyticsTotalsCases: IAnalyticsTotalCases;
	externalResults: IAnalyticsExternalResults[];
	writingFeedback: IChartDataByMonth[];
	writingFeedbackFeatureEnabled: boolean;
}

export enum ESortByStudentSubmissionsTable {
	NoSort = 0,
	SubmissionDate,
}

export interface ISortAndPaginateStudentSubmissionsTable extends IBaseSortAndPaginateTable {
	sortBy: ESortByStudentSubmissionsTable;
}

export interface IStudentSubmissionsAnalyticsTableData extends ISubmissionsAnalyticsResponse {
	contextId: string;
	contextName: string;
	activityId: string;
	activityName: string;
}

export interface IStudentSubmissionsTableDataResponse extends IAssignmentsResponseTableData {
	submissions: IStudentSubmissionsAnalyticsTableData[];
}

export interface IStudentAnalyticsResponse extends IAuthorAnalyticsData {
	studentName: string;
	startAnalyticsDate: Date;
}

export interface IStudentAnalyticsOverview {
	studentName: string;
	authorOverviewData: IAuthorTemplateOverview;
	startAnalyticsDate: Date;
	writingFeedbackFeatureEnabled: boolean;
}
