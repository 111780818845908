import { IScanPropertiesInternalSourcesDatabase } from '@ngx-common/components/new-scan-profiles/models/new-scan-profiles.interfaces';

export interface AssignmentBase {
	title: string;
	description?: string;
	allowSubmitAfterDueDate: boolean;
	dueDate: Date;
	maxScore: number;
}
interface AssignmentExtra {
	id: string;
	courseName: string;
	canStudentAccessReport: boolean;
	enableDraftSubmission: boolean;
	files: string[];
}

export interface CreateAssignmentResponse {
	assignmentId: string;
	contentItemRequest: any;
}

export type Assignment = AssignmentBase & AssignmentExtra;

export interface CourseConfig {
	copyleaksEnabled: boolean;
	ignoreReferences: boolean;
	ignoreQuotes: boolean;
	ignoreCodeComments: boolean;
	ignoreTitles: boolean;
	canStudentAccessReport: boolean;
	scanInternalDatabase: boolean;
	enableCheatDetection: boolean;
	enableDraftSubmission: boolean;
	repositories: IScanPropertiesInternalSourcesDatabase[];
}

export interface ScanError {
	code: number;
	message: string;
}

export interface SubmissionFile {
	id: string;
	name: string;
	status: EScanStatus;
	size: number;
	scanScore?: number;
	scanError?: ScanError;
	uploadedAt?: Date;
	expiryDate?: Date;
	error?: { code: number; message: string };
}

export interface AssignmentFile {
	id: string;
	name: string;
	size: number;
	uploadedAt?: Date;
}

export interface UploadFile {
	loaded?: number;
	data?: File;
}
export type UploadAssignmentFile = AssignmentFile & UploadFile;
export type UploadedSubmissionFile = SubmissionFile & UploadFile;

export enum EScanStatus {
	NotScanned = 0,
	PriceChecked = 2,
	InProgress = 3,
	Downloading = 4,
	Error = 5,
	Completed = 6,
	Uploading = 7,
	Indexed = 8,
	// * front-end only
	None = 10,
	Uploaded = 11,
	Deleted = 12,
	UnsavedChange = 13,
}
export interface Member {
	id: string;
	firstName: string;
	lastName: string;
}

export interface Submission {
	assignmentId: string;
	member: Member;
	files: SubmissionFile[];
	lastUpdate: Date;
	sourcedId: string;
	dueDate?: Date;
	score?: number;
	// * front-end only
	status: EScanStatus;
}

// requests to api
export interface UpdateScoreRequest {
	score: number;
	memberId: string;
}

export interface DownloadUrl {
	downloadUrl: string;
}
export enum Role {
	Admin = 'Admin',
	Instructor = 'Instructor',
	Learner = 'Learner',
}
export interface LtiUser {
	courseId?: string;
	token: string;
	product: string;
}
export enum CourseScanOptions {
	Scan = 0,
	Allow = 1,
	Never = 2,
}
