export interface IAccountBillingAddressValue {
	address1?: string;
	address2?: string;
	city?: string;
	postalCode?: string;
	countryCode: string;
	state?: string;
}

export enum EAccountBillingAddressFormControlNames {
	fcCountry = 'countryCode',
	fcAddress1 = 'address1',
	fcAddress2 = 'address2',
	fcCity = 'city',
	fcState = 'state',
	fcPostalCode = 'postalCode',
}
