import { Component, Input } from '@angular/core';
import {
	EGeneralLMSIntegrationStatus,
	EIntegrationConsumer,
} from 'projects/Dashboard/src/app/pages/integrations/models/integrations.enums ';
import { GeneralLMSIntegrationModel } from 'projects/Dashboard/src/app/pages/integrations/models/integrations.models';
@Component({
	selector: 'lms-integration-item',
	templateUrl: './lms-integration-item.component.html',
	styleUrls: ['./lms-integration-item.component.scss'],
})
export class LmsIntegrationItemComponent {
	/**
	 * The source of the item image.
	 * @Input
	 */
	@Input() badgeImageSrc: string;

	/**
	 * The integration item data.
	 */
	@Input() integrationItem: GeneralLMSIntegrationModel;

	/**
	 * The highlighted text
	 * @Input
	 */
	@Input() highlightedText: string = '';

	/**
	 * Flag indicating whether the integration tpye will be highlighted
	 * @Input
	 */
	@Input() highlightType: boolean = false;

	EGeneralLMSIntegrationStatus = EGeneralLMSIntegrationStatus;
	EGeneralLMSIntegrationType = EIntegrationConsumer;

	get className() {
		switch (this.integrationItem.status) {
			case EGeneralLMSIntegrationStatus.Active:
				return 'active';
			case EGeneralLMSIntegrationStatus.Deleted:
				return 'deleted';
			case EGeneralLMSIntegrationStatus.Pending:
				return 'pending';
			default:
				throw new Error(`Unknown integration status: ${this.integrationItem.status}`);
		}
	}
}

@Component({
	selector: 'lms-integration-item-content',
	template: `
		<ng-content></ng-content>
	`,
})
export class LmsIntegrationItemContentComponent {}

@Component({
	selector: 'lms-integration-item-menu',
	template: `
		<ng-content></ng-content>
	`,
})
export class LmsIntegrationItemMenuComponent {}
