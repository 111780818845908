<mat-form-field
	[ngClass]="{ 'mat-input-no-bottom-padding': noBottomPadding, 'with-label': withLabel }"
	[appearance]="appearance">
	<div class="search-input-icon-container" fxLayoutAlign="center center" style="margin-right: 8px" matPrefix>
		<mat-icon
			*ngIf="!value; else clearSearchInput"
			class="search-input-search-icon"
			[ngClass]="{
				'disabled-icon': disabled,
				'icon-with-background': addIconBackground
			}"
			fontSet="material-icons-round">
			search
		</mat-icon>
		<ng-template #clearSearchInput>
			<button mat-icon-button i18n-matTooltip matTooltip="Clear" (click)="clearSearchValue()">
				<mat-icon
					class="search-input-clear-icon"
					[ngClass]="{
						'disabled-icon': disabled,
						'icon-with-background': addIconBackground
					}"
					fontSet="material-icons-round">
					close
				</mat-icon>
			</button>
		</ng-template>
	</div>
	<mat-label *ngIf="withLabel">{{ placeholder }}</mat-label>
	<input
		matInput
		[formControl]="searchFormControl"
		[placeholder]="withLabel ? '' : placeholder"
		[type]="type"
		[value]="value"
		(input)="changeSearchValue($event.target.value)"
		[spellcheck]="spellcheck" />
</mat-form-field>
