<div fxLayout="column" fxLayoutGap="8px">
	<div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="8px">
		<div class="pick-time-text" i18n="@@date-range-text" style="margin-top: 8px">Pick a date range</div>

		<div class="date" fxLayout="row" fxLayoutGap="8px">
			<div fxLayout="column" fxLayoutGap="8px">
				<label for="analytics-start-date" class="field-label" i18n>From</label>
				<mat-form-field appearance="outline">
					<input
						id="analytics-start-date"
						matInput
						[max]="integrationSelection.get('endDate')?.value"
						[min]="baseStartDate"
						[matDatepicker]="start"
						placeholder="MM/YYYY"
						[formControl]="integrationSelection.get('startDate')" />
					<mat-datepicker-toggle
						matIconSuffix
						[for]="start"
						i18n-matTooltip
						matTooltip="Choose start date"
						class="date-picker-toggle"></mat-datepicker-toggle>
					<mat-datepicker
						#start
						panelClass="analytics-date-select"
						startView="multi-year"
						(monthSelected)="setDate($event, start, true)"></mat-datepicker>
				</mat-form-field>
			</div>
			<div fxLayout="column" fxLayoutGap="8px">
				<label for="analytics-end-date" class="field-label" i18n>To</label>
				<mat-form-field appearance="outline">
					<input
						id="analytics-end-date"
						[min]="integrationSelection.get('startDate')?.value"
						[max]="currentDate"
						matInput
						[matDatepicker]="end"
						placeholder="MM/YYYY"
						[formControl]="integrationSelection.get('endDate')" />
					<mat-datepicker-toggle
						matIconSuffix
						[for]="end"
						i18n-matTooltip
						matTooltip="Choose end date"
						class="date-picker-toggle"></mat-datepicker-toggle>
					<mat-datepicker
						#end
						panelClass="analytics-date-select"
						startView="multi-year"
						(monthSelected)="setDate($event, end, false)"></mat-datepicker>
				</mat-form-field>
			</div>
		</div>
	</div>
	<!--  -->
	<div
		class="start-date-error"
		*ngIf="!this.integrationSelection.get('startDate')?.valid || !this.integrationSelection.get('endDate')?.valid"
		i18n>
		Invalid date range! Please select a valid date range.
	</div>
	<!--  -->
</div>
