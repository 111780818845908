import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RemoveAvatarComponent } from './remove-avatar.component';
import { AvatarModule } from 'ngx-avatar';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AvatarIconModule } from '../avatar-selection-menu/avatar-icon/avatar-icon.module';

@NgModule({
	declarations: [RemoveAvatarComponent],
	exports: [RemoveAvatarComponent],
	imports: [CommonModule, AvatarModule, MatIconModule, FlexLayoutModule, AvatarIconModule],
})
export class RemoveAvatarModule {}
