import { trigger, transition, useAnimation } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fadeIn } from 'ng-animate';
import { AnimationOptions } from 'ngx-lottie';
import { interval } from 'rxjs/internal/observable/interval';

@UntilDestroy()
@Component({
	selector: 'cls-loading-animation',
	templateUrl: './cls-loading-animation.component.html',
	styleUrls: ['./cls-loading-animation.component.scss'],
	animations: [trigger('fadeIn', [transition(':enter', useAnimation(fadeIn))])],
})
export class ClsLoadingAnimationComponent implements OnInit {
	/**
	 * Insert text below animation
	 * @type {string}
	 * @Input
	 */
	@Input() txtMessage: string;

	/**
	 * Animation options
	 * @type {AnimationOptions}
	 */
	options: AnimationOptions = {
		renderer: 'svg',
		path: '/assets/lotties/loading-animation.json',
		loop: true,
	};

	dots: string = '';

	constructor() {}

	ngOnInit(): void {
		if (this.txtMessage) {
			this.txtMessage = this.txtMessage.replace(/\.*$/, '');
			this.animateDots();
		}
	}

	private animateDots(): void {
		let counter = 0;
		interval(500)
			.pipe(untilDestroyed(this))
			.subscribe(() => {
				counter = (counter + 1) % 4;
				this.dots = '.'.repeat(counter);
			});
	}
}
