<cls-panel class="mat-typography">
	<cls-panel-header>
		{{ institute.name }}
	</cls-panel-header>
	<cls-panel-actions>
		<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="menu">
			<mat-icon>more_vert</mat-icon>
		</button>
		<mat-menu #menu="matMenu">
			<!-- <button mat-menu-item
							(click)="toggleEdit()">
				<mat-icon>edit</mat-icon> {{editMode ? "Stop Editing":"Edit"}}
			</button> -->
			<button *ngIf="editMode" mat-menu-item (click)="regenerate.emit(this)">
				<mat-icon>security</mat-icon>
				<span i18n>Regenerate Secret</span>
			</button>
			<mat-divider></mat-divider>
			<button mat-menu-item (click)="delete.emit(institute)">
				<mat-icon>delete</mat-icon>
				<span i18n>Delete</span>
			</button>
		</mat-menu>
		<!-- <button mat-icon-button matTooltip="Edit Institute" (click)="toggleEdit()">
			<mat-icon>edit</mat-icon>
		</button>

		<button mat-icon-button matTooltip="Delete institute" (click)="delete.emit(institute)">
			<mat-icon>delete</mat-icon>
		</button> -->
	</cls-panel-actions>
	<cls-panel-body fxLayout="column" fxLayoutGap="1em">
		<div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="1em">
			<div fxLayout="column" fxFlex="50" fxLayoutAlign="start stretch" fxLayoutGap="1em" style="font-size: 1.5em">
				<mat-form-field fxFlex>
					<input readonly="true" matInput placeholder="Key" i18n-placeholder [value]="institute.id" />
				</mat-form-field>
				<mat-form-field fxFlex>
					<input
						readonly="true"
						#secret
						placeholder="Secret"
						i18n-placeholder
						matInput
						[type]="showSecret ? 'text' : 'password'"
						autocomplete="off"
						[value]="institute.secret" />
					<button mat-button matSuffix mat-icon-button (click)="toggleSecret()" aria-label="visibility">
						<mat-icon>{{ secret.type == 'password' ? 'visibility' : 'visibility_off' }}</mat-icon>
					</button>
				</mat-form-field>
			</div>

			<institute-integrations
				fxLayout="column"
				fxFlex="50"
				fxLayoutAlign="start stretch"
				[instituteId]="institute.id"></institute-integrations>

			<!--	<mat-divider style="margin-bottom: 1.25em">
			</mat-divider> -->
		</div>
		<div fxLayout="row" fxLayoutAlign="start stretch">
			<mat-accordion fxFlex>
				<mat-expansion-panel class="mat-elevation-z0">
					<mat-expansion-panel-header>
						<mat-panel-title i18n>Advanced Settings</mat-panel-title>
					</mat-expansion-panel-header>

					<form #form="ngForm" fxLayout="row wrap" fxFlex fxLayoutGap="1em" style="min-height: 0 !important">
						<fieldset fxLayout="column">
							<mat-form-field>
								<mat-label i18n>Days until scans expire</mat-label>
								<input
									[disabled]="!editMode"
									matInput
									type="number"
									name="daysToExpire"
									required
									[range]="[MinDaysToExpire, MaxDaysToExpire]"
									[(ngModel)]="institute.daysToExpire"
									#daysToExpire="ngModel" />

								<mat-error *ngIf="daysToExpire?.errors?.required" i18n>This field is required</mat-error>
								<mat-error *ngIf="daysToExpire?.errors?.range">
									<span i18n>Value must be between</span>
									{{ MinDaysToExpire }}
									<span i18n>and</span>
									{{ MaxDaysToExpire }}
								</mat-error>
							</mat-form-field>
							<mat-slide-toggle [disabled]="!editMode" [(ngModel)]="institute.scanInternalDatabase" name="sid" i18n>
								Scan Against Internal Database
							</mat-slide-toggle>
							<mat-slide-toggle [disabled]="!editMode" [(ngModel)]="institute.generatePdfReport" name="gpr" i18n>
								Generate PDF report
							</mat-slide-toggle>
						</fieldset>
						<fieldset fxLayout="column">
							<mat-slide-toggle [disabled]="!editMode" [(ngModel)]="institute.ignoreReferences" name="ir" i18n>
								Ignore references
							</mat-slide-toggle>
							<mat-slide-toggle [disabled]="!editMode" [(ngModel)]="institute.ignoreQuotes" name="iq" i18n>
								Ignore quotes
							</mat-slide-toggle>
							<mat-slide-toggle [disabled]="!editMode" [(ngModel)]="institute.ignoreTitles" name="it" i18n>
								Ignore titles
							</mat-slide-toggle>
							<mat-slide-toggle [disabled]="!editMode" [(ngModel)]="institute.ignoreCodeComments" name="icc" i18n>
								Ignore source code comments
							</mat-slide-toggle>
							<mat-slide-toggle [disabled]="!editMode" [(ngModel)]="institute.enableCheatDetection" name="ecd" i18n>
								Enable Cheat Detection
							</mat-slide-toggle>
							<mat-slide-toggle [disabled]="!editMode" [(ngModel)]="institute.canStudentAccessReport" name="csar" i18n>
								Allow students access to reports
							</mat-slide-toggle>
							<mat-slide-toggle
								[disabled]="!editMode || !institute.canStudentAccessReport"
								[(ngModel)]="institute.enableDraftSubmission"
								matTooltip="This can only be enabled if the 'students allowed to view reports' is also enabled"
								i18n-matTooltip
								[matTooltipDisabled]="institute.canStudentAccessReport"
								name="eds"
								i18n>
								Enable Draft Submission
							</mat-slide-toggle>
						</fieldset>
						<br />
						<div style="width: 100%">
							<h3 class="repositories-title" i18n>Repositories:</h3>
							<app-repository-validation-message
								[control]="repositoriesCtrl.control"></app-repository-validation-message>
							<app-scan-databases-table
								[isRepositoriesLoading]="isRepositoriesLoading"
								[repositories]="repositories"
								[hideInternalDatabase]="true"
								[(ngModel)]="institute.repositories"
								(addRepository)="addRepository()"
								#repositoriesCtrl="ngModel"
								name="repositoriesCtrl"></app-scan-databases-table>
						</div>
					</form>
				</mat-expansion-panel>
			</mat-accordion>
		</div>
	</cls-panel-body>
</cls-panel>
