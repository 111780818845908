import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ICopyLeaksCaptchaControlValue } from '@ngx-common/components/copyleaks-captcha/copyleaks-captcha-component/copyleaks-captcha.component';

@Injectable({
	providedIn: 'root',
})
export class SupportService {
	constructor(private http: HttpClient) {}

	async sendContactUsEmail(data: ContactUsRequest) {
		return await this.http.post(`/api/support/contact-us`, data).toPromise();
	}
}

export interface ContactUsRequest {
	name: string;
	email: string;
	company: string;
	message: string;
	phoneNumber: number;
	numberOfUsers?: number;
	captcha: ICopyLeaksCaptchaControlValue;
}
