import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'floatDecimal',
})
export class FloatDecimalPipe implements PipeTransform {
	constructor(private _numberPipe: DecimalPipe) {}
	transform(value: number, digitsInfo?: string): string {
		var isInt = Number.isInteger(value);
		if (isInt) return this._numberPipe.transform(value);
		return this._numberPipe.transform(value, digitsInfo);
	}
}
