import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from '@environment';
import { ClsAlertsService } from '@ngx-common-v2/components/cls-alerts/services/cls-alerts.service';
import { take } from 'rxjs';
import { httpFailRetry } from '../../observable/http-fail-retry.pipes';
import { NgxCommonPagesConfig } from '../../pages/pages.config';
import { AuthService } from '../../services/auth.service';
import { DevicesService } from '../../services/devices.service';
import { UrlLocationService } from '../../services/url-location.service';
import { WindowService } from '../../services/window.service';
import {
	ENotificationType,
	ETaskElementStatus,
	IBatchDownloadUpdateNotification,
	IPersonalNotificationTask,
} from '../models/web-notifications.models';
import { BasePersonalNotificationsService } from './base-personal-notifications.service';

@Injectable({
	providedIn: 'root',
})
export class ProgressPersonalNotificationsService extends BasePersonalNotificationsService {
	constructor(
		private _devicesSvc: DevicesService,
		private _windowSvc: WindowService,
		@Inject(PLATFORM_ID) platformId: object,
		urlLocationSvc: UrlLocationService,
		authSvc: AuthService,
		httpClient: HttpClient,
		clsAlertsSvc: ClsAlertsService,
		@Inject(NgxCommonPagesConfig.key) config: NgxCommonPagesConfig
	) {
		super(platformId, urlLocationSvc, authSvc, httpClient, clsAlertsSvc, config);
		this._onServiceInit();
	}

	private _onServiceInit() {
		this.onNotificationChange.subscribe(value => {
			if (value?.metaData?.status == ETaskElementStatus.Done || value?.metaData?.status == ETaskElementStatus.Failed) {
				setTimeout(() => {
					this.notifications = [...this.notifications.filter(n => n.id != value.id)];
				}, 60000);
			} else if (value?.metaData?.status == ETaskElementStatus.Canceled) {
				this.notifications = [...this.notifications.filter(n => n.id != value.id)];
			}
		});
	}

	public loadNotificationsAsync(forceReload?: boolean): void {
		this.httpClient
			.get<IPersonalNotificationTask[]>(
				`${environment.notificationsAPI}/notifications/${this.signalRProducts}/personal/in-progress`
			)
			.pipe(take(1))
			.subscribe({
				next: result => {
					this.notifications = result;
					for (const data of result) {
						if (data.messageType == ENotificationType.deleteElements) {
							this.initDeleteElementNotificationHandler(data.id);
						}
						if (data.messageType == ENotificationType.downloadReport) {
							this.initDownloadElementNotificationHandler(data.id);
						}
						if (data.messageType == ENotificationType.indexDocuments) {
							this.initIndexDocumentsNotificationHandler(data.id);
						}
					}
				},
				error: err => this.clsAlertsSvc.showHttpResponseError(err),
			});
	}

	public async onDownloadReportPdfRequestFinishAsync(
		notification: IPersonalNotificationTask,
		data: IBatchDownloadUpdateNotification
	) {
		if (notification?.metaData?.totalFiles == 1) {
			if (!notification?.metaData?.fileName) {
				notification.metaData.fileName = 'Copyleaks-report';
			}
			if (this.isAllowedToInteractWithNotification(notification.id))
				await this.downloadReportPdfUrlAsync(notification.id, 'pdf', notification.metaData.fileName);
		} else {
			if (this.isAllowedToInteractWithNotification(notification.id))
				await this.downloadReportPdfUrlAsync(notification.id, 'zip');
		}
		notification.metaData.status = data.status;
		if (data.progress >= 100) {
			notification.metaData.progress = 99;
		} else {
			notification.metaData.progress = data.progress;
		}
		notification.message = 'SERVER_NOTIFICATION.ELEMENT_PDF_DOWNLOAD_DONE';
		this.onNotificationChange.next(notification);
	}

	public onCancelDownload(elementId: string) {
		this.notifications.forEach(notification => {
			if (notification.metaData.selectedIds.includes(elementId)) {
				this.cancelNotification(notification.id);
				return;
			}
		});
	}

	public async downloadReportPdfUrlAsync(notificationId: string, type: string, fileName = '') {
		try {
			this.removeNotificationInteraction(notificationId);
			const fileUrl = `${environment.apiUrl}/v1/dashboard/scans/${notificationId}/export-pdf`;
			if (this._devicesSvc.isIOS()) {
				this._windowSvc.open(fileUrl, '_blank');
			} else {
				const data = await this.httpClient
					.get(fileUrl, { responseType: 'arraybuffer' })
					.pipe(httpFailRetry())
					.toPromise();
				this._downloadPdfFileWithoutNavigation(data, type, notificationId, fileName);
			}
		} catch (error) {
			throw error;
		}
	}

	private _downloadPdfFileWithoutNavigation(
		dataToDownload: any,
		type: string,
		notificationId: string,
		fileName: string
	) {
		const blob = new Blob([dataToDownload], { type: `application/${type}` });
		const url = window?.URL.createObjectURL(blob);
		const a = document.createElement('a');
		document.body.appendChild(a);
		a.setAttribute('style', 'display: none');
		a.href = url;
		a.download =
			type == 'zip' ? `report-pdf-download-${new Date(Date.now()).toISOString()}.zip` : `${fileName} - report.pdf`;
		a.click();
		window?.URL.revokeObjectURL(url);
		a.remove(); // remove the element
	}
}
