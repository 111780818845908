export function loadScript(url: string) {
	return new Promise<void>((resolve, reject) => {
		const existsScript = document.querySelector(`script[src="${url}"]`);
		if (!existsScript) {
			const script: any = document.createElement('script');
			if (script.readyState) {
				// only required for IE <9
				script.onreadystatechange = () => {
					if (script.readyState === 'loaded' || script.readyState === 'complete') {
						script.onreadystatechange = null;
						resolve();
					} else if (script.readyState === 'error') {
						reject();
					}
				};
			} else {
				// Others
				script.onload = () => {
					resolve();
				};
				script.onerror = () => {
					reject();
				};
			}
			script.src = url;
			document.getElementsByTagName('head')[0].appendChild(script);
		} else {
			resolve();
		}
	});
}

export function loadCSS(url: string, type = 'text/css') {
	return new Promise<void>((resolve, reject) => {
		const existsLink = document.querySelector(`link[href="${url}"]`);
		if (!existsLink) {
			const link: any = document.createElement('link');
			if (link.readyState) {
				// only required for IE <9
				link.onreadystatechange = () => {
					if (link.readyState === 'loaded' || link.readyState === 'complete') {
						link.onreadystatechange = null;
						resolve();
					} else if (link.readyState === 'error') {
						reject();
					}
				};
			} else {
				// Others
				link.onload = () => {
					resolve();
				};
				link.onerror = () => {
					reject();
				};
			}
			link.rel = 'stylesheet';
			if (type) {
				link.type = type;
			}
			link.href = url;
			document.getElementsByTagName('head')[0].appendChild(link);
		} else {
			resolve();
		}
	});
}
