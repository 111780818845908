import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'cls-score-level',
	templateUrl: './cls-score-level.component.html',
	styleUrls: ['./cls-score-level.component.scss'],
})
export class ClsScoreLevelComponent implements OnInit, OnChanges {
	/**
	 * Set the score level for view and color
	 * @type {number}
	 * @input score
	 */
	@Input() score: number;

	/**
	 * Source link of referal score
	 * @type {string}
	 * @input link
	 */
	@Input() link: string;

	/**
	 * Cheat Detection flag
	 * @type {boolean}
	 * @input isCheatDetected
	 */
	@Input() isCheatDetected: boolean;

	/**
	 * Is loading flag
	 * @type {boolean}
	 * @input isLoading
	 */
	@Input() isLoading: boolean;

	/**
	 * Error message text
	 * @type {string}
	 * @input errorMessage
	 */
	@Input() errorMessage: string;

	/**
	 * Flag to open link in new window
	 * @type {boolean}
	 * @input openLinkInNewWindow
	 */
	@Input() openLinkInNewWindow: boolean;

	/**
	 * if API property is enabled.
	 * @type {boolean}
	 * @input isAPIPropertyEnabled
	 */
	@Input() isAPIPropertyEnabled: boolean = true;

	/**
	 * Will be determine by the score
	 */
	className: string;

	constructor(private _router: Router) {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.score && changes.score.currentValue !== undefined) {
			this.setColorScore();
		}
	}

	ngOnInit(): void {
		this.setColorScore();
	}
	private setColorScore() {
		if (this.score == 100) {
			this.className = 'hunderd';
		} else if (this.score >= 90) {
			this.className = 'ninety';
		} else if (this.score >= 80) {
			this.className = 'eighty';
		} else if (this.score >= 70) {
			this.className = 'seventy';
		} else if (this.score >= 60) {
			this.className = 'sixty';
		} else if (this.score >= 50) {
			this.className = 'fifty';
		} else if (this.score >= 40) {
			this.className = 'fourty';
		} else if (this.score >= 30) {
			this.className = 'thirty';
		} else if (this.score >= 20) {
			this.className = 'twenty';
		} else if (this.score >= 10) {
			this.className = 'ten';
		} else {
			this.className = 'zero';
		}
	}

	/**
	 * Open external link source
	 */
	openSource() {
		if (!this.link) return;

		if (this.openLinkInNewWindow) {
			window.open(this.link);
		} else {
			this._router.navigate([this.link]);
		}
	}
}
