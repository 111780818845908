import { Component, OnInit, Input } from '@angular/core';
import { IAnalyticsExternalResults as IAnalyticsExternalResults } from '../../models/cls-external-results-table.models';
import { IPaginateData } from '../../../cls-paginator/models/cls-paginator.models';
import { ScreenService } from '@ngx-common/services/screen.service';
import { MatTableDataSource } from '@angular/material/table';
import { ITableEmptyState } from '../../../../components/cls-attachements-manager-table/models/cls-attachements-manager-table.models';
import { downloadDataToCsv } from '../../../../utils/csv_downloader.utils';

@Component({
	selector: 'cls-external-results-table',
	templateUrl: './cls-external-results-table.component.html',
	styleUrls: ['./cls-external-results-table.component.scss'],
})
export class ClsExternalResultsTableComponent implements OnInit {
	/**
	 * The data source for the table.
	 * @type {IAnalyticsExternalResults[]}
	 * @Input
	 * @requires
	 */
	@Input() data: IAnalyticsExternalResults[];

	/**
	 * Show content when the table is empty
	 * @type {ITableEmptyState[]}
	 * @Input
	 * @requires
	 */
	@Input() emptyState: ITableEmptyState;

	/**
	 * Show content when the table is empty
	 * @type {ITableEmptyState[]}
	 * @Input
	 * @requires
	 */
	@Input() showTableHeader: boolean;

	/**
	 * Table title
	 * @type {string}
	 * @Input
	 */
	@Input() tableTitle: string = $localize`Most Common Internet Sources`;

	tableDataSource: MatTableDataSource<IAnalyticsExternalResults>;

	// Controls the sort state -> decenidng/ascending
	isTalbeAscending: boolean = false;
	searchResults: IAnalyticsExternalResults[] = [];
	// Current paginator state
	paginateState: IPaginateData = {
		pageIndex: 1,
		preIndex: 0,
		pageSize: 10,
	};

	pageAmountOptions = [10, 50, 100];

	displayedColumns: string[] = ['results_sources', 'times_found'];

	isMobile: boolean;
	isEmptyState = false;
	searchStr: string;
	hasNoSearchResults = false;

	get dataSource() {
		return this.searchStr && this.searchStr.length ? this.searchResults : this.data;
	}

	get canLoadMore() {
		return this.dataSource.length > this.paginateState.pageSize * this.paginateState.pageIndex;
	}

	get showPaginator() {
		return !this.isEmptyState && this.dataSource?.length > 10 && !this.hasNoSearchResults;
	}

	constructor(private _screenSvc: ScreenService) {}

	ngOnInit(): void {
		this.isMobile = this._screenSvc.isMobile();

		if (!this.data.length) {
			this.data = Array.from(
				{ length: this.emptyState?.totalEmptyRows ? this.emptyState?.totalEmptyRows : this.paginateState.pageSize + 1 },
				() => ({} as IAnalyticsExternalResults)
			);
			this.isEmptyState = true;
		}
		this.tableDataSource = new MatTableDataSource<IAnalyticsExternalResults>(
			this.data.slice(0, this.isEmptyState ? this.paginateState.pageSize + 1 : this.paginateState.pageSize)
		);
	}

	// sort the data
	sortSources() {
		if (this.searchStr) {
			this.searchResults = this.searchResults.sort((a, b) =>
				!this.isTalbeAscending ? a.value - b.value : b.value - a.value
			);
		} else {
			this.data = this.data.sort((a, b) => (!this.isTalbeAscending ? a.value - b.value : b.value - a.value));
		}
		this.isTalbeAscending = !this.isTalbeAscending;
		this.paginateState.pageIndex = 1;
		this.paginateState.preIndex = 0;
		this.movePaginator(this.paginateState);
	}

	// pagination event.
	movePaginator(paginateData: IPaginateData) {
		const paginateFromData = this.dataSource;
		if (this.paginateState.pageSize != paginateData.pageSize) {
			this.tableDataSource.data = paginateFromData.slice(0, paginateData.pageSize);
		} else {
			const startIdx = paginateData.preIndex * paginateData.pageSize;
			const endIdx = paginateData.pageIndex * paginateData.pageSize;

			// page up
			if (startIdx < endIdx) {
				this.tableDataSource.data = paginateFromData.slice(startIdx, endIdx);
			} else {
				// page down
				this.tableDataSource.data = paginateFromData.slice(
					(paginateData.pageIndex - 1) * paginateData.pageSize,
					(paginateData.preIndex - 1) * paginateData.pageSize
				);
			}
		}
		this.paginateState = paginateData;
	}

	// click on source.
	openSource(source: string) {
		window.open('https://' + source, '_blank');
	}

	onSearchConfirm(searchString: string) {
		this.searchStr = searchString;
		if (searchString.length) {
			searchString = searchString.toLocaleLowerCase();
			this.searchResults = this.data.filter(
				r => searchString.includes(r.name.toLocaleLowerCase()) || r.name.toLocaleLowerCase().includes(searchString)
			);
			if (this.searchResults.length > 0) {
				this.tableDataSource = new MatTableDataSource<IAnalyticsExternalResults>(this.searchResults);
				this.tableDataSource.data = this.searchResults.slice(0, this.paginateState.pageSize);
				this.hasNoSearchResults = false;
			} else {
				this.searchResults = Array.from({ length: 11 }, () => ({ name: '', value: null } as IAnalyticsExternalResults));
				this.tableDataSource = new MatTableDataSource<IAnalyticsExternalResults>(this.searchResults);
				this.hasNoSearchResults = true;
			}
			this.paginateState.pageIndex = 1;
			this.paginateState.preIndex = 0;
			this.movePaginator(this.paginateState);
		} else {
			this.hasNoSearchResults = false;
			this.tableDataSource = new MatTableDataSource<IAnalyticsExternalResults>(
				this.data.slice(0, this.isEmptyState ? this.paginateState.pageSize + 1 : this.paginateState.pageSize)
			);
			this.searchStr = '';
			this.searchResults = [];
		}
	}

	download() {
		const dataToExport = this.data.map(source => {
			return {
				Domains: source.name,
				'Times Found': source.value,
			};
		});
		downloadDataToCsv(dataToExport, 'Sources_Data');
	}
}
