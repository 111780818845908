import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'cls-contained-content',
	templateUrl: './cls-contained-content.component.html',
	styleUrls: ['./cls-contained-content.component.scss'],
})
export class ClsContainedContentComponent implements OnInit {
	/**
	 * Indicates if stand absolute or not
	 * @type {boolean}
	 * @Input
	 */
	@Input() isSelfContained = true;

	/**
	 * Flag if the text should be centered
	 * @type {boolean}
	 * @Input
	 */
	@Input() isTextCentered: boolean = true;

	/**
	 * Title size - must include 'px'
	 * @type {string}
	 * @Input
	 */
	@Input() titleSize: string = '20px';
	/**
	 * Gap size - must include 'px'
	 * @type {string}
	 * @Input
	 */
	@Input() gapSize: string = '20px';
	constructor() {}

	ngOnInit(): void {}
}

@Component({
	selector: 'cls-contained-title',
	template: '<ng-content></ng-content>',
})
export class ClsContainedTitleComponent {}

@Component({
	selector: 'cls-contained-description',
	template: '<ng-content></ng-content>',
})
export class ClsContainedDescriptionComponent {}
