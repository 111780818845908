import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-remove-avatar',
	templateUrl: './remove-avatar.component.html',
	styleUrls: ['./remove-avatar.component.scss'],
})
export class RemoveAvatarComponent implements OnInit {
	@Input() entityAvatar: IAvatarRemoval;
	constructor() {}

	ngOnInit(): void {}
}

export interface IAvatarRemoval {
	id?: string;
	name?: string;
	email?: string;
	size?: number;
	disaplyName?: boolean;
	icon?: string;
	iconBgColor?: string;
}
