<div class="integration-level-container" fxLayout="column">
	<cls-page-banner *ngIf="!isLoading && this.dataSrc?.isStatisticsExists">
		<cls-page-banner-icon><mat-icon>info_outline</mat-icon></cls-page-banner-icon>
		<cls-page-banner-description i18n>
			Copyleaks LMS Analytics started collecting data on {{ ANALYTICS_COLLECTION_DATE | date }}. Any data before this
			date will not appear.
		</cls-page-banner-description>
	</cls-page-banner>

	<ng-container *ngIf="isLoading">
		<div class="loading-container">
			<cls-loading-animation [txtMessage]="loadingMessage"></cls-loading-animation>
		</div>
	</ng-container>

	<ng-container *ngIf="!isLoading">
		<lms-analytics-date-range
			style="margin-top: 8px"
			(dateRangeChangeData)="dateRangeChange($event)"
			[dateRange]="dateRange"
			[baseStartDate]="baseStartDate"></lms-analytics-date-range>

		<lms-integration-item
			[integrationItem]="integrationItemData"
			[badgeImageSrc]="integrationItemData?.itemBedgeSource">
			<lms-integration-item-menu>
				<!-- Item menu -->
				<ng-container
					*ngIf="
						platformType === EGeneralLMSIntegrationType.LTI1_3 || platformType === EGeneralLMSIntegrationType.Moodle
					"
					[ngTemplateOutlet]="activeMenu"
					[ngTemplateOutletContext]="{ item: integrationItemData }"></ng-container>
			</lms-integration-item-menu>

			<!-- Item content -->
			<lms-integration-item-content>
				<ng-container [ngSwitch]="integrationItemData.type">
					<ng-container *ngSwitchCase="EGeneralLMSIntegrationType.LTI1_3">
						<a
							mat-icon-button
							*ngIf="!integrationItemData.isInegrationDeleted"
							class="scan-settings-link icon-button"
							[routerLink]="[
								'/dashboard',
								'lti1.3',
								'settings',
								integrationId,
								integrationItemData?.metaData?.defaultScanSettingsId
							]"
							i18n>
							<mat-icon fontSet="material-icons-round">settings</mat-icon>
							Default scan settings
						</a>
					</ng-container>
					<ng-container *ngSwitchCase="EGeneralLMSIntegrationType.Canvas">
						<ng-container [ngSwitch]="integrationItemData.status">
							<ng-container *ngSwitchCase="EGeneralLMSIntegrationStatus.Active">
								<a
									mat-icon-button
									*ngIf="!integrationItemData.isInegrationDeleted"
									class="scan-settings-link icon-button"
									[routerLink]="[
										'/dashboard',
										'canvas',
										'settings',
										integrationId,
										integrationItemData?.metaData?.defaultScanSettingsId
									]"
									i18n>
									<mat-icon fontSet="material-icons-round">settings</mat-icon>
									Default scan settings
								</a>
							</ng-container>
							<ng-container *ngSwitchDefault></ng-container>
						</ng-container>
					</ng-container>
					<ng-container *ngSwitchCase="EGeneralLMSIntegrationType.Moodle">
						<ng-container [ngSwitch]="integrationItemData.status">
							<ng-container *ngSwitchCase="EGeneralLMSIntegrationStatus.Active">
								<a
									mat-icon-button
									*ngIf="!integrationItemData.isInegrationDeleted"
									class="scan-settings-link icon-button"
									[routerLink]="[
										'/dashboard',
										'moodle',
										'settings',
										integrationId,
										integrationItemData?.metaData?.defaultScanSettingsId
									]"
									i18n>
									<mat-icon fontSet="material-icons-round">settings</mat-icon>
									Default scan settings
								</a>
							</ng-container>
							<ng-container *ngSwitchDefault></ng-container>
						</ng-container>
					</ng-container>
					<ng-container *ngSwitchDefault></ng-container>
				</ng-container>
				<!-- Active item content -->
			</lms-integration-item-content>
		</lms-integration-item>

		<ng-container *ngIf="this.dataSrc?.isStatisticsExists; else emptyView">
			<!-- Insights -->
			<div class="insights-container">
				<div class="results-insights-template-container">
					<cls-results-insights-template
						(scrollToEvent)="scrollToGraph($event)"
						fxFlex
						[cardsDataSrc]="dataSrc?.cardsData"
						[analyticsLevel]="eLevelType.Integration"></cls-results-insights-template>
				</div>

				<div class="insights-sections">
					<div
						class="charts-wrapper line-chart-with-top-legend line-charts-top-padding line-charts-bottom-padding"
						[ngClass]="{ 'no-line-charts-bottom-padding': dataSrc.writingFeedbackFeatureEnabled }"
						[id]="eScrollToType.Originality"
						#insightsItem>
						<div class="chart-title" fxLayout="row" fxLayoutGap="8px">
							<h3 i18n>Avg. Originality</h3>
							<mat-icon
								class="info-icon"
								i18n-matTooltip
								matTooltip="Originality score shows historical data of plagiarism and AI cases detected. ">
								info_outline
							</mat-icon>
						</div>
						<ngx-charts-line-chart
							[view]="lineChartView"
							*ngIf="lineChartView"
							class="chart"
							[xAxis]="true"
							[yAxis]="true"
							[legend]="true"
							legendTitle=""
							[results]="dataSrc.originalityData.chartData"
							[yAxisLabel]="incidentsText"
							[showYAxisLabel]="true"
							[showXAxisLabel]="false"
							[showGridLines]="true"
							[customColors]="lineChartCustomColors"
							[yAxisTicks]="dataSrc.originalityData.yTicks"
							[curve]="linearCurve"
							[yScaleMax]="dataSrc.originalityData.maxYTick"
							(window:resize)="onResize($event, insightsItem)"></ngx-charts-line-chart>
					</div>
					<ng-container *ngIf="dataSrc.writingFeedbackFeatureEnabled">
						<div
							class="charts-wrapper line-chart-with-top-legend no-line-charts-bottom-padding line-charts-top-padding writing-feedback-chart"
							[id]="eScrollToType.WritingCorrections">
							<div class="chart-title" fxLayout="row" fxLayoutGap="8px">
								<h3 i18n>Avg. Writing Corrections</h3>
								<mat-icon
									class="info-icon"
									i18n-matTooltip
									matTooltip="Average number of writing corrections per scan.">
									info_outline
								</mat-icon>
							</div>
							<ngx-charts-bar-vertical
								[view]="writingFeedbackChartView"
								*ngIf="writingFeedbackChartView"
								[scheme]="{ domain: ['#FF9A02'] }"
								[results]="dataSrc.writingFeedback.chartData"
								[gradient]="false"
								[xAxis]="true"
								[yAxis]="true"
								[legend]="false"
								[yScaleMin]="0"
								[showXAxisLabel]="true"
								[barPadding]="20"
								[showYAxisLabel]="true"
								[yAxisTicks]="dataSrc.writingFeedback.yTicks"
								[yScaleMax]="dataSrc.writingFeedback.maxYTick"
								[yAxisLabel]="correctionText"></ngx-charts-bar-vertical>
						</div>
					</ng-container>
					<ng-container *ngIf="!dataSrc.writingFeedbackFeatureEnabled">
						<analytics-empty-state [id]="eScrollToType.WritingCorrections" fxFlex>
							<analytics-empty-state-title i18n>Writing Correction</analytics-empty-state-title>
							<analytics-empty-state-image>
								<img src="../../../../assets/analytics/svgs/writingFeedbackEmptyState.svg" alt="Writing Correction" />
							</analytics-empty-state-image>
							<analytics-empty-state-description>
								Keep your writing concise and fresh with tips for improving sentence structure, word choice, overall
								mechanics, spelling, and more.
							</analytics-empty-state-description>
							<analytics-empty-state-bottom-section>
								<div class="empty-state-link-text" i18n>Contact your CSM to enable this feature.</div>
							</analytics-empty-state-bottom-section>
						</analytics-empty-state>
					</ng-container>
				</div>

				<div class="insights-sections">
					<div class="charts-wrapper" [id]="eScrollToType.SubmittedScans">
						<div class="chart-title" fxLayout="row" fxLayoutGap="8px">
							<h3 i18n>Submitted Scans</h3>
							<mat-icon class="info-icon" i18n-matTooltip matTooltip="All the times a document was submitted.">
								info_outline
							</mat-icon>
						</div>
						<ngx-charts-bar-vertical
							class="chart"
							[view]="chartView"
							*ngIf="chartView"
							[scheme]="{ domain: ['#3f9af5'] }"
							[results]="dataSrc.submittedScansData.chartData"
							[gradient]="false"
							[xAxis]="true"
							[yAxis]="true"
							[legend]="false"
							[yScaleMin]="0"
							[showXAxisLabel]="true"
							[barPadding]="20"
							[showYAxisLabel]="true"
							[yAxisTicks]="dataSrc.submittedScansData.yTicks"
							[yScaleMax]="dataSrc.submittedScansData.maxYTick"
							[yAxisLabel]="submissionsText"></ngx-charts-bar-vertical>
					</div>
					<div class="charts-wrapper" [id]="eScrollToType.Results">
						<div class="chart-title" fxLayout="row" fxLayoutGap="8px">
							<h3 i18n>Matched Text Results</h3>
							<mat-icon
								class="info-icon"
								i18n-matTooltip
								matTooltip="Tracks overall historical data of detection results. ">
								info_outline
							</mat-icon>
						</div>
						<ngx-charts-bar-vertical
							class="chart"
							[view]="chartView"
							*ngIf="chartView"
							[scheme]="{ domain: ['#3f9af5'] }"
							[results]="dataSrc.resultsData.chartData"
							[gradient]="false"
							[xAxis]="true"
							[yAxis]="true"
							[legend]="false"
							[yScaleMin]="0"
							[showXAxisLabel]="true"
							[showYAxisLabel]="true"
							[yAxisTicks]="dataSrc.resultsData.yTicks"
							[barPadding]="20"
							[yScaleMax]="dataSrc.resultsData.maxYTick"
							[yAxisLabel]="resultsText"></ngx-charts-bar-vertical>
					</div>
				</div>

				<div class="insights-sections">
					<div
						class="charts-wrapper line-charts-bottom-padding line-charts-top-padding"
						[id]="eScrollToType.CrossLanguage"
						*ngIf="dataSrc?.cardsData?.crossLanguage?.value > 0">
						<div class="chart-title" fxLayout="row" fxLayoutGap="8px">
							<h3 i18n>Cross-Language Matched Text</h3>
							<mat-icon
								class="info-icon"
								i18n-matTooltip
								matTooltip="Historical data of cross-language plagiarism cases">
								info_outline
							</mat-icon>
						</div>
						<ngx-charts-line-chart
							class="chart"
							[view]="chartView"
							*ngIf="chartView"
							[xAxis]="true"
							[yAxis]="true"
							[legend]="false"
							[results]="dataSrc?.crossLanguageData.chartData"
							[yAxisLabel]="incidentsText"
							[showYAxisLabel]="true"
							[showXAxisLabel]="false"
							[showGridLines]="true"
							[scheme]="{ domain: ['#3f9af5'] }"
							[yAxisTicks]="dataSrc?.crossLanguageData.yTicks"
							[curve]="linearCurve"
							[yScaleMax]="dataSrc?.crossLanguageData.maxYTick"></ngx-charts-line-chart>
					</div>
					<ng-container *ngIf="dataSrc?.cardsData?.crossLanguage?.value == 0">
						<analytics-empty-state [id]="eScrollToType.CrossLanguage" fxFlex>
							<analytics-empty-state-title i18n>Get Cross-language plagiarism detection</analytics-empty-state-title>
							<analytics-empty-state-image>
								<img
									class="analytics-empty-state-image"
									src="../../../../assets/analytics/images/crossLanguage.png"
									alt="Cross Language" />
							</analytics-empty-state-image>
							<analytics-empty-state-description>
								Cross-language translation works by scanning for similar content in other languages in addition to the
								language of your original document. This enables you to scan for results in over 30 languages from which
								content may have been taken.
							</analytics-empty-state-description>
							<analytics-empty-state-bottom-section>
								<div>
									<span class="empty-state-link-text" i18n>Learn more&nbsp;</span>
									<a
										href="https://copyleaks.com/features/cross-language-plagiarism-detection"
										target="_blank"
										class="cross-language-empty-state-link-text"
										i18n>
										here.
									</a>
								</div>
								<div class="empty-state-link-text">
									For information on pricing, contact your Customer Success Manager.
								</div>
							</analytics-empty-state-bottom-section>
						</analytics-empty-state>
					</ng-container>

					<div
						class="charts-wrapper hide-legend-section line-chart-with-top-legend line-charts-bottom-padding line-charts-top-padding"
						[id]="eScrollToType.CheatsFound">
						<div class="chart-title" fxLayout="row" fxLayoutGap="8px">
							<h3 i18n>Cheats Found</h3>
							<mat-icon
								class="info-icon"
								i18n-matTooltip
								matTooltip="Appears when a scan has detected an abnormality, i.e., manipulated text, character replacement, etc.">
								info_outline
							</mat-icon>
						</div>
						<ngx-charts-line-chart
							[view]="lineChartView"
							*ngIf="lineChartView"
							class="chart"
							[xAxis]="true"
							[yAxis]="true"
							[legend]="true"
							legendTitle=""
							[results]="dataSrc?.cheatsFoundData.chartData"
							[yAxisLabel]="incidentsText"
							[showYAxisLabel]="true"
							[showXAxisLabel]="false"
							[showGridLines]="true"
							[scheme]="{ domain: ['#d04340'] }"
							[yAxisTicks]="dataSrc?.cheatsFoundData.yTicks"
							[curve]="linearCurve"
							[yScaleMax]="dataSrc?.cheatsFoundData.maxYTick"
							(window:resize)="onResize($event, insightsItem)"></ngx-charts-line-chart>
					</div>
				</div>
			</div>

			<!-- Courses Table -->
			<ng-container *ngIf="!dataSrc.hideContexts">
				<lms-analytics-courses-table [dateRange]="dateRange"></lms-analytics-courses-table>
			</ng-container>

			<!-- Assignments Table  -->
			<lms-analytics-assignments-tabel
				[dateRange]="dateRange"
				[isWithPagination]="true"
				[writingFeedbackFeatureEnabled]="dataSrc.writingFeedbackFeatureEnabled"></lms-analytics-assignments-tabel>

			<!-- Common results -->
			<cls-common-results-template
				class="results-template"
				[dataSrc]="dataSrc?.externalResults"></cls-common-results-template>
		</ng-container>
	</ng-container>
</div>

<ng-template #activeMenu let-item="item">
	<!-- Show all the other actions button -->
	<button
		class="integration-action-button"
		mat-icon-button
		[matMenuTriggerFor]="menu"
		activeIntegrationMenu
		aria-label="more options">
		<mat-icon fontSet="material-icons-round" i18n-matTooltip matTooltip="More options">more_vert</mat-icon>
	</button>
	<mat-menu #menu="matMenu" xPosition="before">
		<button mat-menu-item (click)="openIntegrationLinksDialog(item)">
			<div class="menu-item">
				<mat-icon class="menu-item-icon" fontSet="material-icons-round">list_alt</mat-icon>
				<span i18n>Credentials</span>
			</div>
		</button>
	</mat-menu>
</ng-template>

<ng-template #emptyView>
	<cls-empty-state-template style="padding-bottom: 16px">
		<cls-empty-state-secondary-text i18n *ngIf="dataSrc.showAnalytics">
			Data is Getting Collected...
		</cls-empty-state-secondary-text>
		<cls-empty-state-primary-text i18n *ngIf="dataSrc.showAnalytics">
			Analytics Coming Soon
		</cls-empty-state-primary-text>
		<cls-empty-state-content>
			<disabled-analytics-view
				*ngIf="!dataSrc.showAnalytics"
				[isAdmin]="true"
				[integrationId]="integrationId"
				[platformType]="platformType"
				[scanSettingsId]="integrationItemData?.metaData?.defaultScanSettingsId"></disabled-analytics-view>
		</cls-empty-state-content>
	</cls-empty-state-template>
</ng-template>
