import { DEFAULT_DATABASE_COPYLEAKSDB_ID, DEFAULT_PROFILE_ID, DEFAULT_PROFILE_NAME } from './scan-profiles.consts';
import {
	EDetectionPreferences,
	EInternetScanTypes,
	EScanProfilesForm,
	EScanPropertiesDatabaseType,
} from './scan-profiles.enums';
import {
	IScanPropertieSensitiveDataProtection,
	IScanPropertiesCrossLanguages,
	IScanPropertiesExternalSources,
	IScanPropertiesFilter,
	IScanPropertiesInternalSources,
	IScanPropertiesInternalSourcesDatabase,
	IScanPropertiesMatchTypes,
	IScanPropertiesSearch,
} from './scan-profiles.interfaces';
export class ScanPropertiesProfile {
	constructor(
		public id: string,
		public name: string,
		public selected: boolean,
		public isAdvanced: boolean,
		public isPriceCheckMode: boolean,
		public isSandBox: boolean,
		public filters: IScanPropertiesFilter,
		public matchTypes: IScanPropertiesMatchTypes,
		public search: IScanPropertiesSearch,
		public detectionPreference: EDetectionPreferences,
		public externalSources: IScanPropertiesExternalSources,
		public internalSources: IScanPropertiesInternalSources,
		public sensitiveDataProtection: IScanPropertieSensitiveDataProtection,
		public crossLanguages: IScanPropertiesCrossLanguages,
		public aiDetection?: boolean,
		public organizationId?: string
	) {}
	public isInternalDatabaseEnabled() {
		const databases = this.internalSources.databases;
		const copyleaksDatabase = databases.find(i => i.type === EScanPropertiesDatabaseType.CopyleaksDB);
		if (copyleaksDatabase) {
			return copyleaksDatabase.includeOthersScans || copyleaksDatabase.includeUserScans || copyleaksDatabase.index;
		}

		return false;
	}
	public isRepositoriesEnabled() {
		const databases = this.internalSources.databases;
		const anyRepository = databases.find(this._isActiverepository.bind(this));
		if (anyRepository) {
			return anyRepository.includeOthersScans || anyRepository.includeUserScans;
		}

		return false;
	}
	public repositoriesCount() {
		return this.internalSources.databases.filter(this._isActiverepository.bind(this)).length;
	}
	private _isActiverepository(database: IScanPropertiesInternalSourcesDatabase) {
		return (
			database.type === EScanPropertiesDatabaseType.Repository &&
			(database.includeUserScans || database.includeOthersScans || database.index)
		);
	}

	public hasFilters(hiddenProperties: ScanFormProperties) {
		return (
			(this.filters.htmlTemplate && !hiddenProperties.hasFormCtrl(EScanProfilesForm.fcFilterHtmlTemplate)) ||
			(this.filters.quotes && !hiddenProperties.hasFormCtrl(EScanProfilesForm.fcFilterQuotes)) ||
			(this.filters.citations && !hiddenProperties.hasFormCtrl(EScanProfilesForm.fcFilterCitations)) ||
			(this.filters.references && !hiddenProperties.hasFormCtrl(EScanProfilesForm.fcFilterReferences)) ||
			(this.filters.tableOfContent && !hiddenProperties.hasFormCtrl(EScanProfilesForm.fcFilterTableOfContent)) ||
			(this.filters.code.comments && !hiddenProperties.hasFormCtrl(EScanProfilesForm.fcFilterCodeComments)) ||
			(this.filters.titles && !hiddenProperties.hasFormCtrl(EScanProfilesForm.fcFilterTitles))
		);
	}
}

export class DefaultScanPropertiesProfile extends ScanPropertiesProfile {
	constructor() {
		super(
			DEFAULT_PROFILE_ID,
			DEFAULT_PROFILE_NAME,
			false,
			false,
			false,
			false,
			{
				htmlTemplate: false,
				quotes: false,
				citations: false,
				references: false,
				tableOfContent: false,
				titles: false,
				code: {
					comments: false,
				},
			},
			{
				identicalCheck: true,
				minorChangedCheck: true,
				relatedMeaningCheck: true,
			},
			{
				sensitivityLevel: 3,
				minCopiedWords: null,
				cheatDetection: true,
			},
			EDetectionPreferences.MaxCoverage,
			{
				safeSearch: false,
				internet: {
					enabled: true,
					type: EInternetScanTypes.everywhere,
					selectedDomains: [],
					trustedDomains: [],
				},
			},
			{
				includeUploadedFiles: true,
				databases: [
					{
						id: DEFAULT_DATABASE_COPYLEAKSDB_ID,
						name: 'Copyleaks Database',
						index: false,
						includeUserScans: false,
						includeOthersScans: false,
						type: EScanPropertiesDatabaseType.CopyleaksDB,
					},
				],
			},
			{
				driversLicense: false,
				credentials: false,
				passport: false,
				network: false,
				url: false,
				emailAddress: false,
				creditCard: false,
				phoneNumber: false,
			},
			{
				languages: [],
			}
		);
	}
}

export class ScanFormProperties {
	constructor(public properties: EScanProfilesForm[] = []) {}
	hasFormCtrl(property: EScanProfilesForm) {
		return this.properties.includes(property);
	}
	hasFormCtrls(properties: EScanProfilesForm[]) {
		for (const property of properties) {
			if (!this.hasFormCtrl(property)) {
				return false;
			}
		}
		return true;
	}
	hasFormGroup(property: EScanProfilesForm) {
		switch (property) {
			case EScanProfilesForm.fgFilters:
				return this.hasFormCtrls([
					EScanProfilesForm.fcFilterReferences,
					EScanProfilesForm.fcFilterQuotes,
					EScanProfilesForm.fcFilterTitles,
					EScanProfilesForm.fcFilterTableOfContent,
					EScanProfilesForm.fcFilterHtmlTemplate,
					EScanProfilesForm.fcFilterCodeComments,
				]);
			case EScanProfilesForm.fgMatchTypes:
				return this.hasFormCtrls([
					EScanProfilesForm.fcMatchTypesIdentical,
					EScanProfilesForm.fcMatchTypesMinorChanged,
					EScanProfilesForm.fcMatchTypesRelatedMeaning,
				]);
			case EScanProfilesForm.fgSearch:
				return this.hasFormCtrls([EScanProfilesForm.fcSearchMinCopiedWords, EScanProfilesForm.fcSearchSensitivity]);
			case EScanProfilesForm.fgExternalSources:
				return this.hasFormCtrls([
					EScanProfilesForm.fcExternalSourcesSafeSearch,
					EScanProfilesForm.fgExternalSourcesInternet,
				]);
			case EScanProfilesForm.fgInternalSources:
				return this.hasFormCtrls([
					EScanProfilesForm.fcInternalSourcesUploadedFiles,
					EScanProfilesForm.fcInternalSourcesDatabases,
				]);
			case EScanProfilesForm.fgSensitiveDataProtection:
				return this.hasFormCtrls([
					EScanProfilesForm.fcSensitiveDataProtectionDriversLicense,
					EScanProfilesForm.fcSensitiveDataProtectionCredentials,
					EScanProfilesForm.fcSensitiveDataProtectionPassport,
					EScanProfilesForm.fcSensitiveDataProtectionNetwork,
					EScanProfilesForm.fcSensitiveDataProtectionUrl,
					EScanProfilesForm.fcSensitiveDataProtectionEmailAddress,
					EScanProfilesForm.fcSensitiveDataProtectionCreditCard,
					EScanProfilesForm.fcSensitiveDataProtectionPhoneNumber,
				]);
			default:
				return false;
		}
	}
}

export function isDefaultScanProfile(scanProfileId: string) {
	return scanProfileId == DEFAULT_PROFILE_ID;
}
