<mat-table full-width #databaseMatTable class="tabel-container" [dataSource]="dataSource">
	<!-- name -->
	<ng-container matColumnDef="name">
		<mat-header-cell *matHeaderCellDef>
			<mat-icon class="row-icon"></mat-icon>
			<ng-container i18n="@@NAME_4">Name</ng-container>
		</mat-header-cell>
		<mat-cell
			[attr.data-label]="'Name'"
			i18n-data-label
			*matCellDef="let element"
			[matTooltip]="tooltipPrivateREpository + element.name"
			i18n-matToolTip
			[matTooltipDisabled]="element.type === eScanPropertiesDatabaseType.CopyleaksDB">
			<mat-icon class="row-icon" *ngIf="element.type === eScanPropertiesDatabaseType.CopyleaksDB">dns</mat-icon>
			<mat-icon class="row-icon" *ngIf="element.type === eScanPropertiesDatabaseType.Repository">storage</mat-icon>
			<span
				style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden"
				[style.text-decoration]="validationsMap.get(element.id).isRemoved ? 'line-through' : ''">
				{{ element.name }}
			</span>
			<mat-icon
				i18n-matTooltip
				*ngIf="element.type === eScanPropertiesDatabaseType.CopyleaksDB"
				[inline]="true"
				matTooltip="Millions of user-submitted documents">
				help
			</mat-icon>
			<cls-lock-form-control
				[toGapIcon]="true"
				[isLocked]="isSourceLocked(element.id)"
				[formCtrlParent]="eScanPropertiesForm.fgInternalSources"
				[formCtrlNameToLock]="element.id">
				<cls-form-control-lock-item></cls-form-control-lock-item>
			</cls-lock-form-control>
		</mat-cell>
	</ng-container>
	<!-- includeUserScans -->
	<ng-container matColumnDef="includeUserScans">
		<mat-header-cell
			*matHeaderCellDef
			matTooltip="This will compare against any documents YOU previously uploaded."
			i18n-matTooltip
			i18n="@@COMPARE_TO_MY_SCANS_SP">
			Compare to
			<br />
			MY scans
		</mat-header-cell>
		<mat-cell [attr.data-label]="'Search My Scans'" *matCellDef="let element">
			<div
				class="table-checkbox-wrapper"
				[ngClass]="{
					'checkbox-error': validationsMap.get(element.id).isRemoved && element.includeUserScans
				}">
				<mat-checkbox
					aria-label="compare against any documents"
					matTooltip="This will compare against any documents YOU previously uploaded."
					i18n-matTooltip
					[(ngModel)]="element.includeUserScans"
					[disabled]="
						(!element.includeUserScans && validationsMap.get(element.id).isRemoved) || isSourceDisabled(element.id)
					"
					(change)="checkBoxChange(eDatabaseTableRowColumns.includeUserScans, element)"></mat-checkbox>
			</div>
		</mat-cell>
	</ng-container>
	<!-- includeOthersScans -->
	<ng-container matColumnDef="includeOthersScans">
		<mat-header-cell
			*matHeaderCellDef
			matTooltip="This will compare against ALL documents in the internal database."
			i18n-matTooltip
			i18n="@@COMPARE_TO_OTHERS_SCANS_SP">
			Compare to
			<br />
			OTHERS scans
		</mat-header-cell>
		<mat-cell [attr.data-label]="'Search Others Scans'" *matCellDef="let element">
			<div
				class="table-checkbox-wrapper"
				[ngClass]="{
					'checkbox-error': validationsMap.get(element.id).isRemoved && element.includeOthersScans
				}">
				<mat-checkbox
					aria-label="compare against ALL documents"
					matTooltip="This will compare against ALL documents in the internal database."
					i18n-matTooltip
					[(ngModel)]="element.includeOthersScans"
					[disabled]="
						(!element.includeOthersScans && validationsMap.get(element.id).isRemoved) || isSourceDisabled(element.id)
					"
					(change)="checkBoxChange(eDatabaseTableRowColumns.includeOthersScans, element)"></mat-checkbox>
			</div>
		</mat-cell>
	</ng-container>
	<!-- index -->
	<ng-container matColumnDef="index">
		<mat-header-cell
			cdkDrag
			*matHeaderCellDef
			matTooltip="Your scans will automatically be uploaded to the internal database."
			i18n-matTooltip
			i18n="@@ADD_TO_DATABASE">
			Add to
			<br />
			Database
		</mat-header-cell>
		<mat-cell [attr.data-label]="'Index'" *matCellDef="let element">
			<div
				class="table-checkbox-wrapper"
				[ngClass]="{
					'checkbox-error':
						!isRepositoriesLoading &&
						element?.type !== eScanPropertiesDatabaseType.CopyleaksDB &&
						element.index &&
						(!validationsMap.get(element.id).canIndex || validationsMap.get(element.id).isRemoved)
				}">
				<mat-checkbox
					aria-label="upload permissions"
					[(ngModel)]="element.index"
					[matTooltip]="
						element?.type == eScanPropertiesDatabaseType.CopyleaksDB || validationsMap.get(element.id).canIndex
							? tooltipScansUpload
							: tooltipScansNoPermission
					"
					[disabled]="
						element?.type == eScanPropertiesDatabaseType.CopyleaksDB ||
						(!element.index && validationsMap.get(element.id).isRemoved) ||
						(!element.index && !validationsMap.get(element.id).canIndex) ||
						isSourceDisabled(element.id)
					"
					(change)="checkBoxChange(eDatabaseTableRowColumns.Index, element)"></mat-checkbox>
			</div>
		</mat-cell>
	</ng-container>

	<!-- footer -->
	<ng-container matColumnDef="footer">
		<mat-footer-cell *matFooterCellDef class="footer">
			<ng-container *ngIf="isAdmin">
				<!--  -->
				<div *ngIf="!isRepositoriesLoading && !dataSource?.length">
					<span *ngIf="userBalance?.accountType !== ePaymentPlanType.SharedAccount" i18n>
						You have not created a repository yet.
					</span>
					<span *ngIf="userBalance?.accountType === ePaymentPlanType.SharedAccount" i18n>
						No repositories available.
					</span>
					<br />
				</div>
				<!--  -->
				<div
					class="add-repository"
					*ngIf="
						!isRepositoriesLoading &&
						!isGuestUser &&
						dataSource?.length > 1 &&
						userBalance?.accountType !== ePaymentPlanType.SharedAccount &&
						showAddRepositoryButton &&
						isOwner
					">
					<mat-icon style="margin-right: 5px" color="primary">add</mat-icon>
					<span mat-button type="button" (click)="addRepository.emit()" class="copyleaks-a-tag" i18n>
						Add Repository
					</span>
				</div>

				<div
					*ngIf="!isRepositoriesLoading && (isGuestUser || dataSource?.length <= 1) && showAddRepositoryButton"
					class="get-your-first-repository"
					(click)="addRepository.emit()">
					<img role="presentation" src="assets/add-repo.svg" />
					<div class="add-repo-btn">
						<ng-lottie [options]="options" class="crown-lottie"></ng-lottie>
						<span i18n>Get your first repository</span>
					</div>
				</div>

				<!--  -->
				<div *ngIf="isRepositoriesLoading" style="width: 100%">
					<cls-skeleton-loader [count]="5" [showLoader]="true"></cls-skeleton-loader>
				</div>
			</ng-container>
		</mat-footer-cell>
	</ng-container>

	<mat-header-row *matHeaderRowDef="columns; sticky: true"></mat-header-row>
	<mat-row
		class="table-row"
		*matRowDef="let row; columns: columns"
		[ngClass]="{ 'repository-removed': validationsMap.get(row.id).isRemoved }"></mat-row>
	<mat-footer-row *matFooterRowDef="['footer']"></mat-footer-row>
</mat-table>
