import {
	Directive,
	Renderer2,
	ElementRef,
	ComponentFactoryResolver,
	ViewContainerRef,
	ComponentRef,
	HostListener,
	Output,
	EventEmitter,
} from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
export interface ProgressButtonEvent {
	start: () => void;
	stop: () => void;
}
@Directive({
	selector: 'button[mat-button][progress-button],button[mat-raised-button][progress-button]',
})
export class ProgressButtonDirective {
	private _spinner: ComponentRef<MatProgressSpinner>;
	@Output('pbclick')
	pbclick = new EventEmitter<ProgressButtonEvent>();
	working = false;
	constructor(
		private el: ElementRef<HTMLButtonElement>,
		private host: MatButton,
		private renderer: Renderer2,
		vcref: ViewContainerRef,
		resolver: ComponentFactoryResolver
	) {
		const factory = resolver.resolveComponentFactory(MatProgressSpinner);
		this._spinner = vcref.createComponent(factory);
		this._spinner.instance.mode = 'indeterminate';
		this._spinner.instance.color = host.color;
		this._spinner.instance.diameter = 20;
		renderer.setStyle(this.el.nativeElement, 'display', 'flex');
		renderer.setStyle(this.el.nativeElement, 'align-items', 'center');
		renderer.setStyle(this.el.nativeElement, 'justify-content', 'center');
		renderer.setStyle(this._spinner.location.nativeElement, 'position', 'absolute');
		renderer.setStyle(this._spinner.location.nativeElement, 'display', 'none');
		renderer.appendChild(this.el.nativeElement, this._spinner.location.nativeElement);
	}

	start() {
		this.host.disabled = true;
		this.renderer.setStyle(this._spinner.location.nativeElement, 'display', 'block');
	}

	stop() {
		this.host.disabled = false;
		this.renderer.setStyle(this._spinner.location.nativeElement, 'display', 'none');
	}

	@HostListener('click', ['$event'])
	onButtonClicked(event: MouseEvent) {
		event.stopPropagation();
		this.pbclick.emit({ start: () => this.start(), stop: () => this.stop() });
	}
}
