import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LockFormControlComponent, LockFormControlItemComponent } from './lock-form-control.component';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LockFormControlService } from './service/lock-form-control.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
	declarations: [LockFormControlComponent, LockFormControlItemComponent],
	exports: [LockFormControlComponent, LockFormControlItemComponent],
	imports: [CommonModule, MatIconModule, FlexLayoutModule, MatTooltipModule, MatButtonModule],
	providers: [LockFormControlService],
})
export class LockFormControlModule {}
