<div class="purchase-more-credits-contianer" *ngIf="currentDialogStep == eNewPaymentDialogSteps.PurchaseMoreCredits">
	<app-billing-template-dialog
		class="purchase-more-credits-dialog"
		[billingTemplate]="billingTemplateDialog"
		(saveButtonEvent)="saveCheckout($event)"
		(closeButtonEvent)="closeDialog()"></app-billing-template-dialog>
</div>

<div class="main-container" *ngIf="currentDialogStep != eNewPaymentDialogSteps.PurchaseMoreCredits">
	<cls-choose-product-plan
		*ngIf="currentDialogStep == eNewPaymentDialogSteps.ChoosePlan"
		[currentSubscriptionPlan]="currentSubscriptionPlan"
		[isAnonymous]="dialogData.anonymousUser"
		[title]="dialogData?.title"
		[allProductsPaymentPlans]="allProductsPaymentPlans"
		(continue)="goToCheckout($event)"></cls-choose-product-plan>

	<!-- checkout step -->
	<div
		*ngIf="
			currentDialogStep == eNewPaymentDialogSteps.Checkout || currentDialogStep == eNewPaymentDialogSteps.Processing
		">
		<cls-payment-proccess
			[productType]="dialogData?.productType"
			[showPromoCodeInput]="true"
			[planId]="selectedPlan?.id"
			(succcefullyFinished)="onSucccefullyPaid()"
			(proccessing)="currentDialogStep = eNewPaymentDialogSteps.Processing"
			[initCoupon]="discountCode"
			[creditsPlan]="selectedPlan?.credits"
			[selectedPlanProduct]="selectedPlan?.platformProduct"></cls-payment-proccess>
	</div>
</div>
<button
	*ngIf="currentDialogStep == eNewPaymentDialogSteps.ChoosePlan"
	(click)="closeDialog()"
	class="close-dialog-btn"
	[disableRipple]="true"
	mat-icon-button>
	<mat-icon>close</mat-icon>
</button>
<button
	fxLayout="row"
	fxLayoutGap="4px"
	fxLayoutAlign="center center"
	*ngIf="currentDialogStep == eNewPaymentDialogSteps.Checkout && !hideBackButton"
	(click)="goToChoosePlan()"
	class="back-dialog-btn"
	[disableRipple]="true"
	mat-button>
	<mat-icon>chevron_left</mat-icon>
	<span>Back</span>
</button>
