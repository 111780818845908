import { Component, Input } from '@angular/core';
import { WindowService } from '../../../services/window.service';
import { ENotificationType } from '../web-notification';

@Component({
	selector: 'app-default-notification-item',
	templateUrl: './default-notification-item.component.html',
	styleUrls: ['./default-notification-item.component.scss'],
})
export class DefaultNotificationItemComponent {
	@Input() linkAddress: string;
	@Input() isRead: boolean;
	@Input() notificationType: ENotificationType;

	constructor(private _windowSvc: WindowService) {}

	public navigateToPage(): void {
		if (this.linkAddress) {
			if (this.linkAddress.includes('https://') || this.linkAddress.includes('http://')) {
				this._windowSvc.open(this.linkAddress, '_blank');
			}
		}
	}

	public checkIfLinkAddressIsNull(): boolean {
		if (this.linkAddress !== '' && this.linkAddress !== null && this.linkAddress !== undefined) return true;
		return false;
	}
}

@Component({
	selector: 'app-default-notification-description',
	template: '<ng-content></ng-content>',
})
export class DefaultNotificationDescriptionComponent {}

@Component({
	selector: 'app-default-notification-time',
	template: '<ng-content></ng-content>',
})
export class DefaultNotificationTimeComponent {}
