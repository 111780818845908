import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LmsWritingFeedbackScoreComponent } from './lms-writing-feedback-score.component';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
	declarations: [LmsWritingFeedbackScoreComponent],
	imports: [CommonModule, MatIconModule, NgxSkeletonLoaderModule, MatTooltipModule, MatButtonModule],
	exports: [LmsWritingFeedbackScoreComponent],
})
export class LMSWritingFeedbackScoreModule {}
