import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { SpinnerModule } from '../spinner/spinner.module';
import { PaymentMethodsComponent } from './payment-methods-component/payment-methods.component';

@NgModule({
	declarations: [PaymentMethodsComponent],
	exports: [PaymentMethodsComponent],
	imports: [CommonModule, FormsModule, FlexLayoutModule, SpinnerModule, MatButtonModule, MatRadioModule, MatIconModule],
})
export class PaymentMethodsModule {}
