import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ClsAlertsService } from '../cls-alerts/services/cls-alerts.service';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
	selector: 'cls-copy-link',
	templateUrl: './cls-copy-link.component.html',
	styleUrls: ['./cls-copy-link.component.scss'],
})
export class ClsCopyLinkComponent implements OnInit {
	/**
	 * The name of the link to copy.
	 * @Input
	 */
	@Input() linkName: string = '';

	/**
	 * The url of this link.
	 * @Input
	 */
	@Input() linkSrc: string;

	/**
	 * The length of the url text if the we want shorter version.
	 * @Input
	 */
	@Input() linkSrcLength: number = 50;

	/**
	 * Flag indicating whether to display the link as a secret/password,
	 * i.e. hide the content and only show if the view button is clicked.
	 * @Input
	 */
	@Input() addViewOption: boolean = false;

	linkSrcTrimmed: string;
	linkVisible: boolean = false;

	constructor(private _clipboard: Clipboard, private _clsAlertsService: ClsAlertsService) {}

	ngOnInit(): void {
		if (!this.linkName && this.linkSrc) {
			this.linkName = this.linkSrc;
		}
		if (this.addViewOption) this.linkSrcTrimmed = '*'.repeat(this.linkSrcLength);
		else this.linkSrcTrimmed = this.linkSrc;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.linkSrc) this.linkSrcTrimmed = this.linkSrc;
	}

	// copy to clipboard
	copyUrlToClipBorad(): void {
		this._clipboard.copy(this.linkSrc);
		this._clsAlertsService.showPrimary(
			$localize`Copied to clipboard!`,
			$localize`The URL was copied to your clipboard`,
			3000
		);
	}

	viewLink() {
		this.linkVisible = !this.linkVisible;
		if (this.linkVisible) this.linkSrcTrimmed = this.linkSrc;
		else this.linkSrcTrimmed = '*'.repeat(this.linkSrcLength);
	}
}
