<div
	class="mobile-full-width-container"
	fxLayout="row"
	fxLayout.lt-md="column"
	fxLayoutAlign="center center"
	fxLayoutAlign.lt-md="start start"
	fxLayoutGap="12px">
	<ng-container *ngIf="unHealtySubscription; else healtySubscription">
		<div class="uhealty-subscription" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
			<div fxLayout="row">
				<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
					<g clip-path="url(#clip0_3232_63)">
						<path
							d="M9.00009 3.74813L14.6476 13.5056H3.35259L9.00009 3.74813ZM2.05509 12.7556C1.47759 13.7531 2.19759 15.0056 3.35259 15.0056H14.6476C15.8026 15.0056 16.5226 13.7531 15.9451 12.7556L10.2976 2.99813C9.72009 2.00063 8.28009 2.00063 7.70259 2.99813L2.05509 12.7556ZM8.25009 7.50563V9.00563C8.25009 9.41813 8.58759 9.75563 9.00009 9.75563C9.41259 9.75563 9.75009 9.41813 9.75009 9.00563V7.50563C9.75009 7.09313 9.41259 6.75563 9.00009 6.75563C8.58759 6.75563 8.25009 7.09313 8.25009 7.50563ZM8.25009 11.2556H9.75009V12.7556H8.25009V11.2556Z"
							fill="#D04340" />
					</g>
					<defs>
						<clipPath id="clip0_3232_63">
							<rect width="18" height="18" fill="white" />
						</clipPath>
					</defs>
				</svg>
			</div>
			<span i18n>Payment problem</span>
		</div>
	</ng-container>
	<ng-template #healtySubscription>
		<div
			*ngIf="(!isMobile || isGuestUser || isUserAnOrganizationAdmin || isUserOnlyAnOrganizationMember) && canShowTag"
			class="user-plan-tag-container">
			<span>{{ getUserAccountType() }}</span>
		</div>
		<div
			*ngIf="
				!isGuestUser &&
				!isUserOnlyAnOrganizationMember &&
				!isUserAnOrganizationAdmin &&
				!userBalance?.pauseSubscription?.isPaused &&
				(pagesLeft != 0 || (isMobile && userBalance && userBalance?.accountType == ePaymentPlanType.Prepaid))
			"
			fxLayout="row"
			fxLayoutAlign="center center"
			fxLayoutAlign.lt-md="start center"
			fxLayoutGap="12px">
			<div
				*ngIf="pagesLeft != 0"
				class="user-plan-container"
				[matTooltip]="wordsTooltipTxt"
				i18n-matTooltip
				fxLayout="row"
				(click)="goToBilling()"
				fxLayoutAlign="center center"
				fxLayoutGap="4px">
				<cls-circular-progress-bar
					diameter="40"
					[isLoading]="isLoading"
					[currentAmount]="pagesLeft"
					[maxAmount]="planPages"></cls-circular-progress-bar>
				<div>
					<span class="pages-left-text" i18n>Credits left</span>
					<div *ngIf="isMobile && canShowTag" class="user-plan-tag-container">
						<span>{{ getUserAccountType() }}</span>
					</div>
				</div>
			</div>
			<div *ngIf="pagesLeft == 0 && isMobile && canShowTag" class="user-plan-tag-container">
				<span>{{ getUserAccountType() }}</span>
			</div>
			<div
				*ngIf="
					subscriptionState &&
					subscriptionState.refills.max &&
					!isUserOnlyAnOrganizationMember &&
					!isUserAnOrganizationAdmin &&
					!isCanceled
				"
				class="auto-refill-icon-container"
				fxLayoutAlign="center center">
				<mat-icon
					*ngIf="numberOfRefillsLeft <= 25"
					[matTooltip]="numberOfRefillsLeft > 0 ? numberOfRefillsLeft + ' refills left' : '0 refills left'"
					i18n-matTooltip
					[matBadge]="numberOfRefillsLeft"
					matBadgeSize="small"
					matBadgePosition="below after"
					matBadgeColor="peach">
					all_inclusive
				</mat-icon>
				<mat-icon
					matTooltip="Unlimited refills"
					i18n-matTooltip
					*ngIf="subscriptionState.refills.max - subscriptionState.refills.current > 25">
					all_inclusive
				</mat-icon>
			</div>
		</div>
		<div
			*ngIf="
				!isGuestUser &&
				!isUserOnlyAnOrganizationMember &&
				!isUserAnOrganizationAdmin &&
				userBalance &&
				(userBalance?.accountType == ePaymentPlanType.Trial ||
					(userBalance?.accountType == ePaymentPlanType.Subscription &&
						userBalance?.current == 0 &&
						!subscriptionState?.refills?.max))
			"
			id="upgrade-payment-dialog"
			fxLayout="row"
			[ngClass]="
				userBalance?.accountType == ePaymentPlanType.Trial && pagesLeft == 0
					? 'mobile-width-container'
					: 'mobile-full-width-container'
			"
			fxLayoutAlign="center center"
			fxLayoutGap="16px">
			<cls-image-button
				(click)="upgradePricingPlan($event)"
				*ngIf="userBalance?.accountType == ePaymentPlanType.Trial"
				class="user-plan-btn"
				[width]="isMobile ? '100%' : '153px'">
				<cls-button-image>
					<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M13.1531 7.6355C13.666 7.27341 14.001 6.67607 14.001 6.00049C14.001 4.89592 13.1055 4.00049 12.001 4.00049C10.8964 4.00049 10.001 4.89592 10.001 6.00049C10.001 6.69982 10.3599 7.31531 10.9036 7.67281C9.84466 9.34691 8.40096 11.8147 8.40096 12.8253C7.83778 11.4693 6.28214 10.5836 4.9844 10.0586C5.17624 9.75158 5.28711 9.3888 5.28711 9.00012C5.28711 7.89555 4.39168 7.00012 3.28711 7.00012C2.18254 7.00012 1.28711 7.89555 1.28711 9.00012C1.28711 10.1047 2.18254 11.0001 3.28711 11.0001C3.31361 11.0001 3.34 10.9996 3.36625 10.9986L5.00096 17.9995H19.001L20.6428 10.968C20.759 10.989 20.8787 11 21.001 11C22.1055 11 23.001 10.1046 23.001 9C23.001 7.89543 22.1055 7 21.001 7C19.8964 7 19.001 7.89543 19.001 9C19.001 9.35977 19.096 9.69736 19.2622 9.98904C17.9757 10.5038 16.3376 11.4075 15.6817 12.8253C15.6817 11.6225 14.2328 9.24225 13.1531 7.6355ZM5.07272 19.9995C5.0252 19.6702 5.00096 19.3361 5.00096 18.9995H12.001H19.001C19.001 19.3361 18.9767 19.6702 18.9292 19.9995H5.07272Z"
							fill="#FBFFFF" />
					</svg>
				</cls-button-image>
				<cls-button-text><span i18n>Upgrade</span></cls-button-text>
			</cls-image-button>
			<div
				*ngIf="
					userBalance &&
					userBalance?.accountType == ePaymentPlanType.Subscription &&
					userBalance?.current == 0 &&
					!subscriptionState?.refills?.max &&
					!isCanceled &&
					!isLoading
				"
				fxLayout="row"
				fxLayoutAlign="center center"
				fxLayoutGap="16px">
				<cls-button
					(click)="enableAutoRefill()"
					icon="all_inclusive"
					width="197px"
					class="user-plan-btn"
					[outlined]="true">
					<span i18n>Enable auto refill</span>
				</cls-button>
			</div>
		</div>
		<div
			*ngIf="userBalance?.pauseSubscription?.isPaused && userBalance?.accountType == ePaymentPlanType.Subscription"
			fxLayout="row"
			fxLayoutAlign="center center"
			fxLayoutAlign.lt-md="start center"
			fxLayoutGap="12px">
			<div
				class="user-plan-container"
				fxLayout="row"
				(click)="reactiveAccount()"
				fxLayoutAlign="center center"
				fxLayoutGap="4px">
				<mat-icon class="play-icon" fontSet="material-icons-round">play_arrow</mat-icon>
				<span class="pages-left-text" i18n style="line-height: 15px">Reactivate account</span>
			</div>
		</div>
	</ng-template>
</div>
