import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiEndpoints } from '../constants/auth.constants';
import { IScanPropertiesInternalSourcesDatabase } from '@ngx-common/components/scan-profiles/models/scan-profiles.interfaces';

export const MaxDaysToExpire = 30 * 12 * 2; // 2 years +-
export const MinDaysToExpire = 30;

@Injectable({
	providedIn: 'root',
})
export class InstituteService {
	readonly apiEndpoint = ApiEndpoints.Institutes;
	constructor(private http: HttpClient) {}

	getInstitutes() {
		return this.http.get<Institute[]>(this.apiEndpoint);
	}

	regenerateSecret(id: string) {
		return this.http.get<{ secret: string }>(`${this.apiEndpoint}/regenerate/${id}`);
	}

	getInstitue(id: string) {
		return this.http.get<Institute>(`${this.apiEndpoint}/${id}`);
	}
	addInstitute(institute: Institute) {
		return this.http.post<Institute>(this.apiEndpoint, institute);
	}

	editInstitute(institute: Institute) {
		const { id } = institute;
		return this.http.put<Institute>(`${this.apiEndpoint}/${id}`, institute);
	}

	deleteInstitute(instituteId: string) {
		return this.http.delete<Institute>(`${this.apiEndpoint}/${instituteId}`);
	}

	getLmsInstances(instituteId: string) {
		return this.http.get<LmsInstance[]>(`${this.apiEndpoint}/${instituteId}/instances`);
	}
}

export interface Institute {
	id?: string;
	userId?: string;
	secret: string;
	name: string;
	daysToExpire: number;
	scanInternalDatabase: boolean;
	generatePdfReport: boolean;
	ignoreReferences: boolean;
	ignoreQuotes: boolean;
	ignoreTitles: boolean;
	ignoreCodeComments: boolean;
	canStudentAccessReport: boolean;
	enableCheatDetection: boolean;
	enableDraftSubmission: boolean;
	repositories: IScanPropertiesInternalSourcesDatabase[];
}
export interface LmsInstance {
	id?: string;
	instituteId: string;
	name?: string;
	family?: string;
	version?: string;
	contactEmail?: string;
	isBeingDeleted: boolean;
	createdAt: Date | string;
}
