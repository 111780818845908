import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HighlightSearchedValuePipe } from './highlight-searched-value.pipe';

@NgModule({
	imports: [CommonModule],
	declarations: [HighlightSearchedValuePipe],
	exports: [HighlightSearchedValuePipe],
	providers: [HighlightSearchedValuePipe],
})
export class HighlightSearchedValuePipeModule {}
