import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'cls-mobile-bottom-item',
	templateUrl: './mobile-bottom-item.component.html',
	styleUrls: ['./mobile-bottom-item.component.scss'],
})
export class MobileBottomItemComponent implements OnInit {
	/**
	 * Replace icon with img src
	 * @Input
	 */
	@Input() iconAsImgSrc: string;

	/**
	 * Show image as active
	 * @Input
	 */
	@Input() activeIconAsImgSrc: string;

	/**
	 * Set link for the button
	 * @Input
	 */
	@Input() link: string;

	/**
	 * Set button active only for exact route
	 * @Input
	 */
	@Input() activeMatchExactRoute = false;

	/**
	 * Set notification badge above icon
	 * @Input
	 */
	@Input() unreadNotificationsCount: number = 0;

	get isActiveIconAsImgSrc() {
		return this._router.isActive(this.link, false);
	}

	constructor(private _router: Router) {}

	ngOnInit(): void {}
}

@Component({
	selector: 'cls-mobile-bottom-item-icon',
	template: '<ng-content></ng-content>',
	styleUrls: ['./mobile-bottom-item.component.scss'],
})
export class MobileBottomMenuIconComponent {}

@Component({
	selector: 'cls-mobile-bottom-item-text',
	template: '<ng-content></ng-content>',
	styleUrls: ['./mobile-bottom-item.component.scss'],
})
export class MobileBottomMenuTextComponent {}
