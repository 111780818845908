<div fxLayout="column" fxLayoutGap="30px">
	<ng-container *ngIf="institutes; else loading">
		<ng-container *ngIf="institutes.length; else empty">
			<institute-card
				[institute]="institute"
				*ngFor="let institute of institutes"
				(edit)="editInstitute($event)"
				(regenerate)="regenerateSecret($event)"
				(delete)="confirmDelete($event)"></institute-card>
		</ng-container>
		<ng-template #empty>
			<div style="text-align: center; margin-top: 20px" i18n>You have not setup any institutions yet...</div>
		</ng-template>
	</ng-container>
	<ng-template #loading>
		<div style="padding: 30px 0px">
			<mat-spinner [diameter]="70" class="center" mode="indeterminate" aria-label="waiting"></mat-spinner>
			<div style="text-align: center; margin-top: 20px" i18n>Loading... Please wait.</div>
		</div>
	</ng-template>
</div>
