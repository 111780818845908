import { ICardsData } from 'ngx-common-v2/components/analytics-components/templates/cls-results-insights-template/models/cls-card-data.model';
import { GeneralLMSIntegrationModel } from 'projects/Dashboard/src/app/pages/integrations/models/integrations.models';
import { IAnalyticsExternalResults } from 'ngx-common-v2/components/analytics-components/models/cls-external-results-table.models';
import {
	IChartDataByMonth,
	ITwoLineChart,
	IBarChart,
	IOneLineChart,
} from 'ngx-common-v2/components/analytics-components/models/cls-analytics.models';

export enum ESortByAssignmentsTable {
	NoSort = 0,
	AssignmentName = 1,
	CreatedDate,
	PlagiairsmScore,
	AIScore,
	DueDate,
	WritingFeedback,
}

export enum ESortByCoursesTable {
	NoSort = 0,
	StartDate,
}

export interface IDateRange {
	startYear: number;
	startMonth: number;
	endYear: number;
	endMonth: number;
}

export interface IBaseSortAndPaginateTable {
	amount: number;
	corsur: string;
	IsDescending: boolean;
	searchStr?: string;
	startFrom: number;
	dateRange: IDateRange;
}

export interface ISortAndPaginateAssignmentsTable extends IBaseSortAndPaginateTable {
	sortBy: ESortByAssignmentsTable;
}

export interface ISortAndPaginateCoursesTable extends IBaseSortAndPaginateTable {
	sortBy: ESortByCoursesTable;
}

export interface IAssignmentsTableDataBase {
	id: string;
	name: string;
	createdDate: Date;
	dueDate: Date;
	averageAI: number;
	averagePlagiarism: number;
	courseName: string;
	averageWritingFeedback: number;
}

export interface IAssignmentsTableData extends IAssignmentsTableDataBase {
	contextId: string;
}

export interface IAssignmentsResponseTableData {
	activities?: IAssignmentsTableData[];
	corsur: string;
}

export interface IContextsResponseModel {
	contexts: ICoursesTableData[];
	corsur: string;
	canLoadMore: boolean;
}

export interface ICoursesTableData {
	id: string;
	courseName: string;
	startDate: Date;
	lastModification: Date;
}

export interface IAnalyticsTotalCases {
	totalResults?: number;
	aiCases?: number;
	plagiarismCases?: number;
	cheatDetectionCases?: number;
	crossLanguageCases?: number;
	totalScans?: number;
	writingFeedback?: number;
}

export interface IAnalyticsIntegrationData {
	defaultScanSettingsId: string;
	integrationName: string;
	createdAt: Date;
	deleteDate?: Date;
	lmsFamily: string;
	scanIntegrationId: string;
	registrationUrl: string;
	key: string;
	secret: string;
}

export interface IIntegrationAnalyticsResponseOverview {
	originalityPlagiarism: IChartDataByMonth[];
	originalityAI: IChartDataByMonth[];
	submittedScans: IChartDataByMonth[];
	result: IChartDataByMonth[];
	crossLanguages: IChartDataByMonth[];
	cheatsDetections: IChartDataByMonth[];
	externalResults: IAnalyticsExternalResults[];
	analyticsTotalsCases: IAnalyticsTotalCases;
	integrationData: IAnalyticsIntegrationData;
	startAnalyticsDate: Date;
	isStatisticsExists: boolean;
	hideContexts: boolean;
	showAnalytics: boolean;
	writingFeedback: IChartDataByMonth[];
	writingFeedbackFeatureEnabled: boolean;
}

export interface IIntegrationLevelOverview {
	integrationName: string;
	externalResults: IAnalyticsExternalResults[];
	cardsData: ICardsData;
	originalityData: ITwoLineChart;
	submittedScansData: IBarChart;
	resultsData: IBarChart;
	crossLanguageData: IOneLineChart;
	cheatsFoundData: IOneLineChart;
	integrationData: GeneralLMSIntegrationModel;
	startAnalyticsDate: Date;
	isStatisticsExists: boolean;
	hideContexts: boolean;
	showAnalytics: boolean;
	writingFeedback: IBarChart;
	writingFeedbackFeatureEnabled: boolean;
}
