<div class="container">
	<button (click)="closeTemplate()" class="close-btn" fxLayoutAlign="center center" mat-icon-button>
		<mat-icon>close</mat-icon>
	</button>
	<div fxLayout="row" fxLayoutGap="64px" fxLayout.lt-md="column" fxLayoutGap.lt-md="12px">
		<div class="description-contaier" fxLayout="column" fxLayoutGap="16px">
			<span class="description-title">{{ billingTemplate?.title }}</span>
			<div class="description-content" fxLayout="column">
				<span>
					{{ billingTemplate?.firstDescription }}
				</span>
				<ng-container *ngIf="billingTemplate?.secondDescription">
					<br />
					<span>
						{{ billingTemplate?.secondDescription }}
					</span>
				</ng-container>
			</div>
		</div>

		<mat-divider class="mat-divider" [vertical]="!isMobile"></mat-divider>

		<div class="select-option-top-container" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="16px">
			<div fxLayout="column" fxLayoutGap="8px">
				<div class="select-option-container" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
					<span class="select-title">{{ billingTemplate?.selectOptionTitle }}</span>
					<div class="value-container" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="4px">
						<div class="select-value-container">
							<span class="value">{{ billingTemplate?.selectedOption }}</span>
						</div>

						<button class="expand-more" mat-icon-button [matMenuTriggerFor]="menu">
							<mat-icon>expand_more</mat-icon>
						</button>
					</div>
				</div>
				<span class="select-option-description" *ngIf="billingTemplate?.selectOptionDescription">
					{{ billingTemplate?.selectOptionDescription }}
				</span>
			</div>

			<div fxLayout="row" fxLayoutGap="8px">
				<button
					*ngIf="billingTemplate?.showCancelButton"
					class="cancel-button"
					(click)="closeTemplate()"
					mat-button
					i18n>
					Cancel
				</button>
				<cls-button class="save-button" (click)="saveTemplate()">
					<span>{{ billingTemplate?.saveButtonText }}</span>
				</cls-button>
			</div>
		</div>
	</div>
</div>
<mat-menu #menu="matMenu" class="mat-menu-container-billing-template">
	<button
		(click)="selectOption(option)"
		*ngFor="let option of billingTemplate?.options"
		mat-menu-item
		[disabled]="isDisabledOption(option)"
		[ngClass]="{ 'custom-disabled': isDisabledOption(option) }">
		{{ option }}
	</button>
</mat-menu>
