import {
	Component,
	EventEmitter,
	Inject,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ClsAlertsService } from '@ngx-common-v2/components/cls-alerts/services/cls-alerts.service';
import { filter } from 'rxjs/operators';
import {
	ChoosePaymentMethodComponent,
	ChoosePaymentMethodExportedData,
} from '../../components/choose-payment-method/choose-payment-method.component';
import { EUserBillingStatus, PaymentMethodsService } from '../../components/payment-methods/payment-methods.service';
import { PaymentComponent, PaymentStatusResult } from '../../components/payment/payment-component/payment.component';
import { SIB_EVENTS } from '../../constants/sendinblue-analytics.consts';
import {
	CreditCardDialogComponent,
	ICreditCardDialog,
} from '../../dialogs/credit-card-dialog/credit-card-dialog.component';
import { EPaymentPlanType } from '../../enums/payment-plan-type.enum';
import { EProductType } from '../../enums/product-type.enums';
import { EPlatformProductType, PricingPlan } from '../../models/pricing.model';
import { ECopyleaksAPP, NgxCommonPagesConfig } from '../../pages/pages.config';
import { AppDirectionalityService } from '../../services/app-directionality.service';
import { AuthService } from '../../services/auth.service';
import { BillingService } from '../../services/billing.service';
import { IItem } from '../../services/google-analytics.service';
import { LockoutActivateService } from '../../services/lockout-activate.service';
import {
	ESubscriptionBillingStatus,
	IUserSubscriptionState,
	PaymentPlanService,
} from '../../services/payment-plan.service';
import { PricingService } from '../../services/pricing-plans.service';
import { ScreenService } from '../../services/screen.service';
import { SendInBlueAnalyticsService } from '../../services/send-in-blue-analytics.service';
import { UrlLocationService } from '../../services/url-location.service';
import { BillingAddressComponent } from '../billing-address/billing-address/billing-address.component';
import {
	UnPauseSubscriptionDialogComponent,
	IUnPauseSubscriptionDialogDialogModel,
} from '../../dialogs/unpause-subscirption-dialog/unpause-subscirption-dialog.component';

@UntilDestroy()
@Component({
	selector: 'cls-payment-checkout',
	templateUrl: './payment-checkout.component.html',
	styleUrls: ['./payment-checkout.component.scss'],
})
export class PaymentCheckoutComponent implements OnInit, OnChanges {
	@ViewChild('choosePaymentMethodRef') choosePaymentMethodRef: ChoosePaymentMethodComponent;
	@ViewChild('paymentRef') paymentRef: PaymentComponent;
	@ViewChild('billingAdressRef') billingAdressRef: BillingAddressComponent;

	@Input() repositoryId: string;
	@Input() horizontalMode: boolean = true;
	@Input() productType: EProductType;
	@Input() selectedPlatformProduct: EPlatformProductType;
	@Input() showTitle = true;
	@Input() planId: string;
	@Input() creditsPlan: number;
	@Input() showPromoCodeInput = true;
	@Input() initCoupon = '';
	@Input() isCheckOutPage: boolean;
	@Output() confirmPayment = new EventEmitter<PaymentStatusResult>();

	showCC = true;
	paymentMethodsFinishedInit = false;
	hasPaymentMethods: boolean;
	selectedPlan: PricingPlan;
	paymentDetails: IPaymentDetails;
	billingAddressCtrl: FormControl;
	_isUserPaused: boolean;

	eUserBillingStatus = EUserBillingStatus;
	// This will be usful for later.
	choosePaymentMethodData: ChoosePaymentMethodExportedData;
	formSubmittedCreditCard = false;
	errMsg: string;
	subscriptionState: IUserSubscriptionState;
	eSubscriptionBillingStatus = ESubscriptionBillingStatus;
	failedToInitPayment: boolean;
	validAddress: boolean = false;

	get isMobile() {
		return this._screenSvc.isMobile();
	}

	constructor(
		private _authSvc: AuthService,
		private _screenSvc: ScreenService,
		private _billingSvc: BillingService,
		private _paymentMethodSvc: PaymentMethodsService,
		private _lockoutActiveSvc: LockoutActivateService,
		private _paymentPlanSvc: PaymentPlanService,
		private _pricingService: PricingService,
		private _clsAlertsSvc: ClsAlertsService,
		private _matDialogSvc: MatDialog,
		private _formBuilder: FormBuilder,
		private _route: Router,
		private _activatedRoute: ActivatedRoute,
		private readonly _dir: AppDirectionalityService,
		private _sendInBlueSvc: SendInBlueAnalyticsService,
		private _urlLocationSvc: UrlLocationService,
		@Inject(NgxCommonPagesConfig.key) private _config: NgxCommonPagesConfig
	) {}

	async ngOnInit() {
		this.billingAddressCtrl = this._formBuilder.control(null, Validators.required);
		this.billingAddressCtrl.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
			this.validAddress = false;
		});
		this._paymentMethodSvc
			.getDefaultPaymentMethod()
			.pipe(untilDestroyed(this))
			.subscribe(paymentMethods => {
				this.hasPaymentMethods = !!paymentMethods;
			});

		this._paymentPlanSvc.userBalance$.pipe(untilDestroyed(this)).subscribe(balance => {
			if (balance) {
				this._isUserPaused = balance?.pauseSubscription?.isPaused;
				if (this.isUserSubsIsPaused()) {
					return;
				}
			}
		});

		this._authSvc.user$.pipe(untilDestroyed(this)).subscribe(user => {
			if (user && this._authSvc.isLoggedIn() && this._config.APP != ECopyleaksAPP.Admin) {
				this._paymentPlanSvc.updateUserBalance(true, true, true);
				this._paymentPlanSvc.userBalance$
					.pipe(
						untilDestroyed(this),
						filter(balance => !!balance)
					)
					.subscribe(async balance => {
						this._isUserPaused = balance?.pauseSubscription?.isPaused;
						if (balance.accountType === EPaymentPlanType.SharedAccount) this._route.navigate[`/dashboard/v1/`];
						else {
							this._initSelectedPlan();
							if (balance.accountType === EPaymentPlanType.Subscription && this._config.APP != ECopyleaksAPP.Identity) {
								await this._initSubscriptionStatue();
							} else {
								this.paymentMethodsFinishedInit = true;
							}
						}
					});
			} else if (this._config.APP == ECopyleaksAPP.Admin) {
				this._initSelectedPlan();
				this.paymentMethodsFinishedInit = true;
			}
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.planId) {
			this._initSelectedPlan();
		}
	}

	private async _initSubscriptionStatue() {
		try {
			this.subscriptionState = await this._paymentPlanSvc.getSubscriptionState(this.productType);
			this.paymentMethodsFinishedInit = true;
		} catch (error) {
			this._clsAlertsSvc.showHttpResponseError(error);
		}
	}

	private async _initSelectedPlan() {
		try {
			let plans: PricingPlan[];
			plans = await this._pricingService.mapPlansToPricingPlans(this.productType);
			if (this.productType == EProductType.Repositories) {
				this.selectedPlan = this.planId
					? plans.find(p => p.id === this.planId)
					: plans.find(p => p.credits === this.creditsPlan);
			} else {
				this.selectedPlan = this.planId
					? plans.find(p => p.id === this.planId)
					: plans.find(p => p.credits === this.creditsPlan && p.platformProduct == this.selectedPlatformProduct);
				if (!this.selectedPlatformProduct) {
					this.selectedPlatformProduct = this.selectedPlan?.platformProduct;
				}
			}
			if (!this.selectedPlan) {
				this.selectedPlan = await this._pricingService.getDefaultPlan(this.productType);
				this._route.navigate([], {
					relativeTo: this._activatedRoute,
					queryParams: { planId: this.selectedPlan.id },
					queryParamsHandling: 'merge',
				});
			}
			const isMonthlyPayment = this.selectedPlan.interval.months !== 12;
			this.paymentDetails = {
				monthlyPlan: isMonthlyPayment
					? this.selectedPlan
					: await this._pricingService.getMonthlyPlanByCredits(
							this.selectedPlan.credits,
							this.productType,
							this.selectedPlan.platformProduct
					  ),
				yearlyPlan: isMonthlyPayment
					? await this._pricingService.getYearlyPlanByCredits(
							this.selectedPlan.credits,
							this.productType,
							this.selectedPlan.platformProduct
					  )
					: this.selectedPlan,
				countryCode: this._authSvc.user().countryCode,
				isMonthlyPayment,
			};
			this.sendSendInBlueEvent(this.paymentDetails);
		} catch (error) {
			console.log(error);
			this._clsAlertsSvc.showHttpResponseError(error);
		}
	}

	sendSendInBlueEvent(paymentDetails: IPaymentDetails) {
		const selectedPlanDetails = paymentDetails.isMonthlyPayment
			? paymentDetails.monthlyPlan
			: paymentDetails.yearlyPlan;
		const item: IItem = {
			id: `${selectedPlanDetails.id}`,
			name: `${selectedPlanDetails.credits} Credits,  ${
				!paymentDetails.isMonthlyPayment ? 'Yearly' : 'Monthly'
			} Subscription`,
			location: location?.href,
			category: !paymentDetails.isMonthlyPayment ? 'yearly' : 'monthly',
			yearly: !paymentDetails.isMonthlyPayment,
			price: `${selectedPlanDetails.price}`,
			app: this.getCurrentWebsiteString(),
		};
		this._sendInBlueSvc.onTrackEventEmit(
			SIB_EVENTS.ADD_TO_CART,
			item,
			null,
			this.initCoupon,
			null,
			this._urlLocationSvc.location.hostname
		);
	}

	onChoosePaymentMethod($event: ChoosePaymentMethodExportedData) {
		this.choosePaymentMethodData = $event;
	}

	async confirmPaymentBtnClicked($event) {
		try {
			this.billingAdressRef.form.markAllAsTouched();
			if (!this.billingAddressCtrl.value || !this.billingAddressCtrl.valid) {
				this.billingAdressRef.validateAllFormFields(this.billingAdressRef.form);
				return;
			}
			this.errMsg = '';
			if (this.subscriptionState?.billingStatus === ESubscriptionBillingStatus.UnHealthy) {
				this.UpdatePaymentMethod();
			} else if (this.subscriptionState?.billingStatus === ESubscriptionBillingStatus.PaymentLockedOut) {
				const url = await this._lockoutActiveSvc.getActivteUrl();
				window?.open(url, '_blank');
			} else {
				this.formSubmittedCreditCard = true;
				this.paymentRef.showConfirmPaymentBtnSpinner = true;
				await this.choosePaymentMethodRef.creditCardRef?.valueChange();

				setTimeout(() => {
					if (
						this.choosePaymentMethodData.newPaymentMethod &&
						this.choosePaymentMethodRef.creditCardRef.validate(null)
					) {
						this.paymentRef.showConfirmPaymentBtnSpinner = false;
					} else {
						this.paymentRef.saveUserDetailsWithCaptcha();
					}
					if (!this.choosePaymentMethodData.newPaymentMethod && !this.choosePaymentMethodData.isOldPaymentMethod)
						this.formSubmittedCreditCard = false;
				}, 100);
			}
		} catch (err) {
			console.log(err);
			this._clsAlertsSvc.showHttpResponseError(err);
		}
	}

	onConfirmPayment($event: PaymentStatusResult) {
		this.errMsg = '';
		if (!$event.isSuccess) {
			if (!$event.errorMsg) {
				$event.errorMsg = 'The payment has failed, please try again.';
			}
			this.choosePaymentMethodRef.clear();
			if (typeof $event.errorMsg === 'string') {
				this.errMsg = $event.errorMsg;
				this.formSubmittedCreditCard = false;
			}
		}
		this.confirmPayment.emit($event);
	}

	UpdatePaymentMethod() {
		this.errMsg = '';
		this._matDialogSvc.open(CreditCardDialogComponent, {
			width: '500px',
			direction: this._dir.value,
			data: {
				onSucess: async () => {
					this.paymentMethodsFinishedInit = false;
					setTimeout(async () => {
						this._paymentMethodSvc.clearDefaultPaymentMethod();
						await this._paymentMethodSvc.getDefaultPaymentMethod().toPromise();
						this.paymentMethodsFinishedInit = true;
					}, 2000);
				},
			} as ICreditCardDialog,
		});
	}

	isUserSubsIsPaused() {
		/*if user subs is paused open a dialog to reactive*/
		if (this._isUserPaused) {
			const dialog = this._matDialogSvc.open(UnPauseSubscriptionDialogComponent, {
				autoFocus: false,
				width: '481px',
				panelClass: 'custom-dialog',
				direction: this._dir.value,
				disableClose: true,
				data: {
					title: $localize`Ready to pick up where you left off?`,
					description: $localize`Reactivate your account today and get access to additional features and full reporting.`,
					btnSubmitText: $localize`Reactivate account`,
					disableClose: true,
				} as IUnPauseSubscriptionDialogDialogModel,
			});
			return true;
		}
		return false;
	}

	getCurrentWebsiteString() {
		switch (this._config?.APP) {
			case ECopyleaksAPP.MainWebsite:
				return 'APP';
			case ECopyleaksAPP.Admin:
				return 'Admin';
			case ECopyleaksAPP.API:
				return 'API';
			case ECopyleaksAPP.LMS:
				return 'LMS';
			case ECopyleaksAPP.Identity:
				return 'ID';
			default:
				return '';
		}
	}

	cancelUpdateCC() {
		// its for reload the component
		this.showCC = false;
		setTimeout(() => {
			this.showCC = true;
		}, 100);
	}

	updateErrorMessage($event) {
		this.errMsg = $event;
	}

	back() {
		this._urlLocationSvc.location.href = 'https://copyleaks.com/pricing';
	}
}

interface IPaymentDetails {
	isMonthlyPayment: boolean;
	monthlyPlan: PricingPlan;
	yearlyPlan: PricingPlan;
	countryCode: string;
}
