export const GTAG_CONSTS = {
	EVENT: 'event',
	PAGE_VIEW: 'page_view',
	CONTENT_TYPE: {
		PRODUCT: 'product',
	},
};
export enum GA_EVENT_CATEGORY {
	UX = 'UX',
	TRY = 'Try',
	UX_CS = 'UX - CS',
	UX_GA = 'UX - GA',
	UX_CANCELLATION_FLOW = 'UX - Cancelation Flow',
}
export enum GA_EVENTS {
	SELECT_CONTENT = 'select_content',
	VIEW_ITEM = 'view_item',
	ADD_TO_CART = 'add_to_cart',
	BEGIN_CHECKOUT = 'begin_checkout',
	PURCHASE = 'purchase',
}
export enum GA_EVENT_ACTIONS {
	ACCOUNT_CREATION = 'Account Creation',
	CONTACTED_SUPPORT = 'Contacted Support',
	SCHEDULED_A_CALL_FROM_CONTACT_US = 'Scheduled a call from contact us',
	SCHEDULED_A_CALL_FROM_PRICING = 'Scheduled a call from pricing page',
	ACCOUNT_ACTIVATION = 'Account Activation',
	SETTINGS = 'Settings',
	ACCOUNT_LOGIN = 'Account Login',
	YEARLY_DISCOUNT_CLICKED = 'Yearly discount clicked',
	GROUPS = 'Groups',
	GROUPS_INVITATIONS = 'Groups invitations',
	REPOSITORIES = 'Repositories',
	INDEX_SCANS = 'Index Files',
	PURCHASE = 'Purchase',
	CONFIRMATION_DIALOG = 'Confirmation Dialog',
	AUTO_REFILL_ENABLED = 'auto_refill_enabled',

	SUBMITTED_CONTACT = 'Submitted Contact Info',
	CONFIRMATION = 'Confirmation',
	BILLING_CANCEL = 'Billing - Cancel Subscription',
}
export enum GA_EVENT_LABELS {
	CREATED_WHILE_PAYMENT = 'Created while payment',
	CALENDRY = 'Calendry',
	REFER_A_FRIEND = 'Refer a friend',
	REGULAR_ACTIVATION = 'Regular Activation',
	GROUP_INVITATION = 'Team invitation',
	EMAIL_CHANGED = 'Email changed',
	LOGIN_PAGE = 'Logged in from login page',
	YEARLY_DISCOUNT = 'Yearly Discount',
	TRY_FOR_FREE = 'Homepage - try for free',
	CREATED = 'Created',
	SEND = 'Send',
	USER_INVITED = 'User invited',
	PERSONAL_INFO = 'Personal Information edited',
	EDITED = 'Repository Edited',
	INDEX_SCANS = 'Index Files',
	REMOVE_REPOSITORY = 'Repository Removed',
	REMOVE_DOCUMENTS = 'Repository Documents Removed',
	DESTORYED_ACCOUNT = 'Destroyed account',
	PASSWORD_CHANGED = 'Password changed',
	REFILL = 'Refill',
	CONFIRMATION_DIALOG_YES = 'Yes',
	GUIDE_STARTED = 'Guide Started',
	GUIDE_ENDED = 'Guide Ended',
	CONTINUE = 'Continue',
	CANCEL = 'Cancel',
}
