<a [href]="checkIfLinkAddressIsNull() ? linkAddress : ''" class="copyleaks-a-tag-black" target="_blank">
	<div class="web-notifiction-container" [ngClass]="{ noHover: !checkIfLinkAddressIsNull() }">
		<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 20px">
			<img src="assets/websites-icons/300px.png" />
			<div fxLayout="column" fxFlex="290px">
				<span class="web-notification-text">
					<ng-content select="app-default-notification-description"></ng-content>
				</span>
				<span class="web-notification-time">
					<ng-content select="app-default-notification-time"></ng-content>
				</span>
			</div>
			<div fxLayout="column" fxLayoutAlign="center center">
				<mat-icon class="fiber_manual_record" color="primary" *ngIf="!isRead">fiber_manual_record</mat-icon>
			</div>
		</div>
	</div>
</a>
