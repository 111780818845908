import { Component, Inject, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AllPersonalNotificationsService } from '../../../../../notifications/services/all-personal-notifications.service';
import { NgxCommonPagesConfig } from '../../../../../pages/pages.config';
import { BaseToolbarMenuComponent } from '../base-toolbar-menu-componenet';
import { Router } from '@angular/router';

@UntilDestroy()
@Component({
	selector: 'cls-notifcations-toolbar-menu',
	templateUrl: './notifcations-toolbar-menu.component.html',
	styleUrls: ['./notifcations-toolbar-menu.component.scss'],
})
export class NotifcationsToolbarMenuComponent extends BaseToolbarMenuComponent implements OnInit {
	unreadNotificationsCount: number;
	constructor(
		router: Router,
		private _notificationsSvc: AllPersonalNotificationsService,
		@Inject(NgxCommonPagesConfig.key) private _config: NgxCommonPagesConfig
	) {
		super(router);
	}

	ngOnInit(): void {
		this.app = this._config.APP;
		this._checkUnreadNotifications();
	}

	private _checkUnreadNotifications() {
		this._notificationsSvc.notifications$.pipe(untilDestroyed(this)).subscribe(data => {
			this.unreadNotificationsCount = 0;
			data.forEach(notification => {
				if (!notification.isRead) {
					this.unreadNotificationsCount++;
				}
			});
		});
	}

	async notificationsMenuClosedAsync() {
		if (this.unreadNotificationsCount) {
			await this._notificationsSvc.markAllNotificationsAsRead();
			this.unreadNotificationsCount = 0;
		}
	}

	changeUnreadItemsToZero() {}
}
