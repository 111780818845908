<div fxLayout="row wrap" class="cards-full-container" fxLayoutGap="16px">
	<ng-container [ngSwitch]="analyticsLevel">
		<!-- Integration Level -->
		<ng-container *ngSwitchCase="eLevelType.Integration">
			<ng-container *ngTemplateOutlet="TotalScans"></ng-container>
			<ng-container *ngTemplateOutlet="plagiarismCases"></ng-container>
			<ng-container *ngTemplateOutlet="AiTextCases"></ng-container>
			<ng-container *ngTemplateOutlet="TotalResults"></ng-container>
			<ng-container *ngTemplateOutlet="AvgWriting"></ng-container>
			<ng-container *ngTemplateOutlet="CheatDetection"></ng-container>
			<ng-container *ngTemplateOutlet="CrossLanguage"></ng-container>
		</ng-container>
		<!-- Context Level -->
		<ng-container *ngSwitchCase="eLevelType.Context">
			<ng-container *ngTemplateOutlet="ContextAndActivityTempalte"></ng-container>
		</ng-container>
		<!-- Activity Level -->
		<ng-container *ngSwitchCase="eLevelType.Activity">
			<ng-container *ngTemplateOutlet="ContextAndActivityTempalte"></ng-container>
		</ng-container>
		<!-- Author Level -->
		<ng-container *ngSwitchCase="eLevelType.Author">
			<div fxFlex fxLayout="column" fxLayoutGap="16px">
				<div fxFlex fxLayout="column" fxLayoutGap="16px" class="author-level-cards-height">
					<div fxFlex fxLayout="row-warp" fxLayoutGap="16px">
						<ng-container *ngTemplateOutlet="TotalScans"></ng-container>
						<ng-container *ngTemplateOutlet="plagiarismCases"></ng-container>
					</div>
					<div fxFlex fxLayout="row-warp" fxLayoutGap="16px">
						<ng-container *ngTemplateOutlet="CheatDetection"></ng-container>
						<ng-container *ngTemplateOutlet="CrossLanguage"></ng-container>
					</div>
				</div>
				<ng-container *ngTemplateOutlet="AiTextCases"></ng-container>
				<ng-container *ngTemplateOutlet="AvgWriting"></ng-container>
			</div>
		</ng-container>

		<ng-container *ngSwitchDefault></ng-container>
	</ng-container>
</div>

<ng-template #ContextAndActivityTempalte>
	<ng-container *ngTemplateOutlet="TotalScans"></ng-container>
	<ng-container *ngTemplateOutlet="AvgWriting"></ng-container>
	<ng-container *ngTemplateOutlet="AiTextCases"></ng-container>
	<ng-container *ngTemplateOutlet="CrossLanguage"></ng-container>
	<ng-container *ngTemplateOutlet="plagiarismCases"></ng-container>
</ng-template>

<!-- Total Scans Card -->
<ng-template #TotalScans>
	<cls-info-card
		[ngClass]="{ 'info-card-container': true, 'info-card-container-with-focus': isWithScroll }"
		[showIcon]="false"
		[style.cursor]="isWithScroll ? 'pointer' : 'default'"
		[isLoading]="isLoading"
		cardHeight="100%"
		(click)="isWithScroll ? scrollTo(eGraphToScroll.SubmittedScans) : ''"
		[tabIndex]="isWithScroll ? 0 : -1"
		(keyup.enter)="isWithScroll ? scrollTo(eGraphToScroll.SubmittedScans) : ''">
		<cls-info-card-title>
			<div class="card-title">
				{{ cardsDataSrc?.totalScans.value | number : '1.0-0' }}
			</div>
		</cls-info-card-title>
		<cls-info-card-description>
			<div fxLayout="row wrap" fxLayoutAlign="center">
				<div class="card-description" i18n>{{ cardsDataSrc?.totalScans?.title }}</div>
			</div>
		</cls-info-card-description>
	</cls-info-card>
</ng-template>

<!-- Total Matched Text Results Card -->
<ng-template #TotalResults>
	<div
		fxFlex
		[ngClass]="{ 'info-card-container': true, 'info-card-container-with-focus': isWithScroll }"
		(click)="isWithScroll ? scrollTo(eGraphToScroll.Results) : ''"
		[tabIndex]="isWithScroll ? 0 : -1"
		(keyup.enter)="isWithScroll ? scrollTo(eGraphToScroll.Results) : ''">
		<a
			mat-icon
			[href]="infoLinkBase + 'results'"
			target="_blank"
			aria-label="click to find more."
			i18n-matTooltip
			matTooltip="Since data can come from various sources, scans may include multiple unique results. ">
			<mat-icon class="info-icon info-link-icon">info_outline</mat-icon>
		</a>
		<cls-info-card
			[style.cursor]="isWithScroll ? 'pointer' : 'default'"
			[showIcon]="false"
			[isLoading]="isLoading"
			cardHeight="100%">
			<cls-info-card-title>
				<div class="card-title">
					{{ cardsDataSrc?.totalResults.value | number : '1.0-0' }}
				</div>
			</cls-info-card-title>
			<cls-info-card-description>
				<div class="card-description" i18n>{{ cardsDataSrc?.totalResults?.title }}</div>
			</cls-info-card-description>
		</cls-info-card>
	</div>
</ng-template>

<!-- Avg. Writing correction score -->
<ng-template #AvgWriting>
	<div
		fxFlex
		[ngClass]="{ 'info-card-container': true, 'info-card-container-with-focus': isWithScroll }"
		(click)="isWithScroll ? scrollTo(eGraphToScroll.WritingCorrections) : ''"
		[tabIndex]="isWithScroll ? 0 : -1"
		(keyup.enter)="isWithScroll ? scrollTo(eGraphToScroll.WritingCorrections) : ''">
		<mat-icon class="info-icon" i18n-matTooltip matTooltip="Number of total writing corrections made in scans.">
			info_outline
		</mat-icon>
		<cls-info-card
			[style.cursor]="isWithScroll ? 'pointer' : 'default'"
			[showIcon]="false"
			[isLoading]="isLoading"
			cardHeight="100%">
			<cls-info-card-title>
				<div *ngIf="cardsDataSrc?.avgWritingFeedback.isDisabled" class="card-title card-title-disabled">
					{{ cardsDataSrc?.avgWritingFeedback.value }}
				</div>
				<div *ngIf="!cardsDataSrc?.avgWritingFeedback.isDisabled" class="card-title">
					{{ cardsDataSrc?.avgWritingFeedback.value }}
				</div>
			</cls-info-card-title>
			<cls-info-card-description>
				<div class="card-description" i18n>{{ cardsDataSrc?.avgWritingFeedback?.title }}</div>
			</cls-info-card-description>
		</cls-info-card>
	</div>
</ng-template>

<!-- Cheat Detection -->
<ng-template #CheatDetection>
	<div
		fxFlex
		[ngClass]="{ 'info-card-container': true, 'info-card-container-with-focus': isWithScroll }"
		(click)="isWithScroll ? scrollTo(eGraphToScroll.CheatsFound) : ''"
		[tabIndex]="isWithScroll ? 0 : -1"
		(keyup.enter)="isWithScroll ? scrollTo(eGraphToScroll.CheatsFound) : ''">
		<a
			mat-icon
			[href]="infoLinkBase + 'cheat+detection'"
			target="_blank"
			aria-label="click to find more."
			i18n-matTooltip
			matTooltip="Detects any effort to deceive detection, i.e., text manipulation, hidden characters, etc.">
			<mat-icon class="info-icon info-link-icon">info_outline</mat-icon>
		</a>
		<cls-info-card
			[style.cursor]="isWithScroll ? 'pointer' : 'default'"
			[showIcon]="false"
			[isLoading]="isLoading"
			cardHeight="100%">
			<cls-info-card-title>
				<div class="card-title" [class]="cardsDataSrc?.cheatDetection?.showDesignIcon ? 'alert-text' : ''">
					{{ cardsDataSrc?.cheatDetection.value | number : '1.0-0' }}
				</div>
			</cls-info-card-title>
			<cls-info-card-description>
				<ng-container *ngIf="!cardsDataSrc?.cheatDetection?.showDesignIcon">
					<div class="card-description" i18n>{{ cardsDataSrc?.cheatDetection?.title }}</div>
				</ng-container>
				<ng-container *ngIf="cardsDataSrc?.cheatDetection?.showDesignIcon">
					<div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="center">
						<mat-icon class="material-icons-round alert-icon">warning_amber</mat-icon>
						<div class="card-description" i18n>{{ cardsDataSrc?.cheatDetection?.title }}</div>
					</div>
				</ng-container>
			</cls-info-card-description>
		</cls-info-card>
	</div>
</ng-template>

<!-- Cross Language -->
<ng-template #CrossLanguage>
	<div
		fxFlex
		[ngClass]="{ 'info-card-container': true, 'info-card-container-with-focus': isWithScroll }"
		[tabIndex]="isWithScroll ? 0 : -1"
		(click)="isWithScroll ? scrollTo(eGraphToScroll.CrossLanguage) : ''"
		(keyup.enter)="isWithScroll ? scrollTo(eGraphToScroll.CrossLanguage) : ''">
		<mat-icon
			class="info-icon"
			i18n-matTooltip
			matTooltip="Tracks when plagiarism results are found in a language different from the text scanned.">
			info_outline
		</mat-icon>
		<cls-info-card
			[style.cursor]="isWithScroll ? 'pointer' : 'default'"
			[showIcon]="false"
			cardHeight="100%"
			[isLoading]="isLoading">
			<cls-info-card-title>
				<div class="card-title">
					{{ cardsDataSrc?.crossLanguage?.value | number : '1.0-0' }}
				</div>
			</cls-info-card-title>
			<cls-info-card-description>
				<div class="card-description" i18n>{{ cardsDataSrc?.crossLanguage?.title }}</div>
			</cls-info-card-description>
		</cls-info-card>
	</div>
</ng-template>

<!-- AI Text Cases Card -->
<ng-template #AiTextCases>
	<div
		fxFlex
		[ngClass]="{ 'info-card-container': true, 'info-card-container-with-focus': isWithScroll }"
		(click)="scrollTo(eGraphToScroll.Originality)"
		[tabIndex]="isWithScroll ? 0 : -1"
		(keyup.enter)="isWithScroll ? scrollTo(eGraphToScroll.Originality) : ''">
		<a
			mat-icon
			[href]="infoLinkBase + 'Ai+content+detector'"
			target="_blank"
			aria-label="click to find more."
			i18n-matTooltip
			matTooltip="Tracks how many cases of AI text were detected in all submitted files.">
			<mat-icon class="info-icon info-link-icon">info_outline</mat-icon>
		</a>
		<cls-info-card
			[showIcon]="false"
			[isLoading]="isLoading"
			cardHeight="100%"
			[style.cursor]="isWithScroll ? 'pointer' : 'default'">
			<cls-info-card-title>
				<div class="card-title">
					{{ cardsDataSrc?.aiTextCases?.value | number : '1.0-0' }}
				</div>
			</cls-info-card-title>
			<cls-info-card-description>
				<div class="card-description">{{ cardsDataSrc?.aiTextCases?.title }}</div>
			</cls-info-card-description>
		</cls-info-card>
	</div>
</ng-template>

<!-- Matched Text Cases  -->
<ng-template #plagiarismCases>
	<div
		fxFlex
		[ngClass]="{ 'info-card-container': true, 'info-card-container-with-focus': isWithScroll }"
		[tabIndex]="isWithScroll ? 0 : -1"
		(click)="scrollTo(eGraphToScroll.Originality)"
		(keyup.enter)="isWithScroll ? scrollTo(eGraphToScroll.Originality) : ''">
		<mat-icon
			class="info-icon"
			i18n-matTooltip
			matTooltip="A similarity case is counted when a submission includes identical text, minor changes, or paraphrasing during a plagiarism check, indicating similarities with existing sources.">
			info_outline
		</mat-icon>
		<cls-info-card
			[showIcon]="false"
			[isLoading]="isLoading"
			cardHeight="100%"
			[style.cursor]="isWithScroll ? 'pointer' : 'default'">
			<cls-info-card-title>
				<div class="card-title">
					{{ cardsDataSrc?.plagiarismCases?.value | number : '1.0-0' }}
				</div>
			</cls-info-card-title>
			<cls-info-card-description>
				<div class="card-description" i18n>{{ cardsDataSrc?.plagiarismCases?.title }}</div>
			</cls-info-card-description>
		</cls-info-card>
	</div>
</ng-template>
