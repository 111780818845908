import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, forwardRef, Inject, Input } from '@angular/core';
import { CardFieldElementOptions, Elements } from '../stripe-definitions/element';
import { StripeElement } from '../stripe-element';
import { StripeConfig, stripeConfigToken } from '../stripe-factory';

/** Stripe Card Element for Angular */
@Component({
	selector: 'cls-stripe-card-expiry',
	template: '',
	providers: [
		{
			provide: StripeElement,
			useExisting: forwardRef(() => StripeExpiryNumber),
		},
	],
})
export class StripeExpiryNumber extends StripeElement<'cardExpiry'> {
	constructor(elements: Elements, @Inject(stripeConfigToken) config: StripeConfig, ref: ElementRef<HTMLElement>) {
		super('cardExpiry', elements, config, ref);
	}

	/** Card specific options */
	protected get options(): CardFieldElementOptions {
		return {
			disabled: this.disabled,
		};
	}

	/** Disables the Card control */
	@Input('disabled') set disableSetter(value: boolean) {
		this.disabled = coerceBooleanProperty(value);
	}
	public disabled = false;
}
