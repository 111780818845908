import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { AuthService } from './auth.service';
import { IItem, ITransaction } from './google-analytics.service';
import { OidcService } from './oidc.service';

declare let sendinblue: {
	page: (arg0: string, arg1: { ma_title: string; ma_url: string; ma_path: string }) => void;
	identify: (arg0: string) => void;
	track: (
		arg0: string,
		arg1?: {
			email?: string;
			NAME?: string;
			SURNAME?: string;
		},
		arg2?: {
			event_action?: string;
			event_label?: string;
			value?: number;
			eventdata?: any;
		}
	) => void;
};

function _window(): any {
	// return the global native browser window object
	return window;
}

@Injectable({
	providedIn: 'root',
})
export class SendInBlueAnalyticsService {
	private _isSibPluginIsLoaded = false;

	public get isSibPluginIsLoaded() {
		return this._isSibPluginIsLoaded;
	}

	public set isSibPluginIsLoaded(value) {
		this._isSibPluginIsLoaded = value;
	}

	constructor(
		@Inject(PLATFORM_ID) private platformId: object,
		private _authSvc: AuthService,
		private _oidcService: OidcService
	) {}

	public async initSendInBlueScript() {
		if (this._authSvc.isGuestUser) return;
		if (!this.isSibPluginIsLoaded) {
			await this.initSendInBlueObjectInWindow();
			this.isSibPluginIsLoaded = true;
		}
	}

	private async initSendInBlueObjectInWindow() {
		_window().sib = {
			equeue: [],
			client_key: 'wsxnhu6wphx72hwr7vass',
		};
		/* OPTIONAL: email for identify request*/
		// _window().sib.email_id = email;
		_window().sendinblue = {};
		for (let j = ['track', 'identify', 'trackLink', 'page'], z = 0; z < j.length; z++) {
			(k => {
				// tslint:disable-next-line: only-arrow-functions
				_window().sendinblue[k] = function () {
					const arg = Array.prototype.slice.call(arguments);
					// tslint:disable-next-line: only-arrow-functions
					(
						_window().sib[k] ||
						function () {
							const t = {};
							t[k] = arg;
							_window().sib.equeue.push(t);
						}
					)(arg[0], arg[1], arg[2]);
				};
			})(j[z]);
		}
		const n = document.createElement('script');
		const i = document.getElementsByTagName('script')[0];
		(n.type = 'text/javascript'),
			(n.id = 'sendinblue-js'),
			(n.async = !0),
			(n.src = 'https://sibautomation.com/sa.js?key=wsxnhu6wphx72hwr7vass'),
			i.parentNode.insertBefore(n, i),
			_window().sendinblue.page();
	}

	public onPageEventEmit(pageTitle: string, pageUrl: string, pagePath: string) {
		if (isPlatformBrowser(this.platformId)) {
			try {
				if (!sendinblue) {
					return;
				}
			} catch (error) {
				return;
			}
			sendinblue.page(pageTitle, {
				ma_title: pageTitle,
				ma_url: pageUrl,
				ma_path: pagePath,
			});
		}
	}

	public onIdentifyEventEmit(email: string) {
		if (isPlatformBrowser(this.platformId)) {
			try {
				if (!sendinblue) {
					return;
				}
			} catch (error) {
				return;
			}
			sendinblue.identify(email);
		}
	}

	public onEventEmite(eventCategory: string, eventAction: string, eventLabel?: string, eventValue?: number) {
		if (isPlatformBrowser(this.platformId)) {
			try {
				if (!sendinblue) {
					return;
				}
			} catch (error) {
				return;
			}
			sendinblue.track(eventCategory, null, {
				event_action: eventAction,
				event_label: eventLabel,
				value: eventValue,
			});
		}
	}

	public onTrackEventEmit(
		eventName: string,
		item?: IItem,
		transaction?: ITransaction,
		coupon?: string,
		hostname?: string,
		pageUrl?: string
	) {
		if (isPlatformBrowser(this.platformId)) {
			try {
				if (!sendinblue) {
					return;
				}
			} catch (error) {
				return;
			}

			if (item) {
				const userId = this._oidcService.getClaims()?.sub ?? '';
				const eventdata: any = {
					data: {
						coupon: '',
						total: item.price,
						currency: item.currency ?? 'USD',
						url: item.location,
						items: [
							{
								id: 'id-' + userId,
								name: item.name,
								planid: item.id,
								category: item.category ?? '',
								price: item.price,
								quantity: 1,
							},
						],
					},
				};

				eventdata.data.id = userId;
				if (item.app) {
					eventdata.id = item.app + '-cart:' + userId;
				} else {
					eventdata.id = 'cart:' + userId;
				}

				if (transaction) {
					eventdata.data.transaction_id = transaction.transactionId;
					eventdata.data.affiliation = transaction.affiliation;
					eventdata.data.value = transaction.value;
				}

				if (coupon) {
					eventdata.data.coupon = coupon;
				}

				if (hostname && hostname !== 'localhost') {
					eventdata.data.hostname = hostname;
				}

				if (pageUrl) {
					eventdata.data.url = pageUrl;
				}

				const properties = {
					email: this._authSvc.user$.value?.email,
					NAME: this._authSvc.user$.value?.firstName,
					SURNAME: this._authSvc.user$.value?.lastName,
				};

				sendinblue.track(eventName, properties, eventdata);
			} else {
				sendinblue.track(eventName, {}, {});
			}
		}
	}

	public onSignupTrackEvent(email: string) {
		if (isPlatformBrowser(this.platformId)) {
			try {
				if (!sendinblue) {
					return;
				}
			} catch (error) {
				return;
			}

			const eventdata: any = {
				data: {
					email: email,
				},
			};
			sendinblue.track('sign_up', {}, eventdata);
		}
	}
}
