<div class="progress-bar-container" fxLayoutAlign="center center">
	<div class="stroke-background"></div>
	<mat-progress-spinner
		*ngIf="!isLoading"
		class="progress-bar"
		[diameter]="diameter"
		[color]="colorDependingOnPercentage"
		[mode]="'determinate'"
		[value]="
			currentAmount || currentAmount == 0 ? currentAmountPercentage : amountLeftPercentage
		"></mat-progress-spinner>
	<mat-spinner *ngIf="isLoading" class="progress-bar" [diameter]="diameter" color="primary"></mat-spinner>
	<span class="progress-bar-percentage">
		{{ currentAmount || currentAmount == 0 ? currentAmountAsString : amountLeftAsString }}
	</span>
</div>
