<div fxLayout="row" [class]="!toGapIcon ? 'no-gap-icon' : 'gap-icon'">
	<ng-content select="cls-form-control-lock-item"></ng-content>
	<div class="lock-container" [class]="isAdmin && isEditable ? 'lock-admin' : 'no-pointer'" [style.color]="'black'">
		<ng-container *ngIf="displayLock">
			<ng-container *ngIf="isLocked">
				<button
					type="button"
					aria-label="Locked by admin"
					mat-icon-button
					class="lock-button"
					[matTooltip]="
						modifyToolTip
							? modifyToolTip
							: isAdmin
							? ADMIN_LOCK_CLOSE_TOOLTIP
							: isLocked
							? INSTRUCTOR_LOCK_CLOSE_TOOLTIP
							: ''
					"
					(click)="changeLockMode()">
					<mat-icon>lock</mat-icon>
				</button>
			</ng-container>
			<ng-container *ngIf="!isLocked && isAdmin">
				<button
					type="button"
					aria-label="Opened by admin"
					mat-icon-button
					class="lock-button"
					i18n-matTooltip
					[matTooltip]="isAdmin ? ADMIN_LOCK_OPEN_TOOLTIP : ''"
					(click)="changeLockMode()">
					<mat-icon>lock_open</mat-icon>
				</button>
			</ng-container>
		</ng-container>
	</div>
</div>
