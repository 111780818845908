import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import * as signalR from '@aspnet/signalr';
import { Observable, BehaviorSubject } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

export enum ESignalRConnectionState {
	Error,
	Disconnected,
	Connected,
	PendingConnection,
	Unknown,
}

@Injectable({
	providedIn: 'root',
})
export class NotificationsService {
	public data: any;
	private hubConnection: signalR.HubConnection;
	baseUrl: string;

	reconnectionRetrys = 0;
	maxRetrys = 10;

	private _connectionState$ = new BehaviorSubject<ESignalRConnectionState>(ESignalRConnectionState.Unknown);
	private _manuallyConnectionClosed = false;

	get ConnectionState$() {
		return this._connectionState$;
	}

	constructor(@Inject(PLATFORM_ID) private platformId: object) {}

	setup(baseUrl: string) {
		this.baseUrl = baseUrl;
	}

	startConnection() {
		if (isPlatformBrowser(this.platformId)) {
			if (!this.hubConnection) {
				this._connectionState$.next(ESignalRConnectionState.Unknown);
				this._manuallyConnectionClosed = false;
				this.hubConnection = new signalR.HubConnectionBuilder().withUrl(`${this.baseUrl}/notifications/ws`).build();

				this._connect();

				this.hubConnection.onclose(err => {
					if (this._manuallyConnectionClosed) {
						this._connectionState$.next(ESignalRConnectionState.Disconnected);
					} else {
						this._connectionState$.next(ESignalRConnectionState.Disconnected);
						this._connect();
					}
				});
			}
		}
	}

	private _connect() {
		if (this.hubConnection) {
			this._connectionState$.next(ESignalRConnectionState.PendingConnection);
			this.hubConnection
				.start()
				.then(() => {
					this._connectionState$.next(ESignalRConnectionState.Connected);
				})
				.catch(err => {
					if (this.reconnectionRetrys <= this.maxRetrys) {
						this.reconnectionRetrys++;
						setTimeout(() => {
							this._connect();
						}, 1000 * this.reconnectionRetrys);
					} else {
						this._connectionState$.next(ESignalRConnectionState.Error);
					}
				});
		}
	}

	stopConnection() {
		if (this.hubConnection && this.hubConnection.state === signalR.HubConnectionState.Connected) {
			this._manuallyConnectionClosed = true;
			this.hubConnection.stop();

			this.hubConnection = null;
		}
	}

	on<ReceivedDataType>(target: string) {
		if (!isPlatformBrowser(this.platformId)) {
			return;
		}
		return new Observable<ReceivedDataType>(observer => {
			this.hubConnection.on(target, data => {
				observer.next(JSON.parse(data));
			});
		});
	}

	removeListener(target: string) {
		if (isPlatformBrowser(this.platformId)) {
			if (this.hubConnection) {
				this.hubConnection.off(target);
			}
		}
	}
}
