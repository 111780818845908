import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IIdentityDialogData } from '../../dialogs/identity-dialog/identity-dialog.component';
import { MAX_REFILLS } from '../../constants';
import { ICopyLeaksDialogComponent } from '../../dialogs/copyleaks-dialog/copyleaks-dialog-component/copyleaks-dialog.component';

@Component({
	selector: 'cls-refills-budget-select',
	templateUrl: './refills-budget-select.component.html',
	styleUrls: ['./refills-budget-select.component.scss'],
})
export class RefillsBudgetSelectComponent implements OnInit, ICopyLeaksDialogComponent<IRefillsBudgetSelectResult> {
	selectedBudget: number;
	refillsOptions: IRefillsBudgets[] = [
		{
			name: 'Unlimited',
			value: MAX_REFILLS,
		},
		{
			name: '5',
			value: 5,
		},
		{
			name: '10',
			value: 10,
		},
		{
			name: '15',
			value: 15,
		},
		{
			name: '20',
			value: 20,
		},
		{
			name: '25',
			value: 25,
		},
	];
	constructor(
		@Inject(MAT_DIALOG_DATA)
		public dialogData: IRefillsBudgetSelectDialogComponentData
	) {}
	ngOnInit(): void {
		const budget = this.refillsOptions.find(r => r.value === this.dialogData.budget);
		if (!budget) {
			this.selectedBudget = this.refillsOptions[0].value;
		} else {
			this.selectedBudget = budget.value;
		}
	}
	async isValidComponent() {
		return true;
	}
	async getSubmittedData() {
		return {
			budget: this.selectedBudget,
		} as IRefillsBudgetSelectResult;
	}
}

export interface IRefillsBudgetSelectResult {
	budget: number;
}

export interface IRefillsBudgetSelectDialogComponentData extends IIdentityDialogData {
	budget: number;
	disabledOptions?: boolean;
}

interface IRefillsBudgets {
	name: string;
	value: number;
}
