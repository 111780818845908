import { environment } from '@environment';

export class AnalyticsConstants {
	// routing
	public static BaseAnalyticsRoute = 'lms/:platformType/analytics/:integrationId/';
	public static Routing = {
		CourseRoute: this.BaseAnalyticsRoute + 'course/:courseId',
		ActivityRoute: this.BaseAnalyticsRoute + 'activity/:activityId',
		StudentRoute: this.BaseAnalyticsRoute + 'student/:studentId',
	};

	public static get ANALYTICS_COLLECTION_DATE() {
		if (!environment.production) {
			return new Date(2023, 1, 1);
		}
		if (location?.origin) {
			if (location?.origin?.includes('https://lti-development') || location?.origin?.includes('https://lti-stage')) {
				return new Date(2023, 1, 1);
			}
		}
		return new Date(2024, 0, 1); // 01/01/2024
	}

	public static get VIEW_ANALYTICS() {
		return true;
	}
}
