import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'cls-empty-state-template',
	templateUrl: './cls-empty-state-template.component.html',
	styleUrls: ['./cls-empty-state-template.component.scss'],
})
export class ClsEmptyStateTemplateComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}

@Component({
	selector: 'cls-empty-state-primary-text',
	template: `
		<div class="text-shared-styles primary-text" i18n>
			<ng-content></ng-content>
		</div>
	`,
	styleUrls: ['./cls-empty-state-template.component.scss'],
})
export class ClsEmptyStatePrimaryText {}

@Component({
	selector: 'cls-empty-state-secondary-text',
	template: `
		<div class="text-shared-styles secondary-text" i18n>
			<ng-content></ng-content>
		</div>
	`,
	styleUrls: ['./cls-empty-state-template.component.scss'],
})
export class ClsEmptyStateContent {}

@Component({
	selector: 'cls-empty-state-content',
	template: `
		<ng-content></ng-content>
	`,
	styleUrls: ['./cls-empty-state-template.component.scss'],
})
export class ClsEmptyStateSecondaryText {}
