import { Component, HostListener, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EPathElementGeneralType } from '../../../../enums/path-element-general-type.enum';
import { NotificationRecurrenceItemsDialogComponent } from '../recurrence-update-items-dialog/notification-recurrence-items-dialog.component';
import { AppDirectionalityService } from '../../../../services/app-directionality.service';
import { WindowService } from '../../../../services/window.service';
import { IElementsRecurrenceUpdateNotification } from '../../web-notification';

@Component({
	selector: 'cls-notification-item-recurrence-update',
	templateUrl: './notification-item-recurrence-update.component.html',
	styleUrls: ['./notification-item-recurrence-update.component.scss'],
})
export class NotificationItemRecurrenceUpdateComponent {
	@Input() model: IElementsRecurrenceUpdateNotification;
	@Input() isBackgroundTask = false;
	constructor(
		private _windowService: WindowService,
		private _matDialog: MatDialog,
		private readonly _dir: AppDirectionalityService
	) {}
	@HostListener('click', ['$event.target'])
	onClick() {
		if (this.model.metaData.updatedItems.length == 1) {
			var sharedItem = this.model.metaData.updatedItems[0];
			if (sharedItem.type == EPathElementGeneralType.Folder)
				this._windowService.open(
					`${location.origin}/dashboard/v1/account/shared-with-me?folderId=${sharedItem.elementId}`,
					'_blank'
				);
			else
				this._windowService.open(
					`${location.origin}/dashboard/v1/account/scans/${sharedItem.elementId}/report`,
					'_blank'
				);
		} else {
			this._matDialog.open(NotificationRecurrenceItemsDialogComponent, {
				autoFocus: false,
				direction: this._dir.value,
				panelClass: ['mat-dialog-mobile-full'],
				width: '500px',
				data: this.model,
			});
		}
	}

	getFrequencyTypeString() {
		switch (this.model?.metaData?.frequencyType) {
			case 1:
				return this.model?.metaData?.frequencyPeriod === 1 ? $localize`day` : $localize`days`;
			case 7:
				return this.model?.metaData?.frequencyPeriod === 1 ? $localize`week` : $localize`weeks`;
			case 30:
				return this.model?.metaData?.frequencyPeriod === 1 ? $localize`month` : $localize`months`;
			default:
				return '';
		}
	}
}
