import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class LockFormControlService {
	private _isAdmin: boolean = false;
	private _displayLock: boolean = false;
	private _lockedFormCtrl$ = new BehaviorSubject<IFormCtrlLockState>({ isLocked: false, formCtrlName: '' });

	public get isAdmin() {
		return this._isAdmin;
	}
	public set isAdmin(val: boolean) {
		this._isAdmin = val;
	}

	public get displayLock() {
		return this._displayLock;
	}
	public set displayLock(val: boolean) {
		this._displayLock = val;
	}

	public get lockedFormCtrl$() {
		return this._lockedFormCtrl$;
	}

	public set _lockedFormCtrl(val: IFormCtrlLockState) {
		this._lockedFormCtrl$.next(val);
	}

	constructor() {}
}

export interface IFormCtrlLockState {
	isLocked: boolean;
	formCtrlParent?: string;
	formCtrlName: string;
}
