import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LmsIntegrationItemBadgeComponent } from './lms-integration-item-badge.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
	declarations: [LmsIntegrationItemBadgeComponent],
	imports: [CommonModule, MatIconModule],
	exports: [LmsIntegrationItemBadgeComponent],
})
export class LmsLti1_3IntegrationItemBadgeModule {}
