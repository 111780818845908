import { Component, Input, OnInit } from '@angular/core';
import {
	ESortByCoursesTable,
	ICoursesTableData,
	IDateRange,
	ISortAndPaginateCoursesTable,
} from '../../models/integration-level.models';
import { MatTableDataSource } from '@angular/material/table';
import { IPaginateData } from 'ngx-common-v2/components/cls-paginator/models/cls-paginator.models';
import { EPlatform } from 'shared/enums';
import { AnalyticsDataService } from '../../services/analytics-data.service';
import { ClsAlertsService } from 'ngx-common-v2/components/cls-alerts/services/cls-alerts.service';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'lms-analytics-courses-table',
	templateUrl: './analytics-courses-table.component.html',
	styleUrls: ['./analytics-courses-table.component.scss'],
})
export class AnalyticsCoursesTableComponent implements OnInit {
	dataSrc: ICoursesTableData[];
	@Input() dateRange: IDateRange;

	tableSortAndPaginateState: ISortAndPaginateCoursesTable;
	tableDataSource: MatTableDataSource<ICoursesTableData>;
	displayedColumns: string[] = ['course_name', 'start_date', 'last_modification'];

	eSortBy = ESortByCoursesTable;
	platformType: EPlatform;

	hasNoSearchResults: boolean = false;
	isLoading: boolean = true;
	isTableEmpty: boolean = false;
	noSearchResult: boolean = true;
	canLoadMore: boolean = true;

	cursorArray: string[] = [];

	get showPaginator() {
		// first page
		if (this.paginateState.preIndex === 0)
			return this.dataSrc?.length >= this.pageAmountOptions[0] && !this.isTableEmpty && !this.hasNoSearchResults;

		return !this.isTableEmpty && !this.hasNoSearchResults;
	}
	get receivedDataLength() {
		return (this.paginateState.pageIndex - 1) * this.paginateState.pageSize + this.dataSrc?.length;
	}

	searchStr: string;
	integrationId: string;

	currentDate: number = new Date().getTime();
	pageAmountOptions = [10, 50, 100];

	paginateState: IPaginateData = {
		pageIndex: 1,
		preIndex: 0,
		pageSize: this.pageAmountOptions[0],
	};
	constructor(
		private _analyticsDataService: AnalyticsDataService,
		private _clsAlertSvc: ClsAlertsService,
		private _route: ActivatedRoute
	) {}

	ngOnInit(): void {
		this.dataSrc = this.setEmptyTable();
		this.tableDataSource = new MatTableDataSource<ICoursesTableData>(this.dataSrc);
		this.platformType = Number(this._route.snapshot.paramMap.get('platformType'));
		this.integrationId = this._route.snapshot.paramMap.get('integrationId');

		this.tableSortAndPaginateState = {
			amount: this.paginateState.pageSize,
			corsur: '',
			sortBy: ESortByCoursesTable.StartDate,
			IsDescending: !this.tableSortAndPaginateState?.IsDescending,
			searchStr: '',
			startFrom: 0,
			dateRange: this.dateRange,
		};
		this.getContextsDataAsync();
	}

	async getContextsDataAsync() {
		try {
			this.isLoading = true;
			let result = await this._analyticsDataService.getCoursesTableData(
				this.platformType,
				this.integrationId,
				this.tableSortAndPaginateState
			);

			this.tableSortAndPaginateState.corsur = result.corsur;
			this.canLoadMore = result.canLoadMore;

			if (!result.contexts?.length) {
				if (!this.tableSortAndPaginateState.searchStr) {
					if (this.paginateState.pageIndex == 1) {
						this.isTableEmpty = true;
						this.tableDataSource = new MatTableDataSource<ICoursesTableData>(this.setEmptyTable());
					} else {
						this.paginateState.pageIndex = this.paginateState.pageIndex - 1;
						this.paginateState.preIndex = this.paginateState.preIndex - 1;
					}

					// In case this is the second page or more and there is no result -  we'll keep presenting the last result and disable the loading more results
					if (this.tableSortAndPaginateState.startFrom >= this.tableSortAndPaginateState.amount) {
						this.canLoadMore = false;
					}
				} else {
					this.canLoadMore = false;
					this.isTableEmpty = true;
					this.hasNoSearchResults = true;
					this.tableDataSource = new MatTableDataSource<ICoursesTableData>(this.setEmptyTable());
				}
			} else {
				this.cursorArray.push(result.corsur);
				this.dataSrc = result.contexts;
				this.tableDataSource.data = this.dataSrc;
				this.hasNoSearchResults = false;
				this.isTableEmpty = false;
			}
		} catch (error) {
			this._clsAlertSvc.showSupportError();
		} finally {
			this.isLoading = false;
		}
	}

	onSearchConfirm(searchString: string) {
		this.searchStr = searchString;
		this.tableSortAndPaginateState = {
			...this.tableSortAndPaginateState,
			searchStr: this.searchStr,
			corsur: '',
			startFrom: 0,
		};
		this.paginateState.pageIndex = 1;
		this.paginateState.preIndex = 0;
		this.getContextsDataAsync();
	}

	sortSources(by: ESortByCoursesTable) {
		this.tableSortAndPaginateState.sortBy = by;
		this.tableSortAndPaginateState.IsDescending = !this.tableSortAndPaginateState.IsDescending;

		this.tableSortAndPaginateState.startFrom = 0;
		this.tableSortAndPaginateState.corsur = '';
		this.paginateState.pageIndex = 1;
		this.paginateState.preIndex = 0;
		this.getContextsDataAsync();
	}

	movePaginator(paginateData: IPaginateData) {
		// Moved pagination
		if (this.paginateState.pageIndex > paginateData.pageIndex) {
			this.cursorArray.pop();
			this.cursorArray.pop();
			this.tableSortAndPaginateState.corsur = this.cursorArray[this.cursorArray.length - 1];
			this.tableSortAndPaginateState.startFrom -= this.tableSortAndPaginateState.amount;
		} else if (this.canLoadMore) {
			this.tableSortAndPaginateState.startFrom += this.tableSortAndPaginateState.amount;
		}

		// Change items per page
		if (this.paginateState.pageSize != paginateData.pageSize) {
			this.tableSortAndPaginateState.amount = paginateData.pageSize;
			this.tableSortAndPaginateState.startFrom = 0;
			this.cursorArray = [];
			this.tableSortAndPaginateState.corsur = '';
		}

		this.paginateState = paginateData;

		this.getContextsDataAsync();
	}

	setEmptyTable() {
		return Array.from(
			{ length: 11 },
			() =>
				({
					courseName: '',
				} as ICoursesTableData)
		);
	}
}
