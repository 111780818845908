import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
	WebNotificationComponent,
	WebNotificationDescriptionComponent,
	WebNotificationTimeComponent,
} from './web-notification-component/web-notification.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { AvatarModule } from 'ngx-avatar';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { WebNotificationMenuComponent } from './web-notification-menu/web-notification-menu.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PipesModule } from '../../../pipes/shared/pipes.module';
import { MatDividerModule } from '@angular/material/divider';
import { SpinnerButtonModule } from '../../../components/spinner-button/spinner-button.module';
import { DateFromNowPipeModule } from '../../../pipes/date-from-now/date-from-now-pipe.module';
import { SpinnerModule } from '../../../components/spinner/spinner.module';

@NgModule({
	declarations: [
		WebNotificationComponent,
		WebNotificationMenuComponent,
		WebNotificationDescriptionComponent,
		WebNotificationTimeComponent,
	],
	exports: [
		WebNotificationComponent,
		WebNotificationMenuComponent,
		WebNotificationDescriptionComponent,
		WebNotificationTimeComponent,
	],
	imports: [
		CommonModule,
		FlexLayoutModule,
		AvatarModule,
		DateFromNowPipeModule,
		PipesModule,
		SpinnerButtonModule,
		SpinnerModule,

		MatIconModule,
		MatMenuModule,
		MatButtonModule,
		MatTooltipModule,
		MatDividerModule,
	],
})
export class WebNotificationModule {}
