import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScanDatabasesTableComponent } from './scan-databases-table.component';
import { ScanDatabasesTableService } from './services/scan-databases-table.service';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule } from '@angular/forms';
import { AppSkeletonLoaderModule } from '../../components/skeleton-loader/skeleton-loader.module';
import { TranslateModule } from '@ngx-translate/core';
import { LottieCacheModule, LottieModule } from 'ngx-lottie';
import { LockFormControlModule } from '../lock-form-control/lock-form-control.module';

export function playerFactory() {
	return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web');
}

@NgModule({
	declarations: [ScanDatabasesTableComponent],
	exports: [ScanDatabasesTableComponent],
	providers: [ScanDatabasesTableService],
	imports: [
		CommonModule,
		MatTableModule,
		MatCheckboxModule,
		FormsModule,
		LockFormControlModule,
		MatIconModule,
		MatTooltipModule,
		MatButtonModule,
		AppSkeletonLoaderModule,
		TranslateModule,
		LottieModule.forRoot({ player: playerFactory }),
		LottieCacheModule.forRoot(),
	],
})
export class ScanDatabasesTableModule {}
