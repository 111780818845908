<br />
<form [formGroup]="frmFeatureSuggestion">
	<mat-form-field full-width>
		<mat-label i18n="@@NGX.SUGGEST_A_FEATURE.MY_SUGGESTION">Suggest a Feature</mat-label>
		<input matInput maxlength="150" type="text" formControlName="title" placeholder="My Suggestion" i18n-placeholder />
		<mat-error i18n="@@NGX.SUGGEST_A_FEATURE.VALIDATIONS.MY_SUGGESTION">What is your suggestion?</mat-error>
	</mat-form-field>
	<mat-form-field full-width>
		<mat-label i18n="@@NGX.SUGGEST_A_FEATURE.DESCRIPTION">Tell us more</mat-label>
		<textarea matInput rows="2" formControlName="description" placeholder="Tell us more" i18n-placeholder></textarea>
		<mat-error i18n="@@NGX.SUGGEST_A_FEATURE.VALIDATIONS.DESCRIPTION">Describe your suggestion.</mat-error>
	</mat-form-field>
</form>
