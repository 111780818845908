import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
	AnalyticsEmptyStateComponent,
	AnalyticsEmptyStateDescription,
	AnalyticsEmptyStateImage,
	AnalyticsEmptyStateTitle,
	AnalyticsEmptyStateBottomSection,
} from './analytics-empty-state.component';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
	declarations: [
		AnalyticsEmptyStateComponent,
		AnalyticsEmptyStateTitle,
		AnalyticsEmptyStateDescription,
		AnalyticsEmptyStateImage,
		AnalyticsEmptyStateBottomSection,
	],
	imports: [CommonModule, FlexLayoutModule],
	exports: [
		AnalyticsEmptyStateComponent,
		AnalyticsEmptyStateTitle,
		AnalyticsEmptyStateDescription,
		AnalyticsEmptyStateImage,
		AnalyticsEmptyStateBottomSection,
	],
})
export class AnalyticsEmptyStateModule {}
