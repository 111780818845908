import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, ValidationErrors } from '@angular/forms';
import { IScanPropertiesInternalSourcesDatabase } from '@ngx-common/components/new-scan-profiles/models/new-scan-profiles.interfaces';
import { IScanDatabasesTableValidations } from '@ngx-common/components/scan-databases-table/scan-databases-table.component';
import { ERepositoryPermissions } from '@ngx-common/enums/repositories.shared.enums';

@Component({
	selector: 'app-repository-validation-message',
	templateUrl: './repository-validation-message.component.html',
	styleUrls: ['./repository-validation-message.component.scss'],
})
export class RepositoryValidationMessageComponent {
	@Input() control: FormControl;
	@Input() isReadOnly = false;

	showRepoFixSpinner = false;

	get isRepositoriesValid(): boolean {
		return this.control?.valid;
	}
	get validationErrors(): ValidationErrors {
		return this.control?.errors;
	}
	get errors(): IScanDatabasesTableValidations[] {
		return this.validationErrors ? Object.entries(this.validationErrors).map(e => e[1]) : [];
	}

	fixRepositoriesValidation() {
		this.showRepoFixSpinner = true;
		const value = this.control.value as IScanPropertiesInternalSourcesDatabase[];
		if (value && value.length) {
			value
				.filter(r => this.errors.filter(er => er.id === r.id).length)
				.forEach(r => {
					const error = this.errors.find(er => er.id === r.id);
					r.index = false;
					if (error && error.isRemoved) {
						r.includeOthersScans = false;
						r.includeUserScans = false;
						r.permission = ERepositoryPermissions.None;
					}
				});
			this.control.setValue(value);
		}
		this.showRepoFixSpinner = false;
	}
}
