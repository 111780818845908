<ng-container *ngFor="let alert of alerts">
	<cls-alert-item
		class="slide-in-right"
		[title]="alert.title"
		[description]="alert.description"
		[actionText]="alert.actionText"
		[action]="alert.action"
		[alertItemType]="alert.type"
		(close)="closeAlert(alert)"></cls-alert-item>
</ng-container>
