import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClsSearchInputComponent } from './cls-search-input.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { SpinnerModule } from '@ngx-common/components/spinner/spinner.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
	declarations: [ClsSearchInputComponent],
	imports: [
		CommonModule,
		FlexLayoutModule,
		FormsModule,
		ReactiveFormsModule,
		MatSelectModule,
		SpinnerModule,

		MatFormFieldModule,
		MatInputModule,
		MatIconModule,
		MatTooltipModule,
		MatButtonModule,
	],
	exports: [ClsSearchInputComponent],
})
export class ClsSearchInputModule {}
