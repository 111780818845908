import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'cls-sso-providers',
	templateUrl: './sso-providers.component.html',
	styleUrls: ['./sso-providers.component.scss'],
})
export class SsoProvidersComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
