import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResendActivationSuccessDialogComponent } from './resend-activation-success-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CalloutModule } from '../../components/callout/callout.module';
import { RouterModule } from '@angular/router';

@NgModule({
	declarations: [ResendActivationSuccessDialogComponent],
	exports: [ResendActivationSuccessDialogComponent],
	entryComponents: [ResendActivationSuccessDialogComponent],
	imports: [
		CommonModule,
		RouterModule,
		FlexLayoutModule,
		MatDialogModule,
		MatButtonModule,
		MatIconModule,
		CalloutModule,
	],
})
export class ResendActivationSuccessDialogModule {}
