import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SpinnerModule } from '../../spinner/spinner.module';
import { BillingAddressComponent } from './billing-address.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
	declarations: [BillingAddressComponent],
	exports: [BillingAddressComponent],
	imports: [
		CommonModule,
		FlexLayoutModule,
		FormsModule,
		MatFormFieldModule,
		ReactiveFormsModule,
		MatInputModule,
		MatOptionModule,
		MatSelectModule,
		SpinnerModule,
		NgxSkeletonLoaderModule,
		MatIconModule,
	],
})
export class BillingAddressModule {}
