import {
	Component,
	ComponentFactoryResolver,
	ElementRef,
	HostListener,
	Inject,
	OnInit,
	Type,
	ViewChild,
	ViewContainerRef,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
	CopyleaksCaptchaComponent,
	ICopyLeaksCaptchaControlValue,
} from '../../components/copyleaks-captcha/copyleaks-captcha-component/copyleaks-captcha.component';
import { IUserAvatar } from '../../enums/avatar.model';
import { ScreenService } from '../../services/screen.service';
import { focusErrorInput } from '../../utils/focus-error-input.utils';

@Component({
	selector: 'app-identity-dialog',
	templateUrl: './identity-dialog.component.html',
	styleUrls: ['./identity-dialog.component.scss'],
})
export class IdentityDialogComponent implements OnInit {
	@ViewChild('vcr', { read: ViewContainerRef, static: true })
	vcr: ViewContainerRef;
	passedComponentInstance: IIdentityDialogComponent<any>;
	showSubmitSpinner = false;
	eIdentityDialogType = EIdentityDialogType;
	changeText = false;

	captchaCtrl = new FormControl(null, [Validators.required]);
	@ViewChild('captcha', { read: CopyleaksCaptchaComponent })
	captcha: CopyleaksCaptchaComponent;
	captchaErrorText = '';
	avatar: IUserAvatar = {};

	get outerCloseBtnRight() {
		if (this.isMobile) return `0px`;
		const containerWidth = this._ViewContainerRef.element.nativeElement.parentElement.offsetWidth;
		return `${(innerWidth - containerWidth) / 2 - 47}px`;
	}

	get disableClose(): boolean {
		return this.dialogRef?.disableClose;
	}

	get isMobile() {
		return this._screenSvc?.isMobile();
	}

	constructor(
		public dialogRef: MatDialogRef<IdentityDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: IIdentityDialogData,
		private _ViewContainerRef: ViewContainerRef,
		private _fr: ComponentFactoryResolver,
		private _elementRef: ElementRef,
		private _screenSvc: ScreenService,
		private _router: Router
	) {
		dialogRef.addPanelClass('identity-dialog-mobile');
	}

	ngOnInit(): void {
		setTimeout(() => {
			let padding: string;
			if (!this.isMobile) {
				padding = this.data.customPadding ? this.data.customPadding : '32px';
			} else {
				padding = '21px 25px 34px';
			}
			if (this.data.backGroundColor) {
				const bckGroundColor = this.data.backGroundColor;
				this._ViewContainerRef.element.nativeElement.parentElement.style.background = bckGroundColor;
			}
			this._ViewContainerRef.element.nativeElement.parentElement.style.padding = padding;
		});

		if (!this.data.type) {
			this.data.type = EIdentityDialogType.Default;
		}
		if (this.data.component) {
			const factory = this._fr.resolveComponentFactory(this.data.component);
			const ref = this.vcr.createComponent(factory);
			this.passedComponentInstance = ref.instance;
		}
		this.avatar = this.data.avatar;
	}

	async submit(captcha: CopyleaksCaptchaComponent) {
		try {
			this.captchaErrorText = '';
			captcha.captchaTextCtrl.markAsTouched();

			if (this.data.component) {
				if (!(await this.passedComponentInstance.isValidComponent())) {
					return;
				}
			}
			this.showSubmitSpinner = true;
			const passedComponentData = this.data.component ? await this.passedComponentInstance.getSubmittedData() : null;
			const submittedData: IIdentityDialogSubmittedData = {
				...passedComponentData,
			};
			if (this.data.showCaptcha) {
				if (this.captchaCtrl.valid) {
					submittedData.captcha = this.captchaCtrl.value;
					submittedData.captchaComponent = captcha;
				} else {
					focusErrorInput(this._elementRef);
					return;
				}
			}

			if (this.data?.onSubmit) {
				await this.data.onSubmit(submittedData);
			}
			this.dialogRef.close();
		} catch (error) {
			/** error should be hanlded outside the dialog */
			if (error === EIdentityDialogSubmitErrors.CaptchaNotValid) {
				this.captchaErrorText = this.data.txtInvalidCaptcha ? this.data.txtInvalidCaptcha : 'Wrong Captcha Text.';
				focusErrorInput(this._elementRef);
			}
		} finally {
			this.showSubmitSpinner = false;
		}
	}

	@HostListener('document:keydown', ['$event'])
	handleKeyboardEvent(event: KeyboardEvent) {
		if (event && event.key === 'Enter') {
			this.submit(this.captcha);
		}
	}

	conditinalNavigate() {
		if (this.data.type === this.eIdentityDialogType.DeleteTeam) {
			this._router.navigate([`/teams/${this.data.teamId}/members`]);
		} else {
			this.dialogRef.close();
		}
	}
}

export interface IIdentityDialogData {
	help?: IHelp;
	title?: string;
	titleAlignCenter?: boolean;
	customPadding?: string;
	titleStyle?: IStyle;
	dialogImg?: string;
	description?: string;
	strongDescription?: string;
	descriptionIntro?: string;
	onSubmit?: (submittedData: IIdentityDialogSubmittedData) => Promise<void>;
	component?: Type<IIdentityDialogComponent<any>>;
	callOutDescription?: string;
	btnCancelText?: string;
	btnSubmitText?: string;
	btnCancelBGColor?: string;
	btnSubmitBGColor?: string;
	backGroundColor?: string;
	btnSubmitColor?: string;
	btnSubmitBorder?: boolean;
	btnCancelColor?: string;
	hideSubmitButton?: boolean;
	hideCancelButton?: boolean;
	type?: EIdentityDialogType;
	showCaptcha?: boolean;
	txtInvalidCaptcha?: string;
	teamId?: string;
	avatar?: IUserAvatar; // replace this when IAvatarTeam will upload to the master
}

export enum EIdentityDialogType {
	Default,
	Confirmation,
	DeleteTeam,
}

export interface IHelp {
	title?: string;
	titleHoverText?: string;
	link?: string;
}

export interface IStyle {
	color?: string;
	fontSize?: string;
	margin?: string;
}

export interface IIdentityDialogSubmittedData {
	[key: string]: any;
	captcha?: ICopyLeaksCaptchaControlValue;
	captchaComponent?: CopyleaksCaptchaComponent;
}

export enum EIdentityDialogSubmitErrors {
	CaptchaNotValid,
	VerifyCodeNotValid,
}

export interface IIdentityDialogComponent<T> {
	isValidComponent: () => Promise<boolean>;
	getSubmittedData: () => Promise<T>;
}
