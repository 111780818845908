import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { EProductType } from '../enums/product-type.enums';
import {
	EPlatformProductType,
	IPlatformProductsPlans,
	PlansPricingResult,
	PricingOffer,
	PricingOfferResult,
	PricingPlan,
	PricingPlanItemResultItem,
	PricingPlanResult,
	PricingRecommendation,
} from '../models/pricing.model';
import { httpFailRetry } from '../observable/http-fail-retry.pipes';
import { AuthService } from './auth.service';
import { IUserSubscriptionState } from './payment-plan.service';
@Injectable({
	providedIn: 'root',
})
export class PricingService {
	private _cachedPricingPlans = new Map<EProductType, PricingPlan[]>();
	private _cachedPricingOffers = new Map<string, PricingOfferResult>();
	private _cachedPlansPricingOffers = new Map<string, IPlatformProductsPlans>();
	private _freePlanCredits: number;
	get freePlanCredits() {
		return this._freePlanCredits;
	}
	constructor(private _http: HttpClient, private _authSvc: AuthService) {}

	async getProductsPlans(discountCode?: string, selectedProduct?: EProductType): Promise<IPlatformProductsPlans> {
		try {
			let _productType = selectedProduct;
			if (!selectedProduct) {
				_productType = this._authSvc.getCurrentUserProductType();
			}
			let cacheKey = _productType + discountCode;
			if (!this._cachedPlansPricingOffers[cacheKey]) {
				let reqUrl = `${environment.apiUrl}/v1/${_productType}/pricing/plans`;
				if (discountCode) {
					reqUrl += `?code=${discountCode}`;
				}
				const result = await this._http.get(reqUrl).pipe<IPlatformProductsPlans>(httpFailRetry()).toPromise();
				this._cachedPlansPricingOffers[cacheKey] = result;
			}
			return this._cachedPlansPricingOffers[cacheKey];
		} catch (error) {
			throw error;
		}
	}

	async getPlanbyId(priceId: string, selectedProduct?: EProductType) {
		let _productType = selectedProduct;
		if (!selectedProduct) {
			_productType = this._authSvc.getCurrentUserProductType();
		}
		const reqUrl = `${environment.apiUrl}/v1/${_productType}/pricing/plans/${priceId}`;
		try {
			const plan = await this._http.get(reqUrl).pipe<PricingPlanResult>(httpFailRetry()).toPromise();
			const existedPlan = plan.monthly || plan.yearly;
			return {
				id: existedPlan.planId,
				credits: existedPlan.credits,
				price: existedPlan.price,
				interval: {
					months: plan.monthly ? 1 : 12,
					days: 0,
				},
			} as PricingPlan;
		} catch (error) {
			throw error;
		}
	}

	async mapPlansToPricingPlans(selectedProduct?: EProductType): Promise<PricingPlan[]> {
		let _productType = selectedProduct;
		if (!selectedProduct) {
			_productType = this._authSvc.getCurrentUserProductType();
		}
		if (!this._cachedPricingPlans[_productType]) {
			const productPlans = await this.getProductsPlans(null, _productType);
			if (_productType == EProductType.Repositories || _productType == EProductType.Supports) {
				this._cachedPricingPlans[_productType] = this._mapNormalProductsPlansToPricingPlans(productPlans);
			} else {
				this._cachedPricingPlans[_productType] = this._mapPlatformProductsPlansToPricingPlans(productPlans);
			}
		}
		return this._cachedPricingPlans[_productType];
	}

	_mapPlatformProductsPlansToPricingPlans(productPlans: IPlatformProductsPlans) {
		var result: PricingPlan[] = [];
		productPlans.products.forEach(product => {
			product.plans.forEach(plan => {
				result.push({
					id: plan.monthly.planId,
					credits: plan.monthly.credits,
					platformProduct: product.platformProductType,
					price: plan.monthly.price,
					interval: {
						months: 1,
						days: 0,
					},
				} as PricingPlan);
				if (plan.yearly)
					result.push({
						id: plan.yearly.planId,
						credits: plan.yearly.credits,
						platformProduct: product.platformProductType,
						price: plan.yearly.price,
						interval: {
							months: 12,
							days: 0,
						},
					} as PricingPlan);
			});
		});
		return result;
	}

	_mapNormalProductsPlansToPricingPlans(productPlans: IPlatformProductsPlans) {
		var result: PricingPlan[] = [];
		productPlans.products.forEach(product => {
			product.plans.forEach(plan => {
				result.push({
					id: plan.monthly.planId,
					credits: plan.monthly.credits,
					price: plan.monthly.price,
					interval: {
						months: 1,
						days: 0,
					},
				} as PricingPlan);
				if (plan.yearly)
					result.push({
						id: plan.yearly.planId,
						credits: plan.yearly.credits,
						price: plan.yearly.price,
						interval: {
							months: 12,
							days: 0,
						},
					} as PricingPlan);
			});
		});
		return result;
	}

	async getMonthlyPlanByCredits(
		yearlyCredits: number,
		selectedProduct?: EProductType,
		ePlatformProductType?: EPlatformProductType
	) {
		try {
			let _productType = selectedProduct;
			if (!selectedProduct) {
				_productType = this._authSvc.getCurrentUserProductType();
			}
			var monthlyPlan: PricingPlanItemResultItem;
			const productPlans = await this.getProductsPlans(null, _productType);
			const pricingPlans = await this.mapPlansToPricingPlans(_productType);
			if (_productType == EProductType.Repositories) {
				monthlyPlan = productPlans.products[0].plans.find(p => p.yearly.credits === yearlyCredits)?.monthly;
				return pricingPlans.find(p => p.id == monthlyPlan?.planId);
			} else {
				monthlyPlan = productPlans.products
					.find(product => product.platformProductType == ePlatformProductType)
					.plans.find(plan => plan.yearly.credits == yearlyCredits)?.monthly;
				return pricingPlans.find(p => p.id == monthlyPlan?.planId);
			}
		} catch (error) {
			throw error;
		}
	}

	async getYearlyPlanByCredits(
		monthlyCredits: number,
		selectedProduct?: EProductType,
		ePlatformProductType?: EPlatformProductType
	) {
		try {
			let _productType = selectedProduct;
			if (!selectedProduct) {
				_productType = this._authSvc.getCurrentUserProductType();
			}
			var yearlyPlan: PricingPlanItemResultItem;
			const productPlans = await this.getProductsPlans(null, _productType);
			const pricingPlans = await this.mapPlansToPricingPlans(_productType);
			if (_productType == EProductType.Repositories) {
				yearlyPlan = productPlans.products[0].plans.find(p => p.monthly.credits === monthlyCredits)?.yearly;
				return pricingPlans.find(p => p.id == yearlyPlan?.planId);
			} else {
				yearlyPlan = productPlans.products
					.find(product => product.platformProductType == ePlatformProductType)
					.plans.find(plan => plan.monthly.credits == monthlyCredits)?.yearly;
				return pricingPlans.find(p => p.id == yearlyPlan?.planId);
			}
		} catch (error) {
			throw error;
		}
	}

	async getNextTierPlanByCredits(currentPlan: any) {
		try {
			const plans = await this.mapPlansToPricingPlans();
			return plans
				.filter(
					plan =>
						plan.interval.months == currentPlan.interval.months &&
						plan.platformProduct == currentPlan.platformProductType
				)
				.sort((p, d) => (p.credits > d.credits ? 1 : -1))
				.find(p => p.credits > currentPlan.creditsPackage);
		} catch (error) {
			throw error;
		}
	}

	isMonthlyPlan(subscription: IUserSubscriptionState) {
		return subscription.currentPlan.interval.months === 1;
	}

	isYearlyPlan(subscription: IUserSubscriptionState) {
		return subscription.currentPlan.interval.months === 12;
	}

	async getPricingOffer(data: PricingOffer, selectedProduct: EProductType) {
		try {
			let _productType = selectedProduct;
			if (!selectedProduct) {
				_productType = this._authSvc.getCurrentUserProductType();
			}
			if (!data.coupon && !data.code) {
				const cachedOffer = this._getCachedPricingOffer(data, _productType);
				if (cachedOffer) {
					return cachedOffer;
				}

				const offer = await this._http
					.post<PricingOfferResult>(`${environment.apiUrl}/v1/${_productType}/pricing/offer`, data)
					.toPromise();
				this._cachePricingOffer(data, offer, _productType);
				let user = this._authSvc.user();
				user.countryCode = data.billingAddress.countryCode;
				this._authSvc.updateUserData(user);
				return offer;
			} else {
				let user = this._authSvc.user();
				user.countryCode = data.billingAddress.countryCode;
				this._authSvc.updateUserData(user);
				return await this._http
					.post<PricingOfferResult>(`${environment.apiUrl}/v1/${_productType}/pricing/offer`, data)
					.toPromise();
			}
		} catch (error) {
			throw error;
		}
	}

	private _cachePricingOffer(payload: PricingOffer, offer: PricingOfferResult, productType: EProductType) {
		this._cachedPricingOffers[
			`${payload.billingAddress.countryCode}:${payload.billingAddress.state}:${payload.billingAddress.postalCode}${payload.planId}`
		] = offer;
	}

	private _getCachedPricingOffer(payload: PricingOffer, productType: EProductType) {
		return this._cachedPricingOffers[
			`${payload.billingAddress.countryCode}:${payload.billingAddress.state}:${payload.billingAddress.postalCode}${payload.planId}`
		];
	}

	async getPricingRecommendation(credits: number, selectedProduct?: EProductType) {
		try {
			let _productType = selectedProduct;
			if (!selectedProduct) {
				_productType = this._authSvc.getCurrentUserProductType();
			}
			return await this._http
				.post<PricingRecommendation>(`${environment.apiUrl}/v1/${_productType}/pricing/recommendation`, {
					credits,
				})
				.toPromise();
		} catch (error) {
			throw error;
		}
	}

	async getRecommndedPlanPrice(
		credits: number,
		ePlatformProductType: EPlatformProductType = EPlatformProductType.Bundled
	) {
		try {
			const plans = await this.mapPlansToPricingPlans();
			return plans.find(
				p => p.interval.months == 1 && p.credits >= credits && p.platformProduct == ePlatformProductType
			);
		} catch (error) {
			throw error;
		}
	}

	async getDefaultPlan(selectedProduct?: EProductType) {
		try {
			const plans = await this.mapPlansToPricingPlans();
			return plans.find(p => p.credits == 100);
		} catch (error) {
			throw error;
		}
	}
}
