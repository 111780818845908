import { Pipe, PipeTransform } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { map, Observable } from 'rxjs';

@Pipe({
	name: 'secureImg',
})
export class SecureImgPipe implements PipeTransform {
	constructor(private http: HttpClient, private sanitizer: DomSanitizer) {}

	transform(url: string, headers: HttpHeaders = null): Observable<SafeUrl> {
		if (!url) {
			return;
		}

		return this.http
			.get(url, { responseType: 'blob', headers: headers })
			.pipe(map(val => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(val))));
	}
}
