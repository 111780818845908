import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnalyticsCourseComponent } from './analytics-course.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { ResultsInsightsTemplateModule } from '../../../../../ngx-common-v2/components/analytics-components/templates/cls-results-insights-template/cls-results-insights-template.module';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AnalyticsDataService } from '../../services/analytics-data.service';

import { LmsNavbarModule } from 'shared/componenets/lms-navbar/lms-navbar.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ClsPaginatorModule } from 'ngx-common-v2/components/cls-paginator/cls-paginator.module';
import { ClsContainedContentModule } from 'ngx-common-v2/components/cls-contained-content/cls-contained-content.module';
import { MatTableModule } from '@angular/material/table';
import { ClsSearchInputModule } from 'ngx-common-v2/components/cls-search-input/cls-search-input.module';
import { ClsLoadingAnimationModule } from 'ngx-common-v2/components/cls-loading-animation/cls-loading-animation.module';
import { AnalyticsAssignmentTableModule } from '../../components/analytics-activities-tabel/analytics-activities-table.module';
import { AnalytiscUtilsService } from '../../services/analytics.utils';
import { ClsPageBannerModule } from 'ngx-common-v2/components/cls-page-banner/cls-page-banner.module';
import { ClsEmptyStateTemplateModule } from 'ngx-common-v2/components/analytics-components/templates/cls-empty-state-template/cls-empty-state-template.module';
import { DisabledAnalyticsViewModule } from '../../components/disabled-analytics-view/disabled-analytics-view.module';
import { AnalyticsEmptyStateModule } from '@ngx-common-v2/components/analytics-components/templates/analytics-empty-state/analytics-empty-state.module';

@NgModule({
	declarations: [AnalyticsCourseComponent],
	exports: [AnalyticsCourseComponent],
	imports: [
		CommonModule,
		FlexLayoutModule,
		NgxSkeletonLoaderModule,
		MatIconModule,
		MatTableModule,
		ResultsInsightsTemplateModule,
		NgxChartsModule,
		MatTooltipModule,
		ClsPaginatorModule,
		ClsContainedContentModule,
		ClsSearchInputModule,
		ClsLoadingAnimationModule,
		LmsNavbarModule,
		AnalyticsAssignmentTableModule,
		ClsPageBannerModule,
		ClsEmptyStateTemplateModule,
		DisabledAnalyticsViewModule,
		AnalyticsEmptyStateModule,
	],
	providers: [AnalyticsDataService, AnalytiscUtilsService],
})
export class AnalyticsCourseModule {}
