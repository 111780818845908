import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnalyticsActivitiesTabelComponent } from './analytics-activities-tabel.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { ClsPaginatorModule } from 'ngx-common-v2/components/cls-paginator/cls-paginator.module';
import { ClsContainedContentModule } from 'ngx-common-v2/components/cls-contained-content/cls-contained-content.module';
import { ClsSearchInputModule } from 'ngx-common-v2/components/cls-search-input/cls-search-input.module';
import { ClsScoreLevelModule } from 'ngx-common-v2/components/cls-score-level/cls-score-level.module';
import { MatTableModule } from '@angular/material/table';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';

@NgModule({
	declarations: [AnalyticsActivitiesTabelComponent],
	imports: [
		CommonModule,
		NgxSkeletonLoaderModule,
		MatTooltipModule,
		MatIconModule,
		ClsPaginatorModule,
		ClsContainedContentModule,
		ClsSearchInputModule,
		ClsScoreLevelModule,
		MatTableModule,
		FlexLayoutModule,
		MatButtonModule,
		RouterModule,
	],
	exports: [AnalyticsActivitiesTabelComponent],
})
export class AnalyticsAssignmentTableModule {}
