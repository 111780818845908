import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ClsAlertsService } from '@ngx-common-v2/components/cls-alerts/services/cls-alerts.service';
import { CreditCardComponent } from '../../components/credit-card/credit-card-component/credit-card.component';
import { PaymentMethod } from '../../components/credit-card/stripe/stripe-definitions/payment-method';
import { EPaymentServiceSteps } from '../../models/pricing.model';
import { PaymentService, eCreditCardUpdateMode } from '../../services/payment.service';
@UntilDestroy()
@Component({
	selector: 'cls-credit-card-dialog',
	styleUrls: ['./credit-card-dialog.component.scss'],
	templateUrl: `credit-card-dialog.component.html`,
})
export class CreditCardDialogComponent implements OnInit, OnDestroy {
	@ViewChild('creditCardRef') creditCardRef: CreditCardComponent;
	creditCardCtrl: FormControl;
	newPaymentMethod;
	currentPaymentStep: EPaymentServiceSteps;
	showUpdateBtnSpinner = false;
	disabledForm = false;
	isPlatformBrowser: boolean;

	get showStripComponents(): boolean {
		return this.isPlatformBrowser && !!(window as any).Stripe;
	}

	constructor(
		private _formBuilder: FormBuilder,
		private _paymentSvc: PaymentService,
		private _clsAlertsSvc: ClsAlertsService,
		private _dialogRef: MatDialogRef<CreditCardDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ICreditCardDialog,
		@Inject(PLATFORM_ID) private _platformId: string
	) {}

	async ngOnInit() {
		this.isPlatformBrowser = isPlatformBrowser(this._platformId);
		this.creditCardCtrl = this._formBuilder.control(null, Validators.required);
		this.creditCardCtrl.valueChanges.pipe(untilDestroyed(this)).subscribe(async creditCard => {
			if (this.creditCardCtrl.valid) {
				this.newPaymentMethod = creditCard.paymentMethod;
				if (this.newPaymentMethod) {
					await this.createNewPaymentMethod(this.newPaymentMethod);
				}
			} else {
				this.newPaymentMethod = null;
			}
		});
	}

	ngOnDestroy() {}

	async isValidComponent() {
		return this.creditCardCtrl.valid;
	}
	async getSubmittedData() {
		return this.newPaymentMethod;
	}

	public async createNewPaymentMethod(data: PaymentMethod) {
		try {
			const setupIntent = await this._paymentSvc.createAndConfirmPaymentMethod(
				data,
				eCreditCardUpdateMode.UpdateCreditCardWithoutPayment
			);
			if (setupIntent) {
				this._clsAlertsSvc.showCustomPrimary($localize`Payment method updated successfully`);
				if (this.data?.onSucess) this.data?.onSucess();
				this._dialogRef.close(true);
			} else {
				this._clsAlertsSvc.showCustomError($localize`Update payment method failed`);
				this._dialogRef.close(false);
			}
			this.currentPaymentStep = EPaymentServiceSteps.Completed;
		} catch (err) {
			console.log(err);
			if (err.error && !err.error.code) {
				this._clsAlertsSvc.showCustomError(err.error);
			}
		} finally {
			this.showUpdateBtnSpinner = false;
			this.creditCardRef.showUpdateBtnSpinner = false;
			this.currentPaymentStep = null;
			this.disabledForm = false;
			this.creditCardRef.clear();
		}
	}
}

export interface ICreditCardDialog {
	onSucess?: () => Promise<void>;
}
