import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import {
	AbstractControl,
	ControlValueAccessor,
	FormControl,
	ValidationErrors,
	Validator,
	Validators,
} from '@angular/forms';
import { untilDestroyed } from '@ngneat/until-destroy';
import { IPasswordPolicy } from '../../interfaces/interfaces.shared';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PasswordFormFieldService } from './password-form-field.service';

enum Criteria {
	AtLeastEightChars,
	AtLeastOneLowerCaseChar,
	AtLeastOneUpperCaseChar,
	AtLeastOneDigitChar,
	AtLeastOneSpecialChar,
}
@Component({
	template: '',
})
export abstract class PasswordFormFieldBaseComponent
	implements OnChanges, OnInit, OnDestroy, AfterViewInit, ControlValueAccessor, Validator
{
	@Output() retryPasswordPolicy = new EventEmitter();

	@ViewChild('matFormFieldRef', { static: true, read: ElementRef })
	matFormFieldRef: ElementRef;
	@ViewChild('newPassword') newPasswordInput: ElementRef<HTMLInputElement>;

	passwordPolicyErrorFlag = false;
	inputIsFocused = false;
	newPasswordCtrl: FormControl;
	passwordPolicy: IPasswordPolicy;

	hideNewPassword = true;
	// new password validate properties
	validators: Criteria[] = Object.keys(Criteria).map(key => Criteria[key]);
	criteriaMap = new Map<Criteria, RegExp>();
	containAtLeastEightChars: boolean;
	containMoreThan32Chars: boolean;
	containAtLeastOneLowerCaseLetter: boolean;
	containAtLeastOneUpperCaseLetter: boolean;
	containAtLeastOneDigit: boolean;
	containAtLeastOneSpecialChar: boolean;
	newPasswordStrength: number;
	passwordSpecialChars = `[!\"#$%&'()*+,-./:;<=>?@[\]^_${'`'}{|}~]`;

	private _unsub: any = new Subject();

	formFieldWidth: string;
	showErrorSection = false;

	updateParentControllerValue: (value) => void;

	constructor(private _passwordFormFieldSvc: PasswordFormFieldService) {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.isLoading) {
			if (changes.isLoading.currentValue) {
				this.newPasswordCtrl?.disable();
			} else {
				this.newPasswordCtrl?.enable();
			}
		}
	}

	ngOnInit() {
		this.criteriaMap.set(Criteria.AtLeastEightChars, RegExp(/^.{8,63}$/));
		this.criteriaMap.set(Criteria.AtLeastOneLowerCaseChar, RegExp(/^(?=.*?[a-z])/));
		this.criteriaMap.set(Criteria.AtLeastOneUpperCaseChar, RegExp(/^(?=.*?[A-Z])/));
		this.criteriaMap.set(Criteria.AtLeastOneDigitChar, RegExp(/^(?=.*?[0-9])/));
		this.criteriaMap.set(Criteria.AtLeastOneSpecialChar, RegExp(/^(?=.*?[" !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~"])/));

		this.newPasswordCtrl = new FormControl('');
		this._setValidators();

		// watch new password value change
		this.newPasswordCtrl.valueChanges.pipe(takeUntil(this._unsub)).subscribe(data => {
			this.calculatePasswordStrength(data);
			if (this.updateParentControllerValue) {
				this.updateParentControllerValue(this.newPasswordCtrl.value);
			}
		});

		this._initSubs();
	}

	private _setValidators() {
		this.newPasswordCtrl.setValidators([
			Validators.required,
			...this.validators.map(criteria => Validators.pattern(this.criteriaMap.get(criteria))),
		]);
	}

	ngAfterViewInit(): void {
		setTimeout(() => {
			this.formFieldWidth = this.matFormFieldRef?.nativeElement?.offsetWidth;
			this.showErrorSection = false;
		}, 1000);
	}

	isValidCtrl() {
		return this.newPasswordCtrl?.valid;
	}

	calculatePasswordStrength(password) {
		const requirements: Array<boolean> = [];
		const unit = 100 / 5;

		requirements.push(
			this._containMoreThan32Chars(password),
			this._containAtLeastEightChars(password),
			this._containAtLeastOneLowerCaseLetter(password),
			this._containAtLeastOneUpperCaseLetter(password),
			this._containAtLeastOneDigit(password),
			this._containAtLeastOneSpecialChar(password)
		);

		this.newPasswordStrength = requirements.filter(v => v)?.length * unit;
	}

	getNewPasswordProgressBarColor() {
		return this.newPasswordStrength < 40 ? 'warn' : this.newPasswordStrength <= 80 ? 'warn' : 'accent';
	}

	markAsTouched() {
		this.newPasswordCtrl.markAllAsTouched();
	}

	//#region value accessor interfaces
	writeValue(value: string): void {
		if (this.newPasswordCtrl) {
			this.newPasswordCtrl.setValue(value ? value : '');
		}
	}
	registerOnChange(fn: any): void {
		if (fn) {
			this.updateParentControllerValue = fn;
		}
	}
	registerOnTouched(fn: any): void {}
	setDisabledState?(isDisabled: boolean): void {}
	//#endregion  value accessor interfaces

	//#region validator accessor interface
	validate(control: AbstractControl): ValidationErrors {
		return this.newPasswordCtrl.errors;
	}
	registerOnValidatorChange?(fn: () => void): void {}
	//#endregion validator accessor interface

	private _containAtLeastEightChars(password): boolean {
		if (this.passwordPolicy?.requiredLength)
			this.containAtLeastEightChars = password?.length >= this.passwordPolicy?.requiredLength;
		else this.containAtLeastEightChars = password?.length >= 8;
		return this.containAtLeastEightChars;
	}

	private _containMoreThan32Chars(password): boolean {
		this.containMoreThan32Chars = password?.length > 32;
		return this.containMoreThan32Chars;
	}

	private _containAtLeastOneLowerCaseLetter(password): boolean {
		this.containAtLeastOneLowerCaseLetter = this.criteriaMap.get(Criteria.AtLeastOneLowerCaseChar).test(password);
		return this.containAtLeastOneLowerCaseLetter;
	}

	private _containAtLeastOneUpperCaseLetter(password): boolean {
		this.containAtLeastOneUpperCaseLetter = this.criteriaMap.get(Criteria.AtLeastOneUpperCaseChar).test(password);
		return this.containAtLeastOneUpperCaseLetter;
	}

	private _containAtLeastOneDigit(password): boolean {
		this.containAtLeastOneDigit = this.criteriaMap.get(Criteria.AtLeastOneDigitChar).test(password);
		return this.containAtLeastOneDigit;
	}

	private _containAtLeastOneSpecialChar(password): boolean {
		this.containAtLeastOneSpecialChar = this.criteriaMap.get(Criteria.AtLeastOneSpecialChar).test(password);
		return this.containAtLeastOneSpecialChar;
	}

	private _updatePasswordFormFieldValidators(policy: IPasswordPolicy) {
		this.passwordPolicy = policy;
		this.newPasswordCtrl.setValidators(null);
		if (this.passwordPolicy.requiredLength)
			this.criteriaMap.set(Criteria.AtLeastEightChars, RegExp(`^.{${this.passwordPolicy.requiredLength},63}$`));
		if (this.passwordPolicy.requireLowercase)
			this.criteriaMap.set(Criteria.AtLeastOneLowerCaseChar, RegExp(/^(?=.*?[a-z])/));
		if (this.passwordPolicy.requireUppercase)
			this.criteriaMap.set(Criteria.AtLeastOneUpperCaseChar, RegExp(/^(?=.*?[A-Z])/));
		if (this.passwordPolicy.requireDigit) this.criteriaMap.set(Criteria.AtLeastOneDigitChar, RegExp(/^(?=.*?[0-9])/));
		if (this.passwordPolicy.requireNonAlphanumeric)
			this.criteriaMap.set(Criteria.AtLeastOneSpecialChar, RegExp(/^(?=.*?[" !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~"])/));
		this._setValidators();
	}

	public emitRefreshPolicy() {
		this.passwordPolicyErrorFlag = false;
		this.retryPasswordPolicy.emit();
	}

	private _initSubs() {
		this._passwordFormFieldSvc.passwordPolicyErrorFlag$.pipe(untilDestroyed(this)).subscribe(result => {
			this.passwordPolicyErrorFlag = result;
			if (this.passwordPolicyErrorFlag) {
				this.newPasswordCtrl?.disable();
				this.newPasswordCtrl?.setValue(null);
			} else {
				this.newPasswordCtrl?.enable();
			}
		});

		this._passwordFormFieldSvc.passwordPolicy$.pipe(untilDestroyed(this)).subscribe(result => {
			this._updatePasswordFormFieldValidators(result);
		});
	}

	ngOnDestroy(): void {
		this._unsub.next(null);
		this._unsub.complete();
	}
}
