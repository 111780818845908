export enum AvatarBackgroundColors {
	Bone = '#DDD6B7',
	CadetGrey = '#93B2A5',
	CrystalBlue = '#6D93AC ',
	MetallicSeaweed = '#1E829B',
	SandyBrown = '#F2A960',
	PiggyPink = '#F4E3E8',
	PaleCornflowerBlue = '#A5CBE8 ',
	CookiesAndCream = '#DCDEA8',
	CharmPink = '#E692A9',
	DeepGreen = '#168C68',
	Water = '#D8EDFF',
	EarthYellow = '#E5AD5F',
	Sunglow = '#FBCC2C',
	Apricot = '#FECFB3',
	MetallicBronze = '#9C6D41',
	MysticMaroon = '#A6468C',
	MajorelleBlue = '#5B3CDB',
	RoseGold = '#B16B7D',
}

export enum AvatarIcons {
	AccountBalance = 'account_balance',
	Pets = 'pets',
	Campaign = 'campaign',
	BatteryChargingFull = 'battery_charging_full',
	MarkunreadMailbox = 'markunread_mailbox',
	DoneAll = 'done_all',
	Whatshot = 'whatshot',
	Cake = 'cake',
	Save = 'save',
	OfflineBolt = 'offline_bolt',
	Savings = 'attach_money',
	Interests = 'dashboard',
	VideogameAsset = 'videogame_asset',
	Satisfied = 'sentiment_satisfied_alt',
	Headset = 'headset',
	Favorite = 'favorite',
	EventSeat = 'event_seat',
	SportsEsports = 'sports_esports',
	Looks = 'looks',
	Extension = 'extension',
	Star = 'star',
	Landscape = 'landscape',
	PsychologyAlt = 'psychology_alt',
	LunchDining = 'lunch_dining',
	Gavel = 'gavel',
	BugReport = 'bug_report',
	SportsBasketball = 'sports_basketball',
	LocalFlorist = 'local_florist',
	Toys = 'toys',
	SportsMma = 'sports_mma',
	Android = 'android',
	Share = 'share',
	WC = 'wc',
	Style = 'style',
	PanTool = 'pan_tool',
	Insights = 'insights',
	SelfImprovement = 'self_improvement',
	Inventory2 = 'inventory_2',
	Bolt = 'bolt',
	SportsMartialArts = 'sports_martial_arts',
	FilterAlt = 'filter_alt',
	FlightTakeoff = 'flight_takeoff',
	Psychology = 'psychology',
	ImportantDevices = 'important_devices',
	Accessibility = 'accessibility',
}

export interface IUserAvatar {
	userEmail?: string;
	name?: string;
	size?: number;
}
