import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ClsAlertsService } from 'ngx-common-v2/components/cls-alerts/services/cls-alerts.service';
import { IPaginateData } from 'ngx-common-v2/components/cls-paginator/models/cls-paginator.models';
import {
	IAssignmentsTableData,
	ESortByAssignmentsTable,
	ISortAndPaginateAssignmentsTable,
	IDateRange,
} from 'shared/componenets/lms-analytics/models/integration-level.models';
import { AnalyticsDataService } from 'shared/componenets/lms-analytics/services/analytics-data.service';
import { EPlatform } from 'shared/enums';
import { downloadDataToCsv } from 'ngx-common-v2/utils/csv_downloader.utils';

@Component({
	selector: 'lms-analytics-assignments-tabel',
	templateUrl: './analytics-activities-tabel.component.html',
	styleUrls: ['./analytics-activities-tabel.component.scss'],
})
export class AnalyticsActivitiesTabelComponent implements OnInit {
	@Input() dataSrc: IAssignmentsTableData[];
	@Input() isWithPagination: boolean = false;
	@Input() dateRange: IDateRange;
	@Input() writingFeedbackFeatureEnabled: boolean = false;

	searchResults: IAssignmentsTableData[] = [];
	tableSortAndPaginateState: ISortAndPaginateAssignmentsTable;
	tableDataSource: MatTableDataSource<IAssignmentsTableData>;
	displayedColumns: string[] = [
		'assignment_name',
		'course_name',
		'create_date',
		'avg_ai',
		'plagiarism_score',
		'writingFeeback_avg',
	];
	moodleSearchInputPlaceholderTxt = $localize`Search by Activity ID`;
	othersSearchInputPlaceholderTxt = $localize`Search Assignment`;

	sortBy = ESortByAssignmentsTable;
	sortColumn: ESortByAssignmentsTable;
	platformType: EPlatform;
	ePlatformType = EPlatform;

	hasNoSearchResults: boolean = false;
	isTalbeDescending: boolean = true;
	isLoading: boolean = false;
	isTableEmpty: boolean = false;
	noMoreDataToLoad: boolean = false;

	totalDataLength: number = 0;
	cursorArray: string[] = [];

	get dataSource() {
		return this.searchStr && this.searchStr.length ? this.searchResults : this.dataSrc;
	}

	get canLoadMore() {
		if (this.isWithPagination)
			// if we have less than the page size we cant load more
			return (
				this.dataSource?.length != 0 &&
				this.dataSource?.length >= this.paginateState?.pageSize &&
				!this.noMoreDataToLoad
			);
		return (
			this.dataSource?.length != 0 &&
			this.dataSource?.length > this.paginateState?.pageSize * this.paginateState.pageIndex
		);
	}

	get showPaginator() {
		if (!this.isWithPagination)
			return this.dataSource?.length > this.minDefaultPageSize && !this.isTableEmpty && !this.hasNoSearchResults;
		return this.paginateState.preIndex === 0 && this.paginateState.pageSize === this.minDefaultPageSize
			? this.tableDataSource?.data?.length >= this.paginateState.pageSize &&
					this.searchStr === '' &&
					!this.isTableEmpty &&
					!this.noMoreDataToLoad
			: true;
	}

	integrationId: string;
	searchStr: string = '';

	currentDate: number = new Date().getTime();
	minDefaultPageSize = 10;

	paginateState: IPaginateData = {
		pageIndex: 1,
		preIndex: 0,
		pageSize: this.minDefaultPageSize,
	};

	constructor(
		private _analyticsDataService: AnalyticsDataService,
		private _clsAlertSvc: ClsAlertsService,
		private _router: Router,
		private _route: ActivatedRoute
	) {}

	ngOnInit(): void {
		this.platformType = Number(this._route.snapshot.paramMap.get('platformType'));
		this.integrationId = this._route.snapshot.paramMap.get('integrationId');
		if (this.isWithPagination) {
			this.tableSortAndPaginateState = {
				amount: this.paginateState.pageSize,
				corsur: '',
				startFrom: 0,
				sortBy: ESortByAssignmentsTable.NoSort,
				IsDescending: !this.isTalbeDescending,
				searchStr: this.searchStr,
				dateRange: this.dateRange,
			};
			this.setTableViewData([]);
			this.getTableDataAsync();
		} else {
			this.setTableViewData(this.dataSrc);
		}
		this.reArrangeColumns();
		if (!this.isWithPagination) this.totalDataLength = this.dataSource.length;
	}

	reArrangeColumns() {
		if (!this.isWithPagination) {
			this.displayedColumns = ['assignment_name', 'due_date', 'avg_ai', 'plagiarism_score', 'writingFeeback_avg'];
			return;
		}

		if (this.platformType == this.ePlatformType.LTI1_3) {
			this.displayedColumns = [
				'assignment_name',
				'course_name',
				'due_date',
				'avg_ai',
				'plagiarism_score',
				'writingFeeback_avg',
			];
			return;
		}

		if (this.platformType == this.ePlatformType.Moodle) {
			this.displayedColumns = [
				'assignment_name',
				'course_name',
				'create_date',
				'avg_ai',
				'plagiarism_score',
				'writingFeeback_avg',
			];
			return;
		}

		if (this.platformType == this.ePlatformType.Canvas) {
			this.displayedColumns = ['assignment_name', 'create_date', 'avg_ai', 'plagiarism_score', 'writingFeeback_avg'];
		}
	}

	async getTableDataAsync() {
		try {
			this.isLoading = true;
			const response = await this._analyticsDataService.getAssignmentsTableData(
				this.platformType,
				this.integrationId,
				this.tableSortAndPaginateState
			);
			if (response.corsur?.length) {
				this.cursorArray.push(response.corsur);
			}
			this.tableSortAndPaginateState.corsur =
				this.cursorArray.length != 0 ? this.cursorArray[this.cursorArray.length - 1] : '';

			if (this.isWithPagination) {
				this.totalDataLength =
					(this.paginateState.pageIndex - 1) * this.paginateState.pageSize + response?.activities?.length;
			}

			// In case its not the first page of the table and we got empty result back
			// we cannot load more data
			if (this.paginateState.pageIndex != 1 && !response.activities?.length) {
				this.noMoreDataToLoad = true;
				this.paginateState = {
					...this.paginateState,
					preIndex: this.paginateState.preIndex - 1,
					pageIndex: this.paginateState.pageIndex - 1,
				};
			} else {
				this.noMoreDataToLoad = false;
				this.dataSrc = response.activities;
				this.setTableViewData(this.dataSrc);
			}
		} catch (error) {
			this._clsAlertSvc.showSupportError();
		} finally {
			this.isLoading = false;
		}
	}

	setTableViewData(data: IAssignmentsTableData[]) {
		if (!data?.length) {
			this.isTableEmpty = true;
			this.hasNoSearchResults = this.searchStr != '';
			this.tableDataSource = new MatTableDataSource<IAssignmentsTableData>(this.setEmptyTable());
		} else {
			this.isTableEmpty = false;
			this.hasNoSearchResults = false;
			this.tableDataSource = new MatTableDataSource<IAssignmentsTableData>(data?.slice(0, this.paginateState.pageSize));
		}
	}

	onSearchConfirm(searchString: string) {
		this.searchStr = searchString;
		if (!this.isWithPagination) {
			if (searchString.length) {
				searchString = searchString.toLocaleLowerCase();
				this.searchResults = this.dataSrc?.filter(r => r.name.toLocaleLowerCase().includes(searchString));
				if (this.searchResults.length > 0) {
					this.tableDataSource.data = this.searchResults.slice(0, this.paginateState.pageSize);
					this.hasNoSearchResults = false;
				} else {
					this.searchResults = this.setEmptyTable();
					this.tableDataSource.data = this.searchResults;
					this.hasNoSearchResults = true;
				}
				this.paginateState.pageIndex = 1;
				this.paginateState.preIndex = 0;
			} else {
				this.hasNoSearchResults = false;
				this.dataSrc = this.isTalbeDescending ? this.dataSrc : this.dataSrc?.reverse();
				this.tableDataSource.data = this.dataSrc?.slice(0, this.paginateState.pageSize);
				this.searchStr = '';
				this.searchResults = [];
			}
		} else {
			this.cursorArray = [];
			this.paginateState = {
				pageIndex: 1,
				preIndex: 0,
				pageSize: this.paginateState.pageSize,
			};
			this.tableSortAndPaginateState = {
				...this.tableSortAndPaginateState,
				amount: this.paginateState.pageSize,
				startFrom: 0,
				searchStr: this.searchStr,
				corsur: '',
			};
			this.getTableDataAsync();
		}
	}

	sortAllData(by: ESortByAssignmentsTable) {
		this.isTalbeDescending = !this.isTalbeDescending;
		this.sortColumn = by;
		switch (by) {
			case ESortByAssignmentsTable.AssignmentName:
				if (this.searchStr) {
					this.searchResults = this.searchResults.sort((a, b) => {
						if (this.isTalbeDescending) return b.name.localeCompare(a.name);
						return a.name.localeCompare(b.name);
					});
				} else {
					this.tableDataSource.data = this.dataSrc?.sort((a, b) => {
						if (this.isTalbeDescending) return b.name.localeCompare(a.name);
						return a.name.localeCompare(b.name);
					});
				}
				break;
			case ESortByAssignmentsTable.CreatedDate:
				if (this.searchStr) {
					this.searchResults = this.searchResults.sort((a, b) => a.createdDate.getTime() - b.createdDate.getTime());
				} else {
					this.tableDataSource.data = this.dataSrc.sort((a, b) => {
						if (this.isTalbeDescending) return a.createdDate.getTime() - b.createdDate.getTime();
						return b.createdDate.getTime() - a.createdDate.getTime();
					});
				}
				break;
			case ESortByAssignmentsTable.DueDate:
				if (this.searchStr) {
					this.searchResults = this.searchResults.sort((a, b) => {
						if (a.dueDate == null) return 1;
						if (b.dueDate == null) return -1;
						if (this.isTalbeDescending) return b.dueDate.getTime() - a.dueDate.getTime();
						return a.dueDate.getTime() - b.dueDate.getTime();
					});
				} else {
					this.tableDataSource.data = this.dataSrc.sort((a, b) => {
						if (a.dueDate == null) return 1;
						if (b.dueDate == null) return -1;
						if (this.isTalbeDescending) return b.dueDate.getTime() - a.dueDate.getTime();
						return a.dueDate.getTime() - b.dueDate.getTime();
					});
				}
				break;

			case ESortByAssignmentsTable.AIScore:
				if (this.searchStr) {
					this.tableDataSource.data = this.searchResults.sort((a, b) => {
						if (a.averageAI == null) return 1;
						if (b.averageAI == null) return -1;
						if (!this.isTalbeDescending) return a.averageAI - b.averageAI;
						return b.averageAI - a.averageAI;
					});
				} else {
					this.tableDataSource.data = this.dataSrc?.sort((a, b) => {
						if (a.averageAI == null) return 1;
						if (b.averageAI == null) return -1;
						if (!this.isTalbeDescending) return a.averageAI - b.averageAI;
						return b.averageAI - a.averageAI;
					});
				}
				break;
			case ESortByAssignmentsTable.PlagiairsmScore:
				if (this.searchStr) {
					this.tableDataSource.data = this.searchResults.sort((a, b) => {
						if (a.averagePlagiarism == null) return 1;
						if (b.averagePlagiarism == null) return -1;
						if (!this.isTalbeDescending) return a.averagePlagiarism - b.averagePlagiarism;
						return b.averagePlagiarism - a.averagePlagiarism;
					});
				} else {
					this.tableDataSource.data = this.dataSrc?.sort((a, b) => {
						if (a.averagePlagiarism == null) return 1;
						if (b.averagePlagiarism == null) return -1;
						if (!this.isTalbeDescending) return a.averagePlagiarism - b.averagePlagiarism;
						return b.averagePlagiarism - a.averagePlagiarism;
					});
				}
				break;
			case ESortByAssignmentsTable.WritingFeedback:
				if (this.searchStr) {
					this.tableDataSource.data = this.searchResults.sort((a, b) => {
						if (a.averageWritingFeedback == null) return 1;
						if (b.averageWritingFeedback == null) return -1;
						if (!this.isTalbeDescending) return a.averageWritingFeedback - b.averageWritingFeedback;
						return b.averageWritingFeedback - a.averageWritingFeedback;
					});
				} else {
					this.tableDataSource.data = this.dataSrc?.sort((a, b) => {
						if (a.averageWritingFeedback == null) return 1;
						if (b.averageWritingFeedback == null) return -1;
						if (!this.isTalbeDescending) return a.averageWritingFeedback - b.averageWritingFeedback;
						return b.averageWritingFeedback - a.averageWritingFeedback;
					});
				}
			default:
				break;
		}

		this.paginateState.pageIndex = 1;
		this.paginateState.preIndex = 0;
		this.movePaginator(this.paginateState);
	}

	getSortedData(by: ESortByAssignmentsTable) {
		this.sortColumn = by;
		this.cursorArray = [];
		this.searchStr = '';
		this.paginateState = {
			pageIndex: 1,
			preIndex: 0,
			pageSize: this.paginateState.pageSize,
		};
		this.tableSortAndPaginateState = {
			amount: this.paginateState.pageSize,
			startFrom: 0,
			searchStr: '',
			sortBy: by,
			IsDescending: !this.isTalbeDescending,
			corsur: '',
			dateRange: this.dateRange,
		};
		this.isTalbeDescending = !this.isTalbeDescending;
		this.getTableDataAsync();
	}

	movePaginator(paginateData: IPaginateData) {
		if (!this.isWithPagination) {
			const paginateFromData = this.dataSource;
			if (this.paginateState.pageSize != paginateData.pageSize) {
				this.tableDataSource.data = paginateFromData.slice(0, paginateData.pageSize);
			} else {
				const startIdx = paginateData.preIndex * paginateData.pageSize;
				const endIdx = paginateData.pageIndex * paginateData.pageSize;

				// page up
				if (startIdx < endIdx) {
					this.tableDataSource.data = paginateFromData.slice(startIdx, endIdx);
				} else {
					// page down
					this.tableDataSource.data = paginateFromData.slice(
						(paginateData.pageIndex - 1) * paginateData.pageSize,
						(paginateData.preIndex - 1) * paginateData.pageSize
					);
				}
			}
			this.paginateState = paginateData;
		} else {
			// moved forward
			if (this.paginateState.pageIndex < paginateData.pageIndex) {
				this.paginateState = paginateData;
				this.tableSortAndPaginateState = {
					...this.tableSortAndPaginateState,
					amount: this.paginateState.pageSize,
					startFrom: this.paginateState.preIndex * this.paginateState.pageSize,
				};
			} else {
				this.paginateState = paginateData;
				this.cursorArray.pop();
				this.cursorArray.pop();
				this.tableSortAndPaginateState = {
					...this.tableSortAndPaginateState,
					amount: this.paginateState.pageSize,
					corsur: this.cursorArray.length ? this.cursorArray.pop() : '',
					startFrom:
						this.paginateState.pageIndex == 1 ? 0 : (this.paginateState.pageIndex - 1) * this.paginateState.pageSize,
				};
			}
			this.getTableDataAsync();
		}
	}

	setEmptyTable() {
		return Array.from(
			{ length: 10 },
			() =>
				({
					name: '',
				} as IAssignmentsTableData)
		);
	}

	download() {
		const data = this.isWithPagination ? this.tableDataSource.data : this.dataSrc;
		const dataToExport = data.map(assignment => {
			const avgAI = assignment.averageAI != null ? assignment.averageAI : '---';
			const dueDate = assignment.dueDate ? assignment.dueDate : '---';
			const avgPlagiarism = assignment.averagePlagiarism != null ? assignment.averagePlagiarism : $localize`---`;
			const assignmentName = assignment.name?.replace(/,/g, ' ');
			const courseName = assignment.courseName?.replace(/,/g, ' ');
			const avgWritingFeedback =
				this.writingFeedbackFeatureEnabled && assignment.averageWritingFeedback != null
					? assignment.averageWritingFeedback
					: `---`;
			if (!this.isWithPagination) {
				return {
					'Assignment Name': assignmentName,
					'Due Date': dueDate,
					'Avg. AI Content Score': avgAI,
					'Avg. Plagiarism Score': avgPlagiarism,
					'Avg. Writing Corrections': avgWritingFeedback,
				};
			} else if (this.platformType == this.ePlatformType.LTI1_3) {
				return {
					'Assignment Name': assignmentName,
					'Course Name': courseName,
					'Due Date': dueDate,
					'Avg. AI Content Score': avgAI,
					'Avg. Plagiarism Score': avgPlagiarism,
					'Avg. Writing Corrections': avgWritingFeedback,
				};
			} else if (this.platformType == this.ePlatformType.Moodle) {
				return {
					'Assignment Name': assignmentName,
					'Course Name': courseName,
					'Created Date': new Date(assignment.createdDate).toLocaleDateString(),
					'Avg. AI Content Score': avgAI,
					'Avg. Plagiarism Score': avgPlagiarism,
					'Avg. Writing Corrections': avgWritingFeedback,
				};
			} else if (this.platformType == this.ePlatformType.Canvas) {
				return {
					'Assignment Name': assignmentName,
					'Created Date': new Date(assignment.createdDate).toLocaleDateString(),
					'Avg. AI Content Score': avgAI,
					'Avg. Plagiarism Score': avgPlagiarism,
					'Avg. Writing Corrections': avgWritingFeedback,
				};
			}
		});

		downloadDataToCsv(dataToExport, 'Assignments_Data', 'MMM Do YYYY h:mm a');
	}
}
