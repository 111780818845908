import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationItemDownloadComponent } from './notification-item-download.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatBadgeModule } from '@angular/material/badge';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SpinnerModule } from '../../../components/spinner/spinner.module';
import { NotificationErrorDialogModule } from '../../../notifications/dialogs/notification-error-dialog/notification-error-dialog.module';
import { DateFromNowPipeModule } from '../../../pipes/date-from-now/date-from-now-pipe.module';
import { EllipsisPipeModule } from '../../../pipes/ellipsis/ellipsis-pipe.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
	declarations: [NotificationItemDownloadComponent],
	imports: [
		CommonModule,
		FlexLayoutModule,

		SpinnerModule,
		DateFromNowPipeModule,
		EllipsisPipeModule,
		NotificationErrorDialogModule,
		TranslateModule,

		MatIconModule,
		MatProgressSpinnerModule,
		MatBadgeModule,
		MatTooltipModule,
	],
	exports: [NotificationItemDownloadComponent],
})
export class NotificationItemDownloadModule {}
