import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { BreadcrumbItem } from '@ngx-common/services/seo.service';
import { ICourseAnalyticsOverview } from '../../models/analytics-course.models';
import { AnalyticsDataService as AnalyticsService } from '../../services/analytics-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as shape from 'd3-shape';
import { ClsAlertsService } from 'ngx-common-v2/components/cls-alerts/services/cls-alerts.service';
import { IPaginateData } from 'ngx-common-v2/components/cls-paginator/models/cls-paginator.models';
import { EPlatform } from 'shared/enums';
import { ClsNgxChartUtils } from 'ngx-common-v2/utils/ngx-charts.utils';
import { AnalyticsConstants } from 'shared/constants/analytics';
import { EAnalyticsLevel } from '@ngx-common-v2/components/analytics-components/models/cls-analytics.models';

@Component({
	selector: 'lms-analytics-course',
	templateUrl: './analytics-course.component.html',
	styleUrls: ['./analytics-course.component.scss'],
})
export class AnalyticsCourseComponent implements OnInit {
	@ViewChild('insightsItem') insightsItemElementRef: ElementRef<HTMLDivElement>;
	@Input() isLoadFromDashboard = true;
	@Input() token: string;

	integrationId: string;
	courseId: string;
	platformType: EPlatform;
	eLevelType = EAnalyticsLevel;

	/**
	 * The data needed for the view.
	 */
	@Input() dataSrc: ICourseAnalyticsOverview;

	lineChartCustomColors = [
		{ name: $localize`AI TEXT`, value: '#C1ADDC' },
		{ name: $localize`MATCHED TEXT`, value: '#FD7366' },
	];

	chartText: string = $localize`Assignments`;
	correctionText: string = $localize`CORRECTION`;

	displayedColumns: string[] = ['assignment_name', 'due_date', 'plagiarism_instances', 'ai_text_cases'];

	breadCrumbs: BreadcrumbItem[];
	view: number[];
	writingFeedbackChartView: number[];

	isLoading: boolean;

	currentDate: number = new Date().getTime();

	linearCurve = shape.curveBumpX;
	paginateState: IPaginateData = {
		pageIndex: 1,
		preIndex: 0,
		pageSize: 10,
	};

	loadingMessage = $localize`Loading data...`;
	ANALYTICS_COLLECTION_DATE = AnalyticsConstants.ANALYTICS_COLLECTION_DATE;

	constructor(
		private _analyticsService: AnalyticsService,
		private _clsAlertSvc: ClsAlertsService,
		private _route: Router,
		private _router: ActivatedRoute
	) {}

	ngOnInit(): void {
		this.courseId = this._router.snapshot.paramMap.get('courseId');
		this.integrationId = this._router.snapshot.paramMap.get('integrationId');
		this.platformType = Number(this._router.snapshot.paramMap.get('platformType'));

		this.getCourseDataAsync();
	}
	/**
	 * Fetch the data for the course analytics
	 */
	async getCourseDataAsync() {
		try {
			this.isLoading = true;
			this.dataSrc = await this._analyticsService.getCourseData(
				this.platformType,
				this.integrationId,
				this.courseId,
				this.token
			);

			this.setBreadCrumbs();

			if (!this.dataSrc.writingFeedbackFeatureEnabled) {
				this.dataSrc.cardsData.avgWritingFeedback.value = 'N/A';
				this.dataSrc.cardsData.avgWritingFeedback.isDisabled = true;
			}
		} catch (error) {
			if (!this._analyticsService.redirectOnErroredRequest(error)) this._clsAlertSvc.showSupportError();
		} finally {
			this.isLoading = false;
			if (this.dataSrc && this.dataSrc.isStatisticsExists)
				setTimeout(() => {
					this.onResize(null, this.insightsItemElementRef.nativeElement);
				}, 100);
		}
	}

	setBreadCrumbs() {
		this.breadCrumbs = [
			{
				name: this.dataSrc?.courseName,
				url: `lms/${this.platformType}/analytics/${this.integrationId}/course/${this.courseId}`,
			},
		];

		if (this.isLoadFromDashboard) {
			this.breadCrumbs = [
				{
					name: $localize`Integrations`,
					url: '/lti/v1.3/institues',
				},
				{
					name: this.dataSrc?.integrationName,
					url: `/dashboard/${this.platformType}/analytics/${this.integrationId}`,
				},
				...this.breadCrumbs,
			];
		}
	}

	/**
	 * Sort the data in the table by date, name plagerisem, ai
	 * @param sortBy ESortBy
	 * @returns
	 */

	/**
	 * Download event handler
	 */
	download() {
		console.log('download');
	}

	onResize(event, insightsItem?: HTMLDivElement) {
		this.view = null;
		this.writingFeedbackChartView = null;
		setTimeout(() => {
			if (insightsItem) {
				this.view = [insightsItem.offsetWidth + 70, insightsItem.offsetHeight - 60];
				this.writingFeedbackChartView = [insightsItem.offsetWidth - 25, insightsItem.offsetHeight - 30];
			}
			setTimeout(() => {
				this.checkGraphsData();
			}, 100);
		}, 100);
	}

	checkGraphsData() {
		const el = ClsNgxChartUtils.GetChartsLineSeriesPathElementsRef();
		// Checking if graphs have only one dot
		if (this.dataSrc?.originalityData?.chartData[0]?.series?.length === 1) {
			ClsNgxChartUtils.setGraphPointStroke(el, 0);
		}
		if (this.dataSrc?.originalityData?.chartData[1].series.length === 1) {
			ClsNgxChartUtils.setGraphPointStroke(el, 1);
		}
	}

	/**
	 * Open assignment page
	 * @param assignemntName
	 */
	openAssignment(activityId: string) {
		this._route.navigate([`lms/${this.platformType}/analytics/${this.integrationId}/activity/${activityId}`]);
	}

	/**
	 * Navigate to the url of the breadcrumb.
	 * @param breadCrumbItem
	 */
	navigateWithBreadCrumb(breadCrumbItem: BreadcrumbItem) {
		if (breadCrumbItem.url != '/') this._route.navigate([breadCrumbItem.url]);
	}
}
