import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CreditCardModule } from '../../components/credit-card/credit-card.module';
import { SpinnerModule } from '../../components/spinner/spinner.module';
import { CreditCardDialogComponent } from './credit-card-dialog.component';

@NgModule({
	declarations: [CreditCardDialogComponent],
	imports: [CreditCardModule, SpinnerModule, ReactiveFormsModule, FormsModule, CommonModule],
})
export class CreditCardDialogModule {}
