<div
	class="alert-item-container"
	[ngClass]="{
		'error-theme': alertItemType === EAlertItemType.Error,
		'primary-theme': alertItemType === EAlertItemType.Primary || alertItemType === EAlertItemType.Refresh,
		'warning-theme': alertItemType === EAlertItemType.Warning
	}"
	fxLayout="row"
	fxLayoutAlign="start center"
	fxLayoutGap="16px">
	<!-- close icon -->
	<div
		class="alert-item-icon-container"
		[ngClass]="{
			'error-theme': alertItemType === EAlertItemType.Error,
			'primary-theme': alertItemType === EAlertItemType.Primary || alertItemType === EAlertItemType.Refresh,
			'warning-theme': alertItemType === EAlertItemType.Warning
		}"
		fxLayoutAlign="center center">
		<ng-container [ngSwitch]="alertItemType">
			<ng-container *ngSwitchCase="EAlertItemType.Primary">
				<mat-icon fontSet="material-icons-round" class="alert-item-icon-primary">task_alt</mat-icon>
			</ng-container>
			<ng-container *ngSwitchCase="EAlertItemType.Error">
				<mat-icon fontSet="material-icons-round" class="alert-item-icon-error">info</mat-icon>
			</ng-container>
			<ng-container *ngSwitchCase="EAlertItemType.Warning">
				<mat-icon fontSet="material-icons-round" class="alert-item-icon-warning" style="height: 26px">
					warning_amber
				</mat-icon>
			</ng-container>
			<ng-container *ngSwitchCase="EAlertItemType.Refresh">
				<mat-icon fontSet="material-icons-round" class="alert-item-icon-primary">autorenew</mat-icon>
			</ng-container>
		</ng-container>
	</div>
	<div class="alert-item-text-container" fxLayout="column" fxLayoutGap="2px">
		<span *ngIf="title" class="alert-item-title">
			{{ title }}
		</span>
		<span *ngIf="description" class="alert-item-description" [innerHTML]="description | safe: 'html'"></span>
	</div>
	<div class="icon-container" fxLayout="column" fxLayoutGap="2px" fxLayoutAlign="space-between end" fxFlexAlign="start">
		<mat-icon fontSet="material-icons-round" class="close-alert-icon" (click)="closeAlert()">close</mat-icon>
		<a *ngIf="actionText" class="alert-item-action" (click)="triggerAction()">{{ actionText }}</a>
	</div>
</div>
