<div *ngIf="showBillingTitle" fxLayoutAlign="start center" class="title-container">
	<h3 no-margin-top i18n>Billing Address</h3>
	<mat-icon *ngIf="showCheckMarkCircle && form?.valid" id="checkout-billing-address" class="check-mark">
		check_circle
	</mat-icon>
</div>

<div
	*ngIf="showSpinner"
	class="skeleton-loaders-container"
	fxLayoutGap="35px"
	fxLayoutGap.lt-md="35px"
	fxLayout="column">
	<div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxLayoutGap.lt-md="35px">
		<ngx-skeleton-loader class="skeleton-loader" [theme]="{ 'height.px': 38 }"></ngx-skeleton-loader>
		<ngx-skeleton-loader class="skeleton-loader" [theme]="{ 'height.px': 38 }"></ngx-skeleton-loader>
	</div>
	<div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxLayoutGap.lt-md="35px">
		<ngx-skeleton-loader class="skeleton-loader" [theme]="{ 'height.px': 38 }"></ngx-skeleton-loader>
		<ngx-skeleton-loader class="skeleton-loader" [theme]="{ 'height.px': 38 }"></ngx-skeleton-loader>
	</div>
	<div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxLayoutGap.lt-md="35px">
		<ngx-skeleton-loader
			*ngIf="states.length"
			class="skeleton-loader"
			[theme]="{ 'height.px': 38 }"></ngx-skeleton-loader>
		<ngx-skeleton-loader class="skeleton-loader" [theme]="{ 'height.px': 38 }"></ngx-skeleton-loader>
	</div>
</div>

<ng-container *ngIf="!showSpinner">
	<form *ngIf="form" fxLayout="column" [formGroup]="form">
		<div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxLayoutGap.lt-md="0px">
			<!-- Country -->
			<div class="form-item-container" fxLayout="column" fxLayoutGap="4px">
				<span class="item-title" i18n>Country*</span>
				<div fxLayout="column">
					<mat-select
						i18n-placeholder
						placeholder="Country"
						name="countryCode"
						[ngClass]="{
							'error-highlight': isFieldErrorRequired(eAccountBillingAddressFormControlNames.fcCountry)
						}"
						[formControlName]="eAccountBillingAddressFormControlNames.fcCountry">
						<mat-option *ngFor="let country of countries" [value]="country.code">{{ country.name }}</mat-option>
					</mat-select>
					<span
						*ngIf="isFieldErrorRequired(eAccountBillingAddressFormControlNames.fcCountry)"
						class="error-message"
						i18n>
						Country is required.
					</span>
					<div
						class="error-place-holder"
						*ngIf="!isFieldErrorRequired(eAccountBillingAddressFormControlNames.fcCountry)"></div>
				</div>
			</div>
			<!-- Address 1 -->
			<div class="form-item-container" fxLayout="column" fxLayoutGap="4px">
				<span class="item-title" i18n>Address Line 1*</span>
				<div fxLayout="column">
					<input
						i18n-placeholder
						placeholder="Address"
						matInput
						name="address1"
						[ngClass]="{
							'error-highlight':
								isFieldInvalid(eAccountBillingAddressFormControlNames.fcAddress1) ||
								isFieldErrorRequired(eAccountBillingAddressFormControlNames.fcAddress1)
						}"
						[formControlName]="eAccountBillingAddressFormControlNames.fcAddress1"
						[readonly]="disableForm" />
					<span
						*ngIf="isFieldErrorRequired(eAccountBillingAddressFormControlNames.fcAddress1)"
						class="error-message"
						i18n>
						Address line 1 is required.
					</span>
					<span *ngIf="isFieldInvalid(eAccountBillingAddressFormControlNames.fcAddress1)" class="error-message" i18n>
						{{ isFieldInvalid(eAccountBillingAddressFormControlNames.fcAddress1) }}
					</span>
					<div
						class="error-place-holder"
						*ngIf="
							!isFieldInvalid(eAccountBillingAddressFormControlNames.fcAddress1) ||
							!isFieldErrorRequired(eAccountBillingAddressFormControlNames.fcAddress1)
						"></div>
				</div>
			</div>
		</div>
		<div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxLayoutGap.lt-md="0px">
			<!-- Address2 -->
			<div class="form-item-container" fxLayout="column" fxLayoutGap="4px">
				<span class="item-title" i18n>Address Line 2</span>
				<div fxLayout="column">
					<input
						i18n-placeholder
						placeholder="Address"
						matInput
						name="address2"
						[ngClass]="{
							'error-highlight':
								isFieldInvalid(eAccountBillingAddressFormControlNames.fcAddress2) ||
								isFieldErrorRequired(eAccountBillingAddressFormControlNames.fcAddress2)
						}"
						[formControlName]="eAccountBillingAddressFormControlNames.fcAddress2"
						[readonly]="disableForm" />
					<span
						*ngIf="isFieldErrorRequired(eAccountBillingAddressFormControlNames.fcAddress2)"
						class="error-message"
						i18n>
						Address line 2 is required.
					</span>
					<span *ngIf="isFieldInvalid(eAccountBillingAddressFormControlNames.fcAddress2)" class="error-message" i18n>
						{{ isFieldInvalid(eAccountBillingAddressFormControlNames.fcAddress2) }}
					</span>
					<div
						class="error-place-holder"
						*ngIf="
							!isFieldInvalid(eAccountBillingAddressFormControlNames.fcAddress2) ||
							!isFieldErrorRequired(eAccountBillingAddressFormControlNames.fcAddress2)
						"></div>
				</div>
			</div>
			<!-- City -->
			<div class="form-item-container" fxLayout="column" fxLayoutGap="4px">
				<span class="item-title" i18n>City*</span>
				<div fxLayout="column">
					<input
						i18n-placeholder
						placeholder="City"
						name="city"
						matInput
						[ngClass]="{
							'error-highlight':
								isFieldInvalid(eAccountBillingAddressFormControlNames.fcCity) ||
								isFieldErrorRequired(eAccountBillingAddressFormControlNames.fcCity)
						}"
						[formControlName]="eAccountBillingAddressFormControlNames.fcCity"
						[readonly]="disableForm" />
					<span *ngIf="isFieldErrorRequired(eAccountBillingAddressFormControlNames.fcCity)" class="error-message" i18n>
						City is required.
					</span>
					<span *ngIf="isFieldInvalid(eAccountBillingAddressFormControlNames.fcCity)" class="error-message" i18n>
						{{ isFieldInvalid(eAccountBillingAddressFormControlNames.fcCity) }}
					</span>
					<div
						class="error-place-holder"
						*ngIf="
							!isFieldInvalid(eAccountBillingAddressFormControlNames.fcCity) ||
							!isFieldErrorRequired(eAccountBillingAddressFormControlNames.fcCity)
						"></div>
				</div>
			</div>
		</div>
		<div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxLayoutGap.lt-md="0px">
			<!-- state -->
			<div
				class="form-item-container"
				*ngIf="isToShowState && form?.controls?.address2 && states.length"
				fxLayout="column"
				fxLayoutGap="4px">
				<span class="item-title" i18n>State*</span>
				<div fxLayout="column">
					<mat-select
						i18n-placeholder
						placeholder="State"
						name="state"
						[ngClass]="{
							'error-highlight':
								isFieldInvalid(eAccountBillingAddressFormControlNames.fcState) ||
								isFieldErrorRequired(eAccountBillingAddressFormControlNames.fcState)
						}"
						[formControlName]="eAccountBillingAddressFormControlNames.fcState">
						<mat-option *ngFor="let state of states" [value]="state">{{ state }}</mat-option>
					</mat-select>
					<span *ngIf="isFieldErrorRequired(eAccountBillingAddressFormControlNames.fcState)" class="error-message" i18n>
						State is required.
					</span>
					<span *ngIf="isFieldInvalid(eAccountBillingAddressFormControlNames.fcState)" class="error-message" i18n>
						{{ isFieldInvalid(eAccountBillingAddressFormControlNames.fcState) }}
					</span>
					<div
						class="error-place-holder"
						*ngIf="
							!isFieldInvalid(eAccountBillingAddressFormControlNames.fcState) ||
							!isFieldErrorRequired(eAccountBillingAddressFormControlNames.fcState)
						"></div>
				</div>
			</div>
			<!-- postalCode -->
			<div class="form-item-container" fxLayout="column" fxLayoutGap="4px">
				<span class="item-title" i18n>Zip/Postal Code*</span>
				<div fxLayout="column">
					<input
						i18n-placeholder
						placeholder="Zip/Postal Code"
						[ngClass]="{
							'error-highlight':
								postalCodeError || isFieldErrorRequired(eAccountBillingAddressFormControlNames.fcPostalCode)
						}"
						name="postalCode"
						matInput
						[formControlName]="eAccountBillingAddressFormControlNames.fcPostalCode"
						[readonly]="disableForm" />
					<span
						*ngIf="postalCodeError || isFieldErrorRequired(eAccountBillingAddressFormControlNames.fcPostalCode)"
						class="error-message"
						i18n>
						Zip/Postal Code is not valid.
					</span>
					<div
						class="error-place-holder"
						*ngIf="
							!postalCodeError || !isFieldErrorRequired(eAccountBillingAddressFormControlNames.fcPostalCode)
						"></div>
				</div>
			</div>
		</div>
	</form>
</ng-container>
