import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AvatarModule } from 'ngx-avatar';
import { SpinnerModule } from '../../../components/spinner/spinner.module';
import { DateFromNowPipeModule } from '../../../pipes/date-from-now/date-from-now-pipe.module';
import { EllipsisPipeModule } from '../../../pipes/ellipsis/ellipsis-pipe.module';
import { SupportedFilesService } from '../../../services/supported-files.service';
import { NotificationItemShareComponent } from './notification-item-share/notification-item-share.component';
import { NotificationItemTransferComponent } from './notification-item-transfer/notification-item-transfer.component';
import { SharedItemComponent } from './shared-items-dialog/shared-item/shared-item.component';
import { SharedItemsDialogComponent } from './shared-items-dialog/shared-items-dialog.component';

@NgModule({
	declarations: [
		NotificationItemShareComponent,
		NotificationItemTransferComponent,
		SharedItemsDialogComponent,
		SharedItemComponent,
	],
	exports: [NotificationItemShareComponent, NotificationItemTransferComponent],
	imports: [
		CommonModule,
		FlexLayoutModule,
		MatIconModule,
		MatButtonModule,
		MatDialogModule,
		MatTooltipModule,
		MatBadgeModule,
		AvatarModule,
		DateFromNowPipeModule,
		EllipsisPipeModule,
		SpinnerModule,
	],
	providers: [SupportedFilesService],
})
export class NotificationItemShareModule {}
