import { ElementRef } from '@angular/core';

export function focusErrorInput(element: ElementRef) {
	setTimeout(() => {
		const invalidElements = element.nativeElement.querySelectorAll('input.ng-invalid');

		if (invalidElements && invalidElements.length) {
			invalidElements[0].focus();
		}
	});
}

export function focusFirstInput(element: ElementRef) {
	setTimeout(() => {
		const invalidElements = element.nativeElement.querySelectorAll('input');

		if (invalidElements && invalidElements.length) {
			invalidElements[0].focus();
		}
	});
}
