<div
	class="courses-table"
	[style]="{
		'box-shadow': isLoading ? 'none' : ' -2px -2px 8px 0px #fbffff80, 2px 2px 4px 0px #00000026'
	}">
	<div class="table-wrapper">
		<div class="table-toolbar-container" fxLayout="row" fxLayoutAlign="space-between center">
			<div class="table-title" i18n>Courses</div>
			<div class="table-toolbar-actions" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="15px">
				<cls-search-input
					[withLabel]="true"
					[addIconBackground]="false"
					[noBottomPadding]="true"
					appearance="fill"
					i18n-placeholder
					placeholder="Enter full course name"
					[value]="searchStr"
					[disabled]="isTableEmpty && !searchStr"
					(confirmChange)="onSearchConfirm($event)"></cls-search-input>
			</div>
		</div>
		<table
			mat-table
			[dataSource]="tableDataSource"
			[style]="
				!showPaginator || isLoading
					? 'border-bottom-left-radius:10px!important;border-bottom-right-radius:10px!important'
					: ''
			">
			<!-- Result Sources -->
			<ng-container matColumnDef="course_name">
				<th mat-header-cell *matHeaderCellDef>
					<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
						<span i18n>Course Name</span>
					</div>
				</th>

				<td mat-cell *matCellDef="let element">
					<a
						*ngIf="!isLoading; else loadingSkeleton"
						class="names-container-hover"
						mat-icon-button
						[routerLink]="['/lms', platformType, 'analytics', integrationId, 'course', element.id]"
						i18n-matTooltip
						[matTooltip]="element?.courseName"
						aria-label="opens course analytics">
						<mat-icon class="open-in-new" mat-cell *ngIf="element?.courseName?.length">open_in_new</mat-icon>
						{{ element?.courseName }}
					</a>
				</td>
			</ng-container>

			<ng-container matColumnDef="start_date">
				<th mat-header-cell *matHeaderCellDef>
					<div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
						<span i18n>Start Date</span>
						<button
							mat-icon-button
							class="sort-button"
							(click)="!hasNoSearchResults ? sortSources(eSortBy.StartDate) : ''"
							i18n-matTooltip
							matTooltip="Sort">
							<mat-icon
								fontSet="material-icons-round"
								class="table-sort-icon"
								[ngClass]="{
									'table-sort-icon-selected-desc':
										tableSortAndPaginateState?.sortBy === eSortBy.StartDate && !tableSortAndPaginateState?.IsDescending,
									'table-sort-icon-selected-asc':
										tableSortAndPaginateState?.sortBy === eSortBy.StartDate && tableSortAndPaginateState?.IsDescending
								}"
								[style.color]="isTableEmpty || hasNoSearchResults ? 'gray' : ''">
								sort
							</mat-icon>
						</button>
					</div>
				</th>
				<td mat-cell *matCellDef="let element" style="text-align: center">
					<ng-container *ngIf="!isLoading; else loadingSkeleton">
						{{ element?.startDate | date: 'MMM, yyyy ' }}
					</ng-container>
				</td>
			</ng-container>

			<ng-container matColumnDef="last_modification">
				<th mat-header-cell *matHeaderCellDef>
					<div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
						<span i18n>Last Modification</span>
					</div>
				</th>
				<td mat-cell *matCellDef="let element" style="text-align: center">
					<ng-container *ngIf="!isLoading; else loadingSkeleton">
						{{ element?.lastModification | date: 'MMM, yyyy ' }}
					</ng-container>
				</td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
		</table>
		<ng-container *ngIf="(isTableEmpty || hasNoSearchResults) && !isLoading">
			<cls-contained-content
				class="empty-message"
				[isTextCentered]="true"
				[ngStyle]="hasNoSearchResults ? { top: '100px', height: '90%' } : {}">
				<cls-contained-title>
					<div *ngIf="isTableEmpty && !hasNoSearchResults" i18n="contained-title-empty">No Courses Found</div>
					<div *ngIf="hasNoSearchResults" i18n="contained-title-empty-search">No Search results</div>
				</cls-contained-title>
				<cls-contained-description>
					<div fxLayout="column" fxLayoutGap="32px" fxLayoutAlign="center center">
						<div i18n="contained-description-empty" *ngIf="isTableEmpty && !hasNoSearchResults">
							Use this integration in a course to see data.
						</div>
						<div i18n="contained-description-empty-search" *ngIf="hasNoSearchResults">Cannot find Courses.</div>
					</div>
				</cls-contained-description>
			</cls-contained-content>
		</ng-container>
	</div>
	<!-- </ng-container> -->
	<cls-paginator
		(page)="movePaginator($event)"
		[pageSize]="paginateState.pageSize"
		[canLoadMoreData]="canLoadMore"
		[pageSizeOptions]="pageAmountOptions"
		[isLoadingMoreData]="isLoading"
		[showPaginator]="!isTableEmpty && !hasNoSearchResults && !isLoading"
		[pageIndex]="paginateState.pageIndex"
		[receivedDataLength]="receivedDataLength"></cls-paginator>
</div>

<ng-template #loadingSkeleton>
	<ngx-skeleton-loader
		count="1"
		animation="progress"
		appearance="line"
		[theme]="{
			'background-color': 'rgba(17, 41, 96, 0.3)',
			height: '18px',
			width: '40%',
			top: '10px',
			'border-radius': '10px'
		}"></ngx-skeleton-loader>
</ng-template>
