import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClsPageBannerComponent, ClsPageBannerDescription, ClsPageBannerIcon } from './cls-page-banner.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ClsPageBannerV2Component } from './cls-page-banner-v2/cls-page-banner-v2.component';

@NgModule({
	declarations: [ClsPageBannerComponent, ClsPageBannerDescription, ClsPageBannerIcon, ClsPageBannerV2Component],
	imports: [CommonModule, FlexLayoutModule],
	exports: [ClsPageBannerComponent, ClsPageBannerDescription, ClsPageBannerIcon, ClsPageBannerV2Component],
})
export class ClsPageBannerModule {}
