import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { DefaultNotificationItemModule } from '../default-notification-item/default-notification-item.module';
import { NotificationIndexDocumentModule } from '../notification-index-document/notification-index-document.module';
import { NotificationItemDeleteModule } from '../notification-item-delete/notification-item-delete.module';
import { NotificationItemDownloadModule } from '../notification-item-download/notification-item-download.module';
import { NotificationItemShareModule } from '../notification-item-share/notification-item-share.module';
import { BackgroundNotificationTaskItemComponent } from './background-notification-task-item.component';

@NgModule({
	declarations: [BackgroundNotificationTaskItemComponent],
	exports: [BackgroundNotificationTaskItemComponent],
	imports: [
		CommonModule,
		MatExpansionModule,
		NotificationItemDeleteModule,
		NotificationItemDownloadModule,
		NotificationItemShareModule,
		NotificationIndexDocumentModule,
		DefaultNotificationItemModule,
	],
})
export class BackgroundNotificationTaskItemModule {}
