import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'lms-writing-feedback-score',
	templateUrl: './lms-writing-feedback-score.component.html',
	styleUrls: ['./lms-writing-feedback-score.component.scss'],
})
export class LmsWritingFeedbackScoreComponent implements OnInit {
	/**
	 * Set the score level for view and color
	 * @type {number}
	 * @input score
	 */
	@Input() score: number;

	/**
	 * Is loading flag
	 * @type {boolean}
	 * @input isLoading
	 */
	@Input() isLoading: boolean;

	/**
	 * Source link of referal score
	 * @type {string}
	 * @input link
	 */
	@Input() link: string;

	/**
	 * Flag to open link in new window
	 * @type {boolean}
	 * @input openLinkInNewWindow
	 */
	@Input() openLinkInNewWindow: boolean;

	/**
	 * if API property is enabled.
	 * @type {boolean}
	 * @input isAPIPropertyEnabled
	 */
	@Input() isAPIPropertyEnabled: boolean = true;

	/**
	 * if feature is enabled.
	 * @type {boolean}
	 * @input isFeatureEnabled
	 */
	@Input() isFeatureEnabled: boolean = true;

	/**
	 * if the scan is failed.
	 * @type {boolean}
	 * @input isFailedScan
	 */
	@Input() isFailedScan: boolean = false;

	constructor(private _router: Router) {}

	ngOnInit(): void {}

	/**
	 * Open external link source
	 */
	openSource() {
		if (!this.link) return;

		if (this.openLinkInNewWindow) {
			window.open(this.link);
		} else {
			this._router.navigate([this.link]);
		}
	}
}
