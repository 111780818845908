<!-- Title section -->
<div class="title-container" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
	<mat-icon class="error-icon">warning</mat-icon>
	<span i18n="@@NGX.NOTIFICATION_DIALOG.ERROR_MESSAGE_TITLE">Something went wrong!</span>
</div>
<!-- Failed message section -->
<div class="failed-message-container">
	<span *ngIf="errorMessageIsArray" i18n="@@NGX.NOTIFICATION_DIALOG.ERROR_MESSAGE_ARRAY">
		{{ data.errorMessages.length }} files couldn't be deleted
	</span>
	<span *ngIf="!errorMessageIsArray">{{ data.errorMessages[0].message }}</span>
</div>
<!-- Guide code section -->
<div fxLayout="column" class="guide-code-section" fxLayoutGap="8px">
	<span class="copy-message-title" i18n="@@NGX.NOTIFICATION_DIALOG.IF_RECURRING_ISSUE">
		If this is a recurring issue:
	</span>
	<span class="copy-message" i18n="@@NGX.NOTIFICATION_DIALOG.COPY_ERROR_MESSAGE">
		Copy the following error message for support:
	</span>
	<div fxLayout="row" fxLayoutAlign="start center" class="copy-guide-code-container" fxLayoutGap="35px">
		<button
			mat-icon-button
			class="copy-guide-code-icon-container"
			fxLayoutAlign="center center"
			(click)="copyGuideCode()">
			<mat-icon class="copy-guide-code-icon">content_copy</mat-icon>
		</button>
		<span class="guide-code">{{ guideCode }}</span>
	</div>
	<a
		class="contact-support"
		[href]="'Mailto:support@copyleaks.com?subject=deletion-error-' + this.data.guideCode"
		i18n="@@NGX.NOTIFICATION_DIALOG.CONTACT_SUPPORT">
		Contact Support
	</a>
</div>
<!-- Action section -->
<div fxLayoutAlign="center center" style="padding: 32px 0px 8px">
	<button
		mat-raised-button
		class="help-page-button"
		i18n="@@NGX.NOTIFICATION_DIALOG.HELP_PAGE"
		(click)="openExternalLink(environment.helpSupportLink + '/can-i-delete-one-of-my-scans-and-report')">
		Help Page
	</button>
</div>
