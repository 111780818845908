<ng-template #breadcrumbText>
	<ng-content select="cls-breadcrumb-item-path"></ng-content>
</ng-template>
<ng-container *ngIf="!link">
	<a
		[tabIndex]="0"
		(keyup.enter)="onItemClick()"
		(click)="onItemClick()"
		class="copyleaks-a-tag-gray focus-indicator"
		style="text-decoration: none; color: #999">
		<span class="path-name" [ngClass]="{ 'last-nav-item': last }">
			<ng-container *ngTemplateOutlet="breadcrumbText"></ng-container>
		</span>
	</a>
</ng-container>
<ng-container *ngIf="link">
	<a [routerLink]="link" class="copyleaks-a-tag-gray focus-indicator" style="text-decoration: none; color: #999">
		<span class="path-name" [ngClass]="{ 'last-nav-item': last }">
			<ng-container *ngTemplateOutlet="breadcrumbText"></ng-container>
		</span>
	</a>
</ng-container>

<span
	fxLayoutAlign="center center"
	class="arrow-right-icon"
	*ngIf="!last && direction === 'ltr'"
	style="color: #17a1ff">
	<mat-icon>keyboard_arrow_right</mat-icon>
</span>
<span fxLayoutAlign="center center" class="arrow-left-icon" *ngIf="!last && direction === 'rtl'" style="color: #17a1ff">
	<mat-icon>keyboard_arrow_left</mat-icon>
</span>
