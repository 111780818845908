import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClsAnalyticsAuthorTemplateComponent, ClsAuthorAnalyticsData } from './cls-analytics-author-template.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ResultsInsightsTemplateModule } from '../cls-results-insights-template/cls-results-insights-template.module';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { MatIconModule } from '@angular/material/icon';
import { ClsCommonResultsTemplateModule } from '../cls-common-results-template/cls-common-results-template.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AnalyticsEmptyStateModule } from '../analytics-empty-state/analytics-empty-state.module';

@NgModule({
	declarations: [ClsAnalyticsAuthorTemplateComponent, ClsAuthorAnalyticsData],
	exports: [ClsAnalyticsAuthorTemplateComponent, ClsAuthorAnalyticsData],
	imports: [
		CommonModule,
		FlexLayoutModule,
		ResultsInsightsTemplateModule,
		NgxChartsModule,
		MatIconModule,
		MatTooltipModule,
		ClsCommonResultsTemplateModule,
		AnalyticsEmptyStateModule,
	],
})
export class ClsAnalyticsAuthorTemplateModule {}
