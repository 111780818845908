import { Component, Input, OnInit } from '@angular/core';
import { NumeralPipe } from '@ngx-common/pipes/numeral/numeral.pipe';

@Component({
	selector: 'cls-circular-progress-bar',
	templateUrl: './cls-circular-progress-bar.component.html',
	styleUrls: ['./cls-circular-progress-bar.component.scss'],
})
export class ClsCircularProgressBarComponent {
	/**
	 * the current amount of the progress bar until it reachs the maximum
	 * @Input
	 */
	@Input() currentAmount: number;
	/**
	 * the amount left of the progress bar until it reachs the maximum
	 * @Input
	 */
	@Input() amountLeft: number;
	/**
	 * The maximum value of the progress bar
	 * @Input
	 */
	@Input() maxAmount: number;
	/**
	 * The diameter size
	 * @Input
	 */
	@Input() diameter: string = '40';
	/**
	 * a loader
	 * @Input
	 */
	@Input() isLoading: boolean;

	/**
	 * The amount left as a percentage (%).
	 * @Getter
	 */
	get amountLeftPercentage() {
		return ((this.maxAmount - this.amountLeft) / this.maxAmount) * 100;
	}

	/**
	 * The current amount as a percentage (%).
	 * @Getter
	 */
	get currentAmountPercentage() {
		return (this.currentAmount / this.maxAmount) * 100;
	}
	/**
	 * The amount left as a string.
	 * @Getter
	 */
	get amountLeftAsString() {
		return this._numeralPipe.transform(this.amountLeft, 1);
	}

	/**
	 * The current amount as a string.
	 * @Getter
	 */
	get currentAmountAsString() {
		return this._numeralPipe.transform(this.currentAmount, 1);
	}
	/**
	 * The color based on the current amount/amount left.
	 * @Getter
	 */
	get colorDependingOnPercentage() {
		if (this.currentAmount) {
			if (this.currentAmountPercentage < 15) return 'warn';
			else return 'primary';
		} else {
			if (this.amountLeftPercentage > 85) return 'warn';
			else return 'primary';
		}
	}

	constructor(private _numeralPipe: NumeralPipe) {}
}
