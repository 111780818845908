import { Component, Inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService } from '../../../../services/auth.service';
import { ScreenService } from '../../../../services/screen.service';
import { EDashboardSideNavMode, NewDashboardNavbarV2Service } from '../../services/new-dashboard-navbar-v2.service';
import { ECopyleaksAPP, NgxCommonPagesConfig } from '../../../../pages/pages.config';

@UntilDestroy()
@Component({
	selector: 'cls-dashboard-logo-v2',
	templateUrl: './dashboard-logo-v2.component.html',
	styleUrls: ['./dashboard-logo-v2.component.scss'],
})
export class DashboardLogoComponent implements OnInit {
	@Input() desktopImgUrl: string;
	@Input() mobileImgUrl: string;
	@Input() link: string;

	public isUserLogedIn: boolean;
	sideNavMode: EDashboardSideNavMode = EDashboardSideNavMode.shrinked;

	get showShrinkedLogo() {
		return !this.isMobile && this.sideNavMode == EDashboardSideNavMode.shrinked;
	}
	get isMobile() {
		return this._screenSvc?.isMobile();
	}

	get isLMS() {
		return this._config?.APP == ECopyleaksAPP.LMS;
	}

	constructor(
		private _router: Router,
		private _authSvc: AuthService,
		private _newDashboardNavbarSvc: NewDashboardNavbarV2Service,
		private _screenSvc: ScreenService,
		@Inject(NgxCommonPagesConfig.key) private _config: NgxCommonPagesConfig
	) {}

	ngOnInit(): void {
		this._newDashboardNavbarSvc.sideNavMode$.pipe(untilDestroyed(this)).subscribe(mode => {
			this.sideNavMode = mode;
		});

		this._authSvc.isLogedIn$.pipe(untilDestroyed(this)).subscribe(isUserLogedIn => {
			this.isUserLogedIn = isUserLogedIn;
		});
	}

	navigateToHome() {
		if (this.isUserLogedIn) {
			this._router.navigate([this.link]);
		} else {
			this._router.navigate([`/`]);
		}
	}
}
