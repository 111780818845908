<div class="input-section">
	<mat-form-field [floatLabel]="floatLabel">
		<input
			matInput
			autocomplete="off"
			type="text"
			maxlength="50"
			[errorStateMatcher]="customErrorStateMatcher"
			[formControl]="captchaTextCtrl"
			placeholder="Type the letters and numbers"
			i18n-placeholder />
		<button
			mat-icon-button
			aria-label="refresh"
			[disabled]="maxRefreshes == 0 || showCaptchaSpinner"
			type="button"
			(click)="generateCaptcha()"
			style="font-size: 25px"
			matSuffix
			fxLayout="row"
			fxLayoutAlign="center center">
			<mat-icon inline="true" matTooltip="New Captcha" i18n-matTooltip>refresh</mat-icon>
		</button>
		<mat-error>
			<span *ngIf="!errorText" style="font-size: 12px; margin: 0" i18n="@@NGX.CAPTCHA.VALIDATION_IMAGE">
				Please fill the text that you see in the image.
			</span>
		</mat-error>
		<mat-error class="error-section" *ngIf="errorText">
			{{ errorText }}
		</mat-error>
	</mat-form-field>
</div>

<ng-container *ngIf="generatedCaptcha && !showCaptchaSpinner">
	<img alt="CAPTCHA" style="margin-top: 5px" [src]="'data:image/png;base64,' + generatedCaptcha?.image" />
</ng-container>
<ng-container *ngIf="showCaptchaSpinner">
	<mat-progress-spinner color="primary" diameter="50" mode="indeterminate"></mat-progress-spinner>
</ng-container>
