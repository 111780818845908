import { Component, Input, OnInit } from '@angular/core';
import { IAnalyticsExternalResults } from '../../models/cls-external-results-table.models';
import { ITableEmptyState } from '../../../../components/cls-attachements-manager-table/models/cls-attachements-manager-table.models';

@Component({
	selector: 'cls-common-results-template',
	templateUrl: './cls-common-results-template.component.html',
	styleUrls: ['./cls-common-results-template.component.scss'],
})
export class ClsCommonResultsTemplateComponent implements OnInit {
	@Input() dataSrc: IAnalyticsExternalResults[];

	pieChartData: IAnalyticsExternalResults[];

	emptyState: ITableEmptyState = {
		title: $localize`No Internet Resources`,
		description: $localize`There is no internet results to show`,
	};

	ngOnInit(): void {
		this.dataSrc = this.dataSrc.sort((a, b) => b.value - a.value);
		this.pieChartData = [...this.dataSrc];
	}
}
