import { Direction } from '@angular/cdk/bidi';
import { Component, OnInit, Input, ElementRef, Output, EventEmitter } from '@angular/core';
import { AppDirectionalityService } from '../../../services/app-directionality.service';

@Component({
	selector: 'cls-breadcrumb-item',
	templateUrl: './breadcrumb-item.component.html',
	styleUrls: ['../breadcrumb-navigation/breadcrumb-navigation.component.scss'],
})
export class BreadcrumbItemComponent implements OnInit {
	@Input() last = false;
	@Input() link;
	@Output() itemClick = new EventEmitter();
	public direction: Direction;
	constructor(private elementRef: ElementRef<HTMLElement>, private readonly dir: AppDirectionalityService) {}
	ngOnInit() {
		// this is linked with the BreadcumbsSeoDirective
		const span = this.elementRef.nativeElement.querySelector('span[class="path-name"]') as HTMLElement;
		span?.setAttribute('path-link', this.link);
		// endof "this is linked with the BreadcumbsSeoDirective"
		this.direction = this.dir.value;
	}

	onItemClick() {
		this.itemClick.emit();
	}
}

@Component({
	selector: 'cls-breadcrumb-item-path',
	template: '<ng-content></ng-content>',
})
export class BreadcrumbItemPathComponent {}
