<div class="card-contanier" fxLayout="row" fxLayoutGap="32px" [id]="id" fxFlex>
	<div fxLayout="column" fxLayoutAlign="space-between">
		<div fxLayout="column" fxLayoutGap="8px">
			<div class="card-title">
				<ng-content select="analytics-empty-state-title"></ng-content>
			</div>
			<div class="card-description">
				<ng-content select="analytics-empty-state-description"></ng-content>
			</div>
		</div>
		<ng-content select="analytics-empty-state-bottom-section"></ng-content>
	</div>

	<div fxLayout="row" fxLayoutAlign="center center">
		<ng-content select="analytics-empty-state-image"></ng-content>
	</div>
</div>
