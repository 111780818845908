import { Component, Inject, ViewChild, ElementRef, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { ProgressButtonEvent } from '../../../directives/progress-button.directive';

export interface DeleteSomethingDialogData {
	title: string;
	message: string;
	item: {
		type: string;
		expect: string;
	};
	action: (result: boolean, progress: ProgressButtonEvent) => Promise<any>;
}

@Component({
	selector: 'delete-something-dialog',
	templateUrl: './delete-something-dialog.component.html',
	styleUrls: ['./delete-something-dialog.component.scss'],
})
export class DeleteSomethingDialog implements OnInit {
	public data: DeleteSomethingDialogData;
	public input: string;
	@ViewChild(MatInput, { static: true }) inputRef: MatInput;
	constructor(public dialogRef: MatDialogRef<DeleteSomethingDialog>, @Inject(MAT_DIALOG_DATA) data: any) {
		this.data = data;
	}
	ngOnInit(): void {
		this.inputRef.focus();
	}
	onNoClick(): void {
		this.dialogRef.close();
	}
	async onYesClick(progress: ProgressButtonEvent) {
		await this.data.action(true, progress);
		this.dialogRef.close();
	}
}
