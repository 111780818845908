import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { environment } from '@environment';
import { ConfirmPaymentBtnModule } from '../confirm-payment-btn/confirm-payment-btn.module';
import { AppSkeletonLoaderModule } from '../skeleton-loader/skeleton-loader.module';
import { SpinnerModule } from '../spinner/spinner.module';
import { CreditCardComponent } from './credit-card-component/credit-card.component';
import { StripeModule } from './stripe';
import { CreditCardLoaderComponent } from './credit-card-loader/credit-card-loader.component';

@NgModule({
	declarations: [CreditCardComponent, CreditCardLoaderComponent],
	exports: [CreditCardComponent],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,

		FlexLayoutModule,

		SpinnerModule,
		ConfirmPaymentBtnModule,

		MatFormFieldModule,
		MatCheckboxModule,
		MatButtonModule,
		MatInputModule,
		MatIconModule,
		MatSelectModule,
		MatTooltipModule,
		StripeModule.init({
			publicKey: environment.stripKey,
			elementOptions: {
				style: {
					base: {
						fontFamily: 'haboro-soft, Open Sans, sans-serif',
					},
				},
			},
		}),
		AppSkeletonLoaderModule,
	],
})
export class CreditCardModule {}
