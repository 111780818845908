<!-- no notifications view -->
<ng-container *ngIf="notifications.length == 0 && !this.showSpinner">
	<div fxLayout="column">
		<div class="notifications-title-container" fxLayoutAlign="center center">
			<h3 i18n="@@NGX.CONTAINER_NOTIFICATIONS.NOTIFICATIONS">Notifications</h3>
		</div>
		<div class="empty-notifications-message-container" fxLayout="column" fxLayoutAlign="center center">
			<img
				class="notifications-off-icon"
				src="assets/websites-icons/notifications_off.svg"
				alt="notifications_off.svg" />
			<p class="empty-notifications-message" i18n="@@NGX.CONTAINER_NOTIFICATIONS.NO_UNREAD_NOTIFICATIONS">
				You don't have any unread notifications
			</p>
		</div>
	</div>
</ng-container>

<ng-container *ngIf="notifications.length == 0 && this.showSpinner">
	<cls-spinner *ngIf="this.showSpinner" width="70px"></cls-spinner>
</ng-container>

<ng-container *ngIf="notifications.length > 0">
	<div class="notifications-title-container" fxLayout="row" fxLayoutAlign="center center">
		<h3 i18n="@@NGX.CONTAINER_NOTIFICATIONS.NOTIFICATIONS">Notifications</h3>
		<button mat-icon-button [matMenuTriggerFor]="closeAllMenu" aria-label="more">
			<mat-icon>more_vert</mat-icon>
		</button>
	</div>
	<div class="notification-tasks-container" (scroll)="onPageScroll($event)">
		<ng-container *ngFor="let notification of notifications; last as isLast">
			<ng-container [ngSwitch]="notification.messageType">
				<ng-container *ngSwitchCase="eNotificationType.deleteElements">
					<cls-notification-item-delete
						[model]="notification"
						role="menuitem"
						[isDeletingItem]="isDeletingNotification"
						(onNotificationClose)="onCloseNotificationAsync($event)"></cls-notification-item-delete>
				</ng-container>
				<ng-container *ngSwitchCase="eNotificationType.downloadReport">
					<cls-notification-item-download
						[model]="notification"
						role="menuitem"
						[isDeletingItem]="isDeletingNotification"
						(onNotificationClose)="onCloseNotificationAsync($event)"></cls-notification-item-download>
				</ng-container>
				<ng-container *ngSwitchCase="eNotificationType.share">
					<cls-notification-item-share role="menuitem" [model]="notification"></cls-notification-item-share>
				</ng-container>
				<ng-container *ngSwitchCase="eNotificationType.indexDocuments">
					<cls-notification-index-document
						[model]="notification"
						[isDeletingItem]="isDeletingNotification"
						(onNotificationClose)="onCloseNotificationAsync($event)"></cls-notification-index-document>
				</ng-container>
				<ng-container *ngSwitchCase="eNotificationType.recurrenceUpdate">
					<cls-notification-item-recurrence-update
						role="menuitem"
						[model]="notification"></cls-notification-item-recurrence-update>
				</ng-container>
				<ng-container *ngSwitchCase="eNotificationType.transferElements">
					<cls-notification-item-transfer role="menuitem" [model]="notification"></cls-notification-item-transfer>
				</ng-container>
				<ng-container *ngSwitchCase="eNotificationType.general">
					<cls-notification-item-general
						role="menuitem"
						[model]="notification"
						[isRead]="notification.isRead"></cls-notification-item-general>
				</ng-container>
				<ng-container *ngSwitchDefault>
					<app-default-notification-item
						[isRead]="notification?.isRead"
						role="menuitem"
						[notificationType]="notification?.messageType"
						[linkAddress]="notification?.linkAddress">
						<app-default-notification-description>
							{{ notification.message }}
						</app-default-notification-description>
						<app-default-notification-time [matTooltip]="notification.creationTime | date">
							{{ notification.creationTime | dateFromNow }}
						</app-default-notification-time>
					</app-default-notification-item>
				</ng-container>
			</ng-container>
			<div style="width: 100%" fxLayoutAlign="center center">
				<span [ngClass]="{ 'border-between-notifications': !isLast }"></span>
			</div>
		</ng-container>
		<!-- show spinner for infinite scroll (only if there is more notifications) -->
		<cls-spinner *ngIf="this.showSpinner" width="35px"></cls-spinner>
	</div>
</ng-container>

<mat-menu #closeAllMenu="matMenu" xPosition="before">
	<div mat-menu-item fxLayoutAlign="center center" (click)="$event.stopPropagation(); onClearAllNotificationsAsync()">
		<span class="close-all-button" i18n="@@NGX.CONTAINER_NOTIFICATIONS.CLEAR_BUTTON">Clear</span>
	</div>
</mat-menu>
