import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationErrorDialogComponent } from './notification-error-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NotificationErrorDialogItemComponent } from './components/notification-error-dialog-item/notification-error-dialog-item.component';

@NgModule({
	declarations: [NotificationErrorDialogComponent, NotificationErrorDialogItemComponent],
	imports: [CommonModule, MatDialogModule, MatButtonModule, MatIconModule, FlexLayoutModule],
})
export class NotificationErrorDialogModule {}
