import { animate, query, sequence, stagger, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'cls-toggle-list',
	templateUrl: './cls-toggle-list.component.html',
	styleUrls: ['./cls-toggle-list.component.scss'],
})
export class ClsToggleListComponent implements OnInit {
	txtCollapse = $localize`Collapse`;
	txtExpend = $localize`Expend`;
	/**
	 * The list Title.
	 * @Input
	 */
	@Input() listTitle: string;
	/**
	 * A toggle variable to toggle the list's display.
	 */
	showList: boolean = true;
	constructor() {}

	ngOnInit(): void {}
	/**
	 * toggles the showList variable (if the variable's value is true, then it changes it to false, and the otherway around)
	 * @Function
	 */
	toggleList() {
		if (this.showList) this.showList = false;
		else this.showList = true;
	}
}
