export const SIB_SCRIPT = {
	SCRIPT: 'https://sibautomation.com/sa.js?key=wsxnhu6wphx72hwr7vass',
};

export enum SIB_EVENTS {
	PURCHASED_ITEM = 'purchased_item',
	VIEW_CHECKOUT = 'view_checkout',
	ADD_TO_CART = 'add_to_cart',
	VIEW_PRICING = 'view_pricing',
	REQUEST_CANCELATION = 'requested_cancelation',
	CANCELED_PAID_ACCOUNT = 'canceled_paid_account',
}
