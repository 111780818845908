import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Observable, of } from 'rxjs';
import { finalize, share, tap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class PaymentMethodsService {
	private _cache: any;
	private _cachedObservable$: Observable<IPaymentMethod>;
	private _havePaymentMethod = null;

	constructor(private _http: HttpClient) {}

	setPaymentMethod(paymentMethod: IPaymentMethod) {
		this._cache = paymentMethod;
		this._havePaymentMethod = true;
	}

	get cachedPaymentMethod() {
		return this._cache;
	}

	getDefaultPaymentMethod(): Observable<IPaymentMethod> {
		try {
			let observable: Observable<any>;
			if (this._cache || this._havePaymentMethod === false) {
				observable = of(this._cache);
			} else if (this._cachedObservable$) {
				observable = this._cachedObservable$;
			} else {
				this._cachedObservable$ = this._http
					.get<IPaymentMethod>(`${environment.apiUrl}/v1/account/default/payment-method`)
					.pipe(
						tap(res => {
							if (!res) {
								this._havePaymentMethod = false;
							} else {
								this._havePaymentMethod = true;
							}
							this._cache = res;
						}),
						share(),
						finalize(() => (this._cachedObservable$ = null))
					);
				observable = this._cachedObservable$;
			}
			return observable;
		} catch (error) {
			console.log(error);
		}
	}

	async clearDefaultPaymentMethod() {
		this._cache = null;
		this._cachedObservable$ = null;
		this._havePaymentMethod = null;
	}

	async deletePaymentMethodById(id: string) {
		return await this._http.delete(`${environment.apiUrl}/v1/account/payments/methods/${id}`).toPromise();
	}
}

export interface IPaymentMethod {
	paymentMethodId: string;
	nameOnCard: string;
	experationMonth: number;
	experationYear: number;
	last4Digits: string;
	type: EPaymentMethodTypes;
	userStatus: EUserBillingStatus;
	canDelete: boolean;
}

export enum EPaymentMethodTypes {
	Visa = 1,
	AmericanExpress = 2,
	Discover = 3,
	MasterCard = 4,
	Unknown = 5,
}

export enum EUserBillingStatus {
	Healthy = 1,
	PaymentLockedOut = 2,
}
